/* eslint-disable */
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  /** Base64 encode any value and transmit as a string */
  Base64: { input: any; output: any; }
  /** Base64 encode any value and transmit as a string */
  Base64Brotli: { input: any; output: any; }
  /**
   * Represents non-fractional signed whole numeric values. Since the value may
   * exceed the size of a 32-bit integer, it's encoded as a string.
   */
  BigInt: { input: any; output: any; }
  /** Parse a base64 encoded file upload into an ActionDispatch::Http::UploadedFile object */
  Blob: { input: any; output: any; }
  /** An ISO 8601-encoded date */
  ISO8601Date: { input: any; output: any; }
  /** An ISO 8601-encoded datetime */
  ISO8601DateTime: { input: any; output: any; }
  /** Represents untyped JSON */
  JSON: { input: any; output: any; }
  Upload: { input: any; output: any; }
};

export type AcceptInvitationAttributes = {
  /** Password for the account */
  password?: InputMaybe<Scalars['String']['input']>;
  /** Password confirmation for the account */
  passwordConfirmation?: InputMaybe<Scalars['String']['input']>;
  /** Invitation subdomain */
  subdomain: Scalars['String']['input'];
  /** Invitation token */
  token: Scalars['String']['input'];
};

/** Autogenerated input type of AcceptInvitation */
export type AcceptInvitationInput = {
  attributes: AcceptInvitationAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of AcceptInvitation. */
export type AcceptInvitationPayload = {
  __typename?: 'AcceptInvitationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  credentials?: Maybe<Credentials>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  fieldErrors?: Maybe<Array<FieldError>>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

/** Autogenerated input type of AcceptInvite */
export type AcceptInviteInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Patient App invite code */
  invite: Scalars['ID']['input'];
  /** ID of the requested OTP */
  otp: Scalars['ID']['input'];
  /** OTP code to validate against the OTP */
  otpCode: Scalars['String']['input'];
};

/** Autogenerated return type of AcceptInvite. */
export type AcceptInvitePayload = {
  __typename?: 'AcceptInvitePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** General errors, unrelated to fields */
  errors?: Maybe<Array<Scalars['String']['output']>>;
  /** Input field errors */
  fieldErrors?: Maybe<Array<FieldError>>;
  patientAppConfig?: Maybe<PatientAppConfiguration>;
  /** Whether or not this mutation was successful */
  success?: Maybe<Scalars['Boolean']['output']>;
};

/** Autogenerated input type of AcceptTreatmentPlan */
export type AcceptTreatmentPlanInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  signature?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of AcceptTreatmentPlan. */
export type AcceptTreatmentPlanPayload = {
  __typename?: 'AcceptTreatmentPlanPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** General errors, unrelated to fields */
  errors?: Maybe<Array<Scalars['String']['output']>>;
  /** Input field errors */
  fieldErrors?: Maybe<Array<FieldError>>;
  /** Whether or not this mutation was successful */
  success?: Maybe<Scalars['Boolean']['output']>;
  treatmentPlan?: Maybe<TreatmentPlan>;
};

export type AccessRestriction = {
  __typename?: 'AccessRestriction';
  acceptOtpEmail: Scalars['Boolean']['output'];
  acceptOtpSms: Scalars['Boolean']['output'];
  /** Whether a OTP or a pin code is required to access this resource */
  authenticationType: AccessRestrictionAuthentication;
  expiresAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  id: Scalars['String']['output'];
  /** Name of the user this restriction is for */
  name: Scalars['String']['output'];
  pinCode?: Maybe<Scalars['String']['output']>;
  /** Link to redirect to on successful authentication */
  redirectUrl?: Maybe<Scalars['String']['output']>;
  /** Unique identifier for the access restriction */
  reference?: Maybe<Scalars['String']['output']>;
  /** ID of the UPC this access restriction is associated with */
  upcId?: Maybe<Scalars['ID']['output']>;
  /** Link to access this resource */
  url?: Maybe<Scalars['String']['output']>;
};

export type AccessRestrictionAttributes = {
  otpCode?: InputMaybe<Scalars['String']['input']>;
  otpId?: InputMaybe<Scalars['ID']['input']>;
  pin?: InputMaybe<Scalars['String']['input']>;
  /** UUID for the Access::Restriction */
  restriction?: InputMaybe<Scalars['String']['input']>;
};

export enum AccessRestrictionAuthentication {
  /** Automatic login, but the link expires once logged out and cannot be re-used */
  Auto = 'auto',
  /** Requires OTP authentication */
  Otp = 'otp',
  /** Requires PIN authentication */
  Pin = 'pin'
}

export type Account = {
  __typename?: 'Account';
  activePractitionersCount?: Maybe<Scalars['Int']['output']>;
  baseUrl?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  creditCardBrand?: Maybe<Scalars['String']['output']>;
  creditCardName?: Maybe<Scalars['String']['output']>;
  expiringMonth?: Maybe<Scalars['Int']['output']>;
  expiringYear?: Maybe<Scalars['Int']['output']>;
  guid: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  isPremium: Scalars['Boolean']['output'];
  last4?: Maybe<Scalars['String']['output']>;
  maxPractitionerCount?: Maybe<Scalars['Int']['output']>;
  planName: Scalars['String']['output'];
  smsCredits?: Maybe<Scalars['Int']['output']>;
  subdomain: Scalars['String']['output'];
  verified: Scalars['Boolean']['output'];
};

/** The connection type for Account. */
export type AccountConnection = {
  __typename?: 'AccountConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<AccountEdge>>>;
  /** IDs of ALL results, regardless of pagination */
  ids: Array<Scalars['ID']['output']>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Account>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Returns total count of items in list */
  totalCount: Scalars['Int']['output'];
};

export type AccountCreditInput = {
  /** Amount to add */
  amount: Scalars['Float']['input'];
  date?: InputMaybe<Scalars['ISO8601Date']['input']>;
  /** Describe the transaction */
  description: Scalars['String']['input'];
  /** The transaction method slug, e.g manual_cash, manual_unallocated etc */
  transactionMethod: Scalars['String']['input'];
};

/** An edge in a connection. */
export type AccountEdge = {
  __typename?: 'AccountEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<Account>;
};

export type AccountPlan = {
  __typename?: 'AccountPlan';
  account?: Maybe<Account>;
  features: Array<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  isPremium: Scalars['Boolean']['output'];
  maxLocation: Scalars['Int']['output'];
  /** Maximum allowed patients for this plan */
  maxPatientCount: Scalars['Int']['output'];
  /** Maximum allowed practitioners on this plan */
  maxPractitionerCount: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  noOfLicenseUsed?: Maybe<Scalars['Int']['output']>;
  noOfLocationAdded?: Maybe<Scalars['Int']['output']>;
  patientsAdded?: Maybe<Scalars['Int']['output']>;
  planId: AccountPlanId;
  smsCredits: Scalars['Int']['output'];
  state?: Maybe<AccountPlanState>;
  storage: Scalars['BigInt']['output'];
  storagePerPerson?: Maybe<Scalars['Boolean']['output']>;
  storageSizeUsed?: Maybe<Scalars['BigInt']['output']>;
  subscription?: Maybe<StripeSubscription>;
};

export enum AccountPlanId {
  FreePlan = 'FREE_PLAN',
  Premium = 'PREMIUM',
  PremiumTrial = 'PREMIUM_TRIAL'
}

export enum AccountPlanState {
  Activated = 'activated',
  Archived = 'archived',
  CancelProcessed = 'cancel_processed',
  Cancelled = 'cancelled',
  Deactivated = 'deactivated',
  Declined = 'declined',
  Default = 'default',
  Pending = 'pending'
}

/** Autogenerated return type of AccountPlanUpdated. */
export type AccountPlanUpdatedPayload = {
  __typename?: 'AccountPlanUpdatedPayload';
  accountPlan?: Maybe<AccountPlan>;
};

export type AccountPractice = {
  __typename?: 'AccountPractice';
  features?: Maybe<Array<Scalars['String']['output']>>;
  guid: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type AccountPublic = {
  __typename?: 'AccountPublic';
  activePractitionersCount?: Maybe<Scalars['Int']['output']>;
  baseUrl?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  guid: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  subdomain: Scalars['String']['output'];
  verified: Scalars['Boolean']['output'];
};

export type AccountUpdateInput = {
  /** Features enabled for this account */
  features?: InputMaybe<Array<Feature>>;
  /** Max allowed practitioners for this account */
  maxPractitionerCount?: InputMaybe<Scalars['Int']['input']>;
  /** SMS Credits for this account */
  smsCredits?: InputMaybe<Scalars['Int']['input']>;
  /** Whether this account is active or not */
  verified?: InputMaybe<Scalars['Boolean']['input']>;
};

export type AccountUser = {
  __typename?: 'AccountUser';
  accountId: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  role: AccountUserRole;
  state: AccountUserState;
  userId: Scalars['ID']['output'];
};

export enum AccountUserRole {
  MasterAdmin = 'master_admin'
}

export enum AccountUserState {
  Active = 'active',
  Deactivated = 'deactivated'
}

export type Accounts = {
  __typename?: 'Accounts';
  /** The subdomain of the account */
  accountName?: Maybe<Scalars['String']['output']>;
  /** The ID of the account */
  id?: Maybe<Scalars['ID']['output']>;
  userPracticeConnections?: Maybe<Array<PracticeSwitch>>;
};

/** Autogenerated input type of AddAuthenticator */
export type AddAuthenticatorInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** OTP Code */
  otpCode: Scalars['String']['input'];
  /** OTP Secret key */
  secretKey: Scalars['String']['input'];
};

/** Autogenerated return type of AddAuthenticator. */
export type AddAuthenticatorPayload = {
  __typename?: 'AddAuthenticatorPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** General errors, unrelated to fields */
  errors?: Maybe<Array<Scalars['String']['output']>>;
  /** Input field errors */
  fieldErrors?: Maybe<Array<FieldError>>;
  /** Whether or not this mutation was successful */
  success?: Maybe<Scalars['Boolean']['output']>;
};

/** Autogenerated input type of AddCreditCard */
export type AddCreditCardInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  paymentMethodId: Scalars['String']['input'];
};

/** Autogenerated return type of AddCreditCard. */
export type AddCreditCardPayload = {
  __typename?: 'AddCreditCardPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  fieldErrors?: Maybe<Array<FieldError>>;
  practice?: Maybe<Practice>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

/** Autogenerated input type of AddFamilyMember */
export type AddFamilyMemberInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The ID of the family to update */
  id: Scalars['ID']['input'];
  /** The ID of the UPC record you want to add to the family */
  upcId: Scalars['ID']['input'];
};

/** Autogenerated return type of AddFamilyMember. */
export type AddFamilyMemberPayload = {
  __typename?: 'AddFamilyMemberPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  family?: Maybe<Family>;
  fieldErrors?: Maybe<Array<FieldError>>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

/** Autogenerated input type of AddGroupToRole */
export type AddGroupToRoleInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  groupId: Scalars['ID']['input'];
  roleId: Scalars['ID']['input'];
};

/** Autogenerated return type of AddGroupToRole. */
export type AddGroupToRolePayload = {
  __typename?: 'AddGroupToRolePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  fieldErrors?: Maybe<Array<FieldError>>;
  role?: Maybe<Role>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

/** Autogenerated input type of AddGuests */
export type AddGuestsInput = {
  appointmentId: Scalars['ID']['input'];
  attributes: AppointmentGuestInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of AddGuests. */
export type AddGuestsPayload = {
  __typename?: 'AddGuestsPayload';
  appointment?: Maybe<Appointment>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  fieldErrors?: Maybe<Array<FieldError>>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

/** Autogenerated input type of AddToTreatmentPlanTreatmentsPreset */
export type AddToTreatmentPlanTreatmentsPresetInput = {
  attributes: TreatmentsPresetAddToPlanInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of AddToTreatmentPlanTreatmentsPreset. */
export type AddToTreatmentPlanTreatmentsPresetPayload = {
  __typename?: 'AddToTreatmentPlanTreatmentsPresetPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  dentalToothTreatments?: Maybe<Array<DentalToothTreatment>>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  fieldErrors?: Maybe<Array<FieldError>>;
  patientRecordItems?: Maybe<Array<PatientRecordItem>>;
  success?: Maybe<Scalars['Boolean']['output']>;
  treatmentPlan?: Maybe<TreatmentPlan>;
};

/** Autogenerated input type of AdjustAccountCredit */
export type AdjustAccountCreditInput = {
  attributes: AccountCreditInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of AdjustAccountCredit. */
export type AdjustAccountCreditPayload = {
  __typename?: 'AdjustAccountCreditPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  fieldErrors?: Maybe<Array<FieldError>>;
  patientRecordCredit?: Maybe<PatientRecordCredit>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type AdminPracticeLocation = {
  __typename?: 'AdminPracticeLocation';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  shortName: Scalars['String']['output'];
};

/**
 * Advice Items are suggestions or advice for the patient, e.g videos,
 * fact sheets, or other attachments that a patient may wish to see.
 */
export type AdviceItem = {
  __typename?: 'AdviceItem';
  content?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  dueIn?: Maybe<Scalars['Int']['output']>;
  frequency?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  mediaType?: Maybe<AdviceItemMedia>;
  name?: Maybe<Scalars['String']['output']>;
};

export enum AdviceItemMedia {
  FactSheet = 'fact_sheet',
  Video = 'video'
}

export type AggridCredentials = {
  __typename?: 'AggridCredentials';
  /** AG Grid License Key */
  licenseKey?: Maybe<Scalars['Base64']['output']>;
};

export type Alert = {
  __typename?: 'Alert';
  active: Scalars['Boolean']['output'];
  colour: Scalars['String']['output'];
  createdAt: Scalars['ISO8601DateTime']['output'];
  icon?: Maybe<Icon>;
  id: Scalars['ID']['output'];
  isSuper?: Maybe<Scalars['Boolean']['output']>;
  text: Scalars['String']['output'];
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

/** The connection type for Alert. */
export type AlertConnection = {
  __typename?: 'AlertConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<AlertEdge>>>;
  /** IDs of ALL results, regardless of pagination */
  ids: Array<Scalars['ID']['output']>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Alert>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Returns total count of items in list */
  totalCount: Scalars['Int']['output'];
};

export type AlertCreateInput = {
  /** Hex colour code */
  colour?: InputMaybe<Scalars['String']['input']>;
  /** Icon as Font Awesome string */
  icon?: InputMaybe<Scalars['String']['input']>;
  /** Is Super alert */
  isSuper?: InputMaybe<Scalars['Boolean']['input']>;
  /** Name of Alert */
  text: Scalars['String']['input'];
};

/** An edge in a connection. */
export type AlertEdge = {
  __typename?: 'AlertEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<Alert>;
};

export type AlertUpdateInput = {
  /** Active status of alert */
  active?: InputMaybe<Scalars['Boolean']['input']>;
  /** Hex colour code */
  colour?: InputMaybe<Scalars['String']['input']>;
  /** Icon as Font Awesome string */
  icon?: InputMaybe<Scalars['String']['input']>;
  /** Is Super alert */
  isSuper?: InputMaybe<Scalars['Boolean']['input']>;
  /** Name of Alert */
  text?: InputMaybe<Scalars['String']['input']>;
};

export type AnyNode = {
  /** Unique identifier of the message */
  uuid: Scalars['String']['output'];
};

export type AppSignalCredentials = {
  __typename?: 'AppSignalCredentials';
  /** API key used to send errors to AppSignal */
  apiKey?: Maybe<Scalars['Base64']['output']>;
};

export type ApplicationEnvironment = {
  __typename?: 'ApplicationEnvironment';
  /** Deployed frontend version. This can be compared with bundled frontend version */
  bundleVersion?: Maybe<Scalars['String']['output']>;
  commit?: Maybe<Scalars['String']['output']>;
  environment: ApplicationEnvironments;
  maintenanceTime?: Maybe<Scalars['String']['output']>;
  onlineBookingBaseUrl?: Maybe<Scalars['String']['output']>;
  releaseTime?: Maybe<Scalars['ISO8601DateTime']['output']>;
  thirdParty?: Maybe<ThirdParty>;
  version?: Maybe<Scalars['String']['output']>;
};

export enum ApplicationEnvironments {
  Development = 'development',
  Local = 'local',
  Production = 'production',
  Staging = 'staging'
}

/**
 * This type only exists because graphql-ruby doesn't
 * allow adding enums to orphan_types, and instead only
 * accepts objects. This object is the shape of a GraphQL
 * execution error, but references the ExceptionType enum,
 * including it in the schema
 */
export type ApplicationException = {
  __typename?: 'ApplicationException';
  /** Error message from the exception */
  message?: Maybe<Scalars['String']['output']>;
};

/** Hash containing the error type of the exception */
export type ApplicationExceptionExtension = {
  __typename?: 'ApplicationExceptionExtension';
  id?: Maybe<Scalars['ID']['output']>;
};

export enum ApplicationExceptionType {
  AccountDeactivated = 'ACCOUNT_DEACTIVATED',
  AccountSwitchingFailed = 'ACCOUNT_SWITCHING_FAILED',
  InsufficientPermissions = 'INSUFFICIENT_PERMISSIONS',
  ModificationProhibited = 'MODIFICATION_PROHIBITED',
  SessionExpired = 'SESSION_EXPIRED',
  SessionInvalid = 'SESSION_INVALID',
  SessionTimingOut = 'SESSION_TIMING_OUT'
}

export type Appointment = AnyNode & Badgeable & {
  __typename?: 'Appointment';
  account?: Maybe<Account>;
  allowAppointmentCreatedEmail?: Maybe<Scalars['Boolean']['output']>;
  allowAppointmentCreatedSms?: Maybe<Scalars['Boolean']['output']>;
  allowConfirmationEmail?: Maybe<Scalars['Boolean']['output']>;
  allowConfirmationSms?: Maybe<Scalars['Boolean']['output']>;
  allowReminderEmail?: Maybe<Scalars['Boolean']['output']>;
  allowReminderSms?: Maybe<Scalars['Boolean']['output']>;
  /** Concatenated notes from the appointment. Only use if you really need it, can be big. */
  appNotes?: Maybe<Scalars['String']['output']>;
  appointmentCreatedTemplate?: Maybe<CommunicationTemplate>;
  appointmentCreatedTemplateId?: Maybe<Scalars['ID']['output']>;
  appointmentGuests?: Maybe<Array<AppointmentGuest>>;
  appointmentItems?: Maybe<Array<AppointmentItem>>;
  appointmentReason?: Maybe<AppointmentReason>;
  appointmentReasonId?: Maybe<Scalars['ID']['output']>;
  /**
   * Name of the appointment reason selected for this appointment
   * @deprecated Use title intead
   */
  appointmentReasonName?: Maybe<Scalars['String']['output']>;
  appointmentUpdatedTemplate?: Maybe<CommunicationTemplate>;
  appointmentUpdatedTemplateId?: Maybe<Scalars['ID']['output']>;
  arrivedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  /** Determines if items are automatically invoiced once the appointment is marked as completed. This is disabled. */
  autoInvoice?: Maybe<Scalars['Boolean']['output']>;
  /** Returns just the slugs of the badges assigned to this appointment */
  badgeSlugs?: Maybe<Array<Scalars['String']['output']>>;
  /** Badges assigned to this directory */
  badges?: Maybe<Array<BadgesBadgeLink>>;
  batchSize?: Maybe<Scalars['Int']['output']>;
  billableItems?: Maybe<Array<AppointmentItem>>;
  /** Total amount of billables ready to invoice */
  billablesReadyToInvoiceTotal?: Maybe<Scalars['Float']['output']>;
  /** Cancellation reason */
  cancellationReason?: Maybe<CancellationReason>;
  /** ID of the cancellation reason */
  cancellationReasonId?: Maybe<Scalars['ID']['output']>;
  cancelledAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  /** The chosen colour to show for the appointment */
  colour?: Maybe<Scalars['String']['output']>;
  completedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  confirmationTemplate?: Maybe<CommunicationTemplate>;
  confirmationTemplateId?: Maybe<Scalars['ID']['output']>;
  /** The date and time the appointment was confirmed */
  confirmedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  confirmedGuestCount: Scalars['Int']['output'];
  createdAt: Scalars['ISO8601DateTime']['output'];
  dailyEmailBudget?: Maybe<Scalars['Int']['output']>;
  dailySmsBudget?: Maybe<Scalars['Int']['output']>;
  /** Total number of deposit requests completed */
  depositRequestsCompletedCount?: Maybe<Scalars['Int']['output']>;
  /** Total number of deposit requests in progress */
  depositRequestsInProgressCount?: Maybe<Scalars['Int']['output']>;
  /**
   * Appointment description. Not used
   * @deprecated Use title instead
   */
  description?: Maybe<Scalars['String']['output']>;
  discardedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  /** Duraction in words. e.g 30 minutes */
  duration?: Maybe<Scalars['String']['output']>;
  emailMessage?: Maybe<Scalars['String']['output']>;
  /** The date and time the appointment was ended */
  endTime?: Maybe<Scalars['ISO8601DateTime']['output']>;
  /**
   * Estimated minutes by first checking the minutes on the appointment, then
   * falling back to the sum of the patient record items.
   */
  estimatedMinutes?: Maybe<Scalars['Int']['output']>;
  finishedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  groupMaxSize?: Maybe<Scalars['Int']['output']>;
  groupMinSize?: Maybe<Scalars['Int']['output']>;
  /** Array of guest emails */
  guestContactEmails?: Maybe<Array<Scalars['String']['output']>>;
  /** Array of guest numbers */
  guestContactNumbers?: Maybe<Array<Scalars['String']['output']>>;
  /** Array of guest IDs */
  guestIds?: Maybe<Array<Scalars['ID']['output']>>;
  /** Array of guest names */
  guestNames?: Maybe<Array<Scalars['String']['output']>>;
  /** UUID field to identify this record. Will be replacing ID */
  guid: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  invoices?: Maybe<Array<Invoice>>;
  isCommunicationDelivered?: Maybe<Scalars['Boolean']['output']>;
  isCommunicationFailed?: Maybe<Scalars['Boolean']['output']>;
  isConfirmationRequired?: Maybe<Scalars['Boolean']['output']>;
  isConfirmed?: Maybe<Scalars['Boolean']['output']>;
  /** Is this the first visit for the patient */
  isFirstVisit?: Maybe<Scalars['Boolean']['output']>;
  /** Group appointments are for multiple guests. E.g. We dont do group appointments yet */
  isGroupAppointment?: Maybe<Scalars['Boolean']['output']>;
  /** Basic appointments with single guests. E.g. Consultation for Patient */
  isGuestAppointment?: Maybe<Scalars['Boolean']['output']>;
  /** Is this appointment online booking? */
  isOnlineBooking?: Maybe<Scalars['Boolean']['output']>;
  isOnlineMeeting?: Maybe<Scalars['Boolean']['output']>;
  /** Private appointments are just for the UPC and contain no guests. E.g Lunch Break */
  isPrivateAppointment?: Maybe<Scalars['Boolean']['output']>;
  /** Total number of labworks completed (received) assigned to this appointment */
  labworksCompleteCount?: Maybe<Scalars['Int']['output']>;
  /** Total number of labworks assigned to this appointment */
  labworksCount?: Maybe<Scalars['Int']['output']>;
  /** Total number of labworks in progress (at reception) assigned to this appointment */
  labworksInProgressCount?: Maybe<Scalars['Int']['output']>;
  /** Total number of labworks sent assigned to this appointment */
  labworksSentCount?: Maybe<Scalars['Int']['output']>;
  lateCancellationAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  /** The duration of the appointment in minutes */
  minutes?: Maybe<Scalars['Int']['output']>;
  missedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  notes?: Maybe<Scalars['String']['output']>;
  onHoldDate?: Maybe<Scalars['ISO8601DateTime']['output']>;
  /** The display string for how long ago the appointment was on hold */
  onHoldDisplay?: Maybe<Scalars['String']['output']>;
  onHoldExpired?: Maybe<Scalars['Boolean']['output']>;
  onlineBookingCredit?: Maybe<PatientRecordCredit>;
  onlineBookingCreditGuid?: Maybe<Scalars['ID']['output']>;
  onlineMeetingUrl?: Maybe<Scalars['String']['output']>;
  /** Returns true if the patient has current outstanding balance against their account */
  patientHasOutstandingBalance?: Maybe<Scalars['Boolean']['output']>;
  patientNotes?: Maybe<Array<Note>>;
  patientRecord?: Maybe<PatientRecord>;
  patientRecordItems?: Maybe<Array<PatientRecordItem>>;
  policies?: Maybe<AppointmentPolicy>;
  /** Position of the appointment in the treatment plan if part of one */
  position?: Maybe<Scalars['Int']['output']>;
  practice?: Maybe<Practice>;
  /** ID of the practice */
  practiceGuid?: Maybe<Scalars['ID']['output']>;
  /** ID of the practice */
  practiceId?: Maybe<Scalars['ID']['output']>;
  practitioner?: Maybe<UserPracticeLink>;
  /** Name of assigned practitioner */
  practitionerName?: Maybe<Scalars['String']['output']>;
  privateNote?: Maybe<Scalars['String']['output']>;
  /** The date and time the appointment was proposed */
  proposedDate?: Maybe<Scalars['ISO8601DateTime']['output']>;
  readyAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  reason?: Maybe<Scalars['String']['output']>;
  /** The associated patient recall for the Appointment, if one is linked */
  recall?: Maybe<Recall>;
  recallId?: Maybe<Scalars['ID']['output']>;
  reference?: Maybe<Scalars['String']['output']>;
  reminderEmailState?: Maybe<EmailState>;
  reminderSmsState?: Maybe<ShortMessageState>;
  reminderTemplate?: Maybe<CommunicationTemplate>;
  reminderTemplateId?: Maybe<Scalars['ID']['output']>;
  /** Average rating of all review requests for this appointment */
  reviewRequestsAvgRating?: Maybe<Scalars['Float']['output']>;
  /** Total number of review requests completed */
  reviewRequestsCompletedCount?: Maybe<Scalars['Int']['output']>;
  /** Total number of review requests in progress */
  reviewRequestsInProgressCount?: Maybe<Scalars['Int']['output']>;
  /** The review template associated with the appointment */
  reviewTemplate?: Maybe<ReviewTemplate>;
  /** ID of the review template */
  reviewTemplateId?: Maybe<Scalars['ID']['output']>;
  /** The Room that the appointment belongs_to */
  room?: Maybe<Room>;
  /** ID of the room thant the appointment is linked to */
  roomId?: Maybe<Scalars['ID']['output']>;
  scheduledAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  /** Copied from the practice setting on create/update as a reference for scheduling confirmation requests */
  sendConfirmationDaysBefore?: Maybe<Scalars['Int']['output']>;
  /** Copied from the practice setting on create/update as a reference for scheduling reminders */
  sendReminderDaysBefore?: Maybe<Scalars['Int']['output']>;
  slug?: Maybe<Scalars['String']['output']>;
  smartFill?: Maybe<Scalars['Boolean']['output']>;
  smsMessage?: Maybe<Scalars['String']['output']>;
  /** The date and time the appointment was started */
  startTime?: Maybe<Scalars['ISO8601DateTime']['output']>;
  startedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  state: AppointmentState;
  /** Appointment subject line */
  subject?: Maybe<Scalars['String']['output']>;
  tags?: Maybe<Array<Tag>>;
  title?: Maybe<Scalars['String']['output']>;
  /** Gets the total cost of an Appointment (the sum of all its billable items) */
  totalCost?: Maybe<Scalars['Float']['output']>;
  /** Linked treatment */
  treatment?: Maybe<Treatment>;
  /** ID of the linked treatment */
  treatmentId?: Maybe<Scalars['ID']['output']>;
  /** Linked treatment plan */
  treatmentPlan?: Maybe<TreatmentPlan>;
  /** ID of the linked treatment plan */
  treatmentPlanId?: Maybe<Scalars['ID']['output']>;
  updatedAt: Scalars['ISO8601DateTime']['output'];
  userPracticeConnection?: Maybe<UserPracticeLink>;
  /** Only the ID of the UPC, to avoid another db round-trip */
  userPracticeConnectionId?: Maybe<Scalars['ID']['output']>;
  /** Unique identifier of the message */
  uuid: Scalars['String']['output'];
  /** Linked waitlist */
  waitlist?: Maybe<Waitlist>;
  waitlistId?: Maybe<Scalars['Int']['output']>;
};

export type AppointmentCommunicationInput = {
  allowConfirmationEmail?: InputMaybe<Scalars['Boolean']['input']>;
  allowConfirmationSms?: InputMaybe<Scalars['Boolean']['input']>;
  allowReminderEmail?: InputMaybe<Scalars['Boolean']['input']>;
  allowReminderSms?: InputMaybe<Scalars['Boolean']['input']>;
  confirmationTemplateId?: InputMaybe<Scalars['ID']['input']>;
  isConfirmationRequired?: InputMaybe<Scalars['Boolean']['input']>;
  reminderTemplateId?: InputMaybe<Scalars['ID']['input']>;
};

export type AppointmentCommunicationSetting = {
  __typename?: 'AppointmentCommunicationSetting';
  allowConfirmationEmail?: Maybe<Scalars['Boolean']['output']>;
  allowConfirmationSms?: Maybe<Scalars['Boolean']['output']>;
  allowReminderEmail?: Maybe<Scalars['Boolean']['output']>;
  allowReminderSms?: Maybe<Scalars['Boolean']['output']>;
  appointmentGuid?: Maybe<Scalars['ID']['output']>;
  appointmentId?: Maybe<Scalars['ID']['output']>;
  confirmationTemplateId?: Maybe<Scalars['ID']['output']>;
  createTemplateId?: Maybe<Scalars['ID']['output']>;
  guid?: Maybe<Scalars['ID']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  isConfirmationRequired?: Maybe<Scalars['Boolean']['output']>;
  reminderTemplateId?: Maybe<Scalars['ID']['output']>;
  sendCreateEmail?: Maybe<Scalars['Boolean']['output']>;
  sendCreateSms?: Maybe<Scalars['Boolean']['output']>;
  sendUpdateEmail?: Maybe<Scalars['Boolean']['output']>;
  sendUpdateSms?: Maybe<Scalars['Boolean']['output']>;
  startTime?: Maybe<Scalars['ISO8601DateTime']['output']>;
  updateTemplateId?: Maybe<Scalars['ID']['output']>;
};

/** The connection type for Appointment. */
export type AppointmentConnection = {
  __typename?: 'AppointmentConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<AppointmentEdge>>>;
  /** IDs of ALL results, regardless of pagination */
  ids: Array<Scalars['ID']['output']>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Appointment>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Returns total count of items in list */
  totalCount: Scalars['Int']['output'];
};

export type AppointmentCreateInput = {
  allowAppointmentCreatedEmail?: InputMaybe<Scalars['Boolean']['input']>;
  allowAppointmentCreatedSms?: InputMaybe<Scalars['Boolean']['input']>;
  allowConfirmationEmail?: InputMaybe<Scalars['Boolean']['input']>;
  allowConfirmationSms?: InputMaybe<Scalars['Boolean']['input']>;
  allowReminderEmail?: InputMaybe<Scalars['Boolean']['input']>;
  allowReminderSms?: InputMaybe<Scalars['Boolean']['input']>;
  appointmentCreatedTemplateId?: InputMaybe<Scalars['ID']['input']>;
  appointmentReasonId?: InputMaybe<Scalars['ID']['input']>;
  autoConfirmGuests?: InputMaybe<Scalars['Boolean']['input']>;
  autoInvoice?: InputMaybe<Scalars['Boolean']['input']>;
  /** Array of badge slugs to assign to this appointment */
  badges?: InputMaybe<Array<Scalars['String']['input']>>;
  batchSize?: InputMaybe<Scalars['Int']['input']>;
  billableItems?: InputMaybe<Array<BillableItemInput>>;
  colour?: InputMaybe<Scalars['String']['input']>;
  confirmationTemplateId?: InputMaybe<Scalars['ID']['input']>;
  dailyEmailBudget?: InputMaybe<Scalars['Int']['input']>;
  dailySmsBudget?: InputMaybe<Scalars['Int']['input']>;
  deliverEmail?: InputMaybe<Scalars['Boolean']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  emailMessage?: InputMaybe<Scalars['String']['input']>;
  endTime: Scalars['ISO8601DateTime']['input'];
  groupMaxSize?: InputMaybe<Scalars['Int']['input']>;
  groupMinSize?: InputMaybe<Scalars['Int']['input']>;
  guestIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  isOnlineMeeting?: InputMaybe<Scalars['Boolean']['input']>;
  minutes?: InputMaybe<Scalars['Int']['input']>;
  notes?: InputMaybe<Scalars['String']['input']>;
  recallId?: InputMaybe<Scalars['ID']['input']>;
  redeemedPackageIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  reminderTemplateId?: InputMaybe<Scalars['ID']['input']>;
  reviewTemplateId?: InputMaybe<Scalars['ID']['input']>;
  roomId?: InputMaybe<Scalars['ID']['input']>;
  smartFill?: InputMaybe<Scalars['Boolean']['input']>;
  smsMessage?: InputMaybe<Scalars['String']['input']>;
  startTime: Scalars['ISO8601DateTime']['input'];
  subject: Scalars['String']['input'];
  treatmentId?: InputMaybe<Scalars['ID']['input']>;
  treatmentPlanId?: InputMaybe<Scalars['ID']['input']>;
  /** The assigned practitioner */
  userPracticeConnectionId?: InputMaybe<Scalars['ID']['input']>;
};

export type AppointmentDetailedList = {
  __typename?: 'AppointmentDetailedList';
  appointmentGuests?: Maybe<Array<DetailedListAppointmentGuest>>;
  appointmentType?: Maybe<Scalars['String']['output']>;
  duration?: Maybe<Scalars['String']['output']>;
  end?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  patientGuid?: Maybe<Scalars['ID']['output']>;
  patientName?: Maybe<Scalars['String']['output']>;
  patientRecordId?: Maybe<Scalars['ID']['output']>;
  practiceId?: Maybe<Scalars['ID']['output']>;
  practitionerName?: Maybe<Scalars['String']['output']>;
  slug?: Maybe<Scalars['String']['output']>;
  start?: Maybe<Scalars['String']['output']>;
  state?: Maybe<Scalars['String']['output']>;
  subject?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
};

/** An edge in a connection. */
export type AppointmentEdge = {
  __typename?: 'AppointmentEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<Appointment>;
};

export enum AppointmentField {
  /** Sort by created date */
  CreatedAt = 'created_at',
  /** Sort by end time */
  EndTime = 'end_time',
  /** Sort by on hold date */
  OnHoldDate = 'on_hold_date',
  /** Sort by start time */
  StartTime = 'start_time',
  /** Sort by state */
  State = 'state'
}

export type AppointmentGuest = AnyNode & {
  __typename?: 'AppointmentGuest';
  appointment: Appointment;
  arrivedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  confirmedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  createdAt: Scalars['ISO8601DateTime']['output'];
  emailSentAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  guid: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  invitableId?: Maybe<Scalars['ID']['output']>;
  invitableType?: Maybe<Scalars['String']['output']>;
  missedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  patient?: Maybe<UserPracticeLink>;
  rejectedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  smsSentAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  state: AppointmentGuestState;
  stripeCustomerId?: Maybe<Scalars['ID']['output']>;
  token?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['ISO8601DateTime']['output'];
  userPracticeConnection?: Maybe<UserPracticeLink>;
  userPracticeConnectionId?: Maybe<Scalars['ID']['output']>;
  /** Unique identifier of the message */
  uuid: Scalars['String']['output'];
};

/** The connection type for AppointmentGuest. */
export type AppointmentGuestConnection = {
  __typename?: 'AppointmentGuestConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<AppointmentGuestEdge>>>;
  /** IDs of ALL results, regardless of pagination */
  ids: Array<Scalars['ID']['output']>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<AppointmentGuest>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Returns total count of items in list */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type AppointmentGuestEdge = {
  __typename?: 'AppointmentGuestEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<AppointmentGuest>;
};

export type AppointmentGuestInput = {
  autoConfirm?: InputMaybe<Scalars['Boolean']['input']>;
  deliverEmail?: InputMaybe<Scalars['Boolean']['input']>;
  /** UserPracticeConnection IDs to add as guests */
  userPracticeConnectionIds?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export enum AppointmentGuestState {
  Arrived = 'arrived',
  Confirmed = 'confirmed',
  Invited = 'invited',
  Missed = 'missed',
  Rejected = 'rejected',
  Removed = 'removed'
}

export type AppointmentIconConfiguration = {
  __typename?: 'AppointmentIconConfiguration';
  communicationsFailed?: Maybe<Icon>;
  confirmed?: Maybe<Icon>;
  depositPaid?: Maybe<Icon>;
  depositPending?: Maybe<Icon>;
  firstVisit?: Maybe<Icon>;
  groupAppointment?: Maybe<Icon>;
  labworkComplete?: Maybe<Icon>;
  labworkInProgress?: Maybe<Icon>;
  labworkSent?: Maybe<Icon>;
  notes?: Maybe<Icon>;
  onlineBookingCredit?: Maybe<Icon>;
  onlineBookings?: Maybe<Icon>;
  onlineMeetingLink?: Maybe<Icon>;
  outstandingBalance?: Maybe<Icon>;
  recallLinked?: Maybe<Icon>;
  smartFill?: Maybe<Icon>;
};

export type AppointmentIconConfigurationInput = {
  communicationsFailed?: InputMaybe<Icon>;
  confirmed?: InputMaybe<Icon>;
  depositPaid?: InputMaybe<Icon>;
  depositPending?: InputMaybe<Icon>;
  firstVisit?: InputMaybe<Icon>;
  groupAppointment?: InputMaybe<Icon>;
  labworkComplete?: InputMaybe<Icon>;
  labworkInProgress?: InputMaybe<Icon>;
  labworkSent?: InputMaybe<Icon>;
  notes?: InputMaybe<Icon>;
  onlineBookingCredit?: InputMaybe<Icon>;
  onlineBookings?: InputMaybe<Icon>;
  onlineMeetingLink?: InputMaybe<Icon>;
  outstandingBalance?: InputMaybe<Icon>;
  recallLinked?: InputMaybe<Icon>;
  smartFill?: InputMaybe<Icon>;
};

export type AppointmentInput = {
  appointmentReasonId?: InputMaybe<Scalars['ID']['input']>;
  autoInvoice?: InputMaybe<Scalars['Boolean']['input']>;
  /** Array of badge slugs to assign to this appointment */
  badges?: InputMaybe<Array<Scalars['String']['input']>>;
  billableItems?: InputMaybe<Array<BillableItemInput>>;
  cancellationReasonId?: InputMaybe<Scalars['ID']['input']>;
  colour?: InputMaybe<Scalars['String']['input']>;
  confirmationTemplateId?: InputMaybe<Scalars['ID']['input']>;
  emailMessage?: InputMaybe<Scalars['String']['input']>;
  endTime?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
  isConfirmed?: InputMaybe<Scalars['Boolean']['input']>;
  isOnlineMeeting?: InputMaybe<Scalars['Boolean']['input']>;
  minutes?: InputMaybe<Scalars['Int']['input']>;
  notes?: InputMaybe<Scalars['String']['input']>;
  /** Position of the appointment in the treatment plan if part of one */
  position?: InputMaybe<Scalars['Int']['input']>;
  privateNote?: InputMaybe<Scalars['String']['input']>;
  recallId?: InputMaybe<Scalars['ID']['input']>;
  reminderTemplateId?: InputMaybe<Scalars['ID']['input']>;
  /** Remove badges that are not being passed in */
  removeOldBadges?: InputMaybe<Scalars['Boolean']['input']>;
  reviewTemplateId?: InputMaybe<Scalars['ID']['input']>;
  roomId?: InputMaybe<Scalars['ID']['input']>;
  /**
   * If passed, will send an email with the template to all active guests.
   * This is useful when you want to update the appointment and also send an appointment_updated email.
   * Or if you want to transition to cancelled and send a cancellation email.
   */
  sendEmailTemplateId?: InputMaybe<Scalars['ID']['input']>;
  /** Send immediate email to guests on update */
  sendImmediateEmail?: InputMaybe<Scalars['Boolean']['input']>;
  /** Send immediate SMS to guests on update */
  sendImmediateSms?: InputMaybe<Scalars['Boolean']['input']>;
  smsMessage?: InputMaybe<Scalars['String']['input']>;
  staffToggleGuest?: InputMaybe<Scalars['Boolean']['input']>;
  startTime?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
  state?: InputMaybe<AppointmentStateEvent>;
  subject?: InputMaybe<Scalars['String']['input']>;
  treatmentId?: InputMaybe<Scalars['ID']['input']>;
  treatmentPlanId?: InputMaybe<Scalars['ID']['input']>;
  unlinkTreatmentPlan?: InputMaybe<Scalars['Boolean']['input']>;
  userPracticeConnectionId?: InputMaybe<Scalars['ID']['input']>;
};

export type AppointmentItem = {
  __typename?: 'AppointmentItem';
  appointmentReference?: Maybe<Scalars['String']['output']>;
  catalogueItem?: Maybe<CatalogueItem>;
  catalogueItemId?: Maybe<Scalars['ID']['output']>;
  declinedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  /** Discount percentage */
  discount?: Maybe<Scalars['Float']['output']>;
  id: Scalars['ID']['output'];
  /** Delegates to CatalogueItem#code */
  itemCode?: Maybe<Scalars['String']['output']>;
  itemName?: Maybe<Scalars['String']['output']>;
  itemNameAndCode?: Maybe<Scalars['String']['output']>;
  key: Scalars['Int']['output'];
  minutes?: Maybe<Scalars['Int']['output']>;
  practiceId?: Maybe<Scalars['ID']['output']>;
  practiceProfessionalId?: Maybe<Scalars['ID']['output']>;
  practiceProfessionalName?: Maybe<Scalars['String']['output']>;
  practitioner?: Maybe<Scalars['String']['output']>;
  /** Base price per item ex tax */
  price?: Maybe<Scalars['Float']['output']>;
  /** Single price excluding tax */
  priceExTax?: Maybe<Scalars['Float']['output']>;
  /** Single price including tax */
  priceIncTax?: Maybe<Scalars['Float']['output']>;
  /** Number of items */
  quantity?: Maybe<Scalars['Int']['output']>;
  readyToInvoiceAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  /** Tax percentage */
  tax?: Maybe<Scalars['Float']['output']>;
  /** Total price including tax */
  total?: Maybe<Scalars['Float']['output']>;
  /** Total discount applied */
  totalDiscount?: Maybe<Scalars['Float']['output']>;
  /** Total price excluding tax */
  totalExTax?: Maybe<Scalars['Float']['output']>;
  /** Total tax applied */
  totalTax?: Maybe<Scalars['Float']['output']>;
  treatment?: Maybe<Treatment>;
  treatmentSlug?: Maybe<Scalars['String']['output']>;
  treatmentTitle?: Maybe<Scalars['String']['output']>;
};

export type AppointmentOrderInput = {
  direction?: InputMaybe<Direction>;
  field?: InputMaybe<AppointmentField>;
};

export type AppointmentPolicy = {
  __typename?: 'AppointmentPolicy';
  create?: Maybe<Scalars['Boolean']['output']>;
  destroy?: Maybe<Scalars['Boolean']['output']>;
  discard?: Maybe<Scalars['Boolean']['output']>;
  edit?: Maybe<Scalars['Boolean']['output']>;
  index?: Maybe<Scalars['Boolean']['output']>;
  new?: Maybe<Scalars['Boolean']['output']>;
  show?: Maybe<Scalars['Boolean']['output']>;
  update?: Maybe<Scalars['Boolean']['output']>;
};

export type AppointmentReason = {
  __typename?: 'AppointmentReason';
  allowSmsConfirmation?: Maybe<Scalars['Boolean']['output']>;
  autoInvoiceItems?: Maybe<Scalars['Boolean']['output']>;
  catalogueItemAppointmentReasons?: Maybe<Array<CatalogueItemAppointmentReason>>;
  catalogueItems?: Maybe<Array<CatalogueItem>>;
  colour?: Maybe<Scalars['String']['output']>;
  confirmationTemplate?: Maybe<CommunicationTemplate>;
  coverPhoto?: Maybe<CoverPhotoUploaderAwsLinks>;
  createdAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  createdTemplate?: Maybe<CommunicationTemplate>;
  /** Amount of deposit required for online bookings. Stored as cents. */
  depositAmount?: Maybe<Scalars['Int']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  guid: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  minutes?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  onlineBookingEnabled?: Maybe<Scalars['Boolean']['output']>;
  practitioners?: Maybe<Array<UserPracticeLink>>;
  reminderTemplate?: Maybe<CommunicationTemplate>;
  /** Whether a deposit is required for online bookings */
  requireDeposit?: Maybe<Scalars['Boolean']['output']>;
  /** The review template associated with the appointment */
  reviewTemplate?: Maybe<ReviewTemplate>;
  /** ID of the review template */
  reviewTemplateId?: Maybe<Scalars['ID']['output']>;
  /** Show the total cost for Online Bookings, When enabled, will show the total cost of the service  */
  showTotalCost?: Maybe<Scalars['Boolean']['output']>;
  state?: Maybe<AppointmentReasonState>;
  updatedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  updatedTemplate?: Maybe<CommunicationTemplate>;
  visible?: Maybe<AppointmentReasonVisibility>;
};

/** The connection type for AppointmentReason. */
export type AppointmentReasonConnection = {
  __typename?: 'AppointmentReasonConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<AppointmentReasonEdge>>>;
  /** IDs of ALL results, regardless of pagination */
  ids: Array<Scalars['ID']['output']>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<AppointmentReason>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Returns total count of items in list */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type AppointmentReasonEdge = {
  __typename?: 'AppointmentReasonEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<AppointmentReason>;
};

export type AppointmentReasonInput = {
  catalogueItems?: InputMaybe<Array<CatalogueAppointmentReasonInput>>;
  colour?: InputMaybe<Scalars['String']['input']>;
  confirmationTemplateId?: InputMaybe<Scalars['ID']['input']>;
  coverPhoto?: InputMaybe<Scalars['String']['input']>;
  createdTemplateId?: InputMaybe<Scalars['ID']['input']>;
  /** Amount of deposit required for online bookings. Stored as cents. */
  depositAmount?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  minutes?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  onlineBookingEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  /** Link to these practitioner UPCs */
  practitionerIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  reminderTemplateId?: InputMaybe<Scalars['ID']['input']>;
  removeCoverPhoto?: InputMaybe<Scalars['Boolean']['input']>;
  /** Whether a deposit is required for online bookings */
  requireDeposit?: InputMaybe<Scalars['Boolean']['input']>;
  reviewTemplateId?: InputMaybe<Scalars['ID']['input']>;
  /** Whether to show the total cost of the appointment for online bookings */
  showTotalCost?: InputMaybe<Scalars['Boolean']['input']>;
  state?: InputMaybe<AppointmentReasonState>;
  updatedTemplateId?: InputMaybe<Scalars['ID']['input']>;
  visible?: InputMaybe<AppointmentReasonVisibility>;
};

export enum AppointmentReasonState {
  Active = 'active',
  Archived = 'archived'
}

export enum AppointmentReasonVisibility {
  All = 'all',
  Appointments = 'appointments',
  TreatmentPlans = 'treatment_plans'
}

export type AppointmentRescheduleInput = {
  allowConfirmationEmail?: InputMaybe<Scalars['Boolean']['input']>;
  allowConfirmationSms?: InputMaybe<Scalars['Boolean']['input']>;
  allowReminderEmail?: InputMaybe<Scalars['Boolean']['input']>;
  allowReminderSms?: InputMaybe<Scalars['Boolean']['input']>;
  autoConfirmGuests?: InputMaybe<Scalars['Boolean']['input']>;
  /** Array of badge slugs to assign to this appointment */
  badges?: InputMaybe<Array<Scalars['String']['input']>>;
  colour?: InputMaybe<Scalars['String']['input']>;
  confirmationTemplateId?: InputMaybe<Scalars['ID']['input']>;
  endTime?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
  guid?: InputMaybe<Scalars['ID']['input']>;
  /** The ID of the appointment to reschedule */
  id: Scalars['ID']['input'];
  isConfirmationRequired?: InputMaybe<Scalars['Boolean']['input']>;
  reminderTemplateId?: InputMaybe<Scalars['ID']['input']>;
  /** Remove badges that are not being passed in */
  removeOldBadges?: InputMaybe<Scalars['Boolean']['input']>;
  roomId?: InputMaybe<Scalars['ID']['input']>;
  /**
   * If passed, will send an email with the template to all active guests.
   * This is useful when you want to update the appointment and also send an appointment_updated email.
   * Or if you want to transition to cancelled and send a cancellation email.
   */
  sendEmailTemplateId?: InputMaybe<Scalars['ID']['input']>;
  sendUpdateEmail?: InputMaybe<Scalars['Boolean']['input']>;
  sendUpdateSms?: InputMaybe<Scalars['Boolean']['input']>;
  startTime?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
  subject?: InputMaybe<Scalars['String']['input']>;
  userPracticeConnectionId?: InputMaybe<Scalars['ID']['input']>;
};

export enum AppointmentState {
  Arrived = 'arrived',
  Cancelled = 'cancelled',
  Completed = 'completed',
  Draft = 'draft',
  LateCancellation = 'late_cancellation',
  Missed = 'missed',
  OnHold = 'on_hold',
  Paused = 'paused',
  PractitionerIsFinished = 'practitioner_is_finished',
  PractitionerIsReady = 'practitioner_is_ready',
  ReadyToBook = 'ready_to_book',
  Scheduled = 'scheduled',
  Started = 'started',
  Waiting = 'waiting'
}

export enum AppointmentStateEvent {
  Arrived = 'arrived',
  Cancel = 'cancel',
  Complete = 'complete',
  Hold = 'hold',
  LateCancellation = 'late_cancellation',
  Miss = 'miss',
  NextStep = 'next_step',
  PatientReady = 'patient_ready',
  Pause = 'pause',
  PractitionerReady = 'practitioner_ready',
  PreviousStep = 'previous_step',
  Ready = 'ready',
  RevertToDraft = 'revert_to_draft',
  Schedule = 'schedule',
  Start = 'start',
  Started = 'started',
  Waiting = 'waiting'
}

export type AppointmentSummary = {
  __typename?: 'AppointmentSummary';
  cancelled: Scalars['Int']['output'];
  completed: Scalars['Int']['output'];
  inProgress: Scalars['Int']['output'];
  lateCancellation: Scalars['Int']['output'];
  missed: Scalars['Int']['output'];
  scheduled: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
  totalBooked: Scalars['Int']['output'];
  unconfirmed: Scalars['Int']['output'];
};

export type AppointmentSummaryList = {
  __typename?: 'AppointmentSummaryList';
  count?: Maybe<Scalars['Int']['output']>;
  state?: Maybe<AppointmentState>;
};

export enum AppointmentTimePeriods {
  /** Future bookings, not including today */
  Future = 'future',
  /** Past appointments */
  Past = 'past',
  /** Todays appointments */
  Today = 'today',
  /** Future bookings including today and excluding canceled and completed */
  Upcoming = 'upcoming'
}

/** Autogenerated return type of AppointmentUpdated. */
export type AppointmentUpdatedPayload = {
  __typename?: 'AppointmentUpdatedPayload';
  appointment?: Maybe<Appointment>;
};

/** Autogenerated input type of ArchiveAttachment */
export type ArchiveAttachmentInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of ArchiveAttachment. */
export type ArchiveAttachmentPayload = {
  __typename?: 'ArchiveAttachmentPayload';
  attachment?: Maybe<Attachment>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  fieldErrors?: Maybe<Array<FieldError>>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

/** Autogenerated input type of ArchiveAttachments */
export type ArchiveAttachmentsInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  ids: Array<Scalars['ID']['input']>;
};

/** Autogenerated return type of ArchiveAttachments. */
export type ArchiveAttachmentsPayload = {
  __typename?: 'ArchiveAttachmentsPayload';
  attachments?: Maybe<Array<Attachment>>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  directories?: Maybe<Array<Directory>>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  fieldErrors?: Maybe<Array<FieldError>>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

/** Autogenerated input type of ArchiveCustomerRequest */
export type ArchiveCustomerRequestInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Customer Request ID to archive */
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of ArchiveCustomerRequest. */
export type ArchiveCustomerRequestPayload = {
  __typename?: 'ArchiveCustomerRequestPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  customerRequest?: Maybe<CustomerRequest>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  fieldErrors?: Maybe<Array<FieldError>>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

/** Autogenerated input type of AssignAlert */
export type AssignAlertInput = {
  alertId: Scalars['ID']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** ID of patient to assign alert to */
  connectionId: Scalars['ID']['input'];
};

/** Autogenerated return type of AssignAlert. */
export type AssignAlertPayload = {
  __typename?: 'AssignAlertPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  connectionAlert?: Maybe<UserPracticeConnectionAlert>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  fieldErrors?: Maybe<Array<FieldError>>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

/** Autogenerated input type of AssignAppointmentReasons */
export type AssignAppointmentReasonsInput = {
  appointmentReasonIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  practitioner: Scalars['ID']['input'];
};

/** Autogenerated return type of AssignAppointmentReasons. */
export type AssignAppointmentReasonsPayload = {
  __typename?: 'AssignAppointmentReasonsPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  fieldErrors?: Maybe<Array<FieldError>>;
  success?: Maybe<Scalars['Boolean']['output']>;
  userPracticeConnection?: Maybe<UserPracticeLink>;
};

/** Autogenerated input type of AssignPrice */
export type AssignPriceInput = {
  attributes: PriceAssignInput;
  catalogueItem: Scalars['ID']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  priceList: Scalars['ID']['input'];
};

/** Autogenerated return type of AssignPrice. */
export type AssignPricePayload = {
  __typename?: 'AssignPricePayload';
  catalogueItem?: Maybe<CatalogueItem>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  fieldErrors?: Maybe<Array<FieldError>>;
  price?: Maybe<Price>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type Attachment = AnyNode & Badgeable & {
  __typename?: 'Attachment';
  /** Attachment content */
  attachment?: Maybe<FileUploaderAwsLinks>;
  /** Badges assigned to this directory */
  badges?: Maybe<Array<BadgesBadgeLink>>;
  /** When the attachment was created */
  createdAt: Scalars['ISO8601DateTime']['output'];
  /** Attachment description */
  description?: Maybe<Scalars['String']['output']>;
  /** Directory attachment is stored in */
  directory?: Maybe<Directory>;
  /** Directory ID */
  directoryId?: Maybe<Scalars['ID']['output']>;
  /** When the attachment was discarded */
  discardedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  /** Can this document be edited? */
  editable: Scalars['Boolean']['output'];
  /** File extension e.g */
  fileExtension?: Maybe<Scalars['String']['output']>;
  /** Size of the file in bytes */
  filesize?: Maybe<Scalars['Int']['output']>;
  /** GUID of the attachment */
  guid: Scalars['ID']['output'];
  /** UUID based on GUID */
  guidUuid: Scalars['ID']['output'];
  /** ID of the attachment */
  id: Scalars['ID']['output'];
  /** Is the attachment an image? */
  isImage?: Maybe<Scalars['Boolean']['output']>;
  /** Mime type of Attachment e.g image/png */
  mimeType?: Maybe<Scalars['String']['output']>;
  /** Name of Attachment */
  name?: Maybe<Scalars['String']['output']>;
  /** The patient that this attachment is for */
  patient?: Maybe<UserPracticeLink>;
  /** The practitioner that created this attachment */
  practitioner?: Maybe<UserPracticeLink>;
  /** When the attachment was processed */
  processedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  /** Boolean flag indicating that the file is being processed */
  processing?: Maybe<Scalars['Boolean']['output']>;
  /** Scope of the attachment */
  scope?: Maybe<Scalars['String']['output']>;
  /** Short URL for the attachment */
  shortUrl?: Maybe<Scalars['String']['output']>;
  /** Tags associated with the attachment */
  tags?: Maybe<Array<Tag>>;
  /** Text content of the attachment if the attachment is a text/html or text/plain attachment */
  textContent?: Maybe<Scalars['String']['output']>;
  /** When the attachment was last updated */
  updatedAt: Scalars['ISO8601DateTime']['output'];
  /** Unique identifier of the message */
  uuid: Scalars['String']['output'];
  /** Whether this record is available to staff, or also to patients */
  visibility?: Maybe<VisibilityDefinition>;
};

/** The connection type for Attachment. */
export type AttachmentConnection = {
  __typename?: 'AttachmentConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<AttachmentEdge>>>;
  /** IDs of ALL results, regardless of pagination */
  ids: Array<Scalars['ID']['output']>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Attachment>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Returns total count of items in list */
  totalCount: Scalars['Int']['output'];
};

/** Attachment attributes that are concern item metadata rather than direct file properties */
export type AttachmentCreateInput = {
  /** Badge slugs to apply to the uploaded file */
  badges?: InputMaybe<Array<Scalars['String']['input']>>;
  /**
   * Content type of the attachment — if no directory is given, then this is used
   * to determine the directory to store the attachment in
   */
  contentType?: InputMaybe<DocumentContentType>;
  /** Description of Attachment */
  description?: InputMaybe<Scalars['String']['input']>;
  /** Directory to store the attachment in */
  directory?: InputMaybe<Scalars['ID']['input']>;
  /** Attachment content */
  file?: InputMaybe<Scalars['Upload']['input']>;
  /** File type */
  fileType: Scalars['String']['input'];
  /** Name of Attachment */
  name: Scalars['String']['input'];
  /** Find or create a subdirectory of the given directory ID to store the attachment in */
  subdirectory?: InputMaybe<Scalars['String']['input']>;
  /** Text content */
  textContent?: InputMaybe<Scalars['String']['input']>;
  /** Record to add attachment to */
  uuid: Scalars['String']['input'];
  /** Badge slugs to apply to the uploaded file */
  visibility?: InputMaybe<Visibility>;
};

/** An edge in a connection. */
export type AttachmentEdge = {
  __typename?: 'AttachmentEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<Attachment>;
};

/** Attachment attributes that are concern item metadata rather than direct file properties */
export type AttachmentInput = {
  /** Badge slugs to apply to the uploaded file */
  badges?: InputMaybe<Array<Scalars['String']['input']>>;
  /**
   * Content type of the attachment — if no directory is given, then this is used
   * to determine the directory to store the attachment in
   */
  contentType?: InputMaybe<DocumentContentType>;
  /** Description of Attachment */
  description?: InputMaybe<Scalars['String']['input']>;
  /** Directory to store the attachment in */
  directory?: InputMaybe<Scalars['ID']['input']>;
  /** Attachment content */
  file?: InputMaybe<Scalars['Upload']['input']>;
  /** Name of Attachment */
  name: Scalars['String']['input'];
  /** Find or create a subdirectory of the given directory ID to store the attachment in */
  subdirectory?: InputMaybe<Scalars['String']['input']>;
  /** Text content */
  textContent?: InputMaybe<Scalars['String']['input']>;
  /** Badge slugs to apply to the uploaded file */
  visibility?: InputMaybe<Visibility>;
};

/** Attachment attributes that are concern item metadata rather than direct file properties */
export type AttachmentMetaInput = {
  /** Badge slugs to apply to the uploaded file */
  badges?: InputMaybe<Array<Scalars['String']['input']>>;
  /**
   * Content type of the attachment — if no directory is given, then this is used
   * to determine the directory to store the attachment in
   */
  contentType?: InputMaybe<DocumentContentType>;
  /** Directory to store the attachment in */
  directory?: InputMaybe<Scalars['ID']['input']>;
  /** Badge slugs to apply to the uploaded file */
  visibility?: InputMaybe<Visibility>;
};

/** An attachment to an email, e.g a Documents::Attachment or Documents::RichText */
export type AttachmentProxy = {
  __typename?: 'AttachmentProxy';
  /** File extension of the MIME type */
  fileExtension?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  /** MIME type of the attachment */
  mimeType?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  /** URL to download attachment */
  url?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated return type of AttachmentUpdated. */
export type AttachmentUpdatedPayload = {
  __typename?: 'AttachmentUpdatedPayload';
  attachment?: Maybe<Attachment>;
};

export type AuthenticatorCodeAttributes = {
  /** Email/Username of the user */
  identifier?: InputMaybe<Scalars['String']['input']>;
  otpMethod: OtpMethod;
  /** Password of the user */
  password?: InputMaybe<Scalars['String']['input']>;
};

export type Avatar = {
  __typename?: 'Avatar';
  id: Scalars['ID']['output'];
  /** AWS URL for the medium processed version of this file */
  medium?: Maybe<Scalars['String']['output']>;
  /** AWS URL for the small processed version of this file */
  small?: Maybe<Scalars['String']['output']>;
  url: Scalars['String']['output'];
};

/** Autogenerated return type of AvatarUpdated. */
export type AvatarUpdatedPayload = {
  __typename?: 'AvatarUpdatedPayload';
  avatar?: Maybe<Avatar>;
};

/** Autogenerated input type of BackdateInvoiceTransaction */
export type BackdateInvoiceTransactionInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The date you want to update the transaction to. This will reallocate payments if necessary. */
  date: Scalars['ISO8601Date']['input'];
  /** ID of the invoice transaction */
  invoiceTransaction: Scalars['ID']['input'];
};

/** Autogenerated return type of BackdateInvoiceTransaction. */
export type BackdateInvoiceTransactionPayload = {
  __typename?: 'BackdateInvoiceTransactionPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  fieldErrors?: Maybe<Array<FieldError>>;
  invoiceTransactions?: Maybe<Array<InvoiceTransaction>>;
  paymentTransaction?: Maybe<PaymentTransaction>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

/** Models that can be linked to a badge */
export type Badgeable = {
  /** Badges assigned to this directory */
  badges?: Maybe<Array<BadgesBadgeLink>>;
};

/**
 * The link between a badge template and a badgeable object.\nA badgeable object
 * could be anything from an Appointment, Treatment Plan or Patient.
 */
export type BadgesBadgeLink = {
  __typename?: 'BadgesBadgeLink';
  badgeable?: Maybe<Badgeable>;
  badgeableUuid?: Maybe<Scalars['String']['output']>;
  /** When this record was created */
  createdAt: Scalars['ISO8601DateTime']['output'];
  /** When this record was soft deleted */
  discardedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  /** The ID of the badge template */
  id: Scalars['ID']['output'];
  /** The practice that this cycle type resides under */
  practice?: Maybe<Practice>;
  /** The GUID for the practice */
  practiceGuid?: Maybe<Scalars['String']['output']>;
  /** The practitioner that created this cycle type */
  practitioner?: Maybe<UserPracticeLink>;
  /** The GUID for the pracititoner that created this template */
  practitionerGuid?: Maybe<Scalars['String']['output']>;
  /** The template linked to this badge link */
  template?: Maybe<BadgesTemplate>;
  /** The ID of the template linked to this badge link */
  templateId: Scalars['ID']['output'];
  /** When this record was last updated */
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

/** The connection type for BadgesBadgeLink. */
export type BadgesBadgeLinkConnection = {
  __typename?: 'BadgesBadgeLinkConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<BadgesBadgeLinkEdge>>>;
  /** IDs of ALL results, regardless of pagination */
  ids: Array<Scalars['ID']['output']>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<BadgesBadgeLink>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Returns total count of items in list */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type BadgesBadgeLinkEdge = {
  __typename?: 'BadgesBadgeLinkEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<BadgesBadgeLink>;
};

export type BadgesBadgeLinkInput = {
  /** GraphQL UUID (base64(ClassName:ID)) of the object to attach the badge to */
  badgeable: Array<Scalars['ID']['input']>;
  /** Array of slugs to create badges for */
  slugs?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** A badge template is a template for a badge that can be linked to a badgeable object.\nThese are unique to a practice. */
export type BadgesTemplate = {
  __typename?: 'BadgesTemplate';
  /** The number of badges in use with this template */
  badgeCount: Scalars['Int']['output'];
  /** The badges linked to this template */
  badges?: Maybe<BadgesBadgeLinkConnection>;
  /** The HEX colour code for the badge */
  colour: Scalars['String']['output'];
  /** When this record was created */
  createdAt: Scalars['ISO8601DateTime']['output'];
  /** When this record was soft deleted */
  discardedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  /** The icon of the badge */
  icon: Icon;
  /** The ID of the badge template */
  id: Scalars['ID']['output'];
  /** The name of the badge */
  name: Scalars['String']['output'];
  /** The practice that this cycle type resides under */
  practice?: Maybe<Practice>;
  /** The GUID for the practice */
  practiceGuid?: Maybe<Scalars['String']['output']>;
  /** The practitioner that created this cycle type */
  practitioner?: Maybe<UserPracticeLink>;
  /** The GUID for the pracititoner that created this template */
  practitionerGuid?: Maybe<Scalars['String']['output']>;
  /** The unqiue slug of the badge */
  slug: Scalars['String']['output'];
  /** The type of the badge template */
  templateType: BadgesTemplateType;
  /** When this record was last updated */
  updatedAt: Scalars['ISO8601DateTime']['output'];
};


/** A badge template is a template for a badge that can be linked to a badgeable object.\nThese are unique to a practice. */
export type BadgesTemplateBadgesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** The connection type for BadgesTemplate. */
export type BadgesTemplateConnection = {
  __typename?: 'BadgesTemplateConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<BadgesTemplateEdge>>>;
  /** IDs of ALL results, regardless of pagination */
  ids: Array<Scalars['ID']['output']>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<BadgesTemplate>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Returns total count of items in list */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type BadgesTemplateEdge = {
  __typename?: 'BadgesTemplateEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<BadgesTemplate>;
};

export type BadgesTemplateInput = {
  /** Pass True/False if you want to archive or not */
  archive?: InputMaybe<Scalars['Boolean']['input']>;
  /** The HEX colour string of the badge */
  colour?: InputMaybe<Scalars['String']['input']>;
  /** The icon to use for the badge template */
  icon?: InputMaybe<Scalars['String']['input']>;
  /** The name of the badge template */
  name?: InputMaybe<Scalars['String']['input']>;
  /** The template type to assign this template to */
  templateType?: InputMaybe<BadgesTemplateType>;
};

export enum BadgesTemplateType {
  Appointment = 'appointment',
  Attachment = 'attachment',
  Document = 'document',
  Imaging = 'imaging'
}

export type BadgesTemplateTypeTranslation = {
  __typename?: 'BadgesTemplateTypeTranslation';
  deprecated?: Maybe<Scalars['Boolean']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  type: BadgesTemplateType;
};

export type Base64File = {
  __typename?: 'Base64File';
  /** Base64 file content */
  data: Scalars['String']['output'];
  fileType: Scalars['String']['output'];
  filename: Scalars['String']['output'];
  /** Size of file in bytes */
  filesize: Scalars['Int']['output'];
  /** ID field structured as Pdf:Invoice:id */
  id: Scalars['String']['output'];
};

export type BidirectionalChart = {
  __typename?: 'BidirectionalChart';
  paid?: Maybe<Scalars['Float']['output']>;
  refund?: Maybe<Scalars['Float']['output']>;
  type?: Maybe<Scalars['String']['output']>;
};

export type BillableItemInput = {
  catalogueItemId: Scalars['ID']['input'];
  discount?: InputMaybe<Scalars['Float']['input']>;
  /** If an existing ID is provided, update this item instead */
  id?: InputMaybe<Scalars['ID']['input']>;
  minutes?: InputMaybe<Scalars['Int']['input']>;
  price?: InputMaybe<Scalars['Float']['input']>;
  priceIncTax?: InputMaybe<Scalars['Float']['input']>;
  quantity?: InputMaybe<Scalars['Int']['input']>;
};

export type BirthdayDetailedList = {
  __typename?: 'BirthdayDetailedList';
  age?: Maybe<Scalars['Int']['output']>;
  dob?: Maybe<Scalars['String']['output']>;
  fullName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
};

/** Autogenerated input type of BulkDeletePatientRecordItems */
export type BulkDeletePatientRecordItemsInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Array of patient record items */
  ids: Array<Scalars['ID']['input']>;
};

/** Autogenerated return type of BulkDeletePatientRecordItems. */
export type BulkDeletePatientRecordItemsPayload = {
  __typename?: 'BulkDeletePatientRecordItemsPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  fieldErrors?: Maybe<Array<FieldError>>;
  patientRecordItems?: Maybe<Array<PatientRecordItem>>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

/** Autogenerated input type of BulkUpdateAttachment */
export type BulkUpdateAttachmentInput = {
  /** GUIDs of Attachments to update */
  attachments: Array<Scalars['ID']['input']>;
  attributes: AttachmentMetaInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of BulkUpdateAttachment. */
export type BulkUpdateAttachmentPayload = {
  __typename?: 'BulkUpdateAttachmentPayload';
  attachments?: Maybe<Array<Attachment>>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** General errors, unrelated to fields */
  errors?: Maybe<Array<Scalars['String']['output']>>;
  /** Input field errors */
  fieldErrors?: Maybe<Array<FieldError>>;
  /** Whether or not this mutation was successful */
  success?: Maybe<Scalars['Boolean']['output']>;
};

/** Autogenerated input type of BulkUpdatePatientRecordItems */
export type BulkUpdatePatientRecordItemsInput = {
  attributes: PatientRecordItemInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Array of patient record items */
  ids: Array<Scalars['ID']['input']>;
};

/** Autogenerated return type of BulkUpdatePatientRecordItems. */
export type BulkUpdatePatientRecordItemsPayload = {
  __typename?: 'BulkUpdatePatientRecordItemsPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  fieldErrors?: Maybe<Array<FieldError>>;
  patientRecordItems?: Maybe<Array<PatientRecordItem>>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type BulletChart = {
  __typename?: 'BulletChart';
  measures?: Maybe<Array<Scalars['Float']['output']>>;
  professional?: Maybe<Scalars['String']['output']>;
  ranges?: Maybe<Array<Scalars['Float']['output']>>;
  target?: Maybe<Scalars['Float']['output']>;
};

export type CalendarAvailability = {
  __typename?: 'CalendarAvailability';
  id?: Maybe<Scalars['ID']['output']>;
  timeslots: Array<CalendarTimeSlot>;
};

export type CalendarAvailabilityInput = {
  /** Desired minutes for the time slot */
  minutes?: InputMaybe<Scalars['Int']['input']>;
  /** Array of practitioner IDs */
  practitioners?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** Array of shift type IDs */
  shiftTypes?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export enum CalendarDay {
  /** Friday */
  Fri = 'fri',
  /** Monday */
  Mon = 'mon',
  /** Saturday */
  Sat = 'sat',
  /** Sunday */
  Sun = 'sun',
  /** Thursday */
  Thu = 'thu',
  /** Tuesday */
  Tue = 'tue',
  /** Wednesday */
  Wed = 'wed'
}

export type CalendarEvent = AnyNode & {
  __typename?: 'CalendarEvent';
  allowAppointmentCreatedEmail?: Maybe<Scalars['Boolean']['output']>;
  allowAppointmentCreatedSms?: Maybe<Scalars['Boolean']['output']>;
  allowConfirmationEmail?: Maybe<Scalars['Boolean']['output']>;
  allowConfirmationSms?: Maybe<Scalars['Boolean']['output']>;
  allowReminderEmail?: Maybe<Scalars['Boolean']['output']>;
  allowReminderSms?: Maybe<Scalars['Boolean']['output']>;
  appNotes?: Maybe<Scalars['String']['output']>;
  appointmentCreatedTemplateId?: Maybe<Scalars['ID']['output']>;
  appointmentUpdatedTemplateId?: Maybe<Scalars['ID']['output']>;
  autoInvoice?: Maybe<Scalars['Boolean']['output']>;
  badgeSlugs?: Maybe<Array<Scalars['String']['output']>>;
  billablesReadyToInvoiceTotal?: Maybe<Scalars['Float']['output']>;
  colour?: Maybe<Scalars['String']['output']>;
  confirmationTemplateId?: Maybe<Scalars['ID']['output']>;
  createdAt: Scalars['ISO8601DateTime']['output'];
  /** Total number of deposit requests completed */
  depositRequestsCompletedCount?: Maybe<Scalars['Int']['output']>;
  /** Total number of deposit requests in progress */
  depositRequestsInProgressCount?: Maybe<Scalars['Int']['output']>;
  duration?: Maybe<Scalars['String']['output']>;
  endTime?: Maybe<Scalars['ISO8601DateTime']['output']>;
  estimatedMinutes?: Maybe<Scalars['Int']['output']>;
  guestContactEmails?: Maybe<Array<Scalars['String']['output']>>;
  guestContactNumbers?: Maybe<Array<Scalars['String']['output']>>;
  guestIds?: Maybe<Array<Scalars['ID']['output']>>;
  guestNames?: Maybe<Array<Scalars['String']['output']>>;
  guid: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  isCommunicationDelivered?: Maybe<Scalars['Boolean']['output']>;
  isCommunicationFailed?: Maybe<Scalars['Boolean']['output']>;
  isConfirmationRequired?: Maybe<Scalars['Boolean']['output']>;
  isConfirmed?: Maybe<Scalars['Boolean']['output']>;
  isFirstVisit?: Maybe<Scalars['Boolean']['output']>;
  isGroupAppointment?: Maybe<Scalars['Boolean']['output']>;
  isGuestAppointment?: Maybe<Scalars['Boolean']['output']>;
  isOnlineBooking?: Maybe<Scalars['Boolean']['output']>;
  isOnlineMeeting?: Maybe<Scalars['Boolean']['output']>;
  isPrivateAppointment?: Maybe<Scalars['Boolean']['output']>;
  labworksCompleteCount?: Maybe<Scalars['Int']['output']>;
  labworksInProgressCount?: Maybe<Scalars['Int']['output']>;
  labworksSentCount?: Maybe<Scalars['Int']['output']>;
  minutes?: Maybe<Scalars['Int']['output']>;
  notes?: Maybe<Scalars['String']['output']>;
  onHoldDate?: Maybe<Scalars['ISO8601DateTime']['output']>;
  onHoldExpired?: Maybe<Scalars['Boolean']['output']>;
  onlineBookingCreditGuid?: Maybe<Scalars['ID']['output']>;
  patientHasOutstandingBalance?: Maybe<Scalars['Boolean']['output']>;
  practitionerName?: Maybe<Scalars['String']['output']>;
  reminderTemplateId?: Maybe<Scalars['ID']['output']>;
  /** Average rating of all review requests for this appointment */
  reviewRequestsAvgRating?: Maybe<Scalars['Float']['output']>;
  /** Total number of review requests completed */
  reviewRequestsCompletedCount?: Maybe<Scalars['Int']['output']>;
  /** Total number of review requests in progress */
  reviewRequestsInProgressCount?: Maybe<Scalars['Int']['output']>;
  roomId?: Maybe<Scalars['ID']['output']>;
  sendConfirmationDaysBefore?: Maybe<Scalars['Int']['output']>;
  sendReminderDaysBefore?: Maybe<Scalars['Int']['output']>;
  slug?: Maybe<Scalars['String']['output']>;
  smartFill?: Maybe<Scalars['Boolean']['output']>;
  startTime?: Maybe<Scalars['ISO8601DateTime']['output']>;
  state: AppointmentState;
  title?: Maybe<Scalars['String']['output']>;
  treatmentId?: Maybe<Scalars['ID']['output']>;
  treatmentPlanId?: Maybe<Scalars['ID']['output']>;
  updatedAt: Scalars['ISO8601DateTime']['output'];
  userPracticeConnectionId?: Maybe<Scalars['ID']['output']>;
  /** Unique identifier of the message */
  uuid: Scalars['String']['output'];
  waitlistId?: Maybe<Scalars['Int']['output']>;
};

export enum CalendarResource {
  /** View the calendar with practitioners as resources */
  Practitioner = 'practitioner',
  /** View the calendar with rooms as resources */
  Room = 'room'
}

export type CalendarSearchInput = {
  /** Filter by appointment guest ids. */
  byAppointmentGuests?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** Filter by Patient */
  byPatient?: InputMaybe<UpcSearchInput>;
  /** Filter by practice ids */
  byPracticeIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** By time periods */
  byTimePeriod?: InputMaybe<AppointmentTimePeriods>;
  /** Filter by time range */
  endTime?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
  /** Find only appointments without the given states */
  excludeState?: InputMaybe<Array<AppointmentState>>;
  /** Filter by id */
  id?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** Confirmed appointments */
  isConfirmed?: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by online bookings */
  onlineBookings?: InputMaybe<Scalars['Boolean']['input']>;
  /** Sort query */
  orderBy?: InputMaybe<AppointmentOrderInput>;
  /** Filter by Professional (only view events for that professional) */
  professionalId?: InputMaybe<Scalars['ID']['input']>;
  /** Filter by Room */
  roomIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** Skip imported appointments */
  skipImported?: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by time range */
  startTime?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
  /** Find only appointments with the given states */
  state?: InputMaybe<Array<AppointmentState>>;
  /** Filter by treatment plan id */
  treatmentPlanId?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** Filter by UserPracticeConnection (professional role) */
  userPracticeConnectionIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** Filter if appointment has treatment */
  withTreatment?: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by draft appointments which a pending waitlist */
  withWaitlist?: InputMaybe<Scalars['Boolean']['input']>;
};

export enum CalendarSession {
  /** Afternoon */
  Afternoon = 'afternoon',
  /** Evening */
  Evening = 'evening',
  /** Morning */
  Morning = 'morning'
}

export type CalendarTimeSlot = {
  __typename?: 'CalendarTimeSlot';
  day: CalendarDay;
  endTime: Scalars['ISO8601DateTime']['output'];
  id?: Maybe<Scalars['ID']['output']>;
  /** The number of minutes for this slot */
  minutes: Scalars['Int']['output'];
  practitioner: UserPracticeLink;
  practitionerGuid: Scalars['ID']['output'];
  /** The reservations for this slot that are currently in place */
  reservations: Array<CalendarTimeSlotReservation>;
  rosterShift?: Maybe<RosterShift>;
  session: CalendarSession;
  slot: Scalars['String']['output'];
  startTime: Scalars['ISO8601DateTime']['output'];
  status: CalendarTimeSlotStatus;
};

/** A reservation for a time slot in the calendar provided by the Calendar::ReservationManager. */
export type CalendarTimeSlotReservation = {
  __typename?: 'CalendarTimeSlotReservation';
  endTime?: Maybe<Scalars['ISO8601DateTime']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  /** The number of minutes for this slot */
  minutes: Scalars['Int']['output'];
  releasedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  /** Human readable time until the reservation is released */
  releasedIn?: Maybe<Scalars['String']['output']>;
  reservedBy: Scalars['String']['output'];
  slot: Scalars['String']['output'];
  startTime?: Maybe<Scalars['ISO8601DateTime']['output']>;
};

export enum CalendarTimeSlotStatus {
  /** Available: Booking is possible */
  Available = 'available',
  /** Booked: A booking is confirmed */
  Booked = 'booked',
  /** Reserved: A reservation has been made but is not confirmed */
  Reserved = 'reserved',
  /** Unavailable: Practitioner is not available at this time */
  Unavailable = 'unavailable'
}

/** Autogenerated input type of CancelClaim */
export type CancelClaimInput = {
  attributes: HealthFundsCancelClaimInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** GUID of the health fund request you want to cancel */
  request: Scalars['ID']['input'];
};

/** Autogenerated return type of CancelClaim. */
export type CancelClaimPayload = {
  __typename?: 'CancelClaimPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  fieldErrors?: Maybe<Array<FieldError>>;
  /** The new request created from the cancellation */
  healthFundsRequest?: Maybe<HealthFundsRequest>;
  /** The invoice that was updated from the cancellation */
  invoice?: Maybe<Invoice>;
  /** The invoice claim items that were include as part of the cancellation */
  invoiceClaimItems?: Maybe<Array<InvoiceItem>>;
  /** The invoice transaction that was created from the cancellation if any money was to be adjusted on the invoice */
  invoiceTransaction?: Maybe<InvoiceTransaction>;
  /** The original request to be cancelled */
  originalHealthFundsRequest?: Maybe<HealthFundsRequest>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

/** Autogenerated input type of CancelEmail */
export type CancelEmailInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** ID of the email to cancel */
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of CancelEmail. */
export type CancelEmailPayload = {
  __typename?: 'CancelEmailPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Email>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  fieldErrors?: Maybe<Array<FieldError>>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

/** Autogenerated input type of CancelInvitation */
export type CancelInvitationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  guid: Scalars['ID']['input'];
};

/** Autogenerated return type of CancelInvitation. */
export type CancelInvitationPayload = {
  __typename?: 'CancelInvitationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  fieldErrors?: Maybe<Array<FieldError>>;
  success?: Maybe<Scalars['Boolean']['output']>;
  userPracticeConnection?: Maybe<UserPracticeLink>;
};

/** Autogenerated input type of CancelSms */
export type CancelSmsInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** ID of the email to cancel */
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of CancelSms. */
export type CancelSmsPayload = {
  __typename?: 'CancelSmsPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  fieldErrors?: Maybe<Array<FieldError>>;
  sms?: Maybe<ShortMessage>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type CancellationReason = {
  __typename?: 'CancellationReason';
  colour?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['Int']['output'];
  guid: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
  state?: Maybe<CancellationReasonState>;
  updatedAt: Scalars['Int']['output'];
};

/** The connection type for CancellationReason. */
export type CancellationReasonConnection = {
  __typename?: 'CancellationReasonConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<CancellationReasonEdge>>>;
  /** IDs of ALL results, regardless of pagination */
  ids: Array<Scalars['ID']['output']>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<CancellationReason>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Returns total count of items in list */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type CancellationReasonEdge = {
  __typename?: 'CancellationReasonEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<CancellationReason>;
};

export type CancellationReasonInput = {
  name?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<CancellationReasonState>;
};

export enum CancellationReasonState {
  Active = 'active',
  Archived = 'archived'
}

export type CatalogueAppointmentReasonInput = {
  catalogueItemId: Scalars['ID']['input'];
  discount?: InputMaybe<Scalars['Float']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  price?: InputMaybe<Scalars['Float']['input']>;
};

/**
 * CatalogueItems are any products or services visible
 * in the catalogue. These can also be packages, linking
 * to other products or services to create a bundle.
 *
 * Catalogue items are defined by their name and their SKU,
 * and will generally have a price associated with them, but
 * the price can be set using PriceLists. Once a price list is
 * created, all prices assigned in that price list can be switched
 * by switching the currently active prices — for example, you may
 * have peak and off-peak prices for a product, and you may want to
 * control prices for products depending on time of year, season or
 * demand.
 *
 * While each CatalogueItem has a SKU that uniquely identifies that item,
 * this SKU is controlled by the user. When health fund claims are made
 * against purchased items on an invoice, those items must be identified by
 * different SKUs defined by the health fund the claim is made against.
 *
 * You can create HealthFundItemCodes to link a CatalogueItem to a HealthFund,
 * and when a claim is made with that health fund, the item will be identified
 * by that health funds item code. A catalogue item can have as many health fund
 * item codes as you'd like, and each item code can be valid for one or more funds.
 */
export type CatalogueItem = AnyNode & {
  __typename?: 'CatalogueItem';
  active?: Maybe<Scalars['Boolean']['output']>;
  /** Amount of tax added */
  addedTax?: Maybe<Scalars['Float']['output']>;
  /** Amount of tax added as a % */
  addedTaxPercentage?: Maybe<Scalars['Float']['output']>;
  adviceItemIds?: Maybe<Array<Scalars['ID']['output']>>;
  adviceItems?: Maybe<Array<AdviceItem>>;
  autoNote?: Maybe<Scalars['String']['output']>;
  /** Material that this item is made of */
  bookableAppointmentReason?: Maybe<AppointmentReason>;
  /** Packages have a reflexive relationship to CatalogueItem, to indicate what they contain */
  catalogueItem?: Maybe<CatalogueItem>;
  category?: Maybe<Category>;
  code?: Maybe<Scalars['String']['output']>;
  /** This is the colour represented by the catalogue item selected material */
  colour?: Maybe<Scalars['String']['output']>;
  /** Cost paid for the item by the practice */
  costPrice?: Maybe<Scalars['Float']['output']>;
  coverPhoto?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['ISO8601DateTime']['output'];
  /** Classification of the dental treatment if its an appliance, tooth item or procedure */
  dentalClassification?: Maybe<DentalClassification>;
  /** The jaw that this applies to */
  dentalJaw?: Maybe<DentalJaw>;
  /** How the dental item is priced. e.g per tooth or per jaw */
  dentalPricing?: Maybe<DentalPricing>;
  /** The specific teeth that this applies to */
  dentalTeeth?: Maybe<Array<DentalTeeth>>;
  /** Specific treatment type for this catalogue item e.g filling */
  dentalTreatmentType?: Maybe<DentalTreatment>;
  /** Amount of deposit required for online bookings. Stored as cents. */
  depositAmount?: Maybe<Scalars['Int']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  enableStock?: Maybe<Scalars['Boolean']['output']>;
  expiryMonths?: Maybe<Scalars['Int']['output']>;
  groupName?: Maybe<Scalars['String']['output']>;
  guid: Scalars['ID']['output'];
  /** Health fund item number */
  healthFundItemNumber?: Maybe<Scalars['String']['output']>;
  /** Health fund item number including modality code */
  healthFundItemNumberWithModality?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  /** This is a boolean that indicates if the item is for dental modality */
  isDental: Scalars['Boolean']['output'];
  isPackage?: Maybe<Scalars['Boolean']['output']>;
  /** Base price */
  masterPrice?: Maybe<Scalars['Float']['output']>;
  /** Material that this item is made of */
  material?: Maybe<Material>;
  minutes?: Maybe<Scalars['Int']['output']>;
  /** Modality is which health industry this item applies to */
  modality?: Maybe<CatalogueItemModality>;
  /** Shorthand modality code representing the health industry this item applies to */
  modalityCode?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  nameAndCode?: Maybe<Scalars['String']['output']>;
  online?: Maybe<Scalars['Boolean']['output']>;
  packageLimit?: Maybe<Scalars['Int']['output']>;
  /** Modality is which health industry this item applies to. This represents the label */
  prettyModality?: Maybe<Scalars['String']['output']>;
  price?: Maybe<Price>;
  /** Price excluding tax (if any) */
  priceExTax?: Maybe<Scalars['Float']['output']>;
  /** Price including tax (if any) */
  priceIncTax?: Maybe<Scalars['Float']['output']>;
  /** Returns true if item includes tax */
  priceIncludesTax?: Maybe<Scalars['Boolean']['output']>;
  /** List of price lists for this item */
  priceLists?: Maybe<Array<PriceList>>;
  /** List of different prices for this item */
  prices?: Maybe<Array<Price>>;
  /** Health fund item code used for private health funds */
  publicHealthCode?: Maybe<Scalars['String']['output']>;
  /** Whether a deposit is required for online bookings */
  requireDeposit?: Maybe<Scalars['Boolean']['output']>;
  /** True if this catalogue item requires a FDI code */
  requiresFdi?: Maybe<Scalars['Boolean']['output']>;
  /** True if this catalogue item requires a surface code */
  requiresSurfaceCode?: Maybe<Scalars['Boolean']['output']>;
  /** Show the total cost for Online Bookings, When enabled, will show the total cost of the service. */
  showTotalCost?: Maybe<Scalars['Boolean']['output']>;
  surfaceCount?: Maybe<Scalars['Int']['output']>;
  /** Amount of tax as a decimal value. Similar to added_tax_percentage */
  taxAmount?: Maybe<Scalars['Float']['output']>;
  /** Returns true if item is taxable */
  taxCode?: Maybe<Scalars['Boolean']['output']>;
  /** Total price (price_ex_tax) */
  totalPrice?: Maybe<Scalars['Float']['output']>;
  type: CatalogueItemType;
  updatedAt: Scalars['ISO8601DateTime']['output'];
  updatesExam?: Maybe<Scalars['Boolean']['output']>;
  updatesXray?: Maybe<Scalars['Boolean']['output']>;
  /** Unique identifier of the message */
  uuid: Scalars['String']['output'];
};


/**
 * CatalogueItems are any products or services visible
 * in the catalogue. These can also be packages, linking
 * to other products or services to create a bundle.
 *
 * Catalogue items are defined by their name and their SKU,
 * and will generally have a price associated with them, but
 * the price can be set using PriceLists. Once a price list is
 * created, all prices assigned in that price list can be switched
 * by switching the currently active prices — for example, you may
 * have peak and off-peak prices for a product, and you may want to
 * control prices for products depending on time of year, season or
 * demand.
 *
 * While each CatalogueItem has a SKU that uniquely identifies that item,
 * this SKU is controlled by the user. When health fund claims are made
 * against purchased items on an invoice, those items must be identified by
 * different SKUs defined by the health fund the claim is made against.
 *
 * You can create HealthFundItemCodes to link a CatalogueItem to a HealthFund,
 * and when a claim is made with that health fund, the item will be identified
 * by that health funds item code. A catalogue item can have as many health fund
 * item codes as you'd like, and each item code can be valid for one or more funds.
 */
export type CatalogueItemPriceArgs = {
  priceListId?: InputMaybe<Scalars['ID']['input']>;
};


/**
 * CatalogueItems are any products or services visible
 * in the catalogue. These can also be packages, linking
 * to other products or services to create a bundle.
 *
 * Catalogue items are defined by their name and their SKU,
 * and will generally have a price associated with them, but
 * the price can be set using PriceLists. Once a price list is
 * created, all prices assigned in that price list can be switched
 * by switching the currently active prices — for example, you may
 * have peak and off-peak prices for a product, and you may want to
 * control prices for products depending on time of year, season or
 * demand.
 *
 * While each CatalogueItem has a SKU that uniquely identifies that item,
 * this SKU is controlled by the user. When health fund claims are made
 * against purchased items on an invoice, those items must be identified by
 * different SKUs defined by the health fund the claim is made against.
 *
 * You can create HealthFundItemCodes to link a CatalogueItem to a HealthFund,
 * and when a claim is made with that health fund, the item will be identified
 * by that health funds item code. A catalogue item can have as many health fund
 * item codes as you'd like, and each item code can be valid for one or more funds.
 */
export type CatalogueItemPricesArgs = {
  priceListIds?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type CatalogueItemAppointmentReason = {
  __typename?: 'CatalogueItemAppointmentReason';
  appointmentReason?: Maybe<AppointmentReason>;
  appointmentReasonId: Scalars['ID']['output'];
  catalogueItem?: Maybe<CatalogueItem>;
  catalogueItemId: Scalars['ID']['output'];
  code?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  discount?: Maybe<Scalars['Float']['output']>;
  guid: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  masterPrice?: Maybe<Scalars['Float']['output']>;
  minutes?: Maybe<Scalars['Int']['output']>;
  nameAndCode?: Maybe<Scalars['String']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
  priceIncTax?: Maybe<Scalars['Float']['output']>;
  /** List of different prices for this item */
  prices?: Maybe<Array<Price>>;
  updatedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
};


export type CatalogueItemAppointmentReasonPricesArgs = {
  priceListIds?: InputMaybe<Array<Scalars['ID']['input']>>;
};

/** The connection type for CatalogueItem. */
export type CatalogueItemConnection = {
  __typename?: 'CatalogueItemConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<CatalogueItemEdge>>>;
  /** IDs of ALL results, regardless of pagination */
  ids: Array<Scalars['ID']['output']>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<CatalogueItem>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Returns total count of items in list */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type CatalogueItemEdge = {
  __typename?: 'CatalogueItemEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<CatalogueItem>;
};

export enum CatalogueItemField {
  /** Sort by catalogue item code */
  Code = 'code',
  /** Sort by catalogue item creation time */
  CreatedAt = 'created_at'
}

export type CatalogueItemInput = {
  /** Whether this item is active in the catalogue */
  active?: InputMaybe<Scalars['Boolean']['input']>;
  adviceItemIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** Auto note that gets created when item is marked as completed in a treatment plan */
  autoNote?: InputMaybe<Scalars['String']['input']>;
  bookableAppointmentReason?: InputMaybe<AppointmentReasonInput>;
  catalogueItemId?: InputMaybe<Scalars['ID']['input']>;
  /** Category to which this item belongs */
  categoryId?: InputMaybe<Scalars['ID']['input']>;
  /** SKU identifies the item as a short string */
  code?: InputMaybe<Scalars['String']['input']>;
  /** Cost price */
  costPrice?: InputMaybe<Scalars['Float']['input']>;
  /** Show this image as the cover photo for this item. Set to null to clear. */
  coverPhoto?: InputMaybe<Scalars['String']['input']>;
  dentalClassification?: InputMaybe<Scalars['String']['input']>;
  dentalJaw?: InputMaybe<Scalars['String']['input']>;
  dentalPricing?: InputMaybe<Scalars['String']['input']>;
  dentalTeeth?: InputMaybe<Array<Scalars['String']['input']>>;
  dentalTreatmentType?: InputMaybe<Scalars['String']['input']>;
  /** Amount of deposit required for online bookings. Stored as cents. */
  depositAmount?: InputMaybe<Scalars['Int']['input']>;
  /** Longer description for this item */
  description?: InputMaybe<Scalars['String']['input']>;
  /** Shoudl sales of this item decrement the inventory stock? */
  enableStock?: InputMaybe<Scalars['Boolean']['input']>;
  expiryMonths?: InputMaybe<Scalars['Int']['input']>;
  /** Item number for this service or product to use for claiming */
  healthFundItemNumber?: InputMaybe<Scalars['String']['input']>;
  /** Main price */
  masterPrice?: InputMaybe<Scalars['Float']['input']>;
  materialId?: InputMaybe<Scalars['ID']['input']>;
  /** Number of minutes for this item, if its a service */
  minutes?: InputMaybe<Scalars['Int']['input']>;
  /** Health Fund Modality of this item */
  modality?: InputMaybe<CatalogueItemModality>;
  /** Name visible in the catalogue */
  name?: InputMaybe<Scalars['String']['input']>;
  /** Whether this item can be bought online */
  online?: InputMaybe<Scalars['Boolean']['input']>;
  packageLimit?: InputMaybe<Scalars['Int']['input']>;
  /** Whether the given price is with tax included */
  priceIncludesTax?: InputMaybe<Scalars['Boolean']['input']>;
  /** Whether a deposit is required for online bookings */
  requireDeposit?: InputMaybe<Scalars['Boolean']['input']>;
  /** Whether to show the total cost of the appointment for online bookings */
  showTotalCost?: InputMaybe<Scalars['Boolean']['input']>;
  /** Tax percentage */
  taxAmount?: InputMaybe<Scalars['Float']['input']>;
  /** Tax code for book keeping purposes */
  taxCode?: InputMaybe<Scalars['Boolean']['input']>;
  /** Whether this item is a product/service/package */
  type?: InputMaybe<CatalogueItemType>;
  /** Whether this item should update an exam (?) */
  updatesExam?: InputMaybe<Scalars['Boolean']['input']>;
  /** Whether this item should update an xray (?) */
  updatesXray?: InputMaybe<Scalars['Boolean']['input']>;
};

export enum CatalogueItemModality {
  /** Acupuncturist */
  Acupuncturist = 'acupuncturist',
  /** Chiropractor */
  Chiropractor = 'chiropractor',
  /** Dental Technician */
  DentalTechnician = 'dental_technician',
  /** Dietitian */
  Dietitian = 'dietitian',
  /** Endodontist */
  Endodontist = 'endodontist',
  /** Exercise Physiology */
  ExercisePhysiology = 'exercise_physiology',
  /** General Dentist */
  GeneralDentist = 'general_dentist',
  /** Massage Therapist */
  MassageTherapist = 'massage_therapist',
  /** Myotherapy */
  Myotherapy = 'myotherapy',
  /** Naturopath */
  Naturopath = 'naturopath',
  /** Occupational Therapist */
  OccupationalTherapist = 'occupational_therapist',
  /** Optical Dispenser */
  OpticalDispenser = 'optical_dispenser',
  /** Optometrist */
  Optometrist = 'optometrist',
  /** Oral Surgeon */
  OralSurgeon = 'oral_surgeon',
  /** Orthodontist */
  Orthodontist = 'orthodontist',
  /** Osteopath */
  Osteopath = 'osteopath',
  /** Paedodontist (Paediatric) */
  Paedodontist = 'paedodontist',
  /** Periodontist */
  Periodontist = 'periodontist',
  /** Physiotherapist */
  Physiotherapist = 'physiotherapist',
  /** Podiatrist */
  Podiatrist = 'podiatrist',
  /** Prosthodontist */
  Prosthodontist = 'prosthodontist',
  /** Psychologist */
  Psychologist = 'psychologist',
  /** Speech Pathologist */
  SpeechPathologist = 'speech_pathologist'
}

export type CatalogueItemModalitySelect = {
  __typename?: 'CatalogueItemModalitySelect';
  label: Scalars['String']['output'];
  value: CatalogueItemModality;
};

export type CatalogueItemOrderInput = {
  direction?: InputMaybe<Direction>;
  field?: InputMaybe<CatalogueItemField>;
};

export enum CatalogueItemState {
  Active = 'active',
  Archived = 'archived'
}

export enum CatalogueItemType {
  Package = 'package',
  Product = 'product',
  Service = 'service'
}

export type Category = {
  __typename?: 'Category';
  active: Scalars['Boolean']['output'];
  createdAt: Scalars['ISO8601DateTime']['output'];
  guid: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  industryId: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  practiceId: Scalars['ID']['output'];
  state?: Maybe<CategoryState>;
  type: Scalars['String']['output'];
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

/** The connection type for Category. */
export type CategoryConnection = {
  __typename?: 'CategoryConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<CategoryEdge>>>;
  /** IDs of ALL results, regardless of pagination */
  ids: Array<Scalars['ID']['output']>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Category>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Returns total count of items in list */
  totalCount: Scalars['Int']['output'];
};

export type CategoryCreateInput = {
  /** Industry ID */
  industryId?: InputMaybe<Scalars['ID']['input']>;
  /** Name of Category */
  name: Scalars['String']['input'];
  /** Practice ID */
  practiceId?: InputMaybe<Scalars['ID']['input']>;
  /** Type of Category */
  type: Scalars['String']['input'];
};

/** An edge in a connection. */
export type CategoryEdge = {
  __typename?: 'CategoryEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<Category>;
};

export enum CategoryState {
  Active = 'active',
  Archived = 'archived'
}

export enum CategoryStateEvent {
  Activate = 'activate',
  Archive = 'archive'
}

export type CategoryUpdateInput = {
  /** Name of Category */
  name?: InputMaybe<Scalars['String']['input']>;
  /** Type of Category */
  type?: InputMaybe<Scalars['String']['input']>;
};

export type ChangePasswordAttributes = {
  /** Current Password for the account */
  currentPassword: Scalars['String']['input'];
  /** Password for the account */
  password: Scalars['String']['input'];
  /** Password confirmation for the account */
  passwordConfirmation: Scalars['String']['input'];
};

/** Autogenerated input type of ChangePassword */
export type ChangePasswordInput = {
  attributes: ChangePasswordAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of ChangePassword. */
export type ChangePasswordPayload = {
  __typename?: 'ChangePasswordPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  fieldErrors?: Maybe<Array<FieldError>>;
  success?: Maybe<Scalars['Boolean']['output']>;
  user?: Maybe<User>;
};

export type Chart = {
  __typename?: 'Chart';
  bidirectionalChart?: Maybe<Array<BidirectionalChart>>;
  bulletChart?: Maybe<Array<BulletChart>>;
  columnChart?: Maybe<Array<ColumnChart>>;
  pieChart?: Maybe<Array<PieChart>>;
};

/** Autogenerated input type of Claim */
export type ClaimInput = {
  attributes: HealthFundsClaimInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** GUID of the patient UPC to run a quote against */
  patient: Scalars['ID']['input'];
  /** GraphQL UUID (base64(ClassName:ID)) of the object to attach request to */
  requestable?: InputMaybe<Scalars['ID']['input']>;
};

/** Autogenerated return type of Claim. */
export type ClaimPayload = {
  __typename?: 'ClaimPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  fieldErrors?: Maybe<Array<FieldError>>;
  healthFundsRequest?: Maybe<HealthFundsRequest>;
  invoice?: Maybe<Invoice>;
  invoiceClaimItems?: Maybe<Array<InvoiceItem>>;
  invoiceTransaction?: Maybe<InvoiceTransaction>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

/** Autogenerated input type of ClearRestriction */
export type ClearRestrictionInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of ClearRestriction. */
export type ClearRestrictionPayload = {
  __typename?: 'ClearRestrictionPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  fieldErrors?: Maybe<Array<FieldError>>;
  success: Scalars['Boolean']['output'];
};

export type ColumnChart = {
  __typename?: 'ColumnChart';
  date?: Maybe<Scalars['String']['output']>;
  group?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['Float']['output']>;
};

export type ColumnHistory = {
  __typename?: 'ColumnHistory';
  columnName: Scalars['String']['output'];
  createdAt: Scalars['ISO8601DateTime']['output'];
  createdBy?: Maybe<UserPracticeLink>;
  id: Scalars['ID']['output'];
  valueAfter?: Maybe<Scalars['String']['output']>;
  valueBefore?: Maybe<Scalars['String']['output']>;
};

export type CommunicationInput = {
  email?: InputMaybe<Scalars['Boolean']['input']>;
  letters?: InputMaybe<Scalars['Boolean']['input']>;
  portal?: InputMaybe<Scalars['Boolean']['input']>;
  sms?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CommunicationSettings = {
  __typename?: 'CommunicationSettings';
  communicationType: CommunicationType;
  email: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  letters: Scalars['Boolean']['output'];
  portal: Scalars['Boolean']['output'];
  sms: Scalars['Boolean']['output'];
};

export type CommunicationSettingsInput = {
  advice?: InputMaybe<CommunicationInput>;
  appointment?: InputMaybe<CommunicationInput>;
  general?: InputMaybe<CommunicationInput>;
  marketing?: InputMaybe<CommunicationInput>;
  recall?: InputMaybe<CommunicationInput>;
  referral?: InputMaybe<CommunicationInput>;
};

export type CommunicationTemplate = TemplateInterface & {
  __typename?: 'CommunicationTemplate';
  assigned?: Maybe<Array<TemplateAssignable>>;
  attachments?: Maybe<Array<Attachment>>;
  communicationTemplateType: CommunicationTemplateType;
  createdAt: Scalars['ISO8601DateTime']['output'];
  /** Template marked as default */
  default: Scalars['Boolean']['output'];
  description?: Maybe<Scalars['String']['output']>;
  /** What this template type is used for (e.g Forms, Appointments, Invoices) */
  group?: Maybe<TemplateGroupTranslation>;
  guid: Scalars['ID']['output'];
  /** Email content */
  html?: Maybe<Scalars['Base64Brotli']['output']>;
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
  /** SMS content */
  plainText?: Maybe<Scalars['Base64Brotli']['output']>;
  practice: Practice;
  practiceId: Scalars['ID']['output'];
  state: TemplateState;
  /** Email subject */
  subject?: Maybe<Scalars['String']['output']>;
  type: TemplateType;
  typeDefinition?: Maybe<TemplateTypeTranslation>;
  uuid: Scalars['String']['output'];
  /** How this template is to be used - e.g. Document (only HTML), Communication (HTML + Plain), or Form (JSON) */
  variant?: Maybe<TemplateVariantTranslation>;
};

/** The connection type for CommunicationTemplate. */
export type CommunicationTemplateConnection = {
  __typename?: 'CommunicationTemplateConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<CommunicationTemplateEdge>>>;
  /** IDs of ALL results, regardless of pagination */
  ids: Array<Scalars['ID']['output']>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<CommunicationTemplate>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Returns total count of items in list */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type CommunicationTemplateEdge = {
  __typename?: 'CommunicationTemplateEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<CommunicationTemplate>;
};

export enum CommunicationTemplateType {
  /** Sent to patients when new advice is created for them */
  Advice = 'advice',
  /** Sent to the patient when an appointment needs their confirmation */
  AppointmentAwaitingConfirmation = 'appointment_awaiting_confirmation',
  /** Sent to a patient when their appointment has been cancelled */
  AppointmentCancelled = 'appointment_cancelled',
  /** Sent to a patient when their appointment has been changed, which could include the start/end time or services */
  AppointmentChanged = 'appointment_changed',
  /** Sent to a patient when their group appointment is confirmed */
  AppointmentConfirmGroupGuest = 'appointment_confirm_group_guest',
  /** Sent to a patient when they have been confirmed as a guest for an appointment */
  AppointmentConfirmGuest = 'appointment_confirm_guest',
  /** Sent to a patient when they must click on a link to confirm their appointment */
  AppointmentConfirmation = 'appointment_confirmation',
  /** Sent to patients when an appointment is created when linked to an appointment shortcut */
  AppointmentCreated = 'appointment_created',
  /** Sent to a patient when their request for an appointment has been rejected */
  AppointmentDeclined = 'appointment_declined',
  /** Sent to patients when they are invited to a group appointment */
  AppointmentGroupInvite = 'appointment_group_invite',
  /** Sent to the guest when they've been removed from an appointment */
  AppointmentGuestRemoved = 'appointment_guest_removed',
  /** Sent to a patient when their appointment has been put on hold */
  AppointmentOnhold = 'appointment_onhold',
  /** Sent to the patient when they are invited to an online meeting */
  AppointmentOnlineLink = 'appointment_online_link',
  /** Sent to the practice when an appointment needs their confirmation */
  AppointmentPracticeAwaitingConfirmation = 'appointment_practice_awaiting_confirmation',
  /** Sent to YOU when a guest has been confirmed for an appointment */
  AppointmentPracticeConfirmGuest = 'appointment_practice_confirm_guest',
  /** Sent to patients when they have an appointment */
  AppointmentReason = 'appointment_reason',
  /** Sent to remind a patient of an upcoming appointment prior to the appointment */
  AppointmentReminder = 'appointment_reminder',
  /** Sent to the patient when requesting a deposit payment */
  CustomerRequestDeposit = 'customer_request_deposit',
  /** Sent to the patient when requesting a review */
  CustomerRequestReview = 'customer_request_review',
  /** Sent to the patient when deposit payment could not be processed */
  DepositFailed = 'deposit_failed',
  /** Sent to the patient when deposit payment has been successful */
  DepositPaid = 'deposit_paid',
  /** Used to deliver the form link to a recipient by email / SMS */
  FormDelivery = 'form_delivery',
  /** Used for general communications to patients */
  General = 'general',
  /** Sent when an invoice has been created for a user */
  Invoice = 'invoice',
  /** Sent when an invoice is due and not paid yet */
  InvoiceReminder = 'invoice_reminder',
  /** Send marketing communications to patients */
  Marketing = 'marketing',
  /** Sent when an OnlineMeeting is created */
  OnlineMeetingInvite = 'online_meeting_invite',
  /** Can be linked to recall automation and sent to patients */
  Recall = 'recall',
  /** Sent to referral recipients when to include patient information */
  Referral = 'referral',
  /** Communication template when sending out treatment plans */
  TreatmentTreatmentPlan = 'treatment_treatment_plan'
}

export enum CommunicationType {
  Advice = 'advice',
  Appointment = 'appointment',
  General = 'general',
  Marketing = 'marketing',
  OnlineMeeting = 'online_meeting',
  Otp = 'otp',
  Recall = 'recall',
  Referral = 'referral'
}

/** Autogenerated input type of ConfigureMailSetting */
export type ConfigureMailSettingInput = {
  attributes: MailSettingsInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of ConfigureMailSetting. */
export type ConfigureMailSettingPayload = {
  __typename?: 'ConfigureMailSettingPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  fieldErrors?: Maybe<Array<FieldError>>;
  mailSettings?: Maybe<PracticeMailSettings>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

/** Autogenerated input type of ConfirmGuest */
export type ConfirmGuestInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  token: Scalars['String']['input'];
};

/** Autogenerated return type of ConfirmGuest. */
export type ConfirmGuestPayload = {
  __typename?: 'ConfirmGuestPayload';
  appointmentGuest?: Maybe<AppointmentGuest>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  fieldErrors?: Maybe<Array<FieldError>>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

/** Autogenerated input type of Confirm */
export type ConfirmInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  userPracticeConnectionId: Scalars['ID']['input'];
};

/** Autogenerated return type of Confirm. */
export type ConfirmPayload = {
  __typename?: 'ConfirmPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  fieldErrors?: Maybe<Array<FieldError>>;
  success?: Maybe<Scalars['Boolean']['output']>;
  userPracticeConnection?: Maybe<UserPracticeLink>;
};

/** Autogenerated input type of ConfirmPaymentIntent */
export type ConfirmPaymentIntentInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The invoice to request a payment intent for */
  invoice: Scalars['ID']['input'];
  /** Payment Intent ID received from requestPaymentIntent */
  paymentIntentId: Scalars['ID']['input'];
};

/** Autogenerated return type of ConfirmPaymentIntent. */
export type ConfirmPaymentIntentPayload = {
  __typename?: 'ConfirmPaymentIntentPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** General errors, unrelated to fields */
  errors?: Maybe<Array<Scalars['String']['output']>>;
  /** Input field errors */
  fieldErrors?: Maybe<Array<FieldError>>;
  /** Whether or not this mutation was successful */
  success?: Maybe<Scalars['Boolean']['output']>;
  transactions?: Maybe<Array<InvoiceTransaction>>;
};

/** Autogenerated input type of ConfirmSignUp */
export type ConfirmSignUpInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  token: Scalars['String']['input'];
};

/** Autogenerated return type of ConfirmSignUp. */
export type ConfirmSignUpPayload = {
  __typename?: 'ConfirmSignUpPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  credentials?: Maybe<Credentials>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  fieldErrors?: Maybe<Array<FieldError>>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type ConnectionAlertUpdateInput = {
  note?: InputMaybe<Scalars['String']['input']>;
};

export type Contact = {
  __typename?: 'Contact';
  guid: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  relayCommunications: Scalars['Boolean']['output'];
  userPracticeConnection: UserPracticeLink;
};

export type ContactOverrideRelayInput = {
  /** UUIDs of contacts that should receive communications instead of defauls */
  contactGuids: Array<Scalars['ID']['input']>;
  /** UUID of the UPC to override default relay config for */
  guid: Scalars['ID']['input'];
};

/** Any contactable record, e.g Patient, PracticeProfessional or Contact */
export type Contactable = Patient | PatientRecord | PracticeProfessional | UserPracticeProfile;

/** The connection type for Contactable. */
export type ContactableConnection = {
  __typename?: 'ContactableConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<ContactableEdge>>>;
  /** IDs of ALL results, regardless of pagination */
  ids: Array<Scalars['ID']['output']>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Contactable>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Returns total count of items in list */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type ContactableEdge = {
  __typename?: 'ContactableEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<Contactable>;
};

export type ContentFilter = {
  /** Return a virtual directory of folders and files with these badges */
  badges?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type ContractIdentifier = {
  __typename?: 'ContractIdentifier';
  createdAt: Scalars['ISO8601DateTime']['output'];
  id: Scalars['ID']['output'];
  /** The merchant ID for the selected practitioner/healthfund */
  merchantId?: Maybe<Scalars['String']['output']>;
  /** Name of the contract identifier. Used to identify the contract identifier in the UI */
  name?: Maybe<Scalars['String']['output']>;
  /** The provider ID identifiying them for the selected healthfund */
  providerId?: Maybe<Scalars['String']['output']>;
  /** The provider Name identifiying them for the selected healthfund */
  providerName?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['ISO8601DateTime']['output'];
  /** The practitioner that belongs to this provider number/ contract identifier */
  userPracticeConnection?: Maybe<UserPracticeLink>;
  /** The practitioner that belongs to this provider number/ contract identifier */
  userPracticeConnectionGuid: Scalars['ID']['output'];
};

/** The connection type for ContractIdentifier. */
export type ContractIdentifierConnection = {
  __typename?: 'ContractIdentifierConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<ContractIdentifierEdge>>>;
  /** IDs of ALL results, regardless of pagination */
  ids: Array<Scalars['ID']['output']>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<ContractIdentifier>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Returns total count of items in list */
  totalCount: Scalars['Int']['output'];
};

export type ContractIdentifierCreateInput = {
  id?: InputMaybe<Scalars['String']['input']>;
  /** THe merchant ID for the selected identifier */
  merchantId?: InputMaybe<Scalars['String']['input']>;
  /** Name of the contract identifier */
  name: Scalars['String']['input'];
  /** Contact idenfier provider id */
  providerId: Scalars['String']['input'];
};

/** An edge in a connection. */
export type ContractIdentifierEdge = {
  __typename?: 'ContractIdentifierEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<ContractIdentifier>;
};

export type Conversation = {
  __typename?: 'Conversation';
  /** The date and time the conversation was created */
  createdAt: Scalars['ISO8601DateTime']['output'];
  /** Unique identifier of the conversation */
  id: Scalars['ID']['output'];
  /** The number of messages in the conversation */
  messageCount: Scalars['Int']['output'];
  /** The messages in the conversation */
  messages?: Maybe<MessageConnection>;
  /** The participants in the conversation */
  participants?: Maybe<UserPracticeLinkConnection>;
  /** The date and time the conversation was last updated */
  updatedAt: Scalars['ISO8601DateTime']['output'];
};


export type ConversationMessagesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type ConversationParticipantsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** The connection type for Conversation. */
export type ConversationConnection = {
  __typename?: 'ConversationConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<ConversationEdge>>>;
  /** IDs of ALL results, regardless of pagination */
  ids: Array<Scalars['ID']['output']>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Conversation>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Returns total count of items in list */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type ConversationEdge = {
  __typename?: 'ConversationEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<Conversation>;
};

/** Autogenerated input type of CopyPatientToPractice */
export type CopyPatientToPracticeInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  practice: Scalars['ID']['input'];
};

/** Autogenerated return type of CopyPatientToPractice. */
export type CopyPatientToPracticePayload = {
  __typename?: 'CopyPatientToPracticePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  fieldErrors?: Maybe<Array<FieldError>>;
  success?: Maybe<Scalars['Boolean']['output']>;
  userPracticeConnection?: Maybe<UserPracticeLink>;
};

/** Autogenerated input type of CopyTreatmentsPreset */
export type CopyTreatmentsPresetInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The Id of the preset you want to copy */
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of CopyTreatmentsPreset. */
export type CopyTreatmentsPresetPayload = {
  __typename?: 'CopyTreatmentsPresetPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  fieldErrors?: Maybe<Array<FieldError>>;
  success?: Maybe<Scalars['Boolean']['output']>;
  treatmentsPreset?: Maybe<TreatmentsPreset>;
};

export enum Country {
  /** Andorra */
  Ad = 'AD',
  /** United Arab Emirates */
  Ae = 'AE',
  /** Afghanistan */
  Af = 'AF',
  /** Antigua and Barbuda */
  Ag = 'AG',
  /** Anguilla */
  Ai = 'AI',
  /** Albania */
  Al = 'AL',
  /** Armenia */
  Am = 'AM',
  /** Angola */
  Ao = 'AO',
  /** Antarctica */
  Aq = 'AQ',
  /** Argentina */
  Ar = 'AR',
  /** American Samoa */
  As = 'AS',
  /** Austria */
  At = 'AT',
  /** Australia */
  Au = 'AU',
  /** Aruba */
  Aw = 'AW',
  /** Åland Islands */
  Ax = 'AX',
  /** Azerbaijan */
  Az = 'AZ',
  /** Bosnia and Herzegovina */
  Ba = 'BA',
  /** Barbados */
  Bb = 'BB',
  /** Bangladesh */
  Bd = 'BD',
  /** Belgium */
  Be = 'BE',
  /** Burkina Faso */
  Bf = 'BF',
  /** Bulgaria */
  Bg = 'BG',
  /** Bahrain */
  Bh = 'BH',
  /** Burundi */
  Bi = 'BI',
  /** Benin */
  Bj = 'BJ',
  /** Saint Barthélemy */
  Bl = 'BL',
  /** Bermuda */
  Bm = 'BM',
  /** Brunei Darussalam */
  Bn = 'BN',
  /** Bolivia */
  Bo = 'BO',
  /** Bonaire, Sint Eustatius and Saba */
  Bq = 'BQ',
  /** Brazil */
  Br = 'BR',
  /** Bahamas */
  Bs = 'BS',
  /** Bhutan */
  Bt = 'BT',
  /** Bouvet Island */
  Bv = 'BV',
  /** Botswana */
  Bw = 'BW',
  /** Belarus */
  By = 'BY',
  /** Belize */
  Bz = 'BZ',
  /** Canada */
  Ca = 'CA',
  /** Cocos (Keeling) Islands */
  Cc = 'CC',
  /** Congo, The Democratic Republic of the */
  Cd = 'CD',
  /** Central African Republic */
  Cf = 'CF',
  /** Congo */
  Cg = 'CG',
  /** Switzerland */
  Ch = 'CH',
  /** Côte d'Ivoire */
  Ci = 'CI',
  /** Cook Islands */
  Ck = 'CK',
  /** Chile */
  Cl = 'CL',
  /** Cameroon */
  Cm = 'CM',
  /** China */
  Cn = 'CN',
  /** Colombia */
  Co = 'CO',
  /** Costa Rica */
  Cr = 'CR',
  /** Cuba */
  Cu = 'CU',
  /** Cabo Verde */
  Cv = 'CV',
  /** Curaçao */
  Cw = 'CW',
  /** Christmas Island */
  Cx = 'CX',
  /** Cyprus */
  Cy = 'CY',
  /** Czechia */
  Cz = 'CZ',
  /** Germany */
  De = 'DE',
  /** Djibouti */
  Dj = 'DJ',
  /** Denmark */
  Dk = 'DK',
  /** Dominica */
  Dm = 'DM',
  /** Dominican Republic */
  Do = 'DO',
  /** Algeria */
  Dz = 'DZ',
  /** Ecuador */
  Ec = 'EC',
  /** Estonia */
  Ee = 'EE',
  /** Egypt */
  Eg = 'EG',
  /** Western Sahara */
  Eh = 'EH',
  /** Eritrea */
  Er = 'ER',
  /** Spain */
  Es = 'ES',
  /** Ethiopia */
  Et = 'ET',
  /** Finland */
  Fi = 'FI',
  /** Fiji */
  Fj = 'FJ',
  /** Falkland Islands (Malvinas) */
  Fk = 'FK',
  /** Micronesia, Federated States of */
  Fm = 'FM',
  /** Faroe Islands */
  Fo = 'FO',
  /** France */
  Fr = 'FR',
  /** Gabon */
  Ga = 'GA',
  /** United Kingdom */
  Gb = 'GB',
  /** Grenada */
  Gd = 'GD',
  /** Georgia */
  Ge = 'GE',
  /** French Guiana */
  Gf = 'GF',
  /** Guernsey */
  Gg = 'GG',
  /** Ghana */
  Gh = 'GH',
  /** Gibraltar */
  Gi = 'GI',
  /** Greenland */
  Gl = 'GL',
  /** Gambia */
  Gm = 'GM',
  /** Guinea */
  Gn = 'GN',
  /** Guadeloupe */
  Gp = 'GP',
  /** Equatorial Guinea */
  Gq = 'GQ',
  /** Greece */
  Gr = 'GR',
  /** South Georgia and the South Sandwich Islands */
  Gs = 'GS',
  /** Guatemala */
  Gt = 'GT',
  /** Guam */
  Gu = 'GU',
  /** Guinea-Bissau */
  Gw = 'GW',
  /** Guyana */
  Gy = 'GY',
  /** Hong Kong */
  Hk = 'HK',
  /** Heard Island and McDonald Islands */
  Hm = 'HM',
  /** Honduras */
  Hn = 'HN',
  /** Croatia */
  Hr = 'HR',
  /** Haiti */
  Ht = 'HT',
  /** Hungary */
  Hu = 'HU',
  /** Indonesia */
  Id = 'ID',
  /** Ireland */
  Ie = 'IE',
  /** Israel */
  Il = 'IL',
  /** Isle of Man */
  Im = 'IM',
  /** India */
  In = 'IN',
  /** British Indian Ocean Territory */
  Io = 'IO',
  /** Iraq */
  Iq = 'IQ',
  /** Iran */
  Ir = 'IR',
  /** Iceland */
  Is = 'IS',
  /** Italy */
  It = 'IT',
  /** Jersey */
  Je = 'JE',
  /** Jamaica */
  Jm = 'JM',
  /** Jordan */
  Jo = 'JO',
  /** Japan */
  Jp = 'JP',
  /** Kenya */
  Ke = 'KE',
  /** Kyrgyzstan */
  Kg = 'KG',
  /** Cambodia */
  Kh = 'KH',
  /** Kiribati */
  Ki = 'KI',
  /** Comoros */
  Km = 'KM',
  /** Saint Kitts and Nevis */
  Kn = 'KN',
  /** North Korea */
  Kp = 'KP',
  /** South Korea */
  Kr = 'KR',
  /** Kuwait */
  Kw = 'KW',
  /** Cayman Islands */
  Ky = 'KY',
  /** Kazakhstan */
  Kz = 'KZ',
  /** Lao People's Democratic Republic */
  La = 'LA',
  /** Lebanon */
  Lb = 'LB',
  /** Saint Lucia */
  Lc = 'LC',
  /** Liechtenstein */
  Li = 'LI',
  /** Sri Lanka */
  Lk = 'LK',
  /** Liberia */
  Lr = 'LR',
  /** Lesotho */
  Ls = 'LS',
  /** Lithuania */
  Lt = 'LT',
  /** Luxembourg */
  Lu = 'LU',
  /** Latvia */
  Lv = 'LV',
  /** Libya */
  Ly = 'LY',
  /** Morocco */
  Ma = 'MA',
  /** Monaco */
  Mc = 'MC',
  /** Moldova */
  Md = 'MD',
  /** Montenegro */
  Me = 'ME',
  /** Saint Martin (French part) */
  Mf = 'MF',
  /** Madagascar */
  Mg = 'MG',
  /** Marshall Islands */
  Mh = 'MH',
  /** North Macedonia */
  Mk = 'MK',
  /** Mali */
  Ml = 'ML',
  /** Myanmar */
  Mm = 'MM',
  /** Mongolia */
  Mn = 'MN',
  /** Macao */
  Mo = 'MO',
  /** Northern Mariana Islands */
  Mp = 'MP',
  /** Martinique */
  Mq = 'MQ',
  /** Mauritania */
  Mr = 'MR',
  /** Montserrat */
  Ms = 'MS',
  /** Malta */
  Mt = 'MT',
  /** Mauritius */
  Mu = 'MU',
  /** Maldives */
  Mv = 'MV',
  /** Malawi */
  Mw = 'MW',
  /** Mexico */
  Mx = 'MX',
  /** Malaysia */
  My = 'MY',
  /** Mozambique */
  Mz = 'MZ',
  /** Namibia */
  Na = 'NA',
  /** New Caledonia */
  Nc = 'NC',
  /** Niger */
  Ne = 'NE',
  /** Norfolk Island */
  Nf = 'NF',
  /** Nigeria */
  Ng = 'NG',
  /** Nicaragua */
  Ni = 'NI',
  /** Netherlands */
  Nl = 'NL',
  /** Norway */
  No = 'NO',
  /** Nepal */
  Np = 'NP',
  /** Nauru */
  Nr = 'NR',
  /** Niue */
  Nu = 'NU',
  /** New Zealand */
  Nz = 'NZ',
  /** Oman */
  Om = 'OM',
  /** Panama */
  Pa = 'PA',
  /** Peru */
  Pe = 'PE',
  /** French Polynesia */
  Pf = 'PF',
  /** Papua New Guinea */
  Pg = 'PG',
  /** Philippines */
  Ph = 'PH',
  /** Pakistan */
  Pk = 'PK',
  /** Poland */
  Pl = 'PL',
  /** Saint Pierre and Miquelon */
  Pm = 'PM',
  /** Pitcairn */
  Pn = 'PN',
  /** Puerto Rico */
  Pr = 'PR',
  /** Palestine, State of */
  Ps = 'PS',
  /** Portugal */
  Pt = 'PT',
  /** Palau */
  Pw = 'PW',
  /** Paraguay */
  Py = 'PY',
  /** Qatar */
  Qa = 'QA',
  /** Réunion */
  Re = 'RE',
  /** Romania */
  Ro = 'RO',
  /** Serbia */
  Rs = 'RS',
  /** Russian Federation */
  Ru = 'RU',
  /** Rwanda */
  Rw = 'RW',
  /** Saudi Arabia */
  Sa = 'SA',
  /** Solomon Islands */
  Sb = 'SB',
  /** Seychelles */
  Sc = 'SC',
  /** Sudan */
  Sd = 'SD',
  /** Sweden */
  Se = 'SE',
  /** Singapore */
  Sg = 'SG',
  /** Saint Helena, Ascension and Tristan da Cunha */
  Sh = 'SH',
  /** Slovenia */
  Si = 'SI',
  /** Svalbard and Jan Mayen */
  Sj = 'SJ',
  /** Slovakia */
  Sk = 'SK',
  /** Sierra Leone */
  Sl = 'SL',
  /** San Marino */
  Sm = 'SM',
  /** Senegal */
  Sn = 'SN',
  /** Somalia */
  So = 'SO',
  /** Suriname */
  Sr = 'SR',
  /** South Sudan */
  Ss = 'SS',
  /** Sao Tome and Principe */
  St = 'ST',
  /** El Salvador */
  Sv = 'SV',
  /** Sint Maarten (Dutch part) */
  Sx = 'SX',
  /** Syrian Arab Republic */
  Sy = 'SY',
  /** Eswatini */
  Sz = 'SZ',
  /** Turks and Caicos Islands */
  Tc = 'TC',
  /** Chad */
  Td = 'TD',
  /** French Southern Territories */
  Tf = 'TF',
  /** Togo */
  Tg = 'TG',
  /** Thailand */
  Th = 'TH',
  /** Tajikistan */
  Tj = 'TJ',
  /** Tokelau */
  Tk = 'TK',
  /** Timor-Leste */
  Tl = 'TL',
  /** Turkmenistan */
  Tm = 'TM',
  /** Tunisia */
  Tn = 'TN',
  /** Tonga */
  To = 'TO',
  /** Türkiye */
  Tr = 'TR',
  /** Trinidad and Tobago */
  Tt = 'TT',
  /** Tuvalu */
  Tv = 'TV',
  /** Taiwan */
  Tw = 'TW',
  /** Tanzania */
  Tz = 'TZ',
  /** Ukraine */
  Ua = 'UA',
  /** Uganda */
  Ug = 'UG',
  /** United States Minor Outlying Islands */
  Um = 'UM',
  /** United States */
  Us = 'US',
  /** Uruguay */
  Uy = 'UY',
  /** Uzbekistan */
  Uz = 'UZ',
  /** Holy See (Vatican City State) */
  Va = 'VA',
  /** Saint Vincent and the Grenadines */
  Vc = 'VC',
  /** Venezuela */
  Ve = 'VE',
  /** Virgin Islands, British */
  Vg = 'VG',
  /** Virgin Islands, U.S. */
  Vi = 'VI',
  /** Vietnam */
  Vn = 'VN',
  /** Vanuatu */
  Vu = 'VU',
  /** Wallis and Futuna */
  Wf = 'WF',
  /** Samoa */
  Ws = 'WS',
  /** Yemen */
  Ye = 'YE',
  /** Mayotte */
  Yt = 'YT',
  /** South Africa */
  Za = 'ZA',
  /** Zambia */
  Zm = 'ZM',
  /** Zimbabwe */
  Zw = 'ZW'
}

export type CountryTranslation = {
  __typename?: 'CountryTranslation';
  name?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

export type CouponCode = {
  __typename?: 'CouponCode';
  amountOff?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  percentOff?: Maybe<Scalars['Float']['output']>;
  valid?: Maybe<Scalars['Boolean']['output']>;
};

/** Direct URLs for cover photos uploaded with CoverPhotoUploader */
export type CoverPhotoUploaderAwsLinks = {
  __typename?: 'CoverPhotoUploaderAWSLinks';
  /** AWS URL for the `cover` processed version of this file */
  cover?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  /** AWS URL for the `preview` processed version of this file */
  preview?: Maybe<Scalars['String']['output']>;
  /** Original URL of the attachment */
  url?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of CreateAlert */
export type CreateAlertInput = {
  /** ID of patient to create and assign alert */
  assigneeId?: InputMaybe<Scalars['ID']['input']>;
  attributes: AlertCreateInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of CreateAlert. */
export type CreateAlertPayload = {
  __typename?: 'CreateAlertPayload';
  alert?: Maybe<Alert>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  fieldErrors?: Maybe<Array<FieldError>>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

/** Autogenerated input type of CreateAppointmentForTreatmentPlan */
export type CreateAppointmentForTreatmentPlanInput = {
  appointmentReasonId?: InputMaybe<Scalars['ID']['input']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  position?: InputMaybe<Scalars['Int']['input']>;
  priceListId?: InputMaybe<Scalars['ID']['input']>;
  treatmentPlanId: Scalars['ID']['input'];
};

/** Autogenerated return type of CreateAppointmentForTreatmentPlan. */
export type CreateAppointmentForTreatmentPlanPayload = {
  __typename?: 'CreateAppointmentForTreatmentPlanPayload';
  appointment?: Maybe<Appointment>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  fieldErrors?: Maybe<Array<FieldError>>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

/** Autogenerated input type of CreateAppointment */
export type CreateAppointmentInput = {
  attributes: AppointmentCreateInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of CreateAppointment. */
export type CreateAppointmentPayload = {
  __typename?: 'CreateAppointmentPayload';
  appointment?: Maybe<Appointment>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  fieldErrors?: Maybe<Array<FieldError>>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

/** Autogenerated input type of CreateAppointmentReason */
export type CreateAppointmentReasonInput = {
  /** The attributes to create */
  attributes: AppointmentReasonInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of CreateAppointmentReason. */
export type CreateAppointmentReasonPayload = {
  __typename?: 'CreateAppointmentReasonPayload';
  appointmentReason?: Maybe<AppointmentReason>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  fieldErrors?: Maybe<Array<FieldError>>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

/** Autogenerated input type of CreateAppointments */
export type CreateAppointmentsInput = {
  attributes: Array<AppointmentCreateInput>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of CreateAppointments. */
export type CreateAppointmentsPayload = {
  __typename?: 'CreateAppointmentsPayload';
  appointments?: Maybe<Array<Appointment>>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  fieldErrors?: Maybe<Array<FieldError>>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

/** Autogenerated input type of CreateAttachment */
export type CreateAttachmentInput = {
  attributes: AttachmentCreateInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of CreateAttachment. */
export type CreateAttachmentPayload = {
  __typename?: 'CreateAttachmentPayload';
  attachment?: Maybe<Attachment>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  fieldErrors?: Maybe<Array<FieldError>>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

/** Autogenerated input type of CreateAutomaticRecall */
export type CreateAutomaticRecallInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The id of the UPC */
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of CreateAutomaticRecall. */
export type CreateAutomaticRecallPayload = {
  __typename?: 'CreateAutomaticRecallPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  fieldErrors?: Maybe<Array<FieldError>>;
  success?: Maybe<Scalars['Boolean']['output']>;
  userPracticeConnection?: Maybe<UserPracticeLink>;
};

/** Autogenerated input type of CreateBadgesBadgeLink */
export type CreateBadgesBadgeLinkInput = {
  /** The attributes to create a new cycle */
  attributes: BadgesBadgeLinkInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of CreateBadgesBadgeLink. */
export type CreateBadgesBadgeLinkPayload = {
  __typename?: 'CreateBadgesBadgeLinkPayload';
  badgeable?: Maybe<Array<Badgeable>>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** General errors, unrelated to fields */
  errors?: Maybe<Array<Scalars['String']['output']>>;
  /** Input field errors */
  fieldErrors?: Maybe<Array<FieldError>>;
  /** Whether or not this mutation was successful */
  success?: Maybe<Scalars['Boolean']['output']>;
};

/** Autogenerated input type of CreateBadgesTemplate */
export type CreateBadgesTemplateInput = {
  /** The attributes to create a new cycle */
  attributes: BadgesTemplateInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of CreateBadgesTemplate. */
export type CreateBadgesTemplatePayload = {
  __typename?: 'CreateBadgesTemplatePayload';
  badgesTemplate?: Maybe<BadgesTemplate>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  fieldErrors?: Maybe<Array<FieldError>>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

/** Autogenerated input type of CreateCancellationReason */
export type CreateCancellationReasonInput = {
  /** The attributes to create */
  attributes: CancellationReasonInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of CreateCancellationReason. */
export type CreateCancellationReasonPayload = {
  __typename?: 'CreateCancellationReasonPayload';
  cancellationReason?: Maybe<CancellationReason>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  fieldErrors?: Maybe<Array<FieldError>>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

/** Autogenerated input type of CreateCatalogueItem */
export type CreateCatalogueItemInput = {
  attributes: CatalogueItemInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of CreateCatalogueItem. */
export type CreateCatalogueItemPayload = {
  __typename?: 'CreateCatalogueItemPayload';
  catalogueItem?: Maybe<CatalogueItem>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  fieldErrors?: Maybe<Array<FieldError>>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

/** Autogenerated input type of CreateCategory */
export type CreateCategoryInput = {
  attributes: CategoryCreateInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of CreateCategory. */
export type CreateCategoryPayload = {
  __typename?: 'CreateCategoryPayload';
  category?: Maybe<Category>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  fieldErrors?: Maybe<Array<FieldError>>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type CreateCommunicationsInput = {
  /** Attachments to add to the message (by ID) */
  attachments?: InputMaybe<Array<Scalars['ID']['input']>>;
  communicationType: CommunicationType;
  email?: InputMaybe<EmailInput>;
  letter?: InputMaybe<LetterInput>;
  /** Optionally associated communications with a record */
  messageable?: InputMaybe<Scalars['String']['input']>;
  methods: CommunicationInput;
  /** Private note added to the message */
  note?: InputMaybe<Scalars['String']['input']>;
  recipientContacts?: InputMaybe<Array<ContactOverrideRelayInput>>;
  sms?: InputMaybe<SmsInput>;
  /** Template to use for sending this communication */
  template?: InputMaybe<Scalars['ID']['input']>;
  userPracticeConnectionIds: Array<Scalars['ID']['input']>;
};

export type CreateConnectedAccountAttributes = {
  accountNumber: Scalars['String']['input'];
  addressLine: Scalars['String']['input'];
  city: Scalars['String']['input'];
  country: Scalars['String']['input'];
  dateOfBirth: Scalars['String']['input'];
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  identityDocumentBack?: InputMaybe<Scalars['String']['input']>;
  identityDocumentFront?: InputMaybe<Scalars['String']['input']>;
  industry: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  paymentProviderId: Scalars['ID']['input'];
  phoneNumber: Scalars['String']['input'];
  postalCode: Scalars['String']['input'];
  routingNumber: Scalars['String']['input'];
  source: Scalars['String']['input'];
  state: Scalars['String']['input'];
  website: Scalars['String']['input'];
};

/** Autogenerated input type of CreateConnectedAccount */
export type CreateConnectedAccountInput = {
  attributes: CreateConnectedAccountAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of CreateConnectedAccount. */
export type CreateConnectedAccountPayload = {
  __typename?: 'CreateConnectedAccountPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  fieldErrors?: Maybe<Array<FieldError>>;
  paymentProvider?: Maybe<PaymentProvider>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

/** Autogenerated input type of CreateCustomImport */
export type CreateCustomImportInput = {
  attributes: CustomImportInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of CreateCustomImport. */
export type CreateCustomImportPayload = {
  __typename?: 'CreateCustomImportPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  customImport?: Maybe<CustomImport>;
};

/** Autogenerated input type of CreateCustomerRequest */
export type CreateCustomerRequestInput = {
  attributes: CustomerRequestInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of CreateCustomerRequest. */
export type CreateCustomerRequestPayload = {
  __typename?: 'CreateCustomerRequestPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  customerRequest?: Maybe<CustomerRequest>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  fieldErrors?: Maybe<Array<FieldError>>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

/** Autogenerated input type of CreateDirectory */
export type CreateDirectoryInput = {
  attributes: DirectoryInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of CreateDirectory. */
export type CreateDirectoryPayload = {
  __typename?: 'CreateDirectoryPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  directory?: Maybe<Directory>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  fieldErrors?: Maybe<Array<FieldError>>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

/** Autogenerated input type of CreateEvent */
export type CreateEventInput = {
  attributes: EventInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  connectionId: Scalars['ID']['input'];
};

/** Autogenerated return type of CreateEvent. */
export type CreateEventPayload = {
  __typename?: 'CreateEventPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  fieldErrors?: Maybe<Array<FieldError>>;
  rosterEvent?: Maybe<RosterEvent>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

/** Autogenerated input type of CreateFamily */
export type CreateFamilyInput = {
  /** The attributes to create a family */
  attributes: FamilyCreateInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of CreateFamily. */
export type CreateFamilyPayload = {
  __typename?: 'CreateFamilyPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  family?: Maybe<Family>;
  fieldErrors?: Maybe<Array<FieldError>>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

/** Autogenerated input type of CreateField */
export type CreateFieldInput = {
  /** Attributes for the field */
  attributes: FormFieldInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of CreateField. */
export type CreateFieldPayload = {
  __typename?: 'CreateFieldPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** General errors, unrelated to fields */
  errors?: Maybe<Array<Scalars['String']['output']>>;
  /** Input field errors */
  fieldErrors?: Maybe<Array<FieldError>>;
  section?: Maybe<FormSection>;
  /** Whether or not this mutation was successful */
  success?: Maybe<Scalars['Boolean']['output']>;
};

/** Autogenerated input type of CreateForm */
export type CreateFormInput = {
  attributes: FormCreateInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of CreateForm. */
export type CreateFormPayload = {
  __typename?: 'CreateFormPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  fieldErrors?: Maybe<Array<FieldError>>;
  form?: Maybe<Form>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

/** Autogenerated input type of CreateFormTemplate */
export type CreateFormTemplateInput = {
  attributes: FormTemplateInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of CreateFormTemplate. */
export type CreateFormTemplatePayload = {
  __typename?: 'CreateFormTemplatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  fieldErrors?: Maybe<Array<FieldError>>;
  success?: Maybe<Scalars['Boolean']['output']>;
  template?: Maybe<FormTemplate>;
};

/** Autogenerated input type of CreateGroup */
export type CreateGroupInput = {
  attributes: GroupInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of CreateGroup. */
export type CreateGroupPayload = {
  __typename?: 'CreateGroupPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  fieldErrors?: Maybe<Array<FieldError>>;
  group?: Maybe<Group>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

/** Autogenerated input type of CreateInvoice */
export type CreateInvoiceInput = {
  attributes: InvoiceCreateInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated input type of CreateInvoiceItem */
export type CreateInvoiceItemInput = {
  attributes: InvoiceItemCreateInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of CreateInvoiceItem. */
export type CreateInvoiceItemPayload = {
  __typename?: 'CreateInvoiceItemPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  fieldErrors?: Maybe<Array<FieldError>>;
  invoiceItem?: Maybe<InvoiceItem>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

/** Autogenerated return type of CreateInvoice. */
export type CreateInvoicePayload = {
  __typename?: 'CreateInvoicePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  fieldErrors?: Maybe<Array<FieldError>>;
  invoice?: Maybe<Invoice>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

/** Autogenerated input type of CreateInvoiceSettings */
export type CreateInvoiceSettingsInput = {
  attributes: InvoiceSettingsInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of CreateInvoiceSettings. */
export type CreateInvoiceSettingsPayload = {
  __typename?: 'CreateInvoiceSettingsPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** General errors, unrelated to fields */
  errors?: Maybe<Array<Scalars['String']['output']>>;
  /** Input field errors */
  fieldErrors?: Maybe<Array<FieldError>>;
  invoiceSetting?: Maybe<InvoiceSetting>;
  /** Whether or not this mutation was successful */
  success?: Maybe<Scalars['Boolean']['output']>;
};

/** Autogenerated input type of CreateInvoices */
export type CreateInvoicesInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Deliver Invoice Now */
  deliver?: InputMaybe<Scalars['Boolean']['input']>;
  /** Appointment ID */
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of CreateInvoices. */
export type CreateInvoicesPayload = {
  __typename?: 'CreateInvoicesPayload';
  appointment?: Maybe<Appointment>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  fieldErrors?: Maybe<Array<FieldError>>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

/** Autogenerated input type of CreateLabWork */
export type CreateLabWorkInput = {
  /** The attributes to create the lab work with */
  attributes: LabWorkCreateInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of CreateLabWork. */
export type CreateLabWorkPayload = {
  __typename?: 'CreateLabWorkPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  fieldErrors?: Maybe<Array<FieldError>>;
  labWork?: Maybe<LabWork>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

/** Autogenerated input type of CreateMaterial */
export type CreateMaterialInput = {
  attributes: MaterialInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of CreateMaterial. */
export type CreateMaterialPayload = {
  __typename?: 'CreateMaterialPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** General errors, unrelated to fields */
  errors?: Maybe<Array<Scalars['String']['output']>>;
  /** Input field errors */
  fieldErrors?: Maybe<Array<FieldError>>;
  material?: Maybe<Material>;
  /** Whether or not this mutation was successful */
  success?: Maybe<Scalars['Boolean']['output']>;
};

/** Autogenerated input type of CreateNote */
export type CreateNoteInput = {
  attributes: NoteInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of CreateNote. */
export type CreateNotePayload = {
  __typename?: 'CreateNotePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  fieldErrors?: Maybe<Array<FieldError>>;
  note?: Maybe<Note>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

/** Autogenerated input type of CreatePatientRecordItem */
export type CreatePatientRecordItemInput = {
  attributes: PatientRecordItemInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of CreatePatientRecordItem. */
export type CreatePatientRecordItemPayload = {
  __typename?: 'CreatePatientRecordItemPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  fieldErrors?: Maybe<Array<FieldError>>;
  patientRecordItem?: Maybe<PatientRecordItem>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

/** Autogenerated input type of CreatePerioExam */
export type CreatePerioExamInput = {
  attributes: DentalCreatePerioExamInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of CreatePerioExam. */
export type CreatePerioExamPayload = {
  __typename?: 'CreatePerioExamPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  fieldErrors?: Maybe<Array<FieldError>>;
  perioExam?: Maybe<DentalPerioExam>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

/** Autogenerated input type of CreatePractice */
export type CreatePracticeInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  industryId: Scalars['ID']['input'];
  mobilePhone: Scalars['String']['input'];
  mobileRegionCode: Scalars['Int']['input'];
  mobileRegionShort: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

/** Autogenerated input type of CreatePracticeLocation */
export type CreatePracticeLocationInput = {
  attributes: PracticeLocationsInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of CreatePracticeLocation. */
export type CreatePracticeLocationPayload = {
  __typename?: 'CreatePracticeLocationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** General errors, unrelated to fields */
  errors?: Maybe<Array<Scalars['String']['output']>>;
  /** Input field errors */
  fieldErrors?: Maybe<Array<FieldError>>;
  practiceLocation?: Maybe<PracticeLocation>;
  /** Whether or not this mutation was successful */
  success?: Maybe<Scalars['Boolean']['output']>;
};

/** Autogenerated return type of CreatePractice. */
export type CreatePracticePayload = {
  __typename?: 'CreatePracticePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  fieldErrors?: Maybe<Array<FieldError>>;
  practice?: Maybe<Practice>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

/** Autogenerated input type of CreatePriceList */
export type CreatePriceListInput = {
  attributes: PriceListInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of CreatePriceList. */
export type CreatePriceListPayload = {
  __typename?: 'CreatePriceListPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  fieldErrors?: Maybe<Array<FieldError>>;
  priceList?: Maybe<PriceList>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

/** Autogenerated input type of CreateRecall */
export type CreateRecallInput = {
  attributes: RecallInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of CreateRecall. */
export type CreateRecallPayload = {
  __typename?: 'CreateRecallPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  fieldErrors?: Maybe<Array<FieldError>>;
  recall?: Maybe<Recall>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

/** Autogenerated input type of CreateRecurringTask */
export type CreateRecurringTaskInput = {
  /** The attributes to create a recurring task */
  attributes: RecurringTaskInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of CreateRecurringTask. */
export type CreateRecurringTaskPayload = {
  __typename?: 'CreateRecurringTaskPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  fieldErrors?: Maybe<Array<FieldError>>;
  recurringTask?: Maybe<RecurringTask>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

/** Autogenerated input type of CreateReferral */
export type CreateReferralInput = {
  /** The attributes to create a referral with */
  attributes: ReferralCreateInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of CreateReferral. */
export type CreateReferralPayload = {
  __typename?: 'CreateReferralPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  fieldErrors?: Maybe<Array<FieldError>>;
  referral?: Maybe<Referral>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

/** Autogenerated input type of CreateReferralSource */
export type CreateReferralSourceInput = {
  attributes: ReferralSourceInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of CreateReferralSource. */
export type CreateReferralSourcePayload = {
  __typename?: 'CreateReferralSourcePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  fieldErrors?: Maybe<Array<FieldError>>;
  referralSource?: Maybe<ReferralSource>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

/** Autogenerated input type of CreateReportsSavedReport */
export type CreateReportsSavedReportInput = {
  /** The attributes to create the saved report */
  attributes: ReportsSavedReportInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of CreateReportsSavedReport. */
export type CreateReportsSavedReportPayload = {
  __typename?: 'CreateReportsSavedReportPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  fieldErrors?: Maybe<Array<FieldError>>;
  reportsSavedReport?: Maybe<ReportsSavedReport>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

/** Autogenerated input type of CreateReservation */
export type CreateReservationInput = {
  /** Appointment Reason GUID (optional) */
  appointmentReason?: InputMaybe<Scalars['ID']['input']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Appointment GUID can be used to initialize with an existing draft appointment */
  draftAppointment?: InputMaybe<Scalars['ID']['input']>;
  /** End time for the appointment */
  endTime: Scalars['ISO8601DateTime']['input'];
  /** Preferred practitioner to book appointment with */
  practitioner: Scalars['ID']['input'];
  /** Start time for the appointment */
  startTime: Scalars['ISO8601DateTime']['input'];
};

/** Autogenerated return type of CreateReservation. */
export type CreateReservationPayload = {
  __typename?: 'CreateReservationPayload';
  /** Scheduled appointment */
  appointment?: Maybe<Appointment>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  fieldErrors?: Maybe<Array<FieldError>>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

/** Autogenerated input type of CreateReviewResponse */
export type CreateReviewResponseInput = {
  attributes: ReviewResponseInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of CreateReviewResponse. */
export type CreateReviewResponsePayload = {
  __typename?: 'CreateReviewResponsePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** General errors, unrelated to fields */
  errors?: Maybe<Array<Scalars['String']['output']>>;
  /** Input field errors */
  fieldErrors?: Maybe<Array<FieldError>>;
  reviewResponse?: Maybe<ReviewResponse>;
  /** Whether or not this mutation was successful */
  success?: Maybe<Scalars['Boolean']['output']>;
};

/** Autogenerated input type of CreateReviewTemplate */
export type CreateReviewTemplateInput = {
  attributes: ReviewTemplateInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of CreateReviewTemplate. */
export type CreateReviewTemplatePayload = {
  __typename?: 'CreateReviewTemplatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** General errors, unrelated to fields */
  errors?: Maybe<Array<Scalars['String']['output']>>;
  /** Input field errors */
  fieldErrors?: Maybe<Array<FieldError>>;
  reviewTemplate?: Maybe<ReviewTemplate>;
  /** Whether or not this mutation was successful */
  success?: Maybe<Scalars['Boolean']['output']>;
};

/** Autogenerated input type of CreateRichText */
export type CreateRichTextInput = {
  /** Attributes to create a RichText */
  attributes: RichTextInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of CreateRichText. */
export type CreateRichTextPayload = {
  __typename?: 'CreateRichTextPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** General errors, unrelated to fields */
  errors?: Maybe<Array<Scalars['String']['output']>>;
  /** Input field errors */
  fieldErrors?: Maybe<Array<FieldError>>;
  richText?: Maybe<RichText>;
  /** Whether or not this mutation was successful */
  success?: Maybe<Scalars['Boolean']['output']>;
};

/** Autogenerated input type of CreateRoom */
export type CreateRoomInput = {
  /** Attributes to create a room */
  attributes: RoomInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of CreateRoom. */
export type CreateRoomPayload = {
  __typename?: 'CreateRoomPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  fieldErrors?: Maybe<Array<FieldError>>;
  room?: Maybe<Room>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

/** Autogenerated input type of CreateSetupIntent */
export type CreateSetupIntentInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of CreateSetupIntent. */
export type CreateSetupIntentPayload = {
  __typename?: 'CreateSetupIntentPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  clientSecret?: Maybe<Scalars['String']['output']>;
  /** General errors, unrelated to fields */
  errors?: Maybe<Array<Scalars['String']['output']>>;
  /** Input field errors */
  fieldErrors?: Maybe<Array<FieldError>>;
  /** Whether or not this mutation was successful */
  success?: Maybe<Scalars['Boolean']['output']>;
};

/** Autogenerated input type of CreateShift */
export type CreateShiftInput = {
  attributes: ShiftTypeInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of CreateShift. */
export type CreateShiftPayload = {
  __typename?: 'CreateShiftPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  fieldErrors?: Maybe<Array<FieldError>>;
  shiftType?: Maybe<RosterShiftType>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

/** Autogenerated input type of CreateSnippet */
export type CreateSnippetInput = {
  attributes: SnippetInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of CreateSnippet. */
export type CreateSnippetPayload = {
  __typename?: 'CreateSnippetPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  fieldErrors?: Maybe<Array<FieldError>>;
  snippet?: Maybe<Snippet>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

/** Autogenerated input type of CreateSterilisationAutoclave */
export type CreateSterilisationAutoclaveInput = {
  /** The attributes to create a new autoclave */
  attributes: SterilisationAutoclaveInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of CreateSterilisationAutoclave. */
export type CreateSterilisationAutoclavePayload = {
  __typename?: 'CreateSterilisationAutoclavePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  fieldErrors?: Maybe<Array<FieldError>>;
  sterilisationAutoclave?: Maybe<SterilisationAutoclave>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

/** Autogenerated input type of CreateSterilisationCycle */
export type CreateSterilisationCycleInput = {
  /** The attributes to create a new cycle */
  attributes: SterilisationCycleInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of CreateSterilisationCycle. */
export type CreateSterilisationCyclePayload = {
  __typename?: 'CreateSterilisationCyclePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  fieldErrors?: Maybe<Array<FieldError>>;
  sterilisationCycle?: Maybe<SterilisationCycle>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

/** Autogenerated input type of CreateSterilisationCycleType */
export type CreateSterilisationCycleTypeInput = {
  /** The attributes to create a new cycle type */
  attributes: SterilisationCycleTypeInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of CreateSterilisationCycleType. */
export type CreateSterilisationCycleTypePayload = {
  __typename?: 'CreateSterilisationCycleTypePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  fieldErrors?: Maybe<Array<FieldError>>;
  sterilisationCycleType?: Maybe<SterilisationCycleType>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

/** Autogenerated input type of CreateSterilisationTracking */
export type CreateSterilisationTrackingInput = {
  /** The attributes to create a new tracking */
  attributes: SterilisationTrackingInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of CreateSterilisationTracking. */
export type CreateSterilisationTrackingPayload = {
  __typename?: 'CreateSterilisationTrackingPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  fieldErrors?: Maybe<Array<FieldError>>;
  sterilisationTracking?: Maybe<SterilisationTracking>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

/** Autogenerated input type of CreateTag */
export type CreateTagInput = {
  attributes?: InputMaybe<TagInput>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of CreateTag. */
export type CreateTagPayload = {
  __typename?: 'CreateTagPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** General errors, unrelated to fields */
  errors?: Maybe<Array<Scalars['String']['output']>>;
  /** Input field errors */
  fieldErrors?: Maybe<Array<FieldError>>;
  /** Whether or not this mutation was successful */
  success?: Maybe<Scalars['Boolean']['output']>;
  tag?: Maybe<Tag>;
};

/** Autogenerated input type of CreateTask */
export type CreateTaskInput = {
  /** The attributes to create a task */
  attributes: TaskInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of CreateTask. */
export type CreateTaskPayload = {
  __typename?: 'CreateTaskPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  fieldErrors?: Maybe<Array<FieldError>>;
  success?: Maybe<Scalars['Boolean']['output']>;
  task?: Maybe<Task>;
};

/** Autogenerated input type of CreateTemplate */
export type CreateTemplateInput = {
  attributes: TemplateInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of CreateTemplate. */
export type CreateTemplatePayload = {
  __typename?: 'CreateTemplatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  fieldErrors?: Maybe<Array<FieldError>>;
  success?: Maybe<Scalars['Boolean']['output']>;
  template?: Maybe<TemplateUnion>;
};

/** Autogenerated input type of CreateToothConditions */
export type CreateToothConditionsInput = {
  /** Attributes to create the conditions with */
  attributes: Array<DentalCreateToothConditionInput>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Id of the patient (upc) */
  patientId: Scalars['ID']['input'];
};

/** Autogenerated return type of CreateToothConditions. */
export type CreateToothConditionsPayload = {
  __typename?: 'CreateToothConditionsPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  dentalToothConditions?: Maybe<Array<DentalToothCondition>>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  fieldErrors?: Maybe<Array<FieldError>>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

/** Autogenerated input type of CreateToothTreatment */
export type CreateToothTreatmentInput = {
  attributes: DentalCreateToothTreatmentInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of CreateToothTreatment. */
export type CreateToothTreatmentPayload = {
  __typename?: 'CreateToothTreatmentPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  dentalToothTreatment?: Maybe<DentalToothTreatment>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  fieldErrors?: Maybe<Array<FieldError>>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

/** Autogenerated input type of CreateToothTreatments */
export type CreateToothTreatmentsInput = {
  /** Attributes to create the treatments with */
  attributes: Array<DentalCreateToothTreatmentInput>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Id of the patient (upc) */
  patientId: Scalars['ID']['input'];
};

/** Autogenerated return type of CreateToothTreatments. */
export type CreateToothTreatmentsPayload = {
  __typename?: 'CreateToothTreatmentsPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  dentalToothTreatments?: Maybe<Array<DentalToothTreatment>>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  fieldErrors?: Maybe<Array<FieldError>>;
  success?: Maybe<Scalars['Boolean']['output']>;
  treatmentPlan?: Maybe<TreatmentPlan>;
};

/** Autogenerated input type of CreateTreatment */
export type CreateTreatmentInput = {
  attributes: TreatmentInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of CreateTreatment. */
export type CreateTreatmentPayload = {
  __typename?: 'CreateTreatmentPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  fieldErrors?: Maybe<Array<FieldError>>;
  success?: Maybe<Scalars['Boolean']['output']>;
  treatment?: Maybe<Treatment>;
};

/** Autogenerated input type of CreateTreatmentPlan */
export type CreateTreatmentPlanInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  treatmentId: Scalars['ID']['input'];
};

/** Autogenerated return type of CreateTreatmentPlan. */
export type CreateTreatmentPlanPayload = {
  __typename?: 'CreateTreatmentPlanPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  fieldErrors?: Maybe<Array<FieldError>>;
  success?: Maybe<Scalars['Boolean']['output']>;
  treatmentPlan?: Maybe<TreatmentPlan>;
};

/** Autogenerated input type of CreateTreatmentsPreset */
export type CreateTreatmentsPresetInput = {
  attributes: TreatmentsPresetCreateInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated input type of CreateTreatmentsPresetItem */
export type CreateTreatmentsPresetItemInput = {
  attributes: TreatmentsPresetItemCreateInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of CreateTreatmentsPresetItem. */
export type CreateTreatmentsPresetItemPayload = {
  __typename?: 'CreateTreatmentsPresetItemPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  fieldErrors?: Maybe<Array<FieldError>>;
  success?: Maybe<Scalars['Boolean']['output']>;
  treatmentsPresetItem?: Maybe<TreatmentsPresetItem>;
};

/** Autogenerated return type of CreateTreatmentsPreset. */
export type CreateTreatmentsPresetPayload = {
  __typename?: 'CreateTreatmentsPresetPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  fieldErrors?: Maybe<Array<FieldError>>;
  success?: Maybe<Scalars['Boolean']['output']>;
  treatmentsPreset?: Maybe<TreatmentsPreset>;
};

/** Autogenerated input type of CreateTyroTerminal */
export type CreateTyroTerminalInput = {
  attributes: TyroPaymentTerminalInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of CreateTyroTerminal. */
export type CreateTyroTerminalPayload = {
  __typename?: 'CreateTyroTerminalPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  fieldErrors?: Maybe<Array<FieldError>>;
  paymentTerminal?: Maybe<PaymentTerminal>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

/** Autogenerated input type of CreateUser */
export type CreateUserInput = {
  allowDuplicateEmail?: InputMaybe<Scalars['Boolean']['input']>;
  attributes: UserCreateInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of CreateUser. */
export type CreateUserPayload = {
  __typename?: 'CreateUserPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  fieldErrors?: Maybe<Array<FieldError>>;
  success?: Maybe<Scalars['Boolean']['output']>;
  userPracticeConnections?: Maybe<Array<UserPracticeLink>>;
};

/** Autogenerated input type of CreateWaitlist */
export type CreateWaitlistInput = {
  attributes: WaitlistCreateInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of CreateWaitlist. */
export type CreateWaitlistPayload = {
  __typename?: 'CreateWaitlistPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  fieldErrors?: Maybe<Array<FieldError>>;
  success?: Maybe<Scalars['Boolean']['output']>;
  waitlist?: Maybe<Waitlist>;
};

/** Autogenerated input type of CreateWorkflow */
export type CreateWorkflowInput = {
  attributes: WorkflowInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of CreateWorkflow. */
export type CreateWorkflowPayload = {
  __typename?: 'CreateWorkflowPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  fieldErrors?: Maybe<Array<FieldError>>;
  success?: Maybe<Scalars['Boolean']['output']>;
  workflow?: Maybe<Workflow>;
};

export type Credentials = {
  __typename?: 'Credentials';
  /** The authentication token */
  authToken: Scalars['String']['output'];
  /** UPC for this auth token */
  userPracticeConnection: UserPracticeLink;
};

export type CustomImport = {
  __typename?: 'CustomImport';
  completedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  createdAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  errorMessage?: Maybe<Scalars['String']['output']>;
  failedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  folderName?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  importTask?: Maybe<Scalars['String']['output']>;
  importType?: Maybe<Scalars['String']['output']>;
  jobId?: Maybe<Scalars['String']['output']>;
  mailedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  practice?: Maybe<Practice>;
  practiceId?: Maybe<Scalars['ID']['output']>;
  practiceLocation?: Maybe<PracticeLocation>;
  practiceLocationId?: Maybe<Scalars['ID']['output']>;
  startedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  state?: Maybe<CustomImportState>;
  updatedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
};

/** The connection type for CustomImport. */
export type CustomImportConnection = {
  __typename?: 'CustomImportConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<CustomImportEdge>>>;
  /** IDs of ALL results, regardless of pagination */
  ids: Array<Scalars['ID']['output']>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<CustomImport>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Returns total count of items in list */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type CustomImportEdge = {
  __typename?: 'CustomImportEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<CustomImport>;
};

export type CustomImportInput = {
  folder: Scalars['String']['input'];
  platform: CustomImportPlatform;
  practiceId: Scalars['ID']['input'];
  practiceLocationId: Scalars['ID']['input'];
  startDate?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
  subdomain: Scalars['String']['input'];
  task: Scalars['String']['input'];
};

export enum CustomImportPlatform {
  CorePractice = 'CorePractice',
  CorePracticeLegacy = 'CorePracticeLegacy',
  DentalWindows = 'DentalWindows',
  DentalWindowsLegacy = 'DentalWindowsLegacy',
  Dentally = 'Dentally',
  Dentrix = 'Dentrix',
  Exact = 'Exact',
  MacPractice = 'MacPractice',
  Mindbody = 'Mindbody',
  Oasis = 'Oasis',
  OasisLegacy = 'OasisLegacy',
  OpenDental = 'OpenDental',
  PracticeWorks = 'PracticeWorks',
  Praktika = 'Praktika',
  SimpleClinic = 'SimpleClinic',
  ZenoDental = 'ZenoDental'
}

export enum CustomImportState {
  Failed = 'failed',
  Imported = 'imported',
  Mailed = 'mailed',
  Pending = 'pending',
  Started = 'started'
}

export type CustomerRequest = AnyNode & {
  __typename?: 'CustomerRequest';
  accountId: Scalars['ID']['output'];
  actionable?: Maybe<AnyNode>;
  actionableId?: Maybe<Scalars['ID']['output']>;
  actionableType?: Maybe<Scalars['String']['output']>;
  actionableUuid?: Maybe<Scalars['String']['output']>;
  cancelledAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  completedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  createdAt: Scalars['ISO8601DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  /** The date and time the request was discarded */
  discardedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  expireAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  id: Scalars['ID']['output'];
  invoiceTransaction?: Maybe<InvoiceTransaction>;
  /** The ID of the invoice transaction that processed the payment */
  invoiceTransactionId?: Maybe<Scalars['ID']['output']>;
  metadata?: Maybe<CustomerRequestMetadata>;
  patient: UserPracticeLink;
  patientId: Scalars['ID']['output'];
  /** The ID of the payment transaction that processed the payment */
  paymentTransactionId?: Maybe<Scalars['ID']['output']>;
  pendingAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  practice: Practice;
  practiceId: Scalars['ID']['output'];
  practiceLocation: PracticeLocation;
  /** The ID of the practice location this invoice is assigned to */
  practiceLocationId?: Maybe<Scalars['ID']['output']>;
  practitioner: UserPracticeLink;
  practitionerId: Scalars['ID']['output'];
  receiptUrl?: Maybe<Scalars['String']['output']>;
  requestType: CustomerRequestsRequestType;
  requestable?: Maybe<AnyNode>;
  requestableId?: Maybe<Scalars['ID']['output']>;
  requestableType?: Maybe<Scalars['String']['output']>;
  requestableUuid?: Maybe<Scalars['String']['output']>;
  /** The review template associated with the request */
  reviewTemplate?: Maybe<ReviewTemplate>;
  state: CustomerRequestState;
  updatedAt: Scalars['ISO8601DateTime']['output'];
  url?: Maybe<Scalars['String']['output']>;
  /** Unique identifier of the message */
  uuid: Scalars['String']['output'];
};

/** The connection type for CustomerRequest. */
export type CustomerRequestConnection = {
  __typename?: 'CustomerRequestConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<CustomerRequestEdge>>>;
  /** IDs of ALL results, regardless of pagination */
  ids: Array<Scalars['ID']['output']>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<CustomerRequest>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Returns total count of items in list */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type CustomerRequestEdge = {
  __typename?: 'CustomerRequestEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<CustomerRequest>;
};

export type CustomerRequestInput = {
  depositAmount?: InputMaybe<Scalars['Float']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  expireAt?: InputMaybe<Scalars['ISO8601Date']['input']>;
  patientId: Scalars['ID']['input'];
  requestType: CustomerRequestsRequestType;
  /** Requestable object UUID */
  requestableUuid?: InputMaybe<Scalars['String']['input']>;
  reviewTemplateId?: InputMaybe<Scalars['ID']['input']>;
};

export type CustomerRequestMetadata = {
  __typename?: 'CustomerRequestMetadata';
  /** For deposit requests, the deposit amount requested by the practice */
  depositAmount?: Maybe<Scalars['Int']['output']>;
  /** The ID of the review template associated with the request */
  templateId?: Maybe<Scalars['ID']['output']>;
};

export enum CustomerRequestState {
  Cancelled = 'cancelled',
  Completed = 'completed',
  Expired = 'expired',
  PaymentFailed = 'payment_failed',
  PaymentProcessing = 'payment_processing',
  Pending = 'pending'
}

export type CustomerRequestUpdateInput = {
  depositAmount?: InputMaybe<Scalars['Float']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  expireAt?: InputMaybe<Scalars['ISO8601Date']['input']>;
  state?: InputMaybe<CustomerRequestState>;
};

export enum CustomerRequestsRequestType {
  /** Deposit */
  Deposit = 'deposit',
  /** Review */
  Review = 'review'
}

export type DashboardAnnotation = {
  __typename?: 'DashboardAnnotation';
  /** Colour for the annotation */
  colour?: Maybe<Scalars['String']['output']>;
  /** The ID of the annotation */
  id?: Maybe<Scalars['ID']['output']>;
  /** Label for the annotation */
  label?: Maybe<Scalars['String']['output']>;
  /** Value for the annotation. If this is a goal, it represents the day mode figure */
  value?: Maybe<Scalars['Int']['output']>;
  /** Widget that this annotation is attached to */
  widgetType?: Maybe<DashboardWidgetItem>;
};

export type DashboardAnnotationInput = {
  /** Colour for the annotation */
  colour: Scalars['String']['input'];
  /** Label for the annotation */
  label: Scalars['String']['input'];
  /** Value for the annotation. If this is a goal, it represents the day mode figure */
  value: Scalars['Int']['input'];
  /** Layouts for LG breakpoint */
  widgetType: DashboardWidgetItem;
};

export type DashboardFiltersInput = {
  /** Filter by patient (ID not GUID) */
  patientId?: InputMaybe<Scalars['ID']['input']>;
  /** Filter by average rating range */
  reviewAvgRatingRange?: InputMaybe<Array<Scalars['Float']['input']>>;
};

export type DashboardWidget = {
  __typename?: 'DashboardWidget';
  /** The height of the widget */
  h: Scalars['Int']['output'];
  /** The unique ID of the widget */
  i: DashboardWidgetItem;
  id?: Maybe<Scalars['ID']['output']>;
  /** The width of the widget */
  w: Scalars['Int']['output'];
  /** The x position of the widget */
  x: Scalars['Int']['output'];
  /** The y position of the widget */
  y: Scalars['Int']['output'];
};

export enum DashboardWidgetItem {
  DashboardAppointmentStatus = 'dashboard_appointment_status',
  DashboardAppointmentStatusGrouped = 'dashboard_appointment_status_grouped',
  DashboardDepositRequests = 'dashboard_deposit_requests',
  DashboardPatientStatistics = 'dashboard_patient_statistics',
  DashboardPendingPatients = 'dashboard_pending_patients',
  DashboardPracticeAppointmentsBreakdown = 'dashboard_practice_appointments_breakdown',
  DashboardPracticeLocationReviewRatings = 'dashboard_practice_location_review_ratings',
  DashboardPracticeLocationReviewsWordCloud = 'dashboard_practice_location_reviews_word_cloud',
  DashboardPracticeProduction = 'dashboard_practice_production',
  DashboardPractitionerAppointments = 'dashboard_practitioner_appointments',
  DashboardPractitionerHourlyRate = 'dashboard_practitioner_hourly_rate',
  DashboardPractitionerNewPatients = 'dashboard_practitioner_new_patients',
  DashboardPractitionerRebookRate = 'dashboard_practitioner_rebook_rate',
  DashboardPractitionerReviewRatings = 'dashboard_practitioner_review_ratings',
  DashboardPractitionerUtilisation = 'dashboard_practitioner_utilisation',
  DashboardRecentOnlineBookings = 'dashboard_recent_online_bookings',
  DashboardShortMessageCreditsUsage = 'dashboard_short_message_credits_usage'
}

export type DashboardWidgetLayout = {
  __typename?: 'DashboardWidgetLayout';
  id?: Maybe<Scalars['ID']['output']>;
  /** LG Breakpoint layouts */
  lg?: Maybe<Array<DashboardWidget>>;
  /** MD Breakpoint layouts */
  md?: Maybe<Array<DashboardWidget>>;
  /** SM Breakpoint layouts */
  sm?: Maybe<Array<DashboardWidget>>;
  /** XS Breakpoint layouts */
  xs?: Maybe<Array<DashboardWidget>>;
  /** XXS Breakpoint layouts */
  xxs?: Maybe<Array<DashboardWidget>>;
};

export type DashboardWidgetLayoutInput = {
  /** Layouts for LG breakpoint */
  lg: Array<DashboardWigetInput>;
  /** Layouts for MD breakpoint */
  md: Array<DashboardWigetInput>;
  /** Layouts for SM breakpoint */
  sm: Array<DashboardWigetInput>;
  /** Layouts for XS breakpoint */
  xs: Array<DashboardWigetInput>;
  /** Layouts for XXS breakpoint */
  xxs: Array<DashboardWigetInput>;
};

export type DashboardWigetInput = {
  /** Height */
  h: Scalars['Int']['input'];
  /** Unique ID of the widget */
  i: DashboardWidgetItem;
  /** Width */
  w: Scalars['Int']['input'];
  /** X position */
  x: Scalars['Int']['input'];
  /** Y position */
  y: Scalars['Int']['input'];
};

export type DataMigration = {
  __typename?: 'DataMigration';
  createdAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  filename?: Maybe<Scalars['String']['output']>;
  id: Scalars['BigInt']['output'];
  name?: Maybe<Scalars['String']['output']>;
  status: DataMigrationStatus;
  version?: Maybe<Scalars['String']['output']>;
};

export enum DataMigrationStatus {
  Down = 'down',
  Running = 'running',
  Up = 'up'
}

export type DateRangeInput = {
  end?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
  start?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
};

/** Autogenerated input type of DeactivateAccountUser */
export type DeactivateAccountUserInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of DeactivateAccountUser. */
export type DeactivateAccountUserPayload = {
  __typename?: 'DeactivateAccountUserPayload';
  accountUser: AccountUser;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  fieldErrors?: Maybe<Array<FieldError>>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

export enum DefaultHealthFunds {
  /** ACA Health Benefits Fund */
  AcaHealthBenefitsFund = 'aca_health_benefits_fund',
  /** ahm Health Insurance */
  AhmHealthInsurance = 'ahm_health_insurance',
  /** AIA Health Insurance Pty Ltd */
  AiaHealthInsurance = 'aia_health_insurance',
  /** AMA Insurance */
  AmaInsurance = 'ama_insurance',
  /** Australian Underwater Federation */
  Auf = 'auf',
  /** Australian Unity Health Limited */
  AustralianUnityHealth = 'australian_unity_health',
  /** Bupa HI Pty Ltd */
  Bupa = 'bupa',
  /** CBHS Corporate Health Pty Ltd */
  CbhsCorporateHealth = 'cbhs_corporate_health',
  /** CBHS Health Fund Limited */
  CbhsHealthFund = 'cbhs_health_fund',
  /** CDH Benefits Fund */
  CdhBenefitsFund = 'cdh_benefits_fund',
  /** CUA Health Pty Ltd */
  CuaHealth = 'cua_health',
  /** Defence Health Limited */
  DefenceHealth = 'defence_health',
  /** Doctors' Health Fund */
  DoctorsHealthFund = 'doctors_health_fund',
  /** Department of Veteran Affairs */
  Dva = 'dva',
  /** GMHBA Limited */
  Gmhba = 'gmhba',
  /** GU Health */
  Gu = 'gu',
  /** HBF Health Limited */
  Hbf = 'hbf',
  /** HCF */
  Hcf = 'hcf',
  /** Health Care Insurance Limited */
  Hci = 'hci',
  /** Health Insurance Fund of Australia Limited */
  HealthInsuranceFund = 'health_insurance_fund',
  /** Health Partners */
  HealthPartners = 'health_partners',
  /** icare */
  Icare = 'icare',
  /** Latrobe Health Services */
  LatrobeHealthServices = 'latrobe_health_services',
  /** Medibank Private Limited */
  MedibankPrivate = 'medibank_private',
  /** Medicare Australia */
  MedicareAustralia = 'medicare_australia',
  /** Mildura Health Fund */
  MilduraHealthFund = 'mildura_health_fund',
  /** National Health Benefits Australia Pty Ltd (onemedifund) */
  NationalHealthBenefits = 'national_health_benefits',
  /** Navy Health Ltd */
  NavyHealth = 'navy_health',
  /** nib Health Funds Ltd. */
  NibHealth = 'nib_health',
  /** Nurses & Midwives Health */
  NursesMidwivesHealth = 'nurses_midwives_health',
  /** Peoplecare Health Insurance */
  PeoplecareHealthInsurance = 'peoplecare_health_insurance',
  /** Phoenix Health Fund Limited */
  PhoenixHealthFund = 'phoenix_health_fund',
  /** Police Health */
  PoliceHealth = 'police_health',
  /** Qantas Assure */
  Qantas = 'qantas',
  /** Queensland Country Health Fund Ltd */
  QldCountryHealthFund = 'qld_country_health_fund',
  /** Reserve Bank Health Society Ltd */
  ReserveBankHealthSociety = 'reserve_bank_health_society',
  /** rt health - a division of The Hospitals Contribution Fund */
  RtHealth = 'rt_health',
  /** St.Lukes Health */
  StLukesHealth = 'st_lukes_health',
  /** Teachers Health */
  TeachersHealth = 'teachers_health',
  /** Transport Health Pty Ltd */
  TransportHealth = 'transport_health',
  /** TUH Health Fund */
  TuhHealthFund = 'tuh_health_fund',
  /** Westfund Limited */
  Westfund = 'westfund'
}

/** Autogenerated input type of DeleteAccount */
export type DeleteAccountInput = {
  /** The Account to delete */
  account: Scalars['ID']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of DeleteAccount. */
export type DeleteAccountPayload = {
  __typename?: 'DeleteAccountPayload';
  account?: Maybe<Account>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  fieldErrors?: Maybe<Array<FieldError>>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

/** Autogenerated input type of DeleteDirectory */
export type DeleteDirectoryInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** ID of the Directory to delete */
  directory: Scalars['ID']['input'];
};

/** Autogenerated return type of DeleteDirectory. */
export type DeleteDirectoryPayload = {
  __typename?: 'DeleteDirectoryPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  directory?: Maybe<Directory>;
  /** General errors, unrelated to fields */
  errors?: Maybe<Array<Scalars['String']['output']>>;
  /** Input field errors */
  fieldErrors?: Maybe<Array<FieldError>>;
  /** Whether or not this mutation was successful */
  success?: Maybe<Scalars['Boolean']['output']>;
};

/** Autogenerated input type of DeleteField */
export type DeleteFieldInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
};

/** Autogenerated return type of DeleteField. */
export type DeleteFieldPayload = {
  __typename?: 'DeleteFieldPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** General errors, unrelated to fields */
  errors?: Maybe<Array<Scalars['String']['output']>>;
  /** Input field errors */
  fieldErrors?: Maybe<Array<FieldError>>;
  section?: Maybe<FormSection>;
  /** Whether or not this mutation was successful */
  success?: Maybe<Scalars['Boolean']['output']>;
};

/** Autogenerated input type of DeleteForm */
export type DeleteFormInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** ID of the form to delete */
  form: Scalars['ID']['input'];
};

/** Autogenerated return type of DeleteForm. */
export type DeleteFormPayload = {
  __typename?: 'DeleteFormPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  fieldErrors?: Maybe<Array<FieldError>>;
  form?: Maybe<Form>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

/** Autogenerated input type of DeleteInvoice */
export type DeleteInvoiceInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated input type of DeleteInvoiceItem */
export type DeleteInvoiceItemInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of DeleteInvoiceItem. */
export type DeleteInvoiceItemPayload = {
  __typename?: 'DeleteInvoiceItemPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  fieldErrors?: Maybe<Array<FieldError>>;
  invoiceItem?: Maybe<InvoiceItem>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

/** Autogenerated return type of DeleteInvoice. */
export type DeleteInvoicePayload = {
  __typename?: 'DeleteInvoicePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  fieldErrors?: Maybe<Array<FieldError>>;
  invoice?: Maybe<Invoice>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

/** Autogenerated input type of DeleteMailSetting */
export type DeleteMailSettingInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  practiceId: Scalars['ID']['input'];
};

/** Autogenerated return type of DeleteMailSetting. */
export type DeleteMailSettingPayload = {
  __typename?: 'DeleteMailSettingPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  fieldErrors?: Maybe<Array<FieldError>>;
  practice?: Maybe<Practice>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

/** Autogenerated input type of DeleteRichText */
export type DeleteRichTextInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** ID of the RichText to update */
  richTextId: Scalars['ID']['input'];
};

/** Autogenerated return type of DeleteRichText. */
export type DeleteRichTextPayload = {
  __typename?: 'DeleteRichTextPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** General errors, unrelated to fields */
  errors?: Maybe<Array<Scalars['String']['output']>>;
  /** Input field errors */
  fieldErrors?: Maybe<Array<FieldError>>;
  richText?: Maybe<RichText>;
  /** Whether or not this mutation was successful */
  success?: Maybe<Scalars['Boolean']['output']>;
};

/** Autogenerated input type of DeleteSection */
export type DeleteSectionInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
};

/** Autogenerated return type of DeleteSection. */
export type DeleteSectionPayload = {
  __typename?: 'DeleteSectionPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** General errors, unrelated to fields */
  errors?: Maybe<Array<Scalars['String']['output']>>;
  /** Input field errors */
  fieldErrors?: Maybe<Array<FieldError>>;
  section?: Maybe<FormSection>;
  /** Whether or not this mutation was successful */
  success?: Maybe<Scalars['Boolean']['output']>;
};

/** Autogenerated input type of DeleteTag */
export type DeleteTagInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of DeleteTag. */
export type DeleteTagPayload = {
  __typename?: 'DeleteTagPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  fieldErrors?: Maybe<Array<FieldError>>;
  success?: Maybe<Scalars['Boolean']['output']>;
  tag?: Maybe<Tag>;
};

/** Autogenerated input type of DeleteWaitlist */
export type DeleteWaitlistInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of DeleteWaitlist. */
export type DeleteWaitlistPayload = {
  __typename?: 'DeleteWaitlistPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  fieldErrors?: Maybe<Array<FieldError>>;
  success?: Maybe<Scalars['Boolean']['output']>;
  waitlist?: Maybe<Waitlist>;
};

export type DeliverInput = {
  /** Deliver record as email */
  deliverEmail?: InputMaybe<Scalars['Boolean']['input']>;
  /** Deliver record as SMS */
  deliverSms?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Autogenerated input type of DeliverInvoice */
export type DeliverInvoiceInput = {
  attributes: DeliverInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  invoiceId: Scalars['ID']['input'];
};

/** Autogenerated return type of DeliverInvoice. */
export type DeliverInvoicePayload = {
  __typename?: 'DeliverInvoicePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  fieldErrors?: Maybe<Array<FieldError>>;
  invoice?: Maybe<Invoice>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type DentalChart = {
  __typename?: 'DentalChart';
  createdAt: Scalars['ISO8601DateTime']['output'];
  guid: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  patient: UserPracticeLink;
  /** The ID of the patient this treatment is associated with */
  patientId: Scalars['ID']['output'];
  teeth: Array<DentalTooth>;
  /** Returns all tooth conditions valid for the given date/treatment */
  toothConditions?: Maybe<Array<DentalToothCondition>>;
  toothLocations: Array<DentalToothLocation>;
  /** Returns all tooth treatments valid for the given date/treatment */
  toothTreatments?: Maybe<Array<DentalToothTreatment>>;
  updatedAt: Scalars['ISO8601DateTime']['output'];
};


export type DentalChartToothConditionsArgs = {
  treatmentId?: InputMaybe<Scalars['ID']['input']>;
  visibleAt?: InputMaybe<Scalars['ISO8601Date']['input']>;
};


export type DentalChartToothTreatmentsArgs = {
  treatmentId?: InputMaybe<Scalars['ID']['input']>;
  visibleAt?: InputMaybe<Scalars['ISO8601Date']['input']>;
};

export enum DentalChartConfigDentition {
  /** Both */
  Both = 'both',
  /** Deciduous */
  Deciduous = 'deciduous',
  /** Permanent */
  Permanent = 'permanent'
}

export enum DentalChartConfigGraphicsMode {
  /** High */
  High = 'high',
  /** Low */
  Low = 'low',
  /** Medium */
  Medium = 'medium'
}

export enum DentalChartConfigGraphicsStyle {
  /** Anatomical */
  Anatomical = 'anatomical',
  /** Graphical */
  Graphical = 'graphical'
}

export enum DentalChartConfigToothSurface {
  /** Buccal */
  Buccal = 'buccal',
  /** Palatal */
  Palatal = 'palatal'
}

/** AWS URLs for dental charts uploaded with DentalChartUploader */
export type DentalChartUploaderAwsLinks = {
  __typename?: 'DentalChartUploaderAWSLinks';
  /** AWS URL for the `cover` processed version of this chart */
  coverUrl?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  /** AWS URL for the `pdf` processed version of this chart */
  pdfUrl?: Maybe<Scalars['String']['output']>;
  /** AWS URL for the `preview` processed version of this chart */
  previewUrl?: Maybe<Scalars['String']['output']>;
  /** URL of the chart */
  url?: Maybe<Scalars['String']['output']>;
};

export enum DentalClassification {
  /** Appliance */
  Appliance = 'appliance',
  /** Course */
  Course = 'course',
  /** Full Lower Denture */
  FullLowerDenture = 'full_lower_denture',
  /** Full Upper Denture */
  FullUpperDenture = 'full_upper_denture',
  /** Procedure */
  Procedure = 'procedure',
  /** Quantity */
  Quantity = 'quantity',
  /** Tooth */
  Tooth = 'tooth'
}

export type DentalClassificationTranslation = {
  __typename?: 'DentalClassificationTranslation';
  deprecated?: Maybe<Scalars['Boolean']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  type: DentalClassification;
};

export enum DentalCondition {
  /** Abfraction */
  Abfraction = 'abfraction',
  /** Abrasion */
  Abrasion = 'abrasion',
  /** Caries */
  Caries = 'caries',
  /** Chip */
  Chip = 'chip',
  /** Crack */
  Crack = 'crack',
  /** Drifted Distal */
  DriftedDistal = 'drifted_distal',
  /** Drifted Mesial */
  DriftedMesial = 'drifted_mesial',
  /** Facet */
  Facet = 'facet',
  /** Fissure */
  Fissure = 'fissure',
  /** Fracture */
  Fracture = 'fracture',
  /** Hypoplasia */
  Hypoplasia = 'hypoplasia',
  /** Impacted */
  Impacted = 'impacted',
  /** Pegged */
  Pegged = 'pegged',
  /** Periapical Abscess */
  PeriapicalAbscess = 'periapical_abscess',
  /** Retained Root */
  RetainedRoot = 'retained_root',
  /** Rotated Acw */
  RotatedAcw = 'rotated_acw',
  /** Rotated Cw */
  RotatedCw = 'rotated_cw',
  /** Tipped Distal */
  TippedDistal = 'tipped_distal',
  /** Tipped Mesial */
  TippedMesial = 'tipped_mesial',
  /** Watch */
  Watch = 'watch',
  /** Worn */
  Worn = 'worn'
}

export type DentalCreatePerioExamInput = {
  /** Date of the perio exam. Defaults to today. */
  date?: InputMaybe<Scalars['String']['input']>;
  /** GUID of perio exam to duplicate */
  duplicateExamGuid?: InputMaybe<Scalars['ID']['input']>;
  /** Select which measurements from the previous chart to copy */
  duplicateMeasurements?: InputMaybe<Array<DentalPerioConfigMeasurements>>;
  /** Selected measurements for this exam */
  measurements?: InputMaybe<Array<DentalPerioConfigMeasurements>>;
  /** Auto / Manual of moving around the mouth */
  movementAuto?: InputMaybe<Scalars['Boolean']['input']>;
  /** The movement of the exam around teeth */
  movementPreset?: InputMaybe<DentalPerioConfigMovementPreset>;
  /** Id of patient connection */
  patientId: Scalars['ID']['input'];
  /** Id of practioner connection */
  practitionerId?: InputMaybe<Scalars['ID']['input']>;
  /** The movement of the exam within the tooth */
  toothMovement?: InputMaybe<DentalPerioConfigToothMovement>;
};

export type DentalCreateToothConditionInput = {
  /** Set as active/inactive */
  active?: InputMaybe<Scalars['Boolean']['input']>;
  /** Array of root surface points */
  buccalCrownPoints?: InputMaybe<Array<DentalPointInput>>;
  /** Array of root surface points */
  buccalRootPoints?: InputMaybe<Array<DentalPointInput>>;
  /** Type of condition */
  conditionType: DentalCondition;
  /** Condition date. Normally the date of the condition */
  date?: InputMaybe<Scalars['ISO8601Date']['input']>;
  /** Array of root surface points */
  palatalCrownPoints?: InputMaybe<Array<DentalPointInput>>;
  /** Array of root surface points */
  palatalRootPoints?: InputMaybe<Array<DentalPointInput>>;
  /** Id of the assigned practitioner */
  practitionerId: Scalars['ID']['input'];
  /** Surface code */
  surfaceCode?: InputMaybe<Scalars['String']['input']>;
  /** Array of root surface points */
  surfacePoints?: InputMaybe<Array<DentalPointInput>>;
  /** Id of the tooth */
  toothId: Scalars['ID']['input'];
  /** Id of the selected treatment plan */
  treatmentPlanId: Scalars['ID']['input'];
};

export type DentalCreateToothTreatmentInput = {
  /** Id of appointment when creating the patient record item */
  appointmentId?: InputMaybe<Scalars['ID']['input']>;
  /** ID of catalogue item this treatment links to */
  catalogueItemId?: InputMaybe<Scalars['ID']['input']>;
  /** Date of the treatment. Will default to the date_started of the treatment */
  date?: InputMaybe<Scalars['String']['input']>;
  /** ID of material this treatment links to */
  materialId?: InputMaybe<Scalars['ID']['input']>;
  /** Id of the patient (upc) */
  patientId: Scalars['ID']['input'];
  /** Id of practitioner (UserPracticeConnection) to assign the patient record item */
  practitionerId?: InputMaybe<Scalars['ID']['input']>;
  /** Id of price list when creating the patient record item */
  priceListId?: InputMaybe<Scalars['ID']['input']>;
  /** Surface code required for fillings */
  surfaceCode?: InputMaybe<Scalars['String']['input']>;
  /** Id of the tooth */
  toothId?: InputMaybe<Scalars['ID']['input']>;
  /** Tooth FDI number */
  toothNumber?: InputMaybe<Scalars['Int']['input']>;
  /** Id of the treatment plan */
  treatmentPlanId: Scalars['ID']['input'];
  /** Type of treatment */
  treatmentType: DentalTreatment;
  /** Treatment state */
  type: DentalRecordType;
};

export type DentalInteractionPoint = {
  __typename?: 'DentalInteractionPoint';
  id: Scalars['ID']['output'];
  x: Scalars['Float']['output'];
  y: Scalars['Float']['output'];
};

export enum DentalJaw {
  /** Both */
  Both = 'both',
  /** Lower */
  Lower = 'lower',
  /** Upper */
  Upper = 'upper'
}

export type DentalJawTranslation = {
  __typename?: 'DentalJawTranslation';
  deprecated?: Maybe<Scalars['Boolean']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  type: DentalJaw;
};

export type DentalLegacyChart = DentalLegacyJsonApiInterface & {
  __typename?: 'DentalLegacyChart';
  attributes?: Maybe<DentalChart>;
  id?: Maybe<Scalars['ID']['output']>;
  type?: Maybe<Scalars['String']['output']>;
};

export type DentalLegacyJsonApiInterface = {
  id?: Maybe<Scalars['ID']['output']>;
  type?: Maybe<Scalars['String']['output']>;
};

export type DentalPerioChartUploader = {
  __typename?: 'DentalPerioChartUploader';
  /** 1380 x 2400 */
  coverUrl?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  /** 690 x 1100 */
  previewUrl?: Maybe<Scalars['String']['output']>;
  /** Original file - 2760 x 4800 */
  url?: Maybe<Scalars['String']['output']>;
};

export enum DentalPerioConfigMeasurements {
  /** Bleeding */
  Bleeding = 'bleeding',
  /** Bone Loss */
  BoneLoss = 'bone_loss',
  /** Clinical Attachment Loss */
  ClinicalAttachmentLoss = 'clinical_attachment_loss',
  /** Furcation */
  Furcation = 'furcation',
  /** Gingival Margin */
  GingivalMargin = 'gingival_margin',
  /** Mobility */
  Mobility = 'mobility',
  /** Plaque */
  Plaque = 'plaque',
  /** Pocket Depth */
  PocketDepth = 'pocket_depth',
  /** Supperation */
  Supperation = 'supperation'
}

export enum DentalPerioConfigMovementPreset {
  /** Preset1 */
  Preset1 = 'preset1',
  /** Preset2 */
  Preset2 = 'preset2',
  /** Preset3 */
  Preset3 = 'preset3',
  /** Preset4 */
  Preset4 = 'preset4',
  /** Preset5 */
  Preset5 = 'preset5',
  /** Preset6 */
  Preset6 = 'preset6'
}

export enum DentalPerioConfigToothMovement {
  /** Gm Only */
  GmOnly = 'gm_only',
  /** Pd And Gm */
  PdAndGm = 'pd_and_gm',
  /** Pd Only */
  PdOnly = 'pd_only'
}

/** Periodontal examination entry for a given tooth location. */
export type DentalPerioEntry = {
  __typename?: 'DentalPerioEntry';
  /** Any presence of bleeding */
  bleeding?: Maybe<Array<Maybe<Scalars['Int']['output']>>>;
  /** Loss of bone mass */
  boneLoss?: Maybe<Scalars['Int']['output']>;
  /** The measurement of the pocket depth + gingival margin */
  clinicalAttachmentLoss?: Maybe<Array<Maybe<Scalars['Int']['output']>>>;
  createdAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  /** Date the entry is created for */
  date?: Maybe<Scalars['ISO8601Date']['output']>;
  /** Date the record was marked as soft delete */
  discardedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  /** FDI code idetifying tooth location */
  fdi?: Maybe<Scalars['Int']['output']>;
  /** Root seperating from the gum */
  furcation?: Maybe<Array<Maybe<Scalars['Int']['output']>>>;
  /** Recession of the gum */
  gingivalMargin?: Maybe<Array<Maybe<Scalars['Int']['output']>>>;
  id: Scalars['ID']['output'];
  /** Determines if tooth is missing or not */
  missing?: Maybe<Scalars['Boolean']['output']>;
  /** Mobility of the tooth (wiggle) */
  mobility?: Maybe<Scalars['Int']['output']>;
  /** Patient which this entry is for */
  patient: UserPracticeLink;
  /** Examination record */
  perioExam: DentalPerioExam;
  /** Any presence of plaque */
  plaque?: Maybe<Array<Maybe<Scalars['Int']['output']>>>;
  /** Measurement of the depth of pocket between the tooth and gums */
  pocketDepth?: Maybe<Array<Maybe<Scalars['Int']['output']>>>;
  /** Any presence of puss formation */
  supperation?: Maybe<Array<Maybe<Scalars['Int']['output']>>>;
  /** Tooth face */
  toothFace?: Maybe<DentalToothFace>;
  /** Tooth location which this entry is for */
  toothLocation: DentalToothLocation;
  updatedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
};

/** The connection type for DentalPerioEntry. */
export type DentalPerioEntryConnection = {
  __typename?: 'DentalPerioEntryConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<DentalPerioEntryEdge>>>;
  /** IDs of ALL results, regardless of pagination */
  ids: Array<Scalars['ID']['output']>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<DentalPerioEntry>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Returns total count of items in list */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type DentalPerioEntryEdge = {
  __typename?: 'DentalPerioEntryEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<DentalPerioEntry>;
};

/** Periodontal examination record for a patient */
export type DentalPerioExam = {
  __typename?: 'DentalPerioExam';
  /** Percentage of the mouth that is bleeding */
  bleedingPercent: Scalars['Float']['output'];
  chartImage?: Maybe<DentalPerioChartUploader>;
  createdAt: Scalars['ISO8601DateTime']['output'];
  /** Date of the examination */
  date: Scalars['ISO8601Date']['output'];
  /** Date the record was marked as soft delete */
  discardedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  /** Percentage of the mouth that is covered with furcation */
  furcationPercent: Scalars['Float']['output'];
  guid: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  /** Defines the measurements that are available */
  measurements: Array<DentalPerioConfigMeasurements>;
  /** Defines if the movement around the mouth is automatic. */
  movementAuto: Scalars['Boolean']['output'];
  /** Defines the type of movement preset around the mouth */
  movementPreset: DentalPerioConfigMovementPreset;
  /** Patient which this entry is for */
  patient: UserPracticeLink;
  /** The ID of the patient this record belongs to */
  patientId: Scalars['ID']['output'];
  /** List of perio entries for this examination */
  perioEntries: Array<DentalPerioEntry>;
  /** Percentage of the mouth that is covered with plaque */
  plaquePercent: Scalars['Float']['output'];
  policies?: Maybe<DentalPerioExamPolicy>;
  /** The practitioner that is assigned to this examination */
  practitioner: UserPracticeLink;
  /** The ID of the practitioner who created the record */
  practitionerId: Scalars['ID']['output'];
  /** Probing statistics for the perio exam */
  summary?: Maybe<Scalars['JSON']['output']>;
  /** Percentage of the mouth that is showing supperation */
  supperationPercent: Scalars['Float']['output'];
  /** Defines how we move through the teeth inputs */
  toothMovement: DentalPerioConfigToothMovement;
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

/** The connection type for DentalPerioExam. */
export type DentalPerioExamConnection = {
  __typename?: 'DentalPerioExamConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<DentalPerioExamEdge>>>;
  /** IDs of ALL results, regardless of pagination */
  ids: Array<Scalars['ID']['output']>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<DentalPerioExam>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Returns total count of items in list */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type DentalPerioExamEdge = {
  __typename?: 'DentalPerioExamEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<DentalPerioExam>;
};

export type DentalPerioExamPolicy = {
  __typename?: 'DentalPerioExamPolicy';
  create?: Maybe<Scalars['Boolean']['output']>;
  destroy?: Maybe<Scalars['Boolean']['output']>;
  discard?: Maybe<Scalars['Boolean']['output']>;
  edit?: Maybe<Scalars['Boolean']['output']>;
  index?: Maybe<Scalars['Boolean']['output']>;
  new?: Maybe<Scalars['Boolean']['output']>;
  show?: Maybe<Scalars['Boolean']['output']>;
  update?: Maybe<Scalars['Boolean']['output']>;
};

export type DentalPointInput = {
  /** X position of the tooth */
  x: Scalars['Float']['input'];
  /** Y position of the tooth */
  y: Scalars['Float']['input'];
};

export enum DentalPricing {
  /** Implant */
  Implant = 'implant',
  /** Jaw */
  Jaw = 'jaw',
  /** Root */
  Root = 'root',
  /** Surface */
  Surface = 'surface',
  /** Tooth */
  Tooth = 'tooth'
}

export type DentalPricingTranslation = {
  __typename?: 'DentalPricingTranslation';
  deprecated?: Maybe<Scalars['Boolean']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  type: DentalPricing;
};

export enum DentalRecordType {
  /** Completed */
  Completed = 'completed',
  /** Condition */
  Condition = 'condition',
  /** Existing */
  Existing = 'existing',
  /** Proposed */
  Proposed = 'proposed'
}

export type DentalResolveToothInput = {
  /** Array of IDs of treatments to mark as replaced */
  replacedTreatmentIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** Array of IDs of conditions to mark as treated */
  treatedConditionIds?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export enum DentalSurfaceCode {
  /** B */
  B = 'B',
  /** Bdl */
  Bdl = 'BDL',
  /** Bdp */
  Bdp = 'BDP',
  /** Bi */
  Bi = 'BI',
  /** Bil */
  Bil = 'BIL',
  /** Bip */
  Bip = 'BIP',
  /** Bml */
  Bml = 'BML',
  /** Bmp */
  Bmp = 'BMP',
  /** Bo */
  Bo = 'BO',
  /** Bol */
  Bol = 'BOL',
  /** Bop */
  Bop = 'BOP',
  /** Bp */
  Bp = 'BP',
  /** D */
  D = 'D',
  /** Db */
  Db = 'DB',
  /** Di */
  Di = 'DI',
  /** Dib */
  Dib = 'DIB',
  /** Dibl */
  Dibl = 'DIBL',
  /** Dibp */
  Dibp = 'DIBP',
  /** Dil */
  Dil = 'DIL',
  /** Dip */
  Dip = 'DIP',
  /** Dl */
  Dl = 'DL',
  /** Do */
  Do = 'DO',
  /** Dob */
  Dob = 'DOB',
  /** Dobl */
  Dobl = 'DOBL',
  /** Dobp */
  Dobp = 'DOBP',
  /** Dol */
  Dol = 'DOL',
  /** Dop */
  Dop = 'DOP',
  /** Dp */
  Dp = 'DP',
  /** I */
  I = 'I',
  /** L */
  L = 'L',
  /** Lb */
  Lb = 'LB',
  /** Li */
  Li = 'LI',
  /** Lo */
  Lo = 'LO',
  /** M */
  M = 'M',
  /** Mb */
  Mb = 'MB',
  /** Mbd */
  Mbd = 'MBD',
  /** Mbdl */
  Mbdl = 'MBDL',
  /** Mbdp */
  Mbdp = 'MBDP',
  /** Md */
  Md = 'MD',
  /** Mi */
  Mi = 'MI',
  /** Mib */
  Mib = 'MIB',
  /** Mibl */
  Mibl = 'MIBL',
  /** Mibp */
  Mibp = 'MIBP',
  /** Mid */
  Mid = 'MID',
  /** Midb */
  Midb = 'MIDB',
  /** Midbl */
  Midbl = 'MIDBL',
  /** Midbp */
  Midbp = 'MIDBP',
  /** Midl */
  Midl = 'MIDL',
  /** Midp */
  Midp = 'MIDP',
  /** Mil */
  Mil = 'MIL',
  /** Mip */
  Mip = 'MIP',
  /** Ml */
  Ml = 'ML',
  /** Mld */
  Mld = 'MLD',
  /** Mo */
  Mo = 'MO',
  /** Mob */
  Mob = 'MOB',
  /** Mobl */
  Mobl = 'MOBL',
  /** Mobp */
  Mobp = 'MOBP',
  /** Mod */
  Mod = 'MOD',
  /** Modb */
  Modb = 'MODB',
  /** Modbl */
  Modbl = 'MODBL',
  /** Modbp */
  Modbp = 'MODBP',
  /** Modl */
  Modl = 'MODL',
  /** Modp */
  Modp = 'MODP',
  /** Mol */
  Mol = 'MOL',
  /** Mop */
  Mop = 'MOP',
  /** Mp */
  Mp = 'MP',
  /** Mpd */
  Mpd = 'MPD',
  /** O */
  O = 'O',
  /** P */
  P = 'P',
  /** Pi */
  Pi = 'PI',
  /** Po */
  Po = 'PO'
}

export enum DentalTeeth {
  /** D 1 */
  D_1 = 'd_1',
  /** D 2 */
  D_2 = 'd_2',
  /** D 3 */
  D_3 = 'd_3',
  /** D 4 */
  D_4 = 'd_4',
  /** D 5 */
  D_5 = 'd_5',
  /** P 1 */
  P_1 = 'p_1',
  /** P 2 */
  P_2 = 'p_2',
  /** P 3 */
  P_3 = 'p_3',
  /** P 4 */
  P_4 = 'p_4',
  /** P 5 */
  P_5 = 'p_5',
  /** P 6 */
  P_6 = 'p_6',
  /** P 7 */
  P_7 = 'p_7',
  /** P 8 */
  P_8 = 'p_8'
}

export type DentalTeethTranslation = {
  __typename?: 'DentalTeethTranslation';
  deprecated?: Maybe<Scalars['Boolean']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  type: DentalTeeth;
};

/** A single tooth, uniquely identified by its quadrant and identifier or FDI number. */
export type DentalTooth = AnyNode & {
  __typename?: 'DentalTooth';
  /** Linked dental chart */
  chart: DentalChart;
  /** Date which the tooth was marked as closed gap */
  closedGapAt?: Maybe<Scalars['ISO8601Date']['output']>;
  createdAt: Scalars['ISO8601DateTime']['output'];
  /**
   * FDI Identifier number representing the quadrant + tooth number working form
   * the inside to the outside of the mouth. 11 -> 48 for adults, 51 -> 88 for
   * children. Supernumerary teeth are represented by a number greater than 88.
   */
  fdi: Scalars['Int']['output'];
  /** UUID field to identify this record. Will be replacing ID */
  guid: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  /** Tooth number 1-8 working form the inside of the mouth to the outside */
  identifier: Scalars['Int']['output'];
  /** If true, tooth is missing from the mouth */
  missing: Scalars['Boolean']['output'];
  /** Date which the tooth was marked as missing */
  missingAt?: Maybe<Scalars['ISO8601Date']['output']>;
  /** Date which the tooth was marked as closed gap */
  partiallyEruptedAt?: Maybe<Scalars['ISO8601Date']['output']>;
  /** Determines if the tooth is permanant (adult) or deciduous (child) */
  permanent: Scalars['Boolean']['output'];
  /** Stores positional data for the movement of the tooth */
  position: DentalToothPosition;
  /** Which quadrant the tooth belongs to, 1-4 adults 5-8 children */
  quadrant: Scalars['Int']['output'];
  /** The name of the quadrant e.g LowerRight etc */
  quadrantName: Scalars['String']['output'];
  /** State of the tooth either being visible or missing */
  state: DentalToothState;
  /** Tooth conditions linked to this tooth */
  toothConditions: Array<DentalToothCondition>;
  /** Linked tooth location */
  toothLocation: DentalToothLocation;
  /** Tooth treatments linked to this */
  toothTreatments: Array<DentalToothTreatment>;
  /** Date which the tooth was marked as unerupted */
  uneruptedAt?: Maybe<Scalars['ISO8601Date']['output']>;
  updatedAt: Scalars['ISO8601DateTime']['output'];
  /** Unique identifier of the message */
  uuid: Scalars['String']['output'];
  /** Versions of this tooth */
  versions?: Maybe<DentalVersionConnection>;
  /** Date which the tooth was marked as visible */
  visibleAt?: Maybe<Scalars['ISO8601Date']['output']>;
};


/** A single tooth, uniquely identified by its quadrant and identifier or FDI number. */
export type DentalToothVersionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type DentalToothCondition = {
  __typename?: 'DentalToothCondition';
  active: Scalars['Boolean']['output'];
  /** The date this condition is active for */
  activeAt?: Maybe<Scalars['ISO8601Date']['output']>;
  buccalCrownPoints: Array<DentalInteractionPoint>;
  buccalRootPoints: Array<DentalInteractionPoint>;
  conditionType: DentalCondition;
  createdAt: Scalars['ISO8601DateTime']['output'];
  date: Scalars['ISO8601Date']['output'];
  discardedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  fdi: Scalars['Int']['output'];
  guid: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  /** The date this condition was inactive at */
  inactiveAt?: Maybe<Scalars['ISO8601Date']['output']>;
  name: Scalars['String']['output'];
  palatalCrownPoints: Array<DentalInteractionPoint>;
  palatalRootPoints: Array<DentalInteractionPoint>;
  /** The ID of the patient this condition is associated with */
  patientId?: Maybe<Scalars['ID']['output']>;
  /** The ID of the practitioner this condition is associated with */
  practitionerId?: Maybe<Scalars['ID']['output']>;
  recordType: DentalRecordType;
  state: DentalToothConditionState;
  surfaceCode?: Maybe<Scalars['String']['output']>;
  surfacePoints: Array<DentalInteractionPoint>;
  surfaces: Array<Scalars['String']['output']>;
  tooth?: Maybe<DentalTooth>;
  toothId: Scalars['ID']['output'];
  treatment?: Maybe<Treatment>;
  /** The ID of the treatment this condition is associated with */
  treatmentId?: Maybe<Scalars['ID']['output']>;
  treatmentPlan?: Maybe<TreatmentPlan>;
  /** The ID of the treatment plan this condition is associated with */
  treatmentPlanId?: Maybe<Scalars['ID']['output']>;
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

/** The connection type for DentalToothCondition. */
export type DentalToothConditionConnection = {
  __typename?: 'DentalToothConditionConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<DentalToothConditionEdge>>>;
  /** IDs of ALL results, regardless of pagination */
  ids: Array<Scalars['ID']['output']>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<DentalToothCondition>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Returns total count of items in list */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type DentalToothConditionEdge = {
  __typename?: 'DentalToothConditionEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<DentalToothCondition>;
};

export enum DentalToothConditionState {
  Active = 'active',
  Inactive = 'inactive',
  Treated = 'treated'
}

export enum DentalToothFace {
  /** Buccal */
  Buccal = 'buccal',
  /** Palatal */
  Palatal = 'palatal'
}

/** Tooth location identified by the quadrant and permanent tooth number. Both tooth 11 and 51 belongs to location 11. */
export type DentalToothLocation = {
  __typename?: 'DentalToothLocation';
  id: Scalars['ID']['output'];
  /** Tooth location number 1-8 working form the inside of the mouth to the outside */
  identifier: Scalars['Int']['output'];
  /** Quadrant of the tooth location 1-4. Works from UPPER RIGHT to LOWER RIGHT */
  quadrant: Scalars['Int']['output'];
  /** Teeth that belong to this location */
  teeth?: Maybe<Array<DentalTooth>>;
};

/** Positional data for the movement of a tooth */
export type DentalToothPosition = {
  __typename?: 'DentalToothPosition';
  /** ID */
  id?: Maybe<Scalars['ID']['output']>;
  /** Tooth rotation. -/+ rotation. Rotation is relative to tooth, not the quadrant it belongs to. */
  r1?: Maybe<Scalars['Float']['output']>;
  /** Tooth surface rotation. -/+ rotation. Rotation is relative to surface, not the quadrant it belongs to. */
  r2?: Maybe<Scalars['Float']['output']>;
  /** Movement on the X axis */
  x?: Maybe<Scalars['Float']['output']>;
  /** Movement on the Y axis */
  y?: Maybe<Scalars['Float']['output']>;
  /** Movement on the Z axis */
  z?: Maybe<Scalars['Float']['output']>;
};

export enum DentalToothState {
  ClosedGap = 'closed_gap',
  Missing = 'missing',
  PartiallyErupted = 'partially_erupted',
  Unerupted = 'unerupted',
  Visible = 'visible'
}

export type DentalToothTreatment = {
  __typename?: 'DentalToothTreatment';
  active: Scalars['Boolean']['output'];
  /** The date this treatment is active for */
  activeAt?: Maybe<Scalars['ISO8601Date']['output']>;
  colour?: Maybe<Scalars['String']['output']>;
  completedAt?: Maybe<Scalars['ISO8601Date']['output']>;
  createdAt: Scalars['ISO8601DateTime']['output'];
  date: Scalars['ISO8601Date']['output'];
  discardedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  existingAt?: Maybe<Scalars['ISO8601Date']['output']>;
  fdi: Scalars['Int']['output'];
  guid: Scalars['ID']['output'];
  healthFundItemNumber?: Maybe<Scalars['String']['output']>;
  healthFundItemNumberWithModality?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  /** The date this treatment was inactive at */
  inactiveAt?: Maybe<Scalars['ISO8601Date']['output']>;
  material?: Maybe<Material>;
  /** The ID of the material this treatment is associated with */
  materialId?: Maybe<Scalars['ID']['output']>;
  name: Scalars['String']['output'];
  options: Array<Scalars['String']['output']>;
  patient?: Maybe<UserPracticeLink>;
  /** The ID of the patient this treatment is associated with */
  patientId?: Maybe<Scalars['ID']['output']>;
  patientRecordItem?: Maybe<PatientRecordItem>;
  /** The ID of the patient record item this treatment is associated with */
  patientRecordItemId?: Maybe<Scalars['ID']['output']>;
  practitioner?: Maybe<UserPracticeLink>;
  /** The ID of the practitioner this treatment is associated with */
  practitionerId?: Maybe<Scalars['ID']['output']>;
  price?: Maybe<Scalars['Int']['output']>;
  proposedAt?: Maybe<Scalars['ISO8601Date']['output']>;
  publicHealthCode?: Maybe<Scalars['String']['output']>;
  recordType: DentalRecordType;
  state: DentalToothTreatmentState;
  surfaceCode?: Maybe<Scalars['String']['output']>;
  surfaces?: Maybe<Array<Scalars['String']['output']>>;
  tooth?: Maybe<DentalTooth>;
  /** The tooth this treatment is for */
  toothId?: Maybe<Scalars['ID']['output']>;
  /** The ID of the tooth treatment this treatment is associated with */
  toothTreatmentId?: Maybe<Scalars['ID']['output']>;
  treatment?: Maybe<Treatment>;
  /** The ID of the treatment this treatment is associated with */
  treatmentId?: Maybe<Scalars['ID']['output']>;
  treatmentPlan?: Maybe<TreatmentPlan>;
  /** The ID of the treatment plan this treatment is associated with */
  treatmentPlanId?: Maybe<Scalars['ID']['output']>;
  treatmentType: DentalTreatment;
  updatedAt: Scalars['ISO8601DateTime']['output'];
  wings: Array<Scalars['String']['output']>;
};

/** The connection type for DentalToothTreatment. */
export type DentalToothTreatmentConnection = {
  __typename?: 'DentalToothTreatmentConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<DentalToothTreatmentEdge>>>;
  /** IDs of ALL results, regardless of pagination */
  ids: Array<Scalars['ID']['output']>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<DentalToothTreatment>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Returns total count of items in list */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type DentalToothTreatmentEdge = {
  __typename?: 'DentalToothTreatmentEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<DentalToothTreatment>;
};

export enum DentalToothTreatmentState {
  Active = 'active',
  Inactive = 'inactive',
  Replaced = 'replaced'
}

export enum DentalTreatment {
  /** Abutment */
  Abutment = 'abutment',
  /** Apicectomy */
  Apicectomy = 'apicectomy',
  /** Bridge Pontic */
  BridgePontic = 'bridge_pontic',
  /** Bridge Retainer */
  BridgeRetainer = 'bridge_retainer',
  /** Core And Post */
  CoreAndPost = 'core_and_post',
  /** Crown */
  Crown = 'crown',
  /** Crown Prep */
  CrownPrep = 'crown_prep',
  /** Denture */
  Denture = 'denture',
  /** Extraction */
  Extraction = 'extraction',
  /** Filling */
  Filling = 'filling',
  /** Fissure Sealant */
  FissureSealant = 'fissure_sealant',
  /** Implant */
  Implant = 'implant',
  /** Inlay */
  Inlay = 'inlay',
  /** Lingual Veneer */
  LingualVeneer = 'lingual_veneer',
  /** Maryland */
  Maryland = 'maryland',
  /** Onlay */
  Onlay = 'onlay',
  /** Pin */
  Pin = 'pin',
  /** Post */
  Post = 'post',
  /** Root Filling */
  RootFilling = 'root_filling',
  /** Root Prep */
  RootPrep = 'root_prep',
  /** Tooth Prep */
  ToothPrep = 'tooth_prep',
  /** Veneer */
  Veneer = 'veneer'
}

export type DentalTreatmentTranslation = {
  __typename?: 'DentalTreatmentTranslation';
  deprecated?: Maybe<Scalars['Boolean']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  type: DentalTreatment;
};

export type DentalUpdatePerioEntryInput = {
  /** Any presence of bleeding */
  bleeding?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** Loss of bone mass */
  boneLoss?: InputMaybe<Scalars['Int']['input']>;
  /** Root seperating from the gum */
  furcation?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** Recession of the gum */
  gingivalMargin?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** ID of the perio entry to update */
  id?: InputMaybe<Scalars['ID']['input']>;
  /** Mobility of the tooth (wiggle) */
  mobility?: InputMaybe<Scalars['Int']['input']>;
  /** Any presence of plaque */
  plaque?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** Measurement of the depth of pocket between the tooth and gums */
  pocketDepth?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** Any presence of puss formation */
  supperation?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
};

export type DentalUpdatePerioExamInput = {
  /** Base64 chart image */
  chartImage?: InputMaybe<Scalars['String']['input']>;
  /** Date of exam */
  date?: InputMaybe<Scalars['ISO8601Date']['input']>;
  /** Selected measurements for this exam */
  measurements?: InputMaybe<Array<DentalPerioConfigMeasurements>>;
  /** Auto / Manual of moving around the mouth */
  movementAuto?: InputMaybe<Scalars['Boolean']['input']>;
  /** The movement of the exam around teeth */
  movementPreset?: InputMaybe<DentalPerioConfigMovementPreset>;
  /** Id of practioner connection */
  practitionerId?: InputMaybe<Scalars['ID']['input']>;
  /** The movement of the exam within the tooth */
  toothMovement?: InputMaybe<DentalPerioConfigToothMovement>;
};

export type DentalUpdateToothConditionInput = {
  /** Set as active/inactive */
  active?: InputMaybe<Scalars['Boolean']['input']>;
};

export type DentalUpdateToothInput = {
  /** Whether or not the tooth has a closed gap */
  closedGap?: InputMaybe<Scalars['Boolean']['input']>;
  /** Whether or not the tooth is missing */
  missing?: InputMaybe<Scalars['Boolean']['input']>;
  /** Whether or not the tooth is partially erupted */
  partiallyErupted?: InputMaybe<Scalars['Boolean']['input']>;
  /** Position of the tooth */
  position?: InputMaybe<DentalUpdateToothPositionInput>;
  /** Updates the location of the tooth to deciduous */
  showDeciduous?: InputMaybe<Scalars['Boolean']['input']>;
  /** Updates the location of the tooth to permanent. Overrides show_deciduous */
  showPermanent?: InputMaybe<Scalars['Boolean']['input']>;
  /** Whether or not the tooth is unerupted */
  unerupted?: InputMaybe<Scalars['Boolean']['input']>;
};

export type DentalUpdateToothPositionInput = {
  /** R1 position of the tooth */
  r1: Scalars['Float']['input'];
  /** R2 position of the tooth */
  r2: Scalars['Float']['input'];
  /** X position of the tooth */
  x: Scalars['Float']['input'];
  /** Y position of the tooth */
  y: Scalars['Float']['input'];
  /** Z position of the tooth */
  z: Scalars['Float']['input'];
};

export type DentalUpdateToothTreatmentInput = {
  /** Set as active/inactive */
  active?: InputMaybe<Scalars['Boolean']['input']>;
  /** Treatment options */
  options?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Treatment state */
  type?: InputMaybe<DentalRecordType>;
};

/** Dental Versioning */
export type DentalVersion = AnyNode & {
  __typename?: 'DentalVersion';
  changeList?: Maybe<Scalars['JSON']['output']>;
  createdAt: Scalars['ISO8601DateTime']['output'];
  event?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  itemType?: Maybe<Scalars['String']['output']>;
  message?: Maybe<Scalars['String']['output']>;
  recordType?: Maybe<Scalars['String']['output']>;
  user?: Maybe<User>;
  userName?: Maybe<Scalars['String']['output']>;
  /** Unique identifier of the message */
  uuid: Scalars['String']['output'];
};

/** The connection type for DentalVersion. */
export type DentalVersionConnection = {
  __typename?: 'DentalVersionConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<DentalVersionEdge>>>;
  /** IDs of ALL results, regardless of pagination */
  ids: Array<Scalars['ID']['output']>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<DentalVersion>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Returns total count of items in list */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type DentalVersionEdge = {
  __typename?: 'DentalVersionEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<DentalVersion>;
};

/** Autogenerated input type of DestroyBadgesBadgeLink */
export type DestroyBadgesBadgeLinkInput = {
  /** The attributes to create a new cycle */
  attributes: BadgesBadgeLinkInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of DestroyBadgesBadgeLink. */
export type DestroyBadgesBadgeLinkPayload = {
  __typename?: 'DestroyBadgesBadgeLinkPayload';
  badgeable?: Maybe<Badgeable>;
  badgesBadgeLinks?: Maybe<Array<BadgesBadgeLink>>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  fieldErrors?: Maybe<Array<FieldError>>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

/** Autogenerated input type of DestroyNote */
export type DestroyNoteInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of DestroyNote. */
export type DestroyNotePayload = {
  __typename?: 'DestroyNotePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  fieldErrors?: Maybe<Array<FieldError>>;
  note?: Maybe<Note>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

/** Autogenerated input type of DestroyPatientRecordItem */
export type DestroyPatientRecordItemInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of DestroyPatientRecordItem. */
export type DestroyPatientRecordItemPayload = {
  __typename?: 'DestroyPatientRecordItemPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  fieldErrors?: Maybe<Array<FieldError>>;
  patientRecordItem?: Maybe<PatientRecordItem>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

/** Autogenerated input type of DestroySterilisationAutoclave */
export type DestroySterilisationAutoclaveInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The ID of the autoclave to destroy */
  id: Scalars['String']['input'];
};

/** Autogenerated return type of DestroySterilisationAutoclave. */
export type DestroySterilisationAutoclavePayload = {
  __typename?: 'DestroySterilisationAutoclavePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  fieldErrors?: Maybe<Array<FieldError>>;
  sterilisationAutoclave?: Maybe<SterilisationAutoclave>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

/** Autogenerated input type of DestroySterilisationCycle */
export type DestroySterilisationCycleInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The ID of the cycle to destroy */
  id: Scalars['String']['input'];
};

/** Autogenerated return type of DestroySterilisationCycle. */
export type DestroySterilisationCyclePayload = {
  __typename?: 'DestroySterilisationCyclePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  fieldErrors?: Maybe<Array<FieldError>>;
  sterilisationCycle?: Maybe<SterilisationCycle>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

/** Autogenerated input type of DestroySterilisationCycleType */
export type DestroySterilisationCycleTypeInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The ID of the cycle type to destroy */
  id: Scalars['String']['input'];
};

/** Autogenerated return type of DestroySterilisationCycleType. */
export type DestroySterilisationCycleTypePayload = {
  __typename?: 'DestroySterilisationCycleTypePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  fieldErrors?: Maybe<Array<FieldError>>;
  sterilisationCycleType?: Maybe<SterilisationCycleType>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

/** Autogenerated input type of DestroySterilisationTracking */
export type DestroySterilisationTrackingInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The ID of the tracking to destroy */
  id: Scalars['String']['input'];
};

/** Autogenerated return type of DestroySterilisationTracking. */
export type DestroySterilisationTrackingPayload = {
  __typename?: 'DestroySterilisationTrackingPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  fieldErrors?: Maybe<Array<FieldError>>;
  sterilisationTracking?: Maybe<SterilisationTracking>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

/** Detailed table for reports */
export type DetailedList = AppointmentDetailedList | BirthdayDetailedList | EmailDetailedList | InvoiceItemDetailedList | InvoiceTransactionDetailedList | OutstandingInvoiceDetailedList | ProductivityDetailedList | ShortMessageDetailedList;

export type DetailedListAppointmentGuest = {
  __typename?: 'DetailedListAppointmentGuest';
  patientGuid?: Maybe<Scalars['ID']['output']>;
  patientName?: Maybe<Scalars['String']['output']>;
};

export enum Direction {
  /** Sort by Ascending */
  Asc = 'asc',
  /** Sort by Descending */
  Desc = 'desc'
}

export type Directory = AnyNode & Badgeable & {
  __typename?: 'Directory';
  /** Specifies who has access to this directory: Staff, Patients (if linked), or Owner */
  access?: Maybe<DirectoryAccess>;
  /** Account linked to the directory */
  account?: Maybe<Account>;
  /** Badges assigned to this directory */
  badges?: Maybe<Array<BadgesBadgeLink>>;
  /** What files and badges are allowed in this directory? */
  contentType?: Maybe<DocumentContentType>;
  createdAt: Scalars['ISO8601DateTime']['output'];
  /** Creator of this directory */
  createdBy?: Maybe<UserPracticeLink>;
  /** Subfolders */
  directories?: Maybe<Array<Directory>>;
  /** Amount of subdirectories */
  directoryCount?: Maybe<Scalars['Int']['output']>;
  /** Amount of files */
  fileCount?: Maybe<Scalars['Int']['output']>;
  /** Attachments in this directory */
  files?: Maybe<AttachmentConnection>;
  /** UUID (alias for id) */
  guid: Scalars['ID']['output'];
  /** UUID */
  id: Scalars['ID']['output'];
  /** Is this a patient or practice root directory? */
  isRoot: Scalars['Boolean']['output'];
  /** UPC that last updated this */
  lastUpdatedBy?: Maybe<UserPracticeLink>;
  /** Name of this Directory */
  name?: Maybe<Scalars['String']['output']>;
  /** GUID of the creator of this directory */
  ownerGuid?: Maybe<Scalars['ID']['output']>;
  parent?: Maybe<Directory>;
  parentPath?: Maybe<Array<Directory>>;
  /** Slash separated unix-style path for this directory */
  path?: Maybe<Scalars['String']['output']>;
  /** Patient linked to the directory */
  patient?: Maybe<UserPracticeLink>;
  /** GUID of the linked patient (if any) */
  patientGuid?: Maybe<Scalars['ID']['output']>;
  /** Practice linked to the directory */
  practice?: Maybe<Practice>;
  /** RichTexts in this directory */
  richTexts?: Maybe<Array<RichText>>;
  /** State of this directory */
  state: DirectoryState;
  updatedAt: Scalars['ISO8601DateTime']['output'];
  /** Unique identifier of the message */
  uuid: Scalars['String']['output'];
};


export type DirectoryFilesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  contentFilter?: InputMaybe<ContentFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export enum DirectoryAccess {
  Owner = 'owner',
  Patients = 'patients',
  Staff = 'staff'
}

export type DirectoryContent = Attachment | Directory | RichText;

export type DirectoryInput = {
  /** Name of the directory */
  name: Scalars['String']['input'];
  /** Parent directory */
  parent?: InputMaybe<Scalars['ID']['input']>;
  /** Link a patient to this directory */
  patient?: InputMaybe<Scalars['ID']['input']>;
};

export enum DirectoryState {
  Hidden = 'hidden',
  Immutable = 'immutable',
  /** Virtual directories are e.g search results. They dont exist as records. */
  Virtual = 'virtual',
  Visible = 'visible'
}

/** Autogenerated return type of DirectoryUpdated. */
export type DirectoryUpdatedPayload = {
  __typename?: 'DirectoryUpdatedPayload';
  directory?: Maybe<Directory>;
};

/** Autogenerated input type of DiscardReferral */
export type DiscardReferralInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** ID of the referral you want to discard */
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of DiscardReferral. */
export type DiscardReferralPayload = {
  __typename?: 'DiscardReferralPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  fieldErrors?: Maybe<Array<FieldError>>;
  referral?: Maybe<Referral>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

/** Autogenerated input type of DiscardReferralSource */
export type DiscardReferralSourceInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** ID of the referral source you want to discard */
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of DiscardReferralSource. */
export type DiscardReferralSourcePayload = {
  __typename?: 'DiscardReferralSourcePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  fieldErrors?: Maybe<Array<FieldError>>;
  referralSource?: Maybe<ReferralSource>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

/** Autogenerated input type of DiscardTreatmentPlan */
export type DiscardTreatmentPlanInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** ID of the treatment plan */
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of DiscardTreatmentPlan. */
export type DiscardTreatmentPlanPayload = {
  __typename?: 'DiscardTreatmentPlanPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  fieldErrors?: Maybe<Array<FieldError>>;
  success?: Maybe<Scalars['Boolean']['output']>;
  treatmentPlan?: Maybe<TreatmentPlan>;
};

export enum DocumentContentType {
  /** Manually uploaded files */
  Attachment = 'attachment',
  /** Documents created and managed by staff */
  Document = 'document',
  /** X-Ray and Scans */
  Imaging = 'imaging'
}

/**
 * This is a polymorphic model that links ActiveStorage images on S3 to other
 * models, specifically to Note, Template, Email, Documents::Attachment, Form and
 * any other model that uses a HTML editor. Base64 images can be extracted from the
 * HTML and replaced with an inline reference to the hosted image to reduce storage
 * size and improve performance of the table (see
 * Lexical::Content::ImageExtraction#extract) This model is necessary for editors
 * to be able to upload and host images _before_ records are created. When records
 * are created, mutations that store HTML should accept IDs of uploaded and
 * referenced Documents::Image's, and link them to the record. This lets us keep
 * track of what images are used, and which ones are clear to delete.
 */
export type DocumentImage = {
  __typename?: 'DocumentImage';
  createdAt: Scalars['ISO8601DateTime']['output'];
  id: Scalars['ID']['output'];
  updatedAt: Scalars['ISO8601DateTime']['output'];
  uploadedBy?: Maybe<UserPracticeLink>;
  /** Image URL */
  url?: Maybe<Scalars['String']['output']>;
};

export type DocumentTemplate = TemplateInterface & {
  __typename?: 'DocumentTemplate';
  assigned?: Maybe<Array<TemplateAssignable>>;
  attachments?: Maybe<Array<Attachment>>;
  createdAt: Scalars['ISO8601DateTime']['output'];
  /** Template marked as default */
  default: Scalars['Boolean']['output'];
  description?: Maybe<Scalars['String']['output']>;
  documentTemplateType: DocumentTemplateType;
  drops?: Maybe<Array<LiquidDropField>>;
  /** What this template type is used for (e.g Forms, Appointments, Invoices) */
  group?: Maybe<TemplateGroupTranslation>;
  guid: Scalars['ID']['output'];
  /** Email content */
  html?: Maybe<Scalars['Base64Brotli']['output']>;
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
  practice: Practice;
  practiceId: Scalars['ID']['output'];
  state: TemplateState;
  /** Email subject */
  subject?: Maybe<Scalars['String']['output']>;
  type: TemplateType;
  typeDefinition?: Maybe<TemplateTypeTranslation>;
  uuid: Scalars['String']['output'];
  /** How this template is to be used - e.g. Document (only HTML), Communication (HTML + Plain), or Form (JSON) */
  variant?: Maybe<TemplateVariantTranslation>;
};

/** The connection type for DocumentTemplate. */
export type DocumentTemplateConnection = {
  __typename?: 'DocumentTemplateConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<DocumentTemplateEdge>>>;
  /** IDs of ALL results, regardless of pagination */
  ids: Array<Scalars['ID']['output']>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<DocumentTemplate>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Returns total count of items in list */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type DocumentTemplateEdge = {
  __typename?: 'DocumentTemplateEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<DocumentTemplate>;
};

export enum DocumentTemplateType {
  /** Sent to patients when new advice is created for them */
  DocumentAdvice = 'document_advice',
  /** Used for general documents which don't fit into the other categories */
  DocumentGeneric = 'document_generic',
  /** Letters are documents addressed to patients, such as referral letters */
  DocumentLetterGeneric = 'document_letter_generic',
  /** Letters are documents addressed to patients, such as referral letters */
  DocumentLetterReferral = 'document_letter_referral',
  /** Documents used for e.g medical certificates */
  DocumentMedical = 'document_medical',
  /** Template used for creating treatment plans */
  DocumentTreatment = 'document_treatment'
}

/** Autogenerated input type of DuplicateFormTemplate */
export type DuplicateFormTemplateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of DuplicateFormTemplate. */
export type DuplicateFormTemplatePayload = {
  __typename?: 'DuplicateFormTemplatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  fieldErrors?: Maybe<Array<FieldError>>;
  success?: Maybe<Scalars['Boolean']['output']>;
  template?: Maybe<FormTemplate>;
};

/** Autogenerated input type of DuplicateInvoiceItem */
export type DuplicateInvoiceItemInput = {
  amount: Scalars['Int']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of DuplicateInvoiceItem. */
export type DuplicateInvoiceItemPayload = {
  __typename?: 'DuplicateInvoiceItemPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  fieldErrors?: Maybe<Array<FieldError>>;
  invoiceItems?: Maybe<Array<InvoiceItem>>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

/** Autogenerated input type of DuplicatePatientRecordItem */
export type DuplicatePatientRecordItemInput = {
  amount: Scalars['Int']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of DuplicatePatientRecordItem. */
export type DuplicatePatientRecordItemPayload = {
  __typename?: 'DuplicatePatientRecordItemPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  fieldErrors?: Maybe<Array<FieldError>>;
  patientRecordItems?: Maybe<Array<PatientRecordItem>>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type DynamsoftCredentials = {
  __typename?: 'DynamsoftCredentials';
  /** Dynamsoft Twain org id -  This is used before the product key, only if present. */
  twainOrgId?: Maybe<Scalars['Base64']['output']>;
  /** Dynamsoft Twain product key */
  twainProductKey?: Maybe<Scalars['Base64']['output']>;
};

export type Email = {
  __typename?: 'Email';
  attachments?: Maybe<Array<AttachmentProxy>>;
  communicationType?: Maybe<Scalars['String']['output']>;
  content?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  /** The date and time the message was discarded */
  discardedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  emailableId?: Maybe<Scalars['ID']['output']>;
  emailableType?: Maybe<Scalars['Int']['output']>;
  failureCauses?: Maybe<Scalars['String']['output']>;
  fromEmail?: Maybe<Scalars['String']['output']>;
  guid: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  isMarketing?: Maybe<Scalars['Boolean']['output']>;
  messageable?: Maybe<AnyNode>;
  messageableId?: Maybe<Scalars['ID']['output']>;
  messageableType?: Maybe<Scalars['String']['output']>;
  receiveableId?: Maybe<Scalars['ID']['output']>;
  receiveableType?: Maybe<Scalars['Int']['output']>;
  /** The recipient of the message */
  recipient?: Maybe<Participant>;
  sendAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  sendableId?: Maybe<Scalars['ID']['output']>;
  sendableType?: Maybe<Scalars['Int']['output']>;
  /** The sender of the message */
  sender?: Maybe<Participant>;
  state?: Maybe<EmailState>;
  subject?: Maybe<Scalars['String']['output']>;
  template?: Maybe<Template>;
  toEmail?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
};

/** The connection type for Email. */
export type EmailConnection = {
  __typename?: 'EmailConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<EmailEdge>>>;
  /** IDs of ALL results, regardless of pagination */
  ids: Array<Scalars['ID']['output']>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Email>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Returns total count of items in list */
  totalCount: Scalars['Int']['output'];
};

export type EmailDetailedList = {
  __typename?: 'EmailDetailedList';
  content?: Maybe<Scalars['String']['output']>;
  createdAtLong?: Maybe<Scalars['String']['output']>;
  fromEmail?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  sendAtLong?: Maybe<Scalars['String']['output']>;
  state?: Maybe<Scalars['String']['output']>;
  toEmail?: Maybe<Scalars['String']['output']>;
  whom?: Maybe<Scalars['String']['output']>;
};

/** An edge in a connection. */
export type EmailEdge = {
  __typename?: 'EmailEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<Email>;
};

export type EmailInput = {
  body: Scalars['String']['input'];
  subject: Scalars['String']['input'];
};

export enum EmailState {
  Blocked = 'blocked',
  Bounced = 'bounced',
  Cancelled = 'cancelled',
  Clicked = 'clicked',
  Deferred = 'deferred',
  Delivered = 'delivered',
  Dropped = 'dropped',
  Failed = 'failed',
  Opened = 'opened',
  Prepared = 'prepared',
  Processed = 'processed',
  Ready = 'ready',
  Received = 'received',
  Scheduled = 'scheduled',
  Sent = 'sent',
  SpamReported = 'spam_reported'
}

export type EmailSummaryList = {
  __typename?: 'EmailSummaryList';
  noOfMessage?: Maybe<Scalars['Int']['output']>;
  state?: Maybe<EmailState>;
};

/** Autogenerated input type of EmailTreatmentPlan */
export type EmailTreatmentPlanInput = {
  attributes: TreatmentPlanSendEmailInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** ID of the treatment plan */
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of EmailTreatmentPlan. */
export type EmailTreatmentPlanPayload = {
  __typename?: 'EmailTreatmentPlanPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  fieldErrors?: Maybe<Array<FieldError>>;
  success?: Maybe<Scalars['Boolean']['output']>;
  treatmentPlan?: Maybe<TreatmentPlan>;
};

/** Autogenerated return type of EmailUpdated. */
export type EmailUpdatedPayload = {
  __typename?: 'EmailUpdatedPayload';
  email?: Maybe<Email>;
};

export type EmergencyContact = {
  __typename?: 'EmergencyContact';
  contactNumber?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  fullName?: Maybe<Scalars['String']['output']>;
  fullNameWithTitle?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  lastName?: Maybe<Scalars['String']['output']>;
  patientId?: Maybe<Scalars['ID']['output']>;
  relationship?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  userPracticeConnectionId?: Maybe<Scalars['ID']['output']>;
};

export type EmergencyContactInput = {
  contactNumber?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  relationship?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated input type of EnableBiometrics */
export type EnableBiometricsInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  publicKey: Scalars['String']['input'];
};

/** Autogenerated return type of EnableBiometrics. */
export type EnableBiometricsPayload = {
  __typename?: 'EnableBiometricsPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  fieldErrors?: Maybe<Array<FieldError>>;
  success?: Maybe<Scalars['Boolean']['output']>;
  userPracticeConnection?: Maybe<UserPracticeLink>;
};

/** Autogenerated input type of EnableFeatures */
export type EnableFeaturesInput = {
  /** The Account to upgrade */
  account: Scalars['ID']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  features: Array<Scalars['String']['input']>;
  practiceId: Scalars['ID']['input'];
};

/** Autogenerated return type of EnableFeatures. */
export type EnableFeaturesPayload = {
  __typename?: 'EnableFeaturesPayload';
  account?: Maybe<Account>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  fieldErrors?: Maybe<Array<FieldError>>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type EventInput = {
  endDate?: InputMaybe<Scalars['ISO8601Date']['input']>;
  recurringFrequency?: InputMaybe<Scalars['String']['input']>;
  recurringRate?: InputMaybe<Scalars['Int']['input']>;
  recurringType?: InputMaybe<Scalars['String']['input']>;
  shifts?: InputMaybe<Array<ShiftInput>>;
  startDate: Scalars['ISO8601Date']['input'];
};

export type EventSearchInput = {
  /** Filter by appointment guest ids. */
  byAppointmentGuests?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** Filter by time range */
  endTime?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
  /** Find only appointments without the given states */
  excludeState?: InputMaybe<Array<AppointmentState>>;
  /** Filter by Room */
  roomIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** Filter by time range */
  startTime?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
  /** Find only appointments with the given states */
  state?: InputMaybe<Array<AppointmentState>>;
  /** Filter by UserPracticeConnection (professional role) */
  userPracticeConnectionIds?: InputMaybe<Array<Scalars['ID']['input']>>;
};

/** A family is a group of people who share a common household. */
export type Family = {
  __typename?: 'Family';
  createdAt: Scalars['ISO8601DateTime']['output'];
  /** The UPC that created the family */
  createdBy?: Maybe<UserPracticeLink>;
  /** The UPC id that created this family */
  createdById: Scalars['ID']['output'];
  /** The members of the family */
  familyMembers?: Maybe<Array<FamilyMember>>;
  /** The ID of the family */
  id: Scalars['ID']['output'];
  /** The total number of members in the family */
  membersCount: Scalars['Int']['output'];
  /** The name of the family */
  name: Scalars['String']['output'];
  /** The practice that the family is created under */
  practice?: Maybe<Practice>;
  /** The ID of the practice that the family is linked to */
  practiceId: Scalars['ID']['output'];
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

/** The connection type for Family. */
export type FamilyConnection = {
  __typename?: 'FamilyConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<FamilyEdge>>>;
  /** IDs of ALL results, regardless of pagination */
  ids: Array<Scalars['ID']['output']>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Family>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Returns total count of items in list */
  totalCount: Scalars['Int']['output'];
};

export type FamilyCreateInput = {
  /** Family name (defaults to upc last name if not provided) */
  name?: InputMaybe<Scalars['String']['input']>;
  /** The user you want to create the family for */
  userPracticeConnectionId: Scalars['ID']['input'];
};

/** An edge in a connection. */
export type FamilyEdge = {
  __typename?: 'FamilyEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<Family>;
};

/** A family member is a person in a family. They share a common household\nwith other members of the family. */
export type FamilyMember = {
  __typename?: 'FamilyMember';
  createdAt: Scalars['ISO8601DateTime']['output'];
  /** The family that the member is a member of */
  family?: Maybe<Family>;
  /** The ID of the family */
  familyId: Scalars['ID']['output'];
  /** The ID of the family */
  id: Scalars['ID']['output'];
  updatedAt: Scalars['ISO8601DateTime']['output'];
  /** The UPC that the member is a member of */
  userPracticeConnection?: Maybe<UserPracticeLink>;
  /** The ID of the UPC that the member is a member of */
  userPracticeConnectionId: Scalars['ID']['output'];
};

export type FamilyUpdateInput = {
  /** Family name */
  name: Scalars['String']['input'];
};

export enum Feature {
  Dental = 'dental',
  FormBuilderV2 = 'form_builder_v2',
  Locations = 'locations',
  OpenAI = 'open_a_i',
  PatientApp = 'patient_app',
  PushNotifications = 'push_notifications',
  Reviews = 'reviews',
  SyncFusion = 'sync_fusion'
}

export type FeatureDetails = {
  __typename?: 'FeatureDetails';
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  slug?: Maybe<Feature>;
};

export type FieldError = {
  __typename?: 'FieldError';
  details?: Maybe<Scalars['String']['output']>;
  field: Scalars['String']['output'];
  message: Scalars['String']['output'];
};

export enum FieldType {
  /** Checkboxes */
  Checkbox = 'checkbox',
  /** Date selector */
  Date = 'date',
  /** Date time selector */
  Datetime = 'datetime',
  /** An editable image */
  Drawing = 'drawing',
  /** Mobile */
  Mobile = 'mobile',
  /** Number field */
  Number = 'number',
  /** A paragraph of text (no input) */
  Paragraph = 'paragraph',
  /** Radio buttons */
  Radio = 'radio',
  /** Section */
  Section = 'section',
  /** Select input field (multi or single) */
  Select = 'select',
  /** Signature input */
  Signature = 'signature',
  /** Single row text input */
  Text = 'text',
  /** Multi row text input */
  Textarea = 'textarea',
  /** Time selector */
  Time = 'time',
  /** Form subtitle */
  Title = 'title',
  /** Upload field */
  Upload = 'upload',
  /** Video */
  Video = 'video'
}

/** Redirecting URLs for files uploaded with FileUploader */
export type FileUploaderAwsLinks = {
  __typename?: 'FileUploaderAWSLinks';
  id?: Maybe<Scalars['ID']['output']>;
  /** Redirect URL for the `large` processed version of this file */
  largeUrl?: Maybe<Scalars['String']['output']>;
  /** Redirect URL for the `medium` processed version of this file */
  mediumUrl?: Maybe<Scalars['String']['output']>;
  /** Proxied URL */
  proxyUrl?: Maybe<Scalars['String']['output']>;
  /** Original URL of the attachment */
  url?: Maybe<Scalars['String']['output']>;
  /** Redirect URL for the `x_large` processed version of this file */
  xLargeUrl?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of Finalise */
export type FinaliseInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of Finalise. */
export type FinalisePayload = {
  __typename?: 'FinalisePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  fieldErrors?: Maybe<Array<FieldError>>;
  success?: Maybe<Scalars['Boolean']['output']>;
  userPracticeConnection?: Maybe<UserPracticeLink>;
};

export type FinanceSummary = {
  __typename?: 'FinanceSummary';
  current?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  previous?: Maybe<Scalars['Int']['output']>;
};

export type Form = AnyNode & {
  __typename?: 'Form';
  authToken?: Maybe<Scalars['String']['output']>;
  completedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  createdAt: Scalars['ISO8601DateTime']['output'];
  email?: Maybe<Email>;
  emailLink?: Maybe<Scalars['String']['output']>;
  guid: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  isMedical?: Maybe<Scalars['Boolean']['output']>;
  /** Form fields */
  jsonContent?: Maybe<Array<FormField>>;
  name?: Maybe<Scalars['String']['output']>;
  patientOnly?: Maybe<Scalars['Boolean']['output']>;
  /**
   * Auto-login Access Restricted link, which can only be requested by the patient
   * themselves when already logged into the mobile app
   */
  proxyLink?: Maybe<Scalars['String']['output']>;
  /** Recipient of the form */
  recipient?: Maybe<UserPracticeLink>;
  /** Last generated OTP link */
  restrictedOtpLink?: Maybe<AccessRestriction>;
  /** Last generated PIN code link */
  restrictedPinLink?: Maybe<AccessRestriction>;
  shortMessage?: Maybe<ShortMessage>;
  smsLink?: Maybe<Scalars['String']['output']>;
  state: FormState;
  template?: Maybe<FormTemplate>;
  type?: Maybe<FormTemplateType>;
  updatedAt: Scalars['ISO8601DateTime']['output'];
  updatedBy?: Maybe<UserPracticeLink>;
  /** Unique identifier of the message */
  uuid: Scalars['String']['output'];
};

export type FormCheckboxField = FormFieldInterface & {
  __typename?: 'FormCheckboxField';
  cols?: Maybe<Scalars['Int']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  /**
   * This should be filtered out and not stored in the database
   * but kept on the type to maintain integrity of the type definitions
   * as error is still a valid property on any form field
   */
  error?: Maybe<Scalars['String']['output']>;
  fieldType: FieldType;
  id?: Maybe<Scalars['String']['output']>;
  key: Scalars['String']['output'];
  name: Scalars['String']['output'];
  offset?: Maybe<Scalars['Int']['output']>;
  options?: Maybe<Array<FormFieldOptionType>>;
  /**
   * If this field is defined, this field is linked to the specified column on the
   * Patient model and will update that field when the form updates
   */
  patientColumn?: Maybe<PatientFieldLink>;
  placeholder?: Maybe<Scalars['String']['output']>;
  /** Order of the field in the section */
  position?: Maybe<Scalars['Int']['output']>;
  required?: Maybe<Scalars['Boolean']['output']>;
  sectionId?: Maybe<Scalars['ID']['output']>;
  values?: Maybe<Array<Scalars['String']['output']>>;
};

/** The connection type for Form. */
export type FormConnection = {
  __typename?: 'FormConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<FormEdge>>>;
  /** IDs of ALL results, regardless of pagination */
  ids: Array<Scalars['ID']['output']>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Form>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Returns total count of items in list */
  totalCount: Scalars['Int']['output'];
};

export type FormCreateInput = {
  deliverEmail?: InputMaybe<Scalars['Boolean']['input']>;
  deliverSms?: InputMaybe<Scalars['Boolean']['input']>;
  templateId?: InputMaybe<Scalars['ID']['input']>;
  userPracticeConnectionId: Scalars['ID']['input'];
};

export type FormDateTimeField = FormFieldInterface & {
  __typename?: 'FormDateTimeField';
  cols?: Maybe<Scalars['Int']['output']>;
  datetime?: Maybe<Scalars['ISO8601DateTime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  /**
   * This should be filtered out and not stored in the database
   * but kept on the type to maintain integrity of the type definitions
   * as error is still a valid property on any form field
   */
  error?: Maybe<Scalars['String']['output']>;
  fieldType: FieldType;
  id?: Maybe<Scalars['String']['output']>;
  key: Scalars['String']['output'];
  name: Scalars['String']['output'];
  offset?: Maybe<Scalars['Int']['output']>;
  /**
   * If this field is defined, this field is linked to the specified column on the
   * Patient model and will update that field when the form updates
   */
  patientColumn?: Maybe<PatientFieldLink>;
  placeholder?: Maybe<Scalars['String']['output']>;
  /** Order of the field in the section */
  position?: Maybe<Scalars['Int']['output']>;
  required?: Maybe<Scalars['Boolean']['output']>;
  sectionId?: Maybe<Scalars['ID']['output']>;
};

export type FormDrawingField = FormFieldInterface & {
  __typename?: 'FormDrawingField';
  attachment?: Maybe<FormFieldAttachment>;
  cols?: Maybe<Scalars['Int']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  /**
   * This should be filtered out and not stored in the database
   * but kept on the type to maintain integrity of the type definitions
   * as error is still a valid property on any form field
   */
  error?: Maybe<Scalars['String']['output']>;
  fieldType: FieldType;
  id?: Maybe<Scalars['String']['output']>;
  key: Scalars['String']['output'];
  name: Scalars['String']['output'];
  offset?: Maybe<Scalars['Int']['output']>;
  /**
   * If this field is defined, this field is linked to the specified column on the
   * Patient model and will update that field when the form updates
   */
  patientColumn?: Maybe<PatientFieldLink>;
  placeholder?: Maybe<Scalars['String']['output']>;
  /** Order of the field in the section */
  position?: Maybe<Scalars['Int']['output']>;
  required?: Maybe<Scalars['Boolean']['output']>;
  sectionId?: Maybe<Scalars['ID']['output']>;
};

/** An edge in a connection. */
export type FormEdge = {
  __typename?: 'FormEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<Form>;
};

/** Any form field */
export type FormField = FormCheckboxField | FormDateTimeField | FormDrawingField | FormMobileField | FormNumberField | FormParagraphField | FormRadioField | FormSectionField | FormSelectMultiField | FormSelectSingleField | FormSignatureField | FormTextField | FormTitleField | FormUploadField | FormVideoField;

export type FormFieldAttachment = {
  __typename?: 'FormFieldAttachment';
  attachment?: Maybe<Attachment>;
  attachmentGuid?: Maybe<Scalars['ID']['output']>;
  attachmentId?: Maybe<Scalars['ID']['output']>;
  base64?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

export type FormFieldInput = {
  /** Used by UploadField to determine what file types are accepted */
  acceptedFileTypes?: InputMaybe<Array<Scalars['String']['input']>>;
  /** An attachment linked to this field */
  attachmentId?: InputMaybe<Scalars['ID']['input']>;
  /** How wide this field should be in the grid */
  cols?: InputMaybe<Scalars['Int']['input']>;
  /** Describes the field to the user */
  description?: InputMaybe<Scalars['String']['input']>;
  /** Error on the field if any */
  error?: InputMaybe<Scalars['String']['input']>;
  /** What type of field this is */
  fieldType: FieldType;
  /** Unique identifier for the field */
  key: Scalars['String']['input'];
  /** Maximum value for a number field */
  max?: InputMaybe<Scalars['Int']['input']>;
  /** Minimum value for a number field */
  min?: InputMaybe<Scalars['Int']['input']>;
  /** Whether or not this field accepts multiple values */
  multiple?: InputMaybe<Scalars['Boolean']['input']>;
  /** Field name */
  name: Scalars['String']['input'];
  /** Offset this field in the grid */
  offset?: InputMaybe<Scalars['Int']['input']>;
  /** Options for a select/checkbox/radio field */
  options?: InputMaybe<Array<FormFieldOptionInput>>;
  /** If this field is linked to a patient column, this field will update that column when the form updates */
  patientColumn?: InputMaybe<PatientFieldLink>;
  /** Placeholder to show in the field (if any) */
  placeholder?: InputMaybe<Scalars['String']['input']>;
  /** Order of the field in the section */
  position?: InputMaybe<Scalars['Int']['input']>;
  /** Whether or not this field is required */
  required?: InputMaybe<Scalars['Boolean']['input']>;
  /** Section the field belongs to */
  section?: InputMaybe<Scalars['ID']['input']>;
  /** Value can be either an array or a value, so parse as JSON */
  value?: InputMaybe<Scalars['JSON']['input']>;
};

/** Any form field */
export type FormFieldInterface = {
  cols?: Maybe<Scalars['Int']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  /**
   * This should be filtered out and not stored in the database
   * but kept on the type to maintain integrity of the type definitions
   * as error is still a valid property on any form field
   */
  error?: Maybe<Scalars['String']['output']>;
  fieldType: FieldType;
  id?: Maybe<Scalars['String']['output']>;
  key: Scalars['String']['output'];
  name: Scalars['String']['output'];
  offset?: Maybe<Scalars['Int']['output']>;
  /**
   * If this field is defined, this field is linked to the specified column on the
   * Patient model and will update that field when the form updates
   */
  patientColumn?: Maybe<PatientFieldLink>;
  placeholder?: Maybe<Scalars['String']['output']>;
  /** Order of the field in the section */
  position?: Maybe<Scalars['Int']['output']>;
  required?: Maybe<Scalars['Boolean']['output']>;
  sectionId?: Maybe<Scalars['ID']['output']>;
};

export type FormFieldOptionInput = {
  alertId?: InputMaybe<Scalars['ID']['input']>;
  label?: InputMaybe<Scalars['String']['input']>;
};

export type FormFieldOptionType = {
  __typename?: 'FormFieldOptionType';
  alertId?: Maybe<Scalars['ID']['output']>;
  label?: Maybe<Scalars['String']['output']>;
};

export type FormGenerateLinkInput = {
  form: Scalars['ID']['input'];
  type: AccessRestrictionAuthentication;
};

export type FormMobileField = FormFieldInterface & {
  __typename?: 'FormMobileField';
  cols?: Maybe<Scalars['Int']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  /**
   * This should be filtered out and not stored in the database
   * but kept on the type to maintain integrity of the type definitions
   * as error is still a valid property on any form field
   */
  error?: Maybe<Scalars['String']['output']>;
  fieldType: FieldType;
  id?: Maybe<Scalars['String']['output']>;
  key: Scalars['String']['output'];
  name: Scalars['String']['output'];
  offset?: Maybe<Scalars['Int']['output']>;
  /**
   * If this field is defined, this field is linked to the specified column on the
   * Patient model and will update that field when the form updates
   */
  patientColumn?: Maybe<PatientFieldLink>;
  phoneNumber?: Maybe<MobileValue>;
  placeholder?: Maybe<Scalars['String']['output']>;
  /** Order of the field in the section */
  position?: Maybe<Scalars['Int']['output']>;
  required?: Maybe<Scalars['Boolean']['output']>;
  sectionId?: Maybe<Scalars['ID']['output']>;
};

export type FormNumberField = FormFieldInterface & {
  __typename?: 'FormNumberField';
  cols?: Maybe<Scalars['Int']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  /**
   * This should be filtered out and not stored in the database
   * but kept on the type to maintain integrity of the type definitions
   * as error is still a valid property on any form field
   */
  error?: Maybe<Scalars['String']['output']>;
  fieldType: FieldType;
  id?: Maybe<Scalars['String']['output']>;
  key: Scalars['String']['output'];
  max?: Maybe<Scalars['Int']['output']>;
  min?: Maybe<Scalars['Int']['output']>;
  name: Scalars['String']['output'];
  number?: Maybe<Scalars['BigInt']['output']>;
  offset?: Maybe<Scalars['Int']['output']>;
  /**
   * If this field is defined, this field is linked to the specified column on the
   * Patient model and will update that field when the form updates
   */
  patientColumn?: Maybe<PatientFieldLink>;
  placeholder?: Maybe<Scalars['String']['output']>;
  /** Order of the field in the section */
  position?: Maybe<Scalars['Int']['output']>;
  required?: Maybe<Scalars['Boolean']['output']>;
  sectionId?: Maybe<Scalars['ID']['output']>;
};

export enum FormOrder {
  /** Sort by created_at */
  CreatedAt = 'created_at',
  /** Sort by ID */
  Id = 'id',
  /** Sort by updated_at */
  UpdatedAt = 'updated_at'
}

export type FormOrderInput = {
  direction?: InputMaybe<Direction>;
  field?: InputMaybe<FormOrder>;
};

export type FormParagraphField = FormFieldInterface & {
  __typename?: 'FormParagraphField';
  cols?: Maybe<Scalars['Int']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  /**
   * This should be filtered out and not stored in the database
   * but kept on the type to maintain integrity of the type definitions
   * as error is still a valid property on any form field
   */
  error?: Maybe<Scalars['String']['output']>;
  fieldType: FieldType;
  id?: Maybe<Scalars['String']['output']>;
  key: Scalars['String']['output'];
  name: Scalars['String']['output'];
  offset?: Maybe<Scalars['Int']['output']>;
  /**
   * If this field is defined, this field is linked to the specified column on the
   * Patient model and will update that field when the form updates
   */
  patientColumn?: Maybe<PatientFieldLink>;
  placeholder?: Maybe<Scalars['String']['output']>;
  /** Order of the field in the section */
  position?: Maybe<Scalars['Int']['output']>;
  required?: Maybe<Scalars['Boolean']['output']>;
  sectionId?: Maybe<Scalars['ID']['output']>;
};

export type FormRadioField = FormFieldInterface & {
  __typename?: 'FormRadioField';
  cols?: Maybe<Scalars['Int']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  /**
   * This should be filtered out and not stored in the database
   * but kept on the type to maintain integrity of the type definitions
   * as error is still a valid property on any form field
   */
  error?: Maybe<Scalars['String']['output']>;
  fieldType: FieldType;
  id?: Maybe<Scalars['String']['output']>;
  key: Scalars['String']['output'];
  name: Scalars['String']['output'];
  offset?: Maybe<Scalars['Int']['output']>;
  options?: Maybe<Array<FormFieldOptionType>>;
  /**
   * If this field is defined, this field is linked to the specified column on the
   * Patient model and will update that field when the form updates
   */
  patientColumn?: Maybe<PatientFieldLink>;
  placeholder?: Maybe<Scalars['String']['output']>;
  /** Order of the field in the section */
  position?: Maybe<Scalars['Int']['output']>;
  required?: Maybe<Scalars['Boolean']['output']>;
  sectionId?: Maybe<Scalars['ID']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

export type FormSection = {
  __typename?: 'FormSection';
  /** Fields belonging to this section */
  fields?: Maybe<Array<FormField>>;
  /** Unique identifier for the section */
  id: Scalars['ID']['output'];
  /** Title of the section */
  name?: Maybe<Scalars['String']['output']>;
  /** Order in which this section is displayed */
  position?: Maybe<Scalars['Int']['output']>;
};

export type FormSectionField = FormFieldInterface & {
  __typename?: 'FormSectionField';
  cols?: Maybe<Scalars['Int']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  /**
   * This should be filtered out and not stored in the database
   * but kept on the type to maintain integrity of the type definitions
   * as error is still a valid property on any form field
   */
  error?: Maybe<Scalars['String']['output']>;
  fieldType: FieldType;
  id?: Maybe<Scalars['String']['output']>;
  key: Scalars['String']['output'];
  name: Scalars['String']['output'];
  offset?: Maybe<Scalars['Int']['output']>;
  /**
   * If this field is defined, this field is linked to the specified column on the
   * Patient model and will update that field when the form updates
   */
  patientColumn?: Maybe<PatientFieldLink>;
  placeholder?: Maybe<Scalars['String']['output']>;
  /** Order of the field in the section */
  position?: Maybe<Scalars['Int']['output']>;
  required?: Maybe<Scalars['Boolean']['output']>;
  sectionId?: Maybe<Scalars['ID']['output']>;
};

/** Single or Multi Select */
export type FormSelectField = FormSelectMultiField | FormSelectSingleField;

export type FormSelectMultiField = FormFieldInterface & {
  __typename?: 'FormSelectMultiField';
  cols?: Maybe<Scalars['Int']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  /**
   * This should be filtered out and not stored in the database
   * but kept on the type to maintain integrity of the type definitions
   * as error is still a valid property on any form field
   */
  error?: Maybe<Scalars['String']['output']>;
  fieldType: FieldType;
  id?: Maybe<Scalars['String']['output']>;
  key: Scalars['String']['output'];
  multiple: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  offset?: Maybe<Scalars['Int']['output']>;
  options?: Maybe<Array<FormFieldOptionType>>;
  /**
   * If this field is defined, this field is linked to the specified column on the
   * Patient model and will update that field when the form updates
   */
  patientColumn?: Maybe<PatientFieldLink>;
  placeholder?: Maybe<Scalars['String']['output']>;
  /** Order of the field in the section */
  position?: Maybe<Scalars['Int']['output']>;
  required?: Maybe<Scalars['Boolean']['output']>;
  sectionId?: Maybe<Scalars['ID']['output']>;
  values?: Maybe<Array<Scalars['String']['output']>>;
};

export type FormSelectSingleField = FormFieldInterface & {
  __typename?: 'FormSelectSingleField';
  cols?: Maybe<Scalars['Int']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  /**
   * This should be filtered out and not stored in the database
   * but kept on the type to maintain integrity of the type definitions
   * as error is still a valid property on any form field
   */
  error?: Maybe<Scalars['String']['output']>;
  fieldType: FieldType;
  id?: Maybe<Scalars['String']['output']>;
  key: Scalars['String']['output'];
  multiple: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  offset?: Maybe<Scalars['Int']['output']>;
  options?: Maybe<Array<FormFieldOptionType>>;
  /**
   * If this field is defined, this field is linked to the specified column on the
   * Patient model and will update that field when the form updates
   */
  patientColumn?: Maybe<PatientFieldLink>;
  placeholder?: Maybe<Scalars['String']['output']>;
  /** Order of the field in the section */
  position?: Maybe<Scalars['Int']['output']>;
  required?: Maybe<Scalars['Boolean']['output']>;
  sectionId?: Maybe<Scalars['ID']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

export type FormSignatureField = FormFieldInterface & {
  __typename?: 'FormSignatureField';
  attachment?: Maybe<FormFieldAttachment>;
  cols?: Maybe<Scalars['Int']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  /**
   * This should be filtered out and not stored in the database
   * but kept on the type to maintain integrity of the type definitions
   * as error is still a valid property on any form field
   */
  error?: Maybe<Scalars['String']['output']>;
  fieldType: FieldType;
  id?: Maybe<Scalars['String']['output']>;
  key: Scalars['String']['output'];
  name: Scalars['String']['output'];
  offset?: Maybe<Scalars['Int']['output']>;
  /**
   * If this field is defined, this field is linked to the specified column on the
   * Patient model and will update that field when the form updates
   */
  patientColumn?: Maybe<PatientFieldLink>;
  placeholder?: Maybe<Scalars['String']['output']>;
  /** Order of the field in the section */
  position?: Maybe<Scalars['Int']['output']>;
  required?: Maybe<Scalars['Boolean']['output']>;
  sectionId?: Maybe<Scalars['ID']['output']>;
};

export enum FormState {
  Completed = 'completed',
  CompletedAndLocked = 'completed_and_locked',
  Created = 'created',
  Delivered = 'delivered',
  Failed = 'failed',
  NotDelivered = 'not_delivered',
  Sent = 'sent'
}

export type FormTemplate = TemplateInterface & {
  __typename?: 'FormTemplate';
  assigned?: Maybe<Array<TemplateAssignable>>;
  attachments?: Maybe<Array<Attachment>>;
  communicationTemplate?: Maybe<CommunicationTemplate>;
  createdAt: Scalars['ISO8601DateTime']['output'];
  /** Template marked as default */
  default: Scalars['Boolean']['output'];
  description?: Maybe<Scalars['String']['output']>;
  formTemplateType: FormTemplateType;
  forms?: Maybe<Array<Form>>;
  /** What this template type is used for (e.g Forms, Appointments, Invoices) */
  group?: Maybe<TemplateGroupTranslation>;
  guid: Scalars['ID']['output'];
  /** Email content */
  html?: Maybe<Scalars['Base64Brotli']['output']>;
  id: Scalars['ID']['output'];
  isMedical: Scalars['Boolean']['output'];
  /** Form fields */
  jsonContent?: Maybe<Array<FormFieldInterface>>;
  name?: Maybe<Scalars['String']['output']>;
  numCompleted: Scalars['Int']['output'];
  numSent: Scalars['Int']['output'];
  /** SMS content */
  plainText?: Maybe<Scalars['Base64Brotli']['output']>;
  practice: Practice;
  practiceId: Scalars['ID']['output'];
  /** Form Sections containing fields */
  sections: Array<FormSection>;
  state: TemplateState;
  /** Email subject */
  subject?: Maybe<Scalars['String']['output']>;
  type: TemplateType;
  typeDefinition?: Maybe<TemplateTypeTranslation>;
  uuid: Scalars['String']['output'];
  /** How this template is to be used - e.g. Document (only HTML), Communication (HTML + Plain), or Form (JSON) */
  variant?: Maybe<TemplateVariantTranslation>;
};

/** The connection type for FormTemplate. */
export type FormTemplateConnection = {
  __typename?: 'FormTemplateConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<FormTemplateEdge>>>;
  /** IDs of ALL results, regardless of pagination */
  ids: Array<Scalars['ID']['output']>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<FormTemplate>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Returns total count of items in list */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type FormTemplateEdge = {
  __typename?: 'FormTemplateEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<FormTemplate>;
};

export type FormTemplateInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  html?: InputMaybe<Scalars['String']['input']>;
  isMedical?: InputMaybe<Scalars['Boolean']['input']>;
  /** Form field definitions */
  jsonContent?: InputMaybe<Array<FormFieldInput>>;
  name?: InputMaybe<Scalars['String']['input']>;
  parent?: InputMaybe<Scalars['ID']['input']>;
  plainText?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<TemplateState>;
  subject?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<FormTemplateType>;
};

export enum FormTemplateType {
  /** Used for general interactive forms filled in by the recipient */
  Form = 'form',
  /** Used for interactive intake forms filled in by the recipient to update their personal information */
  Intake = 'intake',
  /** Surveys are forms with specific fields, where answers are weighted and summarised */
  Survey = 'survey',
  /** Sent to patients to deliver a form regarding a recent treatment */
  Treatment = 'treatment',
  /** Used for interactive Form Templates filled in by the recipient */
  Waiver = 'waiver'
}

export type FormTextField = FormFieldInterface & {
  __typename?: 'FormTextField';
  cols?: Maybe<Scalars['Int']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  /**
   * This should be filtered out and not stored in the database
   * but kept on the type to maintain integrity of the type definitions
   * as error is still a valid property on any form field
   */
  error?: Maybe<Scalars['String']['output']>;
  fieldType: FieldType;
  id?: Maybe<Scalars['String']['output']>;
  key: Scalars['String']['output'];
  name: Scalars['String']['output'];
  offset?: Maybe<Scalars['Int']['output']>;
  /**
   * If this field is defined, this field is linked to the specified column on the
   * Patient model and will update that field when the form updates
   */
  patientColumn?: Maybe<PatientFieldLink>;
  placeholder?: Maybe<Scalars['String']['output']>;
  /** Order of the field in the section */
  position?: Maybe<Scalars['Int']['output']>;
  required?: Maybe<Scalars['Boolean']['output']>;
  sectionId?: Maybe<Scalars['ID']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

export type FormTitleField = FormFieldInterface & {
  __typename?: 'FormTitleField';
  cols?: Maybe<Scalars['Int']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  /**
   * This should be filtered out and not stored in the database
   * but kept on the type to maintain integrity of the type definitions
   * as error is still a valid property on any form field
   */
  error?: Maybe<Scalars['String']['output']>;
  fieldType: FieldType;
  id?: Maybe<Scalars['String']['output']>;
  key: Scalars['String']['output'];
  name: Scalars['String']['output'];
  offset?: Maybe<Scalars['Int']['output']>;
  /**
   * If this field is defined, this field is linked to the specified column on the
   * Patient model and will update that field when the form updates
   */
  patientColumn?: Maybe<PatientFieldLink>;
  placeholder?: Maybe<Scalars['String']['output']>;
  /** Order of the field in the section */
  position?: Maybe<Scalars['Int']['output']>;
  required?: Maybe<Scalars['Boolean']['output']>;
  sectionId?: Maybe<Scalars['ID']['output']>;
};

export type FormUpdateInput = {
  jsonContent: Array<FormFieldInput>;
};

export type FormUploadField = FormFieldInterface & {
  __typename?: 'FormUploadField';
  acceptedFileTypes?: Maybe<Array<Scalars['String']['output']>>;
  cols?: Maybe<Scalars['Int']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  /**
   * This should be filtered out and not stored in the database
   * but kept on the type to maintain integrity of the type definitions
   * as error is still a valid property on any form field
   */
  error?: Maybe<Scalars['String']['output']>;
  fieldType: FieldType;
  files?: Maybe<Array<FormFieldAttachment>>;
  id?: Maybe<Scalars['String']['output']>;
  key: Scalars['String']['output'];
  multiple: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  offset?: Maybe<Scalars['Int']['output']>;
  /**
   * If this field is defined, this field is linked to the specified column on the
   * Patient model and will update that field when the form updates
   */
  patientColumn?: Maybe<PatientFieldLink>;
  placeholder?: Maybe<Scalars['String']['output']>;
  /** Order of the field in the section */
  position?: Maybe<Scalars['Int']['output']>;
  required?: Maybe<Scalars['Boolean']['output']>;
  sectionId?: Maybe<Scalars['ID']['output']>;
};

export type FormVideoField = FormFieldInterface & {
  __typename?: 'FormVideoField';
  cols?: Maybe<Scalars['Int']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  /**
   * This should be filtered out and not stored in the database
   * but kept on the type to maintain integrity of the type definitions
   * as error is still a valid property on any form field
   */
  error?: Maybe<Scalars['String']['output']>;
  fieldType: FieldType;
  id?: Maybe<Scalars['String']['output']>;
  key: Scalars['String']['output'];
  name: Scalars['String']['output'];
  offset?: Maybe<Scalars['Int']['output']>;
  /**
   * If this field is defined, this field is linked to the specified column on the
   * Patient model and will update that field when the form updates
   */
  patientColumn?: Maybe<PatientFieldLink>;
  placeholder?: Maybe<Scalars['String']['output']>;
  /** Order of the field in the section */
  position?: Maybe<Scalars['Int']['output']>;
  required?: Maybe<Scalars['Boolean']['output']>;
  sectionId?: Maybe<Scalars['ID']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

export type FullCalendarCredentials = {
  __typename?: 'FullCalendarCredentials';
  /** Intercom App ID */
  licenseKey?: Maybe<Scalars['Base64']['output']>;
};

export enum Gender {
  /** Female */
  Female = 'female',
  /** Male */
  Male = 'male',
  /** Non Binary */
  NonBinary = 'non_binary',
  /** Other */
  Other = 'other',
  /** Prefer Not To Say */
  PreferNotToSay = 'prefer_not_to_say'
}

/** Autogenerated input type of GrantPermission */
export type GrantPermissionInput = {
  attributes: PermissionInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of GrantPermission. */
export type GrantPermissionPayload = {
  __typename?: 'GrantPermissionPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  fieldErrors?: Maybe<Array<FieldError>>;
  role?: Maybe<Role>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

/** Autogenerated input type of GrantPermissionToGroup */
export type GrantPermissionToGroupInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  groupId: Scalars['ID']['input'];
  /** Permission slug */
  permission: Scalars['String']['input'];
};

/** Autogenerated return type of GrantPermissionToGroup. */
export type GrantPermissionToGroupPayload = {
  __typename?: 'GrantPermissionToGroupPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  fieldErrors?: Maybe<Array<FieldError>>;
  group?: Maybe<Group>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type Group = {
  __typename?: 'Group';
  description?: Maybe<Scalars['String']['output']>;
  hasSubgroups: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  inheritedPermissions?: Maybe<Array<Permission>>;
  name?: Maybe<Scalars['String']['output']>;
  parent?: Maybe<Group>;
  permissions?: Maybe<Array<Permission>>;
  practice: Practice;
  roles?: Maybe<Array<Role>>;
  slug?: Maybe<Scalars['String']['output']>;
  subgroups?: Maybe<Array<Group>>;
  userPracticeConnections?: Maybe<UserPracticeLinkConnection>;
};

/** The connection type for Group. */
export type GroupConnection = {
  __typename?: 'GroupConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<GroupEdge>>>;
  /** IDs of ALL results, regardless of pagination */
  ids: Array<Scalars['ID']['output']>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Group>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Returns total count of items in list */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type GroupEdge = {
  __typename?: 'GroupEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<Group>;
};

export type GroupInput = {
  description: Scalars['String']['input'];
  name: Scalars['String']['input'];
  permissions?: InputMaybe<Array<Permission>>;
};

export type Health = {
  __typename?: 'Health';
  buildTime?: Maybe<Scalars['ISO8601DateTime']['output']>;
  /** Frontend version */
  bundleVersion?: Maybe<Scalars['String']['output']>;
  commit?: Maybe<Scalars['String']['output']>;
  environment: ApplicationEnvironments;
  migrated?: Maybe<Scalars['Boolean']['output']>;
  /** Check if application is migrated and connected */
  ready?: Maybe<Scalars['Boolean']['output']>;
  version?: Maybe<Scalars['String']['output']>;
};

export type HealthFundConsumer = {
  __typename?: 'HealthFundConsumer';
  createdAt: Scalars['ISO8601DateTime']['output'];
  id: Scalars['String']['output'];
  primaryIdentifier?: Maybe<Scalars['String']['output']>;
  provider?: Maybe<HealthFundProvider>;
  secondaryIdentifier?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

/** The connection type for HealthFundConsumer. */
export type HealthFundConsumerConnection = {
  __typename?: 'HealthFundConsumerConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<HealthFundConsumerEdge>>>;
  /** IDs of ALL results, regardless of pagination */
  ids: Array<Scalars['ID']['output']>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<HealthFundConsumer>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Returns total count of items in list */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type HealthFundConsumerEdge = {
  __typename?: 'HealthFundConsumerEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<HealthFundConsumer>;
};

export type HealthFundIdentifierInput = {
  /** Provide an existing ID to update */
  id?: InputMaybe<Scalars['String']['input']>;
  /** Primary number, e.g Card number */
  primaryIdentifier: Scalars['String']['input'];
  /** Health Fund Provider ID to link this card to */
  providerGuid: Scalars['String']['input'];
  /** Secondary number, e.g reference (optional) */
  secondaryIdentifier?: InputMaybe<Scalars['String']['input']>;
};

export type HealthFundProvider = HealthFundProviderInterface & {
  __typename?: 'HealthFundProvider';
  createdAt: Scalars['ISO8601DateTime']['output'];
  gateways?: Maybe<Array<HealthFundsGateway>>;
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
  public?: Maybe<Scalars['Boolean']['output']>;
  slug?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

/** Base Health Fund Provider Fields */
export type HealthFundProviderInterface = {
  createdAt: Scalars['ISO8601DateTime']['output'];
  gateways?: Maybe<Array<HealthFundsGateway>>;
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
  public?: Maybe<Scalars['Boolean']['output']>;
  slug?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

export type HealthFundsCancelClaimInput = {
  /** Raw request sent to the gateway */
  request: Scalars['JSON']['input'];
  /** Raw response from gateway */
  response: Scalars['JSON']['input'];
};

export type HealthFundsClaimInput = {
  /** Array of claim items to be processed */
  claimItems: Array<HealthFundsClaimItemInput>;
  /** Gateway e.g Hicaps/Tyro */
  gateway: HealthFundsGateway;
  /** Raw request sent to the gateway */
  request: Scalars['JSON']['input'];
  /** Raw response from gateway */
  response: Scalars['JSON']['input'];
};

export type HealthFundsClaimItem = {
  __typename?: 'HealthFundsClaimItem';
  /** The amount returned by the health fund that is to be claimed */
  amount?: Maybe<Scalars['Float']['output']>;
  claimable?: Maybe<AnyNode>;
  /** THe GUID of the claim item object */
  claimableId: Scalars['String']['output'];
  /** The object linked to this claim item */
  claimableType: Scalars['String']['output'];
  /** The code of the item */
  code?: Maybe<Scalars['String']['output']>;
  /** The date which the item was created */
  createdAt: Scalars['ISO8601DateTime']['output'];
  /** The date for this item to be claimed */
  date?: Maybe<Scalars['ISO8601Date']['output']>;
  /** The date which the item was discarded (soft delete) */
  discardedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  /** The total out of pocket that the patient now needs to pay */
  gap?: Maybe<Scalars['Float']['output']>;
  /** Reference of this item at the gateway. e.g Hicaps ID of this item */
  gatewayRef?: Maybe<Scalars['String']['output']>;
  /** Name of the health fund that processed the transaction */
  healthFundName?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  /** Modality assigned to this item */
  modality?: Maybe<Scalars['String']['output']>;
  /** The name of the item */
  name?: Maybe<Scalars['String']['output']>;
  /** The original amount sent to the health fund to be claimed */
  originalAmount?: Maybe<Scalars['Float']['output']>;
  /** Reference of the patient. Normally this is their reference on their health fund card. e.g 01, 02 etc */
  patientRef?: Maybe<Scalars['String']['output']>;
  /** The amount that the healthfund will be rebated */
  rebate?: Maybe<Scalars['Float']['output']>;
  /** Request linked to this claim item */
  request?: Maybe<HealthFundsRequest>;
  /** The ID of the request */
  requestId?: Maybe<Scalars['ID']['output']>;
  /** The request type */
  requestType?: Maybe<HealthFundsRequestType>;
  /** Healthfund response code */
  responseCode?: Maybe<Scalars['String']['output']>;
  /** health fund response message */
  responseMessage?: Maybe<Scalars['String']['output']>;
  /** Reference of the service to be claimed. For dental this can be the FDI code of the tooth. */
  serviceRef?: Maybe<Scalars['String']['output']>;
  /** The state of the request */
  state?: Maybe<HealthFundsClaimItemState>;
  /** The date which the item was last updated */
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

export type HealthFundsClaimItemAttributes = {
  __typename?: 'HealthFundsClaimItemAttributes';
  /** Medicare Item Override Code */
  medicareItemOverrideCode?: Maybe<HealthFundsMedicareClaimItemOverrideCode>;
  /** Medicare Location Specific Practice Number */
  medicareLspn?: Maybe<Scalars['String']['output']>;
  /** Medicare Item Self Deemed Code */
  medicareSelfDeemedCode?: Maybe<HealthFundsMedicareClaimItemSelfDeemedCode>;
};

export type HealthFundsClaimItemAttributesInput = {
  /** Medicare Item Override Code */
  medicareItemOverrideCode?: InputMaybe<HealthFundsMedicareClaimItemOverrideCode>;
  /** Medicare Location Specific Practice Number */
  medicareLspn?: InputMaybe<Scalars['String']['input']>;
  /** Medicare Item Self Deemed Code */
  medicareSelfDeemedCode?: InputMaybe<HealthFundsMedicareClaimItemSelfDeemedCode>;
};

export type HealthFundsClaimItemInput = {
  /** Claim amount */
  amount: Scalars['Float']['input'];
  /** The current balance of the item */
  balance?: InputMaybe<Scalars['Float']['input']>;
  /** Claimable item ID */
  claimableId: Scalars['ID']['input'];
  /** Claimable Type e.g PatientRecordItem or InvoiceItem */
  claimableType: Scalars['String']['input'];
  /** Code */
  code: Scalars['String']['input'];
  /** Service date */
  date: Scalars['ISO8601Date']['input'];
  /** Gap amount */
  gap?: InputMaybe<Scalars['Float']['input']>;
  /** Name */
  name: Scalars['String']['input'];
  /** Amount already paid by the patient */
  paid?: InputMaybe<Scalars['Float']['input']>;
  /** Patient reference e.g 01 */
  patientRef: Scalars['String']['input'];
  /** Rebate amount */
  rebate?: InputMaybe<Scalars['Float']['input']>;
  /** Raw response from gateway */
  response?: InputMaybe<Scalars['JSON']['input']>;
  /** Item response code */
  responseCode?: InputMaybe<Scalars['String']['input']>;
  /** Item response code message */
  responseMessage?: InputMaybe<Scalars['String']['input']>;
  /** Service reference e.g 18 for FDI */
  serviceRef?: InputMaybe<Scalars['String']['input']>;
};

export enum HealthFundsClaimItemState {
  /** Failed */
  Failed = 'failed',
  /** Processing */
  Processing = 'processing',
  /** Success */
  Success = 'success'
}

export enum HealthFundsGateway {
  Hicaps = 'hicaps',
  Medipass = 'medipass',
  Tyro = 'tyro'
}

export enum HealthFundsMedicareClaimItemOverrideCode {
  /** Ao */
  Ao = 'AO',
  /** Ap */
  Ap = 'AP',
  /** Nc */
  Nc = 'NC'
}

export enum HealthFundsMedicareClaimItemSelfDeemedCode {
  /** N */
  N = 'N',
  /** Sd */
  Sd = 'SD',
  /** Ss */
  Ss = 'SS'
}

export type HealthFundsPaymentInput = {
  /** Amount to be paid */
  amount: Scalars['Float']['input'];
  /** Gateway e.g Hicaps/Tyro */
  gateway: HealthFundsGateway;
  /** Raw request sent to the gateway */
  request: Scalars['JSON']['input'];
  /** Raw response from gateway */
  response: Scalars['JSON']['input'];
  /** Surcharge amount */
  surcharge?: InputMaybe<Scalars['Float']['input']>;
};

export type HealthFundsQuoteInput = {
  /** Array of claim items to be processed */
  claimItems: Array<HealthFundsClaimItemInput>;
  /** Gateway e.g Hicaps/Tyro */
  gateway: HealthFundsGateway;
  /** Raw request sent to the gateway */
  request: Scalars['JSON']['input'];
  /** Raw response from gateway */
  response: Scalars['JSON']['input'];
};

export type HealthFundsRefundInput = {
  /** Amount to be refunded */
  amount: Scalars['Float']['input'];
  /** Gateway e.g Hicaps/Tyro */
  gateway: HealthFundsGateway;
  /** Raw request sent to the gateway */
  request: Scalars['JSON']['input'];
  /** Raw response from gateway */
  response: Scalars['JSON']['input'];
};

export type HealthFundsRequest = {
  __typename?: 'HealthFundsRequest';
  /** The total amount of the request */
  amount?: Maybe<Scalars['Float']['output']>;
  /** The card expiry of the request */
  cardExpiry?: Maybe<Scalars['String']['output']>;
  /** The card number of the request */
  cardNumber?: Maybe<Scalars['String']['output']>;
  /** The claim items associated to this request */
  claimItems?: Maybe<Array<HealthFundsClaimItem>>;
  /** The date which the item was created */
  createdAt: Scalars['ISO8601DateTime']['output'];
  /** The date for this item to be claimed */
  date?: Maybe<Scalars['ISO8601Date']['output']>;
  /** The date which the item was discarded (soft delete) */
  discardedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  /** The total gap of the request */
  gap?: Maybe<Scalars['Float']['output']>;
  /** The healthfund gateway */
  gateway?: Maybe<HealthFundsGateway>;
  /** Name of the health fund that processed the transaction */
  healthFundName?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  /** The invoice transaction that this request is linked to */
  invoiceTransaction?: Maybe<InvoiceTransaction>;
  /** The ID of the invoice transaction linked to this request */
  invoiceTransactionGuid?: Maybe<Scalars['ID']['output']>;
  /** The merchant reference of the request */
  merchantRef?: Maybe<Scalars['String']['output']>;
  /** Base64 data uri receipt PDF */
  originalReceiptUri?: Maybe<Scalars['String']['output']>;
  /** The patient that created this request */
  patient?: Maybe<UserPracticeLink>;
  /** The ID of the patient that created this request */
  patientGuid?: Maybe<Scalars['ID']['output']>;
  /** Base64 data uri PDF Attachment of patient receipt */
  patientReceiptDataUri?: Maybe<Scalars['String']['output']>;
  /** The ID of the patient receipt */
  patientReceiptGuid: Scalars['ID']['output'];
  /** The practice that this cycle resides under */
  practice?: Maybe<Practice>;
  /** The ID of the practice */
  practiceGuid?: Maybe<Scalars['ID']['output']>;
  /** The practitioner that created this request */
  practitioner?: Maybe<UserPracticeLink>;
  /** The ID of the practitioner that created this request */
  practitionerGuid?: Maybe<Scalars['ID']['output']>;
  /** Base64 data uri PDF Attachment of provider receipt */
  providerReceiptDataUri?: Maybe<Scalars['String']['output']>;
  /** The ID of the provider receipt */
  providerReceiptGuid: Scalars['ID']['output'];
  /** The provider reference of the request */
  providerRef?: Maybe<Scalars['String']['output']>;
  /** The total rebate of the request */
  rebate?: Maybe<Scalars['Float']['output']>;
  /** Timestamp of when the original receipt was generated */
  receiptPrintedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  /** The request type */
  requestType?: Maybe<HealthFundsRequestType>;
  requestable?: Maybe<AnyNode>;
  /** @deprecated Avoid using requestable_type/requestable_id directly and instead spread fragments on .requestable */
  requestableId?: Maybe<Scalars['ID']['output']>;
  /** @deprecated Avoid using requestable_type/requestable_id directly and instead spread fragments on .requestable */
  requestableType?: Maybe<Scalars['String']['output']>;
  /** The response code from the gateway. */
  responseCode?: Maybe<Scalars['String']['output']>;
  /** The response message from the gateway */
  responseMessage?: Maybe<Scalars['String']['output']>;
  /** The rrn of the request. In Hicaps this is a daily unique number */
  rrn?: Maybe<Scalars['String']['output']>;
  /** The state of the request */
  state?: Maybe<HealthFundsRequestState>;
  /** The terminal reference of the request */
  terminalRef?: Maybe<Scalars['String']['output']>;
  /** The date which the item was last updated */
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

/** The connection type for HealthFundsRequest. */
export type HealthFundsRequestConnection = {
  __typename?: 'HealthFundsRequestConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<HealthFundsRequestEdge>>>;
  /** IDs of ALL results, regardless of pagination */
  ids: Array<Scalars['ID']['output']>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<HealthFundsRequest>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Returns total count of items in list */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type HealthFundsRequestEdge = {
  __typename?: 'HealthFundsRequestEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<HealthFundsRequest>;
};

export enum HealthFundsRequestState {
  /** Cancelled */
  Cancelled = 'cancelled',
  /** Failed */
  Failed = 'failed',
  /** Processing */
  Processing = 'processing',
  /** Success */
  Success = 'success'
}

export enum HealthFundsRequestType {
  Cancel = 'cancel',
  Claim = 'claim',
  Estimate = 'estimate',
  MedicareClaim = 'medicare_claim',
  Refund = 'refund',
  Sale = 'sale'
}

/** Autogenerated return type of Health. */
export type HealthPayload = {
  __typename?: 'HealthPayload';
  /** Subscribe to changes in the environment, e.g version or build number */
  status?: Maybe<Health>;
};

export type HicapsCredentials = {
  __typename?: 'HicapsCredentials';
  bridgeVersion?: Maybe<Scalars['String']['output']>;
};

export type HicapsPaymentProviderInput = {
  /** Host address of where the bridge app is installed */
  bridgeHost?: InputMaybe<Scalars['String']['input']>;
  /** Enable/Disable payment provider */
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  /** Run Bridge host over HTTPS or not */
  https?: InputMaybe<Scalars['Boolean']['input']>;
};

export type Hidden = AnyNode & {
  __typename?: 'Hidden';
  /** Unique identifier of the message */
  uuid: Scalars['String']['output'];
};

export enum Icon {
  /** Allergies */
  Allergies = 'allergies',
  /** Ambulance */
  Ambulance = 'ambulance',
  /** Band Aid */
  BandAid = 'band_aid',
  /** Bed */
  Bed = 'bed',
  /** Bed Pulse */
  BedPulse = 'bed_pulse',
  /** Biohazard */
  Biohazard = 'biohazard',
  /** Blind */
  Blind = 'blind',
  /** Bone */
  Bone = 'bone',
  /** Brain */
  Brain = 'brain',
  /** Bullseye */
  Bullseye = 'bullseye',
  /** Burn */
  Burn = 'burn',
  /** Calendar */
  Calendar = 'calendar',
  /** Calendar Clock */
  CalendarClock = 'calendar_clock',
  /** Calendar Pen */
  CalendarPen = 'calendar_pen',
  /** Capsules */
  Capsules = 'capsules',
  /** Cash Register */
  CashRegister = 'cash_register',
  /** Check Circle */
  CheckCircle = 'check_circle',
  /** Circle Dollar To Slot */
  CircleDollarToSlot = 'circle_dollar_to_slot',
  /** Circle Exclamation */
  CircleExclamation = 'circle_exclamation',
  /** Circle Radiation */
  CircleRadiation = 'circle_radiation',
  /** Clipboard List */
  ClipboardList = 'clipboard_list',
  /** Clipboard List Check */
  ClipboardListCheck = 'clipboard_list_check',
  /** Clipboard Medical */
  ClipboardMedical = 'clipboard_medical',
  /** Clock */
  Clock = 'clock',
  /** Credit Card */
  CreditCard = 'credit_card',
  /** Crutch */
  Crutch = 'crutch',
  /** Eye */
  Eye = 'eye',
  /** Face Grimace */
  FaceGrimace = 'face_grimace',
  /** Face Smile */
  FaceSmile = 'face_smile',
  /** Face Surprise */
  FaceSurprise = 'face_surprise',
  /** Face Worried */
  FaceWorried = 'face_worried',
  /** File Invoice */
  FileInvoice = 'file_invoice',
  /** Globe */
  Globe = 'globe',
  /** Head Side Cough */
  HeadSideCough = 'head_side_cough',
  /** Head Side Mask */
  HeadSideMask = 'head_side_mask',
  /** Heart */
  Heart = 'heart',
  /** Heart Pulse */
  HeartPulse = 'heart_pulse',
  /** Hospital Symbol */
  HospitalSymbol = 'hospital_symbol',
  /** House Medical Circle Check */
  HouseMedicalCircleCheck = 'house_medical_circle_check',
  /** House Medical Circle Exclamation */
  HouseMedicalCircleExclamation = 'house_medical_circle_exclamation',
  /** Kit Medical */
  KitMedical = 'kit_medical',
  /** Light Emergency */
  LightEmergency = 'light_emergency',
  /** Lightbulb */
  Lightbulb = 'lightbulb',
  /** Location Pin */
  LocationPin = 'location_pin',
  /** Lungs */
  Lungs = 'lungs',
  /** Lungs Virus */
  LungsVirus = 'lungs_virus',
  /** Mask Face */
  MaskFace = 'mask_face',
  /** Memo */
  Memo = 'memo',
  /** Message Dots */
  MessageDots = 'message_dots',
  /** Note */
  Note = 'note',
  /** Note Sticky */
  NoteSticky = 'note_sticky',
  /** Notes Medical */
  NotesMedical = 'notes_medical',
  /** Paperclip */
  Paperclip = 'paperclip',
  /** Pen */
  Pen = 'pen',
  /** Pills */
  Pills = 'pills',
  /** Prescription Bottle */
  PrescriptionBottle = 'prescription_bottle',
  /** Radiation */
  Radiation = 'radiation',
  /** Recall */
  Recall = 'recall',
  /** Restroom */
  Restroom = 'restroom',
  /** Scanner Gun */
  ScannerGun = 'scanner_gun',
  /** Smoking */
  Smoking = 'smoking',
  /** Square Dollar */
  SquareDollar = 'square_dollar',
  /** Star */
  Star = 'star',
  /** Stretcher */
  Stretcher = 'stretcher',
  /** Syringe */
  Syringe = 'syringe',
  /** Tablets */
  Tablets = 'tablets',
  /** Teeth */
  Teeth = 'teeth',
  /** Teeth Open */
  TeethOpen = 'teeth_open',
  /** Thermometer */
  Thermometer = 'thermometer',
  /** Tooth */
  Tooth = 'tooth',
  /** Toothbrush */
  Toothbrush = 'toothbrush',
  /** Triangle Exclamation */
  TriangleExclamation = 'triangle_exclamation',
  /** Truck Medical */
  TruckMedical = 'truck_medical',
  /** Usd */
  Usd = 'usd',
  /** User Plus */
  UserPlus = 'user_plus',
  /** Users */
  Users = 'users',
  /** Vial Virus */
  VialVirus = 'vial_virus',
  /** Viruses */
  Viruses = 'viruses',
  /** Webcam */
  Webcam = 'webcam',
  /** Wheelchair */
  Wheelchair = 'wheelchair'
}

export type IconTranslation = {
  __typename?: 'IconTranslation';
  /** Icon label */
  description: Scalars['String']['output'];
  /** Icon as IconName type */
  icon: Scalars['String']['output'];
  type: Icon;
  value: Scalars['String']['output'];
};

/** Autogenerated input type of ImportDentalCodes */
export type ImportDentalCodesInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The type of dental codes being imported */
  codes?: InputMaybe<Array<Scalars['String']['input']>>;
  /** The Practice to import for */
  practice: Scalars['ID']['input'];
  /** The Account to upgrade */
  subdomain: Scalars['String']['input'];
  /** Update the price of the code */
  updatePrice?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Autogenerated return type of ImportDentalCodes. */
export type ImportDentalCodesPayload = {
  __typename?: 'ImportDentalCodesPayload';
  account?: Maybe<Account>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  fieldErrors?: Maybe<Array<FieldError>>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type ImportFile = {
  __typename?: 'ImportFile';
  createdAt: Scalars['ISO8601DateTime']['output'];
  createdById?: Maybe<Scalars['ID']['output']>;
  duplicateCount?: Maybe<Scalars['Int']['output']>;
  id: Scalars['ID']['output'];
  invalidCount?: Maybe<Scalars['Int']['output']>;
  jobId?: Maybe<Scalars['String']['output']>;
  practice?: Maybe<Practice>;
  practiceLocation?: Maybe<PracticeLocation>;
  rowsCount?: Maybe<Scalars['Int']['output']>;
  state?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['ISO8601DateTime']['output'];
  validCount?: Maybe<Scalars['Int']['output']>;
};

export type Industry = {
  __typename?: 'Industry';
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
  sector?: Maybe<Scalars['String']['output']>;
  slug?: Maybe<Scalars['String']['output']>;
};

export type IntercomAccount = {
  __typename?: 'IntercomAccount';
  /** The ID of the company */
  companyId: Scalars['String']['output'];
  /** When the account was first created */
  createdAt: Scalars['String']['output'];
  customAttributes?: Maybe<IntercomAccountCustomAttributes>;
  /** The account subdomain */
  name?: Maybe<Scalars['String']['output']>;
  /** The current account plan */
  plan?: Maybe<Scalars['String']['output']>;
  /** The number of active users */
  size?: Maybe<Scalars['Int']['output']>;
};

export type IntercomAccountCustomAttributes = {
  __typename?: 'IntercomAccountCustomAttributes';
  activeUsersCount?: Maybe<Scalars['Int']['output']>;
  appointmentCount?: Maybe<Scalars['Int']['output']>;
  inactiveUsersCount?: Maybe<Scalars['Int']['output']>;
  noteCount?: Maybe<Scalars['Int']['output']>;
  patientCount?: Maybe<Scalars['Int']['output']>;
  practiceCount?: Maybe<Scalars['Int']['output']>;
  practices?: Maybe<Array<IntercomPractice>>;
  subscriptionStatus?: Maybe<Scalars['String']['output']>;
  treatmentPlanCount?: Maybe<Scalars['Int']['output']>;
};

export type IntercomConfiguration = {
  __typename?: 'IntercomConfiguration';
  account?: Maybe<IntercomAccount>;
  accountSubdomains?: Maybe<Array<Scalars['String']['output']>>;
  email?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  practices?: Maybe<Array<IntercomPractice>>;
  userId: Scalars['ID']['output'];
};

export type IntercomCredentials = {
  __typename?: 'IntercomCredentials';
  /** Intercom App ID */
  appId?: Maybe<Scalars['String']['output']>;
};

export type IntercomPractice = {
  __typename?: 'IntercomPractice';
  companyId?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['ISO8601DateTime']['output'];
  features?: Maybe<Array<Scalars['String']['output']>>;
  id?: Maybe<Scalars['ID']['output']>;
  industry?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type Invite = {
  __typename?: 'Invite';
  createdAt: Scalars['ISO8601DateTime']['output'];
  guid: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  invitationUrl?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

/** Autogenerated input type of InviteStaff */
export type InviteStaffInput = {
  attributes: OnboardingInviteStaffInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of InviteStaff. */
export type InviteStaffPayload = {
  __typename?: 'InviteStaffPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  fieldErrors?: Maybe<Array<FieldError>>;
  practice?: Maybe<Practice>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

/** Autogenerated input type of InviteToApp */
export type InviteToAppInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** UPC to invite to the app */
  upc: Scalars['ID']['input'];
};

/** Autogenerated return type of InviteToApp. */
export type InviteToAppPayload = {
  __typename?: 'InviteToAppPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** General errors, unrelated to fields */
  errors?: Maybe<Array<Scalars['String']['output']>>;
  /** Input field errors */
  fieldErrors?: Maybe<Array<FieldError>>;
  patientAppConfig?: Maybe<PatientAppConfiguration>;
  /** Whether or not this mutation was successful */
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type Invoice = AnyNode & {
  __typename?: 'Invoice';
  abn?: Maybe<Scalars['String']['output']>;
  appointments?: Maybe<Array<Appointment>>;
  /** Total remaining to pay */
  balance?: Maybe<Scalars['Float']['output']>;
  billTo?: Maybe<Scalars['String']['output']>;
  businessName?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  discardedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  /** Total discount applied */
  discount?: Maybe<Scalars['Float']['output']>;
  /** Total fixed discount to be applied to the invoice */
  discountAmount?: Maybe<Scalars['Float']['output']>;
  dueDate?: Maybe<Scalars['ISO8601DateTime']['output']>;
  fullAddress?: Maybe<Scalars['String']['output']>;
  guid: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  invoiceFooter?: Maybe<Scalars['String']['output']>;
  invoiceNumber?: Maybe<Scalars['Int']['output']>;
  invoiceTransactions?: Maybe<Array<InvoiceTransaction>>;
  issuedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  items?: Maybe<Array<InvoiceItem>>;
  lastSentAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  /** Returns true if invoice is paid in full */
  paid?: Maybe<Scalars['Boolean']['output']>;
  /** Total amount paid */
  paidTotal?: Maybe<Scalars['Float']['output']>;
  /** The patient that the invoice is billed to */
  patient?: Maybe<UserPracticeLink>;
  /** All patients that have an item invoiced to them within this invoice */
  patients?: Maybe<Array<UserPracticeLink>>;
  paymentTransactions?: Maybe<Array<PaymentTransaction>>;
  phone?: Maybe<Scalars['String']['output']>;
  practice: Practice;
  practiceLocation: PracticeLocation;
  /** The ID of the practice location this invoice is assigned to */
  practiceLocationId?: Maybe<Scalars['ID']['output']>;
  /** The practitioner that the invoice was created by */
  practitioner?: Maybe<UserPracticeLink>;
  providerId?: Maybe<Scalars['String']['output']>;
  providerName?: Maybe<Scalars['String']['output']>;
  /**
   * Auto-login Access Restricted link, which can only be requested by the patient
   * themselves when already logged into the mobile app
   */
  proxyLink?: Maybe<Scalars['String']['output']>;
  /** Total quantity of items in the invoice */
  quantity?: Maybe<Scalars['Int']['output']>;
  remindDaysAfterDue?: Maybe<Array<Scalars['Int']['output']>>;
  reminders?: Maybe<Array<Reminder>>;
  state?: Maybe<InvoiceState>;
  /** Total tax applied */
  tax?: Maybe<Scalars['Float']['output']>;
  /** Total price including tax + fixed invoice discounts */
  total?: Maybe<Scalars['Float']['output']>;
  /** Total cost of the invoice before fixed invoice total */
  totalCost?: Maybe<Scalars['Float']['output']>;
  /** Total price excluding tax */
  totalExTax?: Maybe<Scalars['Float']['output']>;
  /** The ID of the billed patient */
  userPracticeConnectionId?: Maybe<Scalars['ID']['output']>;
  /** Unique identifier of the message */
  uuid: Scalars['String']['output'];
  /** Whether this record is available to staff, or also to patients */
  visibility?: Maybe<VisibilityDefinition>;
  website?: Maybe<Scalars['String']['output']>;
};

/** The connection type for Invoice. */
export type InvoiceConnection = {
  __typename?: 'InvoiceConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<InvoiceEdge>>>;
  /** IDs of ALL results, regardless of pagination */
  ids: Array<Scalars['ID']['output']>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Invoice>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Returns total count of items in list */
  totalCount: Scalars['Int']['output'];
};

export type InvoiceCreateInput = {
  /** Whether or not to automatically update stock */
  adjustStock?: InputMaybe<Scalars['Boolean']['input']>;
  /** Whether the invoice should be issued and delivered or created as a draft */
  deliver?: InputMaybe<Scalars['Boolean']['input']>;
  /** UPC ID of the Patient to create the invoice for */
  patient: Scalars['ID']['input'];
  /** Patient Record Items to create invoice from */
  patientRecordItems?: InputMaybe<Array<Scalars['ID']['input']>>;
};

/** An edge in a connection. */
export type InvoiceEdge = {
  __typename?: 'InvoiceEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<Invoice>;
};

export enum InvoiceField {
  /** Sort by invoice creation time */
  CreatedAt = 'created_at',
  /** Sort by invoice ID */
  Id = 'id'
}

export enum InvoiceFilter {
  /** Patient Family Invoices */
  PatientFamilyInvoices = 'patient_family_invoices',
  /** Patient Included Invoices */
  PatientIncludedInvoices = 'patient_included_invoices',
  /** Patient Only Invoices */
  PatientOnlyInvoices = 'patient_only_invoices'
}

export type InvoiceItem = {
  __typename?: 'InvoiceItem';
  /** Balance outstanding */
  balance?: Maybe<Scalars['Float']['output']>;
  catalogueItem?: Maybe<CatalogueItem>;
  code?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  discardedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  /** Discount percentage */
  discount?: Maybe<Scalars['Float']['output']>;
  /** FDI code */
  fdi?: Maybe<Scalars['Int']['output']>;
  guid: Scalars['ID']['output'];
  /** The amount sent to the health fund to be claimed */
  healthFundAmount?: Maybe<Scalars['Float']['output']>;
  /** Additional health fund attributes */
  healthFundAttributes?: Maybe<HealthFundsClaimItemAttributes>;
  /** The total out of pocket that the patient now needs to pay */
  healthFundGap?: Maybe<Scalars['Float']['output']>;
  /** The healthfund gateway */
  healthFundGateway?: Maybe<HealthFundsGateway>;
  /** The most recent claim item attached to this record */
  healthFundItem?: Maybe<HealthFundsClaimItem>;
  /** The most recent claim item attached to this record */
  healthFundItemId?: Maybe<Scalars['ID']['output']>;
  /** Service modality */
  healthFundModality?: Maybe<CatalogueItemModality>;
  /** Service modality code */
  healthFundModalityCode?: Maybe<Scalars['String']['output']>;
  /** Name of the health fund that processed the transaction */
  healthFundName?: Maybe<Scalars['String']['output']>;
  /** Reference of the patient. Normally this is their reference on their health fund card. e.g 01, 02 etc */
  healthFundPatientRef?: Maybe<Scalars['String']['output']>;
  /** Reference of the provider ID used for this item. */
  healthFundProviderRef?: Maybe<Scalars['String']['output']>;
  /** The amount that the healthfund will be rebated */
  healthFundRebate?: Maybe<Scalars['Float']['output']>;
  /** Date/Time of the last request to the healthfund */
  healthFundRequestAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  /** The request type */
  healthFundRequestType?: Maybe<HealthFundsRequestType>;
  /** Healthfund response code */
  healthFundResponseCode?: Maybe<Scalars['String']['output']>;
  /** health fund response message */
  healthFundResponseMessage?: Maybe<Scalars['String']['output']>;
  /** The date for this item to be claimed */
  healthFundServiceDate?: Maybe<Scalars['ISO8601Date']['output']>;
  /** Reference of the service to be claimed. For dental this can be the FDI code of the tooth. */
  healthFundServiceRef?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  invoiceId?: Maybe<Scalars['ID']['output']>;
  invoiceItemTransactions?: Maybe<Array<InvoiceItemTransaction>>;
  invoiceTransaction?: Maybe<InvoiceTransaction>;
  metadata?: Maybe<InvoiceItemMetadata>;
  name?: Maybe<Scalars['String']['output']>;
  patient?: Maybe<UserPracticeLink>;
  /** ID of the patient */
  patientId?: Maybe<Scalars['ID']['output']>;
  patientRecordItem?: Maybe<PatientRecordItem>;
  /** Practitioner who is assigned to this item. This practitioner is the one who will be paid for this item. */
  practitioner?: Maybe<UserPracticeLink>;
  /** ID of the practitioner */
  practitionerId?: Maybe<Scalars['ID']['output']>;
  /** Base price per item ex tax */
  price?: Maybe<Scalars['Float']['output']>;
  /** Single price excluding tax */
  priceExTax?: Maybe<Scalars['Float']['output']>;
  /** Single price including tax */
  priceIncTax?: Maybe<Scalars['Float']['output']>;
  /**
   * Number of items
   * @deprecated We duplicate the InvoiceItem instead of adding a quantity field
   */
  quantity?: Maybe<Scalars['Int']['output']>;
  /** Surface code for the surface */
  surfaceCode?: Maybe<Scalars['String']['output']>;
  /** Tax percentage */
  tax?: Maybe<Scalars['Float']['output']>;
  /** Total price including tax */
  total?: Maybe<Scalars['Float']['output']>;
  /** Total discount applied */
  totalDiscount?: Maybe<Scalars['Float']['output']>;
  /** Total price excluding tax */
  totalExTax?: Maybe<Scalars['Float']['output']>;
  /** Total amount already paid */
  totalPaid?: Maybe<Scalars['Float']['output']>;
  /** Total tax applied */
  totalTax?: Maybe<Scalars['Float']['output']>;
  updatedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
};

/** The connection type for InvoiceItem. */
export type InvoiceItemConnection = {
  __typename?: 'InvoiceItemConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<InvoiceItemEdge>>>;
  /** IDs of ALL results, regardless of pagination */
  ids: Array<Scalars['ID']['output']>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<InvoiceItem>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Returns total count of items in list */
  totalCount: Scalars['Int']['output'];
};

export type InvoiceItemCreateInput = {
  /** ID of the catalogue ietm */
  catalogueItemId?: InputMaybe<Scalars['ID']['input']>;
  /** Id of the invoice */
  invoiceId?: InputMaybe<Scalars['ID']['input']>;
  /** Id of patient to attach record to. This is the UPC object */
  patientId?: InputMaybe<Scalars['ID']['input']>;
  /** Id of practitioner */
  practitionerId?: InputMaybe<Scalars['ID']['input']>;
  /** ID of the catalogue item price list */
  priceListId?: InputMaybe<Scalars['ID']['input']>;
};

export type InvoiceItemDetailedList = {
  __typename?: 'InvoiceItemDetailedList';
  code?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  discount?: Maybe<Scalars['Float']['output']>;
  displayCreatedAt?: Maybe<Scalars['String']['output']>;
  displayItemCreatedAt?: Maybe<Scalars['String']['output']>;
  displayReadyToInvoiceAt?: Maybe<Scalars['String']['output']>;
  displayTaxTotal?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  invoiceId?: Maybe<Scalars['ID']['output']>;
  invoiceNumber?: Maybe<Scalars['ID']['output']>;
  itemCode?: Maybe<Scalars['String']['output']>;
  itemName?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  patientGuid?: Maybe<Scalars['ID']['output']>;
  patientName?: Maybe<Scalars['String']['output']>;
  patientRecordId?: Maybe<Scalars['ID']['output']>;
  practiceId?: Maybe<Scalars['ID']['output']>;
  practiceProfessionalName?: Maybe<Scalars['String']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
  quantity?: Maybe<Scalars['Int']['output']>;
  tax?: Maybe<Scalars['Float']['output']>;
  total?: Maybe<Scalars['Float']['output']>;
};

/** An edge in a connection. */
export type InvoiceItemEdge = {
  __typename?: 'InvoiceItemEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<InvoiceItem>;
};

export type InvoiceItemMetadata = {
  __typename?: 'InvoiceItemMetadata';
  /** Medipass claim service date */
  medipassClaimActualBenefit?: Maybe<Scalars['Int']['output']>;
  /** Medipass claim amount */
  medipassClaimAmount?: Maybe<Scalars['Float']['output']>;
  /** Medipass claim display name */
  medipassClaimDisplayName?: Maybe<Scalars['String']['output']>;
  /** Medipass claim service date */
  medipassClaimExpectedBenefit?: Maybe<Scalars['Int']['output']>;
  /** Medipass claim service date */
  medipassClaimGapAmount?: Maybe<Scalars['Int']['output']>;
  /** Medipass claim ID */
  medipassClaimId?: Maybe<Scalars['String']['output']>;
  /** Medipass claim item ID */
  medipassClaimItemCode?: Maybe<Scalars['String']['output']>;
  /** Medipass claim item ID */
  medipassClaimItemId?: Maybe<Scalars['String']['output']>;
  /** Medipass claim quantity */
  medipassClaimQuantity?: Maybe<Scalars['Int']['output']>;
  /** Medipass claim rebate */
  medipassClaimRebate?: Maybe<Scalars['Float']['output']>;
  /** Medipass claim service date */
  medipassClaimServiceDate?: Maybe<Scalars['String']['output']>;
  /** Medipass claim item ID */
  medipassClaimStatus?: Maybe<Scalars['String']['output']>;
  /** How much was claimed? */
  tyroHealthPointClaimAmount?: Maybe<Scalars['Int']['output']>;
  tyroHealthPointDescription?: Maybe<Scalars['String']['output']>;
  /** How much gap is required to be paid? */
  tyroHealthPointGapAmount?: Maybe<Scalars['Int']['output']>;
  /** Patient ID as on card (2 digits) */
  tyroHealthPointPatientId?: Maybe<Scalars['String']['output']>;
  /** Providwer ID used to process the claim */
  tyroHealthPointProviderId?: Maybe<Scalars['String']['output']>;
  /** How much of that was approved? */
  tyroHealthPointRebateAmount?: Maybe<Scalars['Int']['output']>;
  /** Response code for this item from Tyro */
  tyroHealthPointResponseCode?: Maybe<Scalars['String']['output']>;
  /** Service code claimed under */
  tyroHealthPointServiceCode?: Maybe<Scalars['String']['output']>;
  /** Date of claim (yyyyMMddhhmmss) */
  tyroHealthPointServiceDate?: Maybe<Scalars['String']['output']>;
  /** Item service ref (max 3 characters) */
  tyroHealthPointServiceReference?: Maybe<Scalars['String']['output']>;
};

export type InvoiceItemSummaryList = {
  __typename?: 'InvoiceItemSummaryList';
  masterPrice?: Maybe<Scalars['Float']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  noOfItems?: Maybe<Scalars['Int']['output']>;
  tax?: Maybe<Scalars['Float']['output']>;
};

export type InvoiceItemTransaction = {
  __typename?: 'InvoiceItemTransaction';
  /** The amount of the transaction */
  amount?: Maybe<Scalars['Float']['output']>;
  /** The balance of the transaction */
  balance?: Maybe<Scalars['String']['output']>;
  /** The date and time the transaction was created */
  createdAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  /** The date of the transaction */
  date?: Maybe<Scalars['ISO8601Date']['output']>;
  /** The date and time the transaction was discarded */
  discardedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  id: Scalars['ID']['output'];
  /** The invoice this transaction is associated with */
  invoice?: Maybe<Invoice>;
  /** The ID of the invoice this transaction is associated with */
  invoiceId?: Maybe<Scalars['ID']['output']>;
  /** The invoice item this transaction is associated with */
  invoiceItem?: Maybe<InvoiceItem>;
  /** The ID of the invoice item this transaction is associated with */
  invoiceItemId?: Maybe<Scalars['ID']['output']>;
  /** The invoice transaction this transaction is associated with */
  invoiceTransaction?: Maybe<InvoiceTransaction>;
  /** The ID of the invoice transaction this transaction is associated with */
  invoiceTransactionId?: Maybe<Scalars['ID']['output']>;
  /** The note of the transaction */
  note?: Maybe<Scalars['String']['output']>;
  /** The practitioner is the one assigned to the invoice item and is the one getting paid */
  paidPractitioner?: Maybe<UserPracticeLink>;
  /** The payment transaction this transaction is associated with */
  paymentTransaction?: Maybe<PaymentTransaction>;
  /** The ID of the payment transaction this transaction is associated with */
  paymentTransactionId?: Maybe<Scalars['ID']['output']>;
  /**
   * The practitioner this transaction is associated with. This practitioner is the
   * one who created the invoice transaction but is not assigned the payment
   */
  practitioner?: Maybe<UserPracticeLink>;
  /** The ID of the practitioner this transaction is associated with */
  practitionerId?: Maybe<Scalars['ID']['output']>;
  /** The pretty transaction method */
  prettyTransactionMethod?: Maybe<Scalars['String']['output']>;
  /** The pretty transaction type */
  prettyTransactionType?: Maybe<Scalars['String']['output']>;
  /** The method of transaction */
  transactionMethod?: Maybe<Scalars['String']['output']>;
  /** The type of transaction */
  transactionType?: Maybe<TransactionType>;
  /** The date and time the transaction was last updated */
  updatedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
};

export type InvoiceItemUpdateInput = {
  /** Description of invoice item */
  description?: InputMaybe<Scalars['String']['input']>;
  /** Discount for invoice item */
  discount?: InputMaybe<Scalars['Float']['input']>;
  /** FDI */
  fdi?: InputMaybe<Scalars['Int']['input']>;
  /** Additional Healthfund attributes */
  healthFundAttributes?: InputMaybe<HealthFundsClaimItemAttributesInput>;
  /** Healthfund patient ref */
  healthFundPatientRef?: InputMaybe<Scalars['String']['input']>;
  /** Healthfund service date */
  healthFundServiceDate?: InputMaybe<Scalars['ISO8601Date']['input']>;
  /** Healthfund service ref */
  healthFundServiceRef?: InputMaybe<Scalars['String']['input']>;
  /** Practitioner for Patient Record Item */
  practitionerId?: InputMaybe<Scalars['ID']['input']>;
  /** Price for Invoice Item Excluding Tax */
  priceExTax?: InputMaybe<Scalars['Float']['input']>;
  /** Price for Invoice Item Including Tax */
  priceIncTax?: InputMaybe<Scalars['Float']['input']>;
  /** Quantity of items */
  quantity?: InputMaybe<Scalars['Int']['input']>;
  /** Surface code */
  surfaceCode?: InputMaybe<Scalars['String']['input']>;
  /** Discount $ */
  totalDiscount?: InputMaybe<Scalars['Float']['input']>;
};

export type InvoiceOrderInput = {
  direction?: InputMaybe<Direction>;
  field?: InputMaybe<InvoiceField>;
};

export type InvoicePaymentInput = {
  date?: InputMaybe<Scalars['ISO8601Date']['input']>;
  note?: InputMaybe<Scalars['String']['input']>;
  privateNote?: InputMaybe<Scalars['Boolean']['input']>;
  transactions: Array<TransactionInput>;
};

export type InvoiceSetting = {
  __typename?: 'InvoiceSetting';
  abn?: Maybe<Scalars['String']['output']>;
  /** Account GUID */
  accountGuid: Scalars['ID']['output'];
  businessName?: Maybe<Scalars['String']['output']>;
  contractIdentifier?: Maybe<ContractIdentifier>;
  createdAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  /** Is this the default invoice setting for the practice */
  default: Scalars['Boolean']['output'];
  defaultDueDateDaysFromNow?: Maybe<Scalars['Int']['output']>;
  discardedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  fullAddress?: Maybe<Scalars['String']['output']>;
  guid: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  invoiceFooter?: Maybe<Scalars['String']['output']>;
  logo?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  /** Practice GUID */
  practiceGuid: Scalars['ID']['output'];
  /** Practice ID */
  practiceId: Scalars['ID']['output'];
  updatedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  userPracticeConnections?: Maybe<Array<UserPracticeLink>>;
  website?: Maybe<Scalars['String']['output']>;
};

/** The connection type for InvoiceSetting. */
export type InvoiceSettingConnection = {
  __typename?: 'InvoiceSettingConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<InvoiceSettingEdge>>>;
  /** IDs of ALL results, regardless of pagination */
  ids: Array<Scalars['ID']['output']>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<InvoiceSetting>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Returns total count of items in list */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type InvoiceSettingEdge = {
  __typename?: 'InvoiceSettingEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<InvoiceSetting>;
};

export type InvoiceSettingsInput = {
  abn?: InputMaybe<Scalars['String']['input']>;
  /** Archive the invoice setting */
  archive?: InputMaybe<Scalars['Boolean']['input']>;
  businessName?: InputMaybe<Scalars['String']['input']>;
  contractIdentifier?: InputMaybe<Scalars['ID']['input']>;
  defaultDueDateDaysFromNow?: InputMaybe<Scalars['Int']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  fullAddress?: InputMaybe<Scalars['String']['input']>;
  invoiceFooter?: InputMaybe<Scalars['String']['input']>;
  invoiceSetting?: InputMaybe<Scalars['ID']['input']>;
  /** Base64 Logo to upload */
  logo?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  upcGuids?: InputMaybe<Array<Scalars['String']['input']>>;
  website?: InputMaybe<Scalars['String']['input']>;
};

export enum InvoiceState {
  Archived = 'archived',
  Defaulted = 'defaulted',
  Draft = 'draft',
  Issued = 'issued',
  Paid = 'paid'
}

export enum InvoiceStateEvent {
  Archive = 'archive',
  Default = 'default',
  Issue = 'issue',
  Pay = 'pay',
  ReIssue = 're_issue',
  Refund = 'refund'
}

export type InvoiceTransaction = {
  __typename?: 'InvoiceTransaction';
  amount?: Maybe<Scalars['Float']['output']>;
  /** Whether the surcharge was applied to the total amount. Only for Stripe */
  applySurcharge?: Maybe<Scalars['Boolean']['output']>;
  balance?: Maybe<Scalars['Float']['output']>;
  batchId?: Maybe<Scalars['String']['output']>;
  claimedItems?: Maybe<Array<InvoiceItem>>;
  createdAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  date?: Maybe<Scalars['ISO8601Date']['output']>;
  discardedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  guid: Scalars['ID']['output'];
  /** The health fund request linked to this transaction */
  healthFundsRequest?: Maybe<HealthFundsRequest>;
  /** The ID of the health fund request linked to this transaction */
  healthFundsRequestGuid?: Maybe<Scalars['ID']['output']>;
  id: Scalars['ID']['output'];
  invoice?: Maybe<Invoice>;
  /** The invoice this transaction belongs to */
  invoiceId?: Maybe<Scalars['ID']['output']>;
  invoiceItemTransactions?: Maybe<Array<InvoiceItemTransaction>>;
  metadata?: Maybe<InvoiceTransactionMetadata>;
  note?: Maybe<Scalars['String']['output']>;
  patient?: Maybe<UserPracticeLink>;
  /** The id of the patient that this transaction was made with */
  patientId?: Maybe<Scalars['ID']['output']>;
  /** The amount the patient paid including any surcharges */
  patientPaid?: Maybe<Scalars['Float']['output']>;
  paymentMethod?: Maybe<PaymentMethod>;
  /** The id of the payment method that this transaction was made with */
  paymentMethodId?: Maybe<Scalars['ID']['output']>;
  paymentProvider?: Maybe<PaymentProvider>;
  /** The id of the payment provider that this transaction was made with */
  paymentProviderId?: Maybe<Scalars['ID']['output']>;
  paymentTransaction?: Maybe<PaymentTransaction>;
  /** The payment transaction that this invoice transaction is associated with */
  paymentTransactionId?: Maybe<Scalars['ID']['output']>;
  /** The amount the practice received including any surcharges */
  practiceReceived?: Maybe<Scalars['Float']['output']>;
  practitioner?: Maybe<UserPracticeLink>;
  /** The ID of the practitioner that processed the payment */
  practitionerId?: Maybe<Scalars['ID']['output']>;
  prettyState?: Maybe<Scalars['String']['output']>;
  prettyTransactionMethod?: Maybe<Scalars['String']['output']>;
  prettyTransactionType?: Maybe<Scalars['String']['output']>;
  privateNote?: Maybe<Scalars['Boolean']['output']>;
  returnNote?: Maybe<Scalars['String']['output']>;
  returnProduct?: Maybe<Scalars['Boolean']['output']>;
  state?: Maybe<InvoiceTransactionState>;
  /** Surcharge in cents. Only for Stripe */
  surcharge?: Maybe<Scalars['Int']['output']>;
  transactionMethod?: Maybe<Scalars['String']['output']>;
  transactionType?: Maybe<TransactionType>;
  transactionable?: Maybe<AnyNode>;
  /** The ID of the transactionable object */
  transactionableId?: Maybe<Scalars['ID']['output']>;
  /** The class name of the transactionable object */
  transactionableType?: Maybe<Scalars['String']['output']>;
};

export type InvoiceTransactionClaimInput = {
  /** Links this transaction to an invoice item and assigns metadata to that item */
  invoiceItemId: Scalars['ID']['input'];
  /** How much was claimed? */
  tyroHealthPointClaimAmount?: InputMaybe<Scalars['Float']['input']>;
  tyroHealthPointDescription?: InputMaybe<Scalars['String']['input']>;
  /** Patient ID as on card (2 digits) */
  tyroHealthPointPatientId?: InputMaybe<Scalars['String']['input']>;
  /** How much of that was approved? */
  tyroHealthPointRebateAmount?: InputMaybe<Scalars['Float']['input']>;
  /** Response code for this item from Tyro */
  tyroHealthPointResponseCode?: InputMaybe<Scalars['String']['input']>;
  /** Service code claimed under */
  tyroHealthPointServiceCode?: InputMaybe<Scalars['String']['input']>;
  /** Date of claim (yyyyMMddhhmmss) */
  tyroHealthPointServiceDate?: InputMaybe<Scalars['String']['input']>;
  /** Item service ref (max 3 characters) */
  tyroHealthPointServiceReference?: InputMaybe<Scalars['String']['input']>;
};

/** The connection type for InvoiceTransaction. */
export type InvoiceTransactionConnection = {
  __typename?: 'InvoiceTransactionConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<InvoiceTransactionEdge>>>;
  /** IDs of ALL results, regardless of pagination */
  ids: Array<Scalars['ID']['output']>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<InvoiceTransaction>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Returns total count of items in list */
  totalCount: Scalars['Int']['output'];
};

export type InvoiceTransactionData = {
  __typename?: 'InvoiceTransactionData';
  amount?: Maybe<Scalars['Float']['output']>;
  noOfTransactions?: Maybe<Scalars['Int']['output']>;
  transactionMethod?: Maybe<Scalars['String']['output']>;
};

export type InvoiceTransactionDetailedList = {
  __typename?: 'InvoiceTransactionDetailedList';
  amount?: Maybe<Scalars['Float']['output']>;
  balance?: Maybe<Scalars['Float']['output']>;
  createdAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  createdAtLabel?: Maybe<Scalars['String']['output']>;
  displayAmount?: Maybe<Scalars['Float']['output']>;
  displayTaxTotal?: Maybe<Scalars['Float']['output']>;
  fullName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  invoiceId?: Maybe<Scalars['ID']['output']>;
  invoiceNumber?: Maybe<Scalars['ID']['output']>;
  note?: Maybe<Scalars['String']['output']>;
  patientGuid?: Maybe<Scalars['ID']['output']>;
  patientRecordId?: Maybe<Scalars['ID']['output']>;
  practiceId?: Maybe<Scalars['ID']['output']>;
  practiceName?: Maybe<Scalars['String']['output']>;
  practiceProfessionalId?: Maybe<Scalars['ID']['output']>;
  privateNote?: Maybe<Scalars['String']['output']>;
  returnNote?: Maybe<Scalars['String']['output']>;
  state?: Maybe<Scalars['String']['output']>;
  stateLabel?: Maybe<Scalars['String']['output']>;
  transactionMethod?: Maybe<Scalars['String']['output']>;
  transactionMethodLabel?: Maybe<Scalars['String']['output']>;
  transactionType?: Maybe<Scalars['String']['output']>;
  transactionTypeLabel?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
};

/** An edge in a connection. */
export type InvoiceTransactionEdge = {
  __typename?: 'InvoiceTransactionEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<InvoiceTransaction>;
};

export type InvoiceTransactionMetadata = {
  __typename?: 'InvoiceTransactionMetadata';
  /** Stripe Payment Intent ID */
  stripePaymentIntentId?: Maybe<Scalars['String']['output']>;
  /** Stripe response */
  stripeResponse?: Maybe<InvoiceTransactionStripeResponse>;
  /** Merchant receipt returned from Tyro */
  tyroCustomerReceipt?: Maybe<Scalars['String']['output']>;
  /** Healthpoint total gap amount */
  tyroHealthPointGapAmount?: Maybe<Scalars['String']['output']>;
  /** Healthpoint Transaction Reference */
  tyroHealthPointReference?: Maybe<Scalars['String']['output']>;
  /** Healthpoint total benefit amount */
  tyroHealthPointTotalBenefitAmount?: Maybe<Scalars['String']['output']>;
  /** Merchant ID for health funds */
  tyroMerchantId?: Maybe<Scalars['String']['output']>;
  /** Merchant receipt returned from Tyro */
  tyroMerchantReceipt?: Maybe<Scalars['String']['output']>;
  /** Provider ID for health funds */
  tyroProviderId?: Maybe<Scalars['String']['output']>;
  /** Raw JSON from Tyro */
  tyroRawResponse?: Maybe<Scalars['String']['output']>;
  /** Tyro transaction reference */
  tyroReference?: Maybe<Scalars['String']['output']>;
  /** Tyro response */
  tyroResponse?: Maybe<InvoiceTransactionTyroResponse>;
  /** Tyro RRN reference */
  tyroRrn?: Maybe<Scalars['String']['output']>;
  /** Tyro Transaction Status */
  tyroStatus?: Maybe<Scalars['String']['output']>;
  /** terminal ID for health funds */
  tyroTerminalId?: Maybe<Scalars['String']['output']>;
  /** Tyro Transaction Id */
  tyroTransactionId?: Maybe<Scalars['String']['output']>;
};

export type InvoiceTransactionMetadataInput = {
  /** Stripe Payment Intent ID */
  stripePaymentIntentId?: InputMaybe<Scalars['String']['input']>;
  /** Raw response from Stripe as string */
  stripeRawResponse?: InputMaybe<Scalars['String']['input']>;
  /** Merchant receipt returned from Tyro */
  tyroCustomerReceipt?: InputMaybe<Scalars['String']['input']>;
  /** Healthpoint gap amount */
  tyroHealthPointGapAmount?: InputMaybe<Scalars['String']['input']>;
  /** Healthpoint transaction reference */
  tyroHealthPointReference?: InputMaybe<Scalars['String']['input']>;
  /** Healthpoint benefit amount */
  tyroHealthPointTotalBenefitAmount?: InputMaybe<Scalars['String']['input']>;
  /** Tyro merchant ID used */
  tyroMerchantId?: InputMaybe<Scalars['String']['input']>;
  /** Merchant receipt returned from Tyro */
  tyroMerchantReceipt?: InputMaybe<Scalars['String']['input']>;
  /** Tyro provider ID used */
  tyroProviderId?: InputMaybe<Scalars['String']['input']>;
  /** Raw response from Tyro as string */
  tyroRawResponse?: InputMaybe<Scalars['String']['input']>;
  /** Tyro transaction reference */
  tyroReference?: InputMaybe<Scalars['String']['input']>;
  /** Tyro RRN reference */
  tyroRrn?: InputMaybe<Scalars['String']['input']>;
  /** Tyro Transaction Status */
  tyroStatus?: InputMaybe<Scalars['String']['input']>;
  /** Tyro terminal ID used */
  tyroTerminalId?: InputMaybe<Scalars['String']['input']>;
  /** Tyro Transaction Id */
  tyroTransactionId?: InputMaybe<Scalars['String']['input']>;
};

export enum InvoiceTransactionState {
  Completed = 'completed',
  Failed = 'failed',
  Pending = 'pending',
  Processing = 'processing',
  Refunded = 'refunded',
  Removed = 'removed'
}

export type InvoiceTransactionStripeResponse = {
  __typename?: 'InvoiceTransactionStripeResponse';
  /** Value of transaction */
  amount?: Maybe<Scalars['Int']['output']>;
  /** Value of transaction */
  amountCapturable?: Maybe<Scalars['Int']['output']>;
  /** Value of transaction */
  amountReceived?: Maybe<Scalars['Int']['output']>;
  /** Value of transaction */
  application?: Maybe<Scalars['String']['output']>;
  /** Value of transaction */
  applicationFeeAmount?: Maybe<Scalars['Int']['output']>;
  /** Value of transaction */
  canceledAt?: Maybe<Scalars['Int']['output']>;
  /** Value of transaction */
  cancellationReason?: Maybe<Scalars['String']['output']>;
  /** Value of transaction */
  captureMethod?: Maybe<Scalars['String']['output']>;
  clientSecret?: Maybe<Scalars['String']['output']>;
  confirmationMethod?: Maybe<Scalars['String']['output']>;
  created?: Maybe<Scalars['String']['output']>;
  currency?: Maybe<Scalars['String']['output']>;
  customer?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  /** ID form Stripe transaction */
  id?: Maybe<Scalars['ID']['output']>;
  lastPaymentError?: Maybe<Scalars['String']['output']>;
  latestCharge?: Maybe<Scalars['String']['output']>;
  livemode?: Maybe<Scalars['String']['output']>;
  metadata?: Maybe<Scalars['String']['output']>;
  /** Type of transaction */
  object?: Maybe<Scalars['String']['output']>;
  paymentIntent?: Maybe<PaymentIntent>;
  paymentMethod?: Maybe<Scalars['String']['output']>;
  paymentMethodOptions?: Maybe<Scalars['String']['output']>;
  paymentMethodTypes?: Maybe<Scalars['String']['output']>;
  processing?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  transferData?: Maybe<Scalars['String']['output']>;
  transferGroup?: Maybe<Scalars['String']['output']>;
};

export type InvoiceTransactionSummary = {
  __typename?: 'InvoiceTransactionSummary';
  invoiceTransactions?: Maybe<Array<InvoiceTransactionData>>;
  pieChart?: Maybe<Scalars['JSON']['output']>;
};

export type InvoiceTransactionTyroEftposResponse = {
  __typename?: 'InvoiceTransactionTyroEftposResponse';
  /** Authorisation Code */
  authorisationCode?: Maybe<Scalars['String']['output']>;
  /** Base Amount */
  baseAmount?: Maybe<Scalars['Float']['output']>;
  /** Card Type */
  cardType?: Maybe<Scalars['String']['output']>;
  /** Elided Pan (Card Number) */
  elidedPan?: Maybe<Scalars['String']['output']>;
  /** Issuer Action Code */
  issuerActionCode?: Maybe<Scalars['String']['output']>;
  /** Result form Tyro transaction */
  result?: Maybe<Scalars['String']['output']>;
  /** RRN */
  rrn?: Maybe<Scalars['String']['output']>;
  /** Transaction Amount */
  transactionAmount?: Maybe<Scalars['Float']['output']>;
  /** Transaction Id */
  transactionId?: Maybe<Scalars['String']['output']>;
  /** Transaction Reference */
  transactionReference?: Maybe<Scalars['String']['output']>;
};

export type InvoiceTransactionTyroHealthpointClaimItem = {
  __typename?: 'InvoiceTransactionTyroHealthpointClaimItem';
  /** Claim Amount */
  claimAmount?: Maybe<Scalars['Int']['output']>;
  /** Description */
  description?: Maybe<Scalars['String']['output']>;
  /** Invoice Item */
  invoiceItem?: Maybe<InvoiceItem>;
  /** Patient Id */
  patientId?: Maybe<Scalars['String']['output']>;
  /** Rebate Amount */
  rebateAmount?: Maybe<Scalars['Int']['output']>;
  /** Response Code */
  responseCode?: Maybe<Scalars['String']['output']>;
  /** Service Code */
  serviceCode?: Maybe<Scalars['String']['output']>;
  /** Service Date */
  serviceDate?: Maybe<Scalars['ISO8601Date']['output']>;
  /** Service Reference */
  serviceReference?: Maybe<Scalars['String']['output']>;
};

export type InvoiceTransactionTyroHealthpointResponse = {
  __typename?: 'InvoiceTransactionTyroHealthpointResponse';
  /** Array of Healthpoint Claim Items */
  healthpointClaimItems?: Maybe<Array<InvoiceTransactionTyroHealthpointClaimItem>>;
  /** Healthpoint Gap Amount */
  healthpointGapAmount?: Maybe<Scalars['Int']['output']>;
  /** Healthpoint Health Fund Identifying Digits */
  healthpointHealthFundIdentifyingDigits?: Maybe<Scalars['String']['output']>;
  /** Healthpoint Health Fund Name */
  healthpointHealthFundName?: Maybe<Scalars['String']['output']>;
  /** Healthpoint Member Number */
  healthpointMemberNumber?: Maybe<Scalars['String']['output']>;
  /** Healthpoint PHF Response Code */
  healthpointPhfResponseCode?: Maybe<Scalars['String']['output']>;
  /** Healthpoint PHF Response Code Description */
  healthpointPhfResponseCodeDescription?: Maybe<Scalars['String']['output']>;
  /** Healthpoint Provider Id */
  healthpointProviderId?: Maybe<Scalars['String']['output']>;
  /** Healthpoint Reference Tag */
  healthpointRefTag?: Maybe<Scalars['String']['output']>;
  /** Healthpoint Service Type */
  healthpointServiceType?: Maybe<Scalars['String']['output']>;
  /** Healthpoint Settlement Date Time */
  healthpointSettlementDateTime?: Maybe<Scalars['ISO8601DateTime']['output']>;
  /** Healthpoint Terminal Date Time */
  healthpointTerminalDateTime?: Maybe<Scalars['ISO8601DateTime']['output']>;
  /** Healthpoint Total Benefit Amount */
  healthpointTotalBenefitAmount?: Maybe<Scalars['Int']['output']>;
  /** Result form Tyro transaction */
  result?: Maybe<Scalars['String']['output']>;
  /** Transaction Id */
  transactionId?: Maybe<Scalars['String']['output']>;
};

/** Tyro response type */
export type InvoiceTransactionTyroResponse = InvoiceTransactionTyroEftposResponse | InvoiceTransactionTyroHealthpointResponse;

export type InvoiceTransactionUpdateInput = {
  /** The amount you want to update transaction */
  amount?: InputMaybe<Scalars['Float']['input']>;
  /** Manually set the state of the invoice transaction */
  state?: InputMaybe<InvoiceTransactionState>;
};

export type InvoiceTransactionsSummaryList = {
  __typename?: 'InvoiceTransactionsSummaryList';
  amount?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  tax?: Maybe<Scalars['Float']['output']>;
};

/** Autogenerated return type of InvoiceTransactionsUpdated. */
export type InvoiceTransactionsUpdatedPayload = {
  __typename?: 'InvoiceTransactionsUpdatedPayload';
  invoice?: Maybe<Invoice>;
};

export type InvoiceUpdateInput = {
  /** business ABN for invoice */
  abn?: InputMaybe<Scalars['String']['input']>;
  /** bill to name */
  billTo?: InputMaybe<Scalars['String']['input']>;
  /** Business name for invoice */
  businessName?: InputMaybe<Scalars['String']['input']>;
  /** Fixed discount amount */
  discountAmount?: InputMaybe<Scalars['Float']['input']>;
  /** due date for invoice */
  dueDate?: InputMaybe<Scalars['ISO8601Date']['input']>;
  /** full address for invoice */
  fullAddress?: InputMaybe<Scalars['String']['input']>;
  /** footer content for invoice mailer */
  invoiceFooter?: InputMaybe<Scalars['String']['input']>;
  /** Changing the invoice setting of an invoice */
  invoiceSettingId?: InputMaybe<Scalars['ID']['input']>;
  /** Invoiced issued date/time */
  issuedAt?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
  /** Practice Location to update */
  practiceLocation?: InputMaybe<Scalars['ID']['input']>;
  /** contract identifier provider_id */
  providerId?: InputMaybe<Scalars['String']['input']>;
  /** practice professional name */
  providerName?: InputMaybe<Scalars['String']['input']>;
  /** Defines who can access this invoice */
  visibility?: InputMaybe<Visibility>;
};

/** Autogenerated return type of InvoiceUpdated. */
export type InvoiceUpdatedPayload = {
  __typename?: 'InvoiceUpdatedPayload';
  invoice?: Maybe<Invoice>;
};

export type ItemSortIndex = {
  __typename?: 'ItemSortIndex';
  /** The ID of the item */
  id?: Maybe<Scalars['ID']['output']>;
  /** Preferred index of the item */
  index?: Maybe<Scalars['Int']['output']>;
};

export type ItemSortIndexInput = {
  /** The ID of the item */
  id: Scalars['ID']['input'];
  /** Preferred index of the item */
  index: Scalars['Int']['input'];
};

export type Job = {
  __typename?: 'Job';
  account?: Maybe<Account>;
  /** ID of the account */
  accountId?: Maybe<Scalars['ID']['output']>;
  /** Arguments of the job */
  args?: Maybe<Array<Scalars['String']['output']>>;
  /** Cancelled at of the job */
  cancelledAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  /** Completed at of the job */
  completedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  /** Created at of the job */
  createdAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  /** GUID of the sidekiq job id */
  guid?: Maybe<Scalars['ID']['output']>;
  /** ID of the sidekiq job id */
  id?: Maybe<Scalars['ID']['output']>;
  /** ID of the job */
  jobId?: Maybe<Scalars['ID']['output']>;
  /** Is the job in redis? */
  jobInRedis?: Maybe<Scalars['Boolean']['output']>;
  /** Status of the job */
  jobStatus?: Maybe<Scalars['String']['output']>;
  /** Type of the job */
  jobType?: Maybe<Scalars['String']['output']>;
  /** Last error of the job */
  lastError?: Maybe<Scalars['String']['output']>;
  /** Last error at of the job */
  lastErrorAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  /** Perform at of the job */
  performAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  /** Queue of the job */
  queue?: Maybe<Scalars['String']['output']>;
  /** Raw job data */
  rawJobData?: Maybe<Scalars['JSON']['output']>;
  /** State of the job */
  state?: Maybe<SidekiqJobState>;
  /** The last time sidekiq updated us about this job */
  updateTime?: Maybe<Scalars['ISO8601DateTime']['output']>;
  /** Updated at of the job */
  updatedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  /** ID of the workable */
  workableId?: Maybe<Scalars['ID']['output']>;
  /** Type of the workable */
  workableType?: Maybe<Scalars['String']['output']>;
  /** Worker of the job */
  worker?: Maybe<Scalars['String']['output']>;
};

/** The connection type for Job. */
export type JobConnection = {
  __typename?: 'JobConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<JobEdge>>>;
  /** IDs of ALL results, regardless of pagination */
  ids: Array<Scalars['ID']['output']>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Job>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Returns total count of items in list */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type JobEdge = {
  __typename?: 'JobEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<Job>;
};

export type LabWork = {
  __typename?: 'LabWork';
  /** The appointment linked to the lab work */
  appointment?: Maybe<Appointment>;
  /** ID of the appointment */
  appointmentId?: Maybe<Scalars['ID']['output']>;
  /** The contact link to the lab work */
  contact?: Maybe<UserPracticeLink>;
  /** The contacts full name */
  contactFullName?: Maybe<Scalars['String']['output']>;
  /** ID of the contact */
  contactId?: Maybe<Scalars['ID']['output']>;
  /** Cost of the lab work */
  cost?: Maybe<Scalars['Float']['output']>;
  /** Created at */
  createdAt: Scalars['ISO8601DateTime']['output'];
  /** Discarded at */
  discardedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  /** Due at (if no appointment is provided) */
  dueAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  /** ID of the lab work */
  id: Scalars['ID']['output'];
  /** Notes */
  notes?: Maybe<Scalars['String']['output']>;
  /** The patients name */
  patientFullName?: Maybe<Scalars['String']['output']>;
  /** State of the lab work */
  prettyState: Scalars['String']['output'];
  /** The staff member that requested the lab work */
  requestedBy?: Maybe<UserPracticeLink>;
  /** ID of the user practice connection */
  requestedById: Scalars['ID']['output'];
  /** The requesters name */
  requesterFullName?: Maybe<Scalars['String']['output']>;
  /** The requested users profile */
  requesterProfile?: Maybe<UserPracticeProfile>;
  /** State of the lab work */
  state: LabWorkState;
  /** Updated at */
  updatedAt: Scalars['ISO8601DateTime']['output'];
  /** The patient linked to the lab work */
  userPracticeConnection?: Maybe<UserPracticeLink>;
  /** ID of the user practice connection */
  userPracticeConnectionId: Scalars['ID']['output'];
  /** The patients profile */
  userProfile?: Maybe<UserPracticeProfile>;
};

/** The connection type for LabWork. */
export type LabWorkConnection = {
  __typename?: 'LabWorkConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<LabWorkEdge>>>;
  /** IDs of ALL results, regardless of pagination */
  ids: Array<Scalars['ID']['output']>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<LabWork>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Returns total count of items in list */
  totalCount: Scalars['Int']['output'];
};

export type LabWorkCreateInput = {
  /** Appointment ID */
  appointmentId?: InputMaybe<Scalars['ID']['input']>;
  /** Contact details */
  contactDetails?: InputMaybe<Scalars['String']['input']>;
  /** Contact ID */
  contactId?: InputMaybe<Scalars['ID']['input']>;
  /** Cost of lab work */
  cost?: InputMaybe<Scalars['Float']['input']>;
  /** The due date for the lab work */
  dueAt?: InputMaybe<Scalars['ISO8601Date']['input']>;
  /** Notes about the lab work */
  notes?: InputMaybe<Scalars['String']['input']>;
  /** The user who requested the lab work */
  requestedById?: InputMaybe<Scalars['ID']['input']>;
  /** The user practice connection id */
  userPracticeConnectionId: Scalars['ID']['input'];
};

/** An edge in a connection. */
export type LabWorkEdge = {
  __typename?: 'LabWorkEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<LabWork>;
};

export enum LabWorkState {
  Received = 'received',
  Reception = 'reception',
  Sent = 'sent'
}

export enum LabWorkStateEvent {
  MarkAsReceived = 'mark_as_received',
  MarkAsSent = 'mark_as_sent',
  MarkAtReception = 'mark_at_reception'
}

export type LabWorkUpdateInput = {
  /** Appointment ID */
  appointmentId?: InputMaybe<Scalars['ID']['input']>;
  /** Contact details */
  contactDetails?: InputMaybe<Scalars['String']['input']>;
  /** Contact ID */
  contactId?: InputMaybe<Scalars['ID']['input']>;
  /** Cost of lab work */
  cost?: InputMaybe<Scalars['Float']['input']>;
  /** The due date for the lab work */
  dueAt?: InputMaybe<Scalars['ISO8601Date']['input']>;
  /** Notes about the lab work */
  notes?: InputMaybe<Scalars['String']['input']>;
  /** The user who requested the lab work */
  requestedById?: InputMaybe<Scalars['ID']['input']>;
  /** The user practice connection id */
  userPracticeConnectionId?: InputMaybe<Scalars['ID']['input']>;
};

export type LabelConfiguration = {
  __typename?: 'LabelConfiguration';
  /** What this practice calls an appointment */
  appointment?: Maybe<Scalars['String']['output']>;
  /** What this practice calls the calendar */
  calendar?: Maybe<Scalars['String']['output']>;
  /** What this practice calls a client */
  client?: Maybe<Scalars['String']['output']>;
  /** What this practice calls the manager */
  manager?: Maybe<Scalars['String']['output']>;
  /** What this practice calls a practitioner, e.g a dentist or a GP */
  practitioner?: Maybe<Scalars['String']['output']>;
  /** What this practice calls a recall */
  recall?: Maybe<Scalars['String']['output']>;
  /** What this practice calls a staff member */
  staff?: Maybe<Scalars['String']['output']>;
  /** What this practice calls a treatment plan */
  treatmentPlan?: Maybe<Scalars['String']['output']>;
};

export type LabelConfigurationInput = {
  /** What this practice calls an appointment */
  appointment?: InputMaybe<Scalars['String']['input']>;
  /** What this practice calls the calendar */
  calendar?: InputMaybe<Scalars['String']['input']>;
  /** What this practice calls a client */
  client?: InputMaybe<Scalars['String']['input']>;
  /** What this practice calls the manager */
  manager?: InputMaybe<Scalars['String']['input']>;
  /** What this practice calls a practitioner, e.g a dentist or a GP */
  practitioner?: InputMaybe<Scalars['String']['input']>;
  /** What this practice calls a recall */
  recall?: InputMaybe<Scalars['String']['input']>;
  /** What this practice calls a staff member */
  staff?: InputMaybe<Scalars['String']['input']>;
  /** What this practice calls a treatment plan */
  treatmentPlan?: InputMaybe<Scalars['String']['input']>;
};

export type Letter = {
  __typename?: 'Letter';
  content?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  guid: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  letterableId?: Maybe<Scalars['ID']['output']>;
  letterableType?: Maybe<Scalars['Int']['output']>;
  messageable?: Maybe<AnyNode>;
  messageableId?: Maybe<Scalars['ID']['output']>;
  messageableType?: Maybe<Scalars['String']['output']>;
  receiveableId?: Maybe<Scalars['ID']['output']>;
  receiveableType?: Maybe<Scalars['Int']['output']>;
  /** The recipient of the message */
  recipient?: Maybe<Participant>;
  sendAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  sendableId?: Maybe<Scalars['ID']['output']>;
  sendableType?: Maybe<Scalars['Int']['output']>;
  /** The sender of the message */
  sender?: Maybe<Participant>;
  state?: Maybe<LetterState>;
  subject?: Maybe<Scalars['String']['output']>;
  toAddress?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
};

/** The connection type for Letter. */
export type LetterConnection = {
  __typename?: 'LetterConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<LetterEdge>>>;
  /** IDs of ALL results, regardless of pagination */
  ids: Array<Scalars['ID']['output']>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Letter>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Returns total count of items in list */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type LetterEdge = {
  __typename?: 'LetterEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<Letter>;
};

export type LetterInput = {
  /** Content of the letter */
  body: Scalars['String']['input'];
  /** The ID of the letter to update, if the letter is a draft */
  guid?: InputMaybe<Scalars['ID']['input']>;
  /** Subject of the letter */
  subject: Scalars['String']['input'];
};

export enum LetterState {
  Draft = 'draft',
  Printed = 'printed'
}

/** A Liquid Drop class that can be used to render a template */
export type LiquidDrop = {
  __typename?: 'LiquidDrop';
  /** Accepted records for instantiating this drop and render its variables */
  accepts?: Maybe<Array<LiquidRecord>>;
  /** Description of what the purpose of this drop is */
  description?: Maybe<Scalars['String']['output']>;
  /** Display name of this drop */
  name: Scalars['String']['output'];
  /** The type of the Liquid Drop class */
  type: LiquidDropType;
  /** Variables available to this Liquid Drop class */
  variables?: Maybe<Array<TemplateVariable>>;
};

export type LiquidDropField = FormFieldInterface & {
  __typename?: 'LiquidDropField';
  cols?: Maybe<Scalars['Int']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  drop?: Maybe<LiquidDropType>;
  /**
   * This should be filtered out and not stored in the database
   * but kept on the type to maintain integrity of the type definitions
   * as error is still a valid property on any form field
   */
  error?: Maybe<Scalars['String']['output']>;
  fieldType: FieldType;
  id?: Maybe<Scalars['String']['output']>;
  key: Scalars['String']['output'];
  name: Scalars['String']['output'];
  offset?: Maybe<Scalars['Int']['output']>;
  /**
   * If this field is defined, this field is linked to the specified column on the
   * Patient model and will update that field when the form updates
   */
  patientColumn?: Maybe<PatientFieldLink>;
  placeholder?: Maybe<Scalars['String']['output']>;
  /** Order of the field in the section */
  position?: Maybe<Scalars['Int']['output']>;
  required?: Maybe<Scalars['Boolean']['output']>;
  sectionId?: Maybe<Scalars['ID']['output']>;
};

export type LiquidDropFieldInput = {
  fieldType?: InputMaybe<Scalars['String']['input']>;
  key: Scalars['String']['input'];
  name: Scalars['String']['input'];
  value: LiquidDropType;
};

export type LiquidDropPrefillInput = {
  /** Liquid Drop to prefill with */
  dropType: LiquidDropType;
  uuid?: InputMaybe<Scalars['ID']['input']>;
  /** Name of the variable to prefill with this object. If none is given, the drop will be spread on the top level */
  variableName?: InputMaybe<Scalars['String']['input']>;
};

export enum LiquidDropType {
  Advice = 'Advice',
  AppointmentsAppointmentGuest = 'AppointmentsAppointmentGuest',
  AppointmentsGroup = 'AppointmentsGroup',
  AppointmentsOnlineMeeting = 'AppointmentsOnlineMeeting',
  AppointmentsRecall = 'AppointmentsRecall',
  AppointmentsSingle = 'AppointmentsSingle',
  CatalogueItemsProduct = 'CatalogueItemsProduct',
  CatalogueItemsService = 'CatalogueItemsService',
  CommunicationsAttachment = 'CommunicationsAttachment',
  CommunicationsForm = 'CommunicationsForm',
  CommunicationsGeneral = 'CommunicationsGeneral',
  CommunicationsMessage = 'CommunicationsMessage',
  CommunicationsReminder = 'CommunicationsReminder',
  CustomerRequestsCustomerRequest = 'CustomerRequestsCustomerRequest',
  DentalChart = 'DentalChart',
  DentalPerio = 'DentalPerio',
  FormattingToday = 'FormattingToday',
  HealthFundsContractIdentifier = 'HealthFundsContractIdentifier',
  InvoicesInvoice = 'InvoicesInvoice',
  NotesClinicalNote = 'NotesClinicalNote',
  NotesClinicalNoteTemplate = 'NotesClinicalNoteTemplate',
  NotesPatientNotes = 'NotesPatientNotes',
  Practice = 'Practice',
  PracticeLocation = 'PracticeLocation',
  PracticesInvite = 'PracticesInvite',
  Treatment = 'Treatment',
  TreatmentsTreatment = 'TreatmentsTreatment',
  TreatmentsTreatmentPlan = 'TreatmentsTreatmentPlan',
  UsersContact = 'UsersContact',
  UsersLegacyPatient = 'UsersLegacyPatient',
  UsersLegacyPatientRecord = 'UsersLegacyPatientRecord',
  UsersMobileApp = 'UsersMobileApp',
  UsersPatient = 'UsersPatient',
  UsersPractitioner = 'UsersPractitioner',
  UsersProfile = 'UsersProfile',
  UsersStatistics = 'UsersStatistics'
}

/**
 * All records supported by at least one Liquid Drop class.
 * This is used so that if we drop (he he) support for a record type,
 * it will be automatically removed from this list, and typechecking
 * will fail wherever this value is checked. This is not intended to
 * create a string out of every model, but rather to create a list of
 * currently supported records for template rendering
 */
export enum LiquidRecord {
  Advice = 'Advice',
  Appointment = 'Appointment',
  AppointmentGuest = 'AppointmentGuest',
  AppointmentsGroup = 'Appointments__Group',
  AppointmentsSingle = 'Appointments__Single',
  Attachment = 'Attachment',
  CatalogueItemsProduct = 'CatalogueItems__Product',
  CatalogueItemsService = 'CatalogueItems__Service',
  ClinicalNote = 'ClinicalNote',
  ClinicalNoteTemplate = 'ClinicalNoteTemplate',
  CommunicationsEmail = 'Communications__Email',
  CommunicationsMessage = 'Communications__Message',
  CommunicationsReminder = 'Communications__Reminder',
  CommunicationsShortMessage = 'Communications__ShortMessage',
  CustomerRequest = 'CustomerRequest',
  DentalPerioExam = 'Dental__PerioExam',
  Email = 'Email',
  Form = 'Form',
  HealthFundsContractIdentifier = 'HealthFunds__ContractIdentifier',
  Invite = 'Invite',
  Invoice = 'Invoice',
  NilClass = 'NilClass',
  OnlineMeeting = 'OnlineMeeting',
  Patient = 'Patient',
  PatientRecord = 'PatientRecord',
  Practice = 'Practice',
  PracticeLocation = 'PracticeLocation',
  Recall = 'Recall',
  Reminder = 'Reminder',
  ShortMessage = 'ShortMessage',
  Treatment = 'Treatment',
  TreatmentPlan = 'TreatmentPlan',
  UserPracticeConnection = 'UserPracticeConnection',
  UserPracticeProfile = 'UserPracticeProfile',
  UsersPatientApp = 'Users__PatientApp'
}

export type Localization = {
  __typename?: 'Localization';
  appointmentIcons?: Maybe<AppointmentIconConfiguration>;
  completedAt?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  currency?: Maybe<Scalars['String']['output']>;
  defaultLocale?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  labels?: Maybe<LabelConfiguration>;
  mobileRegionCode?: Maybe<Scalars['String']['output']>;
  mobileRegionShort?: Maybe<Scalars['String']['output']>;
  taxAmount?: Maybe<Scalars['String']['output']>;
  taxCode?: Maybe<Scalars['String']['output']>;
  timezone?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

export type Location = {
  __typename?: 'Location';
  addressLine1?: Maybe<Scalars['String']['output']>;
  addressLine2?: Maybe<Scalars['String']['output']>;
  addressLine3?: Maybe<Scalars['String']['output']>;
  addressLines?: Maybe<Array<Scalars['String']['output']>>;
  city?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  formattedAddress?: Maybe<Scalars['String']['output']>;
  guid?: Maybe<Scalars['ID']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  latitude?: Maybe<Scalars['Float']['output']>;
  longitude?: Maybe<Scalars['Float']['output']>;
  /** Google Places ID */
  placeId?: Maybe<Scalars['ID']['output']>;
  postcode?: Maybe<Scalars['String']['output']>;
  state?: Maybe<Scalars['String']['output']>;
};

export type LocationAddressable = {
  __typename?: 'LocationAddressable';
  addressLine1?: Maybe<Scalars['String']['output']>;
  addressLine2?: Maybe<Scalars['String']['output']>;
  addressLine3?: Maybe<Scalars['String']['output']>;
  addressLines?: Maybe<Array<Scalars['String']['output']>>;
  city?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  formattedAddress?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  latitude?: Maybe<Scalars['Float']['output']>;
  longitude?: Maybe<Scalars['Float']['output']>;
  /** Google Places ID */
  placeId?: Maybe<Scalars['ID']['output']>;
  postcode?: Maybe<Scalars['String']['output']>;
  state?: Maybe<Scalars['String']['output']>;
};

export enum LocationCountry {
  Afghanistan = 'Afghanistan',
  Albania = 'Albania',
  Algeria = 'Algeria',
  AmericanSamoa = 'American_Samoa',
  Andorra = 'Andorra',
  Angola = 'Angola',
  Anguilla = 'Anguilla',
  Antarctica = 'Antarctica',
  AntiguaAndBarbuda = 'Antigua_and_Barbuda',
  Argentina = 'Argentina',
  Armenia = 'Armenia',
  Aruba = 'Aruba',
  Australia = 'Australia',
  Austria = 'Austria',
  Azerbaijan = 'Azerbaijan',
  Bahamas = 'Bahamas',
  Bahrain = 'Bahrain',
  Bangladesh = 'Bangladesh',
  Barbados = 'Barbados',
  Belarus = 'Belarus',
  Belgium = 'Belgium',
  Belize = 'Belize',
  Benin = 'Benin',
  Bermuda = 'Bermuda',
  Bhutan = 'Bhutan',
  Bolivia = 'Bolivia',
  BonaireSintEustatiusAndSaba = 'Bonaire__Sint_Eustatius_and_Saba',
  BosniaAndHerzegovina = 'Bosnia_and_Herzegovina',
  Botswana = 'Botswana',
  BouvetIsland = 'Bouvet_Island',
  Brazil = 'Brazil',
  BritishIndianOceanTerritory = 'British_Indian_Ocean_Territory',
  BruneiDarussalam = 'Brunei_Darussalam',
  Bulgaria = 'Bulgaria',
  BurkinaFaso = 'Burkina_Faso',
  Burundi = 'Burundi',
  CTeDIvoire = 'C_te_d_Ivoire',
  CaboVerde = 'Cabo_Verde',
  Cambodia = 'Cambodia',
  Cameroon = 'Cameroon',
  Canada = 'Canada',
  CaymanIslands = 'Cayman_Islands',
  CentralAfricanRepublic = 'Central_African_Republic',
  Chad = 'Chad',
  Chile = 'Chile',
  China = 'China',
  ChristmasIsland = 'Christmas_Island',
  CocosKeelingIslands = 'Cocos__Keeling__Islands',
  Colombia = 'Colombia',
  Comoros = 'Comoros',
  Congo = 'Congo',
  CongoTheDemocraticRepublicOfThe = 'Congo__The_Democratic_Republic_of_the',
  CookIslands = 'Cook_Islands',
  CostaRica = 'Costa_Rica',
  Croatia = 'Croatia',
  Cuba = 'Cuba',
  CuraAo = 'Cura_ao',
  Cyprus = 'Cyprus',
  Czechia = 'Czechia',
  Denmark = 'Denmark',
  Djibouti = 'Djibouti',
  Dominica = 'Dominica',
  DominicanRepublic = 'Dominican_Republic',
  Ecuador = 'Ecuador',
  Egypt = 'Egypt',
  ElSalvador = 'El_Salvador',
  EquatorialGuinea = 'Equatorial_Guinea',
  Eritrea = 'Eritrea',
  Estonia = 'Estonia',
  Eswatini = 'Eswatini',
  Ethiopia = 'Ethiopia',
  FalklandIslandsMalvinas = 'Falkland_Islands__Malvinas_',
  FaroeIslands = 'Faroe_Islands',
  Fiji = 'Fiji',
  Finland = 'Finland',
  France = 'France',
  FrenchGuiana = 'French_Guiana',
  FrenchPolynesia = 'French_Polynesia',
  FrenchSouthernTerritories = 'French_Southern_Territories',
  Gabon = 'Gabon',
  Gambia = 'Gambia',
  Georgia = 'Georgia',
  Germany = 'Germany',
  Ghana = 'Ghana',
  Gibraltar = 'Gibraltar',
  Greece = 'Greece',
  Greenland = 'Greenland',
  Grenada = 'Grenada',
  Guadeloupe = 'Guadeloupe',
  Guam = 'Guam',
  Guatemala = 'Guatemala',
  Guernsey = 'Guernsey',
  Guinea = 'Guinea',
  GuineaBissau = 'Guinea_Bissau',
  Guyana = 'Guyana',
  Haiti = 'Haiti',
  HeardIslandAndMcDonaldIslands = 'Heard_Island_and_McDonald_Islands',
  HolySeeVaticanCityState = 'Holy_See__Vatican_City_State_',
  Honduras = 'Honduras',
  HongKong = 'Hong_Kong',
  Hungary = 'Hungary',
  Iceland = 'Iceland',
  India = 'India',
  Indonesia = 'Indonesia',
  Iran = 'Iran',
  Iraq = 'Iraq',
  Ireland = 'Ireland',
  IsleOfMan = 'Isle_of_Man',
  Israel = 'Israel',
  Italy = 'Italy',
  Jamaica = 'Jamaica',
  Japan = 'Japan',
  Jersey = 'Jersey',
  Jordan = 'Jordan',
  Kazakhstan = 'Kazakhstan',
  Kenya = 'Kenya',
  Kiribati = 'Kiribati',
  Kuwait = 'Kuwait',
  Kyrgyzstan = 'Kyrgyzstan',
  LaoPeopleSDemocraticRepublic = 'Lao_People_s_Democratic_Republic',
  Latvia = 'Latvia',
  Lebanon = 'Lebanon',
  Lesotho = 'Lesotho',
  Liberia = 'Liberia',
  Libya = 'Libya',
  Liechtenstein = 'Liechtenstein',
  Lithuania = 'Lithuania',
  Luxembourg = 'Luxembourg',
  Macao = 'Macao',
  Madagascar = 'Madagascar',
  Malawi = 'Malawi',
  Malaysia = 'Malaysia',
  Maldives = 'Maldives',
  Mali = 'Mali',
  Malta = 'Malta',
  MarshallIslands = 'Marshall_Islands',
  Martinique = 'Martinique',
  Mauritania = 'Mauritania',
  Mauritius = 'Mauritius',
  Mayotte = 'Mayotte',
  Mexico = 'Mexico',
  MicronesiaFederatedStatesOf = 'Micronesia__Federated_States_of',
  Moldova = 'Moldova',
  Monaco = 'Monaco',
  Mongolia = 'Mongolia',
  Montenegro = 'Montenegro',
  Montserrat = 'Montserrat',
  Morocco = 'Morocco',
  Mozambique = 'Mozambique',
  Myanmar = 'Myanmar',
  Namibia = 'Namibia',
  Nauru = 'Nauru',
  Nepal = 'Nepal',
  Netherlands = 'Netherlands',
  NewCaledonia = 'New_Caledonia',
  NewZealand = 'New_Zealand',
  Nicaragua = 'Nicaragua',
  Niger = 'Niger',
  Nigeria = 'Nigeria',
  Niue = 'Niue',
  NorfolkIsland = 'Norfolk_Island',
  NorthKorea = 'North_Korea',
  NorthMacedonia = 'North_Macedonia',
  NorthernMarianaIslands = 'Northern_Mariana_Islands',
  Norway = 'Norway',
  Oman = 'Oman',
  Pakistan = 'Pakistan',
  Palau = 'Palau',
  PalestineStateOf = 'Palestine__State_of',
  Panama = 'Panama',
  PapuaNewGuinea = 'Papua_New_Guinea',
  Paraguay = 'Paraguay',
  Peru = 'Peru',
  Philippines = 'Philippines',
  Pitcairn = 'Pitcairn',
  Poland = 'Poland',
  Portugal = 'Portugal',
  PuertoRico = 'Puerto_Rico',
  Qatar = 'Qatar',
  RUnion = 'R_union',
  Romania = 'Romania',
  RussianFederation = 'Russian_Federation',
  Rwanda = 'Rwanda',
  SaintBarthLemy = 'Saint_Barth_lemy',
  SaintHelenaAscensionAndTristanDaCunha = 'Saint_Helena__Ascension_and_Tristan_da_Cunha',
  SaintKittsAndNevis = 'Saint_Kitts_and_Nevis',
  SaintLucia = 'Saint_Lucia',
  SaintMartinFrenchPart = 'Saint_Martin__French_part_',
  SaintPierreAndMiquelon = 'Saint_Pierre_and_Miquelon',
  SaintVincentAndTheGrenadines = 'Saint_Vincent_and_the_Grenadines',
  Samoa = 'Samoa',
  SanMarino = 'San_Marino',
  SaoTomeAndPrincipe = 'Sao_Tome_and_Principe',
  SaudiArabia = 'Saudi_Arabia',
  Senegal = 'Senegal',
  Serbia = 'Serbia',
  Seychelles = 'Seychelles',
  SierraLeone = 'Sierra_Leone',
  Singapore = 'Singapore',
  SintMaartenDutchPart = 'Sint_Maarten__Dutch_part_',
  Slovakia = 'Slovakia',
  Slovenia = 'Slovenia',
  SolomonIslands = 'Solomon_Islands',
  Somalia = 'Somalia',
  SouthAfrica = 'South_Africa',
  SouthGeorgiaAndTheSouthSandwichIslands = 'South_Georgia_and_the_South_Sandwich_Islands',
  SouthKorea = 'South_Korea',
  SouthSudan = 'South_Sudan',
  Spain = 'Spain',
  SriLanka = 'Sri_Lanka',
  Sudan = 'Sudan',
  Suriname = 'Suriname',
  SvalbardAndJanMayen = 'Svalbard_and_Jan_Mayen',
  Sweden = 'Sweden',
  Switzerland = 'Switzerland',
  SyrianArabRepublic = 'Syrian_Arab_Republic',
  TRkiye = 'T_rkiye',
  Taiwan = 'Taiwan',
  Tajikistan = 'Tajikistan',
  Tanzania = 'Tanzania',
  Thailand = 'Thailand',
  TimorLeste = 'Timor_Leste',
  Togo = 'Togo',
  Tokelau = 'Tokelau',
  Tonga = 'Tonga',
  TrinidadAndTobago = 'Trinidad_and_Tobago',
  Tunisia = 'Tunisia',
  Turkmenistan = 'Turkmenistan',
  TurksAndCaicosIslands = 'Turks_and_Caicos_Islands',
  Tuvalu = 'Tuvalu',
  Uganda = 'Uganda',
  Ukraine = 'Ukraine',
  UnitedArabEmirates = 'United_Arab_Emirates',
  UnitedKingdom = 'United_Kingdom',
  UnitedStates = 'United_States',
  UnitedStatesMinorOutlyingIslands = 'United_States_Minor_Outlying_Islands',
  Uruguay = 'Uruguay',
  Uzbekistan = 'Uzbekistan',
  Vanuatu = 'Vanuatu',
  Venezuela = 'Venezuela',
  Vietnam = 'Vietnam',
  VirginIslandsBritish = 'Virgin_Islands__British',
  VirginIslandsUS = 'Virgin_Islands__U_S_',
  WallisAndFutuna = 'Wallis_and_Futuna',
  WesternSahara = 'Western_Sahara',
  Yemen = 'Yemen',
  Zambia = 'Zambia',
  Zimbabwe = 'Zimbabwe',
  LandIslands = '_land_Islands'
}

export type LocationInput = {
  addressLine1?: InputMaybe<Scalars['String']['input']>;
  addressLine2?: InputMaybe<Scalars['String']['input']>;
  addressLine3?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  formattedAddress?: InputMaybe<Scalars['String']['input']>;
  latitude?: InputMaybe<Scalars['Float']['input']>;
  longitude?: InputMaybe<Scalars['Float']['input']>;
  /** Link to a Google Place ID */
  placeId?: InputMaybe<Scalars['String']['input']>;
  postcode?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
};

export type LocationSearchResult = {
  __typename?: 'LocationSearchResult';
  formattedAddress?: Maybe<Scalars['String']['output']>;
  matchedString?: Maybe<Scalars['String']['output']>;
  matchedSubstrings?: Maybe<Array<Scalars['String']['output']>>;
  placeId: Scalars['ID']['output'];
  types?: Maybe<Array<Scalars['String']['output']>>;
};

export type LoginAttributes = {
  /** Log in with email or username */
  identifier: Scalars['String']['input'];
  otp?: InputMaybe<Scalars['String']['input']>;
  password: Scalars['String']['input'];
};

/** Autogenerated input type of Login */
export type LoginInput = {
  attributes: LoginAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of Login. */
export type LoginPayload = {
  __typename?: 'LoginPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  credentials?: Maybe<Credentials>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  fieldErrors?: Maybe<Array<FieldError>>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

/** Autogenerated input type of LoginWithRestriction */
export type LoginWithRestrictionInput = {
  attributes: AccessRestrictionAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of LoginWithRestriction. */
export type LoginWithRestrictionPayload = {
  __typename?: 'LoginWithRestrictionPayload';
  accessRestriction?: Maybe<AccessRestriction>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  fieldErrors?: Maybe<Array<FieldError>>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

/** Direct URLs for practice logos uploaded with FileUploader */
export type LogoUploaderAwsLinks = {
  __typename?: 'LogoUploaderAWSLinks';
  id?: Maybe<Scalars['ID']['output']>;
  /** AWS URL for the `medium` processed version of this file */
  medium?: Maybe<Scalars['String']['output']>;
  /** AWS URL for the `small` processed version of this file */
  small?: Maybe<Scalars['String']['output']>;
  /** Original URL of the attachment */
  url?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of Logout */
export type LogoutInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of Logout. */
export type LogoutPayload = {
  __typename?: 'LogoutPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  fieldErrors?: Maybe<Array<FieldError>>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of MailMerge */
export type MailMergeInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** SyncFusion Document */
  docx?: InputMaybe<Scalars['Blob']['input']>;
  /** UUID of the linked record to substitute variables with */
  uuid: Scalars['ID']['input'];
};

/** Autogenerated return type of MailMerge. */
export type MailMergePayload = {
  __typename?: 'MailMergePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** General errors, unrelated to fields */
  errors?: Maybe<Array<Scalars['String']['output']>>;
  /** Input field errors */
  fieldErrors?: Maybe<Array<FieldError>>;
  /** Whether or not this mutation was successful */
  success?: Maybe<Scalars['Boolean']['output']>;
  /** URL to new Docx file with merged variables */
  url?: Maybe<Scalars['String']['output']>;
};

export type MailSettings = {
  __typename?: 'MailSettings';
  address?: Maybe<Scalars['String']['output']>;
  authentication?: Maybe<MailSettingsAuthentication>;
  domain?: Maybe<Scalars['String']['output']>;
  fromEmailAddress?: Maybe<Scalars['String']['output']>;
  port?: Maybe<Scalars['String']['output']>;
};

export enum MailSettingsAuthentication {
  /** Cram Md5 */
  CramMd5 = 'cram_md5',
  /** Login */
  Login = 'login',
  /** Plain */
  Plain = 'plain'
}

export type MailSettingsInput = {
  address: Scalars['String']['input'];
  authentication: Scalars['String']['input'];
  domain: Scalars['String']['input'];
  enableStarttlsAuto: Scalars['Boolean']['input'];
  fromEmailAddress: Scalars['String']['input'];
  password: Scalars['String']['input'];
  port: Scalars['String']['input'];
  userName: Scalars['String']['input'];
};

/** Autogenerated input type of ManageSurcharge */
export type ManageSurchargeInput = {
  applyPatientSurcharge: Scalars['Boolean']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** ID of the invoice transaction */
  invoiceTransaction: Scalars['ID']['input'];
  surcharge: Scalars['Float']['input'];
};

/** Autogenerated return type of ManageSurcharge. */
export type ManageSurchargePayload = {
  __typename?: 'ManageSurchargePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  fieldErrors?: Maybe<Array<FieldError>>;
  invoiceTransaction?: Maybe<InvoiceTransaction>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

/** Autogenerated input type of ManualCancelClaim */
export type ManualCancelClaimInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** GUID of the health fund request you want to cancel */
  request: Scalars['ID']['input'];
};

/** Autogenerated return type of ManualCancelClaim. */
export type ManualCancelClaimPayload = {
  __typename?: 'ManualCancelClaimPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  fieldErrors?: Maybe<Array<FieldError>>;
  /** The new request created from the cancellation */
  healthFundsRequest?: Maybe<HealthFundsRequest>;
  /** The invoice that was updated from the cancellation */
  invoice?: Maybe<Invoice>;
  /** The invoice claim items that were include as part of the cancellation */
  invoiceClaimItems?: Maybe<Array<InvoiceItem>>;
  /** The invoice transaction that was created from the cancellation if any money was to be adjusted on the invoice */
  invoiceTransaction?: Maybe<InvoiceTransaction>;
  /** The original request to be cancelled */
  originalHealthFundsRequest?: Maybe<HealthFundsRequest>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type ManualPaymentProviderInput = {
  /** Enable/Disable payment provider */
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  /** Configured payment methods */
  paymentMethods?: InputMaybe<Array<PaymentMethodInput>>;
};

export type MarketingStatistics = {
  __typename?: 'MarketingStatistics';
  highestRevenueReferentName?: Maybe<Scalars['String']['output']>;
  highestRevenueReferral?: Maybe<Scalars['Float']['output']>;
  topReferrerName?: Maybe<Scalars['String']['output']>;
  topReferrerValue?: Maybe<Scalars['Float']['output']>;
  totalReferralIn?: Maybe<Scalars['Float']['output']>;
  totalReferralOut?: Maybe<Scalars['Float']['output']>;
};

export type Material = {
  __typename?: 'Material';
  colour?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['ISO8601DateTime']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  prettyTreatments: Array<Scalars['String']['output']>;
  state: MaterialState;
  treatmentDefinitions: Array<DentalTreatmentTranslation>;
  treatments: Array<DentalTreatment>;
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

/** The connection type for Material. */
export type MaterialConnection = {
  __typename?: 'MaterialConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<MaterialEdge>>>;
  /** IDs of ALL results, regardless of pagination */
  ids: Array<Scalars['ID']['output']>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Material>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Returns total count of items in list */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type MaterialEdge = {
  __typename?: 'MaterialEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<Material>;
};

export type MaterialInput = {
  colour?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<MaterialState>;
  treatments?: InputMaybe<Array<DentalTreatment>>;
};

export enum MaterialState {
  Active = 'active',
  Inactive = 'inactive'
}

export type MedipassPaymentProviderInput = {
  /** Your medipass API key */
  apiKey?: InputMaybe<Scalars['String']['input']>;
  /** Your medipass App ID */
  appId?: InputMaybe<Scalars['String']['input']>;
  /** Enable/Disable payment provider */
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  /** Whether to target stg or prod */
  environment?: InputMaybe<PaymentProviderMedipassEnvironment>;
};

export type MergeField = {
  __typename?: 'MergeField';
  /** Name of the merge field */
  name: Scalars['String']['output'];
  /** Value of the field cast to string */
  value: Scalars['String']['output'];
};

export type Message = {
  __typename?: 'Message';
  /** The date and time the message was created */
  createdAt: Scalars['ISO8601DateTime']['output'];
  createdBy?: Maybe<UserPracticeLink>;
  /** The date and time the message was discarded */
  discardedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  /** The email message */
  email?: Maybe<Email>;
  /** Unique UUID of the message */
  guid: Scalars['ID']['output'];
  /** Unique identifier of the message */
  id: Scalars['ID']['output'];
  /** Returns true if message is inbound */
  inbound?: Maybe<Scalars['Boolean']['output']>;
  /** A printable letter */
  letter?: Maybe<Letter>;
  /** Private note added to the message */
  note?: Maybe<Scalars['String']['output']>;
  /** Returns true if message is outbound */
  outbound?: Maybe<Scalars['Boolean']['output']>;
  /** The ID of the receiver of the message */
  receiverId?: Maybe<Scalars['ID']['output']>;
  /** The recipient of the message */
  recipient?: Maybe<Participant>;
  /** The sender of the message */
  sender?: Maybe<Participant>;
  /** The ID of the sender of the message */
  senderId?: Maybe<Scalars['ID']['output']>;
  /** The SMS message */
  sms?: Maybe<ShortMessage>;
  /** The date and time the message was last updated */
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

/** The connection type for Message. */
export type MessageConnection = {
  __typename?: 'MessageConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<MessageEdge>>>;
  /** IDs of ALL results, regardless of pagination */
  ids: Array<Scalars['ID']['output']>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Message>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Returns total count of items in list */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type MessageEdge = {
  __typename?: 'MessageEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<Message>;
};

/**
 * For some reason, only Object types that implement an interface
 * will show up if included in orphan_types, so this interface
 * just moves the message field to be used in an interface
 */
export type MessageInterface = {
  /** Additional details, for logging or debugging purposes */
  details?: Maybe<Scalars['String']['output']>;
  /** Message to display to the user */
  message: Scalars['String']['output'];
};

/** Autogenerated return type of MessageUpdated. */
export type MessageUpdatedPayload = {
  __typename?: 'MessageUpdatedPayload';
  sms?: Maybe<ShortMessage>;
};

export type Metadata = {
  __typename?: 'Metadata';
  hasPrivateHealthCare?: Maybe<Scalars['Boolean']['output']>;
  hasPublicHealthCare?: Maybe<Scalars['Boolean']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
};

/** Autogenerated input type of Migrate */
export type MigrateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  version?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of Migrate. */
export type MigratePayload = {
  __typename?: 'MigratePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  dataMigrations?: Maybe<Array<DataMigration>>;
};

/** Autogenerated return type of MobileAppointmentUpdated. */
export type MobileAppointmentUpdatedPayload = {
  __typename?: 'MobileAppointmentUpdatedPayload';
  appointment?: Maybe<Appointment>;
};

export type MobileValue = {
  __typename?: 'MobileValue';
  code?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  short?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of Move */
export type MoveInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Destination folder */
  destination: Scalars['ID']['input'];
  /** UUIDs of Attachments or Directories to move */
  sources: Array<Scalars['ID']['input']>;
};

/** Autogenerated return type of Move. */
export type MovePayload = {
  __typename?: 'MovePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Contains the affected folders content was moved to / from */
  directories?: Maybe<Array<Directory>>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  fieldErrors?: Maybe<Array<FieldError>>;
  /** Contains the updated records of moved directories and attachments */
  sources?: Maybe<Array<DirectoryContent>>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  acceptInvitation?: Maybe<AcceptInvitationPayload>;
  acceptPatientAppInvite?: Maybe<AcceptInvitePayload>;
  acceptTreatmentPlan?: Maybe<AcceptTreatmentPlanPayload>;
  addAppointmentGuests?: Maybe<AddGuestsPayload>;
  addAuthenticator?: Maybe<AddAuthenticatorPayload>;
  addCreditCard?: Maybe<AddCreditCardPayload>;
  addFamilyMember?: Maybe<AddFamilyMemberPayload>;
  addGroupToRole?: Maybe<AddGroupToRolePayload>;
  addToTreatmentPlanTreatmentsPreset?: Maybe<AddToTreatmentPlanTreatmentsPresetPayload>;
  adminDeleteAccount?: Maybe<DeleteAccountPayload>;
  adminEnableFeatures?: Maybe<EnableFeaturesPayload>;
  adminImportDentalCodes?: Maybe<ImportDentalCodesPayload>;
  adminRefreshSidekiqJob?: Maybe<RefreshJobPayload>;
  adminUpdateAccount?: Maybe<UpdateAccountPayload>;
  adminUpdatePassword?: Maybe<UpdatePasswordPayload>;
  adminUpdateUser?: Maybe<UpdateUserPayload>;
  adminUpgradeAccountPlan?: Maybe<UpgradeAccountPlanPayload>;
  /** Archive an attachment */
  archiveAttachment?: Maybe<ArchiveAttachmentPayload>;
  /** Archive an array of attachments */
  archiveAttachments?: Maybe<ArchiveAttachmentsPayload>;
  archiveCustomerRequest?: Maybe<ArchiveCustomerRequestPayload>;
  assignAlert?: Maybe<AssignAlertPayload>;
  assignAppointmentReasons?: Maybe<AssignAppointmentReasonsPayload>;
  /** Creates or updates price for a price list and catalogue item */
  assignPrice?: Maybe<AssignPricePayload>;
  backdateInvoiceTransaction?: Maybe<BackdateInvoiceTransactionPayload>;
  bookAppointment?: Maybe<CreateReservationPayload>;
  /** Bulk deletes a group of patient record items */
  bulkDeletePatientRecordItems?: Maybe<BulkDeletePatientRecordItemsPayload>;
  /** Bulk update attachments */
  bulkUpdateAttachments?: Maybe<BulkUpdateAttachmentPayload>;
  /** Bulk updates a group of patient record items */
  bulkUpdatePatientRecordItems?: Maybe<BulkUpdatePatientRecordItemsPayload>;
  cancelEmail?: Maybe<CancelEmailPayload>;
  cancelInvitation?: Maybe<CancelInvitationPayload>;
  cancelSms?: Maybe<CancelSmsPayload>;
  changePassword?: Maybe<ChangePasswordPayload>;
  clearAccessRestriction?: Maybe<ClearRestrictionPayload>;
  configureMailSettings?: Maybe<ConfigureMailSettingPayload>;
  /** Allows guest to accept invite to appointment */
  confirmAppointmentGuest?: Maybe<ConfirmGuestPayload>;
  confirmOnlineBooking?: Maybe<ConfirmPayload>;
  confirmSignUp?: Maybe<ConfirmSignUpPayload>;
  confirmStripePaymentIntent?: Maybe<ConfirmPaymentIntentPayload>;
  copyPatientToPractice?: Maybe<CopyPatientToPracticePayload>;
  copyTreatmentsPreset?: Maybe<CopyTreatmentsPresetPayload>;
  createAlert?: Maybe<CreateAlertPayload>;
  createAppointment?: Maybe<CreateAppointmentPayload>;
  /** Creates an appointment for a treatment plan. This appointment has not been scheduled in yet */
  createAppointmentForTreatmentPlan?: Maybe<CreateAppointmentForTreatmentPlanPayload>;
  createAppointmentReason?: Maybe<CreateAppointmentReasonPayload>;
  createAppointments?: Maybe<CreateAppointmentsPayload>;
  /** Update an attachments attributes */
  createAttachment?: Maybe<CreateAttachmentPayload>;
  createAutomaticRecall?: Maybe<CreateAutomaticRecallPayload>;
  createBadgesBadgeLink?: Maybe<CreateBadgesBadgeLinkPayload>;
  createBadgesTemplate?: Maybe<CreateBadgesTemplatePayload>;
  createCancellationReason?: Maybe<CreateCancellationReasonPayload>;
  createCatalogueItem?: Maybe<CreateCatalogueItemPayload>;
  createCategory?: Maybe<CreateCategoryPayload>;
  createConnectedAccount?: Maybe<CreateConnectedAccountPayload>;
  createCustomImport?: Maybe<CreateCustomImportPayload>;
  createCustomerRequest?: Maybe<CreateCustomerRequestPayload>;
  /** Bulk creates multiple conditions */
  createDentalToothConditions?: Maybe<CreateToothConditionsPayload>;
  /** Creates a tooth treatment */
  createDentalToothTreatment?: Maybe<CreateToothTreatmentPayload>;
  /** Bulk creates multiple treatments */
  createDentalToothTreatments?: Maybe<CreateToothTreatmentsPayload>;
  /** Create a new directory */
  createDirectory?: Maybe<CreateDirectoryPayload>;
  createFamily?: Maybe<CreateFamilyPayload>;
  createForm?: Maybe<CreateFormPayload>;
  createFormField?: Maybe<CreateFieldPayload>;
  createFormTemplate?: Maybe<CreateFormTemplatePayload>;
  createGroup?: Maybe<CreateGroupPayload>;
  createInvoice?: Maybe<CreateInvoicePayload>;
  createInvoiceItem?: Maybe<CreateInvoiceItemPayload>;
  createInvoiceSetting?: Maybe<CreateInvoiceSettingsPayload>;
  createLabWork?: Maybe<CreateLabWorkPayload>;
  createMaterial?: Maybe<CreateMaterialPayload>;
  createNote?: Maybe<CreateNotePayload>;
  /** Creates a patient record item */
  createPatientRecordItem?: Maybe<CreatePatientRecordItemPayload>;
  /** Creates a new perio exam for a patient. */
  createPerioExam?: Maybe<CreatePerioExamPayload>;
  createPractice?: Maybe<CreatePracticePayload>;
  createPracticeLocation?: Maybe<CreatePracticeLocationPayload>;
  /** Create a new price list */
  createPriceList?: Maybe<CreatePriceListPayload>;
  createRecall?: Maybe<CreateRecallPayload>;
  createRecurringTask?: Maybe<CreateRecurringTaskPayload>;
  createReferral?: Maybe<CreateReferralPayload>;
  createReferralSource?: Maybe<CreateReferralSourcePayload>;
  /** Creates a new saved report */
  createReportsSavedReport?: Maybe<CreateReportsSavedReportPayload>;
  createReviewResponse?: Maybe<CreateReviewResponsePayload>;
  createReviewTemplate?: Maybe<CreateReviewTemplatePayload>;
  createRichText?: Maybe<CreateRichTextPayload>;
  createRoom?: Maybe<CreateRoomPayload>;
  createRosterEvent?: Maybe<CreateEventPayload>;
  createShiftType?: Maybe<CreateShiftPayload>;
  createSnippet?: Maybe<CreateSnippetPayload>;
  createSterilisationAutoclave?: Maybe<CreateSterilisationAutoclavePayload>;
  createSterilisationCycle?: Maybe<CreateSterilisationCyclePayload>;
  createSterilisationCycleType?: Maybe<CreateSterilisationCycleTypePayload>;
  createSterilisationTracking?: Maybe<CreateSterilisationTrackingPayload>;
  createStripeSetupIntent?: Maybe<CreateSetupIntentPayload>;
  /** Create a tag */
  createTag?: Maybe<CreateTagPayload>;
  createTask?: Maybe<CreateTaskPayload>;
  createTemplate?: Maybe<CreateTemplatePayload>;
  createTreatment?: Maybe<CreateTreatmentPayload>;
  createTreatmentPlan?: Maybe<CreateTreatmentPlanPayload>;
  createTreatmentsPreset?: Maybe<CreateTreatmentsPresetPayload>;
  createTreatmentsPresetItem?: Maybe<CreateTreatmentsPresetItemPayload>;
  createTyroTerminal?: Maybe<CreateTyroTerminalPayload>;
  createUser?: Maybe<CreateUserPayload>;
  createWaitlist?: Maybe<CreateWaitlistPayload>;
  createWorkflow?: Maybe<CreateWorkflowPayload>;
  dataMigrate?: Maybe<MigratePayload>;
  deactivateAccountUser?: Maybe<DeactivateAccountUserPayload>;
  /** Delete a directory */
  deleteDirectory?: Maybe<DeleteDirectoryPayload>;
  deleteForm?: Maybe<DeleteFormPayload>;
  deleteFormField?: Maybe<DeleteFieldPayload>;
  deleteFormSection?: Maybe<DeleteSectionPayload>;
  deleteInvoice?: Maybe<DeleteInvoicePayload>;
  deleteInvoiceItem?: Maybe<DeleteInvoiceItemPayload>;
  deleteMailSettings?: Maybe<DeleteMailSettingPayload>;
  deleteRichText?: Maybe<DeleteRichTextPayload>;
  /** Delete a tag */
  deleteTag?: Maybe<DeleteTagPayload>;
  deleteWaitlist?: Maybe<DeleteWaitlistPayload>;
  deliverInvoice?: Maybe<DeliverInvoicePayload>;
  destroyBadgesBadgeLink?: Maybe<DestroyBadgesBadgeLinkPayload>;
  destroyNote?: Maybe<DestroyNotePayload>;
  /** Destroys a patient record item */
  destroyPatientRecordItem?: Maybe<DestroyPatientRecordItemPayload>;
  destroySterilisationAutoclave?: Maybe<DestroySterilisationAutoclavePayload>;
  destroySterilisationCycle?: Maybe<DestroySterilisationCyclePayload>;
  destroySterilisationCycleType?: Maybe<DestroySterilisationCycleTypePayload>;
  destroySterilisationTracking?: Maybe<DestroySterilisationTrackingPayload>;
  discardReferral?: Maybe<DiscardReferralPayload>;
  discardReferralSource?: Maybe<DiscardReferralSourcePayload>;
  discardTreatmentPlan?: Maybe<DiscardTreatmentPlanPayload>;
  duplicateFormTemplate?: Maybe<DuplicateFormTemplatePayload>;
  duplicateInvoiceItem?: Maybe<DuplicateInvoiceItemPayload>;
  /** Duplicates a patient record item */
  duplicatePatientRecordItem?: Maybe<DuplicatePatientRecordItemPayload>;
  emailTreatmentPlan?: Maybe<EmailTreatmentPlanPayload>;
  enableBiometrics?: Maybe<EnableBiometricsPayload>;
  generateAppointmentInvoices?: Maybe<CreateInvoicesPayload>;
  grantPermission?: Maybe<GrantPermissionPayload>;
  grantPermissionToGroup?: Maybe<GrantPermissionToGroupPayload>;
  healthFundsCancelClaim?: Maybe<CancelClaimPayload>;
  healthFundsClaim?: Maybe<ClaimPayload>;
  healthFundsManualCancelClaim?: Maybe<ManualCancelClaimPayload>;
  healthFundsPayment?: Maybe<PaymentPayload>;
  healthFundsQuote?: Maybe<QuotePayload>;
  healthFundsRefund?: Maybe<RefundPayload>;
  inviteToPatientApp?: Maybe<InviteToAppPayload>;
  login?: Maybe<LoginPayload>;
  loginWithRestriction?: Maybe<LoginWithRestrictionPayload>;
  logout?: Maybe<LogoutPayload>;
  manageSurcharge?: Maybe<ManageSurchargePayload>;
  mergeRichText?: Maybe<MailMergePayload>;
  /** Move files and folders */
  moveDocuments?: Maybe<MovePayload>;
  onboardingFinalise?: Maybe<FinalisePayload>;
  onboardingInviteStaff?: Maybe<InviteStaffPayload>;
  onboardingUpdateProfile?: Maybe<UpdateProfilePayload>;
  /** Parses a contacts import */
  parseContactsImport?: Maybe<ParseContactsImportPayload>;
  /** Parses a patients import */
  parsePatientsImport?: Maybe<ParsePatientsImportPayload>;
  payInvoice?: Maybe<PayInvoicePayload>;
  pinNote?: Maybe<PinNotePayload>;
  printLetter?: Maybe<PrintLetterPayload>;
  processStripeRefund?: Maybe<StripeRefundPayload>;
  purchaseCredits?: Maybe<PurchaseCreditsPayload>;
  reactivateSubscription?: Maybe<ReactivateSubscriptionPayload>;
  recoverPassword?: Maybe<RecoverPasswordPayload>;
  refreshToken?: Maybe<RefreshTokenPayload>;
  regenerateFormLink?: Maybe<RegenerateRestrictedLinkPayload>;
  /** Allows guest to reject invite to appointment */
  rejectAppointmentGuest?: Maybe<RejectGuestPayload>;
  rejectOnlineBooking?: Maybe<RejectPayload>;
  rejectTreatmentPlan?: Maybe<RejectTreatmentPlanPayload>;
  removeAppointment?: Maybe<RemoveAppointmentPayload>;
  removeAppointmentGuest?: Maybe<RemoveGuestPayload>;
  removeAuthenticator?: Maybe<RemoveAuthenticatorPayload>;
  removeCreditCard?: Maybe<RemoveCreditCardPayload>;
  removeFamilyMember?: Maybe<RemoveFamilyMemberPayload>;
  removeGroup?: Maybe<RemoveGroupPayload>;
  removeGroupFromRole?: Maybe<RemoveGroupFromRolePayload>;
  removeLabWork?: Maybe<RemoveLabWorkPayload>;
  removeTreatmentsPreset?: Maybe<RemoveTreatmentsPresetPayload>;
  removeTreatmentsPresetItem?: Maybe<RemoveTreatmentsPresetItemPayload>;
  removeTyroTerminal?: Maybe<RemoveTyroTerminalPayload>;
  removeUserContact?: Maybe<RemoveUserContactPayload>;
  reorderFormField?: Maybe<ReorderFieldPayload>;
  /** Reorders a patient record item within an appointment */
  reorderPatientRecordItem?: Maybe<ReorderPatientRecordItemPayload>;
  reorderTreatmentAppointment?: Maybe<ReorderTreatmentAppointmentPayload>;
  requestAuthenticatorCode?: Maybe<RequestAuthenticatorCodePayload>;
  requestOtp?: Maybe<RequestOtpPayload>;
  requestStripePaymentIntent?: Maybe<RequestPaymentIntentPayload>;
  rescheduleAppointment?: Maybe<RescheduleAppointmentPayload>;
  rescheduleAppointments?: Maybe<RescheduleAppointmentsPayload>;
  resendCustomerRequest?: Maybe<ResendCustomerRequestPayload>;
  resendInvitation?: Maybe<ResendInvitationPayload>;
  resetPassword?: Maybe<ResetPasswordPayload>;
  /** Resolves a tooth by marking conditions as treated and treatments as replaced */
  resolveDentalTooth?: Maybe<ResolveToothPayload>;
  restoreDefaultPermissionGroups?: Maybe<RestoreDefaultsPayload>;
  retryEmail?: Maybe<RetryEmailPayload>;
  retrySms?: Maybe<RetrySmsPayload>;
  revokePermission?: Maybe<RevokePermissionPayload>;
  revokePermissionFromGroup?: Maybe<RevokePermissionFromGroupPayload>;
  scheduleReminderInvoice?: Maybe<ScheduleReminderInvoicePayload>;
  sendAppointmentConfirmationNotifications?: Maybe<SendAppointmentConfirmationNotificationsPayload>;
  sendAppointmentReminderNotifications?: Maybe<SendAppointmentReminderNotificationsPayload>;
  sendAppointmentReviewRequest?: Maybe<SendAppointmentReviewRequestPayload>;
  sendAuthenticatorRecoveryCode?: Maybe<SendRecoveryCodePayload>;
  sendBulkCommunications?: Maybe<SendBulkCommunicationsPayload>;
  sendCommunications?: Maybe<SendCommunicationsPayload>;
  signUp?: Maybe<SignUpPayload>;
  switchUser?: Maybe<SwitchPayload>;
  syncConnectedAccount?: Maybe<SyncConnectedAccountPayload>;
  toggleFormBuilderV2?: Maybe<ToggleV2Payload>;
  togglePatientAutoRecall?: Maybe<TogglePatientAutoRecallPayload>;
  toggleReadMessages?: Maybe<ToggleReadMessagesPayload>;
  transitionFormTemplateState?: Maybe<TransitionFormTemplateStatePayload>;
  transitionLabWork?: Maybe<TransitionLabWorkPayload>;
  transitionNoteState?: Maybe<TransitionNoteStatePayload>;
  transitionPatientAppState?: Maybe<TransitionPatientAppStatePayload>;
  transitionPatientRecordItem?: Maybe<TransitionPatientRecordItemPayload>;
  /** Transitions the state of a price list */
  transitionPriceList?: Maybe<TransitionPriceListStatePayload>;
  transitionRecallState?: Maybe<TransitionRecallStatePayload>;
  transitionReferral?: Maybe<TransitionReferralPayload>;
  transitionReferralSource?: Maybe<TransitionReferralSourcePayload>;
  transitionRoomState?: Maybe<TransitionRoomStatePayload>;
  transitionSnippetState?: Maybe<TransitionSnippetStatePayload>;
  transitionStateCategory?: Maybe<TransitionStateCategoryPayload>;
  transitionStateShiftType?: Maybe<TransitionStateShiftPayload>;
  transitionTaskState?: Maybe<TransitionTaskStatePayload>;
  transitionTemplateState?: Maybe<TransitionTemplateStatePayload>;
  transitionTreatmentPlanState?: Maybe<TransitionTreatmentPlanStatePayload>;
  transitionTreatmentState?: Maybe<TransitionTreatmentStatePayload>;
  transitionUserState?: Maybe<TransitionStatePayload>;
  transitionWorkflowState?: Maybe<TransitionWorkflowStatePayload>;
  triggerAutoRecall?: Maybe<TriggerAutoRecallPayload>;
  unassignAlert?: Maybe<UnassignAlertPayload>;
  undiscardTreatmentPlan?: Maybe<UndiscardTreatmentPlanPayload>;
  updateActiveProfile?: Maybe<UpdateActiveProfilePayload>;
  updateAlert?: Maybe<UpdateAlertPayload>;
  updateAppointment?: Maybe<UpdateAppointmentPayload>;
  updateAppointmentCommunications?: Maybe<UpdateCommunicationsPayload>;
  updateAppointmentGuest?: Maybe<UpdateGuestPayload>;
  updateAppointmentReason?: Maybe<UpdateAppointmentReasonPayload>;
  /** Update an attachments attributes */
  updateAttachment?: Maybe<UpdateAttachmentPayload>;
  updateBadgesTemplate?: Maybe<UpdateBadgesTemplatePayload>;
  updateBillingAddress?: Maybe<UpdateBillingAddressPayload>;
  updateCancellationReason?: Maybe<UpdateCancellationReasonPayload>;
  updateCatalogueItem?: Maybe<UpdateCatalogueItemPayload>;
  updateCategory?: Maybe<UpdateCategoryPayload>;
  updateConnectionAlert?: Maybe<UpdateConnectionAlertPayload>;
  updateCustomerRequest?: Maybe<UpdateCustomerRequestPayload>;
  /** Bulk updates teeth */
  updateDentalTeeth?: Maybe<UpdateTeethPayload>;
  /** Updates a single tooth condition */
  updateDentalToothCondition?: Maybe<UpdateToothConditionPayload>;
  /** Updates a single tooth treatment */
  updateDentalToothTreatment?: Maybe<UpdateToothTreatmentPayload>;
  /** Update or move a directory */
  updateDirectory?: Maybe<UpdateDirectoryPayload>;
  updateFamily?: Maybe<UpdateFamilyPayload>;
  updateForm?: Maybe<UpdateFormPayload>;
  updateFormField?: Maybe<UpdateFieldPayload>;
  updateFormSection?: Maybe<UpdateSectionPayload>;
  updateFormTemplate?: Maybe<UpdateFormTemplatePayload>;
  updateGroup?: Maybe<UpdateGroupPayload>;
  updateHicapsPaymentProvider?: Maybe<UpdateHicapsProviderPayload>;
  updateInvoice?: Maybe<UpdateInvoicePayload>;
  updateInvoiceItem?: Maybe<UpdateInvoiceItemPayload>;
  updateInvoiceSetting?: Maybe<UpdateInvoiceSettingsPayload>;
  updateInvoiceTransaction?: Maybe<UpdateInvoiceTransactionPayload>;
  updateLabWork?: Maybe<UpdateLabWorkPayload>;
  updateLetter?: Maybe<UpdateLetterPayload>;
  updateManualPaymentProvider?: Maybe<UpdateManualProviderPayload>;
  updateMaterial?: Maybe<UpdateMaterialPayload>;
  updateMedipassPaymentProvider?: Maybe<UpdateMedipassProviderPayload>;
  updateMessageNote?: Maybe<UpdateMessageNotePayload>;
  updateNote?: Maybe<UpdateNotePayload>;
  updateObject?: Maybe<UpdateObjectPayload>;
  updateOnlineBookingSettings?: Maybe<UpdateOnlineBookingSettingsPayload>;
  updatePatient?: Maybe<UpdatePatientPayload>;
  /** Updates a patient record item */
  updatePatientRecordItem?: Maybe<UpdatePatientRecordItemPayload>;
  /** Bulk update perio entries */
  updatePerioEntries?: Maybe<UpdatePerioEntriesPayload>;
  /** Updates a perio entry */
  updatePerioEntry?: Maybe<UpdatePerioEntryPayload>;
  /** Updates an existing perio exam */
  updatePerioExam?: Maybe<UpdatePerioExamPayload>;
  updatePractice?: Maybe<UpdatePracticePayload>;
  updatePracticeLocation?: Maybe<UpdatePracticeLocationPayload>;
  updatePracticePreference?: Maybe<UpdatePreferencePayload>;
  updatePreferences?: Maybe<UpdatePreferencesPayload>;
  /** Update an existing price list */
  updatePriceList?: Maybe<UpdatePriceListPayload>;
  updateRecurringTask?: Maybe<UpdateRecurringTaskPayload>;
  updateReferral?: Maybe<UpdateReferralPayload>;
  updateReferralSource?: Maybe<UpdateReferralSourcePayload>;
  /** Updates an existing saved report */
  updateReportsSavedReport?: Maybe<UpdateReportsSavedReportPayload>;
  updateReviewTemplate?: Maybe<UpdateReviewTemplatePayload>;
  updateRichText?: Maybe<UpdateRichTextPayload>;
  updateRoom?: Maybe<UpdateRoomPayload>;
  updateShiftType?: Maybe<UpdateShiftPayload>;
  updateSnippet?: Maybe<UpdateSnippetPayload>;
  updateStaff?: Maybe<UpdateStaffPayload>;
  updateStateInvoice?: Maybe<UpdateStateInvoicePayload>;
  updateStatistic?: Maybe<UpdateStatisticPayload>;
  updateStatistics?: Maybe<UpdateStatisticsPayload>;
  updateSterilisationAutoclave?: Maybe<UpdateSterilisationAutoclavePayload>;
  updateSterilisationCycle?: Maybe<UpdateSterilisationCyclePayload>;
  updateSterilisationCycleType?: Maybe<UpdateSterilisationCycleTypePayload>;
  updateSterilisationTracking?: Maybe<UpdateSterilisationTrackingPayload>;
  updateStripePaymentProvider?: Maybe<UpdateStripeProviderPayload>;
  updateTask?: Maybe<UpdateTaskPayload>;
  updateTemplate?: Maybe<UpdateTemplatePayload>;
  updateTreatment?: Maybe<UpdateTreatmentPayload>;
  updateTreatmentPlan?: Maybe<UpdateTreatmentPlanPayload>;
  updateTreatmentsPreset?: Maybe<UpdateTreatmentsPresetPayload>;
  updateTreatmentsPresetItem?: Maybe<UpdateTreatmentsPresetItemPayload>;
  updateTyroPaymentProvider?: Maybe<UpdateTyroProviderPayload>;
  updateTyroTerminal?: Maybe<UpdateTyroTerminalPayload>;
  updateUserAccountCredit?: Maybe<AdjustAccountCreditPayload>;
  updateUserContact?: Maybe<UpdateUserContactPayload>;
  updateWaitlist?: Maybe<UpdateWaitlistPayload>;
  updateWorkflow?: Maybe<UpdateWorkflowPayload>;
  /** Upload attachments for a patient */
  uploadAttachments?: Maybe<UploadAttachmentsPayload>;
  uploadAvatar?: Maybe<UpdateAvatarPayload>;
  /** Upload images in a lexical editor */
  uploadImages?: Maybe<UploadImagePayload>;
  validateFormSection?: Maybe<ValidateSectionPayload>;
  verifyInvitation?: Maybe<VerifyInvitationPayload>;
  verifyOtp?: Maybe<VerifyOtpPayload>;
};


export type MutationAcceptInvitationArgs = {
  input: AcceptInvitationInput;
};


export type MutationAcceptPatientAppInviteArgs = {
  input: AcceptInviteInput;
};


export type MutationAcceptTreatmentPlanArgs = {
  input: AcceptTreatmentPlanInput;
};


export type MutationAddAppointmentGuestsArgs = {
  input: AddGuestsInput;
};


export type MutationAddAuthenticatorArgs = {
  input: AddAuthenticatorInput;
};


export type MutationAddCreditCardArgs = {
  input: AddCreditCardInput;
};


export type MutationAddFamilyMemberArgs = {
  input: AddFamilyMemberInput;
};


export type MutationAddGroupToRoleArgs = {
  input: AddGroupToRoleInput;
};


export type MutationAddToTreatmentPlanTreatmentsPresetArgs = {
  input: AddToTreatmentPlanTreatmentsPresetInput;
};


export type MutationAdminDeleteAccountArgs = {
  input: DeleteAccountInput;
};


export type MutationAdminEnableFeaturesArgs = {
  input: EnableFeaturesInput;
};


export type MutationAdminImportDentalCodesArgs = {
  input: ImportDentalCodesInput;
};


export type MutationAdminRefreshSidekiqJobArgs = {
  input: RefreshJobInput;
};


export type MutationAdminUpdateAccountArgs = {
  input: UpdateAccountInput;
};


export type MutationAdminUpdatePasswordArgs = {
  input: UpdatePasswordInput;
};


export type MutationAdminUpdateUserArgs = {
  input: UpdateUserInput;
};


export type MutationAdminUpgradeAccountPlanArgs = {
  input: UpgradeAccountPlanInput;
};


export type MutationArchiveAttachmentArgs = {
  input: ArchiveAttachmentInput;
};


export type MutationArchiveAttachmentsArgs = {
  input: ArchiveAttachmentsInput;
};


export type MutationArchiveCustomerRequestArgs = {
  input: ArchiveCustomerRequestInput;
};


export type MutationAssignAlertArgs = {
  input: AssignAlertInput;
};


export type MutationAssignAppointmentReasonsArgs = {
  input: AssignAppointmentReasonsInput;
};


export type MutationAssignPriceArgs = {
  input: AssignPriceInput;
};


export type MutationBackdateInvoiceTransactionArgs = {
  input: BackdateInvoiceTransactionInput;
};


export type MutationBookAppointmentArgs = {
  input: CreateReservationInput;
};


export type MutationBulkDeletePatientRecordItemsArgs = {
  input: BulkDeletePatientRecordItemsInput;
};


export type MutationBulkUpdateAttachmentsArgs = {
  input: BulkUpdateAttachmentInput;
};


export type MutationBulkUpdatePatientRecordItemsArgs = {
  input: BulkUpdatePatientRecordItemsInput;
};


export type MutationCancelEmailArgs = {
  input: CancelEmailInput;
};


export type MutationCancelInvitationArgs = {
  input: CancelInvitationInput;
};


export type MutationCancelSmsArgs = {
  input: CancelSmsInput;
};


export type MutationChangePasswordArgs = {
  input: ChangePasswordInput;
};


export type MutationClearAccessRestrictionArgs = {
  input: ClearRestrictionInput;
};


export type MutationConfigureMailSettingsArgs = {
  input: ConfigureMailSettingInput;
};


export type MutationConfirmAppointmentGuestArgs = {
  input: ConfirmGuestInput;
};


export type MutationConfirmOnlineBookingArgs = {
  input: ConfirmInput;
};


export type MutationConfirmSignUpArgs = {
  input: ConfirmSignUpInput;
};


export type MutationConfirmStripePaymentIntentArgs = {
  input: ConfirmPaymentIntentInput;
};


export type MutationCopyPatientToPracticeArgs = {
  input: CopyPatientToPracticeInput;
};


export type MutationCopyTreatmentsPresetArgs = {
  input: CopyTreatmentsPresetInput;
};


export type MutationCreateAlertArgs = {
  input: CreateAlertInput;
};


export type MutationCreateAppointmentArgs = {
  input: CreateAppointmentInput;
};


export type MutationCreateAppointmentForTreatmentPlanArgs = {
  input: CreateAppointmentForTreatmentPlanInput;
};


export type MutationCreateAppointmentReasonArgs = {
  input: CreateAppointmentReasonInput;
};


export type MutationCreateAppointmentsArgs = {
  input: CreateAppointmentsInput;
};


export type MutationCreateAttachmentArgs = {
  input: CreateAttachmentInput;
};


export type MutationCreateAutomaticRecallArgs = {
  input: CreateAutomaticRecallInput;
};


export type MutationCreateBadgesBadgeLinkArgs = {
  input: CreateBadgesBadgeLinkInput;
};


export type MutationCreateBadgesTemplateArgs = {
  input: CreateBadgesTemplateInput;
};


export type MutationCreateCancellationReasonArgs = {
  input: CreateCancellationReasonInput;
};


export type MutationCreateCatalogueItemArgs = {
  input: CreateCatalogueItemInput;
};


export type MutationCreateCategoryArgs = {
  input: CreateCategoryInput;
};


export type MutationCreateConnectedAccountArgs = {
  input: CreateConnectedAccountInput;
};


export type MutationCreateCustomImportArgs = {
  input: CreateCustomImportInput;
};


export type MutationCreateCustomerRequestArgs = {
  input: CreateCustomerRequestInput;
};


export type MutationCreateDentalToothConditionsArgs = {
  input: CreateToothConditionsInput;
};


export type MutationCreateDentalToothTreatmentArgs = {
  input: CreateToothTreatmentInput;
};


export type MutationCreateDentalToothTreatmentsArgs = {
  input: CreateToothTreatmentsInput;
};


export type MutationCreateDirectoryArgs = {
  input: CreateDirectoryInput;
};


export type MutationCreateFamilyArgs = {
  input: CreateFamilyInput;
};


export type MutationCreateFormArgs = {
  input: CreateFormInput;
};


export type MutationCreateFormFieldArgs = {
  input: CreateFieldInput;
};


export type MutationCreateFormTemplateArgs = {
  input: CreateFormTemplateInput;
};


export type MutationCreateGroupArgs = {
  input: CreateGroupInput;
};


export type MutationCreateInvoiceArgs = {
  input: CreateInvoiceInput;
};


export type MutationCreateInvoiceItemArgs = {
  input: CreateInvoiceItemInput;
};


export type MutationCreateInvoiceSettingArgs = {
  input: CreateInvoiceSettingsInput;
};


export type MutationCreateLabWorkArgs = {
  input: CreateLabWorkInput;
};


export type MutationCreateMaterialArgs = {
  input: CreateMaterialInput;
};


export type MutationCreateNoteArgs = {
  input: CreateNoteInput;
};


export type MutationCreatePatientRecordItemArgs = {
  input: CreatePatientRecordItemInput;
};


export type MutationCreatePerioExamArgs = {
  input: CreatePerioExamInput;
};


export type MutationCreatePracticeArgs = {
  input: CreatePracticeInput;
};


export type MutationCreatePracticeLocationArgs = {
  input: CreatePracticeLocationInput;
};


export type MutationCreatePriceListArgs = {
  input: CreatePriceListInput;
};


export type MutationCreateRecallArgs = {
  input: CreateRecallInput;
};


export type MutationCreateRecurringTaskArgs = {
  input: CreateRecurringTaskInput;
};


export type MutationCreateReferralArgs = {
  input: CreateReferralInput;
};


export type MutationCreateReferralSourceArgs = {
  input: CreateReferralSourceInput;
};


export type MutationCreateReportsSavedReportArgs = {
  input: CreateReportsSavedReportInput;
};


export type MutationCreateReviewResponseArgs = {
  input: CreateReviewResponseInput;
};


export type MutationCreateReviewTemplateArgs = {
  input: CreateReviewTemplateInput;
};


export type MutationCreateRichTextArgs = {
  input: CreateRichTextInput;
};


export type MutationCreateRoomArgs = {
  input: CreateRoomInput;
};


export type MutationCreateRosterEventArgs = {
  input: CreateEventInput;
};


export type MutationCreateShiftTypeArgs = {
  input: CreateShiftInput;
};


export type MutationCreateSnippetArgs = {
  input: CreateSnippetInput;
};


export type MutationCreateSterilisationAutoclaveArgs = {
  input: CreateSterilisationAutoclaveInput;
};


export type MutationCreateSterilisationCycleArgs = {
  input: CreateSterilisationCycleInput;
};


export type MutationCreateSterilisationCycleTypeArgs = {
  input: CreateSterilisationCycleTypeInput;
};


export type MutationCreateSterilisationTrackingArgs = {
  input: CreateSterilisationTrackingInput;
};


export type MutationCreateStripeSetupIntentArgs = {
  input: CreateSetupIntentInput;
};


export type MutationCreateTagArgs = {
  input: CreateTagInput;
};


export type MutationCreateTaskArgs = {
  input: CreateTaskInput;
};


export type MutationCreateTemplateArgs = {
  input: CreateTemplateInput;
};


export type MutationCreateTreatmentArgs = {
  input: CreateTreatmentInput;
};


export type MutationCreateTreatmentPlanArgs = {
  input: CreateTreatmentPlanInput;
};


export type MutationCreateTreatmentsPresetArgs = {
  input: CreateTreatmentsPresetInput;
};


export type MutationCreateTreatmentsPresetItemArgs = {
  input: CreateTreatmentsPresetItemInput;
};


export type MutationCreateTyroTerminalArgs = {
  input: CreateTyroTerminalInput;
};


export type MutationCreateUserArgs = {
  input: CreateUserInput;
};


export type MutationCreateWaitlistArgs = {
  input: CreateWaitlistInput;
};


export type MutationCreateWorkflowArgs = {
  input: CreateWorkflowInput;
};


export type MutationDataMigrateArgs = {
  input: MigrateInput;
};


export type MutationDeactivateAccountUserArgs = {
  input: DeactivateAccountUserInput;
};


export type MutationDeleteDirectoryArgs = {
  input: DeleteDirectoryInput;
};


export type MutationDeleteFormArgs = {
  input: DeleteFormInput;
};


export type MutationDeleteFormFieldArgs = {
  input: DeleteFieldInput;
};


export type MutationDeleteFormSectionArgs = {
  input: DeleteSectionInput;
};


export type MutationDeleteInvoiceArgs = {
  input: DeleteInvoiceInput;
};


export type MutationDeleteInvoiceItemArgs = {
  input: DeleteInvoiceItemInput;
};


export type MutationDeleteMailSettingsArgs = {
  input: DeleteMailSettingInput;
};


export type MutationDeleteRichTextArgs = {
  input: DeleteRichTextInput;
};


export type MutationDeleteTagArgs = {
  input: DeleteTagInput;
};


export type MutationDeleteWaitlistArgs = {
  input: DeleteWaitlistInput;
};


export type MutationDeliverInvoiceArgs = {
  input: DeliverInvoiceInput;
};


export type MutationDestroyBadgesBadgeLinkArgs = {
  input: DestroyBadgesBadgeLinkInput;
};


export type MutationDestroyNoteArgs = {
  input: DestroyNoteInput;
};


export type MutationDestroyPatientRecordItemArgs = {
  input: DestroyPatientRecordItemInput;
};


export type MutationDestroySterilisationAutoclaveArgs = {
  input: DestroySterilisationAutoclaveInput;
};


export type MutationDestroySterilisationCycleArgs = {
  input: DestroySterilisationCycleInput;
};


export type MutationDestroySterilisationCycleTypeArgs = {
  input: DestroySterilisationCycleTypeInput;
};


export type MutationDestroySterilisationTrackingArgs = {
  input: DestroySterilisationTrackingInput;
};


export type MutationDiscardReferralArgs = {
  input: DiscardReferralInput;
};


export type MutationDiscardReferralSourceArgs = {
  input: DiscardReferralSourceInput;
};


export type MutationDiscardTreatmentPlanArgs = {
  input: DiscardTreatmentPlanInput;
};


export type MutationDuplicateFormTemplateArgs = {
  input: DuplicateFormTemplateInput;
};


export type MutationDuplicateInvoiceItemArgs = {
  input: DuplicateInvoiceItemInput;
};


export type MutationDuplicatePatientRecordItemArgs = {
  input: DuplicatePatientRecordItemInput;
};


export type MutationEmailTreatmentPlanArgs = {
  input: EmailTreatmentPlanInput;
};


export type MutationEnableBiometricsArgs = {
  input: EnableBiometricsInput;
};


export type MutationGenerateAppointmentInvoicesArgs = {
  input: CreateInvoicesInput;
};


export type MutationGrantPermissionArgs = {
  input: GrantPermissionInput;
};


export type MutationGrantPermissionToGroupArgs = {
  input: GrantPermissionToGroupInput;
};


export type MutationHealthFundsCancelClaimArgs = {
  input: CancelClaimInput;
};


export type MutationHealthFundsClaimArgs = {
  input: ClaimInput;
};


export type MutationHealthFundsManualCancelClaimArgs = {
  input: ManualCancelClaimInput;
};


export type MutationHealthFundsPaymentArgs = {
  input: PaymentInput;
};


export type MutationHealthFundsQuoteArgs = {
  input: QuoteInput;
};


export type MutationHealthFundsRefundArgs = {
  input: RefundInput;
};


export type MutationInviteToPatientAppArgs = {
  input: InviteToAppInput;
};


export type MutationLoginArgs = {
  input: LoginInput;
};


export type MutationLoginWithRestrictionArgs = {
  input: LoginWithRestrictionInput;
};


export type MutationLogoutArgs = {
  input: LogoutInput;
};


export type MutationManageSurchargeArgs = {
  input: ManageSurchargeInput;
};


export type MutationMergeRichTextArgs = {
  input: MailMergeInput;
};


export type MutationMoveDocumentsArgs = {
  input: MoveInput;
};


export type MutationOnboardingFinaliseArgs = {
  input: FinaliseInput;
};


export type MutationOnboardingInviteStaffArgs = {
  input: InviteStaffInput;
};


export type MutationOnboardingUpdateProfileArgs = {
  input: UpdateProfileInput;
};


export type MutationParseContactsImportArgs = {
  input: ParseContactsImportInput;
};


export type MutationParsePatientsImportArgs = {
  input: ParsePatientsImportInput;
};


export type MutationPayInvoiceArgs = {
  input: PayInvoiceInput;
};


export type MutationPinNoteArgs = {
  input: PinNoteInput;
};


export type MutationPrintLetterArgs = {
  input: PrintLetterInput;
};


export type MutationProcessStripeRefundArgs = {
  input: StripeRefundInput;
};


export type MutationPurchaseCreditsArgs = {
  input: PurchaseCreditsInput;
};


export type MutationReactivateSubscriptionArgs = {
  input: ReactivateSubscriptionInput;
};


export type MutationRecoverPasswordArgs = {
  input: RecoverPasswordInput;
};


export type MutationRefreshTokenArgs = {
  input: RefreshTokenInput;
};


export type MutationRegenerateFormLinkArgs = {
  input: RegenerateRestrictedLinkInput;
};


export type MutationRejectAppointmentGuestArgs = {
  input: RejectGuestInput;
};


export type MutationRejectOnlineBookingArgs = {
  input: RejectInput;
};


export type MutationRejectTreatmentPlanArgs = {
  input: RejectTreatmentPlanInput;
};


export type MutationRemoveAppointmentArgs = {
  input: RemoveAppointmentInput;
};


export type MutationRemoveAppointmentGuestArgs = {
  input: RemoveGuestInput;
};


export type MutationRemoveAuthenticatorArgs = {
  input: RemoveAuthenticatorInput;
};


export type MutationRemoveCreditCardArgs = {
  input: RemoveCreditCardInput;
};


export type MutationRemoveFamilyMemberArgs = {
  input: RemoveFamilyMemberInput;
};


export type MutationRemoveGroupArgs = {
  input: RemoveGroupInput;
};


export type MutationRemoveGroupFromRoleArgs = {
  input: RemoveGroupFromRoleInput;
};


export type MutationRemoveLabWorkArgs = {
  input: RemoveLabWorkInput;
};


export type MutationRemoveTreatmentsPresetArgs = {
  input: RemoveTreatmentsPresetInput;
};


export type MutationRemoveTreatmentsPresetItemArgs = {
  input: RemoveTreatmentsPresetItemInput;
};


export type MutationRemoveTyroTerminalArgs = {
  input: RemoveTyroTerminalInput;
};


export type MutationRemoveUserContactArgs = {
  input: RemoveUserContactInput;
};


export type MutationReorderFormFieldArgs = {
  input: ReorderFieldInput;
};


export type MutationReorderPatientRecordItemArgs = {
  input: ReorderPatientRecordItemInput;
};


export type MutationReorderTreatmentAppointmentArgs = {
  input: ReorderTreatmentAppointmentInput;
};


export type MutationRequestAuthenticatorCodeArgs = {
  input: RequestAuthenticatorCodeInput;
};


export type MutationRequestOtpArgs = {
  input: RequestOtpInput;
};


export type MutationRequestStripePaymentIntentArgs = {
  input: RequestPaymentIntentInput;
};


export type MutationRescheduleAppointmentArgs = {
  input: RescheduleAppointmentInput;
};


export type MutationRescheduleAppointmentsArgs = {
  input: RescheduleAppointmentsInput;
};


export type MutationResendCustomerRequestArgs = {
  input: ResendCustomerRequestInput;
};


export type MutationResendInvitationArgs = {
  input: ResendInvitationInput;
};


export type MutationResetPasswordArgs = {
  input: ResetPasswordInput;
};


export type MutationResolveDentalToothArgs = {
  input: ResolveToothInput;
};


export type MutationRestoreDefaultPermissionGroupsArgs = {
  input: RestoreDefaultsInput;
};


export type MutationRetryEmailArgs = {
  input: RetryEmailInput;
};


export type MutationRetrySmsArgs = {
  input: RetrySmsInput;
};


export type MutationRevokePermissionArgs = {
  input: RevokePermissionInput;
};


export type MutationRevokePermissionFromGroupArgs = {
  input: RevokePermissionFromGroupInput;
};


export type MutationScheduleReminderInvoiceArgs = {
  input: ScheduleReminderInvoiceInput;
};


export type MutationSendAppointmentConfirmationNotificationsArgs = {
  input: SendAppointmentConfirmationNotificationsInput;
};


export type MutationSendAppointmentReminderNotificationsArgs = {
  input: SendAppointmentReminderNotificationsInput;
};


export type MutationSendAppointmentReviewRequestArgs = {
  input: SendAppointmentReviewRequestInput;
};


export type MutationSendAuthenticatorRecoveryCodeArgs = {
  input: SendRecoveryCodeInput;
};


export type MutationSendBulkCommunicationsArgs = {
  input: SendBulkCommunicationsInput;
};


export type MutationSendCommunicationsArgs = {
  input: SendCommunicationsInput;
};


export type MutationSignUpArgs = {
  input: SignUpInput;
};


export type MutationSwitchUserArgs = {
  input: SwitchInput;
};


export type MutationSyncConnectedAccountArgs = {
  input: SyncConnectedAccountInput;
};


export type MutationToggleFormBuilderV2Args = {
  input: ToggleV2Input;
};


export type MutationTogglePatientAutoRecallArgs = {
  input: TogglePatientAutoRecallInput;
};


export type MutationToggleReadMessagesArgs = {
  input: ToggleReadMessagesInput;
};


export type MutationTransitionFormTemplateStateArgs = {
  input: TransitionFormTemplateStateInput;
};


export type MutationTransitionLabWorkArgs = {
  input: TransitionLabWorkInput;
};


export type MutationTransitionNoteStateArgs = {
  input: TransitionNoteStateInput;
};


export type MutationTransitionPatientAppStateArgs = {
  input: TransitionPatientAppStateInput;
};


export type MutationTransitionPatientRecordItemArgs = {
  input: TransitionPatientRecordItemInput;
};


export type MutationTransitionPriceListArgs = {
  input: TransitionPriceListStateInput;
};


export type MutationTransitionRecallStateArgs = {
  input: TransitionRecallStateInput;
};


export type MutationTransitionReferralArgs = {
  input: TransitionReferralInput;
};


export type MutationTransitionReferralSourceArgs = {
  input: TransitionReferralSourceInput;
};


export type MutationTransitionRoomStateArgs = {
  input: TransitionRoomStateInput;
};


export type MutationTransitionSnippetStateArgs = {
  input: TransitionSnippetStateInput;
};


export type MutationTransitionStateCategoryArgs = {
  input: TransitionStateCategoryInput;
};


export type MutationTransitionStateShiftTypeArgs = {
  input: TransitionStateShiftInput;
};


export type MutationTransitionTaskStateArgs = {
  input: TransitionTaskStateInput;
};


export type MutationTransitionTemplateStateArgs = {
  input: TransitionTemplateStateInput;
};


export type MutationTransitionTreatmentPlanStateArgs = {
  input: TransitionTreatmentPlanStateInput;
};


export type MutationTransitionTreatmentStateArgs = {
  input: TransitionTreatmentStateInput;
};


export type MutationTransitionUserStateArgs = {
  input: TransitionStateInput;
};


export type MutationTransitionWorkflowStateArgs = {
  input: TransitionWorkflowStateInput;
};


export type MutationTriggerAutoRecallArgs = {
  input: TriggerAutoRecallInput;
};


export type MutationUnassignAlertArgs = {
  input: UnassignAlertInput;
};


export type MutationUndiscardTreatmentPlanArgs = {
  input: UndiscardTreatmentPlanInput;
};


export type MutationUpdateActiveProfileArgs = {
  input: UpdateActiveProfileInput;
};


export type MutationUpdateAlertArgs = {
  input: UpdateAlertInput;
};


export type MutationUpdateAppointmentArgs = {
  input: UpdateAppointmentInput;
};


export type MutationUpdateAppointmentCommunicationsArgs = {
  input: UpdateCommunicationsInput;
};


export type MutationUpdateAppointmentGuestArgs = {
  input: UpdateGuestInput;
};


export type MutationUpdateAppointmentReasonArgs = {
  input: UpdateAppointmentReasonInput;
};


export type MutationUpdateAttachmentArgs = {
  input: UpdateAttachmentInput;
};


export type MutationUpdateBadgesTemplateArgs = {
  input: UpdateBadgesTemplateInput;
};


export type MutationUpdateBillingAddressArgs = {
  input: UpdateBillingAddressInput;
};


export type MutationUpdateCancellationReasonArgs = {
  input: UpdateCancellationReasonInput;
};


export type MutationUpdateCatalogueItemArgs = {
  input: UpdateCatalogueItemInput;
};


export type MutationUpdateCategoryArgs = {
  input: UpdateCategoryInput;
};


export type MutationUpdateConnectionAlertArgs = {
  input: UpdateConnectionAlertInput;
};


export type MutationUpdateCustomerRequestArgs = {
  input: UpdateCustomerRequestInput;
};


export type MutationUpdateDentalTeethArgs = {
  input: UpdateTeethInput;
};


export type MutationUpdateDentalToothConditionArgs = {
  input: UpdateToothConditionInput;
};


export type MutationUpdateDentalToothTreatmentArgs = {
  input: UpdateToothTreatmentInput;
};


export type MutationUpdateDirectoryArgs = {
  input: UpdateDirectoryInput;
};


export type MutationUpdateFamilyArgs = {
  input: UpdateFamilyInput;
};


export type MutationUpdateFormArgs = {
  input: UpdateFormInput;
};


export type MutationUpdateFormFieldArgs = {
  input: UpdateFieldInput;
};


export type MutationUpdateFormSectionArgs = {
  input: UpdateSectionInput;
};


export type MutationUpdateFormTemplateArgs = {
  input: UpdateFormTemplateInput;
};


export type MutationUpdateGroupArgs = {
  input: UpdateGroupInput;
};


export type MutationUpdateHicapsPaymentProviderArgs = {
  input: UpdateHicapsProviderInput;
};


export type MutationUpdateInvoiceArgs = {
  input: UpdateInvoiceInput;
};


export type MutationUpdateInvoiceItemArgs = {
  input: UpdateInvoiceItemInput;
};


export type MutationUpdateInvoiceSettingArgs = {
  input: UpdateInvoiceSettingsInput;
};


export type MutationUpdateInvoiceTransactionArgs = {
  input: UpdateInvoiceTransactionInput;
};


export type MutationUpdateLabWorkArgs = {
  input: UpdateLabWorkInput;
};


export type MutationUpdateLetterArgs = {
  input: UpdateLetterInput;
};


export type MutationUpdateManualPaymentProviderArgs = {
  input: UpdateManualProviderInput;
};


export type MutationUpdateMaterialArgs = {
  input: UpdateMaterialInput;
};


export type MutationUpdateMedipassPaymentProviderArgs = {
  input: UpdateMedipassProviderInput;
};


export type MutationUpdateMessageNoteArgs = {
  input: UpdateMessageNoteInput;
};


export type MutationUpdateNoteArgs = {
  input: UpdateNoteInput;
};


export type MutationUpdateObjectArgs = {
  input: UpdateObjectInput;
};


export type MutationUpdateOnlineBookingSettingsArgs = {
  input: UpdateOnlineBookingSettingsInput;
};


export type MutationUpdatePatientArgs = {
  input: UpdatePatientInput;
};


export type MutationUpdatePatientRecordItemArgs = {
  input: UpdatePatientRecordItemInput;
};


export type MutationUpdatePerioEntriesArgs = {
  input: UpdatePerioEntriesInput;
};


export type MutationUpdatePerioEntryArgs = {
  input: UpdatePerioEntryInput;
};


export type MutationUpdatePerioExamArgs = {
  input: UpdatePerioExamInput;
};


export type MutationUpdatePracticeArgs = {
  input: UpdatePracticeInput;
};


export type MutationUpdatePracticeLocationArgs = {
  input: UpdatePracticeLocationInput;
};


export type MutationUpdatePracticePreferenceArgs = {
  input: UpdatePreferenceInput;
};


export type MutationUpdatePreferencesArgs = {
  input: UpdatePreferencesInput;
};


export type MutationUpdatePriceListArgs = {
  input: UpdatePriceListInput;
};


export type MutationUpdateRecurringTaskArgs = {
  input: UpdateRecurringTaskInput;
};


export type MutationUpdateReferralArgs = {
  input: UpdateReferralInput;
};


export type MutationUpdateReferralSourceArgs = {
  input: UpdateReferralSourceInput;
};


export type MutationUpdateReportsSavedReportArgs = {
  input: UpdateReportsSavedReportInput;
};


export type MutationUpdateReviewTemplateArgs = {
  input: UpdateReviewTemplateInput;
};


export type MutationUpdateRichTextArgs = {
  input: UpdateRichTextInput;
};


export type MutationUpdateRoomArgs = {
  input: UpdateRoomInput;
};


export type MutationUpdateShiftTypeArgs = {
  input: UpdateShiftInput;
};


export type MutationUpdateSnippetArgs = {
  input: UpdateSnippetInput;
};


export type MutationUpdateStaffArgs = {
  input: UpdateStaffInput;
};


export type MutationUpdateStateInvoiceArgs = {
  input: UpdateStateInvoiceInput;
};


export type MutationUpdateStatisticArgs = {
  input: UpdateStatisticInput;
};


export type MutationUpdateStatisticsArgs = {
  input: UpdateStatisticsInput;
};


export type MutationUpdateSterilisationAutoclaveArgs = {
  input: UpdateSterilisationAutoclaveInput;
};


export type MutationUpdateSterilisationCycleArgs = {
  input: UpdateSterilisationCycleInput;
};


export type MutationUpdateSterilisationCycleTypeArgs = {
  input: UpdateSterilisationCycleTypeInput;
};


export type MutationUpdateSterilisationTrackingArgs = {
  input: UpdateSterilisationTrackingInput;
};


export type MutationUpdateStripePaymentProviderArgs = {
  input: UpdateStripeProviderInput;
};


export type MutationUpdateTaskArgs = {
  input: UpdateTaskInput;
};


export type MutationUpdateTemplateArgs = {
  input: UpdateTemplateInput;
};


export type MutationUpdateTreatmentArgs = {
  input: UpdateTreatmentInput;
};


export type MutationUpdateTreatmentPlanArgs = {
  input: UpdateTreatmentPlanInput;
};


export type MutationUpdateTreatmentsPresetArgs = {
  input: UpdateTreatmentsPresetInput;
};


export type MutationUpdateTreatmentsPresetItemArgs = {
  input: UpdateTreatmentsPresetItemInput;
};


export type MutationUpdateTyroPaymentProviderArgs = {
  input: UpdateTyroProviderInput;
};


export type MutationUpdateTyroTerminalArgs = {
  input: UpdateTyroTerminalInput;
};


export type MutationUpdateUserAccountCreditArgs = {
  input: AdjustAccountCreditInput;
};


export type MutationUpdateUserContactArgs = {
  input: UpdateUserContactInput;
};


export type MutationUpdateWaitlistArgs = {
  input: UpdateWaitlistInput;
};


export type MutationUpdateWorkflowArgs = {
  input: UpdateWorkflowInput;
};


export type MutationUploadAttachmentsArgs = {
  input: UploadAttachmentsInput;
};


export type MutationUploadAvatarArgs = {
  input: UpdateAvatarInput;
};


export type MutationUploadImagesArgs = {
  input: UploadImageInput;
};


export type MutationValidateFormSectionArgs = {
  input: ValidateSectionInput;
};


export type MutationVerifyInvitationArgs = {
  input: VerifyInvitationInput;
};


export type MutationVerifyOtpArgs = {
  input: VerifyOtpInput;
};

export enum NewPatientStatus {
  Active = 'active',
  Pending = 'pending'
}

/**
 * Notes are a simple polymorphic record of HTML or plain text\nthat can be added
 * to any other object in the system. In theory,\nyou could even add notes to a
 * note, although message-threads are not\nyet implemented because of the
 * complexity of recursive joins vs the speed\nof such N+1 queries\nA note is
 * always linked to a Practitioner (who authored the note), and often\na Patient
 * whom the note concerns. Patients are optional on Notes, because\nnot all notes
 * may concern patients, e.g if you add a note to a Template, or\nany other system
 * record.\nNotes move through a sequence of states: Draft, Published, and
 * Finalized, where\na finalized note cannot be edited again. Notes don't *have* to
 * be finalized,\nbut by law clinical notes do, as they must prevent being tampered
 * with.\nNotes can be of different types (General, ClinicalBilling,
 * ClinicalDental, ClinicalTreatment, Internal, FollowUp)\nand it is up to the
 * author to set the correct note type.\nIf the note is flagged as sticky, that
 * note will be available on the patient's\nsticky_notes field, and displayed on
 * the patients profile. There is no current limit\nto how many notes can be marked
 * as sticky, but bear in mind that it can become messy\nto have too many sticky
 * notes show up on a patients profile. Notes of any type can be\nflagged as
 * sticky. If a note is flagged as sticky but not linked to any patient,\nthe flag
 * will have no effect.\n**Important:** Notes of any clinical type will be
 * automatically finalized and locked at\nthe end of the day, whereas other notes can be edited.
 */
export type Note = {
  __typename?: 'Note';
  attachments?: Maybe<Array<Attachment>>;
  /** The practitioner that authored the note, Same as practice_professional but instead going through upc now. */
  author?: Maybe<UserPracticeLink>;
  /** Whether this note is an auto note generated from a treatment plan item being completed */
  autoNote?: Maybe<Scalars['Boolean']['output']>;
  content?: Maybe<Scalars['Base64Brotli']['output']>;
  createdAt: Scalars['ISO8601DateTime']['output'];
  discardedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  guid: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  metadata?: Maybe<NoteMetadata>;
  notable?: Maybe<AnyNode>;
  /** @deprecated Avoid using notable_type/notable_id directly and instead spread fragments on .notable */
  notableId?: Maybe<Scalars['ID']['output']>;
  /** @deprecated Avoid using notable_type/notable_id directly and instead spread fragments on .notable */
  notableType?: Maybe<Scalars['String']['output']>;
  notableUuid?: Maybe<Scalars['String']['output']>;
  noteType?: Maybe<NoteTypeTranslation>;
  /** Patient linked to this note */
  patient?: Maybe<UserPracticeLink>;
  practice?: Maybe<Practice>;
  /** @deprecated PracticeProfessional is deprecated, use Practitioner */
  practiceProfessional?: Maybe<PracticeProfessional>;
  slug?: Maybe<Scalars['String']['output']>;
  state?: Maybe<NoteState>;
  /** Whether or not this Note is sticky on the patienst profile */
  sticky?: Maybe<Scalars['Boolean']['output']>;
  updatedAt: Scalars['ISO8601DateTime']['output'];
  /** The practitioner that updated the note last */
  updatedBy?: Maybe<UserPracticeLink>;
  uuid?: Maybe<Scalars['String']['output']>;
};

/** The connection type for Note. */
export type NoteConnection = {
  __typename?: 'NoteConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<NoteEdge>>>;
  /** IDs of ALL results, regardless of pagination */
  ids: Array<Scalars['ID']['output']>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Note>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Returns total count of items in list */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type NoteEdge = {
  __typename?: 'NoteEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<Note>;
};

export type NoteInput = {
  /** Link attachments to this note */
  attachments?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** Text / HTML content of the note */
  content?: InputMaybe<Scalars['String']['input']>;
  /** Type of note content, e.g Clinical, General, Treatment */
  noteType?: InputMaybe<NoteType>;
  /** Assign this note to a patient */
  patient?: InputMaybe<Scalars['ID']['input']>;
  /** Manually set the state of the Note */
  state?: InputMaybe<NoteState>;
  /** Toggle this note as Sticky on the patient */
  sticky?: InputMaybe<Scalars['Boolean']['input']>;
  /** GraphQL UUID (base64(ClassName:ID)) of the object to attach the note to */
  uuid?: InputMaybe<Scalars['String']['input']>;
};

export type NoteMetadata = {
  __typename?: 'NoteMetadata';
  fdi?: Maybe<Scalars['String']['output']>;
  itemCost?: Maybe<Scalars['Float']['output']>;
  noteTitle?: Maybe<Scalars['String']['output']>;
  surfaceCode?: Maybe<Scalars['String']['output']>;
};

export enum NoteState {
  Archived = 'archived',
  Draft = 'draft',
  Finalized = 'finalized',
  Initialized = 'initialized',
  Published = 'published'
}

export enum NoteStateEvent {
  Archive = 'archive',
  Finalize = 'finalize',
  Initialized = 'initialized',
  Publish = 'publish',
  Unarchive = 'unarchive',
  Unpublish = 'unpublish'
}

export enum NoteType {
  /** Clinical Billing */
  ClinicalBilling = 'clinical_billing',
  /** Clinical Dental */
  ClinicalDental = 'clinical_dental',
  /** Clinical Treatment */
  ClinicalTreatment = 'clinical_treatment',
  /** Follow Up */
  FollowUp = 'follow_up',
  /** General */
  General = 'general',
  /** Internal */
  Internal = 'internal'
}

export type NoteTypeTranslation = {
  __typename?: 'NoteTypeTranslation';
  description?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  type?: Maybe<NoteType>;
};

export type OnboardingInviteStaffInput = {
  skipInvitation: Scalars['Boolean']['input'];
  users: Array<OnboardingStaffInput>;
};

export type OnboardingStaffInput = {
  calendarAvailability: Scalars['Boolean']['input'];
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  role: Scalars['String']['input'];
};

export type OnlineBookingSetting = {
  __typename?: 'OnlineBookingSetting';
  account: Account;
  /** Account GUID */
  accountGuid: Scalars['ID']['output'];
  allowDismissableBookingMessage?: Maybe<Scalars['Boolean']['output']>;
  allowPendingPatients?: Maybe<Scalars['Boolean']['output']>;
  bookingInterval?: Maybe<Scalars['Int']['output']>;
  bookingMessageContent?: Maybe<Scalars['String']['output']>;
  bookingMessageTitle?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['ISO8601DateTime']['output'];
  defaultNewPatientState?: Maybe<NewPatientStatus>;
  emailNotifications?: Maybe<Scalars['Boolean']['output']>;
  enabled?: Maybe<Scalars['Boolean']['output']>;
  guid: Scalars['ID']['output'];
  howFarCanBookFrequency?: Maybe<Scalars['String']['output']>;
  howFarCanBookPeriod?: Maybe<Scalars['Int']['output']>;
  id: Scalars['String']['output'];
  leadTimeToBookFrequency?: Maybe<Scalars['String']['output']>;
  leadTimeToBookPeriod?: Maybe<Scalars['Int']['output']>;
  minCancellationNoticeFrequency?: Maybe<Scalars['String']['output']>;
  minCancellationNoticePeriod?: Maybe<Scalars['Int']['output']>;
  /** The email address to redirect online bookings to for staff only */
  onlineBookingsEmail?: Maybe<Scalars['String']['output']>;
  /** The mobile number to send online bookings to for staff only */
  onlineBookingsMobileNumber?: Maybe<Scalars['String']['output']>;
  practice?: Maybe<Practice>;
  /** Practice GUID */
  practiceGuid: Scalars['ID']['output'];
  /** Practice ID */
  practiceId: Scalars['ID']['output'];
  practiceLocation?: Maybe<PracticeLocation>;
  /** Practice Location ID */
  practiceLocationId?: Maybe<Scalars['ID']['output']>;
  /** Whether or not to override the online bookings notifications */
  redirectOnlineBookingsNotifications?: Maybe<Scalars['Boolean']['output']>;
  requireDeposit?: Maybe<Scalars['Boolean']['output']>;
  requirePatientAddress?: Maybe<Scalars['Boolean']['output']>;
  smsNotifications?: Maybe<Scalars['Boolean']['output']>;
  successUrl?: Maybe<Scalars['String']['output']>;
  termsAndConditions?: Maybe<Scalars['String']['output']>;
  themeColor?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

/** The connection type for OnlineBookingSetting. */
export type OnlineBookingSettingConnection = {
  __typename?: 'OnlineBookingSettingConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<OnlineBookingSettingEdge>>>;
  /** IDs of ALL results, regardless of pagination */
  ids: Array<Scalars['ID']['output']>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<OnlineBookingSetting>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Returns total count of items in list */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type OnlineBookingSettingEdge = {
  __typename?: 'OnlineBookingSettingEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<OnlineBookingSetting>;
};

export type OnlineBookingSettingsInput = {
  allowDismissableBookingMessage?: InputMaybe<Scalars['Boolean']['input']>;
  /** Allow pending patients to book appointments from online bookings */
  allowPendingPatients?: InputMaybe<Scalars['Boolean']['input']>;
  /** The booking interval in minutes that online bookings will display timeslots */
  bookingInterval?: InputMaybe<Scalars['Int']['input']>;
  bookingMessageContent?: InputMaybe<Scalars['String']['input']>;
  bookingMessageTitle?: InputMaybe<Scalars['String']['input']>;
  /** The default state for new patients that book online */
  defaultNewPatientState?: InputMaybe<NewPatientStatus>;
  /** Enable online bookings for this practice. The online bookings app with fetch this practice */
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  /** The frequency of in the future the practice allows bookings. Hours, Days, Weeks, Months */
  howFarCanBookFrequency?: InputMaybe<Scalars['String']['input']>;
  /** How far in the future the practice allows booking an appointment */
  howFarCanBookPeriod?: InputMaybe<Scalars['Int']['input']>;
  /** The frequency of the lead time to book period. Hours, Days, Weeks, Months */
  leadTimeToBookFrequency?: InputMaybe<Scalars['String']['input']>;
  /** The period of time before the appointment that the patient can book the appointment */
  leadTimeToBookPeriod?: InputMaybe<Scalars['Int']['input']>;
  onlineBookingSetting?: InputMaybe<Scalars['ID']['input']>;
  /** The email address to redirect online bookings to for staff only */
  onlineBookingsEmail?: InputMaybe<Scalars['String']['input']>;
  /** The mobile number to send online bookings to for staff only */
  onlineBookingsMobileNumber?: InputMaybe<Scalars['String']['input']>;
  /** Redirect emails and sms to the nominated email/number */
  redirectOnlineBookingsNotifications?: InputMaybe<Scalars['Boolean']['input']>;
  /** The url to redirect to after a successful booking for tracking */
  successUrl?: InputMaybe<Scalars['String']['input']>;
  /** Terms and conditions for online bookings to be displayed in the online bookings app */
  termsAndConditions?: InputMaybe<Scalars['String']['input']>;
  /** The main colour theme applied to online bookings app */
  themeColor?: InputMaybe<Scalars['String']['input']>;
};

export enum OrderCustomerRequests {
  CreatedAtAsc = 'created_at_asc',
  CreatedAtDesc = 'created_at_desc',
  ExpireAtAsc = 'expire_at_asc',
  ExpireAtDesc = 'expire_at_desc'
}

export enum OrderLabWorks {
  CostAsc = 'cost_asc',
  CostDesc = 'cost_desc',
  CreatedAtAsc = 'created_at_asc',
  CreatedAtDesc = 'created_at_desc',
  DueAtAsc = 'due_at_asc',
  DueAtDesc = 'due_at_desc'
}

export enum OrderTreatmentsPreset {
  NameAsc = 'name_asc',
  NameDesc = 'name_desc'
}

export type Otp = {
  __typename?: 'Otp';
  id: Scalars['ID']['output'];
  otpMethod?: Maybe<OtpMethod>;
  /** Email or phone number of the receiver */
  receiver?: Maybe<Scalars['String']['output']>;
};

export type OtpAttributes = {
  /** Can be used instead of UPC slug */
  email?: InputMaybe<Scalars['String']['input']>;
  /** UPC slug */
  identifier?: InputMaybe<Scalars['String']['input']>;
  otpMethod: OtpMethod;
  /**
   * A second request containing the subdomain is required if the user has more
   * than one UPC. This is for setting a secure samesite cookie, which is dependent on domain
   */
  subdomain?: InputMaybe<Scalars['String']['input']>;
  upc?: InputMaybe<Scalars['ID']['input']>;
  /** Find the user by username and use last used UPC */
  username?: InputMaybe<Scalars['String']['input']>;
};

export enum OtpMethod {
  Email = 'email',
  Sms = 'sms'
}

export type OutstandingInvoiceDetailedList = {
  __typename?: 'OutstandingInvoiceDetailedList';
  displayBalance?: Maybe<Scalars['Float']['output']>;
  displayInvoiceTotal?: Maybe<Scalars['Float']['output']>;
  displayIssuedAt?: Maybe<Scalars['String']['output']>;
  displayPaidTotal?: Maybe<Scalars['Float']['output']>;
  displayState?: Maybe<Scalars['String']['output']>;
  displayTaxTotal?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  invoiceNumber?: Maybe<Scalars['ID']['output']>;
  patientGuid?: Maybe<Scalars['ID']['output']>;
  patientName?: Maybe<Scalars['String']['output']>;
  patientRecordId?: Maybe<Scalars['ID']['output']>;
  practiceId?: Maybe<Scalars['ID']['output']>;
  practiceName?: Maybe<Scalars['String']['output']>;
};

export type OutstandingInvoiceSummaryList = {
  __typename?: 'OutstandingInvoiceSummaryList';
  amount?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  invoiceNumber?: Maybe<Scalars['ID']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  tax?: Maybe<Scalars['Float']['output']>;
};

/** Information about pagination in a connection. */
export type PageInfo = {
  __typename?: 'PageInfo';
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['String']['output']>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean']['output'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean']['output'];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of ParseContactsImport */
export type ParseContactsImportInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Base64 encoded csv file with contacts */
  file: Scalars['Upload']['input'];
  practiceLocationId: Scalars['ID']['input'];
};

/** Autogenerated return type of ParseContactsImport. */
export type ParseContactsImportPayload = {
  __typename?: 'ParseContactsImportPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  contactImportFile?: Maybe<ImportFile>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  fieldErrors?: Maybe<Array<FieldError>>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

/** Autogenerated input type of ParsePatientsImport */
export type ParsePatientsImportInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Base64 encoded csv file with patients */
  file: Scalars['Upload']['input'];
  practiceLocationId: Scalars['ID']['input'];
};

/** Autogenerated return type of ParsePatientsImport. */
export type ParsePatientsImportPayload = {
  __typename?: 'ParsePatientsImportPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  fieldErrors?: Maybe<Array<FieldError>>;
  patientImportFile?: Maybe<ImportFile>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

/** A participant in a conversation, either a practice or a UPC */
export type Participant = Practice | UserPracticeLink;

export type Patient = {
  __typename?: 'Patient';
  createdAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  dob?: Maybe<Scalars['ISO8601Date']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  forms?: Maybe<Array<Form>>;
  gender?: Maybe<Scalars['String']['output']>;
  homePhone?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  lastName?: Maybe<Scalars['String']['output']>;
  mobilePhone?: Maybe<Scalars['String']['output']>;
  mobileRegionCode?: Maybe<Scalars['String']['output']>;
  mobileRegionShort?: Maybe<Scalars['String']['output']>;
  occupation?: Maybe<Scalars['String']['output']>;
  patientRecords?: Maybe<Array<PatientRecord>>;
  recalls?: Maybe<Array<Recall>>;
  slug?: Maybe<Scalars['String']['output']>;
  treatments?: Maybe<Array<Treatment>>;
  updatedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  userPracticeConnection?: Maybe<UserPracticeLink>;
  /** Used to retrieve UserPracticeConnection from Patient serializer */
  userPracticeConnectionId?: Maybe<Scalars['ID']['output']>;
  workPhone?: Maybe<Scalars['String']['output']>;
};


export type PatientRecallsArgs = {
  state?: InputMaybe<RecallState>;
};

export enum PatientAppConfigState {
  Active = 'active',
  Blocked = 'blocked',
  Inactive = 'inactive',
  Invited = 'invited'
}

export type PatientAppConfiguration = {
  __typename?: 'PatientAppConfiguration';
  account?: Maybe<AccountPublic>;
  createdAt: Scalars['ISO8601DateTime']['output'];
  id: Scalars['ID']['output'];
  /** Code to enter to join (part of the URL) */
  inviteCode?: Maybe<Scalars['String']['output']>;
  /** URL to accept invite */
  inviteUrl?: Maybe<Scalars['String']['output']>;
  lastLoginAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  /** Patients linked to the user on the same account */
  patients?: Maybe<Array<UserPracticeLink>>;
  practice?: Maybe<Practice>;
  practiceGuid?: Maybe<Scalars['String']['output']>;
  state: PatientAppConfigState;
  /** History of state changes and who made them */
  stateHistory?: Maybe<Array<ColumnHistory>>;
  updatedAt: Scalars['ISO8601DateTime']['output'];
  user?: Maybe<User>;
  username?: Maybe<Scalars['String']['output']>;
};

export type PatientAppInvite = {
  __typename?: 'PatientAppInvite';
  account?: Maybe<AccountPublic>;
  createdAt: Scalars['ISO8601DateTime']['output'];
  id: Scalars['ID']['output'];
  /** Code to enter to join (part of the URL) */
  inviteCode?: Maybe<Scalars['String']['output']>;
  /** URL to accept invite */
  inviteUrl?: Maybe<Scalars['String']['output']>;
  lastLoginAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  practiceGuid?: Maybe<Scalars['String']['output']>;
  state: PatientAppConfigState;
  updatedAt: Scalars['ISO8601DateTime']['output'];
  username?: Maybe<Scalars['String']['output']>;
};

export enum PatientAppStateEvent {
  Activate = 'activate',
  Block = 'block',
  Deactivate = 'deactivate'
}

export enum PatientFieldLink {
  /** Private healthcare number */
  DefaultPrivateHealthFundPrimaryIdentifier = 'default_private_health_fund_primary_identifier',
  /** Private Healthcare Provider */
  DefaultPrivateHealthFundProviderGuid = 'default_private_health_fund_provider_guid',
  /** Private Healthcare Reference */
  DefaultPrivateHealthFundSecondaryIdentifier = 'default_private_health_fund_secondary_identifier',
  /** Public Health Number */
  DefaultPublicHealthFundPrimaryIdentifier = 'default_public_health_fund_primary_identifier',
  /** Public Healthcare Provider */
  DefaultPublicHealthFundProviderGuid = 'default_public_health_fund_provider_guid',
  /** Public Healthcare Reference */
  DefaultPublicHealthFundSecondaryIdentifier = 'default_public_health_fund_secondary_identifier',
  /** Emergency Contact Contact Number */
  EmergencyContactContactNumber = 'emergency_contact_contact_number',
  /** Emergency Contact First Name */
  EmergencyContactFirstName = 'emergency_contact_first_name',
  /** Emergency Contact Last Name */
  EmergencyContactLastName = 'emergency_contact_last_name',
  /** Emergency Contact Relationship */
  EmergencyContactRelationship = 'emergency_contact_relationship',
  /** Emergency Contact Title */
  EmergencyContactTitle = 'emergency_contact_title',
  /** Address Line1 */
  HomeLocationAddressLine1 = 'home_location_address_line1',
  /** Address Line2 */
  HomeLocationAddressLine2 = 'home_location_address_line2',
  /** Address Line3 */
  HomeLocationAddressLine3 = 'home_location_address_line3',
  /** City */
  HomeLocationCity = 'home_location_city',
  /** Country */
  HomeLocationCountry = 'home_location_country',
  /** Postcode */
  HomeLocationPostcode = 'home_location_postcode',
  /** State */
  HomeLocationState = 'home_location_state',
  /** Date of Birth */
  ProfileDateOfBirth = 'profile_date_of_birth',
  /** Email */
  ProfileEmail = 'profile_email',
  /** First name */
  ProfileFirstName = 'profile_first_name',
  /** Gender */
  ProfileGender = 'profile_gender',
  /** Home Phone */
  ProfileHomePhone = 'profile_home_phone',
  /** Last name */
  ProfileLastName = 'profile_last_name',
  /** Middle name */
  ProfileMiddleName = 'profile_middle_name',
  /** Mobile number */
  ProfileMobilePhone = 'profile_mobile_phone',
  /** Nationality */
  ProfileNationality = 'profile_nationality',
  /** occupation */
  ProfileOccupation = 'profile_occupation',
  /** Preferred name */
  ProfilePreferredName = 'profile_preferred_name',
  /** Title */
  ProfileTitle = 'profile_title',
  /** Work phone */
  ProfileWorkPhone = 'profile_work_phone'
}

export type PatientRecord = {
  __typename?: 'PatientRecord';
  accountCredit?: Maybe<Scalars['Int']['output']>;
  createdAt: Scalars['ISO8601DateTime']['output'];
  email?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  fullName?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  invoices?: Maybe<InvoiceConnection>;
  lastName?: Maybe<Scalars['String']['output']>;
  mobilePhone?: Maybe<Scalars['String']['output']>;
  patient?: Maybe<Patient>;
  practice?: Maybe<Practice>;
  state: PatientRecordState;
  stickyNote?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['ISO8601DateTime']['output'];
};


export type PatientRecordInvoicesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type PatientRecordCredit = {
  __typename?: 'PatientRecordCredit';
  amount?: Maybe<Scalars['Float']['output']>;
  creditableId?: Maybe<Scalars['ID']['output']>;
  creditableSlug?: Maybe<Scalars['String']['output']>;
  currentBalance?: Maybe<Scalars['Float']['output']>;
  date?: Maybe<Scalars['ISO8601Date']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  invoiceTransaction?: Maybe<InvoiceTransaction>;
  /** The ID of the invoice transaction that processed the payment */
  invoiceTransactionId?: Maybe<Scalars['ID']['output']>;
  patient?: Maybe<UserPracticeLink>;
  /** The ID of the patient that processed the payment */
  patientId?: Maybe<Scalars['ID']['output']>;
  /** The ID of the payment transaction that processed the payment */
  paymentTransactionId?: Maybe<Scalars['ID']['output']>;
  practitioner?: Maybe<UserPracticeLink>;
  /** The ID of the practitioner that processed the payment */
  practitionerId?: Maybe<Scalars['ID']['output']>;
  receiptUrl?: Maybe<Scalars['String']['output']>;
};

/** The connection type for PatientRecordCredit. */
export type PatientRecordCreditConnection = {
  __typename?: 'PatientRecordCreditConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<PatientRecordCreditEdge>>>;
  /** IDs of ALL results, regardless of pagination */
  ids: Array<Scalars['ID']['output']>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<PatientRecordCredit>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Returns total count of items in list */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type PatientRecordCreditEdge = {
  __typename?: 'PatientRecordCreditEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<PatientRecordCredit>;
};

/**
 * PatientRecordItems are links between a PatientRecord (now
 * UserPracticeConnection)\nand a CatalogueItem, which is a product or service.
 * This link is created before\nan item is added to an invoice, and is used to
 * track "suggested" or "prepared" items\nfor a patient.\nWhen an appointment,
 * recall, or treatment is created or updated, practitioners can add\nServices or
 * Products to these and this link is created as a PatientRecordItem. These
 * are\nthen displayed on the invoice builder page, where the practitioner can
 * choose to select\nitems to add to an invoice.\nThe difference between a
 * PatientRecordItem and an InvoiceItem, is that a PatientRecordItem\nis not yet
 * invoiced and thus not linked to an invoice — they are essentially
 * "dangling"\nInvoiceItems ready to be added to an invoice later.\nWhen you think
 * of it like this, they could really have been created as InvoiceItems without\nan
 * Invoice link, and been linked to an Invoice later, but that's not how it's been implemented.
 */
export type PatientRecordItem = {
  __typename?: 'PatientRecordItem';
  appointment?: Maybe<Appointment>;
  /** The ID of the linked appointment */
  appointmentId?: Maybe<Scalars['ID']['output']>;
  balance?: Maybe<Scalars['Float']['output']>;
  catalogueItem?: Maybe<CatalogueItem>;
  /** The code of the item */
  code?: Maybe<Scalars['String']['output']>;
  /** The date which the item was created */
  createdAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  /** The date which the item was marked as declined */
  declinedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  dentalToothTreatment?: Maybe<DentalToothTreatment>;
  /** The description of the item */
  description?: Maybe<Scalars['String']['output']>;
  /** The date which the item was discarded (soft delete) */
  discardedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  /** Discount percentage */
  discount?: Maybe<Scalars['Float']['output']>;
  /** FDI code */
  fdi?: Maybe<Scalars['Int']['output']>;
  guid: Scalars['ID']['output'];
  /** The amount sent to the health fund to be claimed */
  healthFundAmount?: Maybe<Scalars['Float']['output']>;
  /** Additional health fund attributes */
  healthFundAttributes?: Maybe<HealthFundsClaimItemAttributes>;
  /** The total out of pocket that the patient now needs to pay */
  healthFundGap?: Maybe<Scalars['Float']['output']>;
  /** The healthfund gateway */
  healthFundGateway?: Maybe<HealthFundsGateway>;
  /** The most recent claim item attached to this record */
  healthFundItem?: Maybe<HealthFundsClaimItem>;
  /** The most recent claim item attached to this record */
  healthFundItemId?: Maybe<Scalars['ID']['output']>;
  healthFundItemNumber?: Maybe<Scalars['String']['output']>;
  healthFundItemNumberWithModality?: Maybe<Scalars['String']['output']>;
  /** Service modality */
  healthFundModality?: Maybe<CatalogueItemModality>;
  /** Service modality code */
  healthFundModalityCode?: Maybe<Scalars['String']['output']>;
  /** Name of the health fund that processed the transaction */
  healthFundName?: Maybe<Scalars['String']['output']>;
  /** Reference of the patient. Normally this is their reference on their health fund card. e.g 01, 02 etc */
  healthFundPatientRef?: Maybe<Scalars['String']['output']>;
  /** Reference of the provider ID used for this item. */
  healthFundProviderRef?: Maybe<Scalars['String']['output']>;
  /** The amount that the healthfund will be rebated */
  healthFundRebate?: Maybe<Scalars['Float']['output']>;
  /** Date/Time of the last request to the healthfund */
  healthFundRequestAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  /** The request type */
  healthFundRequestType?: Maybe<HealthFundsRequestType>;
  /** Healthfund response code */
  healthFundResponseCode?: Maybe<Scalars['String']['output']>;
  /** health fund response message */
  healthFundResponseMessage?: Maybe<Scalars['String']['output']>;
  /** The date for this item to be claimed */
  healthFundServiceDate?: Maybe<Scalars['ISO8601Date']['output']>;
  /** Reference of the service to be claimed. For dental this can be the FDI code of the tooth. */
  healthFundServiceRef?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  invoice?: Maybe<Invoice>;
  invoiceId?: Maybe<Scalars['ID']['output']>;
  invoiceItem?: Maybe<InvoiceItem>;
  /** The date which the item was invoiced */
  invoicedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  minutes?: Maybe<Scalars['Int']['output']>;
  modality?: Maybe<Scalars['String']['output']>;
  /** The name of the item */
  name?: Maybe<Scalars['String']['output']>;
  note?: Maybe<Note>;
  paidTotal?: Maybe<Scalars['Float']['output']>;
  /** Alias of user_practice_connection (which is the patient) */
  patient?: Maybe<UserPracticeLink>;
  /**
   * The ID of the patient
   * @deprecated Use user_practice_connection instead
   */
  patientRecordId?: Maybe<Scalars['ID']['output']>;
  policies?: Maybe<PatientRecordItemPolicy>;
  position?: Maybe<Scalars['Int']['output']>;
  /**
   * The ID of the practice professional (practitioner)
   * @deprecated Use practitioner instead
   */
  practiceProfessionalId?: Maybe<Scalars['ID']['output']>;
  /** Practitioner that is assigned to this item */
  practitioner?: Maybe<UserPracticeLink>;
  /** The ID of the practitioner */
  practitionerId?: Maybe<Scalars['ID']['output']>;
  /** Base price per item ex tax */
  price?: Maybe<Scalars['Float']['output']>;
  /** Singale price excluding tax */
  priceExTax?: Maybe<Scalars['Float']['output']>;
  /** Single price including tax */
  priceIncTax?: Maybe<Scalars['Float']['output']>;
  /** The ID of the price list that was originally used to create this item */
  priceListId?: Maybe<Scalars['ID']['output']>;
  publicHealthCode?: Maybe<Scalars['String']['output']>;
  /**
   * Number of items
   * @deprecated We duplicate the PatientRecordItem instead of adding a quantity field
   */
  quantity?: Maybe<Scalars['Int']['output']>;
  /** The date which the item was marked as completed */
  readyToInvoiceAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  state?: Maybe<PatientRecordItemState>;
  /** Surface code for the surface */
  surfaceCode?: Maybe<Scalars['String']['output']>;
  /** Tax percentage */
  tax?: Maybe<Scalars['Float']['output']>;
  /** Total price including tax */
  total?: Maybe<Scalars['Float']['output']>;
  /** Total discount applied */
  totalDiscount?: Maybe<Scalars['Float']['output']>;
  /** Total price excluding tax */
  totalExTax?: Maybe<Scalars['Float']['output']>;
  /** Total tax applied */
  totalTax?: Maybe<Scalars['Float']['output']>;
  treatment?: Maybe<Treatment>;
  /** The ID of the treatment */
  treatmentId?: Maybe<Scalars['ID']['output']>;
  treatmentPlan?: Maybe<TreatmentPlan>;
  /** The ID of the treatment plan */
  treatmentPlanId?: Maybe<Scalars['ID']['output']>;
  /** The date which the item was last updated */
  updatedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  userPracticeConnection?: Maybe<UserPracticeLink>;
  userPracticeConnectionId?: Maybe<Scalars['ID']['output']>;
  uuid?: Maybe<Scalars['String']['output']>;
};

/** The connection type for PatientRecordItem. */
export type PatientRecordItemConnection = {
  __typename?: 'PatientRecordItemConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<PatientRecordItemEdge>>>;
  /** IDs of ALL results, regardless of pagination */
  ids: Array<Scalars['ID']['output']>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<PatientRecordItem>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Returns total count of items in list */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type PatientRecordItemEdge = {
  __typename?: 'PatientRecordItemEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<PatientRecordItem>;
};

export enum PatientRecordItemEvent {
  Decline = 'decline',
  Incomplete = 'incomplete',
  MarkAsInvoiced = 'mark_as_invoiced',
  ReadyToInvoice = 'ready_to_invoice'
}

export type PatientRecordItemInput = {
  /** Id of appointment */
  appointmentId?: InputMaybe<Scalars['ID']['input']>;
  /** Catalogue Item Id */
  catalogueItemId?: InputMaybe<Scalars['ID']['input']>;
  /** Discount % */
  discount?: InputMaybe<Scalars['Int']['input']>;
  /** Transition state */
  event?: InputMaybe<PatientRecordItemStateEvent>;
  /** FDI */
  fdi?: InputMaybe<Scalars['Int']['input']>;
  /** Additional Healthfund attributes */
  healthFundAttributes?: InputMaybe<HealthFundsClaimItemAttributesInput>;
  /** Healthfund patient ref */
  healthFundPatientRef?: InputMaybe<Scalars['String']['input']>;
  /** Healthfund service date */
  healthFundServiceDate?: InputMaybe<Scalars['ISO8601Date']['input']>;
  /** Healthfund service ref */
  healthFundServiceRef?: InputMaybe<Scalars['String']['input']>;
  /** Minutes */
  minutes?: InputMaybe<Scalars['Int']['input']>;
  /** Id of patient to attach record to. This is the UPC object */
  ownerId?: InputMaybe<Scalars['ID']['input']>;
  /** Position in appointment */
  position?: InputMaybe<Scalars['Int']['input']>;
  /** Id of practice professional */
  practiceProfessionalId?: InputMaybe<Scalars['ID']['input']>;
  /** Id of practitioner (UserPracticeConnection) */
  practitionerId?: InputMaybe<Scalars['ID']['input']>;
  /** Price */
  price?: InputMaybe<Scalars['Float']['input']>;
  /** Price inc tax */
  priceIncTax?: InputMaybe<Scalars['Float']['input']>;
  /** Id of price list */
  priceListId?: InputMaybe<Scalars['ID']['input']>;
  /** Surface code */
  surfaceCode?: InputMaybe<Scalars['String']['input']>;
  /** Tax */
  tax?: InputMaybe<Scalars['Float']['input']>;
  /** Discount $ */
  totalDiscount?: InputMaybe<Scalars['Float']['input']>;
  /** Id of treatment */
  treatmentId?: InputMaybe<Scalars['ID']['input']>;
  /** Id of treatment plan */
  treatmentPlanId?: InputMaybe<Scalars['ID']['input']>;
};

export type PatientRecordItemPolicy = {
  __typename?: 'PatientRecordItemPolicy';
  archive?: Maybe<Scalars['Boolean']['output']>;
  create?: Maybe<Scalars['Boolean']['output']>;
  decline?: Maybe<Scalars['Boolean']['output']>;
  destroy?: Maybe<Scalars['Boolean']['output']>;
  edit?: Maybe<Scalars['Boolean']['output']>;
  incomplete?: Maybe<Scalars['Boolean']['output']>;
  index?: Maybe<Scalars['Boolean']['output']>;
  markAsInvoiced?: Maybe<Scalars['Boolean']['output']>;
  new?: Maybe<Scalars['Boolean']['output']>;
  show?: Maybe<Scalars['Boolean']['output']>;
  update?: Maybe<Scalars['Boolean']['output']>;
};

export enum PatientRecordItemState {
  Declined = 'declined',
  Invoiced = 'invoiced',
  Proposed = 'proposed',
  ReadyToInvoice = 'ready_to_invoice'
}

export enum PatientRecordItemStateEvent {
  /** Decline */
  Decline = 'decline',
  /** Incomplete */
  Incomplete = 'incomplete',
  /** Mark As Invoiced */
  MarkAsInvoiced = 'mark_as_invoiced',
  /** Ready To Invoice */
  ReadyToInvoice = 'ready_to_invoice'
}

export enum PatientRecordState {
  Active = 'active',
  Archived = 'archived',
  Blocked = 'blocked',
  Disabled = 'disabled',
  Inactive = 'inactive',
  Pending = 'pending'
}

export enum PatientTimestamps {
  FirstAppointmentTime = 'first_appointment_time',
  FirstVisitTime = 'first_visit_time',
  LastAppointmentTime = 'last_appointment_time',
  LastExamTime = 'last_exam_time',
  LastMedicalFormTime = 'last_medical_form_time',
  LastVisitTime = 'last_visit_time',
  LastXrayTime = 'last_xray_time',
  NextAppointmentTime = 'next_appointment_time',
  RecallCompletedTime = 'recall_completed_time',
  RecallDropOffTime = 'recall_drop_off_time',
  RecallExamDueTime = 'recall_exam_due_time',
  RecallVisitDueTime = 'recall_visit_due_time'
}

export type PatientUpdateInput = {
  /** The patient will be automatically get recalls generated they are due for a recall. */
  autoRecalls?: InputMaybe<Scalars['Boolean']['input']>;
  communicationSettings?: InputMaybe<CommunicationSettingsInput>;
  dateOfBirth?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  emergencyContactParams?: InputMaybe<EmergencyContactInput>;
  /** Expo Push Token to receive push notifications in the PatientApp */
  expoPushTokens?: InputMaybe<Array<Scalars['String']['input']>>;
  /**
   * The ID defined by the practice, could be the imported ID from another system
   * to assist with using other systems such as Sidexis
   */
  externalReference?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  gender?: InputMaybe<Gender>;
  healthFundIdentifiers?: InputMaybe<Array<HealthFundIdentifierInput>>;
  homeLocation?: InputMaybe<LocationInput>;
  homePhone?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  middleName?: InputMaybe<Scalars['String']['input']>;
  mobilePhone?: InputMaybe<PhoneInput>;
  practiceConnection?: InputMaybe<PracticeConnectionInput>;
  preferredName?: InputMaybe<Scalars['String']['input']>;
  preferredPhoneMethod?: InputMaybe<Scalars['String']['input']>;
  preferredWrittenMethod?: InputMaybe<Scalars['String']['input']>;
  /** The value in months that is used to calculate the recall_due_time on profile when appointment is completed */
  recallPeriod?: InputMaybe<Scalars['Int']['input']>;
  /** The default workflow that gets used to create recalls if auto_recalls is true */
  recallWorkflowId?: InputMaybe<Scalars['ID']['input']>;
  scanningIntegrations?: InputMaybe<ScanningIntegrationInput>;
  state?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<UserPracticeProfileTitle>;
  /** Toggle using the reference ID provided to integrate with other systems */
  useExternalReference?: InputMaybe<Scalars['Boolean']['input']>;
  /** Unique username the patient can log in with */
  username?: InputMaybe<Scalars['String']['input']>;
  workPhone?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated input type of PayInvoice */
export type PayInvoiceInput = {
  attributes?: InputMaybe<InvoicePaymentInput>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  invoiceId?: InputMaybe<Scalars['ID']['input']>;
};

/** Autogenerated return type of PayInvoice. */
export type PayInvoicePayload = {
  __typename?: 'PayInvoicePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  fieldErrors?: Maybe<Array<FieldError>>;
  invoice?: Maybe<Invoice>;
  success?: Maybe<Scalars['Boolean']['output']>;
  transactions?: Maybe<Array<InvoiceTransaction>>;
};

/** Autogenerated input type of Payment */
export type PaymentInput = {
  attributes: HealthFundsPaymentInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** GUID of the patient UPC to run a quote against */
  patient: Scalars['ID']['input'];
  /** GraphQL UUID (base64(ClassName:ID)) of the object to attach request to */
  requestable?: InputMaybe<Scalars['ID']['input']>;
};

export type PaymentIntent = {
  __typename?: 'PaymentIntent';
  amount?: Maybe<Scalars['Float']['output']>;
  amountCapturable?: Maybe<Scalars['String']['output']>;
  amountReceived?: Maybe<Scalars['Float']['output']>;
  application?: Maybe<Scalars['String']['output']>;
  applicationFeeAmount?: Maybe<Scalars['Float']['output']>;
  canceledAt?: Maybe<Scalars['String']['output']>;
  cancellationReason?: Maybe<Scalars['String']['output']>;
  captureMethod?: Maybe<Scalars['String']['output']>;
  charges?: Maybe<Scalars['String']['output']>;
  clientSecret?: Maybe<Scalars['String']['output']>;
  confirmationMethod?: Maybe<Scalars['String']['output']>;
  currency?: Maybe<Scalars['String']['output']>;
  customer?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  invoice?: Maybe<Scalars['String']['output']>;
  lastPaymentError?: Maybe<Scalars['String']['output']>;
  nextAction?: Maybe<Scalars['String']['output']>;
  obj?: Maybe<Scalars['String']['output']>;
  onBehalfOf?: Maybe<Scalars['String']['output']>;
  paymentMethod?: Maybe<Scalars['String']['output']>;
  receiptEmail?: Maybe<Scalars['String']['output']>;
  review?: Maybe<Scalars['String']['output']>;
  setupFutureUsage?: Maybe<Scalars['String']['output']>;
  shipping?: Maybe<Scalars['String']['output']>;
  statementDescriptor?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
};

export type PaymentIntentInput = {
  /** The UUID of the object to request a payment intent for */
  uuid: Scalars['String']['input'];
};

export type PaymentMethod = {
  __typename?: 'PaymentMethod';
  applyPatientSurcharge?: Maybe<Scalars['Boolean']['output']>;
  default?: Maybe<Scalars['Boolean']['output']>;
  discardedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  enableSurcharge?: Maybe<Scalars['Boolean']['output']>;
  enabled?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
  paymentProvider?: Maybe<PaymentProvider>;
  slug?: Maybe<Scalars['String']['output']>;
  surchargePercentage?: Maybe<Scalars['Float']['output']>;
};

export type PaymentMethodCard = {
  __typename?: 'PaymentMethodCard';
  brand?: Maybe<Scalars['String']['output']>;
  expMonth?: Maybe<Scalars['Int']['output']>;
  expYear?: Maybe<Scalars['Int']['output']>;
  last4?: Maybe<Scalars['String']['output']>;
};

export type PaymentMethodDetails = {
  __typename?: 'PaymentMethodDetails';
  card?: Maybe<PaymentMethodCard>;
};

export type PaymentMethodInput = {
  applyPatientSurcharge?: InputMaybe<Scalars['Boolean']['input']>;
  /** Whether or not this should be used as a default payment method */
  default?: InputMaybe<Scalars['Boolean']['input']>;
  enableSurcharge?: InputMaybe<Scalars['Boolean']['input']>;
  /** Enable/Disable payment provider */
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  /** ID of the payment method. Must be provided to update an existing method */
  id?: InputMaybe<Scalars['ID']['input']>;
  /** Name of the payment method */
  name?: InputMaybe<Scalars['String']['input']>;
  surchargePercentage?: InputMaybe<Scalars['Float']['input']>;
};

/** Autogenerated return type of Payment. */
export type PaymentPayload = {
  __typename?: 'PaymentPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  fieldErrors?: Maybe<Array<FieldError>>;
  healthFundsRequest?: Maybe<HealthFundsRequest>;
  invoice?: Maybe<Invoice>;
  invoiceClaimItems?: Maybe<Array<InvoiceItem>>;
  invoiceTransaction?: Maybe<InvoiceTransaction>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type PaymentProvider = {
  __typename?: 'PaymentProvider';
  applyPatientSurcharge?: Maybe<Scalars['Boolean']['output']>;
  /** Payment provider configuration */
  configuration?: Maybe<PaymentProviderConfiguration>;
  enabled?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ID']['output'];
  isDefault?: Maybe<Scalars['Boolean']['output']>;
  isHicaps?: Maybe<Scalars['Boolean']['output']>;
  isMedipass?: Maybe<Scalars['Boolean']['output']>;
  isStripe?: Maybe<Scalars['Boolean']['output']>;
  isTyro?: Maybe<Scalars['Boolean']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  paymentMethods?: Maybe<Array<PaymentMethod>>;
  paymentTerminals?: Maybe<Array<PaymentTerminal>>;
  practice?: Maybe<Practice>;
  /** Practice ID */
  practiceId: Scalars['ID']['output'];
  practiceLocation?: Maybe<PracticeLocation>;
  /** Practice Location ID */
  practiceLocationId?: Maybe<Scalars['ID']['output']>;
  type?: Maybe<Scalars['String']['output']>;
};

/** Payment Provider Configuration */
export type PaymentProviderConfiguration = PaymentProviderHicapsConfiguration | PaymentProviderManualConfiguration | PaymentProviderMedipassConfiguration | PaymentProviderStripeConfiguration | PaymentProviderTyroConfiguration;

/** The connection type for PaymentProvider. */
export type PaymentProviderConnection = {
  __typename?: 'PaymentProviderConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<PaymentProviderEdge>>>;
  /** IDs of ALL results, regardless of pagination */
  ids: Array<Scalars['ID']['output']>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<PaymentProvider>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Returns total count of items in list */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type PaymentProviderEdge = {
  __typename?: 'PaymentProviderEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<PaymentProvider>;
};

export type PaymentProviderHicapsConfiguration = {
  __typename?: 'PaymentProviderHicapsConfiguration';
  bridgeHost?: Maybe<Scalars['String']['output']>;
  https?: Maybe<Scalars['Boolean']['output']>;
};

export type PaymentProviderManualConfiguration = {
  __typename?: 'PaymentProviderManualConfiguration';
  type: Scalars['String']['output'];
};

export type PaymentProviderMedipassConfiguration = {
  __typename?: 'PaymentProviderMedipassConfiguration';
  apiKey?: Maybe<Scalars['String']['output']>;
  appId?: Maybe<Scalars['String']['output']>;
  authToken?: Maybe<Scalars['String']['output']>;
  environment?: Maybe<PaymentProviderMedipassEnvironment>;
};

export enum PaymentProviderMedipassEnvironment {
  /** Production */
  Production = 'production',
  /** Staging */
  Staging = 'staging'
}

export type PaymentProviderStripeConfiguration = {
  __typename?: 'PaymentProviderStripeConfiguration';
  /** Determines if stripe payments applies a patient surcharge */
  canProcessAndApplyPatientSurcharge?: Maybe<Scalars['Boolean']['output']>;
  /** Determines if stripe payments can be taken */
  canProcessPayments?: Maybe<Scalars['Boolean']['output']>;
  capabilities?: Maybe<StripeCapabilities>;
  chargesEnabled?: Maybe<Scalars['Boolean']['output']>;
  debitNegativeBalances?: Maybe<Scalars['Boolean']['output']>;
  payoutsEnabled?: Maybe<Scalars['Boolean']['output']>;
  /** The Stripe Account ID used for processing payments */
  stripeAccountId?: Maybe<Scalars['String']['output']>;
  /** The base fee that is taken from transaction (in cents) */
  stripeBaseFee?: Maybe<Scalars['Float']['output']>;
  /** The % fee that is taken from transaction */
  stripeZavyFee?: Maybe<Scalars['Float']['output']>;
};

export enum PaymentProviderType {
  Hicaps = 'hicaps',
  Manual = 'manual',
  Medipass = 'medipass',
  Stripe = 'stripe',
  Tyro = 'tyro'
}

export type PaymentProviderTyroConfiguration = {
  __typename?: 'PaymentProviderTyroConfiguration';
  environment?: Maybe<PaymentProviderTyroEnvironment>;
};

export enum PaymentProviderTyroEnvironment {
  /** Development */
  Development = 'development',
  /** Production */
  Production = 'production',
  /** Simulator */
  Simulator = 'simulator'
}

/** Physical payment terminal used to process payments in a dental practice. In our case we use this for Tyro */
export type PaymentTerminal = {
  __typename?: 'PaymentTerminal';
  /** Additional merchant IDs for a terminal */
  additionalMerchantIds?: Maybe<Array<Scalars['String']['output']>>;
  createdAt: Scalars['ISO8601DateTime']['output'];
  discardedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  /** Status returned during the pairing process with Tyro */
  feedback?: Maybe<PaymentTerminalFeedback>;
  id: Scalars['ID']['output'];
  /** Integration Key is the pairing key returned by the Tyro iClient */
  integrationKey?: Maybe<Scalars['String']['output']>;
  /** Default merchant Id for a terminal */
  merchantId?: Maybe<Scalars['String']['output']>;
  /** All Merchant IDs for a terminal, including default. This is used for selecting the correct terminal for a given provider. */
  merchantIds?: Maybe<Array<Scalars['String']['output']>>;
  /** The descriptive name of the payment terminal e.g Front Office */
  name?: Maybe<Scalars['String']['output']>;
  practiceLocation?: Maybe<PracticeLocation>;
  /** The ID of the practice location this UPC is assigned to */
  practiceLocationId?: Maybe<Scalars['ID']['output']>;
  /** Terminal ID */
  terminalId?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

/** The connection type for PaymentTerminal. */
export type PaymentTerminalConnection = {
  __typename?: 'PaymentTerminalConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<PaymentTerminalEdge>>>;
  /** IDs of ALL results, regardless of pagination */
  ids: Array<Scalars['ID']['output']>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<PaymentTerminal>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Returns total count of items in list */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type PaymentTerminalEdge = {
  __typename?: 'PaymentTerminalEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<PaymentTerminal>;
};

export enum PaymentTerminalFeedback {
  /** Not Paired */
  NotPaired = 'not_paired',
  /** Paired */
  Paired = 'paired',
  /** Pairing */
  Pairing = 'pairing',
  /** Pairing Failed */
  PairingFailed = 'pairing_failed'
}

export type PaymentTransaction = AnyNode & {
  __typename?: 'PaymentTransaction';
  /** The total amount of the payment transaction */
  amount?: Maybe<Scalars['Float']['output']>;
  createdAt: Scalars['ISO8601DateTime']['output'];
  /** Date of the payment transaction */
  date?: Maybe<Scalars['ISO8601Date']['output']>;
  guid: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  invoice?: Maybe<Invoice>;
  /** The invoice this transaction belongs to */
  invoiceId?: Maybe<Scalars['ID']['output']>;
  invoiceTransactions?: Maybe<Array<InvoiceTransaction>>;
  /** Note of the payment transaction */
  note?: Maybe<Scalars['String']['output']>;
  patient?: Maybe<UserPracticeLink>;
  /** The id of the patient that this transaction was made with */
  patientId?: Maybe<Scalars['ID']['output']>;
  /** The amount the patient paid including any surcharges */
  patientPaid?: Maybe<Scalars['Float']['output']>;
  /** The amount the practice received including any surcharges */
  practiceReceived?: Maybe<Scalars['Float']['output']>;
  practitioner?: Maybe<UserPracticeLink>;
  /** The ID of the practitioner that processed the payment */
  practitionerId?: Maybe<Scalars['ID']['output']>;
  /** Surcharge in cents. Only for Stripe */
  surcharge?: Maybe<Scalars['Int']['output']>;
  updatedAt: Scalars['ISO8601DateTime']['output'];
  /** Unique identifier of the message */
  uuid: Scalars['String']['output'];
};

/** The connection type for PaymentTransaction. */
export type PaymentTransactionConnection = {
  __typename?: 'PaymentTransactionConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<PaymentTransactionEdge>>>;
  /** IDs of ALL results, regardless of pagination */
  ids: Array<Scalars['ID']['output']>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<PaymentTransaction>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Returns total count of items in list */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type PaymentTransactionEdge = {
  __typename?: 'PaymentTransactionEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<PaymentTransaction>;
};

export enum Permission {
  ActivateReferral = 'activateReferral',
  AddRoleToGroup = 'addRoleToGroup',
  AddUserToGroup = 'addUserToGroup',
  AnnotateClinicalNote = 'annotateClinicalNote',
  ArchiveAlert = 'archiveAlert',
  AssignAdviceItem = 'assignAdviceItem',
  AssignAlert = 'assignAlert',
  AssignLocation = 'assignLocation',
  AssignOwnAlert = 'assignOwnAlert',
  AssignPrice = 'assignPrice',
  AssignPriceList = 'assignPriceList',
  AssignRole = 'assignRole',
  AssignTemplate = 'assignTemplate',
  AssignTreatment = 'assignTreatment',
  AssignTreatmentPlan = 'assignTreatmentPlan',
  CreateAccount = 'createAccount',
  CreateAdvice = 'createAdvice',
  CreateAdviceItem = 'createAdviceItem',
  CreateAdviceItemVideo = 'createAdviceItemVideo',
  CreateAlert = 'createAlert',
  CreateAppointment = 'createAppointment',
  CreateAppointmentGuest = 'createAppointmentGuest',
  CreateAppointmentReason = 'createAppointmentReason',
  CreateAssessment = 'createAssessment',
  CreateAttachment = 'createAttachment',
  CreateBadgesTemplate = 'createBadgesTemplate',
  CreateBreak = 'createBreak',
  CreateCancellationReason = 'createCancellationReason',
  CreateCatalogueItem = 'createCatalogueItem',
  CreateCatalogueItemAppointmentReason = 'createCatalogueItemAppointmentReason',
  CreateCatalogueItemGroup = 'createCatalogueItemGroup',
  CreateCatalogueItemMaterial = 'createCatalogueItemMaterial',
  CreateCategory = 'createCategory',
  CreateClinicalNote = 'createClinicalNote',
  CreateCommunicationSetting = 'createCommunicationSetting',
  CreateContact = 'createContact',
  CreateContractIdentifier = 'createContractIdentifier',
  CreateConversation = 'createConversation',
  CreateCustomField = 'createCustomField',
  CreateCustomImport = 'createCustomImport',
  CreateCustomerRequest = 'createCustomerRequest',
  CreateDental = 'createDental',
  CreateDentalChart = 'createDentalChart',
  CreateEmail = 'createEmail',
  CreateEmergencyContact = 'createEmergencyContact',
  CreateFamily = 'createFamily',
  CreateFileAttachment = 'createFileAttachment',
  CreateForm = 'createForm',
  CreateGroup = 'createGroup',
  CreateHealthCondition = 'createHealthCondition',
  CreateIndustry = 'createIndustry',
  CreateInvoice = 'createInvoice',
  CreateInvoiceItem = 'createInvoiceItem',
  CreateInvoicePreferences = 'createInvoicePreferences',
  CreateInvoiceSetting = 'createInvoiceSetting',
  CreateInvoiceTransaction = 'createInvoiceTransaction',
  CreateLabWork = 'createLabWork',
  CreateLetter = 'createLetter',
  CreateLocation = 'createLocation',
  CreateMailSetting = 'createMailSetting',
  CreateMaterial = 'createMaterial',
  CreateNote = 'createNote',
  CreateNotification = 'createNotification',
  CreateOnlineBookingSetting = 'createOnlineBookingSetting',
  CreateOnlineMeeting = 'createOnlineMeeting',
  CreateOtp = 'createOtp',
  CreateOwnAccount = 'createOwnAccount',
  CreateOwnAdvice = 'createOwnAdvice',
  CreateOwnAdviceItem = 'createOwnAdviceItem',
  CreateOwnAdviceItemVideo = 'createOwnAdviceItemVideo',
  CreateOwnAlert = 'createOwnAlert',
  CreateOwnAppointment = 'createOwnAppointment',
  CreateOwnAppointmentGuest = 'createOwnAppointmentGuest',
  CreateOwnAssessment = 'createOwnAssessment',
  CreateOwnAttachment = 'createOwnAttachment',
  CreateOwnBreak = 'createOwnBreak',
  CreateOwnCancellationReason = 'createOwnCancellationReason',
  CreateOwnCatalogueItem = 'createOwnCatalogueItem',
  CreateOwnCatalogueItemAppointmentReason = 'createOwnCatalogueItemAppointmentReason',
  CreateOwnCatalogueItemGroup = 'createOwnCatalogueItemGroup',
  CreateOwnCatalogueItemMaterial = 'createOwnCatalogueItemMaterial',
  CreateOwnCategory = 'createOwnCategory',
  CreateOwnClinicalNote = 'createOwnClinicalNote',
  CreateOwnCommunicationSetting = 'createOwnCommunicationSetting',
  CreateOwnContact = 'createOwnContact',
  CreateOwnContractIdentifier = 'createOwnContractIdentifier',
  CreateOwnCustomField = 'createOwnCustomField',
  CreateOwnCustomImport = 'createOwnCustomImport',
  CreateOwnCustomerRequest = 'createOwnCustomerRequest',
  CreateOwnDental = 'createOwnDental',
  CreateOwnEmail = 'createOwnEmail',
  CreateOwnEmergencyContact = 'createOwnEmergencyContact',
  CreateOwnFileAttachment = 'createOwnFileAttachment',
  CreateOwnForm = 'createOwnForm',
  CreateOwnHealthCondition = 'createOwnHealthCondition',
  CreateOwnIndustry = 'createOwnIndustry',
  CreateOwnInvoice = 'createOwnInvoice',
  CreateOwnInvoiceItem = 'createOwnInvoiceItem',
  CreateOwnInvoicePreferences = 'createOwnInvoicePreferences',
  CreateOwnInvoiceSetting = 'createOwnInvoiceSetting',
  CreateOwnInvoiceTransaction = 'createOwnInvoiceTransaction',
  CreateOwnLetter = 'createOwnLetter',
  CreateOwnLocation = 'createOwnLocation',
  CreateOwnMailSetting = 'createOwnMailSetting',
  CreateOwnMaterial = 'createOwnMaterial',
  CreateOwnNote = 'createOwnNote',
  CreateOwnNotification = 'createOwnNotification',
  CreateOwnOnlineBookingSetting = 'createOwnOnlineBookingSetting',
  CreateOwnOnlineMeeting = 'createOwnOnlineMeeting',
  CreateOwnOtp = 'createOwnOtp',
  CreateOwnPatient = 'createOwnPatient',
  CreateOwnPaymentMethod = 'createOwnPaymentMethod',
  CreateOwnPaymentProvider = 'createOwnPaymentProvider',
  CreateOwnPaymentTerminal = 'createOwnPaymentTerminal',
  CreateOwnPractice = 'createOwnPractice',
  CreateOwnPracticeLocalisationSetting = 'createOwnPracticeLocalisationSetting',
  CreateOwnPracticeLocation = 'createOwnPracticeLocation',
  CreateOwnPrice = 'createOwnPrice',
  CreateOwnPriceList = 'createOwnPriceList',
  CreateOwnRating = 'createOwnRating',
  CreateOwnRecall = 'createOwnRecall',
  CreateOwnShortMessage = 'createOwnShortMessage',
  CreateOwnTemplate = 'createOwnTemplate',
  CreateOwnUser = 'createOwnUser',
  CreateOwnUserContacts = 'createOwnUserContacts',
  CreateOwnUserPracticeProfile = 'createOwnUserPracticeProfile',
  CreateOwnWaitingAppointment = 'createOwnWaitingAppointment',
  CreateOwnWorkingDay = 'createOwnWorkingDay',
  CreateOwnWorkingWeek = 'createOwnWorkingWeek',
  CreatePatient = 'createPatient',
  CreatePaymentMethod = 'createPaymentMethod',
  CreatePaymentProvider = 'createPaymentProvider',
  CreatePaymentTerminal = 'createPaymentTerminal',
  CreatePaymentTransaction = 'createPaymentTransaction',
  CreatePractice = 'createPractice',
  CreatePracticeLocalisationSetting = 'createPracticeLocalisationSetting',
  CreatePracticeLocation = 'createPracticeLocation',
  CreatePrice = 'createPrice',
  CreatePriceList = 'createPriceList',
  CreateRating = 'createRating',
  CreateRecall = 'createRecall',
  CreateRecurringTask = 'createRecurringTask',
  CreateReferral = 'createReferral',
  CreateReferralSource = 'createReferralSource',
  CreateRefundPaymentTransaction = 'createRefundPaymentTransaction',
  CreateReviewTemplate = 'createReviewTemplate',
  CreateRole = 'createRole',
  CreateRoom = 'createRoom',
  CreateRosterEvent = 'createRosterEvent',
  CreateShiftType = 'createShiftType',
  CreateShortMessage = 'createShortMessage',
  CreateStaff = 'createStaff',
  CreateStaffUser = 'createStaffUser',
  CreateSterilization = 'createSterilization',
  CreateStockAdjustment = 'createStockAdjustment',
  CreateStockDetail = 'createStockDetail',
  CreateStockHistory = 'createStockHistory',
  CreateSubscription = 'createSubscription',
  CreateTask = 'createTask',
  CreateTemplate = 'createTemplate',
  CreateToothCondition = 'createToothCondition',
  CreateTreatment = 'createTreatment',
  CreateTreatmentPlan = 'createTreatmentPlan',
  CreateUser = 'createUser',
  CreateUserContacts = 'createUserContacts',
  CreateUserPracticeProfile = 'createUserPracticeProfile',
  CreateWaitingAppointment = 'createWaitingAppointment',
  CreateWaitlist = 'createWaitlist',
  CreateWorkflow = 'createWorkflow',
  CreateWorkingDay = 'createWorkingDay',
  CreateWorkingWeek = 'createWorkingWeek',
  DeactivateSubscription = 'deactivateSubscription',
  DeleteAccount = 'deleteAccount',
  DeleteAdvice = 'deleteAdvice',
  DeleteAdviceItem = 'deleteAdviceItem',
  DeleteAdviceItemVideo = 'deleteAdviceItemVideo',
  DeleteAlert = 'deleteAlert',
  DeleteAppointment = 'deleteAppointment',
  DeleteAppointmentGuest = 'deleteAppointmentGuest',
  DeleteAppointmentReason = 'deleteAppointmentReason',
  DeleteAssessment = 'deleteAssessment',
  DeleteAttachment = 'deleteAttachment',
  DeleteBadgesTemplate = 'deleteBadgesTemplate',
  DeleteBreak = 'deleteBreak',
  DeleteCancellationReason = 'deleteCancellationReason',
  DeleteCatalogueItem = 'deleteCatalogueItem',
  DeleteCatalogueItemAppointmentReason = 'deleteCatalogueItemAppointmentReason',
  DeleteCatalogueItemGroup = 'deleteCatalogueItemGroup',
  DeleteCatalogueItemMaterial = 'deleteCatalogueItemMaterial',
  DeleteCategory = 'deleteCategory',
  DeleteClinicalNote = 'deleteClinicalNote',
  DeleteCommunicationSetting = 'deleteCommunicationSetting',
  DeleteContact = 'deleteContact',
  DeleteContractIdentifier = 'deleteContractIdentifier',
  DeleteConversation = 'deleteConversation',
  DeleteCustomField = 'deleteCustomField',
  DeleteCustomImport = 'deleteCustomImport',
  DeleteCustomerRequest = 'deleteCustomerRequest',
  DeleteDental = 'deleteDental',
  DeleteDentalChart = 'deleteDentalChart',
  DeleteEmail = 'deleteEmail',
  DeleteEmergencyContact = 'deleteEmergencyContact',
  DeleteFamily = 'deleteFamily',
  DeleteFileAttachment = 'deleteFileAttachment',
  DeleteForm = 'deleteForm',
  DeleteGroup = 'deleteGroup',
  DeleteHealthCondition = 'deleteHealthCondition',
  DeleteIndustry = 'deleteIndustry',
  DeleteInvoice = 'deleteInvoice',
  DeleteInvoiceItem = 'deleteInvoiceItem',
  DeleteInvoicePreferences = 'deleteInvoicePreferences',
  DeleteInvoiceSetting = 'deleteInvoiceSetting',
  DeleteInvoiceTransaction = 'deleteInvoiceTransaction',
  DeleteLabWork = 'deleteLabWork',
  DeleteLetter = 'deleteLetter',
  DeleteLocation = 'deleteLocation',
  DeleteMailSetting = 'deleteMailSetting',
  DeleteMaterial = 'deleteMaterial',
  DeleteNote = 'deleteNote',
  DeleteNotification = 'deleteNotification',
  DeleteOnlineBookingSetting = 'deleteOnlineBookingSetting',
  DeleteOnlineMeeting = 'deleteOnlineMeeting',
  DeleteOtp = 'deleteOtp',
  DeleteOwnAccount = 'deleteOwnAccount',
  DeleteOwnAdvice = 'deleteOwnAdvice',
  DeleteOwnAdviceItem = 'deleteOwnAdviceItem',
  DeleteOwnAdviceItemVideo = 'deleteOwnAdviceItemVideo',
  DeleteOwnAlert = 'deleteOwnAlert',
  DeleteOwnAppointment = 'deleteOwnAppointment',
  DeleteOwnAppointmentGuest = 'deleteOwnAppointmentGuest',
  DeleteOwnAssessment = 'deleteOwnAssessment',
  DeleteOwnAttachment = 'deleteOwnAttachment',
  DeleteOwnBreak = 'deleteOwnBreak',
  DeleteOwnCancellationReason = 'deleteOwnCancellationReason',
  DeleteOwnCatalogueItem = 'deleteOwnCatalogueItem',
  DeleteOwnCatalogueItemAppointmentReason = 'deleteOwnCatalogueItemAppointmentReason',
  DeleteOwnCatalogueItemGroup = 'deleteOwnCatalogueItemGroup',
  DeleteOwnCatalogueItemMaterial = 'deleteOwnCatalogueItemMaterial',
  DeleteOwnCategory = 'deleteOwnCategory',
  DeleteOwnClinicalNote = 'deleteOwnClinicalNote',
  DeleteOwnCommunicationSetting = 'deleteOwnCommunicationSetting',
  DeleteOwnContact = 'deleteOwnContact',
  DeleteOwnContractIdentifier = 'deleteOwnContractIdentifier',
  DeleteOwnCustomField = 'deleteOwnCustomField',
  DeleteOwnCustomImport = 'deleteOwnCustomImport',
  DeleteOwnCustomerRequest = 'deleteOwnCustomerRequest',
  DeleteOwnDental = 'deleteOwnDental',
  DeleteOwnEmail = 'deleteOwnEmail',
  DeleteOwnEmergencyContact = 'deleteOwnEmergencyContact',
  DeleteOwnFileAttachment = 'deleteOwnFileAttachment',
  DeleteOwnForm = 'deleteOwnForm',
  DeleteOwnHealthCondition = 'deleteOwnHealthCondition',
  DeleteOwnIndustry = 'deleteOwnIndustry',
  DeleteOwnInvoice = 'deleteOwnInvoice',
  DeleteOwnInvoiceItem = 'deleteOwnInvoiceItem',
  DeleteOwnInvoicePreferences = 'deleteOwnInvoicePreferences',
  DeleteOwnInvoiceSetting = 'deleteOwnInvoiceSetting',
  DeleteOwnInvoiceTransaction = 'deleteOwnInvoiceTransaction',
  DeleteOwnLetter = 'deleteOwnLetter',
  DeleteOwnLocation = 'deleteOwnLocation',
  DeleteOwnMailSetting = 'deleteOwnMailSetting',
  DeleteOwnMaterial = 'deleteOwnMaterial',
  DeleteOwnNote = 'deleteOwnNote',
  DeleteOwnNotification = 'deleteOwnNotification',
  DeleteOwnOnlineBookingSetting = 'deleteOwnOnlineBookingSetting',
  DeleteOwnOnlineMeeting = 'deleteOwnOnlineMeeting',
  DeleteOwnOtp = 'deleteOwnOtp',
  DeleteOwnPatient = 'deleteOwnPatient',
  DeleteOwnPaymentMethod = 'deleteOwnPaymentMethod',
  DeleteOwnPaymentProvider = 'deleteOwnPaymentProvider',
  DeleteOwnPaymentTerminal = 'deleteOwnPaymentTerminal',
  DeleteOwnPractice = 'deleteOwnPractice',
  DeleteOwnPracticeLocalisationSetting = 'deleteOwnPracticeLocalisationSetting',
  DeleteOwnPracticeLocation = 'deleteOwnPracticeLocation',
  DeleteOwnPrice = 'deleteOwnPrice',
  DeleteOwnPriceList = 'deleteOwnPriceList',
  DeleteOwnRating = 'deleteOwnRating',
  DeleteOwnRecall = 'deleteOwnRecall',
  DeleteOwnShortMessage = 'deleteOwnShortMessage',
  DeleteOwnTemplate = 'deleteOwnTemplate',
  DeleteOwnUser = 'deleteOwnUser',
  DeleteOwnUserContacts = 'deleteOwnUserContacts',
  DeleteOwnUserPracticeProfile = 'deleteOwnUserPracticeProfile',
  DeleteOwnWaitingAppointment = 'deleteOwnWaitingAppointment',
  DeleteOwnWorkingDay = 'deleteOwnWorkingDay',
  DeleteOwnWorkingWeek = 'deleteOwnWorkingWeek',
  DeletePatient = 'deletePatient',
  DeletePaymentMethod = 'deletePaymentMethod',
  DeletePaymentProvider = 'deletePaymentProvider',
  DeletePaymentTerminal = 'deletePaymentTerminal',
  DeletePaymentTransaction = 'deletePaymentTransaction',
  DeletePractice = 'deletePractice',
  DeletePracticeLocalisationSetting = 'deletePracticeLocalisationSetting',
  DeletePracticeLocation = 'deletePracticeLocation',
  DeletePrice = 'deletePrice',
  DeletePriceList = 'deletePriceList',
  DeleteRating = 'deleteRating',
  DeleteRecall = 'deleteRecall',
  DeleteRecurringTask = 'deleteRecurringTask',
  DeleteReferral = 'deleteReferral',
  DeleteReferralSource = 'deleteReferralSource',
  DeleteReviewTemplate = 'deleteReviewTemplate',
  DeleteRole = 'deleteRole',
  DeleteRoom = 'deleteRoom',
  DeleteRosterEvent = 'deleteRosterEvent',
  DeleteShiftType = 'deleteShiftType',
  DeleteShortMessage = 'deleteShortMessage',
  DeleteStaff = 'deleteStaff',
  DeleteStaffUser = 'deleteStaffUser',
  DeleteSterilization = 'deleteSterilization',
  DeleteStockAdjustment = 'deleteStockAdjustment',
  DeleteStockDetail = 'deleteStockDetail',
  DeleteStockHistory = 'deleteStockHistory',
  DeleteSubscription = 'deleteSubscription',
  DeleteTask = 'deleteTask',
  DeleteTemplate = 'deleteTemplate',
  DeleteToothCondition = 'deleteToothCondition',
  DeleteTreatment = 'deleteTreatment',
  DeleteTreatmentPlan = 'deleteTreatmentPlan',
  DeleteUser = 'deleteUser',
  DeleteUserContacts = 'deleteUserContacts',
  DeleteUserPracticeProfile = 'deleteUserPracticeProfile',
  DeleteWaitingAppointment = 'deleteWaitingAppointment',
  DeleteWaitlist = 'deleteWaitlist',
  DeleteWorkflow = 'deleteWorkflow',
  DeleteWorkingDay = 'deleteWorkingDay',
  DeleteWorkingWeek = 'deleteWorkingWeek',
  DeliverForm = 'deliverForm',
  DeliverOtp = 'deliverOtp',
  ExportReports = 'exportReports',
  GrantPermission = 'grantPermission',
  GrantPermissionsForStaff = 'grantPermissionsForStaff',
  GrantPermissionsForUser = 'grantPermissionsForUser',
  InviteUserOnlineMeeting = 'inviteUserOnlineMeeting',
  InviteUserPractice = 'inviteUserPractice',
  ReactivateSubscription = 'reactivateSubscription',
  RemoveRoleFromGroup = 'removeRoleFromGroup',
  RemoveUserFromGroup = 'removeUserFromGroup',
  RemoveWaitlistWaitlist = 'removeWaitlistWaitlist',
  RevokePermission = 'revokePermission',
  RevokePermissionsForStaff = 'revokePermissionsForStaff',
  RevokePermissionsForUser = 'revokePermissionsForUser',
  UnassignAlert = 'unassignAlert',
  UnassignRole = 'unassignRole',
  UpdateAccount = 'updateAccount',
  UpdateAdvice = 'updateAdvice',
  UpdateAdviceItem = 'updateAdviceItem',
  UpdateAdviceItemVideo = 'updateAdviceItemVideo',
  UpdateAlert = 'updateAlert',
  UpdateAppointment = 'updateAppointment',
  UpdateAppointmentGuest = 'updateAppointmentGuest',
  UpdateAppointmentReason = 'updateAppointmentReason',
  UpdateAssessment = 'updateAssessment',
  UpdateAttachment = 'updateAttachment',
  UpdateBadgesTemplate = 'updateBadgesTemplate',
  UpdateBreak = 'updateBreak',
  UpdateCancellationReason = 'updateCancellationReason',
  UpdateCatalogueItem = 'updateCatalogueItem',
  UpdateCatalogueItemAppointmentReason = 'updateCatalogueItemAppointmentReason',
  UpdateCatalogueItemGroup = 'updateCatalogueItemGroup',
  UpdateCatalogueItemMaterial = 'updateCatalogueItemMaterial',
  UpdateCategory = 'updateCategory',
  UpdateClinicalNote = 'updateClinicalNote',
  UpdateCommunicationSetting = 'updateCommunicationSetting',
  UpdateContact = 'updateContact',
  UpdateContractIdentifier = 'updateContractIdentifier',
  UpdateConversation = 'updateConversation',
  UpdateCreditsForUser = 'updateCreditsForUser',
  UpdateCustomField = 'updateCustomField',
  UpdateCustomImport = 'updateCustomImport',
  UpdateCustomerRequest = 'updateCustomerRequest',
  UpdateDental = 'updateDental',
  UpdateDentalChart = 'updateDentalChart',
  UpdateEmail = 'updateEmail',
  UpdateEmergencyContact = 'updateEmergencyContact',
  UpdateFamily = 'updateFamily',
  UpdateFileAttachment = 'updateFileAttachment',
  UpdateForm = 'updateForm',
  UpdateGroup = 'updateGroup',
  UpdateHealthCondition = 'updateHealthCondition',
  UpdateIndustry = 'updateIndustry',
  UpdateInvoice = 'updateInvoice',
  UpdateInvoiceItem = 'updateInvoiceItem',
  UpdateInvoicePreferences = 'updateInvoicePreferences',
  UpdateInvoiceSetting = 'updateInvoiceSetting',
  UpdateInvoiceTransaction = 'updateInvoiceTransaction',
  UpdateLabWork = 'updateLabWork',
  UpdateLetter = 'updateLetter',
  UpdateLocation = 'updateLocation',
  UpdateMailSetting = 'updateMailSetting',
  UpdateMaterial = 'updateMaterial',
  UpdateNote = 'updateNote',
  UpdateNotification = 'updateNotification',
  UpdateOnlineBookingSetting = 'updateOnlineBookingSetting',
  UpdateOnlineMeeting = 'updateOnlineMeeting',
  UpdateOtp = 'updateOtp',
  UpdateOwnAccount = 'updateOwnAccount',
  UpdateOwnAdvice = 'updateOwnAdvice',
  UpdateOwnAdviceItem = 'updateOwnAdviceItem',
  UpdateOwnAdviceItemVideo = 'updateOwnAdviceItemVideo',
  UpdateOwnAlert = 'updateOwnAlert',
  UpdateOwnAppointment = 'updateOwnAppointment',
  UpdateOwnAppointmentGuest = 'updateOwnAppointmentGuest',
  UpdateOwnAssessment = 'updateOwnAssessment',
  UpdateOwnAttachment = 'updateOwnAttachment',
  UpdateOwnBreak = 'updateOwnBreak',
  UpdateOwnCancellationReason = 'updateOwnCancellationReason',
  UpdateOwnCatalogueItem = 'updateOwnCatalogueItem',
  UpdateOwnCatalogueItemAppointmentReason = 'updateOwnCatalogueItemAppointmentReason',
  UpdateOwnCatalogueItemGroup = 'updateOwnCatalogueItemGroup',
  UpdateOwnCatalogueItemMaterial = 'updateOwnCatalogueItemMaterial',
  UpdateOwnCategory = 'updateOwnCategory',
  UpdateOwnClinicalNote = 'updateOwnClinicalNote',
  UpdateOwnCommunicationSetting = 'updateOwnCommunicationSetting',
  UpdateOwnContact = 'updateOwnContact',
  UpdateOwnContractIdentifier = 'updateOwnContractIdentifier',
  UpdateOwnCustomField = 'updateOwnCustomField',
  UpdateOwnCustomImport = 'updateOwnCustomImport',
  UpdateOwnCustomerRequest = 'updateOwnCustomerRequest',
  UpdateOwnDental = 'updateOwnDental',
  UpdateOwnEmail = 'updateOwnEmail',
  UpdateOwnEmergencyContact = 'updateOwnEmergencyContact',
  UpdateOwnFileAttachment = 'updateOwnFileAttachment',
  UpdateOwnForm = 'updateOwnForm',
  UpdateOwnHealthCondition = 'updateOwnHealthCondition',
  UpdateOwnIndustry = 'updateOwnIndustry',
  UpdateOwnInvoice = 'updateOwnInvoice',
  UpdateOwnInvoiceItem = 'updateOwnInvoiceItem',
  UpdateOwnInvoicePreferences = 'updateOwnInvoicePreferences',
  UpdateOwnInvoiceSetting = 'updateOwnInvoiceSetting',
  UpdateOwnInvoiceTransaction = 'updateOwnInvoiceTransaction',
  UpdateOwnLetter = 'updateOwnLetter',
  UpdateOwnLocation = 'updateOwnLocation',
  UpdateOwnMailSetting = 'updateOwnMailSetting',
  UpdateOwnMaterial = 'updateOwnMaterial',
  UpdateOwnNote = 'updateOwnNote',
  UpdateOwnNotification = 'updateOwnNotification',
  UpdateOwnOnlineBookingSetting = 'updateOwnOnlineBookingSetting',
  UpdateOwnOnlineMeeting = 'updateOwnOnlineMeeting',
  UpdateOwnOtp = 'updateOwnOtp',
  UpdateOwnPatient = 'updateOwnPatient',
  UpdateOwnPaymentMethod = 'updateOwnPaymentMethod',
  UpdateOwnPaymentProvider = 'updateOwnPaymentProvider',
  UpdateOwnPaymentTerminal = 'updateOwnPaymentTerminal',
  UpdateOwnPractice = 'updateOwnPractice',
  UpdateOwnPracticeLocalisationSetting = 'updateOwnPracticeLocalisationSetting',
  UpdateOwnPracticeLocation = 'updateOwnPracticeLocation',
  UpdateOwnPrice = 'updateOwnPrice',
  UpdateOwnPriceList = 'updateOwnPriceList',
  UpdateOwnRating = 'updateOwnRating',
  UpdateOwnRecall = 'updateOwnRecall',
  UpdateOwnShortMessage = 'updateOwnShortMessage',
  UpdateOwnTemplate = 'updateOwnTemplate',
  UpdateOwnUser = 'updateOwnUser',
  UpdateOwnUserContacts = 'updateOwnUserContacts',
  UpdateOwnUserPracticeProfile = 'updateOwnUserPracticeProfile',
  UpdateOwnWaitingAppointment = 'updateOwnWaitingAppointment',
  UpdateOwnWorkingDay = 'updateOwnWorkingDay',
  UpdateOwnWorkingWeek = 'updateOwnWorkingWeek',
  UpdatePatient = 'updatePatient',
  UpdatePaymentMethod = 'updatePaymentMethod',
  UpdatePaymentProvider = 'updatePaymentProvider',
  UpdatePaymentTerminal = 'updatePaymentTerminal',
  UpdatePaymentTransaction = 'updatePaymentTransaction',
  UpdatePractice = 'updatePractice',
  UpdatePracticeLocalisationSetting = 'updatePracticeLocalisationSetting',
  UpdatePracticeLocation = 'updatePracticeLocation',
  UpdatePrice = 'updatePrice',
  UpdatePriceList = 'updatePriceList',
  UpdateRating = 'updateRating',
  UpdateRecall = 'updateRecall',
  UpdateRecurringTask = 'updateRecurringTask',
  UpdateReferral = 'updateReferral',
  UpdateReferralSource = 'updateReferralSource',
  UpdateReviewTemplate = 'updateReviewTemplate',
  UpdateRole = 'updateRole',
  UpdateRoom = 'updateRoom',
  UpdateRosterEvent = 'updateRosterEvent',
  UpdateShiftType = 'updateShiftType',
  UpdateShortMessage = 'updateShortMessage',
  UpdateStaff = 'updateStaff',
  UpdateStaffUser = 'updateStaffUser',
  UpdateSterilization = 'updateSterilization',
  UpdateStockAdjustment = 'updateStockAdjustment',
  UpdateStockDetail = 'updateStockDetail',
  UpdateStockHistory = 'updateStockHistory',
  UpdateSubscription = 'updateSubscription',
  UpdateTask = 'updateTask',
  UpdateTemplate = 'updateTemplate',
  UpdateToothCondition = 'updateToothCondition',
  UpdateTreatment = 'updateTreatment',
  UpdateTreatmentPlan = 'updateTreatmentPlan',
  UpdateUser = 'updateUser',
  UpdateUserContacts = 'updateUserContacts',
  UpdateUserPracticeProfile = 'updateUserPracticeProfile',
  UpdateWaitingAppointment = 'updateWaitingAppointment',
  UpdateWaitlist = 'updateWaitlist',
  UpdateWorkflow = 'updateWorkflow',
  UpdateWorkingDay = 'updateWorkingDay',
  UpdateWorkingWeek = 'updateWorkingWeek',
  UploadAdviceItemVideo = 'uploadAdviceItemVideo',
  UploadAttachment = 'uploadAttachment',
  UploadAttachmentEmail = 'uploadAttachmentEmail',
  UploadFileAttachment = 'uploadFileAttachment',
  UploadPatient = 'uploadPatient',
  ViewAccount = 'viewAccount',
  ViewAdvice = 'viewAdvice',
  ViewAdviceItem = 'viewAdviceItem',
  ViewAdviceItemVideo = 'viewAdviceItemVideo',
  ViewAlert = 'viewAlert',
  ViewAppointment = 'viewAppointment',
  ViewAppointmentFirstVisitKpiReports = 'viewAppointmentFirstVisitKpiReports',
  ViewAppointmentGuest = 'viewAppointmentGuest',
  ViewAppointmentOccupancyReports = 'viewAppointmentOccupancyReports',
  ViewAppointmentOnlineBookingsReports = 'viewAppointmentOnlineBookingsReports',
  ViewAppointmentReason = 'viewAppointmentReason',
  ViewAppointmentRebookRateReports = 'viewAppointmentRebookRateReports',
  ViewAppointmentStatusDashboardWidget = 'viewAppointmentStatusDashboardWidget',
  ViewAppointmentStatusGroupedDashboardWidget = 'viewAppointmentStatusGroupedDashboardWidget',
  ViewAppointmentsReports = 'viewAppointmentsReports',
  ViewAssessment = 'viewAssessment',
  ViewAttachment = 'viewAttachment',
  ViewBadgesTemplate = 'viewBadgesTemplate',
  ViewBreak = 'viewBreak',
  ViewCancellationReason = 'viewCancellationReason',
  ViewCatalogueItem = 'viewCatalogueItem',
  ViewCatalogueItemAppointmentReason = 'viewCatalogueItemAppointmentReason',
  ViewCatalogueItemGroup = 'viewCatalogueItemGroup',
  ViewCatalogueItemMaterial = 'viewCatalogueItemMaterial',
  ViewCategory = 'viewCategory',
  ViewClientBirthdaysReports = 'viewClientBirthdaysReports',
  ViewClientDemographicsReports = 'viewClientDemographicsReports',
  ViewClientDepositRequestsReports = 'viewClientDepositRequestsReports',
  ViewClientDropoffsReports = 'viewClientDropoffsReports',
  ViewClientNewPatientsKpiReports = 'viewClientNewPatientsKpiReports',
  ViewClientNewPatientsReports = 'viewClientNewPatientsReports',
  ViewClientNotesReports = 'viewClientNotesReports',
  ViewClientOutstandingTreatmentPlansReports = 'viewClientOutstandingTreatmentPlansReports',
  ViewClientRecallsDueReports = 'viewClientRecallsDueReports',
  ViewClientRecallsReports = 'viewClientRecallsReports',
  ViewClientRequestsReports = 'viewClientRequestsReports',
  ViewClientTreatmentsReports = 'viewClientTreatmentsReports',
  ViewClinicalNote = 'viewClinicalNote',
  ViewCommunicationEmailSummaryReports = 'viewCommunicationEmailSummaryReports',
  ViewCommunicationLetterSummaryReports = 'viewCommunicationLetterSummaryReports',
  ViewCommunicationSetting = 'viewCommunicationSetting',
  ViewCommunicationSmsSummaryReports = 'viewCommunicationSmsSummaryReports',
  ViewContact = 'viewContact',
  ViewContactsReports = 'viewContactsReports',
  ViewContractIdentifier = 'viewContractIdentifier',
  ViewConversation = 'viewConversation',
  ViewCustomField = 'viewCustomField',
  ViewCustomImport = 'viewCustomImport',
  ViewCustomerRequest = 'viewCustomerRequest',
  ViewDashboardAppointmentSummaryReports = 'viewDashboardAppointmentSummaryReports',
  ViewDashboardPatientSummaryReports = 'viewDashboardPatientSummaryReports',
  ViewDashboardPaymentSummaryReports = 'viewDashboardPaymentSummaryReports',
  ViewDashboardRevenueSummaryReports = 'viewDashboardRevenueSummaryReports',
  ViewDental = 'viewDental',
  ViewDentalChart = 'viewDentalChart',
  ViewDepositRequestsDashboardWidget = 'viewDepositRequestsDashboardWidget',
  ViewEmail = 'viewEmail',
  ViewEmergencyContact = 'viewEmergencyContact',
  ViewFamily = 'viewFamily',
  ViewFileAttachment = 'viewFileAttachment',
  ViewFinanceAccountCreditsReports = 'viewFinanceAccountCreditsReports',
  ViewFinanceAgingReports = 'viewFinanceAgingReports',
  ViewFinanceCustomerCollectionsReports = 'viewFinanceCustomerCollectionsReports',
  ViewFinanceCustomersInCreditReports = 'viewFinanceCustomersInCreditReports',
  ViewFinanceExpensesReports = 'viewFinanceExpensesReports',
  ViewFinanceGstReports = 'viewFinanceGstReports',
  ViewFinanceInvoiceDiscountsReports = 'viewFinanceInvoiceDiscountsReports',
  ViewFinanceInvoiceItemTransactionsReports = 'viewFinanceInvoiceItemTransactionsReports',
  ViewFinanceInvoiceTransactionsReports = 'viewFinanceInvoiceTransactionsReports',
  ViewFinanceLabFeesReports = 'viewFinanceLabFeesReports',
  ViewFinanceOutstandingInvoicesReports = 'viewFinanceOutstandingInvoicesReports',
  ViewFinancePractitionerInvoicingReports = 'viewFinancePractitionerInvoicingReports',
  ViewFinancePractitionerPerformanceReports = 'viewFinancePractitionerPerformanceReports',
  ViewFinanceProductionReports = 'viewFinanceProductionReports',
  ViewFinanceProductsAndServicesReports = 'viewFinanceProductsAndServicesReports',
  ViewFinanceUninvoicedItemsReports = 'viewFinanceUninvoicedItemsReports',
  ViewForm = 'viewForm',
  ViewGroup = 'viewGroup',
  ViewHealthCondition = 'viewHealthCondition',
  ViewIndustry = 'viewIndustry',
  ViewInvoice = 'viewInvoice',
  ViewInvoiceItem = 'viewInvoiceItem',
  ViewInvoicePreferences = 'viewInvoicePreferences',
  ViewInvoiceSetting = 'viewInvoiceSetting',
  ViewInvoiceTransaction = 'viewInvoiceTransaction',
  ViewLabWork = 'viewLabWork',
  ViewLetter = 'viewLetter',
  ViewLocation = 'viewLocation',
  ViewMailSetting = 'viewMailSetting',
  ViewMaterial = 'viewMaterial',
  ViewNote = 'viewNote',
  ViewNotification = 'viewNotification',
  ViewOnlineBookingSetting = 'viewOnlineBookingSetting',
  ViewOnlineMeeting = 'viewOnlineMeeting',
  ViewOtp = 'viewOtp',
  ViewOwnAccount = 'viewOwnAccount',
  ViewOwnAdvice = 'viewOwnAdvice',
  ViewOwnAdviceItem = 'viewOwnAdviceItem',
  ViewOwnAdviceItemVideo = 'viewOwnAdviceItemVideo',
  ViewOwnAlert = 'viewOwnAlert',
  ViewOwnAppointment = 'viewOwnAppointment',
  ViewOwnAppointmentGuest = 'viewOwnAppointmentGuest',
  ViewOwnAssessment = 'viewOwnAssessment',
  ViewOwnAttachment = 'viewOwnAttachment',
  ViewOwnBreak = 'viewOwnBreak',
  ViewOwnCancellationReason = 'viewOwnCancellationReason',
  ViewOwnCatalogueItem = 'viewOwnCatalogueItem',
  ViewOwnCatalogueItemAppointmentReason = 'viewOwnCatalogueItemAppointmentReason',
  ViewOwnCatalogueItemGroup = 'viewOwnCatalogueItemGroup',
  ViewOwnCatalogueItemMaterial = 'viewOwnCatalogueItemMaterial',
  ViewOwnCategory = 'viewOwnCategory',
  ViewOwnClinicalNote = 'viewOwnClinicalNote',
  ViewOwnCommunicationSetting = 'viewOwnCommunicationSetting',
  ViewOwnContact = 'viewOwnContact',
  ViewOwnContractIdentifier = 'viewOwnContractIdentifier',
  ViewOwnCustomField = 'viewOwnCustomField',
  ViewOwnCustomImport = 'viewOwnCustomImport',
  ViewOwnCustomerRequest = 'viewOwnCustomerRequest',
  ViewOwnDental = 'viewOwnDental',
  ViewOwnEmail = 'viewOwnEmail',
  ViewOwnEmergencyContact = 'viewOwnEmergencyContact',
  ViewOwnFileAttachment = 'viewOwnFileAttachment',
  ViewOwnForm = 'viewOwnForm',
  ViewOwnHealthCondition = 'viewOwnHealthCondition',
  ViewOwnIndustry = 'viewOwnIndustry',
  ViewOwnInvoice = 'viewOwnInvoice',
  ViewOwnInvoiceItem = 'viewOwnInvoiceItem',
  ViewOwnInvoicePreferences = 'viewOwnInvoicePreferences',
  ViewOwnInvoiceSetting = 'viewOwnInvoiceSetting',
  ViewOwnInvoiceTransaction = 'viewOwnInvoiceTransaction',
  ViewOwnLetter = 'viewOwnLetter',
  ViewOwnLocation = 'viewOwnLocation',
  ViewOwnMailSetting = 'viewOwnMailSetting',
  ViewOwnMaterial = 'viewOwnMaterial',
  ViewOwnNote = 'viewOwnNote',
  ViewOwnNotification = 'viewOwnNotification',
  ViewOwnOnlineBookingSetting = 'viewOwnOnlineBookingSetting',
  ViewOwnOnlineMeeting = 'viewOwnOnlineMeeting',
  ViewOwnOtp = 'viewOwnOtp',
  ViewOwnPatient = 'viewOwnPatient',
  ViewOwnPaymentMethod = 'viewOwnPaymentMethod',
  ViewOwnPaymentProvider = 'viewOwnPaymentProvider',
  ViewOwnPaymentTerminal = 'viewOwnPaymentTerminal',
  ViewOwnPractice = 'viewOwnPractice',
  ViewOwnPracticeLocalisationSetting = 'viewOwnPracticeLocalisationSetting',
  ViewOwnPracticeLocation = 'viewOwnPracticeLocation',
  ViewOwnPrice = 'viewOwnPrice',
  ViewOwnPriceList = 'viewOwnPriceList',
  ViewOwnRating = 'viewOwnRating',
  ViewOwnRecall = 'viewOwnRecall',
  ViewOwnShortMessage = 'viewOwnShortMessage',
  ViewOwnTemplate = 'viewOwnTemplate',
  ViewOwnUser = 'viewOwnUser',
  ViewOwnUserContacts = 'viewOwnUserContacts',
  ViewOwnUserPracticeProfile = 'viewOwnUserPracticeProfile',
  ViewOwnWaitingAppointment = 'viewOwnWaitingAppointment',
  ViewOwnWorkingDay = 'viewOwnWorkingDay',
  ViewOwnWorkingWeek = 'viewOwnWorkingWeek',
  ViewPatient = 'viewPatient',
  ViewPatientStatisticsDashboardWidget = 'viewPatientStatisticsDashboardWidget',
  ViewPaymentMethod = 'viewPaymentMethod',
  ViewPaymentProvider = 'viewPaymentProvider',
  ViewPaymentTerminal = 'viewPaymentTerminal',
  ViewPaymentTransaction = 'viewPaymentTransaction',
  ViewPendingPatientsDashboardWidget = 'viewPendingPatientsDashboardWidget',
  ViewPractice = 'viewPractice',
  ViewPracticeAppointmentsBreakdownDashboardWidget = 'viewPracticeAppointmentsBreakdownDashboardWidget',
  ViewPracticeLocalisationSetting = 'viewPracticeLocalisationSetting',
  ViewPracticeLocation = 'viewPracticeLocation',
  ViewPracticeLocationReviewRatingsDashboardWidget = 'viewPracticeLocationReviewRatingsDashboardWidget',
  ViewPracticeLocationReviewsWordCloudDashboardWidget = 'viewPracticeLocationReviewsWordCloudDashboardWidget',
  ViewPracticeProductionDashboardWidget = 'viewPracticeProductionDashboardWidget',
  ViewPractitionerAppointmentsDashboardWidget = 'viewPractitionerAppointmentsDashboardWidget',
  ViewPractitionerHourlyRateDashboardWidget = 'viewPractitionerHourlyRateDashboardWidget',
  ViewPractitionerNewPatientsDashboardWidget = 'viewPractitionerNewPatientsDashboardWidget',
  ViewPractitionerRebookRateDashboardWidget = 'viewPractitionerRebookRateDashboardWidget',
  ViewPractitionerReviewRatingsDashboardWidget = 'viewPractitionerReviewRatingsDashboardWidget',
  ViewPractitionerUtilisationDashboardWidget = 'viewPractitionerUtilisationDashboardWidget',
  ViewPrice = 'viewPrice',
  ViewPriceList = 'viewPriceList',
  ViewRating = 'viewRating',
  ViewRecall = 'viewRecall',
  ViewRecentOnlineBookingsDashboardWidget = 'viewRecentOnlineBookingsDashboardWidget',
  ViewRecurringTask = 'viewRecurringTask',
  ViewReferral = 'viewReferral',
  ViewReferralSource = 'viewReferralSource',
  ViewReferralsReports = 'viewReferralsReports',
  ViewReviewResponse = 'viewReviewResponse',
  ViewReviewTemplate = 'viewReviewTemplate',
  ViewRole = 'viewRole',
  ViewRoom = 'viewRoom',
  ViewRosterEvent = 'viewRosterEvent',
  ViewShiftType = 'viewShiftType',
  ViewShortMessage = 'viewShortMessage',
  ViewStaff = 'viewStaff',
  ViewStaffUser = 'viewStaffUser',
  ViewSterilization = 'viewSterilization',
  ViewStockAdjustment = 'viewStockAdjustment',
  ViewStockDetail = 'viewStockDetail',
  ViewStockHistory = 'viewStockHistory',
  ViewSubscription = 'viewSubscription',
  ViewTask = 'viewTask',
  ViewTemplate = 'viewTemplate',
  ViewToothCondition = 'viewToothCondition',
  ViewTreatment = 'viewTreatment',
  ViewTreatmentPlan = 'viewTreatmentPlan',
  ViewUser = 'viewUser',
  ViewUserContacts = 'viewUserContacts',
  ViewUserPracticeProfile = 'viewUserPracticeProfile',
  ViewWaitingAppointment = 'viewWaitingAppointment',
  ViewWaitlist = 'viewWaitlist',
  ViewWorkflow = 'viewWorkflow',
  ViewWorkingDay = 'viewWorkingDay',
  ViewWorkingWeek = 'viewWorkingWeek'
}

export type PermissionDetails = {
  __typename?: 'PermissionDetails';
  action: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  resource?: Maybe<Scalars['String']['output']>;
  roles?: Maybe<Array<Role>>;
  slug?: Maybe<Permission>;
};

export type PermissionInput = {
  permission: Permission;
  role: PracticeRole;
};

export type PhoneInput = {
  /** Country code for the phone number, e.g 61 for Australia, or 1 for America */
  code?: InputMaybe<Scalars['String']['input']>;
  /** Phone number */
  phone?: InputMaybe<Scalars['String']['input']>;
  /** Country code in ISO3166 Alpha-2 format, e.g AU, US, NZ */
  short?: InputMaybe<Scalars['String']['input']>;
};

export type PieChart = {
  __typename?: 'PieChart';
  /** Patient Name */
  type?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['Float']['output']>;
};

/** Autogenerated input type of PinNote */
export type PinNoteInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** GUID of the Note to pin */
  note: Scalars['ID']['input'];
};

/** Autogenerated return type of PinNote. */
export type PinNotePayload = {
  __typename?: 'PinNotePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  fieldErrors?: Maybe<Array<FieldError>>;
  note?: Maybe<Note>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type Practice = AnyNode & {
  __typename?: 'Practice';
  account?: Maybe<Account>;
  accountPlan?: Maybe<AccountPlan>;
  activeRoomsCount?: Maybe<Scalars['Int']['output']>;
  allFeatures?: Maybe<Array<Feature>>;
  /** Search Appointments */
  appointments?: Maybe<AppointmentConnection>;
  catalogueItems?: Maybe<CatalogueItemConnection>;
  configuration?: Maybe<PracticeConfiguration>;
  /** @deprecated This is now on PracticeLocation and will be removed */
  coverPhoto?: Maybe<CoverPhotoUploaderAwsLinks>;
  createdAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  currency?: Maybe<Scalars['String']['output']>;
  defaultLocale?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  emailAccount?: Maybe<Scalars['String']['output']>;
  features?: Maybe<Array<FeatureDetails>>;
  guid: Scalars['ID']['output'];
  hasSmsCredits: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  industry?: Maybe<Industry>;
  location?: Maybe<Location>;
  /** @deprecated This is now on PracticeLocation and will be removed */
  logo?: Maybe<LogoUploaderAwsLinks>;
  name?: Maybe<Scalars['String']['output']>;
  overseas?: Maybe<Scalars['Boolean']['output']>;
  /** @deprecated Use UPC resolver instead */
  patient?: Maybe<Patient>;
  phone?: Maybe<Scalars['String']['output']>;
  practiceLocalisationSetting?: Maybe<PracticeLocalisationSetting>;
  /** @deprecated Use the UPC resolver instead */
  practitioners?: Maybe<Array<UserPracticeLink>>;
  referralSources?: Maybe<Array<ReferralSource>>;
  /** @deprecated Use the Recalls resolver */
  referrals?: Maybe<Array<Referral>>;
  taxAmount?: Maybe<Scalars['Int']['output']>;
  timezone?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  /** Unique identifier of the message */
  uuid: Scalars['String']['output'];
  website?: Maybe<Scalars['String']['output']>;
};


export type PracticeAppointmentsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  attributes?: InputMaybe<CalendarSearchInput>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type PracticeCatalogueItemsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type PracticePatientArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
};


export type PracticeReferralSourcesArgs = {
  fromDate?: InputMaybe<Scalars['ISO8601Date']['input']>;
  toDate?: InputMaybe<Scalars['ISO8601Date']['input']>;
};


export type PracticeReferralsArgs = {
  byReferralSource?: InputMaybe<Scalars['Int']['input']>;
  fromDate?: InputMaybe<Scalars['ISO8601Date']['input']>;
  toDate?: InputMaybe<Scalars['ISO8601Date']['input']>;
};

export type PracticeConfiguration = {
  __typename?: 'PracticeConfiguration';
  allowedIpRange?: Maybe<Scalars['String']['output']>;
  allowedIpRangeBypassBy?: Maybe<Array<Scalars['String']['output']>>;
  /** How long before an appointment should the confirmation be sent out? (in seconds) */
  appointmentConfirmationScheduleInterval?: Maybe<Scalars['Int']['output']>;
  /** Toggle the appointment confirmed notification for patients after confirming attendance */
  appointmentConfirmedNotification?: Maybe<Scalars['Boolean']['output']>;
  /** How long before an appointment should the reminder be sent out? (in seconds) */
  appointmentReminderScheduleInterval?: Maybe<Scalars['Int']['output']>;
  /** Toggle the appointment send immediate email default */
  appointmentSendImmediateEmail?: Maybe<Scalars['Boolean']['output']>;
  /** Toggle the appointment send immediate sms default */
  appointmentSendImmediateSms?: Maybe<Scalars['Boolean']['output']>;
  /** Whether or not this practice wants to link and complete recalls automatically to appointments from online bookings */
  autoCompleteRecalls?: Maybe<Scalars['Boolean']['output']>;
  /** The email addresses to send the daily summary to */
  dailySummaryEmails?: Maybe<Scalars['String']['output']>;
  /** Array of dashboard annotations */
  dashboardAnnotations?: Maybe<Array<DashboardAnnotation>>;
  /** Default communication settings */
  defaultCommunicationSettings?: Maybe<Array<CommunicationSettings>>;
  /** Email addresses to send notifications after a patient makes a deposit */
  depositReceiptEmails?: Maybe<Scalars['String']['output']>;
  /** Toggle the appointment confirmations default */
  enableAppointmentConfirmations?: Maybe<Scalars['Boolean']['output']>;
  /** The time in seconds where the enable_appointment_confirmations is valid for */
  enableAppointmentConfirmationsInterval?: Maybe<Scalars['Int']['output']>;
  /** Toggle the appointment email confirmations default */
  enableAppointmentEmailConfirmations?: Maybe<Scalars['Boolean']['output']>;
  /** Toggle the appointment email reminders default */
  enableAppointmentEmailReminders?: Maybe<Scalars['Boolean']['output']>;
  /** Toggle the appointment sms confirmations default */
  enableAppointmentSmsConfirmations?: Maybe<Scalars['Boolean']['output']>;
  /** Toggle the appointment sms reminders default */
  enableAppointmentSmsReminders?: Maybe<Scalars['Boolean']['output']>;
  /** Whether or not this practice wants to enable auto notes to be created when completing treatment plan items */
  enableAutoNotes?: Maybe<Scalars['Boolean']['output']>;
  /** Scanners to be displayed for the practice the ImageScanList component */
  enabledScanners?: Maybe<Array<Scanner>>;
  /** The url to redirect to when a form gets completed */
  formCompletedUrl?: Maybe<Scalars['String']['output']>;
  /** The email address to send notifications to when a form gets completed */
  formEmailNotifications?: Maybe<Scalars['String']['output']>;
  hasEmailSettings: Scalars['Boolean']['output'];
  hasSmsCredits: Scalars['Boolean']['output'];
  id?: Maybe<Scalars['ID']['output']>;
  invoiceSettings?: Maybe<InvoiceSetting>;
  /** The practices preferred labels used for common entities, like appointment */
  labels?: Maybe<LabelConfiguration>;
  localization?: Maybe<Localization>;
  /** The amount of credits left before the practice is warned */
  lowCreditsWarning?: Maybe<Scalars['Int']['output']>;
  mailSettings?: Maybe<MailSettings>;
  /** Toggle whethere online bookings will send email confirmation by default */
  onlineAppointmentEmailConfirmations?: Maybe<Scalars['Boolean']['output']>;
  /** Toggle whether online bookings will send email reminders by default */
  onlineAppointmentEmailReminders?: Maybe<Scalars['Boolean']['output']>;
  /** Toggle whether online bookings will send sms confirmation by default */
  onlineAppointmentSmsConfirmations?: Maybe<Scalars['Boolean']['output']>;
  /** Toggle whether online bookings will send sms reminders by default */
  onlineAppointmentSmsReminders?: Maybe<Scalars['Boolean']['output']>;
  onlineBookingSettings?: Maybe<OnlineBookingSetting>;
  /** Toggle if online bookings will auto confirm appointments */
  onlineBookingsAutoConfirm?: Maybe<Scalars['Boolean']['output']>;
  /** The time in seconds where the online_bookings_auto_confirm is valid for */
  onlineBookingsAutoConfirmInterval?: Maybe<Scalars['Int']['output']>;
  /** Toggle whether patients will receive notifications from online bookings */
  onlineBookingsNotifyPatient?: Maybe<Scalars['Boolean']['output']>;
  /** Toggle whether staff will receive notifications from online bookings */
  onlineBookingsNotifyPractitioner?: Maybe<Scalars['Boolean']['output']>;
  /** Toggle whether staff will receive notifications from online bookings for new patients */
  onlineBookingsNotifyPractitionerNewPatient?: Maybe<Scalars['Boolean']['output']>;
  /** What open hours are displayed for this practice */
  openHours?: Maybe<Scalars['String']['output']>;
  /** Color for the patient app */
  patientAppPrimaryColor?: Maybe<Scalars['String']['output']>;
  /** Whether or not patients can cancel appointments */
  patientCancelAppointment?: Maybe<Scalars['Boolean']['output']>;
  /** Whether or not this practice wants draft-state notes to be automatically published at midnight */
  publishDraftNotes?: Maybe<Scalars['Boolean']['output']>;
  /** Events on which push notifications should be sent to the user */
  pushNotificationEvents?: Maybe<Array<PushNotificationEvent>>;
  recallSettings?: Maybe<RecallSettings>;
  /** Whether or not to send a daily summary of appointments to all practitioners */
  sendDailySummary?: Maybe<Scalars['Boolean']['output']>;
  /** Reserverd SMS number for the practice. This needs to be configured by support. */
  smsNumber?: Maybe<Scalars['String']['output']>;
  /** The selected SMS provider for the practice */
  smsProvider?: Maybe<SmsProvider>;
  /** Links to this practices social media accounts */
  socialLinks?: Maybe<SocialMediaLinks>;
  /** How much tax to charge on products and services (GST/VAT) */
  taxAmount?: Maybe<Scalars['Float']['output']>;
  /** Appointments with this set to true will create the reminder comms even if its unconfirmed */
  unconfirmedAppointmentSendReminders?: Maybe<Scalars['Boolean']['output']>;
};

export type PracticeConfigurationInput = {
  /** Restrict IP range for the practice */
  allowedIpRange?: InputMaybe<Scalars['String']['input']>;
  /** Allow IP range restriction to be bypassed by configured UPCs for the practice */
  allowedIpRangeBypassBy?: InputMaybe<Array<Scalars['String']['input']>>;
  /** How long before an appointment should the confirmation be sent out? (in seconds) */
  appointmentConfirmationScheduleInterval?: InputMaybe<Scalars['Int']['input']>;
  /** Toggle the appointment confirmed notification to patients after confirming attendance */
  appointmentConfirmedNotification?: InputMaybe<Scalars['Boolean']['input']>;
  /** How long before an appointment should the reminder be sent out? (in seconds) */
  appointmentReminderScheduleInterval?: InputMaybe<Scalars['Int']['input']>;
  /** Toggle the appointment email reminders default */
  appointmentSendImmediateEmail?: InputMaybe<Scalars['Boolean']['input']>;
  /** Toggle the appointment sms reminders default */
  appointmentSendImmediateSms?: InputMaybe<Scalars['Boolean']['input']>;
  autoCompleteRecalls?: InputMaybe<Scalars['Boolean']['input']>;
  dailySummaryEmails?: InputMaybe<Scalars['String']['input']>;
  /** Annotations to be displayed on the dashboard */
  dashboardAnnotations?: InputMaybe<Array<DashboardAnnotationInput>>;
  /** Default communication settings */
  defaultCommunicationSettings?: InputMaybe<CommunicationSettingsInput>;
  depositReceiptEmails?: InputMaybe<Scalars['String']['input']>;
  /** Toggle the appointment confirmations required default */
  enableAppointmentConfirmations?: InputMaybe<Scalars['Boolean']['input']>;
  /** The time in seconds where the enable_appointment_confirmations is valid for */
  enableAppointmentConfirmationsInterval?: InputMaybe<Scalars['Int']['input']>;
  /** Toggle the appointment email confirmation default */
  enableAppointmentEmailConfirmations?: InputMaybe<Scalars['Boolean']['input']>;
  /** Toggle the appointment sms reminders default */
  enableAppointmentEmailReminders?: InputMaybe<Scalars['Boolean']['input']>;
  /** Toggle the appointment sms confirmation default */
  enableAppointmentSmsConfirmations?: InputMaybe<Scalars['Boolean']['input']>;
  /** Toggle the appointment sms reminders default */
  enableAppointmentSmsReminders?: InputMaybe<Scalars['Boolean']['input']>;
  enableAutoNotes?: InputMaybe<Scalars['Boolean']['input']>;
  /** Scanners to be displayed for the practice the ImageScanList component */
  enabledScanners?: InputMaybe<Array<Scanner>>;
  formCompletedUrl?: InputMaybe<Scalars['String']['input']>;
  formEmailNotifications?: InputMaybe<Scalars['String']['input']>;
  /** The amount of credits left before the practice is warned */
  lowCreditsWarning?: InputMaybe<Scalars['Int']['input']>;
  /** Toggle whethere online bookings will send email confirmation by default */
  onlineAppointmentEmailConfirmations?: InputMaybe<Scalars['Boolean']['input']>;
  /** Toggle whether online bookings will send email reminders by default */
  onlineAppointmentEmailReminders?: InputMaybe<Scalars['Boolean']['input']>;
  /** Toggle whether online bookings will send sms confirmation by default */
  onlineAppointmentSmsConfirmations?: InputMaybe<Scalars['Boolean']['input']>;
  /** Toggle whether online bookings will send sms reminders by default */
  onlineAppointmentSmsReminders?: InputMaybe<Scalars['Boolean']['input']>;
  /** Toggle if online bookings will auto confirm appointments */
  onlineBookingsAutoConfirm?: InputMaybe<Scalars['Boolean']['input']>;
  /** The time in seconds where the online_bookings_auto_confirm is valid for */
  onlineBookingsAutoConfirmInterval?: InputMaybe<Scalars['Int']['input']>;
  /** Toggle whether patients will receive notifications from online bookings */
  onlineBookingsNotifyPatient?: InputMaybe<Scalars['Boolean']['input']>;
  /** Toggle whether staff will receive notifications from online bookings */
  onlineBookingsNotifyPractitioner?: InputMaybe<Scalars['Boolean']['input']>;
  /** Toggle whether staff will receive notifications from online bookings for new patients */
  onlineBookingsNotifyPractitionerNewPatient?: InputMaybe<Scalars['Boolean']['input']>;
  openHours?: InputMaybe<Scalars['String']['input']>;
  /** Color for the patient app */
  patientAppPrimaryColor?: InputMaybe<Scalars['String']['input']>;
  patientCancelAppointment?: InputMaybe<Scalars['Boolean']['input']>;
  publishDraftNotes?: InputMaybe<Scalars['Boolean']['input']>;
  /** The push notification events to be enabled/disabled */
  pushNotificationEvents?: InputMaybe<Array<PushNotificationEvent>>;
  sendDailySummary?: InputMaybe<Scalars['Boolean']['input']>;
  socialLinks?: InputMaybe<SocialMediaLinkInput>;
  taxAmount?: InputMaybe<Scalars['Float']['input']>;
  /** Appointments with this set to true will create the reminder comms even if its unconfirmed */
  unconfirmedAppointmentSendReminders?: InputMaybe<Scalars['Boolean']['input']>;
};

/** The connection type for Practice. */
export type PracticeConnection = {
  __typename?: 'PracticeConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<PracticeEdge>>>;
  /** IDs of ALL results, regardless of pagination */
  ids: Array<Scalars['ID']['output']>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Practice>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Returns total count of items in list */
  totalCount: Scalars['Int']['output'];
};

export type PracticeConnectionInput = {
  lastMessageReadAt?: InputMaybe<Scalars['ISO8601Date']['input']>;
  practiceLocationId?: InputMaybe<Scalars['ID']['input']>;
  priceListId?: InputMaybe<Scalars['ID']['input']>;
  primaryPractitionerId?: InputMaybe<Scalars['ID']['input']>;
  /** Set to true to remove the UPC photo */
  removePhoto?: InputMaybe<Scalars['Boolean']['input']>;
  secondaryPractitionerId?: InputMaybe<Scalars['ID']['input']>;
  stickyNote?: InputMaybe<Scalars['String']['input']>;
  unreadMessageCount?: InputMaybe<Scalars['Int']['input']>;
};

/** An edge in a connection. */
export type PracticeEdge = {
  __typename?: 'PracticeEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<Practice>;
};

export type PracticeInput = {
  configuration?: InputMaybe<PracticeConfigurationInput>;
  /** Cover photo (base64) */
  coverPhoto?: InputMaybe<Scalars['String']['input']>;
  defaultLocale?: InputMaybe<Scalars['String']['input']>;
  localization?: InputMaybe<PracticeLocalizationInput>;
  location?: InputMaybe<LocationInput>;
  /** Base64 logo */
  logo?: InputMaybe<Scalars['String']['input']>;
  /** Name to display for this practice */
  name?: InputMaybe<Scalars['String']['input']>;
  /** Contact number to display */
  phone?: InputMaybe<Scalars['String']['input']>;
  recallSettings?: InputMaybe<PracticeRecallSettingsInput>;
  /** Flag to remove logo */
  removeCoverPhoto?: InputMaybe<Scalars['Boolean']['input']>;
  /** Flag to remove logo */
  removeLogo?: InputMaybe<Scalars['Boolean']['input']>;
  /** Timezone (dates will be localized to this) */
  timezone?: InputMaybe<Scalars['String']['input']>;
  /** Website to display for this practice */
  website?: InputMaybe<Scalars['String']['input']>;
};

export type PracticeLocalisationSetting = {
  __typename?: 'PracticeLocalisationSetting';
  appointmentIcons?: Maybe<AppointmentIconConfiguration>;
  country?: Maybe<Scalars['String']['output']>;
  currency?: Maybe<Scalars['String']['output']>;
  defaultLocale?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  labels?: Maybe<LabelConfiguration>;
  mobileRegionCode?: Maybe<Scalars['String']['output']>;
  mobileRegionShort?: Maybe<Scalars['String']['output']>;
  practiceId: Scalars['ID']['output'];
  taxAmount?: Maybe<Scalars['Float']['output']>;
  taxCode?: Maybe<Scalars['String']['output']>;
  timezone?: Maybe<Scalars['String']['output']>;
};

export type PracticeLocalizationInput = {
  appointmentIcons?: InputMaybe<AppointmentIconConfigurationInput>;
  country?: InputMaybe<Scalars['String']['input']>;
  labels?: InputMaybe<LabelConfigurationInput>;
  mobileRegionCode?: InputMaybe<Scalars['String']['input']>;
  mobileRegionShort?: InputMaybe<Scalars['String']['input']>;
  taxCode?: InputMaybe<Scalars['String']['input']>;
  timezone?: InputMaybe<Scalars['String']['input']>;
};

export type PracticeLocation = AnyNode & {
  __typename?: 'PracticeLocation';
  account?: Maybe<Account>;
  /** Account GUID */
  accountGuid?: Maybe<Scalars['ID']['output']>;
  address?: Maybe<LocationAddressable>;
  /** Tag Colour */
  colour?: Maybe<Scalars['String']['output']>;
  /** The cover photo of the practice location */
  coverPhoto?: Maybe<Scalars['String']['output']>;
  /** AWS URL for the medium processed version of cover_photo */
  coverPhotoMedium?: Maybe<Scalars['String']['output']>;
  /** AWS URL for the small processed version of cover_photo */
  coverPhotoSmall?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  default?: Maybe<Scalars['Boolean']['output']>;
  discardedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  /** Practice Location ID */
  id: Scalars['ID']['output'];
  /** The logo of the practice location */
  logo?: Maybe<Scalars['String']['output']>;
  /** AWS URL for the medium processed version of logo */
  logoMedium?: Maybe<Scalars['String']['output']>;
  /** AWS URL for the small processed version of logo */
  logoSmall?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  openingHours?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  practice?: Maybe<Practice>;
  /** Practice GUID */
  practiceGuid?: Maybe<Scalars['ID']['output']>;
  /** Practice ID */
  practiceId?: Maybe<Scalars['Int']['output']>;
  primaryPractitioner?: Maybe<UserPracticeLink>;
  /** Default Primary Practitioner ID */
  primaryPractitionerId?: Maybe<Scalars['Int']['output']>;
  shortName?: Maybe<Scalars['String']['output']>;
  socialLinks?: Maybe<SocialLinks>;
  /** Theme Colour */
  theme?: Maybe<Theme>;
  updatedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  /** Unique identifier of the message */
  uuid: Scalars['String']['output'];
  website?: Maybe<Scalars['String']['output']>;
};

/** The connection type for PracticeLocation. */
export type PracticeLocationConnection = {
  __typename?: 'PracticeLocationConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<PracticeLocationEdge>>>;
  /** IDs of ALL results, regardless of pagination */
  ids: Array<Scalars['ID']['output']>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<PracticeLocation>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Returns total count of items in list */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type PracticeLocationEdge = {
  __typename?: 'PracticeLocationEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<PracticeLocation>;
};

export type PracticeLocationsInput = {
  address?: InputMaybe<LocationInput>;
  /** Archive the invoice setting */
  archive?: InputMaybe<Scalars['Boolean']['input']>;
  colour?: InputMaybe<Scalars['String']['input']>;
  /** Base64 Logo to upload */
  coverPhoto?: InputMaybe<Scalars['Upload']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  /** Base64 Logo to upload */
  logo?: InputMaybe<Scalars['Upload']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  openingHours?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  /** Practice Location to update */
  practiceLocation?: InputMaybe<Scalars['ID']['input']>;
  /** Default Primary Practitioner for new patients */
  primaryPractitionerId?: InputMaybe<Scalars['ID']['input']>;
  shortName?: InputMaybe<Scalars['String']['input']>;
  socialLinks?: InputMaybe<SocialLinksInput>;
  theme?: InputMaybe<ThemeInput>;
  website?: InputMaybe<Scalars['String']['input']>;
};

export type PracticeMailSettings = {
  __typename?: 'PracticeMailSettings';
  address?: Maybe<Scalars['String']['output']>;
  authentication?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['ISO8601DateTime']['output'];
  domain?: Maybe<Scalars['String']['output']>;
  enableStarttlsAuto?: Maybe<Scalars['Boolean']['output']>;
  fromEmailAddress?: Maybe<Scalars['String']['output']>;
  guid: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  password?: Maybe<Scalars['String']['output']>;
  port?: Maybe<Scalars['String']['output']>;
  practiceId: Scalars['ID']['output'];
  updatedAt: Scalars['ISO8601DateTime']['output'];
  userName?: Maybe<Scalars['String']['output']>;
};

/** A single practice preference */
export type PracticePreference = {
  __typename?: 'PracticePreference';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  value?: Maybe<Scalars['JSON']['output']>;
};

export type PracticeProfessional = {
  __typename?: 'PracticeProfessional';
  avatarColour?: Maybe<Scalars['String']['output']>;
  calendarAvailability: Scalars['String']['output'];
  email?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  fullName?: Maybe<Scalars['String']['output']>;
  fullNameWithTitle?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  initials?: Maybe<Scalars['String']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  mobilePhone?: Maybe<Scalars['String']['output']>;
  policies?: Maybe<PracticeProfessionalPolicy>;
  practice: Practice;
  professional: Professional;
  role?: Maybe<Scalars['String']['output']>;
  state: Scalars['String']['output'];
};

/** The connection type for PracticeProfessional. */
export type PracticeProfessionalConnection = {
  __typename?: 'PracticeProfessionalConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<PracticeProfessionalEdge>>>;
  /** IDs of ALL results, regardless of pagination */
  ids: Array<Scalars['ID']['output']>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<PracticeProfessional>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Returns total count of items in list */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type PracticeProfessionalEdge = {
  __typename?: 'PracticeProfessionalEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<PracticeProfessional>;
};

export type PracticeProfessionalPolicy = {
  __typename?: 'PracticeProfessionalPolicy';
  create?: Maybe<Scalars['Boolean']['output']>;
  destroy?: Maybe<Scalars['Boolean']['output']>;
  edit?: Maybe<Scalars['Boolean']['output']>;
  index?: Maybe<Scalars['Boolean']['output']>;
  new?: Maybe<Scalars['Boolean']['output']>;
  show?: Maybe<Scalars['Boolean']['output']>;
  update?: Maybe<Scalars['Boolean']['output']>;
};

export type PracticeRecallSettingsInput = {
  /** Allows recalls to be automatically triggered based on recall settings */
  autoProcess?: InputMaybe<Scalars['Boolean']['input']>;
  /** The maximum consecutive missed appointments before a patient is ignored for recalls */
  missedAppointments?: InputMaybe<Scalars['Int']['input']>;
  /** Only select patients who are active */
  onlyActivePatients?: InputMaybe<Scalars['Boolean']['input']>;
  /** The field, last_exam or last_visit, that will be used to determine if a patient should be recalled */
  recallTrigger?: InputMaybe<Scalars['String']['input']>;
  /** Skip patients who have active recalls */
  skipActiveRecall?: InputMaybe<Scalars['Boolean']['input']>;
  /** Skip patients who have prepared recalls */
  skipUpcoming?: InputMaybe<Scalars['Boolean']['input']>;
  /** Skip patients who have drop off time set */
  skipWithDropOff?: InputMaybe<Scalars['Boolean']['input']>;
  /** The practice timezone time of day when the recall worker should trigger to generate recall jobs */
  triggerTime?: InputMaybe<Scalars['String']['input']>;
};

export enum PracticeRole {
  Admin = 'admin',
  Assistant = 'assistant',
  BackOffice = 'back_office',
  Contact = 'contact',
  External = 'external',
  Owner = 'owner',
  Patient = 'patient',
  PracticeManager = 'practice_manager',
  Practitioner = 'practitioner',
  Receptionist = 'receptionist'
}

export type PracticeSwitch = {
  __typename?: 'PracticeSwitch';
  /** The ID of the account */
  accountId?: Maybe<Scalars['ID']['output']>;
  /** The subdomain of the account */
  accountName?: Maybe<Scalars['String']['output']>;
  /** The GUID of the UPC */
  guid?: Maybe<Scalars['ID']['output']>;
  /** The custom ID of the UPC for Apollo */
  id?: Maybe<Scalars['ID']['output']>;
  practiceCoverPhoto?: Maybe<CoverPhotoUploaderAwsLinks>;
  /** The ID of the practice */
  practiceId?: Maybe<Scalars['ID']['output']>;
  /** The name of the practice */
  practiceIndustryName?: Maybe<Scalars['String']['output']>;
  /** The location of the practice */
  practiceLocation?: Maybe<PracticeLocation>;
  /** The name of the practice */
  practiceLogo?: Maybe<LogoUploaderAwsLinks>;
  /** The name of the practice */
  practiceName?: Maybe<Scalars['String']['output']>;
  /** The role of the UPC */
  role?: Maybe<Role>;
  /** The state of the UPC */
  state?: Maybe<Scalars['String']['output']>;
  /** The name of the user */
  userName?: Maybe<Scalars['String']['output']>;
};

export type PreferenceInput = {
  name: Scalars['String']['input'];
  value?: InputMaybe<Scalars['JSON']['input']>;
};

export type Preferences = {
  __typename?: 'Preferences';
  /** Auto show quick availability in the calendar */
  calendarAutoshowQuickAvailability?: Maybe<Scalars['Boolean']['output']>;
  /** The column widths for calendar for the practitioners */
  calendarColumnWidth?: Maybe<Scalars['Int']['output']>;
  /** When calendar is opened, always default to today */
  calendarDefaultToday?: Maybe<Scalars['Boolean']['output']>;
  /** The FullCalendar default view type */
  calendarDefaultView?: Maybe<Scalars['String']['output']>;
  /** Display colours in the calendar based on appointment cancelled status */
  calendarDisplayCancelledColour?: Maybe<Scalars['Boolean']['output']>;
  /** Display colours in the calendar based on appointment completed status */
  calendarDisplayCompletedColour?: Maybe<Scalars['Boolean']['output']>;
  /** Excluded cancelled appointments from being displayed in the calendar */
  calendarExcludeCancelled?: Maybe<Scalars['Boolean']['output']>;
  /** The excluded practitioners from the calendar */
  calendarExcludePractitionerIds?: Maybe<Array<Scalars['ID']['output']>>;
  /** Hide the Saturday column in the calendar */
  calendarHideSaturday?: Maybe<Scalars['Boolean']['output']>;
  /** Hide the Sunday column in the calendar */
  calendarHideSunday?: Maybe<Scalars['Boolean']['output']>;
  /** The initial date the calendar will open to */
  calendarInitialDate?: Maybe<Scalars['String']['output']>;
  /** The selected practitioners to view in the calendar */
  calendarPractitionerIds?: Maybe<Array<Scalars['ID']['output']>>;
  /** Practitioners IDs that have been manually sorted to an index */
  calendarPractitionerSortedIds?: Maybe<Array<ItemSortIndex>>;
  /** Selected calendar resource view */
  calendarResourceType?: Maybe<CalendarResource>;
  /** The selected practitioners to view in the calendar */
  calendarRoomIds?: Maybe<Array<Scalars['ID']['output']>>;
  /** Open the appointment drawer on single click in the calendar */
  calendarSingleClickDrawer?: Maybe<Scalars['Boolean']['output']>;
  /** The time in minutes duration for each appointment slot in the calendar */
  calendarSlotDuration?: Maybe<Scalars['String']['output']>;
  /** The closing time in 24 hour time for the calendar */
  calendarSlotMaxTime?: Maybe<Scalars['Int']['output']>;
  /** The opening time in 24 hour time for the calendar */
  calendarSlotMinTime?: Maybe<Scalars['Int']['output']>;
  /** Stores the selected favourite catalogue items */
  catalogueItemFavoriteIds?: Maybe<Array<Scalars['ID']['output']>>;
  /** Dentition - Both, Permanent or Deciduous */
  chartDentition?: Maybe<DentalChartConfigDentition>;
  /** Graphics mode - low, medium or high */
  chartGraphicsMode?: Maybe<DentalChartConfigGraphicsMode>;
  /** Graphics Style - Anatomical or Graphical */
  chartGraphicsStyle?: Maybe<DentalChartConfigGraphicsStyle>;
  /** Enable material colours in the dental chart */
  chartMaterialColours?: Maybe<Scalars['Boolean']['output']>;
  /** Tooth Surface - Buccal or Palatal */
  chartToothSurface?: Maybe<DentalChartConfigToothSurface>;
  /** Stores the selected dashboard widgets and grid layout */
  dashboardWidgets?: Maybe<DashboardWidgetLayout>;
  /** The GUID of the UPC */
  guid?: Maybe<Scalars['ID']['output']>;
  /** Hide the title of notes with no content */
  hideEmptyNotesTitle?: Maybe<Scalars['Boolean']['output']>;
  /** The ID of the UPC */
  id?: Maybe<Scalars['ID']['output']>;
  /** Show patient treatment notes sider in the patient profile */
  showPatientTreatmentNotes?: Maybe<Scalars['Boolean']['output']>;
  /** Show recall tasks in the task list */
  showRecallTasks?: Maybe<Scalars['Boolean']['output']>;
  /** Show all task states, not just in_progress or pending */
  showTaskHistory?: Maybe<Scalars['Boolean']['output']>;
  /** Only show tasks assigned to current user */
  showUserTasks?: Maybe<Scalars['Boolean']['output']>;
};

export type Price = {
  __typename?: 'Price';
  /** Amount of tax included (if present) */
  addedTax?: Maybe<Scalars['Float']['output']>;
  /** Base amount before any calculations being applied */
  amount?: Maybe<Scalars['Float']['output']>;
  catalogueItem?: Maybe<CatalogueItem>;
  /** The catalogue item that this price is for */
  catalogueItemId: Scalars['ID']['output'];
  createdAt: Scalars['ISO8601DateTime']['output'];
  /** The amount of discount to apply */
  discount?: Maybe<Scalars['Float']['output']>;
  guid: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  /** Name of the pricelist */
  name?: Maybe<Scalars['String']['output']>;
  /** Amount excluding any tax (if present) */
  priceExTax?: Maybe<Scalars['Float']['output']>;
  /** Amount including tax (if present) */
  priceIncTax?: Maybe<Scalars['Float']['output']>;
  /** Flag to indicate if base price is inclusive of tax */
  priceIncludesTax?: Maybe<Scalars['Boolean']['output']>;
  priceList?: Maybe<PriceList>;
  /** The price list that this price is for */
  priceListId: Scalars['ID']['output'];
  /** The amount of tax to apply */
  taxAmount?: Maybe<Scalars['Float']['output']>;
  /** Flag to indicate that there is a tax being applied */
  taxCode?: Maybe<Scalars['Boolean']['output']>;
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

export type PriceAssignInput = {
  /** Active boolean */
  active?: InputMaybe<Scalars['Boolean']['input']>;
  /** Price for item */
  amount?: InputMaybe<Scalars['Float']['input']>;
  /** Discount rate */
  discount?: InputMaybe<Scalars['Float']['input']>;
  /** Price includes tax */
  priceIncludesTax?: InputMaybe<Scalars['Boolean']['input']>;
  /** Tax amount */
  taxAmount?: InputMaybe<Scalars['Float']['input']>;
  /** Tax Code */
  taxCode?: InputMaybe<Scalars['Boolean']['input']>;
};

/** The connection type for Price. */
export type PriceConnection = {
  __typename?: 'PriceConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<PriceEdge>>>;
  /** IDs of ALL results, regardless of pagination */
  ids: Array<Scalars['ID']['output']>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Price>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Returns total count of items in list */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type PriceEdge = {
  __typename?: 'PriceEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<Price>;
};

/**
 * Price Lists contain prices for Catalogue Items, and can be
 * changed at any time to assign a different set of prices to
 * the products and services in your catalogue.
 *
 * All products and services have their prices set by a default price
 * list, but you can change which price list is active.
 */
export type PriceList = {
  __typename?: 'PriceList';
  createdAt: Scalars['ISO8601DateTime']['output'];
  guid: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  /** Returns true if this is the default pricelist. You cannot edit or archive these pricelists */
  isDefault?: Maybe<Scalars['Boolean']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  practice: Practice;
  /**
   * Prefix used when adding items to a quote or invoice. If the code is 521 and
   * the pricelist has a prefix of D it will add the code D521
   */
  prefix?: Maybe<Scalars['String']['output']>;
  prices?: Maybe<Array<Price>>;
  state?: Maybe<PriceListState>;
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

/** The connection type for PriceList. */
export type PriceListConnection = {
  __typename?: 'PriceListConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<PriceListEdge>>>;
  /** IDs of ALL results, regardless of pagination */
  ids: Array<Scalars['ID']['output']>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<PriceList>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Returns total count of items in list */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type PriceListEdge = {
  __typename?: 'PriceListEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<PriceList>;
};

export type PriceListInput = {
  /** A descriptive name for what this price list is used for */
  name?: InputMaybe<Scalars['String']['input']>;
  /** Prefix used to prepend to catalogue item codes */
  prefix?: InputMaybe<Scalars['String']['input']>;
  /** Do the prices include tax? */
  priceIncludesTax?: InputMaybe<Scalars['Boolean']['input']>;
  /** Tax amount for this price list */
  taxAmount?: InputMaybe<Scalars['Float']['input']>;
  /** Tax Code I have no idea what this does */
  taxCode?: InputMaybe<Scalars['Boolean']['input']>;
};

export enum PriceListState {
  Active = 'active',
  Archived = 'archived'
}

export enum PriceListStateEvent {
  Activate = 'activate',
  Archive = 'archive'
}

/** Autogenerated input type of PrintLetter */
export type PrintLetterInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  letterId: Scalars['ID']['input'];
};

/** Autogenerated return type of PrintLetter. */
export type PrintLetterPayload = {
  __typename?: 'PrintLetterPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  fieldErrors?: Maybe<Array<FieldError>>;
  message?: Maybe<Message>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

export enum PrivateHealthcareProvider {
  /** ACA Health Benefits Fund */
  Aca = 'aca',
  /** Defence Health Limited */
  Ahb = 'ahb',
  /** ahm health insurance */
  Ahm = 'ahm',
  /** Doctors' Health Fund */
  Ama = 'ama',
  /** Australian Unity Health Limited */
  Auf = 'auf',
  /** Bupa HI Pty Ltd */
  Bup = 'bup',
  /** CBHS Corporate Health Pty Ltd */
  Cbc = 'cbc',
  /** CBHS Health Fund Limited */
  Cbh = 'cbh',
  /** CDH Benefits Fund */
  Cdh = 'cdh',
  /** CUA Health Pty Ltd */
  Cps = 'cps',
  /** GU Health */
  Fai = 'fai',
  /** Franks Health Insurance */
  Fhi = 'fhi',
  /** GMHBA Limited */
  Gmh = 'gmh',
  /** HBF Health Limited */
  Hbf = 'hbf',
  /** HCF */
  Hcf = 'hcf',
  /** Health Care Insurance Limited */
  Hci = 'hci',
  /** Health Insurance Fund of Australia Limited */
  Hif = 'hif',
  /** Peoplecare Health Insurance */
  Lhm = 'lhm',
  /** Latrobe Health Services */
  Lhs = 'lhs',
  /** Medibank Private Limited */
  Mbp = 'mbp',
  /** Mildura Health Fund */
  Mdh = 'mdh',
  /** AIA Health Insurance Pty Ltd */
  Myo = 'myo',
  /** Navy Health Ltd */
  Nhb = 'nhb',
  /** nib Health Funds Ltd. */
  Nib = 'nib',
  /** Nurses & Midwives Health */
  Nmw = 'nmw',
  /** Teachers Health */
  Ntf = 'ntf',
  /** National Health Benefits Australia Pty Ltd (onemedifund) */
  Omf = 'omf',
  /** Phoenix Health Fund Limited */
  Pwa = 'pwa',
  /** Queensland Country Health Fund Ltd */
  Qch = 'qch',
  /** TUH Health Fund */
  Qtu = 'qtu',
  /** Reserve Bank Health Society Ltd */
  Rbh = 'rbh',
  /** rt health - a division of The Hospitals Contribution Fund */
  Rte = 'rte',
  /** St.Lukes Health */
  Slm = 'slm',
  /** Police Health */
  Spe = 'spe',
  /** Health Partners */
  Sps = 'sps',
  /** Transport Health Pty Ltd */
  Tfs = 'tfs',
  /** Westfund Limited */
  Wfd = 'wfd'
}

export type PrivateHealthcareProviderSelect = {
  __typename?: 'PrivateHealthcareProviderSelect';
  label: Scalars['String']['output'];
  value: PrivateHealthcareProvider;
};

export type ProductPrice = {
  __typename?: 'ProductPrice';
  amountExcludingTax?: Maybe<Scalars['Float']['output']>;
  amountIncludingTax?: Maybe<Scalars['Float']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
  quantity?: Maybe<Scalars['String']['output']>;
  tax?: Maybe<Tax>;
  taxAmount?: Maybe<Scalars['Float']['output']>;
};

export type ProductivityDetailedList = {
  __typename?: 'ProductivityDetailedList';
  completedItems?: Maybe<Scalars['Int']['output']>;
  hourlyRate?: Maybe<Scalars['Float']['output']>;
  hoursAvailable?: Maybe<Scalars['Float']['output']>;
  hoursWorked?: Maybe<Scalars['Float']['output']>;
  inefficientHours?: Maybe<Scalars['Float']['output']>;
  potentialEarnings?: Maybe<Scalars['Float']['output']>;
  totalAppointments?: Maybe<Scalars['Int']['output']>;
  treatmentAcceptance?: Maybe<Scalars['Float']['output']>;
};

export type Professional = {
  __typename?: 'Professional';
  /** Originally from the old serializer and used by gon */
  avatarColor?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  emailAccount?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  fullNameWithTitle?: Maybe<Scalars['String']['output']>;
  gender?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  initials?: Maybe<Scalars['String']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  mobile?: Maybe<Scalars['String']['output']>;
  onboarded: Scalars['Boolean']['output'];
  phone?: Maybe<Scalars['String']['output']>;
  practiceProfessional?: Maybe<PracticeProfessional>;
  practices?: Maybe<Array<Practice>>;
  role: Scalars['String']['output'];
  title?: Maybe<Scalars['String']['output']>;
  user?: Maybe<User>;
  /** Used to retrieve UserPracticeConnection from Professional serializer */
  userPracticeConnectionId?: Maybe<Scalars['ID']['output']>;
};

export type PublicHealthFundProvider = HealthFundProviderInterface & {
  __typename?: 'PublicHealthFundProvider';
  createdAt: Scalars['ISO8601DateTime']['output'];
  gateways?: Maybe<Array<HealthFundsGateway>>;
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
  public?: Maybe<Scalars['Boolean']['output']>;
  slug?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

/** Autogenerated input type of PurchaseCredits */
export type PurchaseCreditsInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  couponCode?: InputMaybe<Scalars['String']['input']>;
  product: Scalars['String']['input'];
};

/** Autogenerated return type of PurchaseCredits. */
export type PurchaseCreditsPayload = {
  __typename?: 'PurchaseCreditsPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  fieldErrors?: Maybe<Array<FieldError>>;
  practice?: Maybe<Practice>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

export enum PushNotificationEvent {
  AppointmentReminder = 'appointment_reminder',
  DocumentShared = 'document_shared',
  FormExpiry = 'form_expiry',
  InvoiceShared = 'invoice_shared',
  TreatmentPlanShared = 'treatment_plan_shared'
}

/**
 * This is the data portion of a push notification sent to Expo.
 * Notifications contain the guid of the object they concern, the
 * event type, and any other data that should be sent along with it
 */
export type PushNotificationPayload = {
  __typename?: 'PushNotificationPayload';
  /** The event type that triggered the push notification */
  event: PushNotificationEvent;
  /** Unique UUID identifier of the object that triggered the push notification (if any) */
  guid?: Maybe<Scalars['ID']['output']>;
  /** Unique UUID identifier of the UPC this notification was meant for (app is expected to switch to this UPC if available) */
  upc?: Maybe<Scalars['ID']['output']>;
};

export type Query = {
  __typename?: 'Query';
  accessRestriction?: Maybe<AccessRestriction>;
  /** Return Current Account assigned billing address */
  accountBillingAddress?: Maybe<Location>;
  /** Returns all practices for an account */
  accountPractices?: Maybe<Array<AccountPractice>>;
  /** Search accounts */
  accounts?: Maybe<AccountConnection>;
  /** Returns all locations for a practice */
  adminPracticeLocations?: Maybe<Array<AdminPracticeLocation>>;
  alert?: Maybe<Alert>;
  /** Search alerts */
  alerts?: Maybe<AlertConnection>;
  appointment?: Maybe<Appointment>;
  appointmentCommunicationSetting?: Maybe<AppointmentCommunicationSetting>;
  appointmentGuest?: Maybe<AppointmentGuest>;
  appointmentGuests?: Maybe<AppointmentGuestConnection>;
  /** Search appointment reasons */
  appointmentReasons?: Maybe<AppointmentReasonConnection>;
  appointmentSummary?: Maybe<AppointmentSummary>;
  /** Search Appointments */
  appointments?: Maybe<AppointmentConnection>;
  /** Get a specific attachment */
  attachment?: Maybe<Attachment>;
  /** Search Attachments */
  attachments?: Maybe<AttachmentConnection>;
  availableAccounts?: Maybe<Array<Accounts>>;
  /** Fetches the avatar for a UPC */
  avatar?: Maybe<Avatar>;
  /** Search background jobs */
  backgroundJobs?: Maybe<JobConnection>;
  /** Get all badges for a record */
  badges?: Maybe<Array<BadgesBadgeLink>>;
  /** Returns a single badge template */
  badgesTemplate?: Maybe<BadgesTemplate>;
  badgesTemplateTypes?: Maybe<Array<BadgesTemplateTypeTranslation>>;
  /** Returns all templates for a practice */
  badgesTemplates?: Maybe<BadgesTemplateConnection>;
  /** Returns booking timeslots for practitioners */
  bookingTimeSlots: Array<CalendarTimeSlot>;
  /** Return calculated price from Stripe for the given product */
  calculateProductPrice?: Maybe<ProductPrice>;
  /**
   * New slimmed version of calendarEvents query that returns a raw result
   * So no graphql parsing. It handles passing specific fields to the query
   */
  calendar?: Maybe<Array<CalendarEvent>>;
  /** Proxy of Appointments resolver, but without paginated connection type */
  calendarEvents?: Maybe<Array<Appointment>>;
  /** Lookup to find the next available time for an appointment/(s) */
  calendarNextAvailability: Array<CalendarAvailability>;
  /**
   * Returns daily roster events for a given date range and upc ids.
   * It will only return the latest roster event per day/ per upc.
   */
  calendarRosterEvents?: Maybe<Array<RosterDay>>;
  /** Returns a given timeslot for a practitioner */
  calendarVacancies: Array<CalendarTimeSlot>;
  /** Search cancellation reasons */
  cancellationReasons?: Maybe<CancellationReasonConnection>;
  /** Find a catalogue item */
  catalogueItem?: Maybe<CatalogueItem>;
  catalogueItemModalities?: Maybe<Array<CatalogueItemModality>>;
  /**
   * Get Catalogue Item modalities for use in a dropdown,
   * as name-value pairs
   */
  catalogueItemModalityOptions?: Maybe<Array<CatalogueItemModalitySelect>>;
  /** Returns all catalogue items */
  catalogueItems?: Maybe<CatalogueItemConnection>;
  categories?: Maybe<CategoryConnection>;
  communicationTemplates?: Maybe<CommunicationTemplateConnection>;
  completeMessage?: Maybe<Scalars['String']['output']>;
  completeText?: Maybe<Scalars['String']['output']>;
  contactable?: Maybe<ContactableConnection>;
  /** Get all contacts for a UPC */
  contacts?: Maybe<Array<Contact>>;
  /** Search Health Funds Contract Identifiers to find provider IDs for practitioners */
  contractIdentifiers?: Maybe<ContractIdentifierConnection>;
  conversation?: Maybe<Conversation>;
  conversations?: Maybe<ConversationConnection>;
  /** All available countries in Zavy */
  countries: Array<CountryTranslation>;
  /** Return Current Account Plan */
  currentAccountPlan?: Maybe<AccountPlan>;
  currentAccountUsers?: Maybe<Array<Accounts>>;
  currentUserPreferences?: Maybe<Preferences>;
  /** Fetch Custom Imports */
  customImports?: Maybe<CustomImportConnection>;
  /** Returns a single customer request */
  customerRequest?: Maybe<CustomerRequest>;
  /** Search customer requests */
  customerRequests?: Maybe<CustomerRequestConnection>;
  /** Return dashboard widget data */
  dashboards?: Maybe<Report>;
  dataMigrations?: Maybe<Array<DataMigration>>;
  /**
   * This resolver only exists to return the enum, as enums are
   * omitted from orphan types. Because its referenced here, it
   * will be included in the schema. As a bonus, I guess you can
   * also query for it directly.
   */
  defaultHealthFunds?: Maybe<Array<DefaultHealthFunds>>;
  dentalChart?: Maybe<DentalChart>;
  dentalClassifications?: Maybe<Array<DentalClassificationTranslation>>;
  dentalJaws?: Maybe<Array<DentalJawTranslation>>;
  dentalLegacyChart?: Maybe<DentalLegacyChart>;
  /** Returns all perio entries */
  dentalPerioEntries?: Maybe<DentalPerioEntryConnection>;
  dentalPerioEntry?: Maybe<DentalPerioEntry>;
  dentalPerioExam?: Maybe<DentalPerioExam>;
  /** Returns all perio exams */
  dentalPerioExams?: Maybe<DentalPerioExamConnection>;
  dentalPricings?: Maybe<Array<DentalPricingTranslation>>;
  dentalTeeth?: Maybe<Array<DentalTeethTranslation>>;
  dentalTooth?: Maybe<DentalTooth>;
  /** Returns all tooth conditions */
  dentalToothConditions?: Maybe<DentalToothConditionConnection>;
  /** Returns all tooth treatments */
  dentalToothTreatments?: Maybe<DentalToothTreatmentConnection>;
  dentalTreatments?: Maybe<Array<DentalTreatmentTranslation>>;
  directory?: Maybe<Directory>;
  documentTemplates?: Maybe<DocumentTemplateConnection>;
  editText?: Maybe<Scalars['String']['output']>;
  emails?: Maybe<EmailConnection>;
  environment: ApplicationEnvironment;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  /** Returns all families for the practice */
  families?: Maybe<FamilyConnection>;
  /** Returns a single family */
  family?: Maybe<Family>;
  /** Returns a single family member */
  familyMember?: Maybe<FamilyMember>;
  /** Get all features */
  features?: Maybe<Array<FeatureDetails>>;
  financeSummary?: Maybe<Array<FinanceSummary>>;
  form?: Maybe<Form>;
  /**
   * Get all sections for a form or template. All forms and form templates have at
   * least one section, which can contain multiple fields
   */
  formSections?: Maybe<Array<FormSection>>;
  formTemplates?: Maybe<FormTemplateConnection>;
  forms?: Maybe<FormConnection>;
  /** All available genders in Zavy */
  genders: Array<Gender>;
  group?: Maybe<Group>;
  /** Returns all groups */
  groups?: Maybe<GroupConnection>;
  /** Request health information from the server */
  health: Health;
  /** Search Health Fund Identifiers for patients. These are their links to their health fund providers */
  healthFundIdentifiers?: Maybe<HealthFundConsumerConnection>;
  healthFunds?: Maybe<Array<HealthFundProvider>>;
  healthFundsRequest?: Maybe<HealthFundsRequest>;
  healthFundsRequests?: Maybe<HealthFundsRequestConnection>;
  icons?: Maybe<Array<IconTranslation>>;
  importFiles?: Maybe<Array<ImportFile>>;
  /** Get all Industries which a practice can belong to, e.g General Dentist, Osteopathy */
  industries: Array<Industry>;
  /** Fetches the intercom configuration */
  intercomConfiguration: IntercomConfiguration;
  invoice?: Maybe<Invoice>;
  invoiceItem?: Maybe<InvoiceItem>;
  /** Returns all invoice items */
  invoiceItems?: Maybe<InvoiceItemConnection>;
  /** Returns invoice settings for the current practice */
  invoiceSetting?: Maybe<InvoiceSetting>;
  /** Returns invoice settings for the current practice */
  invoiceSettings?: Maybe<InvoiceSettingConnection>;
  invoiceTransaction?: Maybe<InvoiceTransaction>;
  invoiceTransactionSummary?: Maybe<InvoiceTransactionSummary>;
  /** Search invoice transactions */
  invoiceTransactions?: Maybe<InvoiceTransactionConnection>;
  /** Search Invoices */
  invoices?: Maybe<InvoiceConnection>;
  /** Returns a single labwork */
  labWork?: Maybe<LabWork>;
  /** Returns all lab works */
  labWorks?: Maybe<LabWorkConnection>;
  letter?: Maybe<Letter>;
  letters?: Maybe<LetterConnection>;
  linkedPractices?: Maybe<PracticeConnection>;
  /** Fetches a location by GUID or Google Place ID */
  location?: Maybe<Location>;
  /** Search Google Places API */
  locations?: Maybe<Array<LocationSearchResult>>;
  /** Return Practice Mail Settings */
  mailSettings?: Maybe<PracticeMailSettings>;
  marketingStatistics?: Maybe<MarketingStatistics>;
  material?: Maybe<Material>;
  /** Search materials */
  materials: MaterialConnection;
  /** Retrieve merge fields for a given record */
  mergeFields?: Maybe<Array<MergeField>>;
  messages?: Maybe<MessageConnection>;
  /** Resolve any object from a UUID, given that you have view-permissions */
  node?: Maybe<UuidNode>;
  note?: Maybe<Note>;
  /**
   * Get all available note types with their associated metadata
   * like name, description, and color
   */
  noteTypes?: Maybe<Array<NoteTypeTranslation>>;
  /**
   * Notes are a simple polymorphic record of HTML or plain text\nthat can be added
   * to any other object in the system. In theory,\nyou could even add notes to a
   * note, although message-threads are not\nyet implemented because of the
   * complexity of recursive joins vs the speed\nof such N+1 queries\nA note is
   * always linked to a Practitioner (who authored the note), and often\na Patient
   * whom the note concerns. Patients are optional on Notes, because\nnot all notes
   * may concern patients, e.g if you add a note to a Template, or\nany other
   * system record.\nNotes move through a sequence of states: Draft, Published, and
   * Finalized, where\na finalized note cannot be edited again. Notes don't *have*
   * to be finalized,\nbut by law clinical notes do, as they must prevent being
   * tampered with.\nNotes can be of different types (General, ClinicalBilling,
   * ClinicalDental, ClinicalTreatment, Internal, FollowUp)\nand it is up to the
   * author to set the correct note type.\nIf the note is flagged as sticky, that
   * note will be available on the patient's\nsticky_notes field, and displayed on
   * the patients profile. There is no current limit\nto how many notes can be
   * marked as sticky, but bear in mind that it can become messy\nto have too many
   * sticky notes show up on a patients profile. Notes of any type can be\nflagged
   * as sticky. If a note is flagged as sticky but not linked to any patient,\nthe
   * flag will have no effect.\n**Important:** Notes of any clinical type will be
   * automatically finalized and locked at\nthe end of the day, whereas other notes can be edited.
   */
  notes?: Maybe<NoteConnection>;
  /** Returns a online booking settings */
  onlineBookingSetting?: Maybe<OnlineBookingSetting>;
  /** Returns online booking settings */
  onlineBookingSettings?: Maybe<OnlineBookingSettingConnection>;
  /** Returns online booking appointments for a practice */
  onlineBookingsAppointments?: Maybe<AppointmentConnection>;
  /** Get the Patient App configuration for a user in relation to a practice */
  patientAppConfig?: Maybe<PatientAppConfiguration>;
  /** Get all Patient App configurations for the current user */
  patientAppConfigs?: Maybe<Array<PatientAppConfiguration>>;
  /** Get the Patient App configuration for an invited user */
  patientAppInvite?: Maybe<PatientAppInvite>;
  /** Search patient record credits (Account Credit) */
  patientRecordCredits?: Maybe<PatientRecordCreditConnection>;
  /** Returns all patient record items */
  patientRecordItems?: Maybe<PatientRecordItemConnection>;
  /** All available payment methods in Zavy */
  paymentMethodTypes: Array<TransactionMethod>;
  /** Search Payment Methods */
  paymentMethods?: Maybe<Array<PaymentMethod>>;
  paymentProvider?: Maybe<PaymentProvider>;
  paymentProviders?: Maybe<PaymentProviderConnection>;
  paymentTerminal?: Maybe<PaymentTerminal>;
  /** Search PaymentTerminals */
  paymentTerminals?: Maybe<PaymentTerminalConnection>;
  paymentTransactions?: Maybe<PaymentTransactionConnection>;
  pdfInvoice?: Maybe<Base64File>;
  pdfLetters?: Maybe<Base64File>;
  pdfNotes?: Maybe<Base64File>;
  pdfPaymentTransaction?: Maybe<Base64File>;
  /** Get all roles and their permissions */
  permissions?: Maybe<Array<PermissionDetails>>;
  practice?: Maybe<Practice>;
  /** Returns a single practice location */
  practiceLocation?: Maybe<PracticeLocation>;
  /** Returns practice locations for the current practice */
  practiceLocations?: Maybe<PracticeLocationConnection>;
  /** Return Practice Payment Method */
  practicePaymentMethod?: Maybe<Practice>;
  /** Fetch a practice preference by name */
  practicePreference?: Maybe<PracticePreference>;
  /** Returns all practice professionals */
  practiceProfessionals?: Maybe<PracticeProfessionalConnection>;
  /** Fetches all sidekiq jobs for a practice */
  practiceSidekiqJobs?: Maybe<JobConnection>;
  practices?: Maybe<PracticeConnection>;
  /** Return SMS products from Stripe */
  premiumProducts?: Maybe<Array<StripeProducts>>;
  priceList?: Maybe<PriceList>;
  /** Search price lists */
  priceLists?: Maybe<PriceListConnection>;
  /** Fetches prices for a given catalogue item. */
  prices?: Maybe<PriceConnection>;
  privateHealthcareProviderOptions?: Maybe<Array<PrivateHealthcareProviderSelect>>;
  privateHealthcareProviders?: Maybe<Array<PrivateHealthcareProvider>>;
  /** Search recalls */
  recalls?: Maybe<RecallConnection>;
  /** Returns a single recurring task */
  recurringTask?: Maybe<RecurringTask>;
  /** Search Recurring Tasks */
  recurringTasks?: Maybe<RecurringTaskConnection>;
  referral?: Maybe<Referral>;
  /** Generate referral source statistics */
  referralSourceStatistics?: Maybe<ReferralSourceStatistics>;
  /** Returns all referral sources */
  referralSources?: Maybe<ReferralSourceConnection>;
  referralSourcesSummary?: Maybe<Array<ReferralSourcesSummary>>;
  /** Search referrals */
  referrals?: Maybe<ReferralConnection>;
  /** All available relationships in Zavy */
  relationships: Array<Relationship>;
  /**
   * Given a string of Liquid markup and a list of variables, parse this string
   * as a Liquid template, and inject objects in place of variables.
   *
   * This resolver has no idea about templates or any other records, and works
   * only with a string. This allows you to prefill single variables on-the-fly,
   * without having to create a template first, or to pass a templates content
   * and inject variables according to the templates' render config.
   */
  renderVariables?: Maybe<Scalars['String']['output']>;
  /** Run a report by specifying the report type and the filters. */
  reports?: Maybe<Report>;
  /** Returns saved reports for agiven report type */
  reportsSavedReports?: Maybe<ReportsSavedReportConnection>;
  /** Returns a single review response */
  reviewResponse?: Maybe<ReviewResponse>;
  /** Returns a single review template */
  reviewTemplate?: Maybe<ReviewTemplate>;
  /** Returns multiple review templates */
  reviewTemplates?: Maybe<ReviewTemplateConnection>;
  /** Find a Documents::RichText by ID */
  richText?: Maybe<RichText>;
  /** Get all available types of RichText documents, which determines the available variables */
  richTextClasses?: Maybe<Array<RichTextClassInfo>>;
  /** Find and search for Documents::RichText */
  richTexts?: Maybe<RichTextConnection>;
  /** Get all roles and their permissions */
  role?: Maybe<Role>;
  /** Returns all roles */
  roles?: Maybe<RoleConnection>;
  /** Search Rooms */
  room?: Maybe<Room>;
  /** Search Rooms */
  rooms?: Maybe<RoomConnection>;
  rosterEvent?: Maybe<RosterEvent>;
  /** Return Roster Events */
  rosterEvents?: Maybe<RosterEventConnection>;
  /** Return Shift Types */
  rosterShiftTypes?: Maybe<RosterShiftTypeConnection>;
  session?: Maybe<Session>;
  sessionStatus?: Maybe<SessionStatus>;
  sms?: Maybe<ShortMessageConnection>;
  snippet?: Maybe<Snippet>;
  snippets?: Maybe<Array<Snippet>>;
  /** Returns a single autoclave */
  sterilisationAutoclave?: Maybe<SterilisationAutoclave>;
  /** Returns all autoclaves for a practice */
  sterilisationAutoclaves?: Maybe<SterilisationAutoclaveConnection>;
  /** Returns a single cycle */
  sterilisationCycle?: Maybe<SterilisationCycle>;
  /** Returns a single cycle_type */
  sterilisationCycleType?: Maybe<SterilisationCycleType>;
  /** Returns all autoclave cycle types */
  sterilisationCycleTypes?: Maybe<SterilisationCycleTypeConnection>;
  /** Returns all autoclaves cycles for a practice */
  sterilisationCycles?: Maybe<SterilisationCycleConnection>;
  /** Returns a single tracking */
  sterilisationTracking?: Maybe<SterilisationTracking>;
  /** Returns all trackings */
  sterilisationTrackings?: Maybe<SterilisationTrackingConnection>;
  /** Return Subscription Payment Transactions */
  subscriptionPaymentTransactions?: Maybe<Array<SubscriptionPaymentTransactions>>;
  /** Search tags on any object */
  tags?: Maybe<Array<Tag>>;
  /** Returns a single task */
  task?: Maybe<Task>;
  /** Search Tasks */
  tasks?: Maybe<TaskConnection>;
  template: TemplateResponse;
  /** Get a single drop by name */
  templateDrop?: Maybe<LiquidDrop>;
  /** Get variables and metadata for Liquid Drops used in template rendering */
  templateDrops?: Maybe<Array<LiquidDrop>>;
  templateTypes?: Maybe<Array<TemplateTypeTranslation>>;
  templates?: Maybe<TemplateConnection>;
  /** All available titles in Zavy */
  titles: Array<Title>;
  treatment?: Maybe<Treatment>;
  /** Proxy of Appointments resolver, but WITH paginated connection type */
  treatmentAppointments?: Maybe<AppointmentConnection>;
  treatmentPlan?: Maybe<TreatmentPlan>;
  /** Search treatment plans */
  treatmentPlans?: Maybe<TreatmentPlanConnection>;
  /** Search treatments */
  treatments?: Maybe<TreatmentConnection>;
  treatmentsPreset?: Maybe<TreatmentsPreset>;
  /** Returns all presets items */
  treatmentsPresetItems?: Maybe<TreatmentsPresetItemConnection>;
  /** Returns all presets for your own practice */
  treatmentsPresets?: Maybe<TreatmentsPresetConnection>;
  unauthenticatedHealthFunds: Array<PublicHealthFundProvider>;
  /** Request a new 2FA provisioning uri */
  userAuthenticator?: Maybe<UserAuthenticator>;
  userPracticeConnection?: Maybe<UserPracticeLink>;
  /** Search user practice connections alerts */
  userPracticeConnectionAlerts?: Maybe<UserPracticeConnectionAlertConnection>;
  userPracticeConnections?: Maybe<UserPracticeLinkConnection>;
  /** Search users */
  users?: Maybe<UserConnection>;
  /** Verifies coupon code */
  verifyCouponCode?: Maybe<CouponCode>;
  /** The check before creating or updating an appointment to verify for double bookings */
  verifySlot?: Maybe<VerifySlot>;
  /** Return Versions for an object */
  versions?: Maybe<Version>;
  /** Get available access/visibility levels */
  visibility: Array<VisibilityDefinition>;
  waitlist?: Maybe<Waitlist>;
  /** Return Waitlists */
  waitlists?: Maybe<WaitlistConnection>;
  /**
   * Workflow is a template that we can create depending on the type, i.e, recall.
   * We can use this workflow to then create the associated record type.
   */
  workflow?: Maybe<Workflow>;
  /** Return Workflows */
  workflows?: Maybe<WorkflowConnection>;
};


export type QueryAccessRestrictionArgs = {
  restriction: Scalars['String']['input'];
};


export type QueryAccountPracticesArgs = {
  subdomain: Scalars['String']['input'];
};


export type QueryAccountsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  subdomain?: InputMaybe<Scalars['String']['input']>;
};


export type QueryAdminPracticeLocationsArgs = {
  practiceId: Scalars['ID']['input'];
  subdomain: Scalars['String']['input'];
};


export type QueryAlertArgs = {
  id: Scalars['ID']['input'];
};


export type QueryAlertsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  byActive?: InputMaybe<Scalars['Boolean']['input']>;
  byUnassigned?: InputMaybe<Scalars['String']['input']>;
  byUpcSlug?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
};


export type QueryAppointmentArgs = {
  guid?: InputMaybe<Scalars['ID']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
};


export type QueryAppointmentCommunicationSettingArgs = {
  appointmentGuid?: InputMaybe<Scalars['ID']['input']>;
  patientGuid: Scalars['ID']['input'];
  startTime: Scalars['ISO8601DateTime']['input'];
};


export type QueryAppointmentGuestArgs = {
  guid?: InputMaybe<Scalars['ID']['input']>;
  token?: InputMaybe<Scalars['String']['input']>;
};


export type QueryAppointmentGuestsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  appointmentState?: InputMaybe<Array<AppointmentState>>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  includeFamily?: InputMaybe<Scalars['Boolean']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  state?: InputMaybe<Array<AppointmentGuestState>>;
  timePeriod?: InputMaybe<AppointmentTimePeriods>;
  upc: Array<Scalars['ID']['input']>;
  withTreatment?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QueryAppointmentReasonsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  byState?: InputMaybe<Array<AppointmentReasonState>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  online?: InputMaybe<Scalars['Boolean']['input']>;
  practiceIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  practitionerIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  priceListIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  reasonableType?: InputMaybe<Array<Scalars['String']['input']>>;
  search?: InputMaybe<Scalars['String']['input']>;
  visibility?: InputMaybe<Array<AppointmentReasonVisibility>>;
};


export type QueryAppointmentSummaryArgs = {
  period?: InputMaybe<Scalars['String']['input']>;
  userPracticeConnectionId?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryAppointmentsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  attributes?: InputMaybe<CalendarSearchInput>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryAttachmentArgs = {
  guid?: InputMaybe<Scalars['ID']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryAttachmentsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  directory?: InputMaybe<Scalars['ID']['input']>;
  directoryContentType?: InputMaybe<Array<DocumentContentType>>;
  filetype?: InputMaybe<Array<Scalars['String']['input']>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
  imagesOnly?: InputMaybe<Scalars['Boolean']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  patientId?: InputMaybe<Array<Scalars['ID']['input']>>;
  practitionerId?: InputMaybe<Array<Scalars['ID']['input']>>;
  uuid?: InputMaybe<Scalars['String']['input']>;
};


export type QueryAvatarArgs = {
  guid?: InputMaybe<Scalars['ID']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
};


export type QueryBackgroundJobsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  jid?: InputMaybe<Scalars['String']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  subdomain?: InputMaybe<Scalars['String']['input']>;
};


export type QueryBadgesArgs = {
  templateType?: InputMaybe<Array<BadgesTemplateType>>;
  uuid: Array<Scalars['ID']['input']>;
};


export type QueryBadgesTemplateArgs = {
  id: Scalars['ID']['input'];
};


export type QueryBadgesTemplatesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  archived?: InputMaybe<Scalars['Boolean']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
  last?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  slugs?: InputMaybe<Array<Scalars['String']['input']>>;
  templateTypes?: InputMaybe<Array<BadgesTemplateType>>;
};


export type QueryBookingTimeSlotsArgs = {
  minutes?: InputMaybe<Scalars['Int']['input']>;
  practitioners?: InputMaybe<Array<Scalars['ID']['input']>>;
  timeRange: TimeRangeInput;
};


export type QueryCalculateProductPriceArgs = {
  couponCode?: InputMaybe<Scalars['String']['input']>;
  product: Scalars['String']['input'];
};


export type QueryCalendarArgs = {
  attributes: EventSearchInput;
};


export type QueryCalendarEventsArgs = {
  attributes?: InputMaybe<CalendarSearchInput>;
};


export type QueryCalendarNextAvailabilityArgs = {
  availabilities?: InputMaybe<Array<CalendarAvailabilityInput>>;
  date: Scalars['ISO8601Date']['input'];
  days?: InputMaybe<Array<CalendarDay>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  maxGapBetweenAppointments?: InputMaybe<Scalars['Int']['input']>;
  minGapBetweenAppointments?: InputMaybe<Scalars['Int']['input']>;
  onlineBooking?: InputMaybe<Scalars['Boolean']['input']>;
  searchDays?: InputMaybe<Scalars['Int']['input']>;
  sessions?: InputMaybe<Array<CalendarSession>>;
  slotInterval?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryCalendarRosterEventsArgs = {
  endDate: Scalars['ISO8601Date']['input'];
  startDate: Scalars['ISO8601Date']['input'];
  userPracticeConnectionIds?: InputMaybe<Array<Scalars['ID']['input']>>;
};


export type QueryCalendarVacanciesArgs = {
  minutes?: InputMaybe<Scalars['Int']['input']>;
  practitionerIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  timeRange: TimeRangeInput;
};


export type QueryCancellationReasonsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  byState?: InputMaybe<Array<CancellationReasonState>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryCatalogueItemArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  sku?: InputMaybe<Scalars['String']['input']>;
};


export type QueryCatalogueItemsArgs = {
  active?: InputMaybe<Array<Scalars['Boolean']['input']>>;
  after?: InputMaybe<Scalars['String']['input']>;
  appointmentReasonId?: InputMaybe<Scalars['ID']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  byFavorite?: InputMaybe<Scalars['Boolean']['input']>;
  categoryIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  dentalExcludeSurfaceItems?: InputMaybe<Scalars['Boolean']['input']>;
  dentalOnly?: InputMaybe<Scalars['Boolean']['input']>;
  dentalTreatmentTypes?: InputMaybe<Array<DentalTreatment>>;
  enableStock?: InputMaybe<Array<Scalars['Boolean']['input']>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
  last?: InputMaybe<Scalars['Int']['input']>;
  online?: InputMaybe<Array<Scalars['Boolean']['input']>>;
  order?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<CatalogueItemOrderInput>;
  practiceId?: InputMaybe<Scalars['ID']['input']>;
  priceListId?: InputMaybe<Scalars['ID']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<CatalogueItemState>;
  taxCode?: InputMaybe<Array<Scalars['Boolean']['input']>>;
  type?: InputMaybe<CatalogueItemType>;
  updatesExam?: InputMaybe<Array<Scalars['Boolean']['input']>>;
};


export type QueryCategoriesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  byState?: InputMaybe<Array<CategoryState>>;
  byType?: InputMaybe<Array<Scalars['String']['input']>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
};


export type QueryCommunicationTemplatesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  communicationType?: InputMaybe<Array<CommunicationType>>;
  excludeType?: InputMaybe<Array<TemplateType>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  hasEmail?: InputMaybe<Scalars['Boolean']['input']>;
  hasLetter?: InputMaybe<Scalars['Boolean']['input']>;
  hasSms?: InputMaybe<Scalars['Boolean']['input']>;
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
  last?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<Array<TemplateState>>;
  type?: InputMaybe<Array<TemplateType>>;
  typeGroups?: InputMaybe<Array<TemplateGroup>>;
  variant?: InputMaybe<TemplateVariant>;
};


export type QueryCompleteMessageArgs = {
  message: Scalars['ID']['input'];
};


export type QueryCompleteTextArgs = {
  maxTokens?: InputMaybe<Scalars['Int']['input']>;
  text: Scalars['String']['input'];
};


export type QueryContactableArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  query: Scalars['String']['input'];
};


export type QueryContactsArgs = {
  guid: Scalars['ID']['input'];
};


export type QueryContractIdentifiersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  byPracticeGuid?: InputMaybe<Scalars['ID']['input']>;
  byProviderId?: InputMaybe<Scalars['String']['input']>;
  byUpcId?: InputMaybe<Scalars['ID']['input']>;
  byUpcSlug?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  guids?: InputMaybe<Array<Scalars['String']['input']>>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryConversationArgs = {
  id: Scalars['ID']['input'];
};


export type QueryConversationsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
  last?: InputMaybe<Scalars['Int']['input']>;
  participants?: InputMaybe<Array<Scalars['ID']['input']>>;
};


export type QueryCurrentAccountUsersArgs = {
  subdomain: Scalars['String']['input'];
};


export type QueryCustomImportsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  subdomain: Scalars['String']['input'];
};


export type QueryCustomerRequestArgs = {
  id: Scalars['ID']['input'];
};


export type QueryCustomerRequestsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  dateRange?: InputMaybe<TimeRangeInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<OrderCustomerRequests>;
  patientId?: InputMaybe<Scalars['ID']['input']>;
  practiceLocationIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  requestType?: InputMaybe<CustomerRequestsRequestType>;
  requestableUuid?: InputMaybe<Scalars['String']['input']>;
  reviewRequestAttributes?: InputMaybe<ReviewRequestsInput>;
  search?: InputMaybe<Scalars['String']['input']>;
  showArchived?: InputMaybe<Scalars['Boolean']['input']>;
  state?: InputMaybe<Array<CustomerRequestState>>;
};


export type QueryDashboardsArgs = {
  byDateInterval?: InputMaybe<ReportDateMode>;
  byDateRange?: InputMaybe<DateRangeInput>;
  byPracticeLocations: Array<Scalars['ID']['input']>;
  byPractitioners?: InputMaybe<Array<Scalars['ID']['input']>>;
  filters?: InputMaybe<DashboardFiltersInput>;
  reportType: ReportType;
};


export type QueryDentalChartArgs = {
  upcId?: InputMaybe<Scalars['ID']['input']>;
  upcSlug?: InputMaybe<Scalars['String']['input']>;
};


export type QueryDentalLegacyChartArgs = {
  guid?: InputMaybe<Scalars['ID']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
};


export type QueryDentalPerioEntriesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  byFdi?: InputMaybe<Scalars['Int']['input']>;
  byPerioExam?: InputMaybe<Scalars['ID']['input']>;
  byToothFace?: InputMaybe<Array<DentalToothFace>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryDentalPerioEntryArgs = {
  fdi?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  perioExamId?: InputMaybe<Scalars['ID']['input']>;
  toothFace?: InputMaybe<DentalToothFace>;
};


export type QueryDentalPerioExamArgs = {
  id: Scalars['ID']['input'];
};


export type QueryDentalPerioExamsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  byDate?: InputMaybe<Scalars['ISO8601Date']['input']>;
  byPatient?: InputMaybe<Scalars['ID']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  upc?: InputMaybe<Array<Scalars['ID']['input']>>;
};


export type QueryDentalToothArgs = {
  fdi: Scalars['Int']['input'];
  upcId?: InputMaybe<Scalars['ID']['input']>;
  upcSlug?: InputMaybe<Scalars['String']['input']>;
};


export type QueryDentalToothConditionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  patientId?: InputMaybe<Scalars['ID']['input']>;
  state?: InputMaybe<Array<DentalToothConditionState>>;
  toothId?: InputMaybe<Scalars['ID']['input']>;
  toothNumber?: InputMaybe<Scalars['Int']['input']>;
  treatmentId?: InputMaybe<Scalars['ID']['input']>;
  visibleAt?: InputMaybe<Scalars['String']['input']>;
};


export type QueryDentalToothTreatmentsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  patientId?: InputMaybe<Scalars['ID']['input']>;
  state?: InputMaybe<Array<DentalToothTreatmentState>>;
  toothId?: InputMaybe<Scalars['ID']['input']>;
  toothNumber?: InputMaybe<Scalars['Int']['input']>;
  treatmentId?: InputMaybe<Scalars['ID']['input']>;
  type?: InputMaybe<Array<DentalRecordType>>;
  visibleAt?: InputMaybe<Scalars['String']['input']>;
};


export type QueryDirectoryArgs = {
  contentFilter?: InputMaybe<ContentFilter>;
  contentType?: InputMaybe<DocumentContentType>;
  directory?: InputMaybe<Scalars['ID']['input']>;
  patient?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryDocumentTemplatesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  communicationType?: InputMaybe<Array<CommunicationType>>;
  excludeType?: InputMaybe<Array<TemplateType>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  hasEmail?: InputMaybe<Scalars['Boolean']['input']>;
  hasLetter?: InputMaybe<Scalars['Boolean']['input']>;
  hasSms?: InputMaybe<Scalars['Boolean']['input']>;
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
  last?: InputMaybe<Scalars['Int']['input']>;
  prefillUuid?: InputMaybe<Scalars['String']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<Array<TemplateState>>;
  type?: InputMaybe<Array<TemplateType>>;
  typeGroups?: InputMaybe<Array<TemplateGroup>>;
  variant?: InputMaybe<TemplateVariant>;
};


export type QueryEditTextArgs = {
  instruction: TextEditInstruction;
  maxTokens?: InputMaybe<Scalars['Int']['input']>;
  text: Scalars['String']['input'];
};


export type QueryEmailsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  excludeOtp?: InputMaybe<Scalars['Boolean']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  recipientGuid?: InputMaybe<Scalars['ID']['input']>;
  recipientId?: InputMaybe<Scalars['ID']['input']>;
  showCancelled?: InputMaybe<Scalars['Boolean']['input']>;
  showFailed?: InputMaybe<Scalars['Boolean']['input']>;
  showScheduled?: InputMaybe<Scalars['Boolean']['input']>;
  state?: InputMaybe<Array<EmailState>>;
};


export type QueryFamiliesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
};


export type QueryFamilyArgs = {
  guid?: InputMaybe<Scalars['ID']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryFamilyMemberArgs = {
  id: Scalars['ID']['input'];
};


export type QueryFeaturesArgs = {
  search?: InputMaybe<Scalars['String']['input']>;
};


export type QueryFinanceSummaryArgs = {
  period: Scalars['String']['input'];
};


export type QueryFormArgs = {
  guid?: InputMaybe<Scalars['ID']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryFormSectionsArgs = {
  form?: InputMaybe<Scalars['ID']['input']>;
  template?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryFormTemplatesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  communicationType?: InputMaybe<Array<CommunicationType>>;
  excludeType?: InputMaybe<Array<TemplateType>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  hasEmail?: InputMaybe<Scalars['Boolean']['input']>;
  hasLetter?: InputMaybe<Scalars['Boolean']['input']>;
  hasSms?: InputMaybe<Scalars['Boolean']['input']>;
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
  last?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<Array<TemplateState>>;
  type?: InputMaybe<Array<TemplateType>>;
  typeGroups?: InputMaybe<Array<TemplateGroup>>;
  variant?: InputMaybe<TemplateVariant>;
};


export type QueryFormsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<FormOrderInput>;
  recipientGuids?: InputMaybe<Array<Scalars['ID']['input']>>;
  recipients?: InputMaybe<Array<Scalars['ID']['input']>>;
  search?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<Array<FormState>>;
  template?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryGroupArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
};


export type QueryGroupsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  hierarchical?: InputMaybe<Scalars['Boolean']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
};


export type QueryHealthFundIdentifiersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  byUpcGuids?: InputMaybe<Array<Scalars['ID']['input']>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  guids?: InputMaybe<Array<Scalars['ID']['input']>>;
  isPublic?: InputMaybe<Scalars['Boolean']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryHealthFundsArgs = {
  byCountry?: InputMaybe<Country>;
  byGateway?: InputMaybe<HealthFundsGateway>;
  guids?: InputMaybe<Array<Scalars['String']['input']>>;
  publicHealth?: InputMaybe<Scalars['Boolean']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
};


export type QueryHealthFundsRequestArgs = {
  id: Scalars['ID']['input'];
};


export type QueryHealthFundsRequestsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  patient?: InputMaybe<Scalars['ID']['input']>;
  requestable?: InputMaybe<Scalars['String']['input']>;
};


export type QueryImportFilesArgs = {
  importType: Scalars['String']['input'];
};


export type QueryInvoiceArgs = {
  guid?: InputMaybe<Scalars['ID']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryInvoiceItemArgs = {
  id: Scalars['ID']['input'];
};


export type QueryInvoiceItemsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  guids?: InputMaybe<Array<Scalars['ID']['input']>>;
  invoiceId?: InputMaybe<Scalars['ID']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  patientId?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryInvoiceSettingArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryInvoiceSettingsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  archived?: InputMaybe<Scalars['Boolean']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  default?: InputMaybe<Scalars['Boolean']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryInvoiceTransactionArgs = {
  id: Scalars['ID']['input'];
};


export type QueryInvoiceTransactionSummaryArgs = {
  period: Scalars['String']['input'];
};


export type QueryInvoiceTransactionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  byDateRange?: InputMaybe<DateRangeInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  invoiceId: Scalars['ID']['input'];
  last?: InputMaybe<Scalars['Int']['input']>;
  transactionTypes?: InputMaybe<Array<TransactionType>>;
  withBalance?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QueryInvoicesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  byConnection?: InputMaybe<Scalars['ID']['input']>;
  byDateRange?: InputMaybe<DateRangeInput>;
  byState?: InputMaybe<Array<InvoiceState>>;
  filter?: InputMaybe<InvoiceFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  isPaid?: InputMaybe<Scalars['Boolean']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<InvoiceOrderInput>;
};


export type QueryLabWorkArgs = {
  id: Scalars['ID']['input'];
};


export type QueryLabWorksArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  appointmentId?: InputMaybe<Scalars['ID']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<OrderLabWorks>;
  requiredById?: InputMaybe<Scalars['ID']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<Array<LabWorkState>>;
  upcId?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryLetterArgs = {
  guid?: InputMaybe<Scalars['ID']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryLettersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  recipientId?: InputMaybe<Scalars['ID']['input']>;
  recipientSlug?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<Array<LetterState>>;
};


export type QueryLinkedPracticesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};


export type QueryLocationArgs = {
  guid?: InputMaybe<Scalars['ID']['input']>;
  placeId?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryLocationsArgs = {
  country?: InputMaybe<Scalars['String']['input']>;
  search: Scalars['String']['input'];
};


export type QueryMarketingStatisticsArgs = {
  referralDateRange?: InputMaybe<TimeRangeInput>;
};


export type QueryMaterialArgs = {
  id: Scalars['ID']['input'];
};


export type QueryMaterialsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  practiceId?: InputMaybe<Array<Scalars['ID']['input']>>;
  search?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<Array<MaterialState>>;
  treatments?: InputMaybe<Array<DentalTreatment>>;
};


export type QueryMergeFieldsArgs = {
  uuid: Scalars['ID']['input'];
};


export type QueryMessagesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  appointmentId?: InputMaybe<Scalars['ID']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  conversationIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  excludeState?: InputMaybe<Array<ShortMessageState>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  participantIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  state?: InputMaybe<Array<ShortMessageState>>;
};


export type QueryNodeArgs = {
  uuid: Scalars['ID']['input'];
};


export type QueryNoteArgs = {
  guid: Scalars['ID']['input'];
};


export type QueryNotesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  authors?: InputMaybe<Array<Scalars['ID']['input']>>;
  before?: InputMaybe<Scalars['String']['input']>;
  createdAtTime?: InputMaybe<TimeRangeInput>;
  fdi?: InputMaybe<Array<Scalars['String']['input']>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  includeAutoNotes?: InputMaybe<Scalars['Boolean']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  noteTypes?: InputMaybe<Array<NoteType>>;
  patients?: InputMaybe<Array<Scalars['ID']['input']>>;
  search?: InputMaybe<Scalars['String']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<Array<NoteState>>;
  sticky?: InputMaybe<Scalars['Boolean']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
};


export type QueryOnlineBookingSettingArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryOnlineBookingSettingsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  practice?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryOnlineBookingsAppointmentsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryPatientAppConfigArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  upc?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryPatientAppInviteArgs = {
  inviteCode?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryPatientRecordCreditsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  byPatientIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  byPractitionerIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryPatientRecordItemsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  appointmentId?: InputMaybe<Scalars['ID']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  connectionId?: InputMaybe<Scalars['ID']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  guids?: InputMaybe<Array<Scalars['ID']['input']>>;
  last?: InputMaybe<Scalars['Int']['input']>;
  patientRecordId?: InputMaybe<Scalars['ID']['input']>;
  state?: InputMaybe<Array<PatientRecordItemState>>;
  treatmentPlanId?: InputMaybe<Scalars['ID']['input']>;
  withAppointment?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QueryPaymentMethodsArgs = {
  byProviderType?: InputMaybe<PaymentProviderType>;
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  excludedMethods?: InputMaybe<Array<TransactionMethod>>;
};


export type QueryPaymentProviderArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  practiceLocationId?: InputMaybe<Scalars['ID']['input']>;
  providerType?: InputMaybe<PaymentProviderType>;
};


export type QueryPaymentProvidersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  practiceLocationId?: InputMaybe<Scalars['ID']['input']>;
  providerType?: InputMaybe<PaymentProviderType>;
};


export type QueryPaymentTerminalArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryPaymentTerminalsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  byProviderType?: InputMaybe<PaymentProviderType>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryPaymentTransactionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  invoiceId?: InputMaybe<Scalars['ID']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryPdfInvoiceArgs = {
  id: Scalars['ID']['input'];
  includeTransactions?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QueryPdfLettersArgs = {
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
};


export type QueryPdfNotesArgs = {
  authors?: InputMaybe<Array<Scalars['ID']['input']>>;
  fdi?: InputMaybe<Array<Scalars['String']['input']>>;
  noteTypes?: InputMaybe<Array<NoteType>>;
  patient: Scalars['ID']['input'];
  search?: InputMaybe<Scalars['String']['input']>;
};


export type QueryPdfPaymentTransactionArgs = {
  id: Scalars['ID']['input'];
};


export type QueryPermissionsArgs = {
  search?: InputMaybe<Scalars['String']['input']>;
};


export type QueryPracticeArgs = {
  guid?: InputMaybe<Scalars['ID']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryPracticeLocationArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryPracticeLocationsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  archived?: InputMaybe<Scalars['Boolean']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  default?: InputMaybe<Scalars['Boolean']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  practice?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryPracticePreferenceArgs = {
  name?: InputMaybe<Scalars['String']['input']>;
};


export type QueryPracticeProfessionalsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  practiceId?: InputMaybe<Scalars['ID']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
};


export type QueryPracticeSidekiqJobsArgs = {
  accountId: Scalars['ID']['input'];
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  jobType: Scalars['String']['input'];
  last?: InputMaybe<Scalars['Int']['input']>;
  workableId: Scalars['ID']['input'];
  workableType: Scalars['String']['input'];
};


export type QueryPracticesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};


export type QueryPriceListArgs = {
  guid?: InputMaybe<Scalars['ID']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryPriceListsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  default?: InputMaybe<Scalars['Boolean']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  practiceId?: InputMaybe<Array<Scalars['ID']['input']>>;
  state?: InputMaybe<Array<PriceListState>>;
};


export type QueryPricesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  priceListId?: InputMaybe<Scalars['ID']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
};


export type QueryRecallsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  afterDate?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  byRecallableId?: InputMaybe<Scalars['ID']['input']>;
  byRecallableType?: InputMaybe<Scalars['String']['input']>;
  dateRange?: InputMaybe<TimeRangeInput>;
  dropOffExpired?: InputMaybe<Scalars['Boolean']['input']>;
  dynamicDate?: InputMaybe<Scalars['Boolean']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  state?: InputMaybe<RecallState>;
  template?: InputMaybe<Scalars['Boolean']['input']>;
  upcPatientIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  userPracticeConnectionIds?: InputMaybe<Array<Scalars['ID']['input']>>;
};


export type QueryRecurringTaskArgs = {
  id: Scalars['String']['input'];
};


export type QueryRecurringTasksArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  byState?: InputMaybe<Array<RecurringTaskState>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryReferralArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryReferralSourceStatisticsArgs = {
  referent?: InputMaybe<ReferralInput>;
  referralDateRange?: InputMaybe<TimeRangeInput>;
  referrer: ReferralInput;
};


export type QueryReferralSourcesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  byState?: InputMaybe<Array<ReferralSourceState>>;
  categoryId?: InputMaybe<Scalars['ID']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryReferralSourcesSummaryArgs = {
  referralDateRange?: InputMaybe<TimeRangeInput>;
};


export type QueryReferralsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  byState?: InputMaybe<ReferralState>;
  byUpc?: InputMaybe<Scalars['ID']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  forDocuments?: InputMaybe<Scalars['Boolean']['input']>;
  initialSource?: InputMaybe<Scalars['Boolean']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  referent?: InputMaybe<ReferralInput>;
  referralDateRange?: InputMaybe<TimeRangeInput>;
  referralType?: InputMaybe<ReferralType>;
  referrer?: InputMaybe<ReferralInput>;
};


export type QueryRenderVariablesArgs = {
  interpolation: Array<LiquidDropPrefillInput>;
  text: Scalars['String']['input'];
};


export type QueryReportsArgs = {
  byDateInterval?: InputMaybe<ReportDateMode>;
  byDateRange?: InputMaybe<DateRangeInput>;
  byPracticeLocations: Array<Scalars['ID']['input']>;
  byPractitioners?: InputMaybe<Array<Scalars['ID']['input']>>;
  reportType: ReportType;
};


export type QueryReportsSavedReportsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  archived?: InputMaybe<Scalars['Boolean']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  reportType?: InputMaybe<ReportType>;
};


export type QueryReviewResponseArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryReviewTemplateArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryReviewTemplatesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  archived?: InputMaybe<Scalars['Boolean']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  practice?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryRichTextArgs = {
  id: Scalars['ID']['input'];
};


export type QueryRichTextsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  params?: InputMaybe<RichTextSearchParameters>;
};


export type QueryRoleArgs = {
  slug: PracticeRole;
};


export type QueryRolesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  slug?: InputMaybe<Array<PracticeRole>>;
};


export type QueryRoomArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryRoomsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  byState?: InputMaybe<Array<RoomState>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  practiceLocationIds?: InputMaybe<Array<Scalars['ID']['input']>>;
};


export type QueryRosterEventArgs = {
  id: Scalars['ID']['input'];
};


export type QueryRosterEventsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  byDateRange?: InputMaybe<DateRangeInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryRosterShiftTypesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  byState?: InputMaybe<ShiftTypeState>;
  excludeBlocked?: InputMaybe<Scalars['Boolean']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type QuerySmsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  excludeOtp?: InputMaybe<Scalars['Boolean']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  recipientGuid?: InputMaybe<Scalars['ID']['input']>;
  recipientId?: InputMaybe<Scalars['ID']['input']>;
  showCancelled?: InputMaybe<Scalars['Boolean']['input']>;
  showFailed?: InputMaybe<Scalars['Boolean']['input']>;
  showScheduled?: InputMaybe<Scalars['Boolean']['input']>;
  state?: InputMaybe<Array<ShortMessageState>>;
  upcGuid?: InputMaybe<Scalars['String']['input']>;
};


export type QuerySnippetArgs = {
  snippet: Scalars['ID']['input'];
};


export type QuerySnippetsArgs = {
  author?: InputMaybe<Array<Scalars['ID']['input']>>;
  search?: InputMaybe<Scalars['String']['input']>;
  snippetType?: InputMaybe<Array<SnippetType>>;
  state?: InputMaybe<Array<SnippetState>>;
};


export type QuerySterilisationAutoclaveArgs = {
  id: Scalars['String']['input'];
};


export type QuerySterilisationAutoclavesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  archived?: InputMaybe<Scalars['Boolean']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  excludeIds?: InputMaybe<Array<Scalars['String']['input']>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
};


export type QuerySterilisationCycleArgs = {
  id: Scalars['String']['input'];
};


export type QuerySterilisationCycleTypeArgs = {
  id: Scalars['String']['input'];
};


export type QuerySterilisationCycleTypesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  archived?: InputMaybe<Scalars['Boolean']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  excludeIds?: InputMaybe<Array<Scalars['String']['input']>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
};


export type QuerySterilisationCyclesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  archived?: InputMaybe<Scalars['Boolean']['input']>;
  autoclaveId?: InputMaybe<Scalars['String']['input']>;
  barcode?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  date?: InputMaybe<Scalars['ISO8601Date']['input']>;
  excludeIds?: InputMaybe<Array<Scalars['String']['input']>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
};


export type QuerySterilisationTrackingArgs = {
  id: Scalars['String']['input'];
};


export type QuerySterilisationTrackingsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  archived?: InputMaybe<Scalars['Boolean']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  excludeIds?: InputMaybe<Array<Scalars['String']['input']>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  patientGuid?: InputMaybe<Scalars['String']['input']>;
  practiceGuid?: InputMaybe<Scalars['String']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  trackableUuid?: InputMaybe<Scalars['String']['input']>;
};


export type QueryTagsArgs = {
  uuid: Scalars['String']['input'];
};


export type QueryTaskArgs = {
  id: Scalars['ID']['input'];
};


export type QueryTasksArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  byState?: InputMaybe<Array<TaskState>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  showRecallTasks?: InputMaybe<Scalars['Boolean']['input']>;
  taskAssigneeIds?: InputMaybe<Array<Scalars['ID']['input']>>;
};


export type QueryTemplateArgs = {
  guid: Scalars['ID']['input'];
};


export type QueryTemplateDropArgs = {
  drop?: InputMaybe<LiquidDropType>;
  templateType?: InputMaybe<TemplateType>;
};


export type QueryTemplateDropsArgs = {
  drops?: InputMaybe<Array<LiquidDropType>>;
  types?: InputMaybe<Array<TemplateType>>;
};


export type QueryTemplateTypesArgs = {
  communicationTypes?: InputMaybe<Array<CommunicationType>>;
  groups?: InputMaybe<Array<TemplateGroup>>;
  variants?: InputMaybe<Array<TemplateVariant>>;
};


export type QueryTemplatesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  communicationType?: InputMaybe<Array<CommunicationType>>;
  excludeType?: InputMaybe<Array<TemplateType>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  hasEmail?: InputMaybe<Scalars['Boolean']['input']>;
  hasLetter?: InputMaybe<Scalars['Boolean']['input']>;
  hasSms?: InputMaybe<Scalars['Boolean']['input']>;
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
  last?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<Array<TemplateState>>;
  type?: InputMaybe<Array<TemplateType>>;
  typeGroups?: InputMaybe<Array<TemplateGroup>>;
  variant?: InputMaybe<TemplateVariant>;
};


export type QueryTreatmentArgs = {
  guid?: InputMaybe<Scalars['ID']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
  treatmentPlanGuid?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryTreatmentAppointmentsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  attributes?: InputMaybe<CalendarSearchInput>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryTreatmentPlanArgs = {
  guid?: InputMaybe<Scalars['ID']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
};


export type QueryTreatmentPlansArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  hideArchived?: InputMaybe<Scalars['Boolean']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<TreatmentPlanOrderInput>;
  patientGuids?: InputMaybe<Array<Scalars['ID']['input']>>;
  patientIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  state?: InputMaybe<Array<TreatmentPlanState>>;
  treatmentId?: InputMaybe<Scalars['ID']['input']>;
  treatmentIds?: InputMaybe<Array<Scalars['ID']['input']>>;
};


export type QueryTreatmentsArgs = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  appointmentReasonId?: InputMaybe<Scalars['ID']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  inProgress?: InputMaybe<Scalars['Boolean']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  patientId?: InputMaybe<Array<Scalars['ID']['input']>>;
  practitionerId?: InputMaybe<Array<Scalars['ID']['input']>>;
  state?: InputMaybe<Array<TreatmentState>>;
  upc?: InputMaybe<Array<Scalars['ID']['input']>>;
  userPracticeConnectionId?: InputMaybe<Array<Scalars['ID']['input']>>;
  withActiveAppointments?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QueryTreatmentsPresetArgs = {
  id: Scalars['ID']['input'];
};


export type QueryTreatmentsPresetItemsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  presetId?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryTreatmentsPresetsArgs = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<OrderTreatmentsPreset>;
  private?: InputMaybe<Scalars['Boolean']['input']>;
  upcId?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryUnauthenticatedHealthFundsArgs = {
  byCountry?: InputMaybe<Country>;
  byGateway?: InputMaybe<HealthFundsGateway>;
  guids?: InputMaybe<Array<Scalars['String']['input']>>;
  publicHealth?: InputMaybe<Scalars['Boolean']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
};


export type QueryUserPracticeConnectionArgs = {
  guid?: InputMaybe<Scalars['ID']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
};


export type QueryUserPracticeConnectionAlertsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  byUpcId: Scalars['ID']['input'];
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryUserPracticeConnectionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  attributes?: InputMaybe<UpcSearchInput>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryUsersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
};


export type QueryVerifyCouponCodeArgs = {
  couponCode: Scalars['String']['input'];
};


export type QueryVerifySlotArgs = {
  appointments?: InputMaybe<Array<VerifySlotInput>>;
};


export type QueryVersionsArgs = {
  attributes?: InputMaybe<VersionSearchInput>;
};


export type QueryWaitlistArgs = {
  id: Scalars['ID']['input'];
};


export type QueryWaitlistsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  byState?: InputMaybe<Array<WaitlistState>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryWorkflowArgs = {
  id: Scalars['ID']['input'];
};


export type QueryWorkflowsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  byState: Array<WorkflowState>;
  byType: WorkflowType;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** Autogenerated input type of Quote */
export type QuoteInput = {
  attributes: HealthFundsQuoteInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** GUID of the patient UPC to run a quote against */
  patient: Scalars['ID']['input'];
  /** GraphQL UUID (base64(ClassName:ID)) of the object to attach request to */
  requestable?: InputMaybe<Scalars['ID']['input']>;
};

/** Autogenerated return type of Quote. */
export type QuotePayload = {
  __typename?: 'QuotePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  fieldErrors?: Maybe<Array<FieldError>>;
  healthFundsRequest?: Maybe<HealthFundsRequest>;
  invoiceClaimItems?: Maybe<Array<InvoiceItem>>;
  plannerClaimItems?: Maybe<Array<PatientRecordItem>>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

/** Autogenerated input type of ReactivateSubscription */
export type ReactivateSubscriptionInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of ReactivateSubscription. */
export type ReactivateSubscriptionPayload = {
  __typename?: 'ReactivateSubscriptionPayload';
  account?: Maybe<Account>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  fieldErrors?: Maybe<Array<FieldError>>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type Recall = AnyNode & {
  __typename?: 'Recall';
  beginAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  canCancel: Scalars['Boolean']['output'];
  canComplete: Scalars['Boolean']['output'];
  cancelledAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  colour?: Maybe<Scalars['String']['output']>;
  completedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  createdAt: Scalars['ISO8601DateTime']['output'];
  dueDate?: Maybe<Scalars['ISO8601DateTime']['output']>;
  emailContent?: Maybe<Scalars['String']['output']>;
  endAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  expiredAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  frequency?: Maybe<RecallFrequencyUnit>;
  guid: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
  period?: Maybe<Scalars['Int']['output']>;
  recallType?: Maybe<RecallType>;
  recallable?: Maybe<Recallable>;
  recalled: Scalars['Boolean']['output'];
  reminderFrequency?: Maybe<RecallFrequencyUnit>;
  reminderIncrement?: Maybe<Scalars['Int']['output']>;
  reminderPeriodLabel?: Maybe<Scalars['String']['output']>;
  reminders?: Maybe<Array<Reminder>>;
  remindersNumber?: Maybe<Scalars['Int']['output']>;
  scheduledDropOff?: Maybe<Scalars['ISO8601DateTime']['output']>;
  smsContent?: Maybe<Scalars['String']['output']>;
  state?: Maybe<RecallState>;
  stateLabel?: Maybe<Scalars['String']['output']>;
  template?: Maybe<CommunicationTemplate>;
  updatedAt: Scalars['ISO8601DateTime']['output'];
  uuid: Scalars['String']['output'];
  workflow?: Maybe<Workflow>;
};

/** The connection type for Recall. */
export type RecallConnection = {
  __typename?: 'RecallConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<RecallEdge>>>;
  /** IDs of ALL results, regardless of pagination */
  ids: Array<Scalars['ID']['output']>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Recall>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Returns total count of items in list */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type RecallEdge = {
  __typename?: 'RecallEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<Recall>;
};

export enum RecallFrequencyUnit {
  Days = 'days',
  Months = 'months',
  Weeks = 'weeks'
}

export type RecallInput = {
  /** Name of the Recall */
  name: Scalars['String']['input'];
  /** UPC ID of the recallable patient */
  patientId: Scalars['ID']['input'];
  reminders: Array<ReminderInput>;
  /** Workflow Template used for the recall */
  workflowId?: InputMaybe<Scalars['ID']['input']>;
};

export type RecallSettings = {
  __typename?: 'RecallSettings';
  /** Allows recalls to be automatically triggered based on recall settings */
  autoProcess?: Maybe<Scalars['Boolean']['output']>;
  /** The maximum consecutive missed appointments before a patient is ignored for recalls */
  missedAppointments?: Maybe<Scalars['Int']['output']>;
  /** Only select patients who are active */
  onlyActivePatients?: Maybe<Scalars['Boolean']['output']>;
  /** The field, last_exam or last_visit, that will be used to determine if a patient should be recalled */
  recallTrigger?: Maybe<Scalars['String']['output']>;
  /** Skip patients who have active recalls */
  skipActiveRecall?: Maybe<Scalars['Boolean']['output']>;
  /** Skip patients who have prepared recalls */
  skipUpcoming?: Maybe<Scalars['Boolean']['output']>;
  /** Skip patients who have drop off time set */
  skipWithDropOff?: Maybe<Scalars['Boolean']['output']>;
  /** The practice timezone time of day when the recall worker should trigger to generate recall jobs */
  triggerTime?: Maybe<Scalars['String']['output']>;
};

export enum RecallState {
  Cancelled = 'cancelled',
  Completed = 'completed',
  DropOff = 'drop_off',
  Prepared = 'prepared'
}

export enum RecallStateEvent {
  Cancel = 'cancel',
  Complete = 'complete',
  DropOff = 'drop_off'
}

export enum RecallType {
  Automatic = 'automatic',
  Manual = 'manual'
}

export type Recallable = Patient | PatientRecord | Practice | Treatment | UserPracticeLink;

/** Autogenerated input type of RecoverPassword */
export type RecoverPasswordInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Reset password by email or username */
  identifier: Scalars['String']['input'];
};

/** Autogenerated return type of RecoverPassword. */
export type RecoverPasswordPayload = {
  __typename?: 'RecoverPasswordPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  fieldErrors?: Maybe<Array<FieldError>>;
  message?: Maybe<Scalars['String']['output']>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

export enum RecurringFrequency {
  /** Day */
  Day = 'day',
  /** Month */
  Month = 'month',
  /** Week */
  Week = 'week',
  /** Year */
  Year = 'year'
}

export type RecurringTask = {
  __typename?: 'RecurringTask';
  color?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['ISO8601DateTime']['output'];
  days?: Maybe<Array<Weekday>>;
  description?: Maybe<Scalars['String']['output']>;
  dueAtFrequency?: Maybe<Scalars['String']['output']>;
  dueAtPeriod?: Maybe<Scalars['Int']['output']>;
  endAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  id: Scalars['String']['output'];
  lastRunAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  practice?: Maybe<Practice>;
  recurringFrequency?: Maybe<Scalars['String']['output']>;
  startAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  state?: Maybe<RecurringTaskState>;
  taskAssignees?: Maybe<Array<UserPracticeLink>>;
  title?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

/** The connection type for RecurringTask. */
export type RecurringTaskConnection = {
  __typename?: 'RecurringTaskConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<RecurringTaskEdge>>>;
  /** IDs of ALL results, regardless of pagination */
  ids: Array<Scalars['ID']['output']>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<RecurringTask>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Returns total count of items in list */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type RecurringTaskEdge = {
  __typename?: 'RecurringTaskEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<RecurringTask>;
};

export type RecurringTaskInput = {
  /** List of days of the week the recurring task should be created */
  days?: InputMaybe<Array<Weekday>>;
  /** Description the recurring task */
  description?: InputMaybe<Scalars['String']['input']>;
  /** The frequency of the recurring task */
  dueAtFrequency?: InputMaybe<Scalars['String']['input']>;
  /** The period of the recurring task */
  dueAtPeriod?: InputMaybe<Scalars['Int']['input']>;
  /** The end date for the recurring task */
  endAt?: InputMaybe<Scalars['ISO8601Date']['input']>;
  /** The frequency of the recurring task (daily, weekly, monthly, yearly) */
  recurringFrequency?: InputMaybe<Scalars['String']['input']>;
  /** The start date for the recurring task */
  startAt?: InputMaybe<Scalars['ISO8601Date']['input']>;
  /** The state of the recurring task */
  state?: InputMaybe<RecurringTaskState>;
  /** List of assigned staff */
  taskAssigneeIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** Title of the recurring task */
  title?: InputMaybe<Scalars['String']['input']>;
};

export enum RecurringTaskState {
  Active = 'active',
  Archived = 'archived',
  Inactive = 'inactive',
  Paused = 'paused'
}

export enum RecurringType {
  Date = 'date',
  WeekDay = 'week_day'
}

export type Referral = AnyNode & {
  __typename?: 'Referral';
  attachments?: Maybe<Array<Attachment>>;
  createdAt: Scalars['ISO8601DateTime']['output'];
  /** Description added against the referral */
  description?: Maybe<Scalars['String']['output']>;
  discardedAt: Scalars['ISO8601DateTime']['output'];
  guid: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  /** Toggle if this referral IN is the initial source */
  initialSource?: Maybe<Scalars['Boolean']['output']>;
  /** The last appointment date for the referent */
  lastAppointment?: Maybe<Scalars['ISO8601DateTime']['output']>;
  /** The next appointment date for the referent */
  nextAppointment?: Maybe<Scalars['ISO8601DateTime']['output']>;
  /** Practice ID of the referral */
  practiceId?: Maybe<Scalars['ID']['output']>;
  /** GUID of the Referent */
  referentGuid?: Maybe<Scalars['String']['output']>;
  referentId?: Maybe<Scalars['ID']['output']>;
  /** Name of the Referent */
  referentName?: Maybe<Scalars['String']['output']>;
  /** Person being referred, UserPracticeConnection */
  referentType?: Maybe<Scalars['String']['output']>;
  /** Referral IN/OUT */
  referralType?: Maybe<ReferralType>;
  /** GUID of the Referrer */
  referrerGuid?: Maybe<Scalars['String']['output']>;
  referrerId?: Maybe<Scalars['ID']['output']>;
  /** Name of the Referrer */
  referrerName?: Maybe<Scalars['String']['output']>;
  /** Role of the Referrer */
  referrerRole?: Maybe<Scalars['String']['output']>;
  /** Person/Source referring, UserPracticeConnection/ReferralSource/Contact */
  referrerType?: Maybe<Scalars['String']['output']>;
  /** Rich text documents created for this Referral */
  richTexts?: Maybe<Array<RichText>>;
  /** State of the Referral (new_lead, in_progress, completed, closed) */
  state?: Maybe<ReferralState>;
  /** The total number of appointments for the referent */
  totalAppointments?: Maybe<Scalars['Int']['output']>;
  /** The total invoiced amount that the referrer has generated through referrals */
  totalInvoiced?: Maybe<Scalars['Float']['output']>;
  updatedAt: Scalars['ISO8601DateTime']['output'];
  /** Unique identifier of the message */
  uuid: Scalars['String']['output'];
};

/** The connection type for Referral. */
export type ReferralConnection = {
  __typename?: 'ReferralConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<ReferralEdge>>>;
  /** IDs of ALL results, regardless of pagination */
  ids: Array<Scalars['ID']['output']>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Referral>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Returns total count of items in list */
  totalCount: Scalars['Int']['output'];
};

export type ReferralCreateInput = {
  /** Link attachments to this referral */
  attachments?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** Description added against the referral source */
  description: Scalars['String']['input'];
  /** Toggle if this referral IN is the initial source */
  initialSource?: InputMaybe<Scalars['Boolean']['input']>;
  /** Person being referred, UserPracticeConnection */
  referentId: Scalars['ID']['input'];
  referentType: Scalars['String']['input'];
  /** Referral In/Out */
  referralType: ReferralType;
  /** Person/Source referring, UserPracticeConnection/ReferralSource/Contact */
  referrerId: Scalars['ID']['input'];
  referrerType: Scalars['String']['input'];
};

/** An edge in a connection. */
export type ReferralEdge = {
  __typename?: 'ReferralEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<Referral>;
};

export type ReferralInput = {
  /** Referrer ID */
  id: Scalars['ID']['input'];
  /** Referrer type */
  type?: InputMaybe<ReferralReferrer>;
};

export enum ReferralReferrer {
  Contact = 'Contact',
  ReferralSource = 'ReferralSource',
  UserPracticeConnection = 'UserPracticeConnection'
}

export type ReferralSource = {
  __typename?: 'ReferralSource';
  category: Category;
  categoryId: Scalars['ID']['output'];
  createdAt: Scalars['ISO8601DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  guid: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  practiceId: Scalars['ID']['output'];
  state: ReferralSourceState;
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

/** The connection type for ReferralSource. */
export type ReferralSourceConnection = {
  __typename?: 'ReferralSourceConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<ReferralSourceEdge>>>;
  /** IDs of ALL results, regardless of pagination */
  ids: Array<Scalars['ID']['output']>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<ReferralSource>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Returns total count of items in list */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type ReferralSourceEdge = {
  __typename?: 'ReferralSourceEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<ReferralSource>;
};

export type ReferralSourceInput = {
  /** Referral Source category */
  categoryId: Scalars['ID']['input'];
  /** Referral Source name */
  name: Scalars['String']['input'];
};

export enum ReferralSourceState {
  Active = 'active',
  Archived = 'archived'
}

export enum ReferralSourceStateEvent {
  Activate = 'activate',
  Archive = 'archive'
}

export type ReferralSourceStatistics = {
  __typename?: 'ReferralSourceStatistics';
  averageReferralRevenue: Scalars['Int']['output'];
  referrerName: Scalars['String']['output'];
  total: Scalars['Int']['output'];
  totalRevenue: Scalars['Int']['output'];
};

export type ReferralSourcesSummary = {
  __typename?: 'ReferralSourcesSummary';
  lifetimeValue: Scalars['Float']['output'];
  name: Scalars['String']['output'];
  referrerGuid?: Maybe<Scalars['ID']['output']>;
  referrerId: Scalars['ID']['output'];
  referrerType: Scalars['String']['output'];
  referrerTypeLabel: Scalars['String']['output'];
  total: Scalars['Float']['output'];
};

export enum ReferralState {
  Closed = 'closed',
  Completed = 'completed',
  InProgress = 'in_progress',
  NewLead = 'new_lead'
}

export enum ReferralStateEvent {
  Close = 'close',
  Complete = 'complete',
  Progress = 'progress',
  StartOver = 'start_over'
}

export enum ReferralType {
  In = 'in',
  Out = 'out'
}

export type ReferralUpdateInput = {
  /** Link attachments to this referral */
  attachments?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** Description added against the referral */
  description?: InputMaybe<Scalars['String']['input']>;
  /** Toggle if this referral IN is the initial source */
  initialSource?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Autogenerated input type of RefreshJob */
export type RefreshJobInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The sidekiq job to refresh */
  sidekiqJob: Scalars['ID']['input'];
};

/** Autogenerated return type of RefreshJob. */
export type RefreshJobPayload = {
  __typename?: 'RefreshJobPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  fieldErrors?: Maybe<Array<FieldError>>;
  sidekiqJob?: Maybe<Job>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

/** Autogenerated input type of RefreshToken */
export type RefreshTokenInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of RefreshToken. */
export type RefreshTokenPayload = {
  __typename?: 'RefreshTokenPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  fieldErrors?: Maybe<Array<FieldError>>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type Refund = {
  __typename?: 'Refund';
  data?: Maybe<Array<RefundData>>;
  url?: Maybe<Scalars['String']['output']>;
};

export type RefundData = {
  __typename?: 'RefundData';
  amount?: Maybe<Scalars['Int']['output']>;
};

/** Autogenerated input type of Refund */
export type RefundInput = {
  attributes: HealthFundsRefundInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** GUID of the patient UPC to run a quote against */
  patient: Scalars['ID']['input'];
  /** GraphQL UUID (base64(ClassName:ID)) of the object to attach request to */
  requestable?: InputMaybe<Scalars['ID']['input']>;
};

/** Autogenerated return type of Refund. */
export type RefundPayload = {
  __typename?: 'RefundPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  fieldErrors?: Maybe<Array<FieldError>>;
  healthFundsRequest?: Maybe<HealthFundsRequest>;
  invoice?: Maybe<Invoice>;
  invoiceClaimItems?: Maybe<Array<InvoiceItem>>;
  invoiceTransaction?: Maybe<InvoiceTransaction>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type RefundResponse = {
  __typename?: 'RefundResponse';
  amount?: Maybe<Scalars['Int']['output']>;
  balanceTransaction?: Maybe<Scalars['String']['output']>;
  charge?: Maybe<Scalars['String']['output']>;
  created?: Maybe<Scalars['Int']['output']>;
  currency?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  metadata?: Maybe<Scalars['JSON']['output']>;
  object?: Maybe<Scalars['String']['output']>;
  paymentIntent?: Maybe<Scalars['String']['output']>;
  reason?: Maybe<Scalars['String']['output']>;
  receiptNumber?: Maybe<Scalars['String']['output']>;
  sourceTransferReversal?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  transferReversal?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of RegenerateRestrictedLink */
export type RegenerateRestrictedLinkInput = {
  attributes: FormGenerateLinkInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of RegenerateRestrictedLink. */
export type RegenerateRestrictedLinkPayload = {
  __typename?: 'RegenerateRestrictedLinkPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  fieldErrors?: Maybe<Array<FieldError>>;
  form?: Maybe<Form>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

/** Autogenerated input type of RejectGuest */
export type RejectGuestInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  token: Scalars['String']['input'];
};

/** Autogenerated return type of RejectGuest. */
export type RejectGuestPayload = {
  __typename?: 'RejectGuestPayload';
  appointmentGuest?: Maybe<AppointmentGuest>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  fieldErrors?: Maybe<Array<FieldError>>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

/** Autogenerated input type of Reject */
export type RejectInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  userPracticeConnectionId: Scalars['ID']['input'];
};

/** Autogenerated return type of Reject. */
export type RejectPayload = {
  __typename?: 'RejectPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  fieldErrors?: Maybe<Array<FieldError>>;
  success?: Maybe<Scalars['Boolean']['output']>;
  userPracticeConnection?: Maybe<UserPracticeLink>;
};

/** Autogenerated input type of RejectTreatmentPlan */
export type RejectTreatmentPlanInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of RejectTreatmentPlan. */
export type RejectTreatmentPlanPayload = {
  __typename?: 'RejectTreatmentPlanPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  fieldErrors?: Maybe<Array<FieldError>>;
  success?: Maybe<Scalars['Boolean']['output']>;
  treatmentPlan?: Maybe<TreatmentPlan>;
};

export enum Relationship {
  Child = 'child',
  Father = 'father',
  Friend = 'friend',
  Guardian = 'guardian',
  Mother = 'mother',
  Other = 'other',
  Partner = 'partner',
  Sibling = 'sibling',
  Spouse = 'spouse'
}

export type Relative = {
  __typename?: 'Relative';
  dateOfBirth?: Maybe<Scalars['ISO8601DateTime']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  internationalMobile?: Maybe<Scalars['String']['output']>;
  isDefault?: Maybe<Scalars['Boolean']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  relationship?: Maybe<Scalars['String']['output']>;
};

export type Reminder = AnyNode & {
  __typename?: 'Reminder';
  contactType?: Maybe<WorkflowStepContact>;
  emailContent?: Maybe<Scalars['String']['output']>;
  failureCauses?: Maybe<Scalars['String']['output']>;
  frequency?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  period?: Maybe<Scalars['Int']['output']>;
  position?: Maybe<Scalars['Int']['output']>;
  remindableType?: Maybe<Scalars['String']['output']>;
  smsContent?: Maybe<Scalars['String']['output']>;
  state: ReminderState;
  subject?: Maybe<Scalars['String']['output']>;
  taskAssigneeIds?: Maybe<Array<Scalars['ID']['output']>>;
  templateId?: Maybe<Scalars['ID']['output']>;
  triggeredAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  /** Unique identifier of the message */
  uuid: Scalars['String']['output'];
};

export type ReminderInput = {
  contactType?: InputMaybe<WorkflowStepContact>;
  emailContent?: InputMaybe<Scalars['String']['input']>;
  frequency: Scalars['String']['input'];
  period: Scalars['Int']['input'];
  position?: InputMaybe<Scalars['Int']['input']>;
  remindableType?: InputMaybe<Scalars['String']['input']>;
  smsContent?: InputMaybe<Scalars['String']['input']>;
  subject?: InputMaybe<Scalars['String']['input']>;
  /** UPC ids for the staff that will get assigned tasks generated from phone and letter contact_type */
  taskAssigneeIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  templateId?: InputMaybe<Scalars['ID']['input']>;
};

export enum ReminderState {
  Archived = 'archived',
  Cancelled = 'cancelled',
  Failed = 'failed',
  Pending = 'pending',
  Prepared = 'prepared',
  Sent = 'sent',
  Successful = 'successful'
}

/** Autogenerated input type of RemoveAppointment */
export type RemoveAppointmentInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of RemoveAppointment. */
export type RemoveAppointmentPayload = {
  __typename?: 'RemoveAppointmentPayload';
  appointment?: Maybe<Appointment>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  fieldErrors?: Maybe<Array<FieldError>>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

/** Autogenerated input type of RemoveAuthenticator */
export type RemoveAuthenticatorInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** OTP Code */
  otpCode: Scalars['String']['input'];
};

/** Autogenerated return type of RemoveAuthenticator. */
export type RemoveAuthenticatorPayload = {
  __typename?: 'RemoveAuthenticatorPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** General errors, unrelated to fields */
  errors?: Maybe<Array<Scalars['String']['output']>>;
  /** Input field errors */
  fieldErrors?: Maybe<Array<FieldError>>;
  /** Whether or not this mutation was successful */
  success?: Maybe<Scalars['Boolean']['output']>;
};

/** Autogenerated input type of RemoveCreditCard */
export type RemoveCreditCardInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  practiceId: Scalars['ID']['input'];
};

/** Autogenerated return type of RemoveCreditCard. */
export type RemoveCreditCardPayload = {
  __typename?: 'RemoveCreditCardPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  fieldErrors?: Maybe<Array<FieldError>>;
  practice?: Maybe<Practice>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

/** Autogenerated input type of RemoveFamilyMember */
export type RemoveFamilyMemberInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The ID of the family member you want to remove from the family */
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of RemoveFamilyMember. */
export type RemoveFamilyMemberPayload = {
  __typename?: 'RemoveFamilyMemberPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  family?: Maybe<Family>;
  fieldErrors?: Maybe<Array<FieldError>>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

/** Autogenerated input type of RemoveGroupFromRole */
export type RemoveGroupFromRoleInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  groupId: Scalars['ID']['input'];
  roleId: Scalars['ID']['input'];
};

/** Autogenerated return type of RemoveGroupFromRole. */
export type RemoveGroupFromRolePayload = {
  __typename?: 'RemoveGroupFromRolePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  fieldErrors?: Maybe<Array<FieldError>>;
  role?: Maybe<Role>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

/** Autogenerated input type of RemoveGroup */
export type RemoveGroupInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of RemoveGroup. */
export type RemoveGroupPayload = {
  __typename?: 'RemoveGroupPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  fieldErrors?: Maybe<Array<FieldError>>;
  group?: Maybe<Group>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

/** Autogenerated input type of RemoveGuest */
export type RemoveGuestInput = {
  appointmentGuestId: Scalars['ID']['input'];
  appointmentId: Scalars['ID']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of RemoveGuest. */
export type RemoveGuestPayload = {
  __typename?: 'RemoveGuestPayload';
  appointment?: Maybe<Appointment>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  fieldErrors?: Maybe<Array<FieldError>>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

/** Autogenerated input type of RemoveLabWork */
export type RemoveLabWorkInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of RemoveLabWork. */
export type RemoveLabWorkPayload = {
  __typename?: 'RemoveLabWorkPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  fieldErrors?: Maybe<Array<FieldError>>;
  labWork?: Maybe<LabWork>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

/** Autogenerated input type of RemoveTreatmentsPreset */
export type RemoveTreatmentsPresetInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated input type of RemoveTreatmentsPresetItem */
export type RemoveTreatmentsPresetItemInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of RemoveTreatmentsPresetItem. */
export type RemoveTreatmentsPresetItemPayload = {
  __typename?: 'RemoveTreatmentsPresetItemPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  fieldErrors?: Maybe<Array<FieldError>>;
  success?: Maybe<Scalars['Boolean']['output']>;
  treatmentsPresetItem?: Maybe<TreatmentsPresetItem>;
};

/** Autogenerated return type of RemoveTreatmentsPreset. */
export type RemoveTreatmentsPresetPayload = {
  __typename?: 'RemoveTreatmentsPresetPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  fieldErrors?: Maybe<Array<FieldError>>;
  success?: Maybe<Scalars['Boolean']['output']>;
  treatmentsPreset?: Maybe<TreatmentsPreset>;
};

/** Autogenerated input type of RemoveTyroTerminal */
export type RemoveTyroTerminalInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of RemoveTyroTerminal. */
export type RemoveTyroTerminalPayload = {
  __typename?: 'RemoveTyroTerminalPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  fieldErrors?: Maybe<Array<FieldError>>;
  paymentTerminal?: Maybe<PaymentTerminal>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

/** Autogenerated input type of RemoveUserContact */
export type RemoveUserContactInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** GUID of the contact UPC to remove */
  contact?: InputMaybe<Scalars['ID']['input']>;
  /** GUID of the UPC to update contacts for */
  guid: Scalars['ID']['input'];
};

/** Autogenerated return type of RemoveUserContact. */
export type RemoveUserContactPayload = {
  __typename?: 'RemoveUserContactPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  fieldErrors?: Maybe<Array<FieldError>>;
  success?: Maybe<Scalars['Boolean']['output']>;
  userPracticeConnection?: Maybe<UserPracticeLink>;
};

/** Autogenerated input type of ReorderField */
export type ReorderFieldInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  /** New position for the field */
  position: Scalars['Int']['input'];
  section?: InputMaybe<Scalars['ID']['input']>;
};

/** Autogenerated return type of ReorderField. */
export type ReorderFieldPayload = {
  __typename?: 'ReorderFieldPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** General errors, unrelated to fields */
  errors?: Maybe<Array<Scalars['String']['output']>>;
  /** Input field errors */
  fieldErrors?: Maybe<Array<FieldError>>;
  section?: Maybe<FormSection>;
  /** Whether or not this mutation was successful */
  success?: Maybe<Scalars['Boolean']['output']>;
};

/** Autogenerated input type of ReorderPatientRecordItem */
export type ReorderPatientRecordItemInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  patientRecordItemId: Scalars['ID']['input'];
  position: Scalars['Int']['input'];
};

/** Autogenerated return type of ReorderPatientRecordItem. */
export type ReorderPatientRecordItemPayload = {
  __typename?: 'ReorderPatientRecordItemPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  fieldErrors?: Maybe<Array<FieldError>>;
  patientRecordItems?: Maybe<Array<PatientRecordItem>>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

/** Autogenerated input type of ReorderTreatmentAppointment */
export type ReorderTreatmentAppointmentInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  position: Scalars['Int']['input'];
};

/** Autogenerated return type of ReorderTreatmentAppointment. */
export type ReorderTreatmentAppointmentPayload = {
  __typename?: 'ReorderTreatmentAppointmentPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  fieldErrors?: Maybe<Array<FieldError>>;
  success?: Maybe<Scalars['Boolean']['output']>;
  treatmentPlan?: Maybe<TreatmentPlan>;
};

export type Report = {
  __typename?: 'Report';
  chartData?: Maybe<Chart>;
  data?: Maybe<Scalars['JSON']['output']>;
  detailedList?: Maybe<Array<DetailedList>>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  id: Scalars['String']['output'];
  reportType?: Maybe<ReportType>;
  summaryList?: Maybe<Array<SummaryList>>;
};

export type ReportData = ReportsAppointmentData | ReportsAppointmentOccupancyData | ReportsAppointmentRebookRateData | ReportsDashboardAppointmentsData | ReportsDashboardNewPatientsData | ReportsDashboardPatientStatisticsData | ReportsDashboardPractitionerAppointmentsData | ReportsDashboardShortMessageCreditsUsageData | ReportsFinanceInvoiceItemTransactionData | ReportsFinancePerformanceData | ReportsFinanceProductionData | ReportsFinanceProductsAndServicesData | ReportsInvoiceData | ReportsPatientData | ReportsPatientRecallsDueData | ReportsRecallsData | ReportsTreatmentPlanData;

export enum ReportDateMode {
  Day = 'day',
  Month = 'month',
  Quarter = 'quarter',
  Week = 'week',
  Year = 'year'
}

export enum ReportType {
  /** Appointment First Visit Kpi */
  AppointmentFirstVisitKpi = 'appointment_first_visit_kpi',
  /** Appointment Occupancy */
  AppointmentOccupancy = 'appointment_occupancy',
  /** Appointment Online Bookings */
  AppointmentOnlineBookings = 'appointment_online_bookings',
  /** Appointment Rebook Rate */
  AppointmentRebookRate = 'appointment_rebook_rate',
  /** Appointment Status */
  AppointmentStatus = 'appointment_status',
  /** Appointments Status */
  AppointmentsStatus = 'appointments_status',
  /** Client Birthdays */
  ClientBirthdays = 'client_birthdays',
  /** Communication Emails */
  CommunicationEmails = 'communication_emails',
  /** Communication Short Messages */
  CommunicationShortMessages = 'communication_short_messages',
  /** Communications Email */
  CommunicationsEmail = 'communications_email',
  /** Communications Short Messages */
  CommunicationsShortMessages = 'communications_short_messages',
  /** Contacts */
  Contacts = 'contacts',
  /** Dashboard Appointment Status */
  DashboardAppointmentStatus = 'dashboard_appointment_status',
  /** Dashboard Appointment Status Grouped */
  DashboardAppointmentStatusGrouped = 'dashboard_appointment_status_grouped',
  /** Dashboard Deposit Requests */
  DashboardDepositRequests = 'dashboard_deposit_requests',
  /** Dashboard Patient Statistics */
  DashboardPatientStatistics = 'dashboard_patient_statistics',
  /** Dashboard Pending Patients */
  DashboardPendingPatients = 'dashboard_pending_patients',
  /** Dashboard Practice Appointments Breakdown */
  DashboardPracticeAppointmentsBreakdown = 'dashboard_practice_appointments_breakdown',
  /** Dashboard Practice Location Review Ratings */
  DashboardPracticeLocationReviewRatings = 'dashboard_practice_location_review_ratings',
  /** Dashboard Practice Location Reviews Word Cloud */
  DashboardPracticeLocationReviewsWordCloud = 'dashboard_practice_location_reviews_word_cloud',
  /** Dashboard Practice Production */
  DashboardPracticeProduction = 'dashboard_practice_production',
  /** Dashboard Practitioner Appointments */
  DashboardPractitionerAppointments = 'dashboard_practitioner_appointments',
  /** Dashboard Practitioner Hourly Rate */
  DashboardPractitionerHourlyRate = 'dashboard_practitioner_hourly_rate',
  /** Dashboard Practitioner New Patients */
  DashboardPractitionerNewPatients = 'dashboard_practitioner_new_patients',
  /** Dashboard Practitioner Rebook Rate */
  DashboardPractitionerRebookRate = 'dashboard_practitioner_rebook_rate',
  /** Dashboard Practitioner Review Ratings */
  DashboardPractitionerReviewRatings = 'dashboard_practitioner_review_ratings',
  /** Dashboard Practitioner Utilisation */
  DashboardPractitionerUtilisation = 'dashboard_practitioner_utilisation',
  /** Dashboard Recent Online Bookings */
  DashboardRecentOnlineBookings = 'dashboard_recent_online_bookings',
  /** Dashboard Short Message Credits Usage */
  DashboardShortMessageCreditsUsage = 'dashboard_short_message_credits_usage',
  /** Deposit Requests */
  DepositRequests = 'deposit_requests',
  /** Finance Account Credits */
  FinanceAccountCredits = 'finance_account_credits',
  /** Finance Aging */
  FinanceAging = 'finance_aging',
  /** Finance Customer Collections */
  FinanceCustomerCollections = 'finance_customer_collections',
  /** Finance Customers In Credit */
  FinanceCustomersInCredit = 'finance_customers_in_credit',
  /** Finance Gst */
  FinanceGst = 'finance_gst',
  /** Finance Invoice Discounts */
  FinanceInvoiceDiscounts = 'finance_invoice_discounts',
  /** Finance Invoice Item Transactions */
  FinanceInvoiceItemTransactions = 'finance_invoice_item_transactions',
  /** Finance Invoice Items */
  FinanceInvoiceItems = 'finance_invoice_items',
  /** Finance Invoice Transactions */
  FinanceInvoiceTransactions = 'finance_invoice_transactions',
  /** Finance Invoices */
  FinanceInvoices = 'finance_invoices',
  /** Finance Lab Fees */
  FinanceLabFees = 'finance_lab_fees',
  /** Finance Outstanding Invoices */
  FinanceOutstandingInvoices = 'finance_outstanding_invoices',
  /** Finance Performance */
  FinancePerformance = 'finance_performance',
  /** Finance Production */
  FinanceProduction = 'finance_production',
  /** Finance Products And Services */
  FinanceProductsAndServices = 'finance_products_and_services',
  /** Finance Uninvoiced Items */
  FinanceUninvoicedItems = 'finance_uninvoiced_items',
  /** Patient Birthdays */
  PatientBirthdays = 'patient_birthdays',
  /** Patient Dropoffs */
  PatientDropoffs = 'patient_dropoffs',
  /** Patient New Patients */
  PatientNewPatients = 'patient_new_patients',
  /** Patient New Patients Kpi */
  PatientNewPatientsKpi = 'patient_new_patients_kpi',
  /** Patient Outstanding Treatment Plans */
  PatientOutstandingTreatmentPlans = 'patient_outstanding_treatment_plans',
  /** Patient Recalls */
  PatientRecalls = 'patient_recalls',
  /** Patient Recalls Due */
  PatientRecallsDue = 'patient_recalls_due',
  /** Patient Treatment Plans */
  PatientTreatmentPlans = 'patient_treatment_plans',
  /** Staff Productivity */
  StaffProductivity = 'staff_productivity',
  /** Summary Invoice Transactionss */
  SummaryInvoiceTransactionss = 'summary_invoice_transactionss'
}

/** Report data representing appointment data */
export type ReportsAppointmentData = {
  __typename?: 'ReportsAppointmentData';
  /** Whether the appointment record allows confirmation email */
  allowConfirmationEmail?: Maybe<Scalars['Boolean']['output']>;
  /** Whether the appointment record allows confirmation SMS */
  allowConfirmationSms?: Maybe<Scalars['Boolean']['output']>;
  /** Whether the appointment record allows reminder email */
  allowReminderEmail?: Maybe<Scalars['Boolean']['output']>;
  /** Whether the appointment record allows reminder SMS */
  allowReminderSms?: Maybe<Scalars['Boolean']['output']>;
  /** The badges assigned to the appointment */
  badges?: Maybe<Array<ReportsBadgesData>>;
  /** The number of billables in the appointment record */
  billablesCount?: Maybe<Scalars['Int']['output']>;
  /** The colour of the appointment record */
  colour?: Maybe<Scalars['String']['output']>;
  /** The number of completed minutes in the appointment record */
  completedMinutes?: Maybe<Scalars['Int']['output']>;
  /** The number of confirmed guests in the appointment record */
  confirmedGuestCount?: Maybe<Scalars['Int']['output']>;
  /** The end time of the appointment record */
  endTime?: Maybe<Scalars['ISO8601DateTime']['output']>;
  /** The time the appointment record was finished */
  finishedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  /** The number of guests in the appointment record */
  guestCount?: Maybe<Scalars['Int']['output']>;
  /** The ID of the appointment record */
  id?: Maybe<Scalars['ID']['output']>;
  /** The invoiced total of the appointment record */
  invoicedTotal?: Maybe<Scalars['Float']['output']>;
  /** Whether the appointment record is confirmed */
  isConfirmed?: Maybe<Scalars['Boolean']['output']>;
  /** Wheather the appointment is a first visit */
  isFirstVisit?: Maybe<Scalars['Boolean']['output']>;
  /** Whether the appointment record is an online booking */
  isOnlineBooking?: Maybe<Scalars['Boolean']['output']>;
  /** The email of the patient the appointment record belongs to */
  patientEmail?: Maybe<Scalars['String']['output']>;
  /** The full name of the patient the appointment record belongs to */
  patientFullName?: Maybe<Scalars['String']['output']>;
  /** The guid of the patient the appointment record belongs to */
  patientGuid?: Maybe<Scalars['ID']['output']>;
  /** The phone of the patient the appointment record belongs to */
  patientPhone?: Maybe<Scalars['String']['output']>;
  /** The name of the practice the appointment record belongs to */
  practiceName?: Maybe<Scalars['String']['output']>;
  /** The full name of the practitioner the appointment record belongs to */
  practitionerFullName?: Maybe<Scalars['String']['output']>;
  /** The proposed total of the appointment record */
  proposedTotal?: Maybe<Scalars['Float']['output']>;
  /** The reference of the appointment record */
  reference?: Maybe<Scalars['String']['output']>;
  reportType?: Maybe<ReportType>;
  /** The number of scheduled minutes in the appointment record */
  scheduledMinutes?: Maybe<Scalars['Int']['output']>;
  /** The slug of the appointment record */
  slug?: Maybe<Scalars['String']['output']>;
  /** The start time of the appointment record */
  startTime?: Maybe<Scalars['ISO8601DateTime']['output']>;
  /** The time the appointment record was started */
  startedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  /** The state of the appointment record */
  state: AppointmentState;
  /** The difference between the proposed and invoiced totals of the appointment record */
  totalDiff?: Maybe<Scalars['Float']['output']>;
};

/** Report for practitioner occupancy */
export type ReportsAppointmentOccupancyData = {
  __typename?: 'ReportsAppointmentOccupancyData';
  /** The name of the account */
  accountName?: Maybe<Scalars['String']['output']>;
  /** Number of cancelled appointments */
  apptsCancelled?: Maybe<Scalars['Int']['output']>;
  /** Number of completed appointments */
  apptsCompleted?: Maybe<Scalars['Int']['output']>;
  /** Number of late cancelled appointments */
  apptsLateCancelled?: Maybe<Scalars['Int']['output']>;
  /** NUmber of scheduled appointments */
  apptsScheduled?: Maybe<Scalars['Int']['output']>;
  /** The date of the record */
  date?: Maybe<Scalars['ISO8601Date']['output']>;
  /** The day of the record */
  day?: Maybe<Scalars['String']['output']>;
  /** Total completed items */
  itemsCompletedCount?: Maybe<Scalars['Int']['output']>;
  /** Total invoiced items */
  itemsInvoicedCount?: Maybe<Scalars['Int']['output']>;
  /** Total proposed items */
  itemsProposedCount?: Maybe<Scalars['Int']['output']>;
  /** Percentage of time the practitioner is free */
  occupancyFreeRate?: Maybe<Scalars['Float']['output']>;
  /** Percentage of patient appointments */
  occupancyPatientRate?: Maybe<Scalars['Float']['output']>;
  /** Percentage of private appointments */
  occupancyPrivateRate?: Maybe<Scalars['Float']['output']>;
  /** The total occupancy rate of the day */
  occupancyRate?: Maybe<Scalars['Float']['output']>;
  /** Name of the practice */
  practiceName?: Maybe<Scalars['String']['output']>;
  /** Practitioners full name */
  practitionerFullName?: Maybe<Scalars['String']['output']>;
  reportType?: Maybe<ReportType>;
  /** Total number of rostered hours in the day */
  rosterHours?: Maybe<Scalars['Float']['output']>;
  /** Total number of free hours */
  rosterHoursFree?: Maybe<Scalars['Float']['output']>;
  /** Total number of hours for patient appointments */
  rosterHoursPatient?: Maybe<Scalars['Float']['output']>;
  /** Total number of hours for private appointments */
  rosterHoursPrivate?: Maybe<Scalars['Float']['output']>;
  /** Total amount invoiced */
  totalInvoiced?: Maybe<Scalars['Float']['output']>;
  /** Total amount paid */
  totalPaid?: Maybe<Scalars['Float']['output']>;
  /** Total amount proposed */
  totalProposed?: Maybe<Scalars['Float']['output']>;
};

/** Report practitioner rebook rates for assigned appointments */
export type ReportsAppointmentRebookRateData = {
  __typename?: 'ReportsAppointmentRebookRateData';
  /** The name of the account */
  accountName?: Maybe<Scalars['String']['output']>;
  /** Number of appoiontments completed during this period */
  completedAppointmentsCount?: Maybe<Scalars['Int']['output']>;
  /** Number of unique patients assigned to these completed appointments */
  completedAppointmentsPatientCount?: Maybe<Scalars['Int']['output']>;
  /** Number of days this report spans. All data in this report is based on this number of days. */
  days?: Maybe<Scalars['Int']['output']>;
  /** The ID of the UPC record */
  id?: Maybe<Scalars['ID']['output']>;
  /** The name of the practice */
  practiceName?: Maybe<Scalars['String']['output']>;
  /** The practitioners name */
  practitionerFullName?: Maybe<Scalars['String']['output']>;
  /** Number of rebooked appointments */
  rebookAppointmentsCount?: Maybe<Scalars['Int']['output']>;
  /** Percentage of rebooked appointments for unique patients */
  rebookRate?: Maybe<Scalars['Int']['output']>;
  reportType?: Maybe<ReportType>;
  /** The ID of the user record, in this case its the practitioner */
  userId?: Maybe<Scalars['ID']['output']>;
};

/** Report data representing badges */
export type ReportsBadgesData = {
  __typename?: 'ReportsBadgesData';
  /** The colour of the badge */
  colour?: Maybe<Scalars['String']['output']>;
  /** The icon name */
  icon?: Maybe<Scalars['String']['output']>;
  /** The name of the badge */
  name?: Maybe<Scalars['String']['output']>;
};

/** Report data showing monthly breakdown of booked appointments by first visit / existing patients */
export type ReportsDashboardAppointmentsData = {
  __typename?: 'ReportsDashboardAppointmentsData';
  /** Number of appointments */
  appointmentsCount?: Maybe<Scalars['Int']['output']>;
  /** Number of arrived appointments */
  arrivedCount?: Maybe<Scalars['Int']['output']>;
  /** Number of cancelled appointments */
  cancelledCount?: Maybe<Scalars['Int']['output']>;
  /** Number of completed appointments */
  completedCount?: Maybe<Scalars['Int']['output']>;
  /** The date of the data point */
  date?: Maybe<Scalars['ISO8601Date']['output']>;
  /** Number of existing visit appointments */
  existingVisitCount?: Maybe<Scalars['Int']['output']>;
  /** NUmber of first visit appointments */
  firstVisitCount?: Maybe<Scalars['Int']['output']>;
  /** Number of late cancellation appointments */
  lateCancellationCount?: Maybe<Scalars['Int']['output']>;
  /** Number of missed appointments */
  missedCount?: Maybe<Scalars['Int']['output']>;
  /** Number of paused appointments */
  pausedCount?: Maybe<Scalars['Int']['output']>;
  /** Number of practitioner finished appointments */
  practitionerIsFinishedCount?: Maybe<Scalars['Int']['output']>;
  /** Number of practitioner ready appointments */
  practitionerIsReadyCount?: Maybe<Scalars['Int']['output']>;
  reportType?: Maybe<ReportType>;
  /** Number of scheduled appointments */
  scheduledCount?: Maybe<Scalars['Int']['output']>;
  /** Number of started appointments */
  startedCount?: Maybe<Scalars['Int']['output']>;
  /** Number of waiting appointments */
  waitingCount?: Maybe<Scalars['Int']['output']>;
};

/** Report data dashboard new patients */
export type ReportsDashboardNewPatientsData = {
  __typename?: 'ReportsDashboardNewPatientsData';
  /** The patient name */
  patientFullName?: Maybe<Scalars['String']['output']>;
  /** The patients referral name */
  patientReferralName?: Maybe<Scalars['String']['output']>;
  /** The name of the practice */
  practiceName: Scalars['String']['output'];
  /** The primary practitioners name */
  primaryPractitionerFullName?: Maybe<Scalars['String']['output']>;
  reportType?: Maybe<ReportType>;
};

/** Report data dashboard new patients */
export type ReportsDashboardPatientStatisticsData = {
  __typename?: 'ReportsDashboardPatientStatisticsData';
  /** Number of created patients */
  patientCount?: Maybe<Scalars['Int']['output']>;
  /** Name of the practice */
  practiceName?: Maybe<Scalars['String']['output']>;
  /** Number of completed recalls */
  recallCompletedCount: Scalars['Int']['output'];
  /** Number of created recalls */
  recallCreatedCount?: Maybe<Scalars['Int']['output']>;
  /** Number of dropped off recalls */
  recallDropOffCount?: Maybe<Scalars['Int']['output']>;
  reportType?: Maybe<ReportType>;
};

/** Report data showing monthly breakdown of booked appointments by first visit / existing patients */
export type ReportsDashboardPractitionerAppointmentsData = {
  __typename?: 'ReportsDashboardPractitionerAppointmentsData';
  /** Number of appointments */
  appointmentsCount?: Maybe<Scalars['Int']['output']>;
  /** Number of arrived appointments */
  arrivedCount?: Maybe<Scalars['Int']['output']>;
  /** Number of cancelled appointments */
  cancelledCount?: Maybe<Scalars['Int']['output']>;
  /** Number of completed appointments */
  completedCount?: Maybe<Scalars['Int']['output']>;
  /** The date of the data point */
  date?: Maybe<Scalars['ISO8601Date']['output']>;
  /** Number of existing visit appointments */
  existingVisitCount?: Maybe<Scalars['Int']['output']>;
  /** NUmber of first visit appointments */
  firstVisitCount?: Maybe<Scalars['Int']['output']>;
  /** Number of late cancellation appointments */
  lateCancellationCount?: Maybe<Scalars['Int']['output']>;
  /** Number of missed appointments */
  missedCount?: Maybe<Scalars['Int']['output']>;
  /** Number of paused appointments */
  pausedCount?: Maybe<Scalars['Int']['output']>;
  /** Practitioners Name */
  practitionerFullName?: Maybe<Scalars['String']['output']>;
  /** Number of practitioner finished appointments */
  practitionerIsFinishedCount?: Maybe<Scalars['Int']['output']>;
  /** Number of practitioner ready appointments */
  practitionerIsReadyCount?: Maybe<Scalars['Int']['output']>;
  /** Practitioner UPC ID */
  practitionerUpcId?: Maybe<Scalars['ID']['output']>;
  reportType?: Maybe<ReportType>;
  /** Number of scheduled appointments */
  scheduledCount?: Maybe<Scalars['Int']['output']>;
  /** Number of started appointments */
  startedCount?: Maybe<Scalars['Int']['output']>;
  /** Number of waiting appointments */
  waitingCount?: Maybe<Scalars['Int']['output']>;
};

/** Report data dashboard short message credits usage */
export type ReportsDashboardShortMessageCreditsUsageData = {
  __typename?: 'ReportsDashboardShortMessageCreditsUsageData';
  /** The date of the data point */
  date?: Maybe<Scalars['ISO8601Date']['output']>;
  /** Total credits that would be used but failed */
  failedCount?: Maybe<Scalars['Int']['output']>;
  /** Total credits that would be used but failed */
  failedCredits?: Maybe<Scalars['Int']['output']>;
  reportType?: Maybe<ReportType>;
  /** Total credits used that are scheduled */
  scheduledCount?: Maybe<Scalars['Int']['output']>;
  /** Total credits used that are scheduled */
  scheduledCredits?: Maybe<Scalars['Int']['output']>;
  /** Total credits used for successful message */
  sentCount?: Maybe<Scalars['Int']['output']>;
  /** Total credits used for successful message */
  sentCredits?: Maybe<Scalars['Int']['output']>;
};

/** Payment allocations for practitioners per invoice item */
export type ReportsFinanceInvoiceItemTransactionData = {
  __typename?: 'ReportsFinanceInvoiceItemTransactionData';
  /** The name of the account */
  accountName?: Maybe<Scalars['String']['output']>;
  /** Amount that was paid/refunded. Refunds are negative */
  amount?: Maybe<Scalars['Float']['output']>;
  /** The date when the payment was made */
  date?: Maybe<Scalars['ISO8601Date']['output']>;
  /** The ID of the UPC record */
  id?: Maybe<Scalars['ID']['output']>;
  /** The ID of the linked invoice */
  invoiceId?: Maybe<Scalars['ID']['output']>;
  /** The code of the product/service that was paid/refunded for */
  invoiceItemCode?: Maybe<Scalars['String']['output']>;
  /** The ID of the linked invoice item */
  invoiceItemId?: Maybe<Scalars['ID']['output']>;
  /** The name of the product/service that was paid/refunded for */
  invoiceItemName?: Maybe<Scalars['String']['output']>;
  /** The total price the invoice item was charged at */
  invoiceItemPriceIncTax?: Maybe<Scalars['Float']['output']>;
  /** Current account credit for the patient */
  patientAccountCredit?: Maybe<Scalars['Float']['output']>;
  /** The email of the patient the invoice record belongs to */
  patientEmail?: Maybe<Scalars['String']['output']>;
  /** The full name of the patient the invoice record belongs to */
  patientFullName?: Maybe<Scalars['String']['output']>;
  /** The guid of the patient the invoice record belongs to */
  patientGuid?: Maybe<Scalars['ID']['output']>;
  /** The phone of the patient the invoice record belongs to */
  patientPhone?: Maybe<Scalars['String']['output']>;
  /** The name of the practice */
  practiceName?: Maybe<Scalars['String']['output']>;
  /** The practitioners name */
  practitionerFullName?: Maybe<Scalars['String']['output']>;
  reportType?: Maybe<ReportType>;
  /** The method of transaction. e.g. "cash", "eftpos", "credit_card" */
  transactionMethod?: Maybe<Scalars['String']['output']>;
  /** The type of transaction e.g. "payment", "refund" */
  transactionType?: Maybe<Scalars['String']['output']>;
};

/**
 * Report data for UPC performance across practices.
 * Data is scoped to the period for which the report was run.
 */
export type ReportsFinancePerformanceData = {
  __typename?: 'ReportsFinancePerformanceData';
  /** The name of the account */
  accountName?: Maybe<Scalars['String']['output']>;
  /** Number of appoiontments completed */
  completedAppointmentsCount?: Maybe<Scalars['Int']['output']>;
  /** Number of completed appointments hours */
  completedAppointmentsCountHours?: Maybe<Scalars['Int']['output']>;
  /** Number of unique patient visits from completed appointments */
  completedAppointmentsPatientCount?: Maybe<Scalars['Int']['output']>;
  /** Number of days this report spans. All data in this report is based on this number of days. */
  days?: Maybe<Scalars['Int']['output']>;
  /** The ID of the UPC record */
  id?: Maybe<Scalars['ID']['output']>;
  /** Total amount of invoiced items */
  invoicedTotal?: Maybe<Scalars['Float']['output']>;
  /** Total amount of invoiced items paid */
  paidTotal?: Maybe<Scalars['Float']['output']>;
  /** The name of the practice */
  practiceName?: Maybe<Scalars['String']['output']>;
  /** The practitioners name */
  practitionerFullName?: Maybe<Scalars['String']['output']>;
  /** Practitioner rate per number of completed appointments */
  ratePerAppointment?: Maybe<Scalars['Float']['output']>;
  /** Practitioner rate per billed appointment hours */
  ratePerBilledHours?: Maybe<Scalars['Float']['output']>;
  /** Practitioner rate per day */
  ratePerDay?: Maybe<Scalars['Float']['output']>;
  reportType?: Maybe<ReportType>;
  /** The ID of the user record */
  userId?: Maybe<Scalars['ID']['output']>;
};

/**
 * Practice production report which reports on figures only for items that are part
 * of accepted treatment plans.
 */
export type ReportsFinanceProductionData = {
  __typename?: 'ReportsFinanceProductionData';
  /** Total amount that was invoiced - the production amount */
  adjusted?: Maybe<Scalars['Float']['output']>;
  /** Date of the data point */
  date?: Maybe<Scalars['ISO8601Date']['output']>;
  /** Day of the data point */
  day?: Maybe<Scalars['String']['output']>;
  /** Total amount that was actually invoiced */
  invoiced?: Maybe<Scalars['Float']['output']>;
  /** Total amount paid for this day */
  paid?: Maybe<Scalars['Float']['output']>;
  /** Total amount of all completed/invoiced items in accepted plans */
  production?: Maybe<Scalars['Float']['output']>;
  reportType?: Maybe<ReportType>;
  /** Total amount of proposed items in accpted plans. */
  scheduled?: Maybe<Scalars['Float']['output']>;
  /** Total amount of production for this day (scheduled + production + adjusted) */
  totalProduction?: Maybe<Scalars['Float']['output']>;
};

/** Report data representing products and services (invoice items) */
export type ReportsFinanceProductsAndServicesData = {
  __typename?: 'ReportsFinanceProductsAndServicesData';
  /** The balance of the invoice item */
  balance?: Maybe<Scalars['Float']['output']>;
  /** The cataloge item public health code of the invoice item */
  catalogeItemPublicHealthCode?: Maybe<Scalars['String']['output']>;
  /** The catalogue item health fund item number of the invoice item */
  catalogueItemHealthFundItemNumber?: Maybe<Scalars['String']['output']>;
  /** The SKU of the invoice item */
  code?: Maybe<Scalars['String']['output']>;
  /** The created at date of the invoice item */
  createdAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  /** The description of the invoice item */
  description?: Maybe<Scalars['String']['output']>;
  /** The ID of the invoice item record */
  id?: Maybe<Scalars['ID']['output']>;
  /** The ID of the invoice the invoice item belongs to */
  invoiceId?: Maybe<Scalars['ID']['output']>;
  /** The issued at date of the invoice the invoice item belongs to */
  invoiceIssuedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  /** The email of the patient the invoice item belongs to */
  patientEmail?: Maybe<Scalars['String']['output']>;
  /** The full name of the patient the invoice item belongs to */
  patientFullName?: Maybe<Scalars['String']['output']>;
  /** The guid of the patient the invoice item belongs to */
  patientGuid?: Maybe<Scalars['ID']['output']>;
  /** The phone number of the patient the invoice item belongs to */
  patientPhone?: Maybe<Scalars['String']['output']>;
  /** The name of the practice the invoice item belongs to */
  practiceName?: Maybe<Scalars['String']['output']>;
  /** The full name of the practitioner the invoice item belongs to */
  practitionerFullName?: Maybe<Scalars['String']['output']>;
  /** The price of the invoice item excluding tax */
  priceExTax?: Maybe<Scalars['Float']['output']>;
  /** The price of the invoice item including tax */
  priceIncTax?: Maybe<Scalars['Float']['output']>;
  /** The quantity of the invoice item */
  quantity?: Maybe<Scalars['Int']['output']>;
  /** The ready to invoice at date of the invoice item */
  readyToInvoiceAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  reportType?: Maybe<ReportType>;
  /** The tooth FDI of the invoice item */
  toothFdi?: Maybe<Scalars['Int']['output']>;
  /** The tooth surface of the invoice item */
  toothSurface?: Maybe<Scalars['String']['output']>;
  /** The total price of the invoice item including tax */
  total?: Maybe<Scalars['Float']['output']>;
  /** The total discount of the invoice item */
  totalDiscount?: Maybe<Scalars['Float']['output']>;
  /** The total price of the invoice item excluding tax */
  totalExTax?: Maybe<Scalars['Float']['output']>;
  /** The total amount paid for the invoice item */
  totalPaid?: Maybe<Scalars['Float']['output']>;
  /** The total tax of the invoice item */
  totalTax?: Maybe<Scalars['Float']['output']>;
};

/** Report data representing invnoice data */
export type ReportsInvoiceData = {
  __typename?: 'ReportsInvoiceData';
  /** The ABN of the invoice record */
  abn?: Maybe<Scalars['String']['output']>;
  /** The balance total of the invoice record */
  balanceTotal?: Maybe<Scalars['Float']['output']>;
  /** The bill to of the invoice record */
  billTo?: Maybe<Scalars['String']['output']>;
  /** The business name of the invoice record */
  businessName?: Maybe<Scalars['String']['output']>;
  /** The time the invoice record was created */
  createdAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  /** The days overdue of the invoice record */
  daysOverdue: Scalars['Int']['output'];
  /** The time the invoice record was due */
  dueAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  /** The ID of the invoice record */
  id?: Maybe<Scalars['ID']['output']>;
  /** The invoice number of the invoice record */
  invoiceNumber?: Maybe<Scalars['ID']['output']>;
  /** The time the invoice record was issued */
  issuedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  /** The time the invoice record was paid */
  paidAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  /** The paid total of the invoice record */
  paidTotal?: Maybe<Scalars['Float']['output']>;
  /** The email of the patient the invoice record belongs to */
  patientEmail?: Maybe<Scalars['String']['output']>;
  /** The full name of the patient the invoice record belongs to */
  patientFullName?: Maybe<Scalars['String']['output']>;
  /** The guid of the patient the invoice record belongs to */
  patientGuid?: Maybe<Scalars['ID']['output']>;
  /** The phone of the patient the invoice record belongs to */
  patientPhone?: Maybe<Scalars['String']['output']>;
  /** The phone of the invoice record */
  phone?: Maybe<Scalars['String']['output']>;
  /** The practice email of the invoice record */
  practiceEmail?: Maybe<Scalars['String']['output']>;
  /** The name of the practice the invoice record belongs to */
  practiceName?: Maybe<Scalars['String']['output']>;
  /** The full name of the practitioner the invoice record belongs to */
  practitionerFullName?: Maybe<Scalars['String']['output']>;
  /** The reference of the invoice record */
  reference?: Maybe<Scalars['String']['output']>;
  reportType?: Maybe<ReportType>;
  /** The state of the invoice record */
  state?: Maybe<InvoiceState>;
  /** The total cost of the invoice record */
  totalCost?: Maybe<Scalars['Float']['output']>;
  /** The total discount of the invoice record */
  totalDiscount?: Maybe<Scalars['Float']['output']>;
  /** The total ex tax of the invoice record */
  totalExTax?: Maybe<Scalars['Float']['output']>;
  /** The total quantity of the invoice record */
  totalQuantity?: Maybe<Scalars['Int']['output']>;
  /** The total tax of the invoice record */
  totalTax?: Maybe<Scalars['Float']['output']>;
  /** The website of the invoice record */
  website?: Maybe<Scalars['String']['output']>;
};

/** Report data representing patient data */
export type ReportsPatientData = {
  __typename?: 'ReportsPatientData';
  /** The account credit for the patient */
  patientAccountCredit: Scalars['Int']['output'];
  /** The alerts assigned to the patient */
  patientAlerts?: Maybe<Array<ReportsBadgesData>>;
  /** The number of appointments for the patient */
  patientAppointmentsCount: Scalars['Int']['output'];
  /** The patients created at date */
  patientCreatedAt: Scalars['ISO8601DateTime']['output'];
  /** The patients date of birth */
  patientDateOfBirth?: Maybe<Scalars['ISO8601Date']['output']>;
  /** The patients email */
  patientEmail?: Maybe<Scalars['String']['output']>;
  /** The first appointment slug for the patient */
  patientFirstAppointmentSlug?: Maybe<Scalars['String']['output']>;
  /** Time of the first appointment */
  patientFirstAppointmentStartTime?: Maybe<Scalars['ISO8601DateTime']['output']>;
  /** Time of the first visit */
  patientFirstVisitTime?: Maybe<Scalars['ISO8601DateTime']['output']>;
  /** The patient name */
  patientFullName?: Maybe<Scalars['String']['output']>;
  /** The patients guid */
  patientGuid: Scalars['ID']['output'];
  /** The current home city/suburb of the patient */
  patientHomeCity?: Maybe<Scalars['String']['output']>;
  /** The current home postcode of the patient */
  patientHomePostcode?: Maybe<Scalars['String']['output']>;
  /** The last appointment slug for the patient */
  patientLastAppointmentSlug?: Maybe<Scalars['String']['output']>;
  /** Time of the last appointment */
  patientLastAppointmentStartTime?: Maybe<Scalars['ISO8601DateTime']['output']>;
  /** Time of the last exam */
  patientLastExamTime?: Maybe<Scalars['ISO8601DateTime']['output']>;
  /** Time of the last medical form */
  patientLastMedicalFormTime?: Maybe<Scalars['ISO8601DateTime']['output']>;
  /** Time of the last visit */
  patientLastVisitTime?: Maybe<Scalars['ISO8601DateTime']['output']>;
  /** Time of the last xray */
  patientLastXrayTime?: Maybe<Scalars['ISO8601DateTime']['output']>;
  /** The next appointment slug for the patient */
  patientNextAppointmentSlug?: Maybe<Scalars['String']['output']>;
  /** Time of the next appointment */
  patientNextAppointmentStartTime?: Maybe<Scalars['ISO8601DateTime']['output']>;
  /** The patients phone number */
  patientPhone?: Maybe<Scalars['String']['output']>;
  /** The name of the pricelist assigned to the patient */
  patientPriceListName?: Maybe<Scalars['String']['output']>;
  /** Recall completed time */
  patientRecallCompletedTime?: Maybe<Scalars['ISO8601DateTime']['output']>;
  /** Recall drop off time */
  patientRecallDropOffTime?: Maybe<Scalars['ISO8601DateTime']['output']>;
  /** Recall due time */
  patientRecallDueTime?: Maybe<Scalars['ISO8601DateTime']['output']>;
  /** The patients referral name */
  patientReferralName?: Maybe<Scalars['String']['output']>;
  /** The total invoices cost for the patient */
  patientTotalInvoicesCost: Scalars['Float']['output'];
  /** The total invoices paid for the patient */
  patientTotalInvoicesPaid: Scalars['Float']['output'];
  /** The name of the practice */
  practiceName: Scalars['String']['output'];
  /** The primary practitioners name */
  primaryPractitionerFullName?: Maybe<Scalars['String']['output']>;
  reportType?: Maybe<ReportType>;
  /** The secondary practitioners name */
  secondaryPractitionerFullName?: Maybe<Scalars['String']['output']>;
};

/** Report data representing patient data */
export type ReportsPatientRecallsDueData = {
  __typename?: 'ReportsPatientRecallsDueData';
  /** The patients age */
  patientAge?: Maybe<Scalars['Int']['output']>;
  /** The total number of appointments this patient has */
  patientAppointmentsCount?: Maybe<Scalars['Int']['output']>;
  /** Determines if the patient has auto recalls turned on */
  patientAutoRecalls: Scalars['Boolean']['output'];
  /** The number of consecutive missed appointments */
  patientConsecutiveMissedAppointmentsCount?: Maybe<Scalars['Int']['output']>;
  /** The patients created at date */
  patientCreatedAt: Scalars['ISO8601DateTime']['output'];
  /** The patients date of birth */
  patientDateOfBirth?: Maybe<Scalars['ISO8601Date']['output']>;
  /** The patients email */
  patientEmail?: Maybe<Scalars['String']['output']>;
  /** The patient name */
  patientFullName?: Maybe<Scalars['String']['output']>;
  /** The patients home full address */
  patientHomeAddress?: Maybe<Scalars['String']['output']>;
  /** Time of the last exam */
  patientLastExamTime?: Maybe<Scalars['ISO8601DateTime']['output']>;
  /** Time of the last visit */
  patientLastVisitTime?: Maybe<Scalars['ISO8601DateTime']['output']>;
  /** Time of the next appointment */
  patientNextAppointmentStartTime?: Maybe<Scalars['ISO8601DateTime']['output']>;
  /** The patients phone number */
  patientPhone?: Maybe<Scalars['String']['output']>;
  /** Recall drop off time */
  patientRecallDropOffTime?: Maybe<Scalars['ISO8601DateTime']['output']>;
  /** Recall exam due time */
  patientRecallExamDueTime?: Maybe<Scalars['ISO8601DateTime']['output']>;
  /** Number of months for the recall due date to be set */
  patientRecallPeriod?: Maybe<Scalars['Int']['output']>;
  /** Recall visit due time */
  patientRecallVisitDueTime?: Maybe<Scalars['ISO8601DateTime']['output']>;
  /** Auto recall workflow name */
  patientRecallWorkflowName?: Maybe<Scalars['Int']['output']>;
  /** The name of the practice */
  practiceName: Scalars['String']['output'];
  /** The primary practitioners name */
  primaryPractitionerFullName?: Maybe<Scalars['String']['output']>;
  reportType?: Maybe<ReportType>;
  /** The secondary practitioners name */
  secondaryPractitionerFullName?: Maybe<Scalars['String']['output']>;
};

/** Report data representing Recalls */
export type ReportsRecallsData = {
  __typename?: 'ReportsRecallsData';
  /** The patients age */
  patientAge?: Maybe<Scalars['Int']['output']>;
  /** The total number of appointments this patient has */
  patientAppointmentsCount?: Maybe<Scalars['Int']['output']>;
  /** The patients date of birth */
  patientDateOfBirth?: Maybe<Scalars['ISO8601Date']['output']>;
  /** The patients email */
  patientEmail?: Maybe<Scalars['String']['output']>;
  /** The patient name */
  patientFullName?: Maybe<Scalars['String']['output']>;
  /** guid of the patient */
  patientGuid?: Maybe<Scalars['ID']['output']>;
  /** The patients home full address */
  patientHomeAddress?: Maybe<Scalars['String']['output']>;
  /** Time of the last exam */
  patientLastExamTime?: Maybe<Scalars['ISO8601DateTime']['output']>;
  /** Time of the last visit */
  patientLastVisitTime?: Maybe<Scalars['ISO8601DateTime']['output']>;
  /** The patients phone number */
  patientPhone?: Maybe<Scalars['String']['output']>;
  /** The name of the practice */
  practiceName: Scalars['String']['output'];
  /** The primary practitioners name */
  primaryPractitionerFullName?: Maybe<Scalars['String']['output']>;
  /** The recalls cancelled at date */
  recallCancelledAt: Scalars['ISO8601DateTime']['output'];
  /** The recalls completed at date */
  recallCompletedAt: Scalars['ISO8601DateTime']['output'];
  /** The recalls created at date */
  recallCreatedAt: Scalars['ISO8601DateTime']['output'];
  /** The recalls expired at date */
  recallExpiredAt: Scalars['ISO8601DateTime']['output'];
  /** The name of the recall */
  recallName?: Maybe<Scalars['String']['output']>;
  /** JSON data of the recall reminders */
  recallReminders?: Maybe<Array<ReportsRemindersData>>;
  /** The recalls scheduled dropoff at date */
  recallScheudledDropoffAt: Scalars['ISO8601DateTime']['output'];
  /** The state of the recall */
  recallState?: Maybe<RecallState>;
  /** The type of recall */
  recallType?: Maybe<RecallType>;
  /** Recall workflow name */
  recallWorkflowName?: Maybe<Scalars['Int']['output']>;
  reportType?: Maybe<ReportType>;
  /** The secondary practitioners name */
  secondaryPractitionerFullName?: Maybe<Scalars['String']['output']>;
};

/** Report data representing reminders */
export type ReportsRemindersData = {
  __typename?: 'ReportsRemindersData';
  /** The type of reminder */
  contactType?: Maybe<WorkflowStepContact>;
  failureCauses?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  /** The position of the reminder */
  position?: Maybe<Scalars['Int']['output']>;
  /** The state of the reminder */
  state?: Maybe<ReminderState>;
  /** The time when its triggered */
  triggeredAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
};

/**
 * A saved report is a saved instance of the AG grid reports.\nWe support saving
 * the columns, filters, sort, and other settings so that\nthey can easily be reloaded.
 */
export type ReportsSavedReport = {
  __typename?: 'ReportsSavedReport';
  /** When this record was created */
  createdAt: Scalars['ISO8601DateTime']['output'];
  /** Description of the saved report */
  description?: Maybe<Scalars['String']['output']>;
  /** When this record was soft deleted */
  discardedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  /** The ID of the saved report */
  id: Scalars['ID']['output'];
  /** The name of the saved report */
  name?: Maybe<Scalars['String']['output']>;
  /** The practice that this autoclave resides under */
  practice?: Maybe<Practice>;
  /** The GUID for the practice */
  practiceGuid?: Maybe<Scalars['String']['output']>;
  /** The practitioner that created this autoclave */
  practitioner?: Maybe<UserPracticeLink>;
  /** The GUID for the practitioner who created this cycle */
  practitionerGuid?: Maybe<Scalars['String']['output']>;
  /** The type of saved report */
  reportType?: Maybe<ReportType>;
  /** The AGGrid state of then report */
  state?: Maybe<ReportsSavedReportState>;
  /** When this record was last updated */
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

/** The connection type for ReportsSavedReport. */
export type ReportsSavedReportConnection = {
  __typename?: 'ReportsSavedReportConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<ReportsSavedReportEdge>>>;
  /** IDs of ALL results, regardless of pagination */
  ids: Array<Scalars['ID']['output']>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<ReportsSavedReport>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Returns total count of items in list */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type ReportsSavedReportEdge = {
  __typename?: 'ReportsSavedReportEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<ReportsSavedReport>;
};

export type ReportsSavedReportInput = {
  /** Boolean flag to archive/unarchive the saved report */
  archive?: InputMaybe<Scalars['Boolean']['input']>;
  /** (AG Grid) - Column state */
  columnState?: InputMaybe<Array<Scalars['JSON']['input']>>;
  /** The date preset to use e.g last_week, last_month */
  datePreset?: InputMaybe<Scalars['String']['input']>;
  /** A description of the saved report */
  description?: InputMaybe<Scalars['String']['input']>;
  /** (AG Grid) - Filter model */
  filterModel?: InputMaybe<Scalars['JSON']['input']>;
  /** (AG Grid) - Group state */
  groupState?: InputMaybe<Array<Scalars['JSON']['input']>>;
  /** The name of the saved report */
  name?: InputMaybe<Scalars['String']['input']>;
  /** The type of report to save this for */
  reportType?: InputMaybe<ReportType>;
};

export type ReportsSavedReportState = {
  __typename?: 'ReportsSavedReportState';
  /** AG Grid columnstate */
  columnState?: Maybe<Array<Scalars['JSON']['output']>>;
  /** AG Grid date preset e.g This Month, Last Month etc. */
  datePreset?: Maybe<Scalars['String']['output']>;
  /** AG Grid filter models */
  filterModel?: Maybe<Scalars['JSON']['output']>;
  /** AG Grid group state */
  groupState?: Maybe<Scalars['JSON']['output']>;
};

/** Report data representing treatment plans */
export type ReportsTreatmentPlanData = {
  __typename?: 'ReportsTreatmentPlanData';
  /** The datetime which this option was accepted at */
  acceptedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  /** The total appointments in this option */
  appointmentsCount?: Maybe<Scalars['Int']['output']>;
  /** The colour of the option */
  colour?: Maybe<Scalars['String']['output']>;
  /** The total cost of all items in the option that are not declined and have been completed */
  completedCost?: Maybe<Scalars['Float']['output']>;
  /** The total completed item count */
  completedItemsCount?: Maybe<Scalars['Int']['output']>;
  /** ID of the option plan */
  id?: Maybe<Scalars['ID']['output']>;
  /** Name of the option */
  name?: Maybe<Scalars['String']['output']>;
  /** The email of the patient the appointment record belongs to */
  patientEmail?: Maybe<Scalars['String']['output']>;
  /** The full name of the patient the appointment record belongs to */
  patientFullName?: Maybe<Scalars['String']['output']>;
  /** The guid of the patient the appointment record belongs to */
  patientGuid?: Maybe<Scalars['ID']['output']>;
  /** The current home city/suburb of the patient */
  patientHomeCity?: Maybe<Scalars['String']['output']>;
  /** The current home postcode of the patient */
  patientHomePostcode?: Maybe<Scalars['String']['output']>;
  /** The phone of the patient the appointment record belongs to */
  patientPhone?: Maybe<Scalars['String']['output']>;
  /** The current pricelist assigned to the patient */
  patientPriceListName?: Maybe<Scalars['String']['output']>;
  /** The name of the practice the appointment record belongs to */
  practiceName?: Maybe<Scalars['String']['output']>;
  /** The full name of the practitioner the appointment record belongs to */
  practitionerFullName?: Maybe<Scalars['String']['output']>;
  /** The total proposed cost of all items in the option */
  proposedCost?: Maybe<Scalars['Float']['output']>;
  /** The total proposed item count */
  proposedItemsCount?: Maybe<Scalars['Int']['output']>;
  /** The datetime which this option was rejected at */
  rejectedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  reportType?: Maybe<ReportType>;
  /** The current state of the option */
  state?: Maybe<TreatmentPlanState>;
  /** The total cost of all items in the option that are not declined */
  totalCost?: Maybe<Scalars['Float']['output']>;
  /** The total count of items in the plan */
  totalCount?: Maybe<Scalars['Int']['output']>;
  /** The total amount paid of items that have been invoiced and paid */
  totalPaid?: Maybe<Scalars['Float']['output']>;
  /** The date which the treatment started for */
  treatmentDateStarted?: Maybe<Scalars['ISO8601Date']['output']>;
  /** The slug of the option */
  treatmentPlanSlug?: Maybe<Scalars['String']['output']>;
  /** The slug of the treatment */
  treatmentSlug?: Maybe<Scalars['String']['output']>;
  /** The state of the overlaying treatment */
  treatmentState?: Maybe<TreatmentState>;
  /** The title of the treatment */
  treatmentTitle?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of RequestAuthenticatorCode */
export type RequestAuthenticatorCodeInput = {
  attributes: AuthenticatorCodeAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of RequestAuthenticatorCode. */
export type RequestAuthenticatorCodePayload = {
  __typename?: 'RequestAuthenticatorCodePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  fieldErrors?: Maybe<Array<FieldError>>;
  otp?: Maybe<Otp>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

/** Autogenerated input type of RequestOtp */
export type RequestOtpInput = {
  attributes: OtpAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of RequestOtp. */
export type RequestOtpPayload = {
  __typename?: 'RequestOtpPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  fieldErrors?: Maybe<Array<FieldError>>;
  otp?: Maybe<Otp>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

/** Autogenerated input type of RequestPaymentIntent */
export type RequestPaymentIntentInput = {
  attributes?: InputMaybe<PaymentIntentInput>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of RequestPaymentIntent. */
export type RequestPaymentIntentPayload = {
  __typename?: 'RequestPaymentIntentPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  fieldErrors?: Maybe<Array<FieldError>>;
  paymentIntent?: Maybe<PaymentIntent>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

/** Autogenerated input type of RescheduleAppointment */
export type RescheduleAppointmentInput = {
  attributes: AppointmentRescheduleInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of RescheduleAppointment. */
export type RescheduleAppointmentPayload = {
  __typename?: 'RescheduleAppointmentPayload';
  appointment?: Maybe<Appointment>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  fieldErrors?: Maybe<Array<FieldError>>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

/** Autogenerated input type of RescheduleAppointments */
export type RescheduleAppointmentsInput = {
  attributes: Array<AppointmentRescheduleInput>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of RescheduleAppointments. */
export type RescheduleAppointmentsPayload = {
  __typename?: 'RescheduleAppointmentsPayload';
  appointments?: Maybe<Array<Appointment>>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  fieldErrors?: Maybe<Array<FieldError>>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

/** Autogenerated input type of ResendCustomerRequest */
export type ResendCustomerRequestInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Customer Request ID to update */
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of ResendCustomerRequest. */
export type ResendCustomerRequestPayload = {
  __typename?: 'ResendCustomerRequestPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  customerRequest?: Maybe<CustomerRequest>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  fieldErrors?: Maybe<Array<FieldError>>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

/** Autogenerated input type of ResendInvitation */
export type ResendInvitationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  guid: Scalars['ID']['input'];
};

/** Autogenerated return type of ResendInvitation. */
export type ResendInvitationPayload = {
  __typename?: 'ResendInvitationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  fieldErrors?: Maybe<Array<FieldError>>;
  success?: Maybe<Scalars['Boolean']['output']>;
  userPracticeConnection?: Maybe<UserPracticeLink>;
};

export type ResetPasswordAttributes = {
  /** Password for the account */
  password: Scalars['String']['input'];
  /** Password confirmation for the account */
  passwordConfirmation: Scalars['String']['input'];
  /** Password recovery token */
  token: Scalars['String']['input'];
};

/** Autogenerated input type of ResetPassword */
export type ResetPasswordInput = {
  attributes: ResetPasswordAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of ResetPassword. */
export type ResetPasswordPayload = {
  __typename?: 'ResetPasswordPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  fieldErrors?: Maybe<Array<FieldError>>;
  success?: Maybe<Scalars['Boolean']['output']>;
  user?: Maybe<User>;
};

/** Autogenerated input type of ResolveTooth */
export type ResolveToothInput = {
  attributes: DentalResolveToothInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** ID of the original tooth treatment */
  toothTreatmentId: Scalars['ID']['input'];
};

/** Autogenerated return type of ResolveTooth. */
export type ResolveToothPayload = {
  __typename?: 'ResolveToothPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  dentalTooth?: Maybe<DentalTooth>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  fieldErrors?: Maybe<Array<FieldError>>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type ResponseMessage = MessageInterface & {
  __typename?: 'ResponseMessage';
  /** Additional details, for logging or debugging purposes */
  details?: Maybe<Scalars['String']['output']>;
  /** Message to display to the user */
  message: Scalars['String']['output'];
  /** Message type indicating how to display this message (error/info/warn/debug) */
  type: ResponseMessageType;
};

export enum ResponseMessageType {
  /** Debug message, only shown when debugging is enabled in the context */
  Debug = 'debug',
  /** Error message to the user */
  Error = 'error',
  /** Informational message to the user */
  Info = 'info',
  /** Warning message to the user */
  Warn = 'warn'
}

/** Autogenerated input type of RestoreDefaults */
export type RestoreDefaultsInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of RestoreDefaults. */
export type RestoreDefaultsPayload = {
  __typename?: 'RestoreDefaultsPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  fieldErrors?: Maybe<Array<FieldError>>;
  groups?: Maybe<Array<Group>>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

/** Autogenerated input type of RetryEmail */
export type RetryEmailInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** ID of the email to cancel */
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of RetryEmail. */
export type RetryEmailPayload = {
  __typename?: 'RetryEmailPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Email>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  fieldErrors?: Maybe<Array<FieldError>>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

/** Autogenerated input type of RetrySms */
export type RetrySmsInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** ID of the email to cancel */
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of RetrySms. */
export type RetrySmsPayload = {
  __typename?: 'RetrySmsPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  fieldErrors?: Maybe<Array<FieldError>>;
  sms?: Maybe<ShortMessage>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type ReviewAnswer = {
  __typename?: 'ReviewAnswer';
  /** Question for the response */
  question: Scalars['String']['output'];
  /** Rating for the question */
  rating: Scalars['Int']['output'];
};

export type ReviewRequestsInput = {
  /** Avg rating for the review */
  avgRatingRange?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  /** Filter by review template */
  reviewTemplateIds?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type ReviewResponse = AnyNode & {
  __typename?: 'ReviewResponse';
  /** Answers for the review response */
  answers?: Maybe<Array<ReviewAnswer>>;
  /** Avg rating for the review template */
  avgRating?: Maybe<Scalars['Float']['output']>;
  /** Comment for the review response */
  comment?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  /** The original request */
  customerRequest?: Maybe<CustomerRequest>;
  /** Customer Request ID */
  customerRequestId?: Maybe<Scalars['ID']['output']>;
  /** Date of the review response */
  date?: Maybe<Scalars['ISO8601Date']['output']>;
  /** Review response ID */
  id: Scalars['ID']['output'];
  /** Max rating for the review template */
  maxRating?: Maybe<Scalars['Int']['output']>;
  /** Min rating for the review template */
  minRating?: Maybe<Scalars['Int']['output']>;
  /** The practice location that is linked to this response */
  practiceLocation?: Maybe<PracticeLocation>;
  /** Practice Location ID */
  practiceLocationId?: Maybe<Scalars['ID']['output']>;
  /** The practitioner that is linked to this response */
  practitioner?: Maybe<UserPracticeLink>;
  /** Practitioner ID */
  practitionerId?: Maybe<Scalars['ID']['output']>;
  /** Who asked for this review */
  reviewable?: Maybe<AnyNode>;
  sentAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  /** The review template that is linked to this response */
  template?: Maybe<ReviewTemplate>;
  /** Review Template ID */
  templateId?: Maybe<Scalars['ID']['output']>;
  updatedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  /** The UPC that created this response */
  userPracticeConnection?: Maybe<UserPracticeLink>;
  /** User Practice Location ID */
  userPracticeLocationId?: Maybe<Scalars['ID']['output']>;
  /** Unique identifier of the message */
  uuid: Scalars['String']['output'];
};

export type ReviewResponseAnswerInput = {
  /** Question for the response */
  question: Scalars['String']['input'];
  /** Rating for the question */
  rating: Scalars['Int']['input'];
};

export type ReviewResponseInput = {
  answers?: InputMaybe<Array<ReviewResponseAnswerInput>>;
  /** Comment for the response (if required) */
  comment?: InputMaybe<Scalars['String']['input']>;
  /** Original customer request for this review response */
  customerRequest?: InputMaybe<Scalars['ID']['input']>;
};

export type ReviewTemplate = AnyNode & {
  __typename?: 'ReviewTemplate';
  /** Allow comment for the review template */
  allowComment?: Maybe<Scalars['Boolean']['output']>;
  /** When enabled, will autosend the review out for linked appointments */
  autoSendAppointmentReviews?: Maybe<Scalars['Boolean']['output']>;
  /** Avg rating for the review template */
  avgRating?: Maybe<Scalars['Float']['output']>;
  createdAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  /** Description of the review template */
  description?: Maybe<Scalars['String']['output']>;
  discardedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  /** Google review link for the review template */
  googleReviewLink?: Maybe<Scalars['String']['output']>;
  /** Google review min rating for the review template */
  googleReviewMinRating?: Maybe<Scalars['Float']['output']>;
  /** Bolean to indicate that a google review redirect is enabled */
  googleReviewRedirect?: Maybe<Scalars['Boolean']['output']>;
  /** Review template ID */
  id: Scalars['ID']['output'];
  /** Max rating for the review template */
  maxRating?: Maybe<Scalars['Int']['output']>;
  /** Min rating for the review template */
  minRating?: Maybe<Scalars['Int']['output']>;
  /** Name of the review template */
  name?: Maybe<Scalars['String']['output']>;
  practice?: Maybe<Practice>;
  /** Practice ID */
  practiceId?: Maybe<Scalars['ID']['output']>;
  /** Questions for the review template */
  questions?: Maybe<Array<Scalars['String']['output']>>;
  /** Number of responses for the review template */
  responsesCount?: Maybe<Scalars['Int']['output']>;
  updatedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  /** Unique identifier of the message */
  uuid: Scalars['String']['output'];
};

/** The connection type for ReviewTemplate. */
export type ReviewTemplateConnection = {
  __typename?: 'ReviewTemplateConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<ReviewTemplateEdge>>>;
  /** IDs of ALL results, regardless of pagination */
  ids: Array<Scalars['ID']['output']>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<ReviewTemplate>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Returns total count of items in list */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type ReviewTemplateEdge = {
  __typename?: 'ReviewTemplateEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<ReviewTemplate>;
};

export type ReviewTemplateInput = {
  /** Allow comment for the review template */
  allowComment?: InputMaybe<Scalars['Boolean']['input']>;
  /** Archive the review template */
  archive?: InputMaybe<Scalars['Boolean']['input']>;
  /** When enabled, will autosend the review out for linked appointments */
  autoSendAppointmentReviews?: InputMaybe<Scalars['Boolean']['input']>;
  /** Description of the review template to display to the users */
  description?: InputMaybe<Scalars['String']['input']>;
  /** Google review link for the review template */
  googleReviewLink?: InputMaybe<Scalars['String']['input']>;
  /** Google review min rating for the review template */
  googleReviewMinRating?: InputMaybe<Scalars['Float']['input']>;
  /** Bolean to indicate that a google review redirect is enabled */
  googleReviewRedirect?: InputMaybe<Scalars['Boolean']['input']>;
  /** Name of the review template */
  name?: InputMaybe<Scalars['String']['input']>;
  /** Array of questions */
  questions?: InputMaybe<Array<Scalars['String']['input']>>;
  reviewTemplate?: InputMaybe<Scalars['ID']['input']>;
};

/** Autogenerated input type of RevokePermissionFromGroup */
export type RevokePermissionFromGroupInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  groupId: Scalars['ID']['input'];
  /** Permission slug */
  permission: Scalars['String']['input'];
};

/** Autogenerated return type of RevokePermissionFromGroup. */
export type RevokePermissionFromGroupPayload = {
  __typename?: 'RevokePermissionFromGroupPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  fieldErrors?: Maybe<Array<FieldError>>;
  group?: Maybe<Group>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

/** Autogenerated input type of RevokePermission */
export type RevokePermissionInput = {
  attributes: PermissionInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of RevokePermission. */
export type RevokePermissionPayload = {
  __typename?: 'RevokePermissionPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  fieldErrors?: Maybe<Array<FieldError>>;
  role?: Maybe<Role>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type RichText = Badgeable & {
  __typename?: 'RichText';
  /** Badges assigned to this directory */
  badges?: Maybe<Array<BadgesBadgeLink>>;
  /** Class used to render the rich text mergefields */
  classType?: Maybe<RichTextClassInfo>;
  createdAt: Scalars['ISO8601DateTime']['output'];
  createdBy?: Maybe<UserPracticeLink>;
  /** Whether or not this template is the default template for this class_name */
  default?: Maybe<Scalars['Boolean']['output']>;
  directory?: Maybe<Directory>;
  /** SyncFusion Document loaded into the editor */
  docx?: Maybe<Scalars['String']['output']>;
  guid: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
  parent?: Maybe<RichText>;
  /** ID of the parent document, if any */
  parentId?: Maybe<Scalars['ID']['output']>;
  patient?: Maybe<UserPracticeLink>;
  /** Previously exported PDF file */
  pdf?: Maybe<Scalars['String']['output']>;
  /** SyncFusion Document loaded into the editor */
  png?: Maybe<Scalars['String']['output']>;
  record?: Maybe<AnyNode>;
  recordUuid?: Maybe<Scalars['String']['output']>;
  /** Whether or not this is a template */
  template?: Maybe<Scalars['Boolean']['output']>;
  updatedAt: Scalars['ISO8601DateTime']['output'];
  updatedBy?: Maybe<UserPracticeLink>;
  /** UUID of the linked record */
  uuid: Scalars['String']['output'];
};

export enum RichTextClass {
  AppointmentGuest = 'AppointmentGuest',
  DentalPerioExam = 'Dental__PerioExam',
  Form = 'Form',
  Invoice = 'Invoice',
  Recall = 'Recall',
  Referral = 'Referral',
  Treatment = 'Treatment',
  UserPracticeConnection = 'UserPracticeConnection'
}

export type RichTextClassInfo = {
  __typename?: 'RichTextClassInfo';
  className?: Maybe<RichTextClass>;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
  variables?: Maybe<Array<TemplateVariable>>;
};

/** The connection type for RichText. */
export type RichTextConnection = {
  __typename?: 'RichTextConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<RichTextEdge>>>;
  /** IDs of ALL results, regardless of pagination */
  ids: Array<Scalars['ID']['output']>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<RichText>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Returns total count of items in list */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type RichTextEdge = {
  __typename?: 'RichTextEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<RichText>;
};

export type RichTextInput = {
  /** Badges to attach to the document */
  badges?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Class Name of the Document */
  className?: InputMaybe<RichTextClass>;
  /** Whether or not this template is the default template for this class_name */
  default?: InputMaybe<Scalars['Boolean']['input']>;
  /** Directory to store the document (not required for templates) */
  directory?: InputMaybe<Scalars['ID']['input']>;
  /** SyncFusion Document */
  docx?: InputMaybe<Scalars['Blob']['input']>;
  /** Name of the Document */
  name?: InputMaybe<Scalars['String']['input']>;
  /** Parent document (Template) */
  parent?: InputMaybe<Scalars['ID']['input']>;
  /** Patient to assign the document to */
  patient?: InputMaybe<Scalars['ID']['input']>;
  /** SyncFusion PDF export */
  pdf?: InputMaybe<Scalars['Blob']['input']>;
  /** SyncFusion Image Preview */
  png?: InputMaybe<Scalars['Blob']['input']>;
  /** GraphQL UUID (base64(ClassName:ID)) of the object to attach the note to */
  record?: InputMaybe<Scalars['String']['input']>;
};

export type RichTextSearchParameters = {
  /** Filter by badges */
  badges?: InputMaybe<Array<Scalars['String']['input']>>;
  className?: InputMaybe<RichTextClass>;
  /** Filter by default (only useful together with template: true) */
  default?: InputMaybe<Scalars['Boolean']['input']>;
  directory?: InputMaybe<Scalars['ID']['input']>;
  /** Search for patient documents */
  patient?: InputMaybe<Scalars['ID']['input']>;
  /** Search for documents linked to a specific record */
  record?: InputMaybe<Scalars['ID']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  /** Whether or not to search for templates only */
  template?: InputMaybe<Scalars['Boolean']['input']>;
};

export type Role = {
  __typename?: 'Role';
  groups?: Maybe<Array<Group>>;
  id: Scalars['ID']['output'];
  name?: Maybe<PracticeRole>;
  permissions?: Maybe<Array<Permission>>;
  practice: Practice;
  slug?: Maybe<Scalars['String']['output']>;
  userPracticeConnections?: Maybe<UserPracticeLinkConnection>;
};

/** The connection type for Role. */
export type RoleConnection = {
  __typename?: 'RoleConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<RoleEdge>>>;
  /** IDs of ALL results, regardless of pagination */
  ids: Array<Scalars['ID']['output']>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Role>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Returns total count of items in list */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type RoleEdge = {
  __typename?: 'RoleEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<Role>;
};

export type Room = {
  __typename?: 'Room';
  createdAt: Scalars['ISO8601DateTime']['output'];
  guid: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  /** Name of the room */
  name?: Maybe<Scalars['String']['output']>;
  /** Practice that the room belongs_to */
  practice?: Maybe<Practice>;
  /** Practice location the room belongs to */
  practiceLocation?: Maybe<PracticeLocation>;
  /** Practice location the room belongs to */
  practiceLocationId?: Maybe<Scalars['ID']['output']>;
  state?: Maybe<RoomState>;
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

/** The connection type for Room. */
export type RoomConnection = {
  __typename?: 'RoomConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<RoomEdge>>>;
  /** IDs of ALL results, regardless of pagination */
  ids: Array<Scalars['ID']['output']>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Room>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Returns total count of items in list */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type RoomEdge = {
  __typename?: 'RoomEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<Room>;
};

export type RoomInput = {
  name: Scalars['String']['input'];
  /** Practice Location to update */
  practiceLocation?: InputMaybe<Scalars['ID']['input']>;
};

export enum RoomState {
  Active = 'active',
  Archived = 'archived'
}

export enum RoomStateEvent {
  Activate = 'activate',
  Archive = 'archive'
}

export type RosterDay = {
  __typename?: 'RosterDay';
  day?: Maybe<Scalars['ISO8601Date']['output']>;
  /** The most recent event for this day */
  event?: Maybe<RosterEvent>;
  /** All events for this day */
  events?: Maybe<Array<RosterEvent>>;
};

export type RosterEvent = {
  __typename?: 'RosterEvent';
  /** The date that this event ends. If this is a recurring event, this is the last date that the event will occur. */
  endDate?: Maybe<Scalars['ISO8601Date']['output']>;
  /** The ID of the event. */
  id: Scalars['ID']['output'];
  /** The frequency that this event recurs. */
  recurringFrequency?: Maybe<RecurringFrequency>;
  /** The number of times that this event recurs. */
  recurringRate?: Maybe<Scalars['Int']['output']>;
  /** Only for Monthly recurring frequency. day (mon, tues, etc) or date (1st, 2nd, etc) */
  recurringType?: Maybe<RecurringType>;
  shifts?: Maybe<Array<RosterShift>>;
  /** The UserPracticeConnection that this event is associated with. */
  userPracticeConnection?: Maybe<UserPracticeLink>;
  /** The ID of the UserPracticeConnection that this event is associated with. */
  userPracticeConnectionId?: Maybe<Scalars['ID']['output']>;
};

/** The connection type for RosterEvent. */
export type RosterEventConnection = {
  __typename?: 'RosterEventConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<RosterEventEdge>>>;
  /** IDs of ALL results, regardless of pagination */
  ids: Array<Scalars['ID']['output']>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<RosterEvent>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Returns total count of items in list */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type RosterEventEdge = {
  __typename?: 'RosterEventEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<RosterEvent>;
};

export type RosterShift = {
  __typename?: 'RosterShift';
  endTime: Scalars['String']['output'];
  guid: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  onlineBookings?: Maybe<Scalars['Boolean']['output']>;
  shiftType?: Maybe<RosterShiftType>;
  startTime: Scalars['String']['output'];
};

export type RosterShiftType = {
  __typename?: 'RosterShiftType';
  blockAvailability?: Maybe<Scalars['Boolean']['output']>;
  colour: Scalars['String']['output'];
  guid: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  state: ShiftTypeState;
};

/** The connection type for RosterShiftType. */
export type RosterShiftTypeConnection = {
  __typename?: 'RosterShiftTypeConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<RosterShiftTypeEdge>>>;
  /** IDs of ALL results, regardless of pagination */
  ids: Array<Scalars['ID']['output']>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<RosterShiftType>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Returns total count of items in list */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type RosterShiftTypeEdge = {
  __typename?: 'RosterShiftTypeEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<RosterShiftType>;
};

export enum Scanner {
  Cliniview = 'cliniview',
  CsImaging = 'csImaging',
  Dbswin = 'dbswin',
  ExaminePro = 'examinePro',
  Ezdent = 'ezdent',
  FloridaProbe = 'floridaProbe',
  Idixel = 'idixel',
  Newtom = 'newtom',
  Romexis = 'romexis',
  Scanora = 'scanora',
  Sidexis = 'sidexis',
  SteriStock = 'steriStock',
  Trios = 'trios',
  Vistasoft = 'vistasoft'
}

export type ScanningIntegrationInput = {
  cliniview?: InputMaybe<Scalars['String']['input']>;
  csimaging?: InputMaybe<Scalars['String']['input']>;
  dbswin?: InputMaybe<Scalars['String']['input']>;
  examinepro?: InputMaybe<Scalars['String']['input']>;
  ezdent?: InputMaybe<Scalars['String']['input']>;
  floridaprobe?: InputMaybe<Scalars['String']['input']>;
  idixel?: InputMaybe<Scalars['String']['input']>;
  newtom?: InputMaybe<Scalars['String']['input']>;
  romexis?: InputMaybe<Scalars['String']['input']>;
  scanora?: InputMaybe<Scalars['String']['input']>;
  sidexis?: InputMaybe<Scalars['String']['input']>;
  steriStock?: InputMaybe<Scalars['String']['input']>;
  trios?: InputMaybe<Scalars['String']['input']>;
  vistasoft?: InputMaybe<Scalars['String']['input']>;
};

export type ScanningIntegrations = {
  __typename?: 'ScanningIntegrations';
  cliniview?: Maybe<Scalars['String']['output']>;
  csimaging?: Maybe<Scalars['String']['output']>;
  dbswin?: Maybe<Scalars['String']['output']>;
  examinepro?: Maybe<Scalars['String']['output']>;
  ezdent?: Maybe<Scalars['String']['output']>;
  floridaprobe?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  idixel?: Maybe<Scalars['String']['output']>;
  newtom?: Maybe<Scalars['String']['output']>;
  romexis?: Maybe<Scalars['String']['output']>;
  scanora?: Maybe<Scalars['String']['output']>;
  sidexis?: Maybe<Scalars['String']['output']>;
  steriStock?: Maybe<Scalars['String']['output']>;
  trios?: Maybe<Scalars['String']['output']>;
  vistasoft?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of ScheduleReminderInvoice */
export type ScheduleReminderInvoiceInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  daysAfterDue?: InputMaybe<Scalars['Int']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of ScheduleReminderInvoice. */
export type ScheduleReminderInvoicePayload = {
  __typename?: 'ScheduleReminderInvoicePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  fieldErrors?: Maybe<Array<FieldError>>;
  reminder?: Maybe<Reminder>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

/** Autogenerated input type of SendAppointmentConfirmationNotifications */
export type SendAppointmentConfirmationNotificationsInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of SendAppointmentConfirmationNotifications. */
export type SendAppointmentConfirmationNotificationsPayload = {
  __typename?: 'SendAppointmentConfirmationNotificationsPayload';
  appointment?: Maybe<Appointment>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  fieldErrors?: Maybe<Array<FieldError>>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

/** Autogenerated input type of SendAppointmentReminderNotifications */
export type SendAppointmentReminderNotificationsInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of SendAppointmentReminderNotifications. */
export type SendAppointmentReminderNotificationsPayload = {
  __typename?: 'SendAppointmentReminderNotificationsPayload';
  appointment?: Maybe<Appointment>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  fieldErrors?: Maybe<Array<FieldError>>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

/** Autogenerated input type of SendAppointmentReviewRequest */
export type SendAppointmentReviewRequestInput = {
  appointment?: InputMaybe<Scalars['ID']['input']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  reviewTemplate?: InputMaybe<Scalars['ID']['input']>;
};

/** Autogenerated return type of SendAppointmentReviewRequest. */
export type SendAppointmentReviewRequestPayload = {
  __typename?: 'SendAppointmentReviewRequestPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  fieldErrors?: Maybe<Array<FieldError>>;
  reviewRequest?: Maybe<CustomerRequest>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

/** Autogenerated input type of SendBulkCommunications */
export type SendBulkCommunicationsInput = {
  attributes: CreateCommunicationsInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of SendBulkCommunications. */
export type SendBulkCommunicationsPayload = {
  __typename?: 'SendBulkCommunicationsPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  conversation?: Maybe<Conversation>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  fieldErrors?: Maybe<Array<FieldError>>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

/** Autogenerated input type of SendCommunications */
export type SendCommunicationsInput = {
  attributes: CreateCommunicationsInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of SendCommunications. */
export type SendCommunicationsPayload = {
  __typename?: 'SendCommunicationsPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  conversation?: Maybe<Conversation>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  fieldErrors?: Maybe<Array<FieldError>>;
  messages?: Maybe<Array<Message>>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

/** Autogenerated input type of SendRecoveryCode */
export type SendRecoveryCodeInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Users email address */
  email?: InputMaybe<Scalars['String']['input']>;
  /** OTP method, email/sms */
  otpMethod?: InputMaybe<OtpMethod>;
  /** Users Password */
  password?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of SendRecoveryCode. */
export type SendRecoveryCodePayload = {
  __typename?: 'SendRecoveryCodePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** General errors, unrelated to fields */
  errors?: Maybe<Array<Scalars['String']['output']>>;
  /** Input field errors */
  fieldErrors?: Maybe<Array<FieldError>>;
  /** Whether or not this mutation was successful */
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type Session = {
  __typename?: 'Session';
  /** This field will be set if the user is using a restricted session */
  accessRestriction?: Maybe<AccessRestriction>;
  account?: Maybe<Account>;
  env?: Maybe<ApplicationEnvironment>;
  expiresAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  id: Scalars['ID']['output'];
  practice?: Maybe<Practice>;
  practiceConnection?: Maybe<UserPracticeLink>;
  practiceLocation?: Maybe<PracticeLocation>;
  user?: Maybe<User>;
};

export type SessionStatus = {
  __typename?: 'SessionStatus';
  accessRestriction?: Maybe<AccessRestriction>;
  env?: Maybe<ApplicationEnvironment>;
  id: Scalars['ID']['output'];
  isValid?: Maybe<Scalars['Boolean']['output']>;
  practiceConnection?: Maybe<UserPracticeLink>;
  tokenExpiry?: Maybe<Scalars['ISO8601DateTime']['output']>;
};

/** Autogenerated return type of SessionUpdated. */
export type SessionUpdatedPayload = {
  __typename?: 'SessionUpdatedPayload';
  session?: Maybe<SessionStatus>;
};

export type ShiftInput = {
  endTime: Scalars['String']['input'];
  onlineBookings?: InputMaybe<Scalars['Boolean']['input']>;
  shiftTypeId?: InputMaybe<Scalars['ID']['input']>;
  startTime: Scalars['String']['input'];
};

export type ShiftTypeInput = {
  blockAvailability?: InputMaybe<Scalars['Boolean']['input']>;
  colour: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

export enum ShiftTypeState {
  Active = 'active',
  Archived = 'archived'
}

export enum ShiftTypeStateEvent {
  Activate = 'activate',
  Archive = 'archive'
}

export type ShortMessage = {
  __typename?: 'ShortMessage';
  /** The action to take when a reply is received directly after this message */
  autoReplyAction?: Maybe<Scalars['String']['output']>;
  communicationType?: Maybe<Scalars['String']['output']>;
  content?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  /** The date and time the message was discarded */
  discardedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  failureCauses?: Maybe<Scalars['String']['output']>;
  guid: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  /** Returns true if message is inbound */
  inbound?: Maybe<Scalars['Boolean']['output']>;
  isMarketing?: Maybe<Scalars['Boolean']['output']>;
  messageable?: Maybe<AnyNode>;
  messageableId?: Maybe<Scalars['ID']['output']>;
  messageableType?: Maybe<Scalars['String']['output']>;
  /** Returns true if message is outbound */
  outbound?: Maybe<Scalars['Boolean']['output']>;
  receiveableId?: Maybe<Scalars['ID']['output']>;
  receiveableType?: Maybe<Scalars['Int']['output']>;
  /** The recipient of the message */
  recipient?: Maybe<Participant>;
  sendAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  sendableId?: Maybe<Scalars['ID']['output']>;
  sendableType?: Maybe<Scalars['Int']['output']>;
  /** The sender of the message */
  sender?: Maybe<Participant>;
  state?: Maybe<ShortMessageState>;
  template?: Maybe<Template>;
  toMobile?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
};

/** The connection type for ShortMessage. */
export type ShortMessageConnection = {
  __typename?: 'ShortMessageConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<ShortMessageEdge>>>;
  /** IDs of ALL results, regardless of pagination */
  ids: Array<Scalars['ID']['output']>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<ShortMessage>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Returns total count of items in list */
  totalCount: Scalars['Int']['output'];
};

export type ShortMessageDetailedList = {
  __typename?: 'ShortMessageDetailedList';
  content?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  createdAtLong?: Maybe<Scalars['String']['output']>;
  displayState?: Maybe<Scalars['String']['output']>;
  failureCauses?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  initials?: Maybe<Scalars['String']['output']>;
  receiver?: Maybe<Scalars['String']['output']>;
  sendAtLong?: Maybe<Scalars['String']['output']>;
  state?: Maybe<Scalars['String']['output']>;
  whom?: Maybe<Scalars['String']['output']>;
};

/** An edge in a connection. */
export type ShortMessageEdge = {
  __typename?: 'ShortMessageEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<ShortMessage>;
};

export enum ShortMessageState {
  Cancelled = 'cancelled',
  Failed = 'failed',
  Prepared = 'prepared',
  Ready = 'ready',
  Received = 'received',
  Scheduled = 'scheduled',
  Sent = 'sent'
}

export enum SidekiqJobState {
  Cancelled = 'cancelled',
  Completed = 'completed',
  Failed = 'failed',
  Pending = 'pending',
  Started = 'started'
}

export type SignUpAttributes = {
  /** Email used to log in */
  email: Scalars['String']['input'];
  /** First name of the user */
  firstName?: InputMaybe<Scalars['String']['input']>;
  /** Industry to associate business to */
  industryId: Scalars['ID']['input'];
  /** Last name of the user */
  lastName?: InputMaybe<Scalars['String']['input']>;
  /** Phone number */
  mobilePhone: Scalars['String']['input'];
  /** Mobile region code */
  mobileRegionCode: Scalars['Int']['input'];
  /** Mobile region short code */
  mobileRegionShort: Scalars['String']['input'];
  /** Password for the account */
  password: Scalars['String']['input'];
  /** Password confirmation for the account */
  passwordConfirmation: Scalars['String']['input'];
  /** Name of the Business */
  practiceName: Scalars['String']['input'];
};

/** Autogenerated input type of SignUp */
export type SignUpInput = {
  attributes: SignUpAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of SignUp. */
export type SignUpPayload = {
  __typename?: 'SignUpPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  fieldErrors?: Maybe<Array<FieldError>>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type SmsInput = {
  body: Scalars['String']['input'];
};

export enum SmsProvider {
  AmazonSns = 'amazon_sns',
  BurstSms = 'burst_sms'
}

export type Snippet = {
  __typename?: 'Snippet';
  content: Scalars['String']['output'];
  createdAt: Scalars['ISO8601DateTime']['output'];
  id: Scalars['ID']['output'];
  isPrivate: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  snippetType: SnippetType;
  state: SnippetState;
  updatedAt: Scalars['ISO8601DateTime']['output'];
  userPracticeConnection: UserPracticeLink;
};

export type SnippetInput = {
  content: Scalars['String']['input'];
  isPrivate?: InputMaybe<Scalars['Boolean']['input']>;
  name: Scalars['String']['input'];
  type?: InputMaybe<SnippetType>;
};

export enum SnippetState {
  Active = 'active',
  Archived = 'archived'
}

export enum SnippetStateEvent {
  Activate = 'activate',
  Archive = 'archive'
}

export enum SnippetType {
  General = 'general',
  LetterFooter = 'letter_footer',
  LetterHead = 'letter_head'
}

export type SocialLinks = {
  __typename?: 'SocialLinks';
  facebookUrl?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  instagramUrl?: Maybe<Scalars['String']['output']>;
  linkedinUrl?: Maybe<Scalars['String']['output']>;
  xUrl?: Maybe<Scalars['String']['output']>;
  youtubeUrl?: Maybe<Scalars['String']['output']>;
};

export type SocialLinksInput = {
  facebookUrl?: InputMaybe<Scalars['String']['input']>;
  instagramUrl?: InputMaybe<Scalars['String']['input']>;
  linkedinUrl?: InputMaybe<Scalars['String']['input']>;
  xUrl?: InputMaybe<Scalars['String']['input']>;
  youtubeUrl?: InputMaybe<Scalars['String']['input']>;
};

export type SocialMediaLinkInput = {
  facebookUrl?: InputMaybe<Scalars['String']['input']>;
  instagramUrl?: InputMaybe<Scalars['String']['input']>;
  linkedinUrl?: InputMaybe<Scalars['String']['input']>;
  youtubeUrl?: InputMaybe<Scalars['String']['input']>;
};

export type SocialMediaLinks = {
  __typename?: 'SocialMediaLinks';
  /** Link to Facebook presence (if any) */
  facebookUrl?: Maybe<Scalars['String']['output']>;
  /** Link to instagram presence (if any) */
  instagramUrl?: Maybe<Scalars['String']['output']>;
  /** Link to LinkedIn presence (if any) */
  linkedinUrl?: Maybe<Scalars['String']['output']>;
  /** Link to youtube presence (if any) */
  youtubeUrl?: Maybe<Scalars['String']['output']>;
};

export type StatisticsInput = {
  date?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
  statistic: PatientTimestamps;
};

/** Autoclave is a machine used for sterlisation of equiptment in a dental practice */
export type SterilisationAutoclave = {
  __typename?: 'SterilisationAutoclave';
  /** When this record was created */
  createdAt: Scalars['ISO8601DateTime']['output'];
  /** Number of cycles that this autoclave was used for */
  cycleCount: Scalars['Int']['output'];
  /** The cycles assigned to this autoclave machine */
  cycles?: Maybe<SterilisationCycleConnection>;
  /** The description of the autoclave. e.g Room 1 */
  description?: Maybe<Scalars['String']['output']>;
  /** When this record was soft deleted */
  discardedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  /** The ID of the autoclave */
  id: Scalars['String']['output'];
  /** The autoclave manufacturer */
  manufacture?: Maybe<Scalars['String']['output']>;
  /** The autoclave model number */
  model?: Maybe<Scalars['String']['output']>;
  /** The name of the autoclave */
  name: Scalars['String']['output'];
  /** The photo of the autoclave */
  photo?: Maybe<CoverPhotoUploaderAwsLinks>;
  /** The practice that this autoclave resides under */
  practice?: Maybe<Practice>;
  /** The GUID for the practice */
  practiceGuid?: Maybe<Scalars['String']['output']>;
  practiceLocation?: Maybe<PracticeLocation>;
  /** The ID of the practice location this UPC is assigned to */
  practiceLocationId?: Maybe<Scalars['ID']['output']>;
  /** The practitioner that created this autoclave */
  practitioner?: Maybe<UserPracticeLink>;
  /** The GUID for the practitioner who created this cycle */
  practitionerGuid?: Maybe<Scalars['String']['output']>;
  /** The total cost of the unit */
  purchaseCost?: Maybe<Scalars['Float']['output']>;
  /** The purchase date */
  purchaseDate?: Maybe<Scalars['ISO8601DateTime']['output']>;
  /** The supplier of the autoclave */
  purchaseSupplier?: Maybe<Scalars['String']['output']>;
  /** Number of months for the warranty */
  purchaseWarrantyMonths?: Maybe<Scalars['Int']['output']>;
  /** The autoclave serial number */
  serial?: Maybe<Scalars['String']['output']>;
  /** When this record was last updated */
  updatedAt: Scalars['ISO8601DateTime']['output'];
};


/** Autoclave is a machine used for sterlisation of equiptment in a dental practice */
export type SterilisationAutoclaveCyclesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** The connection type for SterilisationAutoclave. */
export type SterilisationAutoclaveConnection = {
  __typename?: 'SterilisationAutoclaveConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<SterilisationAutoclaveEdge>>>;
  /** IDs of ALL results, regardless of pagination */
  ids: Array<Scalars['ID']['output']>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<SterilisationAutoclave>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Returns total count of items in list */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type SterilisationAutoclaveEdge = {
  __typename?: 'SterilisationAutoclaveEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<SterilisationAutoclave>;
};

export type SterilisationAutoclaveInput = {
  /** Set if you want to archive/unarchive record */
  archive?: InputMaybe<Scalars['Boolean']['input']>;
  /** Description of the autoclave */
  description?: InputMaybe<Scalars['String']['input']>;
  /** Manufacturer */
  manufacture?: InputMaybe<Scalars['String']['input']>;
  /** Model */
  model?: InputMaybe<Scalars['String']['input']>;
  /** The name of the autoclave */
  name?: InputMaybe<Scalars['String']['input']>;
  /** Base64 image */
  photo?: InputMaybe<Scalars['String']['input']>;
  /** Practice Location to update */
  practiceLocation?: InputMaybe<Scalars['ID']['input']>;
  /** Cost of purchase from supplier */
  purchaseCost?: InputMaybe<Scalars['Float']['input']>;
  /** Date of purchase */
  purchaseDate?: InputMaybe<Scalars['ISO8601Date']['input']>;
  /** Supplier that was purchased from */
  purchaseSupplier?: InputMaybe<Scalars['String']['input']>;
  /** The number of months of included warranty from purchase date */
  purchaseWarrantyMonths?: InputMaybe<Scalars['Int']['input']>;
  /** Serial Number */
  serial?: InputMaybe<Scalars['String']['input']>;
};

/**
 * A cycle is used to record the autoclave cleaning cycles.\nEverything from which
 * type of sterlisation process, the cycle count,\nwhen it started/finished.
 */
export type SterilisationCycle = {
  __typename?: 'SterilisationCycle';
  /** The autoclaved used for the cycle */
  autoclave?: Maybe<SterilisationAutoclave>;
  /** The Id of the autoclave machine */
  autoclaveId?: Maybe<Scalars['String']['output']>;
  /** Barcode for the given cycle */
  barcode: Scalars['String']['output'];
  /** When the cycle was completed */
  completedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  /** The practitioner that this cycle loaded by */
  completedBy?: Maybe<UserPracticeLink>;
  /** The GUID of the upc who completed the cycle */
  completedByGuid?: Maybe<Scalars['String']['output']>;
  /** When this record was created */
  createdAt: Scalars['ISO8601DateTime']['output'];
  /** State of the cycle */
  cycleState: SterilisationCycleStateType;
  /** The cycle type used */
  cycleType?: Maybe<SterilisationCycleType>;
  /** The Id of the cycle type */
  cycleTypeId?: Maybe<Scalars['String']['output']>;
  /** Date of the cycle. Defaults to the created date */
  date: Scalars['ISO8601Date']['output'];
  /** When this record was soft deleted */
  discardedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  /** When the cycle is to expire */
  expireAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  /** The ID of the cycle */
  id: Scalars['String']['output'];
  /** When the cycle was inspected */
  inspectedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  /** The practitioner that this cycle inspected by */
  inspectedBy?: Maybe<UserPracticeLink>;
  /** The GUID of the upc who inspected the cycle */
  inspectedByGuid?: Maybe<Scalars['String']['output']>;
  /** Inspection state of the cycle */
  inspectionState: SterilisationCycleInspectionStateType;
  /** When the cycle was loaded */
  loadedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  /** The practitioner that this cycle loaded by */
  loadedBy?: Maybe<UserPracticeLink>;
  /** The GUID of the upc who loaded the cycle */
  loadedByGuid?: Maybe<Scalars['String']['output']>;
  /** If cycle is locked from editing */
  locked: Scalars['Boolean']['output'];
  /** When this record was locked and not available for editing */
  lockedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  /** Cycle notes */
  notes?: Maybe<Scalars['String']['output']>;
  /** Cycle number */
  number: Scalars['Int']['output'];
  /** The practice that this cycle resides under */
  practice?: Maybe<Practice>;
  /** The GUID for the practice */
  practiceGuid?: Maybe<Scalars['String']['output']>;
  practiceLocation?: Maybe<PracticeLocation>;
  /** The ID of the practice location this UPC is assigned to */
  practiceLocationId?: Maybe<Scalars['ID']['output']>;
  /** The practitioner that created this cycle */
  practitioner?: Maybe<UserPracticeLink>;
  /** The GUID for the practitioner who created this cycle */
  practitionerGuid?: Maybe<Scalars['String']['output']>;
  /** When the cycle was started */
  startedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  /** The practitioner that this cycle started by */
  startedBy?: Maybe<UserPracticeLink>;
  /** The GUID of the upc who started the cycle */
  startedByGuid?: Maybe<Scalars['String']['output']>;
  /** When the cycle was unloaded */
  unloadedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  /** The practitioner that this cycle unloaded by */
  unloadedBy?: Maybe<UserPracticeLink>;
  /** The GUID of the upc who unloaded the cycle */
  unloadedByGuid?: Maybe<Scalars['String']['output']>;
  /** When this record was last updated */
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

/** The connection type for SterilisationCycle. */
export type SterilisationCycleConnection = {
  __typename?: 'SterilisationCycleConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<SterilisationCycleEdge>>>;
  /** IDs of ALL results, regardless of pagination */
  ids: Array<Scalars['ID']['output']>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<SterilisationCycle>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Returns total count of items in list */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type SterilisationCycleEdge = {
  __typename?: 'SterilisationCycleEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<SterilisationCycle>;
};

export type SterilisationCycleInput = {
  /** Set if you want to archive/unarchive record */
  archive?: InputMaybe<Scalars['Boolean']['input']>;
  /** ID of the linked autoclave */
  autoclaveId?: InputMaybe<Scalars['String']['input']>;
  /** The name of the cycle */
  barcode?: InputMaybe<Scalars['String']['input']>;
  /** Set the time when the cycle was completed */
  completedAt?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
  /** Trigger cycle state update */
  cycleStateEvent?: InputMaybe<SterilisationCycleStateEventType>;
  /** ID of the linked cycle type */
  cycleTypeId?: InputMaybe<Scalars['String']['input']>;
  /** Set the time when the cycle items expire at */
  expireAt?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
  /** Trigger inspection state update */
  inspectionStateEvent?: InputMaybe<SterilisationCycleInspectionStateEventType>;
  /** Set the time when the cycle was loaded */
  loadedAt?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
  /** If you want to lock to disable editing */
  lock?: InputMaybe<Scalars['Boolean']['input']>;
  /** Additional notes */
  notes?: InputMaybe<Scalars['String']['input']>;
  /** The cycle number */
  number?: InputMaybe<Scalars['Int']['input']>;
  /** Set the time when the cycle was started */
  startedAt?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
  /** Set the time when the cycle was unloaded */
  unloadedAt?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
};

export enum SterilisationCycleInspectionStateEventType {
  FailInspection = 'fail_inspection',
  PartiallyFailInspection = 'partially_fail_inspection',
  PassInspection = 'pass_inspection',
  SetPending = 'set_pending'
}

export enum SterilisationCycleInspectionStateType {
  /** Failed */
  Failed = 'failed',
  /** Partially Failed */
  PartiallyFailed = 'partially_failed',
  /** Passed */
  Passed = 'passed',
  /** Pending */
  Pending = 'pending'
}

export enum SterilisationCycleStateEventType {
  CompleteCycle = 'complete_cycle',
  LoadCycle = 'load_cycle',
  SetDraft = 'set_draft',
  StartCycle = 'start_cycle',
  UnloadCycle = 'unload_cycle'
}

export enum SterilisationCycleStateType {
  /** Completed */
  Completed = 'completed',
  /** Draft */
  Draft = 'draft',
  /** Loaded */
  Loaded = 'loaded',
  /** Started */
  Started = 'started',
  /** Unloaded */
  Unloaded = 'unloaded'
}

/** Autoclave cycle types are used to define the type of cycle that the autoclave machine is running */
export type SterilisationCycleType = {
  __typename?: 'SterilisationCycleType';
  /** When this record was created */
  createdAt: Scalars['ISO8601DateTime']['output'];
  /** Number of cycles that this cycle type was used for */
  cycleCount: Scalars['Int']['output'];
  /** The cycles assigned to this autoclave machine */
  cycles?: Maybe<SterilisationCycleConnection>;
  /** When this record was soft deleted */
  discardedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  /** The ID of the cycle type */
  id: Scalars['String']['output'];
  /** The name of the cycle type */
  name: Scalars['String']['output'];
  /** The practice that this cycle type resides under */
  practice?: Maybe<Practice>;
  /** The GUID for the practice */
  practiceGuid?: Maybe<Scalars['String']['output']>;
  /** The practitioner that created this cycle type */
  practitioner?: Maybe<UserPracticeLink>;
  /** The GUID for the practitioner who created this cycle */
  practitionerGuid?: Maybe<Scalars['String']['output']>;
  /** When this record was last updated */
  updatedAt: Scalars['ISO8601DateTime']['output'];
};


/** Autoclave cycle types are used to define the type of cycle that the autoclave machine is running */
export type SterilisationCycleTypeCyclesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** The connection type for SterilisationCycleType. */
export type SterilisationCycleTypeConnection = {
  __typename?: 'SterilisationCycleTypeConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<SterilisationCycleTypeEdge>>>;
  /** IDs of ALL results, regardless of pagination */
  ids: Array<Scalars['ID']['output']>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<SterilisationCycleType>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Returns total count of items in list */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type SterilisationCycleTypeEdge = {
  __typename?: 'SterilisationCycleTypeEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<SterilisationCycleType>;
};

export type SterilisationCycleTypeInput = {
  /** Set if you want to archive/unarchive record */
  archive?: InputMaybe<Scalars['Boolean']['input']>;
  /** The name of the cycle type */
  name?: InputMaybe<Scalars['String']['input']>;
};

/**
 * Used to track the cycles to a given patient through a trackable reference.\nA
 * trackable could be anything from an Appointment, Treatment Plan, Patient or\na
 * treatment item within a plan.
 */
export type SterilisationTracking = {
  __typename?: 'SterilisationTracking';
  /** The barcode associated to this tracking link */
  barcode: Scalars['String']['output'];
  /** When this record was created */
  createdAt: Scalars['ISO8601DateTime']['output'];
  /** The cycle that this tracking belongs to */
  cycle: SterilisationCycle;
  /** The ID of the cycle */
  cycleId: Scalars['String']['output'];
  /** When this record was soft deleted */
  discardedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  /** The ID of the cycle */
  id: Scalars['String']['output'];
  /** The patient this tracking belongs to */
  patient: UserPracticeLink;
  /** THe GUID of the patient */
  patientGuid?: Maybe<Scalars['String']['output']>;
  /** The practice this tracking belongs to */
  practice: Practice;
  /** THe GUID of the practice */
  practiceGuid: Scalars['String']['output'];
  /** The practitioner that created this tracking */
  practitioner?: Maybe<UserPracticeLink>;
  /** The GUID for the practitioner who created this tracking */
  practitionerGuid?: Maybe<Scalars['String']['output']>;
  trackable?: Maybe<AnyNode>;
  /** THe GUID of the trackable object */
  trackableId: Scalars['String']['output'];
  /** The object linked to this tracking */
  trackableType: Scalars['String']['output'];
  /** When this record was last updated */
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

/** The connection type for SterilisationTracking. */
export type SterilisationTrackingConnection = {
  __typename?: 'SterilisationTrackingConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<SterilisationTrackingEdge>>>;
  /** IDs of ALL results, regardless of pagination */
  ids: Array<Scalars['ID']['output']>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<SterilisationTracking>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Returns total count of items in list */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type SterilisationTrackingEdge = {
  __typename?: 'SterilisationTrackingEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<SterilisationTracking>;
};

export type SterilisationTrackingInput = {
  /** Set if you want to archive/unarchive record */
  archive?: InputMaybe<Scalars['Boolean']['input']>;
  /** Barcode for the cycle to add a tracking for (If known) */
  barcode?: InputMaybe<Scalars['String']['input']>;
  /** If selected. Will create a new cycle and add the tracking to it if we cannot find by barcode */
  createCycle?: InputMaybe<Scalars['Boolean']['input']>;
  /** ID for the cycle to add a tracking for (If known) */
  cycleId?: InputMaybe<Scalars['String']['input']>;
  /** GUID for the patient to add a tracking for (If known) */
  patientGuid?: InputMaybe<Scalars['String']['input']>;
  /** UUID/GUID of the object the tracking is for */
  trackableId?: InputMaybe<Scalars['String']['input']>;
  /** Name of the object the tracking is for */
  trackableType?: InputMaybe<Scalars['String']['input']>;
  /** GraphQL UUID (base64(ClassName:ID)) of the object to attach the tracking to */
  trackableUuid?: InputMaybe<Scalars['String']['input']>;
};

export type StripeCapabilities = {
  __typename?: 'StripeCapabilities';
  cardPayments?: Maybe<Scalars['String']['output']>;
  transfers?: Maybe<Scalars['String']['output']>;
};

export type StripeCredentials = {
  __typename?: 'StripeCredentials';
  /** Stripe Public Key */
  publicKey?: Maybe<Scalars['Base64']['output']>;
};

export type StripePaymentProviderInput = {
  /** Apply the surcharge to the patient invoice */
  applyPatientSurcharge?: InputMaybe<Scalars['Boolean']['input']>;
  /** Enable/Disable payment provider */
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  /** The id of the payment provider */
  id: Scalars['ID']['input'];
};

export type StripeProducts = {
  __typename?: 'StripeProducts';
  created?: Maybe<Scalars['Int']['output']>;
  currency?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  nickname?: Maybe<Scalars['String']['output']>;
  product?: Maybe<Scalars['String']['output']>;
  unitAmount?: Maybe<Scalars['Int']['output']>;
};

/** Autogenerated input type of StripeRefund */
export type StripeRefundInput = {
  amount: Scalars['Int']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of StripeRefund. */
export type StripeRefundPayload = {
  __typename?: 'StripeRefundPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  fieldErrors?: Maybe<Array<FieldError>>;
  stripeRefund?: Maybe<RefundResponse>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type StripeSubscription = {
  __typename?: 'StripeSubscription';
  currentPeriodEnd?: Maybe<Scalars['ISO8601DateTime']['output']>;
  currentPeriodStart?: Maybe<Scalars['ISO8601DateTime']['output']>;
};

export type Subscription = {
  __typename?: 'Subscription';
  accountPlanUpdated?: Maybe<AccountPlanUpdatedPayload>;
  appointmentUpdated?: Maybe<AppointmentUpdatedPayload>;
  attachmentUpdated?: Maybe<AttachmentUpdatedPayload>;
  avatarUpdated?: Maybe<AvatarUpdatedPayload>;
  directoryUpdated?: Maybe<DirectoryUpdatedPayload>;
  emailUpdated?: Maybe<EmailUpdatedPayload>;
  health?: Maybe<HealthPayload>;
  invoiceTransactionsUpdated?: Maybe<InvoiceTransactionsUpdatedPayload>;
  invoiceUpdated?: Maybe<InvoiceUpdatedPayload>;
  messageUpdated?: Maybe<MessageUpdatedPayload>;
  mobileAppointmentUpdated?: Maybe<MobileAppointmentUpdatedPayload>;
  sessionUpdated?: Maybe<SessionUpdatedPayload>;
  userUpdated?: Maybe<UserUpdatedPayload>;
  waitlistUpdated?: Maybe<WaitlistUpdatedPayload>;
};


export type SubscriptionDirectoryUpdatedArgs = {
  id: Scalars['ID']['input'];
};


export type SubscriptionInvoiceTransactionsUpdatedArgs = {
  guid?: InputMaybe<Scalars['ID']['input']>;
};


export type SubscriptionInvoiceUpdatedArgs = {
  guid?: InputMaybe<Scalars['ID']['input']>;
};


export type SubscriptionMobileAppointmentUpdatedArgs = {
  guid: Scalars['ID']['input'];
};


export type SubscriptionUserUpdatedArgs = {
  guid: Scalars['ID']['input'];
};

export type SubscriptionPaymentTransactions = {
  __typename?: 'SubscriptionPaymentTransactions';
  amount?: Maybe<Scalars['Int']['output']>;
  created?: Maybe<Scalars['Int']['output']>;
  currency?: Maybe<Scalars['String']['output']>;
  failureMessage?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  metadata?: Maybe<Scalars['JSON']['output']>;
  paymentMethodDetails?: Maybe<PaymentMethodDetails>;
  receiptUrl?: Maybe<Scalars['String']['output']>;
  refunds?: Maybe<Refund>;
  status?: Maybe<Scalars['String']['output']>;
};

/** Summary table for reports */
export type SummaryList = AppointmentSummaryList | EmailSummaryList | InvoiceItemSummaryList | InvoiceTransactionsSummaryList | OutstandingInvoiceSummaryList;

/** Autogenerated input type of Switch */
export type SwitchInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  upc: Scalars['ID']['input'];
};

/** Autogenerated return type of Switch. */
export type SwitchPayload = {
  __typename?: 'SwitchPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  fieldErrors?: Maybe<Array<FieldError>>;
  /** Retrieve a new session when switching user */
  session?: Maybe<Session>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

/** Autogenerated input type of SyncConnectedAccount */
export type SyncConnectedAccountInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of SyncConnectedAccount. */
export type SyncConnectedAccountPayload = {
  __typename?: 'SyncConnectedAccountPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  fieldErrors?: Maybe<Array<FieldError>>;
  paymentProvider?: Maybe<PaymentProvider>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type SyncFusionCredentials = {
  __typename?: 'SyncFusionCredentials';
  /** Backend server for SyncFusion */
  backendUrl?: Maybe<Scalars['String']['output']>;
  /** SyncFusion License Key */
  licenseKey?: Maybe<Scalars['Base64']['output']>;
};

export type Tag = {
  __typename?: 'Tag';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  taggable?: Maybe<AnyNode>;
};

export type TagInput = {
  /** Name of the tag */
  name: Scalars['String']['input'];
  /** UUID of object to attach tag to */
  uuid: Scalars['String']['input'];
};

export type Task = {
  __typename?: 'Task';
  color?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['ISO8601DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  dueAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  dueAtLabel?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  practice?: Maybe<Practice>;
  recurringTask?: Maybe<RecurringTask>;
  reminder?: Maybe<Reminder>;
  state?: Maybe<TaskState>;
  taskAssignees?: Maybe<Array<TaskAssignee>>;
  title?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

export type TaskAssignee = {
  __typename?: 'TaskAssignee';
  id: Scalars['ID']['output'];
  practiceProfessionalId?: Maybe<Scalars['ID']['output']>;
  taskId: Scalars['ID']['output'];
  userPracticeConnection?: Maybe<UserPracticeLink>;
  userPracticeConnectionId?: Maybe<Scalars['ID']['output']>;
};

/** The connection type for Task. */
export type TaskConnection = {
  __typename?: 'TaskConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<TaskEdge>>>;
  /** IDs of ALL results, regardless of pagination */
  ids: Array<Scalars['ID']['output']>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Task>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Returns total count of items in list */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type TaskEdge = {
  __typename?: 'TaskEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<Task>;
};

export type TaskInput = {
  /** Description the task */
  description?: InputMaybe<Scalars['String']['input']>;
  /** The due date for the task */
  dueAt?: InputMaybe<Scalars['ISO8601Date']['input']>;
  /** List of assigned staff */
  taskAssignees?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** Title of the task */
  title: Scalars['String']['input'];
};

export enum TaskState {
  Cancelled = 'cancelled',
  Completed = 'completed',
  Hidden = 'hidden',
  InProgress = 'in_progress',
  Incomplete = 'incomplete',
  Overdue = 'overdue'
}

export enum TaskStateEvent {
  Cancel = 'cancel',
  Complete = 'complete',
  Overdue = 'overdue',
  Start = 'start',
  Trigger = 'trigger'
}

export type Tax = {
  __typename?: 'Tax';
  displayName?: Maybe<Scalars['String']['output']>;
  percentage?: Maybe<Scalars['Float']['output']>;
};

/** Available kinds of templates */
export type Template = CommunicationTemplate | DocumentTemplate | FormTemplate;

export type TemplateAssignable = AppointmentReason | Form | Invoice | Recall;

/** The connection type for Template. */
export type TemplateConnection = {
  __typename?: 'TemplateConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<TemplateEdge>>>;
  /** IDs of ALL results, regardless of pagination */
  ids: Array<Scalars['ID']['output']>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Template>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Returns total count of items in list */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type TemplateEdge = {
  __typename?: 'TemplateEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<Template>;
};

/**
 * Template Groups are used to group a bunch of template types in
 * a logical group, for example the following could be considered
 * to belong to the same group (Appointment):
 * - appointment_reminder
 * - appointment_confirmation
 * - appointment_cancellation
 *
 * When editing templates, its useful to be able to filter our
 * growing list of template types by what group they belong to.
 */
export enum TemplateGroup {
  Appointments = 'appointments',
  Communications = 'communications',
  CustomerRequests = 'customer_requests',
  Forms = 'forms',
  Invoices = 'invoices',
  Letters = 'letters',
  TreatmentPlans = 'treatment_plans'
}

/**
 * Template Groups are used to group a bunch of template types in
 * a logical group, for example the following could be considered
 * to belong to the same group (Appointment):
 * - appointment_reminder
 * - appointment_confirmation
 * - appointment_cancellation
 *
 * When editing templates, its useful to be able to filter our
 * growing list of template types by what group they belong to.
 */
export type TemplateGroupTranslation = {
  __typename?: 'TemplateGroupTranslation';
  description?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  slug: TemplateGroup;
};

export type TemplateInput = {
  attachments?: InputMaybe<Array<Scalars['ID']['input']>>;
  description?: InputMaybe<Scalars['String']['input']>;
  /** Additional drops to include in this document template */
  drops?: InputMaybe<Array<LiquidDropFieldInput>>;
  html?: InputMaybe<Scalars['String']['input']>;
  letter?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  plainText?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<TemplateState>;
  subject?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<TemplateType>;
  variant?: InputMaybe<TemplateVariant>;
};

/** Interface for common fields for Templates */
export type TemplateInterface = {
  assigned?: Maybe<Array<TemplateAssignable>>;
  attachments?: Maybe<Array<Attachment>>;
  createdAt: Scalars['ISO8601DateTime']['output'];
  /** Template marked as default */
  default: Scalars['Boolean']['output'];
  description?: Maybe<Scalars['String']['output']>;
  /** What this template type is used for (e.g Forms, Appointments, Invoices) */
  group?: Maybe<TemplateGroupTranslation>;
  guid: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
  practice: Practice;
  practiceId: Scalars['ID']['output'];
  state: TemplateState;
  type: TemplateType;
  typeDefinition?: Maybe<TemplateTypeTranslation>;
  uuid: Scalars['String']['output'];
  /** How this template is to be used - e.g. Document (only HTML), Communication (HTML + Plain), or Form (JSON) */
  variant?: Maybe<TemplateVariantTranslation>;
};

export type TemplateResponse = {
  __typename?: 'TemplateResponse';
  /** Messages sent by the operation */
  messages: Array<ResponseMessage>;
  result?: Maybe<Template>;
  /** Indicate whether the operation was successful */
  success: Scalars['Boolean']['output'];
};

export enum TemplateState {
  Active = 'active',
  Archived = 'archived',
  Current = 'current'
}

export enum TemplateStateEvent {
  Activate = 'activate',
  Archive = 'archive',
  Unarchive = 'unarchive'
}

export enum TemplateType {
  Advice = 'advice',
  AppointmentAwaitingConfirmation = 'appointment_awaiting_confirmation',
  AppointmentCancelled = 'appointment_cancelled',
  AppointmentChanged = 'appointment_changed',
  AppointmentConfirmGroupGuest = 'appointment_confirm_group_guest',
  AppointmentConfirmGuest = 'appointment_confirm_guest',
  AppointmentConfirmation = 'appointment_confirmation',
  AppointmentCreated = 'appointment_created',
  AppointmentDeclined = 'appointment_declined',
  AppointmentGroupInvite = 'appointment_group_invite',
  AppointmentGuestRemoved = 'appointment_guest_removed',
  AppointmentInvite = 'appointment_invite',
  AppointmentOnhold = 'appointment_onhold',
  AppointmentOnlineLink = 'appointment_online_link',
  AppointmentPracticeAwaitingConfirmation = 'appointment_practice_awaiting_confirmation',
  AppointmentPracticeConfirmGuest = 'appointment_practice_confirm_guest',
  AppointmentReason = 'appointment_reason',
  AppointmentReminder = 'appointment_reminder',
  CustomerRequestDeposit = 'customer_request_deposit',
  CustomerRequestReview = 'customer_request_review',
  DepositFailed = 'deposit_failed',
  DepositPaid = 'deposit_paid',
  DocumentAdvice = 'document_advice',
  DocumentGeneric = 'document_generic',
  DocumentLetterGeneric = 'document_letter_generic',
  DocumentLetterReferral = 'document_letter_referral',
  DocumentMedical = 'document_medical',
  DocumentTreatment = 'document_treatment',
  Form = 'form',
  FormDelivery = 'form_delivery',
  General = 'general',
  Intake = 'intake',
  Invoice = 'invoice',
  InvoiceReminder = 'invoice_reminder',
  Marketing = 'marketing',
  OnlineMeetingInvite = 'online_meeting_invite',
  Recall = 'recall',
  Referral = 'referral',
  Survey = 'survey',
  Treatment = 'treatment',
  TreatmentTreatmentPlan = 'treatment_treatment_plan',
  Waiver = 'waiver'
}

export type TemplateTypeTranslation = {
  __typename?: 'TemplateTypeTranslation';
  deprecated?: Maybe<Scalars['Boolean']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  /** The Liquid Drop used to render this Template Type */
  drop?: Maybe<LiquidDrop>;
  /** What this template type is used for (e.g Forms, Appointments) */
  group?: Maybe<TemplateGroupTranslation>;
  id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  type: TemplateType;
};

/** Available kinds of templates */
export type TemplateUnion = CommunicationTemplate | DocumentTemplate | FormTemplate;

export type TemplateVariable = {
  __typename?: 'TemplateVariable';
  contentType?: Maybe<TemplateVariableContent>;
  description: Scalars['String']['output'];
  exampleValue?: Maybe<Scalars['String']['output']>;
  group?: Maybe<Scalars['String']['output']>;
  isList: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  path?: Maybe<Array<Scalars['String']['output']>>;
  type: Scalars['String']['output'];
};

export enum TemplateVariableContent {
  Html = 'html',
  Image = 'image',
  Liquid = 'liquid',
  Text = 'text',
  WordMl = 'word_ml'
}

export enum TemplateVariant {
  /** These kind of templates contain both plain-text and HTML content for delivering email and SMS */
  Communication = 'communication',
  /** These kind of templates only contain HTML content and are used for building PDF and Word-style documents */
  Document = 'document',
  /** Templates used for building dynamic forms, and delivering them by email or SMS */
  Form = 'form'
}

/**
 * Template Variants (previously content_type) define how a Template is
 * used:
 * - Document: HTML only
 * - Communication: HTML and SMS
 * - Form: HTML, SMS and JSON content
 */
export type TemplateVariantTranslation = {
  __typename?: 'TemplateVariantTranslation';
  description?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  slug: TemplateVariant;
};

export enum TextEditInstruction {
  DumbDown = 'dumb_down',
  FillOutTheRest = 'fill_out_the_rest',
  Rewrite = 'rewrite',
  SpellingAndGrammar = 'spelling_and_grammar'
}

export type Theme = {
  __typename?: 'Theme';
  id?: Maybe<Scalars['ID']['output']>;
  primaryColour?: Maybe<Scalars['String']['output']>;
  secondaryColour?: Maybe<Scalars['String']['output']>;
};

export type ThemeInput = {
  primaryColour?: InputMaybe<Scalars['String']['input']>;
  secondaryColour?: InputMaybe<Scalars['String']['input']>;
};

export type ThirdParty = {
  __typename?: 'ThirdParty';
  aggrid?: Maybe<AggridCredentials>;
  appSignal?: Maybe<AppSignalCredentials>;
  dynamsoft?: Maybe<DynamsoftCredentials>;
  fullCalendar?: Maybe<FullCalendarCredentials>;
  hicaps?: Maybe<HicapsCredentials>;
  intercom?: Maybe<IntercomCredentials>;
  stripe?: Maybe<StripeCredentials>;
  syncFusion?: Maybe<SyncFusionCredentials>;
  tyro?: Maybe<TyroCredentials>;
};

export type TimeRangeInput = {
  /** Timestamp (in milliseconds) for latest search result */
  end?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
  /** Timestamp (in milliseconds) for earliest search result */
  start?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
};

export enum Title {
  /** Dr */
  Dr = 'dr',
  /** Master */
  Master = 'master',
  /** Miss */
  Miss = 'miss',
  /** Mr */
  Mr = 'mr',
  /** Mrs */
  Mrs = 'mrs',
  /** Ms */
  Ms = 'ms',
  /** Mx */
  Mx = 'mx',
  /** Prof. */
  Professor = 'professor'
}

/** Autogenerated input type of TogglePatientAutoRecall */
export type TogglePatientAutoRecallInput = {
  /** The value to set auto_recalls to */
  autoRecalls: Scalars['Boolean']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The id of the UPC */
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of TogglePatientAutoRecall. */
export type TogglePatientAutoRecallPayload = {
  __typename?: 'TogglePatientAutoRecallPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  fieldErrors?: Maybe<Array<FieldError>>;
  success?: Maybe<Scalars['Boolean']['output']>;
  userPracticeConnection?: Maybe<UserPracticeLink>;
};

/** Autogenerated input type of ToggleReadMessages */
export type ToggleReadMessagesInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  lastMessageReadAt: Scalars['ISO8601DateTime']['input'];
  unreadMessagesCount: Scalars['Int']['input'];
};

/** Autogenerated return type of ToggleReadMessages. */
export type ToggleReadMessagesPayload = {
  __typename?: 'ToggleReadMessagesPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  fieldErrors?: Maybe<Array<FieldError>>;
  success?: Maybe<Scalars['Boolean']['output']>;
  userPracticeConnection?: Maybe<UserPracticeLink>;
};

/** Autogenerated input type of ToggleV2 */
export type ToggleV2Input = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of ToggleV2. */
export type ToggleV2Payload = {
  __typename?: 'ToggleV2Payload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** General errors, unrelated to fields */
  errors?: Maybe<Array<Scalars['String']['output']>>;
  /** Input field errors */
  fieldErrors?: Maybe<Array<FieldError>>;
  preference?: Maybe<PracticePreference>;
  /** Whether or not this mutation was successful */
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type TransactionInput = {
  /** The amount you want to charge/refund */
  amount: Scalars['Float']['input'];
  applySurcharge?: InputMaybe<Scalars['Boolean']['input']>;
  claims?: InputMaybe<Array<InvoiceTransactionClaimInput>>;
  /** Link this transaction to one or more invoice items. */
  invoiceItemIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** Additional transaction metadata */
  metadata?: InputMaybe<InvoiceTransactionMetadataInput>;
  /** Transaction note */
  note?: InputMaybe<Scalars['String']['input']>;
  paymentResponse?: InputMaybe<Scalars['JSON']['input']>;
  /** Select if note is private or not */
  privateNote?: InputMaybe<Scalars['Boolean']['input']>;
  /** Return note for refunds and product returns */
  returnNote?: InputMaybe<Scalars['String']['input']>;
  /** Manually set the state of the invoice transaction */
  state?: InputMaybe<InvoiceTransactionState>;
  surcharge?: InputMaybe<Scalars['Int']['input']>;
  /** The original transaction id, used for refunds */
  transactionId?: InputMaybe<Scalars['ID']['input']>;
  transactionMethod: Scalars['String']['input'];
  transactionType: TransactionType;
};

export enum TransactionMethod {
  /** Hicaps Cancellation */
  HicapsCancellation = 'hicaps_cancellation',
  /** Hicaps Claim */
  HicapsClaim = 'hicaps_claim',
  /** Hicaps Eftpos */
  HicapsEftpos = 'hicaps_eftpos',
  /** Hicaps Medicare Claim */
  HicapsMedicareClaim = 'hicaps_medicare_claim',
  /** Manual Account Credit */
  ManualAccountCredit = 'manual_account_credit',
  /** Manual Cash */
  ManualCash = 'manual_cash',
  /** Manual Cheque */
  ManualCheque = 'manual_cheque',
  /** Manual Other */
  ManualOther = 'manual_other',
  /** Manual Unallocated */
  ManualUnallocated = 'manual_unallocated',
  /** Medipass Cancellation */
  MedipassCancellation = 'medipass_cancellation',
  /** Medipass Claim */
  MedipassClaim = 'medipass_claim',
  /** Not Applicable */
  NotApplicable = 'not_applicable',
  /** Stripe Card */
  StripeCard = 'stripe_card',
  /** Tyro Eftpos */
  TyroEftpos = 'tyro_eftpos',
  /** Tyro Health Point Cancellation */
  TyroHealthPointCancellation = 'tyro_health_point_cancellation',
  /** Tyro Health Point Claim */
  TyroHealthPointClaim = 'tyro_health_point_claim',
  /** Tyro Medicare Claim */
  TyroMedicareClaim = 'tyro_medicare_claim'
}

export enum TransactionType {
  /** Account Credit Adjustment */
  AccountCreditAdjustment = 'account_credit_adjustment',
  /** Account Credit Deposit */
  AccountCreditDeposit = 'account_credit_deposit',
  /** Account Credit Withdrawal */
  AccountCreditWithdrawal = 'account_credit_withdrawal',
  /** Claim */
  Claim = 'claim',
  /** Discount */
  Discount = 'discount',
  /** Discount Reversal */
  DiscountReversal = 'discount_reversal',
  /** Invoice Item Removed */
  InvoiceItemRemoved = 'invoice_item_removed',
  /** Payment */
  Payment = 'payment',
  /** Refund */
  Refund = 'refund',
  /** Surcharge */
  Surcharge = 'surcharge'
}

/** Autogenerated input type of TransitionFormTemplateState */
export type TransitionFormTemplateStateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  event: TemplateStateEvent;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of TransitionFormTemplateState. */
export type TransitionFormTemplateStatePayload = {
  __typename?: 'TransitionFormTemplateStatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  fieldErrors?: Maybe<Array<FieldError>>;
  success?: Maybe<Scalars['Boolean']['output']>;
  template?: Maybe<FormTemplate>;
};

/** Autogenerated input type of TransitionLabWork */
export type TransitionLabWorkInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The event to transition the labwork */
  event: LabWorkStateEvent;
  /** The ID of the lab work to update */
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of TransitionLabWork. */
export type TransitionLabWorkPayload = {
  __typename?: 'TransitionLabWorkPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  fieldErrors?: Maybe<Array<FieldError>>;
  labWork?: Maybe<LabWork>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

/** Autogenerated input type of TransitionNoteState */
export type TransitionNoteStateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  event: NoteStateEvent;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of TransitionNoteState. */
export type TransitionNoteStatePayload = {
  __typename?: 'TransitionNoteStatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  fieldErrors?: Maybe<Array<FieldError>>;
  note?: Maybe<Note>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

/** Autogenerated input type of TransitionPatientAppState */
export type TransitionPatientAppStateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Event to transition state */
  event: PatientAppStateEvent;
  /** Patient App Config to change state of */
  patientApp: Scalars['ID']['input'];
};

/** Autogenerated return type of TransitionPatientAppState. */
export type TransitionPatientAppStatePayload = {
  __typename?: 'TransitionPatientAppStatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** General errors, unrelated to fields */
  errors?: Maybe<Array<Scalars['String']['output']>>;
  /** Input field errors */
  fieldErrors?: Maybe<Array<FieldError>>;
  patientAppConfig?: Maybe<PatientAppConfiguration>;
  /** Whether or not this mutation was successful */
  success?: Maybe<Scalars['Boolean']['output']>;
};

/** Autogenerated input type of TransitionPatientRecordItem */
export type TransitionPatientRecordItemInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  event: PatientRecordItemEvent;
  patientRecordItemId: Scalars['ID']['input'];
};

/** Autogenerated return type of TransitionPatientRecordItem. */
export type TransitionPatientRecordItemPayload = {
  __typename?: 'TransitionPatientRecordItemPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  fieldErrors?: Maybe<Array<FieldError>>;
  patientRecordItem?: Maybe<PatientRecordItem>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

/** Autogenerated input type of TransitionPriceListState */
export type TransitionPriceListStateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  event: PriceListStateEvent;
  guid: Scalars['ID']['input'];
};

/** Autogenerated return type of TransitionPriceListState. */
export type TransitionPriceListStatePayload = {
  __typename?: 'TransitionPriceListStatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  fieldErrors?: Maybe<Array<FieldError>>;
  priceList?: Maybe<PriceList>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

/** Autogenerated input type of TransitionRecallState */
export type TransitionRecallStateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  event: RecallStateEvent;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of TransitionRecallState. */
export type TransitionRecallStatePayload = {
  __typename?: 'TransitionRecallStatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  fieldErrors?: Maybe<Array<FieldError>>;
  recall?: Maybe<Recall>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

/** Autogenerated input type of TransitionReferral */
export type TransitionReferralInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The event to transition the Referral */
  event: ReferralStateEvent;
  /** The ID of the referral to update */
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of TransitionReferral. */
export type TransitionReferralPayload = {
  __typename?: 'TransitionReferralPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  fieldErrors?: Maybe<Array<FieldError>>;
  referral?: Maybe<Referral>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

/** Autogenerated input type of TransitionReferralSource */
export type TransitionReferralSourceInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The event to transition the ReferralSource */
  event: ReferralSourceStateEvent;
  /** The ID of the referral source to update */
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of TransitionReferralSource. */
export type TransitionReferralSourcePayload = {
  __typename?: 'TransitionReferralSourcePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  fieldErrors?: Maybe<Array<FieldError>>;
  referralSource?: Maybe<ReferralSource>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

/** Autogenerated input type of TransitionRoomState */
export type TransitionRoomStateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  event: RoomStateEvent;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of TransitionRoomState. */
export type TransitionRoomStatePayload = {
  __typename?: 'TransitionRoomStatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  fieldErrors?: Maybe<Array<FieldError>>;
  room?: Maybe<Room>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

/** Autogenerated input type of TransitionSnippetState */
export type TransitionSnippetStateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  event: SnippetStateEvent;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of TransitionSnippetState. */
export type TransitionSnippetStatePayload = {
  __typename?: 'TransitionSnippetStatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  fieldErrors?: Maybe<Array<FieldError>>;
  snippet?: Maybe<Snippet>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

/** Autogenerated input type of TransitionStateCategory */
export type TransitionStateCategoryInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  event: CategoryStateEvent;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of TransitionStateCategory. */
export type TransitionStateCategoryPayload = {
  __typename?: 'TransitionStateCategoryPayload';
  category?: Maybe<Category>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  fieldErrors?: Maybe<Array<FieldError>>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

/** Autogenerated input type of TransitionState */
export type TransitionStateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  event: UserPracticeConnectionStateEvent;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of TransitionState. */
export type TransitionStatePayload = {
  __typename?: 'TransitionStatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  fieldErrors?: Maybe<Array<FieldError>>;
  success?: Maybe<Scalars['Boolean']['output']>;
  userPracticeConnection?: Maybe<UserPracticeLink>;
};

/** Autogenerated input type of TransitionStateShift */
export type TransitionStateShiftInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  event: ShiftTypeStateEvent;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of TransitionStateShift. */
export type TransitionStateShiftPayload = {
  __typename?: 'TransitionStateShiftPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  fieldErrors?: Maybe<Array<FieldError>>;
  shiftType?: Maybe<RosterShiftType>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

/** Autogenerated input type of TransitionTaskState */
export type TransitionTaskStateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  event: TaskStateEvent;
  ids: Array<Scalars['ID']['input']>;
};

/** Autogenerated return type of TransitionTaskState. */
export type TransitionTaskStatePayload = {
  __typename?: 'TransitionTaskStatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  fieldErrors?: Maybe<Array<FieldError>>;
  success?: Maybe<Scalars['Boolean']['output']>;
  tasks?: Maybe<Array<Task>>;
};

/** Autogenerated input type of TransitionTemplateState */
export type TransitionTemplateStateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  event: TemplateStateEvent;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of TransitionTemplateState. */
export type TransitionTemplateStatePayload = {
  __typename?: 'TransitionTemplateStatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  fieldErrors?: Maybe<Array<FieldError>>;
  success?: Maybe<Scalars['Boolean']['output']>;
  template?: Maybe<TemplateUnion>;
};

/** Autogenerated input type of TransitionTreatmentPlanState */
export type TransitionTreatmentPlanStateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  event: TreatmentPlanStateEvent;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of TransitionTreatmentPlanState. */
export type TransitionTreatmentPlanStatePayload = {
  __typename?: 'TransitionTreatmentPlanStatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  fieldErrors?: Maybe<Array<FieldError>>;
  success?: Maybe<Scalars['Boolean']['output']>;
  treatmentPlan?: Maybe<TreatmentPlan>;
};

/** Autogenerated input type of TransitionTreatmentState */
export type TransitionTreatmentStateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  event: TreatmentStateEvent;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of TransitionTreatmentState. */
export type TransitionTreatmentStatePayload = {
  __typename?: 'TransitionTreatmentStatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  fieldErrors?: Maybe<Array<FieldError>>;
  success?: Maybe<Scalars['Boolean']['output']>;
  treatment?: Maybe<Treatment>;
};

/** Autogenerated input type of TransitionWorkflowState */
export type TransitionWorkflowStateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  event: WorkflowStateEvent;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of TransitionWorkflowState. */
export type TransitionWorkflowStatePayload = {
  __typename?: 'TransitionWorkflowStatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  fieldErrors?: Maybe<Array<FieldError>>;
  success?: Maybe<Scalars['Boolean']['output']>;
  workflow?: Maybe<Workflow>;
};

export type Treatment = AnyNode & {
  __typename?: 'Treatment';
  appointments?: Maybe<Array<Appointment>>;
  chartImage?: Maybe<DentalChartUploaderAwsLinks>;
  createdAt: Scalars['ISO8601DateTime']['output'];
  dateStarted?: Maybe<Scalars['ISO8601Date']['output']>;
  /** Default pricelist for the treatment, if not set, the default pricelist for the practice is used */
  defaultPriceList?: Maybe<PriceList>;
  /** Globally unique identifier */
  guid: Scalars['ID']['output'];
  /** Account-wide unique identifier */
  id: Scalars['ID']['output'];
  /** The Patient linked to the treatment */
  patient?: Maybe<UserPracticeLink>;
  /** UPC Id of the patient */
  patientId?: Maybe<Scalars['ID']['output']>;
  patientRecord?: Maybe<PatientRecord>;
  patientRecordId?: Maybe<Scalars['ID']['output']>;
  policies?: Maybe<TreatmentPolicy>;
  /** The practitioner that is assigned to this examination */
  practitioner?: Maybe<UserPracticeLink>;
  /** UPC Id of the practitioner assigned to the treatment plan */
  practitionerId?: Maybe<Scalars['ID']['output']>;
  primaryPractitioner?: Maybe<Professional>;
  referral?: Maybe<Referral>;
  referralId?: Maybe<Scalars['ID']['output']>;
  slug?: Maybe<Scalars['String']['output']>;
  state?: Maybe<TreatmentState>;
  title?: Maybe<Scalars['String']['output']>;
  treatmentPlans?: Maybe<Array<TreatmentPlan>>;
  updatedAt: Scalars['ISO8601DateTime']['output'];
  /**
   * The Patient linked to the treatment
   * @deprecated Deprecated in favour of `patient`
   */
  userPracticeConnection: UserPracticeLink;
  userPracticeConnectionId?: Maybe<Scalars['ID']['output']>;
  /** Unique identifier of the message */
  uuid: Scalars['String']['output'];
};

/** The connection type for Treatment. */
export type TreatmentConnection = {
  __typename?: 'TreatmentConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<TreatmentEdge>>>;
  /** IDs of ALL results, regardless of pagination */
  ids: Array<Scalars['ID']['output']>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Treatment>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Returns total count of items in list */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type TreatmentEdge = {
  __typename?: 'TreatmentEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<Treatment>;
};

export type TreatmentInput = {
  dateStarted?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
  /** Assign an appointment to this treatment plan */
  firstAppointment?: InputMaybe<Scalars['ID']['input']>;
  /** Assign this treatment to this patient (ID) */
  patient?: InputMaybe<Scalars['ID']['input']>;
  /** Assign this practitioner to the treatment (ID) */
  practitioner?: InputMaybe<Scalars['ID']['input']>;
  /** Assign a referral where the patient is the referent to this treatment plan */
  referral?: InputMaybe<Scalars['ID']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  userPracticeConnectionId?: InputMaybe<Scalars['ID']['input']>;
};

export type TreatmentPlan = AnyNode & {
  __typename?: 'TreatmentPlan';
  /** The date and time when the treatment plan was accepted */
  acceptedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  /** Appointments for this treatment plan */
  appointments?: Maybe<Array<Appointment>>;
  /** Catalogue items linked to this treatment plan */
  catalogueItems?: Maybe<Array<CatalogueItem>>;
  /** The chart image */
  chartImage?: Maybe<DentalChartUploaderAwsLinks>;
  /** The colour of the treatment plan */
  colour?: Maybe<Scalars['String']['output']>;
  /** The cost of completed treatments */
  completedCost?: Maybe<Scalars['Float']['output']>;
  /** The number of items that have been completed */
  completedItemsCount: Scalars['Int']['output'];
  /** The percentage of the treatment plan that is complete */
  completionPercentage: Scalars['Float']['output'];
  /** Date the treatment plan was created */
  createdAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  /** Date the treatment plan was started */
  dateStarted?: Maybe<Scalars['ISO8601Date']['output']>;
  /** Date the treatment plan was discarded */
  discardedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  /** Treatment Plan Unique GUID */
  guid: Scalars['ID']['output'];
  /** Treatment Plan ID */
  id: Scalars['ID']['output'];
  /** The PDF attachment of the stored treatment plan pdf (by lexical) */
  lexicalPdf?: Maybe<Attachment>;
  /** The name of the treatment plan */
  name?: Maybe<Scalars['String']['output']>;
  /** Notes for this treatment plan */
  notes?: Maybe<Array<Note>>;
  /** The Patient linked to the treatment */
  patient?: Maybe<UserPracticeLink>;
  /** Patient record items linked to this treatment plan */
  patientRecordItems?: Maybe<Array<PatientRecordItem>>;
  /** The PDF attachment of the stored treatment plan pdf */
  pdf?: Maybe<Attachment>;
  /** Timestamp when the chart image was last updated */
  pdfChartGeneratedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  /** Include the dental chart in the treatment plan pdf */
  pdfIncludeChart?: Maybe<Scalars['Boolean']['output']>;
  /** Include notes in the treatment plan pdf */
  pdfIncludeNotes?: Maybe<Scalars['Boolean']['output']>;
  /** Include the perio chart in the treatment plan pdf */
  pdfIncludePerioChart?: Maybe<Scalars['Boolean']['output']>;
  /** Only show items marked as incomplete in the treatment plan pdf */
  pdfShowIncompleteOnly?: Maybe<Scalars['Boolean']['output']>;
  /** Show unassigned items in the treatment plan pdf */
  pdfShowUnassigned?: Maybe<Scalars['Boolean']['output']>;
  /** Include a summary in the treatment plan pdf */
  pdfSummary?: Maybe<Scalars['String']['output']>;
  /** The policies for this treatment plan */
  policies?: Maybe<TreatmentPlanPolicy>;
  /** The practitioner that is assigned to this examination */
  practitioner?: Maybe<UserPracticeLink>;
  /** The cost of the treatment plan, including any discounts */
  proposedCost?: Maybe<Scalars['Float']['output']>;
  /** The number of proposed items */
  proposedItemsCount: Scalars['Int']['output'];
  /** The date and time the treatment plan was rejected */
  rejectedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  requiredVisits: Scalars['Int']['output'];
  signature?: Maybe<Scalars['String']['output']>;
  /** Treatment Plan Slug */
  slug?: Maybe<Scalars['String']['output']>;
  /** The current state of the treatment plan */
  state: TreatmentPlanState;
  /** Total cost of the treatment plan */
  totalCost?: Maybe<Scalars['Float']['output']>;
  /** Total number of items in the treatment plan */
  totalCount: Scalars['Int']['output'];
  /** The treatment this plan is for */
  treatment?: Maybe<Treatment>;
  /** The ID of the linked treatment */
  treatmentId?: Maybe<Scalars['ID']['output']>;
  /** The date and time the treatment plan was last updated */
  updatedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  /** Unique identifier of the message */
  uuid: Scalars['String']['output'];
  /** Whether this record is available to staff, or also to patients */
  visibility?: Maybe<VisibilityDefinition>;
};

/** The connection type for TreatmentPlan. */
export type TreatmentPlanConnection = {
  __typename?: 'TreatmentPlanConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<TreatmentPlanEdge>>>;
  /** IDs of ALL results, regardless of pagination */
  ids: Array<Scalars['ID']['output']>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<TreatmentPlan>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Returns total count of items in list */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type TreatmentPlanEdge = {
  __typename?: 'TreatmentPlanEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<TreatmentPlan>;
};

export type TreatmentPlanInput = {
  /** Base64 chart image */
  chartImage?: InputMaybe<Scalars['String']['input']>;
  /** If true, will generate the PDF and resave against the treamtment plan */
  generatePdf?: InputMaybe<Scalars['Boolean']['input']>;
  /** Treatment Plan PDF: Include chart image */
  pdfIncludeChart?: InputMaybe<Scalars['Boolean']['input']>;
  /** Treatment Plan PDF: Include planner notes */
  pdfIncludeNotes?: InputMaybe<Scalars['Boolean']['input']>;
  /** Treatment Plan PDF: Include latest perio exam chart */
  pdfIncludePerioChart?: InputMaybe<Scalars['Boolean']['input']>;
  /** Treatment Plan PDF: Show incomplete items only */
  pdfShowIncompleteOnly?: InputMaybe<Scalars['Boolean']['input']>;
  /** Treatment Plan PDF: Show unassigned items not linked to appointments as well */
  pdfShowUnassigned?: InputMaybe<Scalars['Boolean']['input']>;
  /** Treatment Plan PDF: Footer summary */
  pdfSummary?: InputMaybe<Scalars['String']['input']>;
  /** Signature to attach to treatment plan */
  signature?: InputMaybe<Scalars['String']['input']>;
  /** Defines who can access this record */
  visibility?: InputMaybe<Visibility>;
};

export enum TreatmentPlanOrder {
  CreatedAt = 'created_at',
  DateStarted = 'date_started',
  Id = 'id',
  UpdatedAt = 'updated_at'
}

export type TreatmentPlanOrderInput = {
  direction?: InputMaybe<Direction>;
  field?: InputMaybe<TreatmentPlanOrder>;
};

export type TreatmentPlanPolicy = {
  __typename?: 'TreatmentPlanPolicy';
  accept?: Maybe<Scalars['Boolean']['output']>;
  create?: Maybe<Scalars['Boolean']['output']>;
  destroy?: Maybe<Scalars['Boolean']['output']>;
  edit?: Maybe<Scalars['Boolean']['output']>;
  index?: Maybe<Scalars['Boolean']['output']>;
  new?: Maybe<Scalars['Boolean']['output']>;
  reject?: Maybe<Scalars['Boolean']['output']>;
  show?: Maybe<Scalars['Boolean']['output']>;
  update?: Maybe<Scalars['Boolean']['output']>;
};

export type TreatmentPlanSendEmailInput = {
  /** Base64 String attachment */
  base64Attachment?: InputMaybe<Scalars['String']['input']>;
  /** Flag if email should be sent */
  sendEmail?: InputMaybe<Scalars['Boolean']['input']>;
  /** Flag if SMS should be sent */
  sendSms?: InputMaybe<Scalars['Boolean']['input']>;
  /** ID of chosen template */
  templateId: Scalars['ID']['input'];
};

export enum TreatmentPlanState {
  /** Accepted */
  Accepted = 'accepted',
  /** Archived */
  Archived = 'archived',
  /** Pending */
  Pending = 'pending',
  /** Rejected */
  Rejected = 'rejected'
}

export enum TreatmentPlanStateEvent {
  Accept = 'accept',
  Reject = 'reject'
}

export type TreatmentPolicy = {
  __typename?: 'TreatmentPolicy';
  archive?: Maybe<Scalars['Boolean']['output']>;
  create?: Maybe<Scalars['Boolean']['output']>;
  destroy?: Maybe<Scalars['Boolean']['output']>;
  edit?: Maybe<Scalars['Boolean']['output']>;
  index?: Maybe<Scalars['Boolean']['output']>;
  new?: Maybe<Scalars['Boolean']['output']>;
  show?: Maybe<Scalars['Boolean']['output']>;
  update?: Maybe<Scalars['Boolean']['output']>;
};

export enum TreatmentState {
  Archived = 'archived',
  Completed = 'completed',
  InProgress = 'in_progress'
}

export enum TreatmentStateEvent {
  Archive = 'archive',
  Complete = 'complete',
  Uncomplete = 'uncomplete'
}

export type TreatmentsPreset = {
  __typename?: 'TreatmentsPreset';
  /** Determines if then preset is currently in use or not */
  active: Scalars['Boolean']['output'];
  /** The list catalogue items linked to this preset through preset items */
  catalgoueItems?: Maybe<Array<CatalogueItem>>;
  /** The codes of the catalogue items in this preset */
  catalogueItemCodes?: Maybe<Array<Scalars['String']['output']>>;
  /** The date and time this preset was created */
  createdAt: Scalars['ISO8601DateTime']['output'];
  /** The date and time this preset was discarded */
  discardedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  /** The id of the treatment preset */
  id: Scalars['ID']['output'];
  /** The name of the preset */
  name: Scalars['String']['output'];
  /** The practice which this preset resides in */
  practice: Practice;
  /** The list of preset items linked to this preset */
  presetItems: Array<TreatmentsPresetItem>;
  /** Determines if the preset requires a tooth to be selected */
  requiresTooth: Scalars['Boolean']['output'];
  /** Determines if the preset visible to others in the practice */
  shared: Scalars['Boolean']['output'];
  /** The date and time this preset was updated */
  updatedAt: Scalars['ISO8601DateTime']['output'];
  /** The owner of this preset */
  userPracticeConnection: UserPracticeLink;
  /** The ID of the owner */
  userPracticeConnectionId: Scalars['ID']['output'];
};

export type TreatmentsPresetAddToPlanInput = {
  /** ID of the practitioner */
  practitionerId?: InputMaybe<Scalars['ID']['input']>;
  /** ID of selected price list */
  priceListId?: InputMaybe<Scalars['ID']['input']>;
  /** ID of the selected tooth */
  toothId?: InputMaybe<Scalars['ID']['input']>;
  /** ID of the treatment plan */
  treatmentPlanId: Scalars['ID']['input'];
  /** ID of the treatment preset */
  treatmentsPresetId: Scalars['ID']['input'];
};

/** The connection type for TreatmentsPreset. */
export type TreatmentsPresetConnection = {
  __typename?: 'TreatmentsPresetConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<TreatmentsPresetEdge>>>;
  /** IDs of ALL results, regardless of pagination */
  ids: Array<Scalars['ID']['output']>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<TreatmentsPreset>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Returns total count of items in list */
  totalCount: Scalars['Int']['output'];
};

export type TreatmentsPresetCreateInput = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  name: Scalars['String']['input'];
  shared?: InputMaybe<Scalars['Boolean']['input']>;
};

/** An edge in a connection. */
export type TreatmentsPresetEdge = {
  __typename?: 'TreatmentsPresetEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<TreatmentsPreset>;
};

export type TreatmentsPresetItem = {
  __typename?: 'TreatmentsPresetItem';
  /** Which appointment to assign this item to */
  appointmentNumber?: Maybe<Scalars['Int']['output']>;
  /** The catalogue item linked to this preset */
  catalogueItem: CatalogueItem;
  /** The ID of the linked catalogue item */
  catalogueItemId: Scalars['ID']['output'];
  /** Set the number of times we add this items to the treatment */
  count?: Maybe<Scalars['Int']['output']>;
  /** The date and time this item was created */
  createdAt: Scalars['ISO8601DateTime']['output'];
  /** The date and time this item was discarded */
  discardedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  /** The id of the treatment preset item */
  id: Scalars['ID']['output'];
  /** The preset this item is linked to */
  preset: TreatmentsPreset;
  /** Set the item quantity */
  quantity?: Maybe<Scalars['Int']['output']>;
  /** The ID of the preset */
  treatmentsPresetId: Scalars['ID']['output'];
  /** The date and time this item was updated */
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

/** The connection type for TreatmentsPresetItem. */
export type TreatmentsPresetItemConnection = {
  __typename?: 'TreatmentsPresetItemConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<TreatmentsPresetItemEdge>>>;
  /** IDs of ALL results, regardless of pagination */
  ids: Array<Scalars['ID']['output']>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<TreatmentsPresetItem>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Returns total count of items in list */
  totalCount: Scalars['Int']['output'];
};

export type TreatmentsPresetItemCreateInput = {
  appointmentNumber?: InputMaybe<Scalars['Int']['input']>;
  catalogueItemId: Scalars['ID']['input'];
  count?: InputMaybe<Scalars['Int']['input']>;
  quantity?: InputMaybe<Scalars['Int']['input']>;
  treatmentsPresetId: Scalars['ID']['input'];
};

/** An edge in a connection. */
export type TreatmentsPresetItemEdge = {
  __typename?: 'TreatmentsPresetItemEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<TreatmentsPresetItem>;
};

export type TreatmentsPresetItemUpdateInput = {
  appointmentNumber?: InputMaybe<Scalars['Int']['input']>;
  count?: InputMaybe<Scalars['Int']['input']>;
  quantity?: InputMaybe<Scalars['Int']['input']>;
};

export type TreatmentsPresetUpdateInput = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  shared?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Autogenerated input type of TriggerAutoRecall */
export type TriggerAutoRecallInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Filter by recall_due_time end */
  endDate: Scalars['ISO8601Date']['input'];
  /** The id of the practice */
  id: Scalars['ID']['input'];
  /** Filter by recall_due_time start */
  startDate: Scalars['ISO8601Date']['input'];
};

/** Autogenerated return type of TriggerAutoRecall. */
export type TriggerAutoRecallPayload = {
  __typename?: 'TriggerAutoRecallPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  fieldErrors?: Maybe<Array<FieldError>>;
  practice?: Maybe<Practice>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type TyroCredentials = {
  __typename?: 'TyroCredentials';
  /** Tyro API key used for the Tyro payment processing */
  apiKey?: Maybe<Scalars['String']['output']>;
};

export type TyroPaymentProviderInput = {
  /** Enable/Disable payment provider */
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  /** Tyro Environment */
  environment?: InputMaybe<PaymentProviderTyroEnvironment>;
};

export type TyroPaymentTerminalInput = {
  /** Array of additional merchant ids */
  additionalMerchantIds?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Status returned by the pairing process */
  feedback?: InputMaybe<Scalars['String']['input']>;
  /** Integration Key returned by the pairing process */
  integrationKey?: InputMaybe<Scalars['String']['input']>;
  /** Default Merchant Id */
  merchantId?: InputMaybe<Scalars['String']['input']>;
  /** Terminal Name */
  name?: InputMaybe<Scalars['String']['input']>;
  /** Practice Location to link */
  practiceLocation?: InputMaybe<Scalars['ID']['input']>;
  /** Unique ID of the terminal */
  terminalId?: InputMaybe<Scalars['String']['input']>;
};

/** Any node type */
export type UuidNode = Appointment | AppointmentGuest | Attachment | CatalogueItem | CommunicationTemplate | DentalTooth | Form | Hidden | Invoice | Practice | Recall | Referral | Reminder | ReviewResponse | Treatment | TreatmentPlan | UserPracticeLink;

/** Autogenerated input type of UnassignAlert */
export type UnassignAlertInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of UnassignAlert. */
export type UnassignAlertPayload = {
  __typename?: 'UnassignAlertPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  connectionAlert?: Maybe<UserPracticeConnectionAlert>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  fieldErrors?: Maybe<Array<FieldError>>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

/** Autogenerated input type of UndiscardTreatmentPlan */
export type UndiscardTreatmentPlanInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** ID of the treatment plan */
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of UndiscardTreatmentPlan. */
export type UndiscardTreatmentPlanPayload = {
  __typename?: 'UndiscardTreatmentPlanPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  fieldErrors?: Maybe<Array<FieldError>>;
  success?: Maybe<Scalars['Boolean']['output']>;
  treatmentPlan?: Maybe<TreatmentPlan>;
};

export enum UpcField {
  /** Order by how much credit the user has in the account */
  AccountCredit = 'account_credit',
  /** Order by creation time */
  CreatedAt = 'created_at',
  /** Order by ID (default) */
  Id = 'id',
  /** Order by last_message_at time */
  LastMessageAt = 'last_message_at',
  /** Order by name */
  Name = 'name',
  /** Order by unpaid balance */
  OutstandingBalanceCount = 'outstanding_balance_count',
  /** Order by primary practitioner */
  PrimaryPractitioner = 'primary_practitioner',
  /** Order by role */
  Role = 'role',
  /** Order by current status */
  State = 'state'
}

export type UpcSearchInput = {
  /** Only users with scheduled appointment in this time range */
  appointmentTime?: InputMaybe<TimeRangeInput>;
  /** Only users born between these dates */
  bornBetween?: InputMaybe<TimeRangeInput>;
  /** Only get those that are enabled on calendar */
  calendarEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  /** Only users that with created_at in this range */
  createdAtTime?: InputMaybe<TimeRangeInput>;
  /** Get UPCs without IDs */
  excludeIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** Filter by role slug */
  excludeRole?: InputMaybe<Array<PracticeRole>>;
  /** Filter by first appointment start time (any state) */
  firstAppointmentDate?: InputMaybe<TimeRangeInput>;
  /** Filter by first COMPLETED appointment start time */
  firstVisitDate?: InputMaybe<TimeRangeInput>;
  /** Get UPCs by GUID */
  guids?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** Only get those with/without appointments */
  hasAppointment?: InputMaybe<Scalars['Boolean']['input']>;
  /** Only get those with online appointments that are pending */
  hasOnlineAppointment?: InputMaybe<Scalars['Boolean']['input']>;
  /** Only get those with/without recalls */
  hasRecall?: InputMaybe<Scalars['Boolean']['input']>;
  /** Get UPCs by ID */
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** Filter by latest appointment start time (any state) */
  lastAppointmentDate?: InputMaybe<TimeRangeInput>;
  /** Filter by invoice date of last exam PatientRecordItem */
  lastExamDate?: InputMaybe<TimeRangeInput>;
  /** Filter by last medical form completion date */
  lastMedicalFormDate?: InputMaybe<TimeRangeInput>;
  /** Filter by the last message sent/recieved for this UPC */
  lastMessageAt?: InputMaybe<TimeRangeInput>;
  /** Filter by latest COMPLETED appointment start time */
  lastVisitDate?: InputMaybe<TimeRangeInput>;
  /** Filter by invoice date of last xray PatientRecordItem */
  lastXrayDate?: InputMaybe<TimeRangeInput>;
  /** Excluded patients with missed appointments greater than this value */
  missedAppointments?: InputMaybe<Scalars['Int']['input']>;
  /** Filter by next appointment start time */
  nextAppointmentDate?: InputMaybe<TimeRangeInput>;
  /** Sort query */
  orderBy?: InputMaybe<UserPracticeLinkOrderInput>;
  /** Filter by patient name */
  patientName?: InputMaybe<Scalars['String']['input']>;
  /** Filter by patient phone number */
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  /** Get UPCs by practice Ids */
  practiceIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** Get UPCs by practice location Ids */
  practiceLocationIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** Filter by users price list */
  priceListId?: InputMaybe<Scalars['ID']['input']>;
  primaryPractitionerId?: InputMaybe<Array<Scalars['ID']['input']>>;
  /**
   * Sort users with these primary practitioners first in the result. This is
   * useful when viewing a list of patient for a practitioner
   */
  prioritizePrimaryPractitioner?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** Only users that with recall_due_time in this range */
  recallDueTime?: InputMaybe<TimeRangeInput>;
  /** Fetch Patients based on if they have a recall due, prepared, completed or dropped off */
  recallFilter?: InputMaybe<Scalars['String']['input']>;
  /** Only users with scheduled recalls in this time range */
  recallTime?: InputMaybe<TimeRangeInput>;
  /** Filter by role slug */
  role?: InputMaybe<PracticeRole>;
  /** Filter by multiple role slugs */
  roles?: InputMaybe<Array<PracticeRole>>;
  /** Free-text search through UserPracticeProfiles */
  search?: InputMaybe<Scalars['String']['input']>;
  secondaryPractitionerId?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** Excluded patients with missed appointments greater than this value */
  skipActiveRecall?: InputMaybe<Scalars['Boolean']['input']>;
  /** Excluded patients with missed appointments greater than this value */
  skipFutureVisit?: InputMaybe<Scalars['Boolean']['input']>;
  /** Excluded patients with skip_drop_off_time set */
  skipWithDropOff?: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by state */
  state?: InputMaybe<Array<UserPracticeConnectionState>>;
  treatmentPlanState?: InputMaybe<Array<TreatmentPlanState>>;
  unconfirmed?: InputMaybe<Scalars['Boolean']['input']>;
  /** Only get UPCs with SMS messages */
  upcWithSms?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Autogenerated input type of UpdateAccount */
export type UpdateAccountInput = {
  /** The Account to update */
  account: Scalars['ID']['input'];
  attributes: AccountUpdateInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of UpdateAccount. */
export type UpdateAccountPayload = {
  __typename?: 'UpdateAccountPayload';
  account?: Maybe<Account>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  fieldErrors?: Maybe<Array<FieldError>>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

/** Autogenerated input type of UpdateActiveProfile */
export type UpdateActiveProfileInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Profile you want to set as the active one on a UPC */
  profile: Scalars['ID']['input'];
  /** UPC is required now because we can no longer reliably guess the UPC from user/account/practice */
  upc: Scalars['ID']['input'];
};

/** Autogenerated return type of UpdateActiveProfile. */
export type UpdateActiveProfilePayload = {
  __typename?: 'UpdateActiveProfilePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  fieldErrors?: Maybe<Array<FieldError>>;
  success?: Maybe<Scalars['Boolean']['output']>;
  userPracticeConnection?: Maybe<UserPracticeLink>;
};

/** Autogenerated input type of UpdateAlert */
export type UpdateAlertInput = {
  attributes: AlertUpdateInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of UpdateAlert. */
export type UpdateAlertPayload = {
  __typename?: 'UpdateAlertPayload';
  alert?: Maybe<Alert>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  fieldErrors?: Maybe<Array<FieldError>>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

/** Autogenerated input type of UpdateAppointment */
export type UpdateAppointmentInput = {
  attributes: AppointmentInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of UpdateAppointment. */
export type UpdateAppointmentPayload = {
  __typename?: 'UpdateAppointmentPayload';
  appointment?: Maybe<Appointment>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  fieldErrors?: Maybe<Array<FieldError>>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

/** Autogenerated input type of UpdateAppointmentReason */
export type UpdateAppointmentReasonInput = {
  /** The attributes to update */
  attributes: AppointmentReasonInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Appointment Reason to update */
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of UpdateAppointmentReason. */
export type UpdateAppointmentReasonPayload = {
  __typename?: 'UpdateAppointmentReasonPayload';
  appointmentReason?: Maybe<AppointmentReason>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  fieldErrors?: Maybe<Array<FieldError>>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

/** Autogenerated input type of UpdateAttachment */
export type UpdateAttachmentInput = {
  attributes: AttachmentInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of UpdateAttachment. */
export type UpdateAttachmentPayload = {
  __typename?: 'UpdateAttachmentPayload';
  attachment?: Maybe<Attachment>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  fieldErrors?: Maybe<Array<FieldError>>;
  /** Previous parent directory if it changed */
  previousDirectory?: Maybe<Directory>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

/** Autogenerated input type of UpdateAvatar */
export type UpdateAvatarInput = {
  /** New user avatar */
  avatar?: InputMaybe<Scalars['Upload']['input']>;
  /** Base64 encoded image to use as avatar instead of multipart */
  base64?: InputMaybe<Scalars['String']['input']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** UPC (guid) to update */
  upc: Scalars['ID']['input'];
};

/** Autogenerated return type of UpdateAvatar. */
export type UpdateAvatarPayload = {
  __typename?: 'UpdateAvatarPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** General errors, unrelated to fields */
  errors?: Maybe<Array<Scalars['String']['output']>>;
  /** Input field errors */
  fieldErrors?: Maybe<Array<FieldError>>;
  /** Whether or not this mutation was successful */
  success?: Maybe<Scalars['Boolean']['output']>;
  upc?: Maybe<UserPracticeLink>;
};

/** Autogenerated input type of UpdateBadgesTemplate */
export type UpdateBadgesTemplateInput = {
  /** The attributes to create a new cycle */
  attributes: BadgesTemplateInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The ID of the template to update */
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of UpdateBadgesTemplate. */
export type UpdateBadgesTemplatePayload = {
  __typename?: 'UpdateBadgesTemplatePayload';
  badgesTemplate?: Maybe<BadgesTemplate>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  fieldErrors?: Maybe<Array<FieldError>>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

/** Autogenerated input type of UpdateBillingAddress */
export type UpdateBillingAddressInput = {
  attributes: LocationInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of UpdateBillingAddress. */
export type UpdateBillingAddressPayload = {
  __typename?: 'UpdateBillingAddressPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  fieldErrors?: Maybe<Array<FieldError>>;
  location?: Maybe<Location>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

/** Autogenerated input type of UpdateCancellationReason */
export type UpdateCancellationReasonInput = {
  /** The attributes to update */
  attributes: CancellationReasonInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Cancellation Reason to update */
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of UpdateCancellationReason. */
export type UpdateCancellationReasonPayload = {
  __typename?: 'UpdateCancellationReasonPayload';
  cancellationReason?: Maybe<CancellationReason>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  fieldErrors?: Maybe<Array<FieldError>>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

/** Autogenerated input type of UpdateCatalogueItem */
export type UpdateCatalogueItemInput = {
  attributes: CatalogueItemInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of UpdateCatalogueItem. */
export type UpdateCatalogueItemPayload = {
  __typename?: 'UpdateCatalogueItemPayload';
  catalogueItem?: Maybe<CatalogueItem>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  fieldErrors?: Maybe<Array<FieldError>>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

/** Autogenerated input type of UpdateCategory */
export type UpdateCategoryInput = {
  attributes: CategoryUpdateInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of UpdateCategory. */
export type UpdateCategoryPayload = {
  __typename?: 'UpdateCategoryPayload';
  category?: Maybe<Category>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  fieldErrors?: Maybe<Array<FieldError>>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

/** Autogenerated input type of UpdateCommunications */
export type UpdateCommunicationsInput = {
  attributes: AppointmentCommunicationInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of UpdateCommunications. */
export type UpdateCommunicationsPayload = {
  __typename?: 'UpdateCommunicationsPayload';
  appointment?: Maybe<Appointment>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  fieldErrors?: Maybe<Array<FieldError>>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

/** Autogenerated input type of UpdateConnectionAlert */
export type UpdateConnectionAlertInput = {
  attributes: ConnectionAlertUpdateInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of UpdateConnectionAlert. */
export type UpdateConnectionAlertPayload = {
  __typename?: 'UpdateConnectionAlertPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  connectionAlert?: Maybe<UserPracticeConnectionAlert>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  fieldErrors?: Maybe<Array<FieldError>>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

/** Autogenerated input type of UpdateCustomerRequest */
export type UpdateCustomerRequestInput = {
  attributes: CustomerRequestUpdateInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Customer Request ID to update */
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of UpdateCustomerRequest. */
export type UpdateCustomerRequestPayload = {
  __typename?: 'UpdateCustomerRequestPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  customerRequest?: Maybe<CustomerRequest>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  fieldErrors?: Maybe<Array<FieldError>>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

/** Autogenerated input type of UpdateDirectory */
export type UpdateDirectoryInput = {
  attributes: DirectoryInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** ID of the Directory to delete */
  directory: Scalars['ID']['input'];
};

/** Autogenerated return type of UpdateDirectory. */
export type UpdateDirectoryPayload = {
  __typename?: 'UpdateDirectoryPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  directory?: Maybe<Directory>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  fieldErrors?: Maybe<Array<FieldError>>;
  /** The previous parent directory of the updated directory */
  previousParent?: Maybe<Directory>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

/** Autogenerated input type of UpdateFamily */
export type UpdateFamilyInput = {
  /** The attributes to update a family */
  attributes: FamilyUpdateInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The ID of the family to update */
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of UpdateFamily. */
export type UpdateFamilyPayload = {
  __typename?: 'UpdateFamilyPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  family?: Maybe<Family>;
  fieldErrors?: Maybe<Array<FieldError>>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

/** Autogenerated input type of UpdateField */
export type UpdateFieldInput = {
  /** Attributes for the field */
  attributes: FormFieldInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
};

/** Autogenerated return type of UpdateField. */
export type UpdateFieldPayload = {
  __typename?: 'UpdateFieldPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** General errors, unrelated to fields */
  errors?: Maybe<Array<Scalars['String']['output']>>;
  field?: Maybe<FormField>;
  /** Input field errors */
  fieldErrors?: Maybe<Array<FieldError>>;
  /** Whether or not this mutation was successful */
  success?: Maybe<Scalars['Boolean']['output']>;
};

/** Autogenerated input type of UpdateForm */
export type UpdateFormInput = {
  attributes: FormUpdateInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of UpdateForm. */
export type UpdateFormPayload = {
  __typename?: 'UpdateFormPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  fieldErrors?: Maybe<Array<FieldError>>;
  form?: Maybe<Form>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

/** Autogenerated input type of UpdateFormTemplate */
export type UpdateFormTemplateInput = {
  attributes: FormTemplateInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of UpdateFormTemplate. */
export type UpdateFormTemplatePayload = {
  __typename?: 'UpdateFormTemplatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  fieldErrors?: Maybe<Array<FieldError>>;
  success?: Maybe<Scalars['Boolean']['output']>;
  template?: Maybe<FormTemplate>;
};

/** Autogenerated input type of UpdateGroup */
export type UpdateGroupInput = {
  attributes: GroupInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of UpdateGroup. */
export type UpdateGroupPayload = {
  __typename?: 'UpdateGroupPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  fieldErrors?: Maybe<Array<FieldError>>;
  group?: Maybe<Group>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

/** Autogenerated input type of UpdateGuest */
export type UpdateGuestInput = {
  appointmentGuestId: Scalars['ID']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  state: AppointmentGuestState;
};

/** Autogenerated return type of UpdateGuest. */
export type UpdateGuestPayload = {
  __typename?: 'UpdateGuestPayload';
  appointment?: Maybe<Appointment>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  fieldErrors?: Maybe<Array<FieldError>>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

/** Autogenerated input type of UpdateHicapsProvider */
export type UpdateHicapsProviderInput = {
  attributes: HicapsPaymentProviderInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of UpdateHicapsProvider. */
export type UpdateHicapsProviderPayload = {
  __typename?: 'UpdateHicapsProviderPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  fieldErrors?: Maybe<Array<FieldError>>;
  paymentProvider?: Maybe<PaymentProvider>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

/** Autogenerated input type of UpdateInvoice */
export type UpdateInvoiceInput = {
  attributes: InvoiceUpdateInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated input type of UpdateInvoiceItem */
export type UpdateInvoiceItemInput = {
  attributes: InvoiceItemUpdateInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of UpdateInvoiceItem. */
export type UpdateInvoiceItemPayload = {
  __typename?: 'UpdateInvoiceItemPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** General errors, unrelated to fields */
  errors?: Maybe<Array<Scalars['String']['output']>>;
  /** Input field errors */
  fieldErrors?: Maybe<Array<FieldError>>;
  invoiceItem?: Maybe<InvoiceItem>;
  /** Whether or not this mutation was successful */
  success?: Maybe<Scalars['Boolean']['output']>;
};

/** Autogenerated return type of UpdateInvoice. */
export type UpdateInvoicePayload = {
  __typename?: 'UpdateInvoicePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  fieldErrors?: Maybe<Array<FieldError>>;
  invoice?: Maybe<Invoice>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

/** Autogenerated input type of UpdateInvoiceSettings */
export type UpdateInvoiceSettingsInput = {
  attributes: InvoiceSettingsInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of UpdateInvoiceSettings. */
export type UpdateInvoiceSettingsPayload = {
  __typename?: 'UpdateInvoiceSettingsPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** General errors, unrelated to fields */
  errors?: Maybe<Array<Scalars['String']['output']>>;
  /** Input field errors */
  fieldErrors?: Maybe<Array<FieldError>>;
  invoiceSetting?: Maybe<InvoiceSetting>;
  /** Whether or not this mutation was successful */
  success?: Maybe<Scalars['Boolean']['output']>;
};

/** Autogenerated input type of UpdateInvoiceTransaction */
export type UpdateInvoiceTransactionInput = {
  attributes?: InputMaybe<InvoiceTransactionUpdateInput>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** ID of the invoice transaction */
  invoiceTransaction: Scalars['ID']['input'];
};

/** Autogenerated return type of UpdateInvoiceTransaction. */
export type UpdateInvoiceTransactionPayload = {
  __typename?: 'UpdateInvoiceTransactionPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  fieldErrors?: Maybe<Array<FieldError>>;
  invoiceTransaction?: Maybe<InvoiceTransaction>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

/** Autogenerated input type of UpdateLabWork */
export type UpdateLabWorkInput = {
  /** The attributes to update */
  attributes: LabWorkUpdateInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The ID of the lab work to update */
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of UpdateLabWork. */
export type UpdateLabWorkPayload = {
  __typename?: 'UpdateLabWorkPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  fieldErrors?: Maybe<Array<FieldError>>;
  labWork?: Maybe<LabWork>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

/** Autogenerated input type of UpdateLetter */
export type UpdateLetterInput = {
  /** Subject and body to update */
  attributes: LetterInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** GUID of the letter to update */
  guid: Scalars['ID']['input'];
};

/** Autogenerated return type of UpdateLetter. */
export type UpdateLetterPayload = {
  __typename?: 'UpdateLetterPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  fieldErrors?: Maybe<Array<FieldError>>;
  letter?: Maybe<Letter>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

/** Autogenerated input type of UpdateManualProvider */
export type UpdateManualProviderInput = {
  attributes: ManualPaymentProviderInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of UpdateManualProvider. */
export type UpdateManualProviderPayload = {
  __typename?: 'UpdateManualProviderPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  fieldErrors?: Maybe<Array<FieldError>>;
  paymentProvider?: Maybe<PaymentProvider>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

/** Autogenerated input type of UpdateMaterial */
export type UpdateMaterialInput = {
  attributes: MaterialInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  material: Scalars['ID']['input'];
};

/** Autogenerated return type of UpdateMaterial. */
export type UpdateMaterialPayload = {
  __typename?: 'UpdateMaterialPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** General errors, unrelated to fields */
  errors?: Maybe<Array<Scalars['String']['output']>>;
  /** Input field errors */
  fieldErrors?: Maybe<Array<FieldError>>;
  material?: Maybe<Material>;
  /** Whether or not this mutation was successful */
  success?: Maybe<Scalars['Boolean']['output']>;
};

/** Autogenerated input type of UpdateMedipassProvider */
export type UpdateMedipassProviderInput = {
  attributes: MedipassPaymentProviderInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of UpdateMedipassProvider. */
export type UpdateMedipassProviderPayload = {
  __typename?: 'UpdateMedipassProviderPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  fieldErrors?: Maybe<Array<FieldError>>;
  paymentProvider?: Maybe<PaymentProvider>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

/** Autogenerated input type of UpdateMessageNote */
export type UpdateMessageNoteInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  messageId: Scalars['ID']['input'];
  note: Scalars['String']['input'];
};

/** Autogenerated return type of UpdateMessageNote. */
export type UpdateMessageNotePayload = {
  __typename?: 'UpdateMessageNotePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  conversation?: Maybe<Conversation>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  fieldErrors?: Maybe<Array<FieldError>>;
  message?: Maybe<Message>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

/** Autogenerated input type of UpdateNote */
export type UpdateNoteInput = {
  attributes: NoteInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** GUID of the Note to update */
  note: Scalars['ID']['input'];
};

/** Autogenerated return type of UpdateNote. */
export type UpdateNotePayload = {
  __typename?: 'UpdateNotePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  fieldErrors?: Maybe<Array<FieldError>>;
  note?: Maybe<Note>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

/** Autogenerated input type of UpdateObject */
export type UpdateObjectInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  uuid: Scalars['String']['input'];
};

/** Autogenerated return type of UpdateObject. */
export type UpdateObjectPayload = {
  __typename?: 'UpdateObjectPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  fieldErrors?: Maybe<Array<FieldError>>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

/** Autogenerated input type of UpdateOnlineBookingSettings */
export type UpdateOnlineBookingSettingsInput = {
  attributes: OnlineBookingSettingsInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of UpdateOnlineBookingSettings. */
export type UpdateOnlineBookingSettingsPayload = {
  __typename?: 'UpdateOnlineBookingSettingsPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** General errors, unrelated to fields */
  errors?: Maybe<Array<Scalars['String']['output']>>;
  /** Input field errors */
  fieldErrors?: Maybe<Array<FieldError>>;
  onlineBookingSetting?: Maybe<OnlineBookingSetting>;
  /** Whether or not this mutation was successful */
  success?: Maybe<Scalars['Boolean']['output']>;
};

/** Autogenerated input type of UpdatePassword */
export type UpdatePasswordInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Password for the account */
  password: Scalars['String']['input'];
  /** Password confirmation for the account */
  passwordConfirmation: Scalars['String']['input'];
  /** The User to update */
  user: Scalars['ID']['input'];
};

/** Autogenerated return type of UpdatePassword. */
export type UpdatePasswordPayload = {
  __typename?: 'UpdatePasswordPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  fieldErrors?: Maybe<Array<FieldError>>;
  success?: Maybe<Scalars['Boolean']['output']>;
  user?: Maybe<User>;
};

/** Autogenerated input type of UpdatePatient */
export type UpdatePatientInput = {
  attributes: PatientUpdateInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of UpdatePatient. */
export type UpdatePatientPayload = {
  __typename?: 'UpdatePatientPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** General errors, unrelated to fields */
  errors?: Maybe<Array<Scalars['String']['output']>>;
  /** Input field errors */
  fieldErrors?: Maybe<Array<FieldError>>;
  /** Whether or not this mutation was successful */
  success?: Maybe<Scalars['Boolean']['output']>;
  userPracticeConnection?: Maybe<UserPracticeLink>;
};

/** Autogenerated input type of UpdatePatientRecordItem */
export type UpdatePatientRecordItemInput = {
  attributes: PatientRecordItemInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of UpdatePatientRecordItem. */
export type UpdatePatientRecordItemPayload = {
  __typename?: 'UpdatePatientRecordItemPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  fieldErrors?: Maybe<Array<FieldError>>;
  patientRecordItem?: Maybe<PatientRecordItem>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

/** Autogenerated input type of UpdatePerioEntries */
export type UpdatePerioEntriesInput = {
  attributes: Array<DentalUpdatePerioEntryInput>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  perioExamId: Scalars['ID']['input'];
};

/** Autogenerated return type of UpdatePerioEntries. */
export type UpdatePerioEntriesPayload = {
  __typename?: 'UpdatePerioEntriesPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  fieldErrors?: Maybe<Array<FieldError>>;
  perioEntries?: Maybe<Array<DentalPerioEntry>>;
  perioExam?: Maybe<DentalPerioExam>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

/** Autogenerated input type of UpdatePerioEntry */
export type UpdatePerioEntryInput = {
  attributes: DentalUpdatePerioEntryInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of UpdatePerioEntry. */
export type UpdatePerioEntryPayload = {
  __typename?: 'UpdatePerioEntryPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  fieldErrors?: Maybe<Array<FieldError>>;
  perioEntry?: Maybe<DentalPerioEntry>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

/** Autogenerated input type of UpdatePerioExam */
export type UpdatePerioExamInput = {
  attributes: DentalUpdatePerioExamInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of UpdatePerioExam. */
export type UpdatePerioExamPayload = {
  __typename?: 'UpdatePerioExamPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  fieldErrors?: Maybe<Array<FieldError>>;
  perioExam?: Maybe<DentalPerioExam>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

/** Autogenerated input type of UpdatePractice */
export type UpdatePracticeInput = {
  attributes: PracticeInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated input type of UpdatePracticeLocation */
export type UpdatePracticeLocationInput = {
  attributes: PracticeLocationsInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of UpdatePracticeLocation. */
export type UpdatePracticeLocationPayload = {
  __typename?: 'UpdatePracticeLocationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** General errors, unrelated to fields */
  errors?: Maybe<Array<Scalars['String']['output']>>;
  /** Input field errors */
  fieldErrors?: Maybe<Array<FieldError>>;
  practiceLocation?: Maybe<PracticeLocation>;
  /** Whether or not this mutation was successful */
  success?: Maybe<Scalars['Boolean']['output']>;
};

/** Autogenerated return type of UpdatePractice. */
export type UpdatePracticePayload = {
  __typename?: 'UpdatePracticePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  fieldErrors?: Maybe<Array<FieldError>>;
  practice?: Maybe<Practice>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

/** Autogenerated input type of UpdatePreference */
export type UpdatePreferenceInput = {
  /** Updated preference */
  attributes: PreferenceInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of UpdatePreference. */
export type UpdatePreferencePayload = {
  __typename?: 'UpdatePreferencePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** General errors, unrelated to fields */
  errors?: Maybe<Array<Scalars['String']['output']>>;
  /** Input field errors */
  fieldErrors?: Maybe<Array<FieldError>>;
  preference?: Maybe<PracticePreference>;
  /** Whether or not this mutation was successful */
  success?: Maybe<Scalars['Boolean']['output']>;
};

/** Autogenerated input type of UpdatePreferences */
export type UpdatePreferencesInput = {
  attributes: UserUpdatePreferencesInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of UpdatePreferences. */
export type UpdatePreferencesPayload = {
  __typename?: 'UpdatePreferencesPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  fieldErrors?: Maybe<Array<FieldError>>;
  preferences?: Maybe<Preferences>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

/** Autogenerated input type of UpdatePriceList */
export type UpdatePriceListInput = {
  attributes: PriceListInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  guid: Scalars['ID']['input'];
};

/** Autogenerated return type of UpdatePriceList. */
export type UpdatePriceListPayload = {
  __typename?: 'UpdatePriceListPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  fieldErrors?: Maybe<Array<FieldError>>;
  priceList?: Maybe<PriceList>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

/** Autogenerated input type of UpdateProfile */
export type UpdateProfileInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  onboarded: Scalars['Boolean']['input'];
  timezone?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of UpdateProfile. */
export type UpdateProfilePayload = {
  __typename?: 'UpdateProfilePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  fieldErrors?: Maybe<Array<FieldError>>;
  success?: Maybe<Scalars['Boolean']['output']>;
  userPracticeConnection?: Maybe<UserPracticeLink>;
};

/** Autogenerated input type of UpdateRecurringTask */
export type UpdateRecurringTaskInput = {
  /** The attributes to update a recurring task */
  attributes: RecurringTaskInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The ID of the recurring task to update */
  id: Scalars['String']['input'];
};

/** Autogenerated return type of UpdateRecurringTask. */
export type UpdateRecurringTaskPayload = {
  __typename?: 'UpdateRecurringTaskPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  fieldErrors?: Maybe<Array<FieldError>>;
  recurringTask?: Maybe<RecurringTask>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

/** Autogenerated input type of UpdateReferral */
export type UpdateReferralInput = {
  /** The attributes to update a referral with */
  attributes: ReferralUpdateInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The ID of the referral to update */
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of UpdateReferral. */
export type UpdateReferralPayload = {
  __typename?: 'UpdateReferralPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  fieldErrors?: Maybe<Array<FieldError>>;
  referral?: Maybe<Referral>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

/** Autogenerated input type of UpdateReferralSource */
export type UpdateReferralSourceInput = {
  attributes: ReferralSourceInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of UpdateReferralSource. */
export type UpdateReferralSourcePayload = {
  __typename?: 'UpdateReferralSourcePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  fieldErrors?: Maybe<Array<FieldError>>;
  referralSource?: Maybe<ReferralSource>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

/** Autogenerated input type of UpdateReportsSavedReport */
export type UpdateReportsSavedReportInput = {
  /** The attributes to update a saved report */
  attributes: ReportsSavedReportInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The ID of the saved report to update */
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of UpdateReportsSavedReport. */
export type UpdateReportsSavedReportPayload = {
  __typename?: 'UpdateReportsSavedReportPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  fieldErrors?: Maybe<Array<FieldError>>;
  reportsSavedReport?: Maybe<ReportsSavedReport>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

/** Autogenerated input type of UpdateReviewTemplate */
export type UpdateReviewTemplateInput = {
  attributes: ReviewTemplateInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of UpdateReviewTemplate. */
export type UpdateReviewTemplatePayload = {
  __typename?: 'UpdateReviewTemplatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** General errors, unrelated to fields */
  errors?: Maybe<Array<Scalars['String']['output']>>;
  /** Input field errors */
  fieldErrors?: Maybe<Array<FieldError>>;
  reviewTemplate?: Maybe<ReviewTemplate>;
  /** Whether or not this mutation was successful */
  success?: Maybe<Scalars['Boolean']['output']>;
};

/** Autogenerated input type of UpdateRichText */
export type UpdateRichTextInput = {
  /** Attributes to create a RichText */
  attributes: RichTextInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** ID of the RichText to update */
  richTextId: Scalars['ID']['input'];
};

/** Autogenerated return type of UpdateRichText. */
export type UpdateRichTextPayload = {
  __typename?: 'UpdateRichTextPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** General errors, unrelated to fields */
  errors?: Maybe<Array<Scalars['String']['output']>>;
  /** Input field errors */
  fieldErrors?: Maybe<Array<FieldError>>;
  richText?: Maybe<RichText>;
  /** Whether or not this mutation was successful */
  success?: Maybe<Scalars['Boolean']['output']>;
};

/** Autogenerated input type of UpdateRoom */
export type UpdateRoomInput = {
  /** The attributes to update */
  attributes: RoomInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Room ID to update */
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of UpdateRoom. */
export type UpdateRoomPayload = {
  __typename?: 'UpdateRoomPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  fieldErrors?: Maybe<Array<FieldError>>;
  room?: Maybe<Room>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

/** Autogenerated input type of UpdateSection */
export type UpdateSectionInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  /** Name, and title, of the section */
  name?: InputMaybe<Scalars['String']['input']>;
  /** Order of the section in the form */
  position?: InputMaybe<Scalars['Int']['input']>;
  /** Template the field belongs to */
  template?: InputMaybe<Scalars['ID']['input']>;
};

/** Autogenerated return type of UpdateSection. */
export type UpdateSectionPayload = {
  __typename?: 'UpdateSectionPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** General errors, unrelated to fields */
  errors?: Maybe<Array<Scalars['String']['output']>>;
  /** Input field errors */
  fieldErrors?: Maybe<Array<FieldError>>;
  section?: Maybe<FormSection>;
  /** Whether or not this mutation was successful */
  success?: Maybe<Scalars['Boolean']['output']>;
};

/** Autogenerated input type of UpdateShift */
export type UpdateShiftInput = {
  attributes: ShiftTypeInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of UpdateShift. */
export type UpdateShiftPayload = {
  __typename?: 'UpdateShiftPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  fieldErrors?: Maybe<Array<FieldError>>;
  shiftType?: Maybe<RosterShiftType>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

/** Autogenerated input type of UpdateSnippet */
export type UpdateSnippetInput = {
  attributes: SnippetInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of UpdateSnippet. */
export type UpdateSnippetPayload = {
  __typename?: 'UpdateSnippetPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  fieldErrors?: Maybe<Array<FieldError>>;
  snippet?: Maybe<Snippet>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

/** Autogenerated input type of UpdateStaff */
export type UpdateStaffInput = {
  attributes: UserUpdateInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of UpdateStaff. */
export type UpdateStaffPayload = {
  __typename?: 'UpdateStaffPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** General errors, unrelated to fields */
  errors?: Maybe<Array<Scalars['String']['output']>>;
  /** Input field errors */
  fieldErrors?: Maybe<Array<FieldError>>;
  /** Whether or not this mutation was successful */
  success?: Maybe<Scalars['Boolean']['output']>;
  userPracticeConnection?: Maybe<UserPracticeLink>;
};

/** Autogenerated input type of UpdateStateInvoice */
export type UpdateStateInvoiceInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  event: InvoiceStateEvent;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of UpdateStateInvoice. */
export type UpdateStateInvoicePayload = {
  __typename?: 'UpdateStateInvoicePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  fieldErrors?: Maybe<Array<FieldError>>;
  invoice?: Maybe<Invoice>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

/** Autogenerated input type of UpdateStatistic */
export type UpdateStatisticInput = {
  clearDate?: InputMaybe<Scalars['Boolean']['input']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  date?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
  guid?: InputMaybe<Scalars['ID']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  statistic: PatientTimestamps;
};

/** Autogenerated return type of UpdateStatistic. */
export type UpdateStatisticPayload = {
  __typename?: 'UpdateStatisticPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  fieldErrors?: Maybe<Array<FieldError>>;
  success?: Maybe<Scalars['Boolean']['output']>;
  userPracticeConnection?: Maybe<UserPracticeLink>;
};

/** Autogenerated input type of UpdateStatistics */
export type UpdateStatisticsInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  guid?: InputMaybe<Scalars['ID']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  statistics: Array<StatisticsInput>;
};

/** Autogenerated return type of UpdateStatistics. */
export type UpdateStatisticsPayload = {
  __typename?: 'UpdateStatisticsPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  fieldErrors?: Maybe<Array<FieldError>>;
  success?: Maybe<Scalars['Boolean']['output']>;
  userPracticeConnection?: Maybe<UserPracticeLink>;
};

/** Autogenerated input type of UpdateSterilisationAutoclave */
export type UpdateSterilisationAutoclaveInput = {
  /** The attributes to create a new autoclave */
  attributes: SterilisationAutoclaveInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The ID of the autoclave to update */
  id: Scalars['String']['input'];
};

/** Autogenerated return type of UpdateSterilisationAutoclave. */
export type UpdateSterilisationAutoclavePayload = {
  __typename?: 'UpdateSterilisationAutoclavePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  fieldErrors?: Maybe<Array<FieldError>>;
  sterilisationAutoclave?: Maybe<SterilisationAutoclave>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

/** Autogenerated input type of UpdateSterilisationCycle */
export type UpdateSterilisationCycleInput = {
  /** The attributes to update a cycle */
  attributes: SterilisationCycleInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The ID of the cycle to update */
  id: Scalars['String']['input'];
};

/** Autogenerated return type of UpdateSterilisationCycle. */
export type UpdateSterilisationCyclePayload = {
  __typename?: 'UpdateSterilisationCyclePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  fieldErrors?: Maybe<Array<FieldError>>;
  sterilisationCycle?: Maybe<SterilisationCycle>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

/** Autogenerated input type of UpdateSterilisationCycleType */
export type UpdateSterilisationCycleTypeInput = {
  /** The attributes to create a new cycle type */
  attributes: SterilisationCycleTypeInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The ID of the cycle type to update */
  id: Scalars['String']['input'];
};

/** Autogenerated return type of UpdateSterilisationCycleType. */
export type UpdateSterilisationCycleTypePayload = {
  __typename?: 'UpdateSterilisationCycleTypePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  fieldErrors?: Maybe<Array<FieldError>>;
  sterilisationCycleType?: Maybe<SterilisationCycleType>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

/** Autogenerated input type of UpdateSterilisationTracking */
export type UpdateSterilisationTrackingInput = {
  /** The attributes to update an existing tracking */
  attributes: SterilisationTrackingInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The ID of the tracking to update */
  id: Scalars['String']['input'];
};

/** Autogenerated return type of UpdateSterilisationTracking. */
export type UpdateSterilisationTrackingPayload = {
  __typename?: 'UpdateSterilisationTrackingPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  fieldErrors?: Maybe<Array<FieldError>>;
  sterilisationTracking?: Maybe<SterilisationTracking>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

/** Autogenerated input type of UpdateStripeProvider */
export type UpdateStripeProviderInput = {
  attributes: StripePaymentProviderInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of UpdateStripeProvider. */
export type UpdateStripeProviderPayload = {
  __typename?: 'UpdateStripeProviderPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  fieldErrors?: Maybe<Array<FieldError>>;
  paymentProvider?: Maybe<PaymentProvider>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

/** Autogenerated input type of UpdateTask */
export type UpdateTaskInput = {
  /** The attributes to update */
  attributes: TaskInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The ID of the task to update */
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of UpdateTask. */
export type UpdateTaskPayload = {
  __typename?: 'UpdateTaskPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  fieldErrors?: Maybe<Array<FieldError>>;
  success?: Maybe<Scalars['Boolean']['output']>;
  task?: Maybe<Task>;
};

/** Autogenerated input type of UpdateTeeth */
export type UpdateTeethInput = {
  /** Attributes to update the teeth with */
  attributes: DentalUpdateToothInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Id of the patient (upc) */
  patientId: Scalars['ID']['input'];
  /** Array of tooth ids to update */
  toothIds: Array<Scalars['ID']['input']>;
};

/** Autogenerated return type of UpdateTeeth. */
export type UpdateTeethPayload = {
  __typename?: 'UpdateTeethPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  dentalTeeth?: Maybe<Array<DentalTooth>>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  fieldErrors?: Maybe<Array<FieldError>>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

/** Autogenerated input type of UpdateTemplate */
export type UpdateTemplateInput = {
  attributes: TemplateInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of UpdateTemplate. */
export type UpdateTemplatePayload = {
  __typename?: 'UpdateTemplatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  fieldErrors?: Maybe<Array<FieldError>>;
  success?: Maybe<Scalars['Boolean']['output']>;
  template?: Maybe<TemplateUnion>;
};

/** Autogenerated input type of UpdateToothCondition */
export type UpdateToothConditionInput = {
  /** Attributes to create the conditions with */
  attributes: DentalUpdateToothConditionInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** ID of the tooth condition to update */
  toothConditionId: Scalars['ID']['input'];
};

/** Autogenerated return type of UpdateToothCondition. */
export type UpdateToothConditionPayload = {
  __typename?: 'UpdateToothConditionPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  dentalToothCondition?: Maybe<DentalToothCondition>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  fieldErrors?: Maybe<Array<FieldError>>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

/** Autogenerated input type of UpdateToothTreatment */
export type UpdateToothTreatmentInput = {
  /** Attributes to create the treatments with */
  attributes: DentalUpdateToothTreatmentInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** ID of the tooth treatment to update */
  toothTreatmentId: Scalars['ID']['input'];
};

/** Autogenerated return type of UpdateToothTreatment. */
export type UpdateToothTreatmentPayload = {
  __typename?: 'UpdateToothTreatmentPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  dentalToothTreatment?: Maybe<DentalToothTreatment>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  fieldErrors?: Maybe<Array<FieldError>>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

/** Autogenerated input type of UpdateTreatment */
export type UpdateTreatmentInput = {
  attributes: TreatmentInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of UpdateTreatment. */
export type UpdateTreatmentPayload = {
  __typename?: 'UpdateTreatmentPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  fieldErrors?: Maybe<Array<FieldError>>;
  success?: Maybe<Scalars['Boolean']['output']>;
  treatment?: Maybe<Treatment>;
};

/** Autogenerated input type of UpdateTreatmentPlan */
export type UpdateTreatmentPlanInput = {
  attributes: TreatmentPlanInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of UpdateTreatmentPlan. */
export type UpdateTreatmentPlanPayload = {
  __typename?: 'UpdateTreatmentPlanPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  fieldErrors?: Maybe<Array<FieldError>>;
  success?: Maybe<Scalars['Boolean']['output']>;
  treatmentPlan?: Maybe<TreatmentPlan>;
};

/** Autogenerated input type of UpdateTreatmentsPreset */
export type UpdateTreatmentsPresetInput = {
  attributes: TreatmentsPresetUpdateInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated input type of UpdateTreatmentsPresetItem */
export type UpdateTreatmentsPresetItemInput = {
  attributes: TreatmentsPresetItemUpdateInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of UpdateTreatmentsPresetItem. */
export type UpdateTreatmentsPresetItemPayload = {
  __typename?: 'UpdateTreatmentsPresetItemPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  fieldErrors?: Maybe<Array<FieldError>>;
  success?: Maybe<Scalars['Boolean']['output']>;
  treatmentsPresetItem?: Maybe<TreatmentsPresetItem>;
};

/** Autogenerated return type of UpdateTreatmentsPreset. */
export type UpdateTreatmentsPresetPayload = {
  __typename?: 'UpdateTreatmentsPresetPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  fieldErrors?: Maybe<Array<FieldError>>;
  success?: Maybe<Scalars['Boolean']['output']>;
  treatmentsPreset?: Maybe<TreatmentsPreset>;
};

/** Autogenerated input type of UpdateTyroProvider */
export type UpdateTyroProviderInput = {
  attributes: TyroPaymentProviderInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of UpdateTyroProvider. */
export type UpdateTyroProviderPayload = {
  __typename?: 'UpdateTyroProviderPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  fieldErrors?: Maybe<Array<FieldError>>;
  paymentProvider?: Maybe<PaymentProvider>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

/** Autogenerated input type of UpdateTyroTerminal */
export type UpdateTyroTerminalInput = {
  attributes: TyroPaymentTerminalInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of UpdateTyroTerminal. */
export type UpdateTyroTerminalPayload = {
  __typename?: 'UpdateTyroTerminalPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  fieldErrors?: Maybe<Array<FieldError>>;
  paymentTerminal?: Maybe<PaymentTerminal>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

/** Autogenerated input type of UpdateUserContact */
export type UpdateUserContactInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** GUID of the contact UPC to update */
  contact: Scalars['ID']['input'];
  /** GUID of the UPC to update contacts for */
  guid: Scalars['ID']['input'];
  /** Whether or not this contact should receive communications meant for the user */
  relayCommunications?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Autogenerated return type of UpdateUserContact. */
export type UpdateUserContactPayload = {
  __typename?: 'UpdateUserContactPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  fieldErrors?: Maybe<Array<FieldError>>;
  success?: Maybe<Scalars['Boolean']['output']>;
  userPracticeConnection?: Maybe<UserPracticeLink>;
};

/** Autogenerated input type of UpdateUser */
export type UpdateUserInput = {
  attributes: UserUpdateInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The User to update */
  user: Scalars['ID']['input'];
};

/** Autogenerated return type of UpdateUser. */
export type UpdateUserPayload = {
  __typename?: 'UpdateUserPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  fieldErrors?: Maybe<Array<FieldError>>;
  success?: Maybe<Scalars['Boolean']['output']>;
  user?: Maybe<User>;
};

/** Autogenerated input type of UpdateWaitlist */
export type UpdateWaitlistInput = {
  attributes: WaitlistUpdateInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of UpdateWaitlist. */
export type UpdateWaitlistPayload = {
  __typename?: 'UpdateWaitlistPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  fieldErrors?: Maybe<Array<FieldError>>;
  success?: Maybe<Scalars['Boolean']['output']>;
  waitlist?: Maybe<Waitlist>;
};

/** Autogenerated input type of UpdateWorkflow */
export type UpdateWorkflowInput = {
  attributes: WorkflowInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of UpdateWorkflow. */
export type UpdateWorkflowPayload = {
  __typename?: 'UpdateWorkflowPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  fieldErrors?: Maybe<Array<FieldError>>;
  success?: Maybe<Scalars['Boolean']['output']>;
  workflow?: Maybe<Workflow>;
};

/** Autogenerated input type of UpgradeAccountPlan */
export type UpgradeAccountPlanInput = {
  /** The Account to upgrade */
  account: Scalars['ID']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of UpgradeAccountPlan. */
export type UpgradeAccountPlanPayload = {
  __typename?: 'UpgradeAccountPlanPayload';
  account?: Maybe<Account>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  fieldErrors?: Maybe<Array<FieldError>>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

/** Autogenerated input type of UploadAttachments */
export type UploadAttachmentsInput = {
  /** Badge slugs to apply to all files */
  badges?: InputMaybe<Array<Scalars['String']['input']>>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Content type of the attachment (imaging, uploads, documents, etc.) */
  contentType?: InputMaybe<DocumentContentType>;
  /** Directory to store the attachment in */
  directory?: InputMaybe<Scalars['ID']['input']>;
  /** Files to upload */
  files: Array<Scalars['Upload']['input']>;
  /** Find or create a subdirectory of the given directory ID to store the attachment in */
  subdirectory?: InputMaybe<Scalars['String']['input']>;
  /** Record to add attachment to */
  uuid: Scalars['String']['input'];
};

/** Autogenerated return type of UploadAttachments. */
export type UploadAttachmentsPayload = {
  __typename?: 'UploadAttachmentsPayload';
  attachments?: Maybe<Array<Attachment>>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  fieldErrors?: Maybe<Array<FieldError>>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

/** Autogenerated input type of UploadImage */
export type UploadImageInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Images to upload */
  files: Array<Scalars['Upload']['input']>;
  /** Optionally associate uploaded images with an existing record (this can be done later) */
  uuid?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of UploadImage. */
export type UploadImagePayload = {
  __typename?: 'UploadImagePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** General errors, unrelated to fields */
  errors?: Maybe<Array<Scalars['String']['output']>>;
  /** Input field errors */
  fieldErrors?: Maybe<Array<FieldError>>;
  images?: Maybe<Array<DocumentImage>>;
  /** Whether or not this mutation was successful */
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type User = {
  __typename?: 'User';
  accountUser?: Maybe<AccountUser>;
  authenticatorEnabled?: Maybe<Scalars['Boolean']['output']>;
  confirmationToken?: Maybe<Scalars['String']['output']>;
  confirmedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  createdAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  /** Expo push tokens for push notifications (1 per device) */
  expoPushTokens?: Maybe<Array<Scalars['String']['output']>>;
  firstName?: Maybe<Scalars['String']['output']>;
  guid: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  intercomUserHash?: Maybe<Scalars['String']['output']>;
  isBiometricsEnabled?: Maybe<Scalars['Boolean']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  mobilePhone?: Maybe<Scalars['String']['output']>;
  mobileRegionCode?: Maybe<Scalars['Int']['output']>;
  mobileRegionShort?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  patients?: Maybe<Array<Patient>>;
  professional?: Maybe<Professional>;
  registered?: Maybe<Scalars['Boolean']['output']>;
  userPracticeConnections?: Maybe<Array<UserPracticeLink>>;
  username?: Maybe<Scalars['String']['output']>;
};


export type UserUserPracticeConnectionsArgs = {
  accountId?: InputMaybe<Scalars['ID']['input']>;
  practiceGuids?: InputMaybe<Array<Scalars['ID']['input']>>;
  roles?: InputMaybe<Array<PracticeRole>>;
  state?: InputMaybe<Array<UserPracticeConnectionState>>;
};

/** A 2FA authenticator for a User */
export type UserAuthenticator = {
  __typename?: 'UserAuthenticator';
  email?: Maybe<Scalars['String']['output']>;
  enabled: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  mobile?: Maybe<Scalars['String']['output']>;
  secret: Scalars['String']['output'];
  uri: Scalars['String']['output'];
};

/** The connection type for User. */
export type UserConnection = {
  __typename?: 'UserConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<UserEdge>>>;
  /** IDs of ALL results, regardless of pagination */
  ids: Array<Scalars['ID']['output']>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<User>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Returns total count of items in list */
  totalCount: Scalars['Int']['output'];
};

export type UserCreateInput = {
  dateOfBirth?: InputMaybe<Scalars['ISO8601Date']['input']>;
  /** Description added against the referral source */
  description?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  gender?: InputMaybe<Gender>;
  generalAddress?: InputMaybe<Scalars['String']['input']>;
  homePhone?: InputMaybe<Scalars['String']['input']>;
  /** Toggle if this referral IN is the initial source */
  initialSource?: InputMaybe<Scalars['Boolean']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  /** Link this user to an existing account. This will let the user log in to both */
  linkedUpcId?: InputMaybe<Scalars['ID']['input']>;
  middleName?: InputMaybe<Scalars['String']['input']>;
  mobilePhone?: InputMaybe<Scalars['String']['input']>;
  mobileRegionCode?: InputMaybe<Scalars['Int']['input']>;
  mobileRegionShort?: InputMaybe<Scalars['String']['input']>;
  nationality?: InputMaybe<Scalars['String']['input']>;
  occupation?: InputMaybe<Scalars['String']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
  /** Create multiple practice connections for this user. If left blank, only create for current practice */
  practiceIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** Creates user for a specific practice location, or defaults to current practice default location */
  practiceLocation?: InputMaybe<Scalars['ID']['input']>;
  preferredName?: InputMaybe<Scalars['String']['input']>;
  priceListId?: InputMaybe<Scalars['ID']['input']>;
  primaryPractitionerId?: InputMaybe<Scalars['ID']['input']>;
  privateHealthcareNumber?: InputMaybe<Scalars['String']['input']>;
  publicHealthcareNumber?: InputMaybe<Scalars['String']['input']>;
  /** Referral In/Out */
  referralType?: InputMaybe<ReferralType>;
  /** Person/Source referring, UserPracticeConnection/ReferralSource/Contact */
  referrerId?: InputMaybe<Scalars['ID']['input']>;
  referrerType?: InputMaybe<Scalars['String']['input']>;
  role: PracticeRole;
  /** Also send the new user one of your intake forms */
  templateId?: InputMaybe<Scalars['ID']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  workPhone?: InputMaybe<Scalars['String']['input']>;
};

/** An edge in a connection. */
export type UserEdge = {
  __typename?: 'UserEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<User>;
};

export type UserPracticeConnectionAlert = {
  __typename?: 'UserPracticeConnectionAlert';
  alert?: Maybe<Alert>;
  alertId: Scalars['ID']['output'];
  createdAt: Scalars['ISO8601DateTime']['output'];
  id: Scalars['ID']['output'];
  note?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['ISO8601DateTime']['output'];
  userPracticeConnectionId: Scalars['ID']['output'];
};

/** The connection type for UserPracticeConnectionAlert. */
export type UserPracticeConnectionAlertConnection = {
  __typename?: 'UserPracticeConnectionAlertConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<UserPracticeConnectionAlertEdge>>>;
  /** IDs of ALL results, regardless of pagination */
  ids: Array<Scalars['ID']['output']>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<UserPracticeConnectionAlert>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Returns total count of items in list */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type UserPracticeConnectionAlertEdge = {
  __typename?: 'UserPracticeConnectionAlertEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<UserPracticeConnectionAlert>;
};

export enum UserPracticeConnectionState {
  Active = 'active',
  Archived = 'archived',
  Blocked = 'blocked',
  Disabled = 'disabled',
  Inactive = 'inactive',
  Invited = 'invited',
  Pending = 'pending'
}

export enum UserPracticeConnectionStateEvent {
  AcceptInvite = 'accept_invite',
  Activate = 'activate',
  Archive = 'archive',
  Block = 'block',
  Deactivate = 'deactivate',
  Disable = 'disable',
  Pending = 'pending',
  SendInvite = 'send_invite'
}

export type UserPracticeLink = AnyNode & {
  __typename?: 'UserPracticeLink';
  account: Account;
  /** The total amount of credit available to this user that they can use */
  accountCredit?: Maybe<Scalars['Float']['output']>;
  /** Active alerts for this user practice connection. */
  activeUpcAlerts: Array<UserPracticeConnectionAlert>;
  appointmentReasons?: Maybe<Array<AppointmentReason>>;
  appointments?: Maybe<AppointmentConnection>;
  appointmentsCount?: Maybe<Scalars['Int']['output']>;
  attachmentsCount?: Maybe<Scalars['Int']['output']>;
  /** Whether the patient will get auto generated recalls */
  autoRecalls?: Maybe<Scalars['Boolean']['output']>;
  balance: Scalars['Float']['output'];
  communicationSettings?: Maybe<Array<CommunicationSettings>>;
  consecutiveMissedAppointmentsCount?: Maybe<Scalars['Int']['output']>;
  contacts?: Maybe<Array<Contact>>;
  contractIdentifiers?: Maybe<Array<ContractIdentifier>>;
  conversationsCount?: Maybe<Scalars['Int']['output']>;
  createdAt: Scalars['ISO8601DateTime']['output'];
  defaultPrivateHealthFund?: Maybe<HealthFundConsumer>;
  defaultPublicHealthFund?: Maybe<HealthFundConsumer>;
  demo?: Maybe<Scalars['Boolean']['output']>;
  emailsCount?: Maybe<Scalars['Int']['output']>;
  emergencyContact?: Maybe<EmergencyContact>;
  /** The family for this user */
  family?: Maybe<Family>;
  /** The family id for this user */
  familyId?: Maybe<Scalars['ID']['output']>;
  firstAppointmentTime?: Maybe<Scalars['ISO8601DateTime']['output']>;
  firstVisitTime?: Maybe<Scalars['ISO8601DateTime']['output']>;
  forms?: Maybe<FormConnection>;
  /** UUID field to identify this record. Will be replacing ID */
  guid: Scalars['ID']['output'];
  healthFundIdentifiers?: Maybe<Array<HealthFundConsumer>>;
  hideEmptyNotesTitle?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ID']['output'];
  invite?: Maybe<Invite>;
  invoicesCount?: Maybe<Scalars['Int']['output']>;
  isGlobalAdmin?: Maybe<Scalars['Boolean']['output']>;
  lastAppointmentTime?: Maybe<Scalars['ISO8601DateTime']['output']>;
  lastExamTime?: Maybe<Scalars['ISO8601DateTime']['output']>;
  lastMedicalData?: Maybe<Scalars['ISO8601DateTime']['output']>;
  lastMedicalFormTime?: Maybe<Scalars['ISO8601DateTime']['output']>;
  lastMessage?: Maybe<ShortMessage>;
  /** The last message either sent/received by this upc */
  lastMessageAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  lastMessageId?: Maybe<Scalars['ID']['output']>;
  /** The last time a message was read */
  lastMessageReadAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  lastVisitTime?: Maybe<Scalars['ISO8601DateTime']['output']>;
  lastXrayTime?: Maybe<Scalars['ISO8601DateTime']['output']>;
  /** Last created profile record, even if not approved */
  latestProfile?: Maybe<UserPracticeProfile>;
  metadata?: Maybe<Metadata>;
  nextAppointmentTime?: Maybe<Scalars['ISO8601DateTime']['output']>;
  notesCount?: Maybe<Scalars['Int']['output']>;
  /** The total outstanding amount of invoices to be paid. This does not include draft/archived invoices */
  outstandingBalanceCount?: Maybe<Scalars['Float']['output']>;
  patient?: Maybe<Patient>;
  patientRecord?: Maybe<PatientRecord>;
  permissions: Array<Permission>;
  photo?: Maybe<Avatar>;
  practice?: Maybe<Practice>;
  practiceGuid?: Maybe<Scalars['ID']['output']>;
  practiceId?: Maybe<Scalars['ID']['output']>;
  practiceLocation?: Maybe<PracticeLocation>;
  /** The ID of the practice location this UPC is assigned to */
  practiceLocationId?: Maybe<Scalars['ID']['output']>;
  practiceProfessional?: Maybe<PracticeProfessional>;
  preferences?: Maybe<Preferences>;
  /** Prescriber number (if staff member) */
  prescriberNumber?: Maybe<Scalars['String']['output']>;
  priceList?: Maybe<PriceList>;
  /** Selected pricelist id */
  priceListId?: Maybe<Scalars['ID']['output']>;
  primaryPractitioner?: Maybe<UserPracticeLink>;
  /** The ID of the primary practitioner */
  primaryPractitionerId?: Maybe<Scalars['ID']['output']>;
  professional?: Maybe<Professional>;
  profile?: Maybe<UserPracticeProfile>;
  proposedInvoiceItemsCost?: Maybe<Scalars['Float']['output']>;
  /** Qualifications (if staff member) */
  qualifications?: Maybe<Scalars['String']['output']>;
  readyToInvoiceItemsCost?: Maybe<Scalars['Float']['output']>;
  /** Set when the recall is marked as completed. */
  recallCompletedTime?: Maybe<Scalars['ISO8601DateTime']['output']>;
  /** Set when the recall is marked as dropped off. */
  recallDropOffTime?: Maybe<Scalars['ISO8601DateTime']['output']>;
  /** Either the recall_visit_due_time or recall_exam_due_time depending on the practice recall setting trigger */
  recallDueTime?: Maybe<Scalars['ISO8601DateTime']['output']>;
  /** The date that is set for the next recall based off recall_period. This triggers once an item gets invoiced. */
  recallExamDueTime?: Maybe<Scalars['ISO8601DateTime']['output']>;
  /** The period after the patients last_exam or last_vists in MONTHS when the auto recall will trigger */
  recallPeriod?: Maybe<Scalars['Int']['output']>;
  /** The date that is set for the next recall based off recall_period. This triggers at the completion of an appointment. */
  recallVisitDueTime?: Maybe<Scalars['ISO8601DateTime']['output']>;
  recallWorkflow?: Maybe<Workflow>;
  /** The selected recall workflow that will generate if auto_recall is true */
  recallWorkflowId?: Maybe<Scalars['ID']['output']>;
  recalls?: Maybe<RecallConnection>;
  recallsCount?: Maybe<Scalars['Int']['output']>;
  relatives?: Maybe<Array<Relative>>;
  role: Role;
  rosterDays?: Maybe<Array<RosterDay>>;
  scanIdentifier?: Maybe<Scalars['String']['output']>;
  scanningIntegrations?: Maybe<ScanningIntegrations>;
  secondaryPractitioner?: Maybe<UserPracticeLink>;
  /** The ID of the secondary practitioner */
  secondaryPractitionerId?: Maybe<Scalars['ID']['output']>;
  shortMessagesCount?: Maybe<Scalars['Int']['output']>;
  slug?: Maybe<Scalars['String']['output']>;
  state?: Maybe<UserPracticeConnectionState>;
  stickyNotes?: Maybe<Array<Note>>;
  treatmentPlansCount?: Maybe<Scalars['Int']['output']>;
  treatments?: Maybe<TreatmentConnection>;
  unreadMessageCount?: Maybe<Scalars['Int']['output']>;
  /** Empty if latest_profile is the same as profile */
  updatedProfile?: Maybe<UserPracticeProfile>;
  user: User;
  userId: Scalars['ID']['output'];
  /** Unique identifier of the message */
  uuid: Scalars['String']['output'];
};


export type UserPracticeLinkAppointmentsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type UserPracticeLinkFormsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type UserPracticeLinkRecallsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  state?: InputMaybe<RecallState>;
};


export type UserPracticeLinkRosterDaysArgs = {
  endDate?: InputMaybe<Scalars['ISO8601Date']['input']>;
  startDate?: InputMaybe<Scalars['ISO8601Date']['input']>;
};


export type UserPracticeLinkTreatmentsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** The connection type for UserPracticeLink. */
export type UserPracticeLinkConnection = {
  __typename?: 'UserPracticeLinkConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<UserPracticeLinkEdge>>>;
  /** IDs of ALL results, regardless of pagination */
  ids: Array<Scalars['ID']['output']>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<UserPracticeLink>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Returns total count of items in list */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type UserPracticeLinkEdge = {
  __typename?: 'UserPracticeLinkEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<UserPracticeLink>;
};

export type UserPracticeLinkOrderInput = {
  direction?: InputMaybe<Direction>;
  field?: InputMaybe<UpcField>;
};

export type UserPracticeProfile = AnyNode & {
  __typename?: 'UserPracticeProfile';
  age?: Maybe<Scalars['Int']['output']>;
  avatarColor?: Maybe<Scalars['String']['output']>;
  avatarColour?: Maybe<Scalars['String']['output']>;
  billingAddress?: Maybe<Location>;
  calendarAvailability?: Maybe<Scalars['Boolean']['output']>;
  dailySummaryEmail?: Maybe<Scalars['String']['output']>;
  dateOfBirth?: Maybe<Scalars['ISO8601Date']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  /** ID of patient from external systems. Usually comes from imported data */
  externalReference?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  /** Use preferred_full_name instead of this field */
  fullName?: Maybe<Scalars['String']['output']>;
  fullNameWithTitle?: Maybe<Scalars['String']['output']>;
  gender?: Maybe<Gender>;
  generalAddress?: Maybe<Location>;
  guid: Scalars['ID']['output'];
  homeAddress?: Maybe<Location>;
  homePhone?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  initials?: Maybe<Scalars['String']['output']>;
  internationalMobile?: Maybe<Scalars['String']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  middleName?: Maybe<Scalars['String']['output']>;
  mobilePhone?: Maybe<Scalars['String']['output']>;
  mobileRegionCode?: Maybe<Scalars['String']['output']>;
  mobileRegionShort?: Maybe<Scalars['String']['output']>;
  nationality?: Maybe<Scalars['String']['output']>;
  occupation?: Maybe<Scalars['String']['output']>;
  onboarded: Scalars['Boolean']['output'];
  /** If this is true, the user has updated the profile and the practice has an outdated profile on file */
  outdated?: Maybe<Scalars['Boolean']['output']>;
  practice?: Maybe<Practice>;
  /** Need to be used instead of full_name */
  preferredFullName?: Maybe<Scalars['String']['output']>;
  preferredName?: Maybe<Scalars['String']['output']>;
  sendDailySummary?: Maybe<Scalars['Boolean']['output']>;
  title?: Maybe<UserPracticeProfileTitle>;
  /** Default to using this field when integrating with external systems */
  useExternalReference?: Maybe<Scalars['Boolean']['output']>;
  user?: Maybe<User>;
  /** Unique identifier of the message */
  uuid: Scalars['String']['output'];
  workPhone?: Maybe<Scalars['String']['output']>;
};

export enum UserPracticeProfileTitle {
  /** Dr */
  Dr = 'dr',
  /** Master */
  Master = 'master',
  /** Miss */
  Miss = 'miss',
  /** Mr */
  Mr = 'mr',
  /** Mrs */
  Mrs = 'mrs',
  /** Ms */
  Ms = 'ms',
  /** Mx */
  Mx = 'mx',
  /** Professor */
  Professor = 'professor'
}

export type UserUpdateInput = {
  calendarAvailability?: InputMaybe<Scalars['Boolean']['input']>;
  contractIdentifiers?: InputMaybe<Array<ContractIdentifierCreateInput>>;
  dailySummaryEmail?: InputMaybe<Scalars['String']['input']>;
  defaultPrivateHealthFund?: InputMaybe<HealthFundIdentifierInput>;
  defaultPublicHealthFund?: InputMaybe<HealthFundIdentifierInput>;
  email?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  gender?: InputMaybe<Gender>;
  healthFundIdentifiers?: InputMaybe<Array<HealthFundIdentifierInput>>;
  homeAddress?: InputMaybe<LocationInput>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  mobilePhone?: InputMaybe<Scalars['String']['input']>;
  mobileRegionCode?: InputMaybe<Scalars['String']['input']>;
  mobileRegionShort?: InputMaybe<Scalars['String']['input']>;
  /** Staff members prescriber number */
  prescriberNumber?: InputMaybe<Scalars['String']['input']>;
  /** Staff members' qualifications */
  qualifications?: InputMaybe<Scalars['String']['input']>;
  /** Set True to remove the Profile Avatar */
  removePhoto?: InputMaybe<Scalars['Boolean']['input']>;
  role?: InputMaybe<Scalars['String']['input']>;
  sendDailySummary?: InputMaybe<Scalars['Boolean']['input']>;
  title?: InputMaybe<UserPracticeProfileTitle>;
  /** Unique username the employee can log in with */
  username?: InputMaybe<Scalars['String']['input']>;
  workPhone?: InputMaybe<Scalars['String']['input']>;
};

export type UserUpdatePreferencesInput = {
  /** Auto show quick availability in the calendar */
  calendarAutoshowQuickAvailability?: InputMaybe<Scalars['Boolean']['input']>;
  /** The column widths for calendar for the practitioners */
  calendarColumnWidth?: InputMaybe<Scalars['Int']['input']>;
  /** When calendar is opened, always default to today */
  calendarDefaultToday?: InputMaybe<Scalars['Boolean']['input']>;
  /** The FullCalendar default view type */
  calendarDefaultView?: InputMaybe<Scalars['String']['input']>;
  /** Display colours in the calendar based on appointment cancelled status */
  calendarDisplayCancelledColour?: InputMaybe<Scalars['Boolean']['input']>;
  /** Display colours in the calendar based on appointment completed status */
  calendarDisplayCompletedColour?: InputMaybe<Scalars['Boolean']['input']>;
  /** Excluded cancelled appointments from being displayed in the calendar */
  calendarExcludeCancelled?: InputMaybe<Scalars['Boolean']['input']>;
  /** The excluded practitioners from the calendar */
  calendarExcludePractitionerIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** Hide the Saturday column in the calendar */
  calendarHideSaturday?: InputMaybe<Scalars['Boolean']['input']>;
  /** Hide the Sunday column in the calendar */
  calendarHideSunday?: InputMaybe<Scalars['Boolean']['input']>;
  /** The initial date the calendar will open to */
  calendarInitialDate?: InputMaybe<Scalars['String']['input']>;
  /** The selected practitioners to view in the calendar */
  calendarPractitionerIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** Practitioners IDs that have been manually sorted to an index */
  calendarPractitionerSortedIds?: InputMaybe<Array<ItemSortIndexInput>>;
  /** Selected calendar resource view */
  calendarResourceType?: InputMaybe<CalendarResource>;
  /** The selected rooms to view in the calendar */
  calendarRoomIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** Open drawer on single click in the calendar */
  calendarSingleClickDrawer?: InputMaybe<Scalars['Boolean']['input']>;
  /** The time in minutes duration for each appointment slot in the calendar */
  calendarSlotDuration?: InputMaybe<Scalars['String']['input']>;
  /** The closing time in 24 hour time for the calendar */
  calendarSlotMaxTime?: InputMaybe<Scalars['Int']['input']>;
  /** The opening time in 24 hour time for the calendar */
  calendarSlotMinTime?: InputMaybe<Scalars['Int']['input']>;
  /** The selected practitioners to view in the favorite list */
  catalogueItemFavoriteIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** Dentition - Both, Permanent or Deciduous */
  chartDentition?: InputMaybe<DentalChartConfigDentition>;
  /** Graphics mode - low, medium or high */
  chartGraphicsMode?: InputMaybe<DentalChartConfigGraphicsMode>;
  /** Graphics Style - Anatomical or Graphical */
  chartGraphicsStyle?: InputMaybe<DentalChartConfigGraphicsStyle>;
  /** Enable material colours in the dental chart */
  chartMaterialColours?: InputMaybe<Scalars['Boolean']['input']>;
  /** Tooth Surface - Buccal or Palatal */
  chartToothSurface?: InputMaybe<DentalChartConfigToothSurface>;
  /** Layouts for dashboard widget */
  dashboardWidgets?: InputMaybe<DashboardWidgetLayoutInput>;
  /** In the notes section, hide the title if it is blank */
  hideEmptyNotesTitle?: InputMaybe<Scalars['Boolean']['input']>;
  /** Set the user as a global admin */
  isGlobalAdmin?: InputMaybe<Scalars['Boolean']['input']>;
  /** Show patient treatment notes in the patient notes section */
  showPatientTreatmentNotes?: InputMaybe<Scalars['Boolean']['input']>;
  /** Show recall tasks in the task list */
  showRecallTasks?: InputMaybe<Scalars['Boolean']['input']>;
  /** Show all task states, not just in_progress or pending */
  showTaskHistory?: InputMaybe<Scalars['Boolean']['input']>;
  /** Only show tasks assigned to current user */
  showUserTasks?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Autogenerated return type of UserUpdated. */
export type UserUpdatedPayload = {
  __typename?: 'UserUpdatedPayload';
  userPracticeConnection?: Maybe<UserPracticeLink>;
};

/** Autogenerated input type of ValidateSection */
export type ValidateSectionInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
};

/** Autogenerated return type of ValidateSection. */
export type ValidateSectionPayload = {
  __typename?: 'ValidateSectionPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** General errors, unrelated to fields */
  errors?: Maybe<Array<Scalars['String']['output']>>;
  /** Input field errors */
  fieldErrors?: Maybe<Array<FieldError>>;
  section?: Maybe<FormSection>;
  /** Whether or not this mutation was successful */
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type VerifyInvitationAttributes = {
  /** Invitation token */
  invitationToken: Scalars['String']['input'];
  /** Invitation subdomain */
  subdomain: Scalars['String']['input'];
};

/** Autogenerated input type of VerifyInvitation */
export type VerifyInvitationInput = {
  attributes: VerifyInvitationAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of VerifyInvitation. */
export type VerifyInvitationPayload = {
  __typename?: 'VerifyInvitationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  credentials?: Maybe<Credentials>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  fieldErrors?: Maybe<Array<FieldError>>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

/** Autogenerated input type of VerifyOtp */
export type VerifyOtpInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  code: Scalars['String']['input'];
  id: Scalars['ID']['input'];
  /** Patient App configuration to accept or increment login count on */
  patientAppConfig?: InputMaybe<Scalars['ID']['input']>;
};

/** Autogenerated return type of VerifyOtp. */
export type VerifyOtpPayload = {
  __typename?: 'VerifyOtpPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  credentials?: Maybe<Credentials>;
  /** General errors, unrelated to fields */
  errors?: Maybe<Array<Scalars['String']['output']>>;
  /** Input field errors */
  fieldErrors?: Maybe<Array<FieldError>>;
  /** Whether or not this mutation was successful */
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type VerifySlot = {
  __typename?: 'VerifySlot';
  errors?: Maybe<Array<VerifySlotError>>;
  id?: Maybe<Scalars['ID']['output']>;
  valid?: Maybe<Scalars['Boolean']['output']>;
};

export type VerifySlotError = {
  __typename?: 'VerifySlotError';
  appointment?: Maybe<Appointment>;
  message?: Maybe<Scalars['String']['output']>;
  practitioner?: Maybe<UserPracticeLink>;
  room?: Maybe<Room>;
};

export type VerifySlotInput = {
  /** Filter by IDs */
  appointmentId?: InputMaybe<Scalars['ID']['input']>;
  /** Filter by end time */
  endTime?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
  /** Filter by practitioner ID */
  practitionerId?: InputMaybe<Scalars['ID']['input']>;
  /** Filter by room ID */
  roomId?: InputMaybe<Scalars['ID']['input']>;
  /** Filter by start time */
  startTime?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
};

export type Version = {
  __typename?: 'Version';
  fields?: Maybe<Array<VersionField>>;
  id?: Maybe<Scalars['ID']['output']>;
};

export type VersionField = {
  __typename?: 'VersionField';
  changes?: Maybe<Array<Scalars['JSON']['output']>>;
  date?: Maybe<Scalars['ISO8601DateTime']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  owner?: Maybe<Scalars['String']['output']>;
};

export type VersionSearchInput = {
  /** Changed fields to search for */
  fields: Array<Scalars['String']['input']>;
  /** Filter by ID */
  version: Scalars['ID']['input'];
};

export enum Visibility {
  Staff = 'staff',
  StaffAndPatients = 'staff_and_patients'
}

export type VisibilityDefinition = {
  __typename?: 'VisibilityDefinition';
  description?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  level: Visibility;
  name?: Maybe<Scalars['String']['output']>;
};

export type Waitlist = {
  __typename?: 'Waitlist';
  appointment?: Maybe<Appointment>;
  catalogueItemIds?: Maybe<Array<Scalars['ID']['output']>>;
  createdAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  discardedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  guid: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  notes?: Maybe<Scalars['String']['output']>;
  practitioner?: Maybe<UserPracticeLink>;
  preferredDays?: Maybe<Array<Weekday>>;
  preferredTimes?: Maybe<Array<WaitlistPreferredTime>>;
  state?: Maybe<WaitlistState>;
  updatedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  urgent?: Maybe<Scalars['Boolean']['output']>;
  userPracticeConnection?: Maybe<UserPracticeLink>;
};

/** The connection type for Waitlist. */
export type WaitlistConnection = {
  __typename?: 'WaitlistConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<WaitlistEdge>>>;
  /** IDs of ALL results, regardless of pagination */
  ids: Array<Scalars['ID']['output']>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Waitlist>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Returns total count of items in list */
  totalCount: Scalars['Int']['output'];
};

export type WaitlistCreateInput = {
  catalogueItemIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  notes?: InputMaybe<Scalars['String']['input']>;
  practitionerId?: InputMaybe<Scalars['ID']['input']>;
  preferredDays?: InputMaybe<Array<Weekday>>;
  preferredTimes?: InputMaybe<Array<WaitlistPreferredTime>>;
  urgent?: InputMaybe<Scalars['Boolean']['input']>;
  userPracticeConnectionId: Scalars['ID']['input'];
};

/** An edge in a connection. */
export type WaitlistEdge = {
  __typename?: 'WaitlistEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<Waitlist>;
};

export enum WaitlistPreferredTime {
  Afternoon = 'afternoon',
  Morning = 'morning'
}

export enum WaitlistState {
  Booked = 'booked',
  Canceled = 'canceled',
  Pending = 'pending'
}

export type WaitlistUpdateInput = {
  catalogueItemIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  notes?: InputMaybe<Scalars['String']['input']>;
  practitionerId?: InputMaybe<Scalars['ID']['input']>;
  preferredDays?: InputMaybe<Array<Weekday>>;
  preferredTimes?: InputMaybe<Array<WaitlistPreferredTime>>;
  urgent?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Autogenerated return type of WaitlistUpdated. */
export type WaitlistUpdatedPayload = {
  __typename?: 'WaitlistUpdatedPayload';
  waitlist?: Maybe<Waitlist>;
};

export enum Weekday {
  Friday = 'friday',
  Monday = 'monday',
  Saturday = 'saturday',
  Sunday = 'sunday',
  Thursday = 'thursday',
  Tuesday = 'tuesday',
  Wednesday = 'wednesday'
}

export type Workflow = {
  __typename?: 'Workflow';
  createdAt: Scalars['ISO8601DateTime']['output'];
  guid: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
  practice?: Maybe<Practice>;
  state?: Maybe<Scalars['String']['output']>;
  steps?: Maybe<Array<WorkflowStep>>;
  updatedAt: Scalars['ISO8601DateTime']['output'];
  workflowType?: Maybe<Scalars['String']['output']>;
};

/** The connection type for Workflow. */
export type WorkflowConnection = {
  __typename?: 'WorkflowConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<WorkflowEdge>>>;
  /** IDs of ALL results, regardless of pagination */
  ids: Array<Scalars['ID']['output']>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Workflow>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Returns total count of items in list */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type WorkflowEdge = {
  __typename?: 'WorkflowEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<Workflow>;
};

export type WorkflowInput = {
  name: Scalars['String']['input'];
  steps: Array<WorkflowStepInput>;
  workflowType: Scalars['String']['input'];
};

export enum WorkflowState {
  Active = 'active',
  Archived = 'archived'
}

export enum WorkflowStateEvent {
  Activate = 'activate',
  Archive = 'archive'
}

export type WorkflowStep = {
  __typename?: 'WorkflowStep';
  assignableRole?: Maybe<Array<PracticeRole>>;
  contactType?: Maybe<WorkflowStepContact>;
  createdAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  emailContent?: Maybe<Scalars['String']['output']>;
  frequency: Scalars['String']['output'];
  guid: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  period: Scalars['Int']['output'];
  position: Scalars['Int']['output'];
  smsContent?: Maybe<Scalars['String']['output']>;
  subject?: Maybe<Scalars['String']['output']>;
  templateId?: Maybe<Scalars['ID']['output']>;
  updatedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  workflowId: Scalars['ID']['output'];
};

export enum WorkflowStepContact {
  Email = 'email',
  Letter = 'letter',
  Phone = 'phone',
  Sms = 'sms'
}

export type WorkflowStepInput = {
  assignableRole?: InputMaybe<Array<PracticeRole>>;
  contactType: WorkflowStepContact;
  emailContent?: InputMaybe<Scalars['String']['input']>;
  frequency: Scalars['String']['input'];
  id?: InputMaybe<Scalars['ID']['input']>;
  period: Scalars['Int']['input'];
  smsContent?: InputMaybe<Scalars['String']['input']>;
  templateId?: InputMaybe<Scalars['ID']['input']>;
};

export enum WorkflowType {
  Campaign = 'campaign',
  Recall = 'recall'
}
